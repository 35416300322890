{
  "1": {
    "bug": {
      "damageTaken": {
        "Bug": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fighting": 2,
        "Fire": 1,
        "Flying": 1,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 2,
        "Ice": 0,
        "Normal": 0,
        "Poison": 1,
        "Psychic": 0,
        "Rock": 1,
        "Water": 0
      }
    },
    "fire": {
      "damageTaken": {
        "Bug": 2,
        "Dragon": 0,
        "Electric": 0,
        "Fighting": 0,
        "Fire": 2,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 1,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 1,
        "Water": 1
      }
    },
    "ghost": {
      "damageTaken": {
        "Bug": 2,
        "Dragon": 0,
        "Electric": 0,
        "Fighting": 3,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 1,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 3,
        "Poison": 2,
        "Psychic": 0,
        "Rock": 0,
        "Water": 0
      }
    },
    "ice": {
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 0,
        "Fighting": 1,
        "Fire": 1,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 0,
        "Ground": 0,
        "Ice": 2,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 1,
        "Steel": 1,
        "Water": 0
      }
    },
    "poison": {
      "damageTaken": {
        "Bug": 1,
        "Dragon": 0,
        "Electric": 0,
        "Fighting": 2,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 1,
        "Ice": 0,
        "Normal": 0,
        "Poison": 2,
        "Psychic": 1,
        "Rock": 0,
        "Water": 0
      }
    },
    "psychic": {
      "damageTaken": {
        "Bug": 1,
        "Dragon": 0,
        "Electric": 0,
        "Fighting": 2,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 3,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 2,
        "Rock": 0,
        "Water": 0
      }
    },
    "dark": {"inherit": true, "isNonstandard": "Future"},
    "steel": {"inherit": true, "isNonstandard": "Future"}
  },
  "2": {
    "fire": {
      "inherit": true,
      "damageTaken": {
        "Bug": 2,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fighting": 0,
        "Fire": 2,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 1,
        "Ice": 2,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 1,
        "Steel": 2,
        "Water": 1
      }
    },
    "ice": {
      "inherit": true,
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fighting": 1,
        "Fire": 1,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 0,
        "Ground": 0,
        "Ice": 2,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 1,
        "Steel": 1,
        "Water": 0
      }
    },
    "steel": {
      "inherit": true,
      "damageTaken": {
        "Bug": 2,
        "Dark": 2,
        "Dragon": 2,
        "Electric": 0,
        "Fairy": 2,
        "Fighting": 1,
        "Fire": 1,
        "Flying": 2,
        "Ghost": 2,
        "Grass": 2,
        "Ground": 1,
        "Ice": 2,
        "Normal": 2,
        "Poison": 3,
        "Psychic": 2,
        "Rock": 2,
        "Steel": 2,
        "Water": 0
      }
    }
  },
  "5": {
    "electric": {
      "inherit": true,
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 2,
        "Fighting": 0,
        "Fire": 0,
        "Flying": 2,
        "Ghost": 0,
        "Grass": 0,
        "Ground": 1,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 2,
        "Water": 0
      }
    },
    "ghost": {
      "inherit": true,
      "damageTaken": {
        "Bug": 2,
        "Dark": 1,
        "Dragon": 0,
        "Electric": 0,
        "Fighting": 3,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 1,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 3,
        "Poison": 2,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 0,
        "Water": 0
      }
    },
    "grass": {
      "inherit": true,
      "damageTaken": {
        "Bug": 1,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 2,
        "Fighting": 0,
        "Fire": 1,
        "Flying": 1,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 2,
        "Ice": 1,
        "Normal": 0,
        "Poison": 1,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 0,
        "Water": 2
      }
    },
    "steel": {
      "inherit": true,
      "damageTaken": {
        "Bug": 2,
        "Dark": 2,
        "Dragon": 2,
        "Electric": 0,
        "Fighting": 1,
        "Fire": 1,
        "Flying": 2,
        "Ghost": 2,
        "Grass": 2,
        "Ground": 1,
        "Ice": 2,
        "Normal": 2,
        "Poison": 3,
        "Psychic": 2,
        "Rock": 2,
        "Steel": 2,
        "Water": 0
      }
    },
    "fairy": {"inherit": true, "isNonstandard": "Future"}
  },
  "6": {
    "dark": {
      "inherit": true,
      "damageTaken": {
        "Bug": 1,
        "Dark": 2,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 1,
        "Fighting": 1,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 2,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 3,
        "Rock": 0,
        "Steel": 0,
        "Water": 0
      }
    }
  },
  "9": {
    "bug": {
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 0,
        "Fighting": 2,
        "Fire": 1,
        "Flying": 1,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 2,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 1,
        "Steel": 0,
        "Water": 0
      },
      "HPivs": {"atk": 30, "def": 30, "spd": 30},
      "HPdvs": {"atk": 13, "def": 13}
    },
    "dark": {
      "damageTaken": {
        "Bug": 1,
        "Dark": 2,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 1,
        "Fighting": 1,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 2,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 3,
        "Rock": 0,
        "Steel": 0,
        "Water": 0
      },
      "HPivs": {}
    },
    "dragon": {
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 1,
        "Electric": 2,
        "Fairy": 1,
        "Fighting": 0,
        "Fire": 2,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 0,
        "Ice": 1,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 0,
        "Water": 2
      },
      "HPivs": {"atk": 30},
      "HPdvs": {"def": 14}
    },
    "electric": {
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 2,
        "Fairy": 0,
        "Fighting": 0,
        "Fire": 0,
        "Flying": 2,
        "Ghost": 0,
        "Grass": 0,
        "Ground": 1,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 2,
        "Water": 0
      },
      "HPivs": {"spa": 30},
      "HPdvs": {"atk": 14}
    },
    "fairy": {
      "damageTaken": {
        "Bug": 2,
        "Dark": 2,
        "Dragon": 3,
        "Electric": 0,
        "Fairy": 0,
        "Fighting": 2,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 0,
        "Poison": 1,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 1,
        "Water": 0
      }
    },
    "fighting": {
      "damageTaken": {
        "Bug": 2,
        "Dark": 2,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 1,
        "Fighting": 0,
        "Fire": 0,
        "Flying": 1,
        "Ghost": 0,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 1,
        "Rock": 2,
        "Steel": 0,
        "Water": 0
      },
      "HPivs": {"def": 30, "spa": 30, "spd": 30, "spe": 30},
      "HPdvs": {"atk": 12, "def": 12}
    },
    "fire": {
      "damageTaken": {
        "Bug": 2,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 2,
        "Fighting": 0,
        "Fire": 2,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 1,
        "Ice": 2,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 1,
        "Steel": 2,
        "Water": 1
      },
      "HPivs": {"atk": 30, "spa": 30, "spe": 30},
      "HPdvs": {"atk": 14, "def": 12}
    },
    "flying": {
      "damageTaken": {
        "Bug": 2,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 1,
        "Fairy": 0,
        "Fighting": 2,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 3,
        "Ice": 1,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 1,
        "Steel": 0,
        "Water": 0
      },
      "HPivs": {"hp": 30, "atk": 30, "def": 30, "spa": 30, "spd": 30},
      "HPdvs": {"atk": 12, "def": 13}
    },
    "ghost": {
      "damageTaken": {
        "Bug": 2,
        "Dark": 1,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 0,
        "Fighting": 3,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 1,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 3,
        "Poison": 2,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 0,
        "Water": 0
      },
      "HPivs": {"def": 30, "spd": 30},
      "HPdvs": {"atk": 13, "def": 14}
    },
    "grass": {
      "damageTaken": {
        "Bug": 1,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 2,
        "Fairy": 0,
        "Fighting": 0,
        "Fire": 1,
        "Flying": 1,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 2,
        "Ice": 1,
        "Normal": 0,
        "Poison": 1,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 0,
        "Water": 2
      },
      "HPivs": {"atk": 30, "spa": 30},
      "HPdvs": {"atk": 14, "def": 14}
    },
    "ground": {
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 3,
        "Fairy": 0,
        "Fighting": 0,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 1,
        "Ground": 0,
        "Ice": 1,
        "Normal": 0,
        "Poison": 2,
        "Psychic": 0,
        "Rock": 2,
        "Steel": 0,
        "Water": 1
      },
      "HPivs": {"spa": 30, "spd": 30},
      "HPdvs": {"atk": 12}
    },
    "ice": {
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 0,
        "Fighting": 1,
        "Fire": 1,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 0,
        "Ground": 0,
        "Ice": 2,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 1,
        "Steel": 1,
        "Water": 0
      },
      "HPivs": {"atk": 30, "def": 30},
      "HPdvs": {"def": 13}
    },
    "normal": {
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 0,
        "Fighting": 1,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 3,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 0,
        "Water": 0
      }
    },
    "poison": {
      "damageTaken": {
        "Bug": 2,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 2,
        "Fighting": 2,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 1,
        "Ice": 0,
        "Normal": 0,
        "Poison": 2,
        "Psychic": 1,
        "Rock": 0,
        "Steel": 0,
        "Water": 0
      },
      "HPivs": {"def": 30, "spa": 30, "spd": 30},
      "HPdvs": {"atk": 12, "def": 14}
    },
    "psychic": {
      "damageTaken": {
        "Bug": 1,
        "Dark": 1,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 0,
        "Fighting": 2,
        "Fire": 0,
        "Flying": 0,
        "Ghost": 1,
        "Grass": 0,
        "Ground": 0,
        "Ice": 0,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 2,
        "Rock": 0,
        "Steel": 0,
        "Water": 0
      },
      "HPivs": {"atk": 30, "spe": 30},
      "HPdvs": {"def": 12}
    },
    "rock": {
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 0,
        "Fairy": 0,
        "Fighting": 1,
        "Fire": 2,
        "Flying": 2,
        "Ghost": 0,
        "Grass": 1,
        "Ground": 1,
        "Ice": 0,
        "Normal": 2,
        "Poison": 2,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 1,
        "Water": 1
      },
      "HPivs": {"def": 30, "spd": 30, "spe": 30},
      "HPdvs": {"atk": 13, "def": 12}
    },
    "steel": {
      "damageTaken": {
        "Bug": 2,
        "Dark": 0,
        "Dragon": 2,
        "Electric": 0,
        "Fairy": 2,
        "Fighting": 1,
        "Fire": 1,
        "Flying": 2,
        "Ghost": 0,
        "Grass": 2,
        "Ground": 1,
        "Ice": 2,
        "Normal": 2,
        "Poison": 3,
        "Psychic": 2,
        "Rock": 2,
        "Steel": 2,
        "Water": 0
      },
      "HPivs": {"spd": 30},
      "HPdvs": {"atk": 13}
    },
    "water": {
      "damageTaken": {
        "Bug": 0,
        "Dark": 0,
        "Dragon": 0,
        "Electric": 1,
        "Fairy": 0,
        "Fighting": 0,
        "Fire": 2,
        "Flying": 0,
        "Ghost": 0,
        "Grass": 1,
        "Ground": 0,
        "Ice": 2,
        "Normal": 0,
        "Poison": 0,
        "Psychic": 0,
        "Rock": 0,
        "Steel": 2,
        "Water": 2
      },
      "HPivs": {"atk": 30, "def": 30, "spa": 30},
      "HPdvs": {"atk": 14, "def": 13}
    }
  }
}