import { html } from 'htm/preact'
import { iconIndexes } from './icons'
import { Dex } from '@pkmn/dex'
const pokedex = Dex.forGen(9).data.Species

export const PokemonIcon = ({ pokemon }) => {
  const [id, data] = Object.entries(pokedex).find(
    p => p[1].name === pokemon.replace('-*', '').replace(/'/g, '\u2019'),
  )
  const index = iconIndexes[id] || data.num

  return html` <span
    class="icon"
    style=${{
      backgroundPosition: `-${(index % 12) * 40}px -${
        Math.floor(index / 12) * 30
      }px`,
    }}
  ></span>`
}
