{
  "1": {
    "bulbasaur": {"tier": "LC"},
    "ivysaur": {"tier": "NFE"},
    "venusaur": {"tier": "UU"},
    "charmander": {"tier": "LC"},
    "charmeleon": {"tier": "NFE"},
    "charizard": {"tier": "NU"},
    "squirtle": {"tier": "LC"},
    "wartortle": {"tier": "NFE"},
    "blastoise": {"tier": "NU"},
    "caterpie": {"tier": "LC"},
    "metapod": {"tier": "NFE"},
    "butterfree": {"tier": "PU"},
    "weedle": {"tier": "LC"},
    "kakuna": {"tier": "NFE"},
    "beedrill": {"tier": "PU"},
    "pidgey": {"tier": "LC"},
    "pidgeotto": {"tier": "NFE"},
    "pidgeot": {"tier": "PU"},
    "rattata": {"tier": "LC"},
    "raticate": {"tier": "NU"},
    "spearow": {"tier": "LC"},
    "fearow": {"tier": "PU"},
    "ekans": {"tier": "LC"},
    "arbok": {"tier": "PU"},
    "pikachu": {"tier": "LC"},
    "raichu": {"tier": "NUBL"},
    "sandshrew": {"tier": "LC"},
    "sandslash": {"tier": "PU"},
    "nidoranf": {"tier": "LC"},
    "nidorina": {"tier": "NFE"},
    "nidoqueen": {"tier": "PU"},
    "nidoranm": {"tier": "LC"},
    "nidorino": {"tier": "NFE"},
    "nidoking": {"tier": "NU"},
    "clefairy": {"tier": "LC"},
    "clefable": {"tier": "NU"},
    "vulpix": {"tier": "LC"},
    "ninetales": {"tier": "NU"},
    "jigglypuff": {"tier": "LC"},
    "wigglytuff": {"tier": "NU"},
    "zubat": {"tier": "LC"},
    "golbat": {"tier": "PU"},
    "oddish": {"tier": "LC"},
    "gloom": {"tier": "NFE"},
    "vileplume": {"tier": "PU"},
    "paras": {"tier": "LC"},
    "parasect": {"tier": "PU"},
    "venonat": {"tier": "LC"},
    "venomoth": {"tier": "NU"},
    "diglett": {"tier": "LC"},
    "dugtrio": {"tier": "UU"},
    "meowth": {"tier": "LC"},
    "persian": {"tier": "UU"},
    "psyduck": {"tier": "LC"},
    "golduck": {"tier": "NUBL"},
    "mankey": {"tier": "LC"},
    "primeape": {"tier": "PU"},
    "growlithe": {"tier": "LC"},
    "arcanine": {"tier": "PU"},
    "poliwag": {"tier": "LC"},
    "poliwhirl": {"tier": "NUBL"},
    "poliwrath": {"tier": "NUBL"},
    "abra": {"tier": "PU"},
    "kadabra": {"tier": "UU"},
    "alakazam": {"tier": "OU"},
    "machop": {"tier": "LC"},
    "machoke": {"tier": "NFE"},
    "machamp": {"tier": "PU"},
    "bellsprout": {"tier": "LC"},
    "weepinbell": {"tier": "NFE"},
    "victreebel": {"tier": "UU"},
    "tentacool": {"tier": "LC"},
    "tentacruel": {"tier": "UU"},
    "geodude": {"tier": "LC"},
    "graveler": {"tier": "PU"},
    "golem": {"tier": "NU"},
    "ponyta": {"tier": "LC"},
    "rapidash": {"tier": "PU"},
    "slowpoke": {"tier": "LC"},
    "slowbro": {"tier": "OU"},
    "magnemite": {"tier": "LC"},
    "magneton": {"tier": "PU"},
    "farfetchd": {"tier": "PU"},
    "doduo": {"tier": "LC"},
    "dodrio": {"tier": "UU"},
    "seel": {"tier": "LC"},
    "dewgong": {"tier": "UU"},
    "grimer": {"tier": "LC"},
    "muk": {"tier": "PU"},
    "shellder": {"tier": "LC"},
    "cloyster": {"tier": "OU"},
    "gastly": {"tier": "PU"},
    "haunter": {"tier": "UU"},
    "gengar": {"tier": "OU"},
    "onix": {"tier": "PU"},
    "drowzee": {"tier": "PU"},
    "hypno": {"tier": "UUBL"},
    "krabby": {"tier": "LC"},
    "kingler": {"tier": "NU"},
    "voltorb": {"tier": "LC"},
    "electrode": {"tier": "NU"},
    "exeggcute": {"tier": "NU"},
    "exeggutor": {"tier": "OU"},
    "cubone": {"tier": "LC"},
    "marowak": {"tier": "PU"},
    "hitmonlee": {"tier": "PU"},
    "hitmonchan": {"tier": "PU"},
    "lickitung": {"tier": "PU"},
    "koffing": {"tier": "LC"},
    "weezing": {"tier": "PU"},
    "rhyhorn": {"tier": "LC"},
    "rhydon": {"tier": "OU"},
    "chansey": {"tier": "OU"},
    "tangela": {"tier": "UU"},
    "kangaskhan": {"tier": "UU"},
    "horsea": {"tier": "LC"},
    "seadra": {"tier": "NU"},
    "goldeen": {"tier": "LC"},
    "seaking": {"tier": "PU"},
    "staryu": {"tier": "PU"},
    "starmie": {"tier": "OU"},
    "mrmime": {"tier": "NU"},
    "scyther": {"tier": "PU"},
    "jynx": {"tier": "OU"},
    "electabuzz": {"tier": "UU"},
    "magmar": {"tier": "PU"},
    "pinsir": {"tier": "PU"},
    "tauros": {"tier": "OU"},
    "magikarp": {"tier": "LC"},
    "gyarados": {"tier": "UU"},
    "lapras": {"tier": "UUBL"},
    "ditto": {"tier": "PU"},
    "eevee": {"tier": "LC"},
    "vaporeon": {"tier": "UU"},
    "jolteon": {"tier": "OU"},
    "flareon": {"tier": "PU"},
    "porygon": {"tier": "PU"},
    "omanyte": {"tier": "PU"},
    "omastar": {"tier": "UU"},
    "kabuto": {"tier": "LC"},
    "kabutops": {"tier": "NU"},
    "aerodactyl": {"tier": "UU"},
    "snorlax": {"tier": "OU"},
    "articuno": {"tier": "UU"},
    "zapdos": {"tier": "OU"},
    "moltres": {"tier": "NU"},
    "dratini": {"tier": "LC"},
    "dragonair": {"tier": "PU"},
    "dragonite": {"tier": "UU"},
    "mewtwo": {"tier": "Uber"},
    "mew": {"tier": "Uber"},
    "missingno": {"isNonstandard": "Unobtainable", "tier": "Illegal"}
  },
  "2": {
    "bulbasaur": {"tier": "LC"},
    "ivysaur": {"tier": "NFE"},
    "venusaur": {"tier": "UUBL"},
    "charmander": {"tier": "LC"},
    "charmeleon": {"tier": "NFE"},
    "charizard": {"tier": "UUBL"},
    "squirtle": {"tier": "LC"},
    "wartortle": {"tier": "NFE"},
    "blastoise": {"tier": "UU"},
    "caterpie": {"tier": "LC"},
    "metapod": {"tier": "NFE"},
    "butterfree": {"tier": "PU"},
    "weedle": {"tier": "LC"},
    "kakuna": {"tier": "NFE"},
    "beedrill": {"tier": "PU"},
    "pidgey": {"tier": "LC"},
    "pidgeotto": {"tier": "NFE"},
    "pidgeot": {"tier": "NU"},
    "rattata": {"tier": "LC"},
    "raticate": {"tier": "NU"},
    "spearow": {"tier": "LC"},
    "fearow": {"tier": "NU"},
    "ekans": {"tier": "LC"},
    "arbok": {"tier": "NU"},
    "pichu": {"tier": "LC"},
    "pikachu": {"tier": "UU"},
    "raichu": {"tier": "NUBL"},
    "sandshrew": {"tier": "LC"},
    "sandslash": {"tier": "UU"},
    "nidoranf": {"tier": "LC"},
    "nidorina": {"tier": "NFE"},
    "nidoqueen": {"tier": "UU"},
    "nidoranm": {"tier": "LC"},
    "nidorino": {"tier": "NFE"},
    "nidoking": {"tier": "OU"},
    "cleffa": {"tier": "LC"},
    "clefairy": {"tier": "NFE"},
    "clefable": {"tier": "UUBL"},
    "vulpix": {"tier": "LC"},
    "ninetales": {"tier": "NU"},
    "igglybuff": {"tier": "LC"},
    "jigglypuff": {"tier": "NFE"},
    "wigglytuff": {"tier": "NU"},
    "zubat": {"tier": "LC"},
    "golbat": {"tier": "NFE"},
    "crobat": {"tier": "UU"},
    "oddish": {"tier": "LC"},
    "gloom": {"tier": "NU"},
    "vileplume": {"tier": "UU"},
    "bellossom": {"tier": "UU"},
    "paras": {"tier": "LC"},
    "parasect": {"tier": "PU"},
    "venonat": {"tier": "LC"},
    "venomoth": {"tier": "PU"},
    "diglett": {"tier": "LC"},
    "dugtrio": {"tier": "NU"},
    "meowth": {"tier": "LC"},
    "persian": {"tier": "NU"},
    "psyduck": {"tier": "LC"},
    "golduck": {"tier": "NUBL"},
    "mankey": {"tier": "LC"},
    "primeape": {"tier": "NU"},
    "growlithe": {"tier": "LC"},
    "arcanine": {"tier": "UU"},
    "poliwag": {"tier": "LC"},
    "poliwhirl": {"tier": "PU"},
    "poliwrath": {"tier": "NUBL"},
    "politoed": {"tier": "UU"},
    "abra": {"tier": "LC"},
    "kadabra": {"tier": "UU"},
    "alakazam": {"tier": "UUBL"},
    "machop": {"tier": "LC"},
    "machoke": {"tier": "PU"},
    "machamp": {"tier": "OU"},
    "bellsprout": {"tier": "LC"},
    "weepinbell": {"tier": "NFE"},
    "victreebel": {"tier": "UU"},
    "tentacool": {"tier": "PU"},
    "tentacruel": {"tier": "UUBL"},
    "geodude": {"tier": "LC"},
    "graveler": {"tier": "NU"},
    "golem": {"tier": "OU"},
    "ponyta": {"tier": "LC"},
    "rapidash": {"tier": "NU"},
    "slowpoke": {"tier": "PU"},
    "slowbro": {"tier": "UU"},
    "slowking": {"tier": "UU"},
    "magnemite": {"tier": "NU"},
    "magneton": {"tier": "UU"},
    "farfetchd": {"tier": "NU"},
    "doduo": {"tier": "LC"},
    "dodrio": {"tier": "UU"},
    "seel": {"tier": "LC"},
    "dewgong": {"tier": "NU"},
    "grimer": {"tier": "LC"},
    "muk": {"tier": "UUBL"},
    "shellder": {"tier": "LC"},
    "cloyster": {"tier": "OU"},
    "gastly": {"tier": "PU"},
    "haunter": {"tier": "UU"},
    "gengar": {"tier": "OU"},
    "onix": {"tier": "LC"},
    "steelix": {"tier": "OU"},
    "drowzee": {"tier": "LC"},
    "hypno": {"tier": "UU"},
    "krabby": {"tier": "LC"},
    "kingler": {"tier": "NU"},
    "voltorb": {"tier": "PU"},
    "electrode": {"tier": "UU"},
    "exeggcute": {"tier": "NU"},
    "exeggutor": {"tier": "OU"},
    "cubone": {"tier": "PU"},
    "marowak": {"tier": "OU"},
    "tyrogue": {"tier": "LC"},
    "hitmonlee": {"tier": "NU"},
    "hitmonchan": {"tier": "PUBL"},
    "hitmontop": {"tier": "PU"},
    "lickitung": {"tier": "NU"},
    "koffing": {"tier": "LC"},
    "weezing": {"tier": "NU"},
    "rhyhorn": {"tier": "LC"},
    "rhydon": {"tier": "OU"},
    "chansey": {"tier": "UU"},
    "blissey": {"tier": "OU"},
    "tangela": {"tier": "PU"},
    "kangaskhan": {"tier": "UUBL"},
    "horsea": {"tier": "LC"},
    "seadra": {"tier": "PU"},
    "kingdra": {"tier": "UUBL"},
    "goldeen": {"tier": "LC"},
    "seaking": {"tier": "PU"},
    "staryu": {"tier": "LC"},
    "starmie": {"tier": "OU"},
    "mrmime": {"tier": "UU"},
    "scyther": {"tier": "UU"},
    "scizor": {"tier": "UUBL"},
    "smoochum": {"tier": "LC"},
    "jynx": {"tier": "OU"},
    "elekid": {"tier": "PU"},
    "electabuzz": {"tier": "UU"},
    "magby": {"tier": "LC"},
    "magmar": {"tier": "NU"},
    "pinsir": {"tier": "UU"},
    "tauros": {"tier": "UUBL"},
    "magikarp": {"tier": "LC"},
    "gyarados": {"tier": "UU"},
    "lapras": {"tier": "UUBL"},
    "ditto": {"tier": "PU"},
    "eevee": {"tier": "LC"},
    "vaporeon": {"tier": "OU"},
    "jolteon": {"tier": "UUBL"},
    "flareon": {"tier": "NU"},
    "espeon": {"tier": "UUBL"},
    "umbreon": {"tier": "OU"},
    "porygon": {"tier": "NU"},
    "porygon2": {"tier": "UUBL"},
    "omanyte": {"tier": "LC"},
    "omastar": {"tier": "UU"},
    "kabuto": {"tier": "LC"},
    "kabutops": {"tier": "UU"},
    "aerodactyl": {"tier": "UUBL"},
    "snorlax": {"tier": "OU"},
    "articuno": {"tier": "UUBL"},
    "zapdos": {"tier": "OU"},
    "moltres": {"tier": "UUBL"},
    "dratini": {"tier": "LC"},
    "dragonair": {"tier": "NU"},
    "dragonite": {"tier": "UUBL"},
    "mewtwo": {"tier": "Uber"},
    "mew": {"tier": "Uber"},
    "chikorita": {"tier": "LC"},
    "bayleef": {"tier": "PU"},
    "meganium": {"tier": "UUBL"},
    "cyndaquil": {"tier": "LC"},
    "quilava": {"tier": "NFE"},
    "typhlosion": {"tier": "UUBL"},
    "totodile": {"tier": "LC"},
    "croconaw": {"tier": "NFE"},
    "feraligatr": {"tier": "NUBL"},
    "sentret": {"tier": "LC"},
    "furret": {"tier": "PU"},
    "hoothoot": {"tier": "LC"},
    "noctowl": {"tier": "PU"},
    "ledyba": {"tier": "LC"},
    "ledian": {"tier": "NU"},
    "spinarak": {"tier": "LC"},
    "ariados": {"tier": "PU"},
    "chinchou": {"tier": "NU"},
    "lanturn": {"tier": "UU"},
    "togepi": {"tier": "LC"},
    "togetic": {"tier": "PU"},
    "natu": {"tier": "LC"},
    "xatu": {"tier": "NU"},
    "mareep": {"tier": "LC"},
    "flaaffy": {"tier": "PU"},
    "ampharos": {"tier": "UU"},
    "marill": {"tier": "LC"},
    "azumarill": {"tier": "NU"},
    "sudowoodo": {"tier": "NU"},
    "hoppip": {"tier": "LC"},
    "skiploom": {"tier": "NFE"},
    "jumpluff": {"tier": "UU"},
    "aipom": {"tier": "PU"},
    "sunkern": {"tier": "LC"},
    "sunflora": {"tier": "PU"},
    "yanma": {"tier": "PU"},
    "wooper": {"tier": "LC"},
    "quagsire": {"tier": "UU"},
    "murkrow": {"tier": "PU"},
    "misdreavus": {"tier": "OU"},
    "unown": {"tier": "PU"},
    "wobbuffet": {"tier": "PU"},
    "girafarig": {"tier": "UU"},
    "pineco": {"tier": "NU"},
    "forretress": {"tier": "OU"},
    "dunsparce": {"tier": "NU"},
    "gligar": {"tier": "UU"},
    "snubbull": {"tier": "LC"},
    "granbull": {"tier": "UU"},
    "qwilfish": {"tier": "UU"},
    "shuckle": {"tier": "NU"},
    "heracross": {"tier": "OU"},
    "sneasel": {"tier": "PU"},
    "teddiursa": {"tier": "LC"},
    "ursaring": {"tier": "UUBL"},
    "slugma": {"tier": "LC"},
    "magcargo": {"tier": "PU"},
    "swinub": {"tier": "LC"},
    "piloswine": {"tier": "UU"},
    "corsola": {"tier": "PU"},
    "remoraid": {"tier": "LC"},
    "octillery": {"tier": "NU"},
    "delibird": {"tier": "PU"},
    "mantine": {"tier": "PU"},
    "skarmory": {"tier": "OU"},
    "houndour": {"tier": "NU"},
    "houndoom": {"tier": "UUBL"},
    "phanpy": {"tier": "LC"},
    "donphan": {"tier": "UUBL"},
    "stantler": {"tier": "NU"},
    "smeargle": {"tier": "UUBL"},
    "miltank": {"tier": "OU"},
    "raikou": {"tier": "OU"},
    "entei": {"tier": "UUBL"},
    "suicune": {"tier": "OU"},
    "larvitar": {"tier": "LC"},
    "pupitar": {"tier": "NU"},
    "tyranitar": {"tier": "OU"},
    "lugia": {"tier": "Uber"},
    "hooh": {"tier": "Uber"},
    "celebi": {"tier": "Uber"}
  },
  "3": {
    "bulbasaur": {"tier": "LC"},
    "ivysaur": {"tier": "PU"},
    "venusaur": {"tier": "UUBL"},
    "charmander": {"tier": "LC"},
    "charmeleon": {"tier": "PU"},
    "charizard": {"tier": "OU"},
    "squirtle": {"tier": "LC"},
    "wartortle": {"tier": "PU"},
    "blastoise": {"tier": "UU"},
    "caterpie": {"tier": "LC"},
    "metapod": {"tier": "NFE"},
    "butterfree": {"tier": "ZU"},
    "weedle": {"tier": "LC"},
    "kakuna": {"tier": "NFE"},
    "beedrill": {"tier": "PU"},
    "pidgey": {"tier": "LC"},
    "pidgeotto": {"tier": "ZU"},
    "pidgeot": {"tier": "NU"},
    "rattata": {"tier": "LC"},
    "raticate": {"tier": "NU"},
    "spearow": {"tier": "LC"},
    "fearow": {"tier": "UU"},
    "ekans": {"tier": "LC"},
    "arbok": {"tier": "PU"},
    "pichu": {"tier": "LC"},
    "pikachu": {"tier": "NU"},
    "raichu": {"tier": "UU"},
    "sandshrew": {"tier": "LC"},
    "sandslash": {"tier": "UU"},
    "nidoranf": {"tier": "LC"},
    "nidorina": {"tier": "NFE"},
    "nidoqueen": {"tier": "UU"},
    "nidoranm": {"tier": "LC"},
    "nidorino": {"tier": "ZU"},
    "nidoking": {"tier": "UU"},
    "cleffa": {"tier": "LC"},
    "clefairy": {"tier": "NFE"},
    "clefable": {"tier": "UU"},
    "vulpix": {"tier": "LC"},
    "ninetales": {"tier": "UU"},
    "igglybuff": {"tier": "LC"},
    "jigglypuff": {"tier": "NFE"},
    "wigglytuff": {"tier": "PU"},
    "zubat": {"tier": "LC"},
    "golbat": {"tier": "NU"},
    "crobat": {"tier": "UUBL"},
    "oddish": {"tier": "LC"},
    "gloom": {"tier": "ZUBL"},
    "vileplume": {"tier": "UU"},
    "bellossom": {"tier": "NU"},
    "paras": {"tier": "LC"},
    "parasect": {"tier": "ZU"},
    "venonat": {"tier": "LC"},
    "venomoth": {"tier": "NU"},
    "diglett": {"tier": "NU"},
    "dugtrio": {"tier": "OU"},
    "meowth": {"tier": "LC"},
    "persian": {"tier": "UU"},
    "psyduck": {"tier": "LC"},
    "golduck": {"tier": "UU"},
    "mankey": {"tier": "ZU"},
    "primeape": {"tier": "UU"},
    "growlithe": {"tier": "ZU"},
    "arcanine": {"tier": "UU"},
    "poliwag": {"tier": "LC"},
    "poliwhirl": {"tier": "ZUBL"},
    "poliwrath": {"tier": "UU"},
    "politoed": {"tier": "UU"},
    "abra": {"tier": "PU"},
    "kadabra": {"tier": "UUBL"},
    "alakazam": {"tier": "UUBL"},
    "machop": {"tier": "LC"},
    "machoke": {"tier": "PUBL"},
    "machamp": {"tier": "UUBL"},
    "bellsprout": {"tier": "LC"},
    "weepinbell": {"tier": "ZUBL"},
    "victreebel": {"tier": "UU"},
    "tentacool": {"tier": "ZUBL"},
    "tentacruel": {"tier": "UU"},
    "geodude": {"tier": "LC"},
    "graveler": {"tier": "PU"},
    "golem": {"tier": "UU"},
    "ponyta": {"tier": "ZUBL"},
    "rapidash": {"tier": "UU"},
    "slowpoke": {"tier": "LC"},
    "slowbro": {"tier": "UUBL"},
    "slowking": {"tier": "UU"},
    "magnemite": {"tier": "ZU"},
    "magneton": {"tier": "OU"},
    "farfetchd": {"tier": "ZU"},
    "doduo": {"tier": "PU"},
    "dodrio": {"tier": "UUBL"},
    "seel": {"tier": "LC"},
    "dewgong": {"tier": "NU"},
    "grimer": {"tier": "ZU"},
    "muk": {"tier": "UU"},
    "shellder": {"tier": "LC"},
    "cloyster": {"tier": "OU"},
    "gastly": {"tier": "PU"},
    "haunter": {"tier": "NU"},
    "gengar": {"tier": "OU"},
    "onix": {"tier": "ZU"},
    "steelix": {"tier": "UUBL"},
    "drowzee": {"tier": "ZUBL"},
    "hypno": {"tier": "UU"},
    "krabby": {"tier": "LC"},
    "kingler": {"tier": "PU"},
    "voltorb": {"tier": "ZU"},
    "electrode": {"tier": "UU"},
    "exeggcute": {"tier": "ZU"},
    "exeggutor": {"tier": "UUBL"},
    "cubone": {"tier": "ZU"},
    "marowak": {"tier": "UUBL"},
    "tyrogue": {"tier": "LC"},
    "hitmonlee": {"tier": "UU"},
    "hitmonchan": {"tier": "NU"},
    "hitmontop": {"tier": "UU"},
    "lickitung": {"tier": "PU"},
    "koffing": {"tier": "ZU"},
    "weezing": {"tier": "UUBL"},
    "rhyhorn": {"tier": "ZU"},
    "rhydon": {"tier": "UUBL"},
    "chansey": {"tier": "UUBL"},
    "blissey": {"tier": "OU"},
    "tangela": {"tier": "PU"},
    "kangaskhan": {"tier": "UU"},
    "horsea": {"tier": "ZU"},
    "seadra": {"tier": "PU"},
    "kingdra": {"tier": "UUBL"},
    "goldeen": {"tier": "LC"},
    "seaking": {"tier": "ZU"},
    "staryu": {"tier": "ZU"},
    "starmie": {"tier": "OU"},
    "mrmime": {"tier": "UU"},
    "scyther": {"tier": "UU"},
    "scizor": {"tier": "UUBL"},
    "smoochum": {"tier": "ZUBL"},
    "jynx": {"tier": "UUBL"},
    "elekid": {"tier": "ZU"},
    "electabuzz": {"tier": "UU"},
    "magby": {"tier": "PU"},
    "magmar": {"tier": "UU"},
    "pinsir": {"tier": "UU"},
    "tauros": {"tier": "UUBL"},
    "magikarp": {"tier": "LC"},
    "gyarados": {"tier": "OU"},
    "lapras": {"tier": "UUBL"},
    "ditto": {"tier": "ZU"},
    "eevee": {"tier": "LC"},
    "vaporeon": {"tier": "UUBL"},
    "jolteon": {"tier": "OU"},
    "flareon": {"tier": "NU"},
    "espeon": {"tier": "UUBL"},
    "umbreon": {"tier": "UUBL"},
    "porygon": {"tier": "ZUBL"},
    "porygon2": {"tier": "UUBL"},
    "omanyte": {"tier": "PU"},
    "omastar": {"tier": "UU"},
    "kabuto": {"tier": "LC"},
    "kabutops": {"tier": "UU"},
    "aerodactyl": {"tier": "OU"},
    "snorlax": {"tier": "OU"},
    "articuno": {"tier": "UUBL"},
    "zapdos": {"tier": "OU"},
    "moltres": {"tier": "OU"},
    "dratini": {"tier": "LC"},
    "dragonair": {"tier": "PU"},
    "dragonite": {"tier": "UUBL"},
    "mewtwo": {"tier": "Uber"},
    "mew": {"tier": "Uber"},
    "chikorita": {"tier": "LC"},
    "bayleef": {"tier": "ZU"},
    "meganium": {"tier": "UU"},
    "cyndaquil": {"tier": "ZU"},
    "quilava": {"tier": "ZUBL"},
    "typhlosion": {"tier": "UUBL"},
    "totodile": {"tier": "LC"},
    "croconaw": {"tier": "NFE"},
    "feraligatr": {"tier": "UU"},
    "sentret": {"tier": "LC"},
    "furret": {"tier": "PU"},
    "hoothoot": {"tier": "LC"},
    "noctowl": {"tier": "PU"},
    "ledyba": {"tier": "LC"},
    "ledian": {"tier": "ZU"},
    "spinarak": {"tier": "LC"},
    "ariados": {"tier": "ZUBL"},
    "chinchou": {"tier": "ZU"},
    "lanturn": {"tier": "UU"},
    "togepi": {"tier": "LC"},
    "togetic": {"tier": "PU"},
    "natu": {"tier": "ZU"},
    "xatu": {"tier": "UU"},
    "mareep": {"tier": "LC"},
    "flaaffy": {"tier": "ZUBL"},
    "ampharos": {"tier": "UU"},
    "azurill": {"tier": "LC"},
    "marill": {"tier": "NFE"},
    "azumarill": {"tier": "UU"},
    "sudowoodo": {"tier": "NU"},
    "hoppip": {"tier": "LC"},
    "skiploom": {"tier": "NFE"},
    "jumpluff": {"tier": "UU"},
    "aipom": {"tier": "ZU"},
    "sunkern": {"tier": "LC"},
    "sunflora": {"tier": "ZU"},
    "yanma": {"tier": "PU"},
    "wooper": {"tier": "LC"},
    "quagsire": {"tier": "UU"},
    "murkrow": {"tier": "NU"},
    "misdreavus": {"tier": "UU"},
    "unown": {"tier": "ZU"},
    "wynaut": {"tier": "Uber"},
    "wobbuffet": {"tier": "Uber"},
    "girafarig": {"tier": "UU"},
    "pineco": {"tier": "LC"},
    "forretress": {"tier": "OU"},
    "dunsparce": {"tier": "PUBL"},
    "gligar": {"tier": "UU"},
    "snubbull": {"tier": "LC"},
    "granbull": {"tier": "UU"},
    "qwilfish": {"tier": "UU"},
    "shuckle": {"tier": "PU"},
    "heracross": {"tier": "OU"},
    "sneasel": {"tier": "UU"},
    "teddiursa": {"tier": "LC"},
    "ursaring": {"tier": "UUBL"},
    "slugma": {"tier": "LC"},
    "magcargo": {"tier": "PU"},
    "swinub": {"tier": "LC"},
    "piloswine": {"tier": "PUBL"},
    "corsola": {"tier": "PU"},
    "remoraid": {"tier": "LC"},
    "octillery": {"tier": "NU"},
    "delibird": {"tier": "ZU"},
    "mantine": {"tier": "UU"},
    "skarmory": {"tier": "OU"},
    "houndour": {"tier": "PU"},
    "houndoom": {"tier": "UUBL"},
    "phanpy": {"tier": "LC"},
    "donphan": {"tier": "UUBL"},
    "stantler": {"tier": "UU"},
    "smeargle": {"tier": "UUBL"},
    "miltank": {"tier": "UUBL"},
    "raikou": {"tier": "UUBL"},
    "entei": {"tier": "UUBL"},
    "suicune": {"tier": "OU"},
    "larvitar": {"tier": "ZU"},
    "pupitar": {"tier": "NU"},
    "tyranitar": {"tier": "OU"},
    "lugia": {"tier": "Uber"},
    "hooh": {"tier": "Uber"},
    "celebi": {"tier": "OU"},
    "treecko": {"tier": "LC"},
    "grovyle": {"tier": "PU"},
    "sceptile": {"tier": "UUBL"},
    "torchic": {"tier": "LC"},
    "combusken": {"tier": "PU"},
    "blaziken": {"tier": "UUBL"},
    "mudkip": {"tier": "LC"},
    "marshtomp": {"tier": "PU"},
    "swampert": {"tier": "OU"},
    "poochyena": {"tier": "LC"},
    "mightyena": {"tier": "PU"},
    "zigzagoon": {"tier": "NFE"},
    "linoone": {"tier": "UUBL"},
    "wurmple": {"tier": "LC"},
    "silcoon": {"tier": "NFE"},
    "beautifly": {"tier": "ZU"},
    "cascoon": {"tier": "NFE"},
    "dustox": {"tier": "ZU"},
    "lotad": {"tier": "LC"},
    "lombre": {"tier": "PU"},
    "ludicolo": {"tier": "UUBL"},
    "seedot": {"tier": "LC"},
    "nuzleaf": {"tier": "NFE"},
    "shiftry": {"tier": "UU"},
    "taillow": {"tier": "ZU"},
    "swellow": {"tier": "UUBL"},
    "wingull": {"tier": "LC"},
    "pelipper": {"tier": "NU"},
    "ralts": {"tier": "LC"},
    "kirlia": {"tier": "NFE"},
    "gardevoir": {"tier": "UUBL"},
    "surskit": {"tier": "LC"},
    "masquerain": {"tier": "ZU"},
    "shroomish": {"tier": "LC"},
    "breloom": {"tier": "OU"},
    "slakoth": {"tier": "LC"},
    "vigoroth": {"tier": "NU"},
    "slaking": {"tier": "UUBL"},
    "nincada": {"tier": "LC"},
    "ninjask": {"tier": "UU"},
    "shedinja": {"tier": "ZUBL"},
    "whismur": {"tier": "LC"},
    "loudred": {"tier": "NFE"},
    "exploud": {"tier": "UU"},
    "makuhita": {"tier": "LC"},
    "hariyama": {"tier": "UUBL"},
    "nosepass": {"tier": "ZU"},
    "skitty": {"tier": "LC"},
    "delcatty": {"tier": "ZU"},
    "sableye": {"tier": "NU"},
    "mawile": {"tier": "PU"},
    "aron": {"tier": "ZU"},
    "lairon": {"tier": "PU"},
    "aggron": {"tier": "UU"},
    "meditite": {"tier": "PU"},
    "medicham": {"tier": "UUBL"},
    "electrike": {"tier": "LC"},
    "manectric": {"tier": "UU"},
    "plusle": {"tier": "NU"},
    "minun": {"tier": "PU"},
    "volbeat": {"tier": "PU"},
    "illumise": {"tier": "ZU"},
    "roselia": {"tier": "NU"},
    "gulpin": {"tier": "LC"},
    "swalot": {"tier": "PU"},
    "carvanha": {"tier": "LC"},
    "sharpedo": {"tier": "UU"},
    "wailmer": {"tier": "LC"},
    "wailord": {"tier": "NU"},
    "numel": {"tier": "ZU"},
    "camerupt": {"tier": "UU"},
    "torkoal": {"tier": "NU"},
    "spoink": {"tier": "ZU"},
    "grumpig": {"tier": "UU"},
    "spinda": {"tier": "ZU"},
    "trapinch": {"tier": "PU"},
    "vibrava": {"tier": "PU"},
    "flygon": {"tier": "OU"},
    "cacnea": {"tier": "LC"},
    "cacturne": {"tier": "NU"},
    "swablu": {"tier": "LC"},
    "altaria": {"tier": "UU"},
    "zangoose": {"tier": "UUBL"},
    "seviper": {"tier": "PU"},
    "lunatone": {"tier": "UU"},
    "solrock": {"tier": "UU"},
    "barboach": {"tier": "LC"},
    "whiscash": {"tier": "NU"},
    "corphish": {"tier": "LC"},
    "crawdaunt": {"tier": "NU"},
    "baltoy": {"tier": "LC"},
    "claydol": {"tier": "OU"},
    "lileep": {"tier": "ZU"},
    "cradily": {"tier": "UU"},
    "anorith": {"tier": "ZUBL"},
    "armaldo": {"tier": "UUBL"},
    "feebas": {"tier": "LC"},
    "milotic": {"tier": "OU"},
    "castform": {"tier": "ZU"},
    "castformsunny": {"tier": "ZU"},
    "castformrainy": {"tier": "ZU"},
    "castformsnowy": {"tier": "ZU"},
    "kecleon": {"tier": "NU"},
    "shuppet": {"tier": "ZU"},
    "banette": {"tier": "UU"},
    "duskull": {"tier": "PU"},
    "dusclops": {"tier": "UUBL"},
    "tropius": {"tier": "PU"},
    "chimecho": {"tier": "NU"},
    "absol": {"tier": "UU"},
    "snorunt": {"tier": "ZU"},
    "glalie": {"tier": "NU"},
    "spheal": {"tier": "LC"},
    "sealeo": {"tier": "PU"},
    "walrein": {"tier": "UU"},
    "clamperl": {"tier": "ZUBL"},
    "huntail": {"tier": "NU"},
    "gorebyss": {"tier": "UU"},
    "relicanth": {"tier": "NU"},
    "luvdisc": {"tier": "ZU"},
    "bagon": {"tier": "LC"},
    "shelgon": {"tier": "PU"},
    "salamence": {"tier": "OU"},
    "beldum": {"tier": "LC"},
    "metang": {"tier": "NU"},
    "metagross": {"tier": "OU"},
    "regirock": {"tier": "UUBL"},
    "regice": {"tier": "UUBL"},
    "registeel": {"tier": "UUBL"},
    "latias": {"tier": "Uber"},
    "latios": {"tier": "Uber"},
    "kyogre": {"tier": "Uber"},
    "groudon": {"tier": "Uber"},
    "rayquaza": {"tier": "Uber"},
    "jirachi": {"tier": "OU"},
    "deoxys": {"tier": "Uber"},
    "deoxysattack": {"tier": "Uber"},
    "deoxysdefense": {"tier": "Uber"},
    "deoxysspeed": {"tier": "Uber"}
  },
  "4": {
    "bulbasaur": {"tier": "LC"},
    "ivysaur": {"tier": "NFE"},
    "venusaur": {"tier": "UU"},
    "charmander": {"tier": "LC"},
    "charmeleon": {"tier": "NFE"},
    "charizard": {"tier": "NU"},
    "squirtle": {"tier": "LC"},
    "wartortle": {"tier": "NFE"},
    "blastoise": {"tier": "UU"},
    "caterpie": {"tier": "LC"},
    "metapod": {"tier": "NFE"},
    "butterfree": {"tier": "NU"},
    "weedle": {"tier": "LC"},
    "kakuna": {"tier": "NFE"},
    "beedrill": {"tier": "NU"},
    "pidgey": {"tier": "LC"},
    "pidgeotto": {"tier": "NFE"},
    "pidgeot": {"tier": "NU"},
    "rattata": {"tier": "LC"},
    "raticate": {"tier": "NU"},
    "spearow": {"tier": "LC"},
    "fearow": {"tier": "NU"},
    "ekans": {"tier": "LC"},
    "arbok": {"tier": "NU"},
    "pichu": {"tier": "LC"},
    "pichuspikyeared": {"tier": "NU"},
    "pikachu": {"tier": "NU"},
    "raichu": {"tier": "NU"},
    "sandshrew": {"tier": "LC"},
    "sandslash": {"tier": "NU"},
    "nidoranf": {"tier": "LC"},
    "nidorina": {"tier": "NFE"},
    "nidoqueen": {"tier": "NU"},
    "nidoranm": {"tier": "LC"},
    "nidorino": {"tier": "NFE"},
    "nidoking": {"tier": "UU"},
    "cleffa": {"tier": "LC"},
    "clefairy": {"tier": "NFE"},
    "clefable": {"tier": "UU"},
    "vulpix": {"tier": "LC"},
    "ninetales": {"tier": "NU"},
    "igglybuff": {"tier": "LC"},
    "jigglypuff": {"tier": "NFE"},
    "wigglytuff": {"tier": "NU"},
    "zubat": {"tier": "LC"},
    "golbat": {"tier": "NU"},
    "crobat": {"tier": "UUBL"},
    "oddish": {"tier": "LC"},
    "gloom": {"tier": "NFE"},
    "vileplume": {"tier": "NU"},
    "bellossom": {"tier": "NU"},
    "paras": {"tier": "LC"},
    "parasect": {"tier": "NU"},
    "venonat": {"tier": "LC"},
    "venomoth": {"tier": "NU"},
    "diglett": {"tier": "NU"},
    "dugtrio": {"tier": "UU"},
    "meowth": {"tier": "LC"},
    "persian": {"tier": "NU"},
    "psyduck": {"tier": "LC"},
    "golduck": {"tier": "NU"},
    "mankey": {"tier": "LC"},
    "primeape": {"tier": "UU"},
    "growlithe": {"tier": "LC"},
    "arcanine": {"tier": "UU"},
    "poliwag": {"tier": "LC"},
    "poliwhirl": {"tier": "NFE"},
    "poliwrath": {"tier": "NU"},
    "politoed": {"tier": "NU"},
    "abra": {"tier": "LC"},
    "kadabra": {"tier": "NU"},
    "alakazam": {"tier": "UU"},
    "machop": {"tier": "LC"},
    "machoke": {"tier": "NU"},
    "machamp": {"tier": "OU"},
    "bellsprout": {"tier": "LC"},
    "weepinbell": {"tier": "NFE"},
    "victreebel": {"tier": "NU"},
    "tentacool": {"tier": "LC"},
    "tentacruel": {"tier": "(OU)"},
    "geodude": {"tier": "LC"},
    "graveler": {"tier": "NFE"},
    "golem": {"tier": "NU"},
    "ponyta": {"tier": "LC"},
    "rapidash": {"tier": "NU"},
    "slowpoke": {"tier": "LC"},
    "slowbro": {"tier": "UU"},
    "slowking": {"tier": "NU"},
    "magnemite": {"tier": "LC"},
    "magneton": {"tier": "NU"},
    "magnezone": {"tier": "OU"},
    "farfetchd": {"tier": "NU"},
    "doduo": {"tier": "LC"},
    "dodrio": {"tier": "NU"},
    "seel": {"tier": "LC"},
    "dewgong": {"tier": "NU"},
    "grimer": {"tier": "LC"},
    "muk": {"tier": "NU"},
    "shellder": {"tier": "LC"},
    "cloyster": {"tier": "UU"},
    "gastly": {"tier": "LC"},
    "haunter": {"tier": "NU"},
    "gengar": {"tier": "OU"},
    "onix": {"tier": "LC"},
    "steelix": {"tier": "UU"},
    "drowzee": {"tier": "LC"},
    "hypno": {"tier": "NU"},
    "krabby": {"tier": "LC"},
    "kingler": {"tier": "NU"},
    "voltorb": {"tier": "LC"},
    "electrode": {"tier": "NU"},
    "exeggcute": {"tier": "LC"},
    "exeggutor": {"tier": "UU"},
    "cubone": {"tier": "LC"},
    "marowak": {"tier": "NU"},
    "tyrogue": {"tier": "LC"},
    "hitmonlee": {"tier": "UU"},
    "hitmonchan": {"tier": "NU"},
    "hitmontop": {"tier": "UU"},
    "lickitung": {"tier": "LC"},
    "lickilicky": {"tier": "NU"},
    "koffing": {"tier": "LC"},
    "weezing": {"tier": "UU"},
    "rhyhorn": {"tier": "LC"},
    "rhydon": {"tier": "NFE"},
    "rhyperior": {"tier": "UU"},
    "happiny": {"tier": "LC"},
    "chansey": {"tier": "UU"},
    "blissey": {"tier": "OU"},
    "tangela": {"tier": "NU"},
    "tangrowth": {"tier": "UU"},
    "kangaskhan": {"tier": "UU"},
    "horsea": {"tier": "LC"},
    "seadra": {"tier": "NFE"},
    "kingdra": {"tier": "OU"},
    "goldeen": {"tier": "LC"},
    "seaking": {"tier": "NU"},
    "staryu": {"tier": "LC"},
    "starmie": {"tier": "OU"},
    "mimejr": {"tier": "LC"},
    "mrmime": {"tier": "NU"},
    "scyther": {"tier": "UU"},
    "scizor": {"tier": "OU"},
    "smoochum": {"tier": "LC"},
    "jynx": {"tier": "NU"},
    "elekid": {"tier": "LC"},
    "electabuzz": {"tier": "NU"},
    "electivire": {"tier": "(OU)"},
    "magby": {"tier": "LC"},
    "magmar": {"tier": "NFE"},
    "magmortar": {"tier": "NU"},
    "pinsir": {"tier": "NU"},
    "tauros": {"tier": "NU"},
    "magikarp": {"tier": "LC"},
    "gyarados": {"tier": "OU"},
    "lapras": {"tier": "NU"},
    "ditto": {"tier": "NU"},
    "eevee": {"tier": "LC"},
    "vaporeon": {"tier": "(OU)"},
    "jolteon": {"tier": "(OU)"},
    "flareon": {"tier": "NU"},
    "espeon": {"tier": "NUBL"},
    "umbreon": {"tier": "(OU)"},
    "leafeon": {"tier": "UU"},
    "glaceon": {"tier": "NU"},
    "porygon": {"tier": "LC"},
    "porygon2": {"tier": "NU"},
    "porygonz": {"tier": "UUBL"},
    "omanyte": {"tier": "LC"},
    "omastar": {"tier": "UU"},
    "kabuto": {"tier": "LC"},
    "kabutops": {"tier": "UU"},
    "aerodactyl": {"tier": "OU"},
    "munchlax": {"tier": "LC"},
    "snorlax": {"tier": "(OU)"},
    "articuno": {"tier": "NU"},
    "zapdos": {"tier": "OU"},
    "moltres": {"tier": "UU"},
    "dratini": {"tier": "LC"},
    "dragonair": {"tier": "NU"},
    "dragonite": {"tier": "OU"},
    "mewtwo": {"tier": "Uber"},
    "mew": {"tier": "Uber"},
    "chikorita": {"tier": "LC"},
    "bayleef": {"tier": "NFE"},
    "meganium": {"tier": "NU"},
    "cyndaquil": {"tier": "LC"},
    "quilava": {"tier": "NFE"},
    "typhlosion": {"tier": "NU"},
    "totodile": {"tier": "LC"},
    "croconaw": {"tier": "NFE"},
    "feraligatr": {"tier": "UU"},
    "sentret": {"tier": "LC"},
    "furret": {"tier": "NU"},
    "hoothoot": {"tier": "LC"},
    "noctowl": {"tier": "NU"},
    "ledyba": {"tier": "LC"},
    "ledian": {"tier": "NU"},
    "spinarak": {"tier": "LC"},
    "ariados": {"tier": "NU"},
    "chinchou": {"tier": "LC"},
    "lanturn": {"tier": "UU"},
    "togepi": {"tier": "LC"},
    "togetic": {"tier": "NU"},
    "togekiss": {"tier": "(OU)"},
    "natu": {"tier": "LC"},
    "xatu": {"tier": "NU"},
    "mareep": {"tier": "LC"},
    "flaaffy": {"tier": "NFE"},
    "ampharos": {"tier": "NU"},
    "azurill": {"tier": "LC"},
    "marill": {"tier": "NFE"},
    "azumarill": {"tier": "UU"},
    "bonsly": {"tier": "LC"},
    "sudowoodo": {"tier": "NU"},
    "hoppip": {"tier": "LC"},
    "skiploom": {"tier": "NFE"},
    "jumpluff": {"tier": "NU"},
    "aipom": {"tier": "LC"},
    "ambipom": {"tier": "UU"},
    "sunkern": {"tier": "LC"},
    "sunflora": {"tier": "NU"},
    "yanma": {"tier": "NU"},
    "yanmega": {"tier": "UUBL"},
    "wooper": {"tier": "LC"},
    "quagsire": {"tier": "NU"},
    "murkrow": {"tier": "NU"},
    "honchkrow": {"tier": "UUBL"},
    "misdreavus": {"tier": "NU"},
    "mismagius": {"tier": "UU"},
    "unown": {"tier": "NU"},
    "wynaut": {"tier": "Uber"},
    "wobbuffet": {"tier": "Uber"},
    "girafarig": {"tier": "NU"},
    "pineco": {"tier": "LC"},
    "forretress": {"tier": "OU"},
    "dunsparce": {"tier": "NU"},
    "gligar": {"tier": "NU", "doublesTier": "LC"},
    "gliscor": {"tier": "OU"},
    "snubbull": {"tier": "LC"},
    "granbull": {"tier": "NU"},
    "qwilfish": {"tier": "UU"},
    "shuckle": {"tier": "NU"},
    "heracross": {"tier": "UUBL"},
    "sneasel": {"tier": "NU"},
    "weavile": {"tier": "OU"},
    "teddiursa": {"tier": "LC"},
    "ursaring": {"tier": "UU"},
    "slugma": {"tier": "LC"},
    "magcargo": {"tier": "NU"},
    "swinub": {"tier": "LC"},
    "piloswine": {"tier": "NU"},
    "mamoswine": {"tier": "(OU)"},
    "corsola": {"tier": "NU"},
    "remoraid": {"tier": "LC"},
    "octillery": {"tier": "NU"},
    "delibird": {"tier": "NU"},
    "mantyke": {"tier": "LC"},
    "mantine": {"tier": "NU"},
    "skarmory": {"tier": "OU"},
    "houndour": {"tier": "LC"},
    "houndoom": {"tier": "UU"},
    "phanpy": {"tier": "LC"},
    "donphan": {"tier": "UU"},
    "stantler": {"tier": "NU"},
    "smeargle": {"tier": "(OU)"},
    "miltank": {"tier": "UU"},
    "raikou": {"tier": "UUBL"},
    "entei": {"tier": "NUBL"},
    "suicune": {"tier": "OU"},
    "larvitar": {"tier": "LC"},
    "pupitar": {"tier": "NFE"},
    "tyranitar": {"tier": "OU"},
    "lugia": {"tier": "Uber"},
    "hooh": {"tier": "Uber"},
    "celebi": {"tier": "OU"},
    "treecko": {"tier": "LC"},
    "grovyle": {"tier": "NFE"},
    "sceptile": {"tier": "UU"},
    "torchic": {"tier": "LC"},
    "combusken": {"tier": "NFE"},
    "blaziken": {"tier": "UU"},
    "mudkip": {"tier": "LC"},
    "marshtomp": {"tier": "NFE"},
    "swampert": {"tier": "OU"},
    "poochyena": {"tier": "LC"},
    "mightyena": {"tier": "NU"},
    "zigzagoon": {"tier": "LC"},
    "linoone": {"tier": "NU"},
    "wurmple": {"tier": "LC"},
    "silcoon": {"tier": "NFE"},
    "beautifly": {"tier": "NU"},
    "cascoon": {"tier": "NFE"},
    "dustox": {"tier": "NU"},
    "lotad": {"tier": "LC"},
    "lombre": {"tier": "NFE"},
    "ludicolo": {"tier": "UU"},
    "seedot": {"tier": "LC"},
    "nuzleaf": {"tier": "NFE"},
    "shiftry": {"tier": "NU"},
    "taillow": {"tier": "LC"},
    "swellow": {"tier": "UU"},
    "wingull": {"tier": "LC"},
    "pelipper": {"tier": "NU"},
    "ralts": {"tier": "LC"},
    "kirlia": {"tier": "NFE"},
    "gardevoir": {"tier": "NU"},
    "gallade": {"tier": "UUBL"},
    "surskit": {"tier": "LC"},
    "masquerain": {"tier": "NU"},
    "shroomish": {"tier": "LC"},
    "breloom": {"tier": "OU"},
    "slakoth": {"tier": "LC"},
    "vigoroth": {"tier": "NU"},
    "slaking": {"tier": "NU"},
    "nincada": {"tier": "LC"},
    "ninjask": {"tier": "(OU)"},
    "shedinja": {"tier": "NU"},
    "whismur": {"tier": "LC"},
    "loudred": {"tier": "NFE"},
    "exploud": {"tier": "NU"},
    "makuhita": {"tier": "LC"},
    "hariyama": {"tier": "UU"},
    "nosepass": {"tier": "LC"},
    "probopass": {"tier": "NU"},
    "skitty": {"tier": "LC"},
    "delcatty": {"tier": "NU"},
    "sableye": {"tier": "NU"},
    "mawile": {"tier": "NU"},
    "aron": {"tier": "LC"},
    "lairon": {"tier": "NFE"},
    "aggron": {"tier": "UU"},
    "meditite": {"tier": "NFE"},
    "medicham": {"tier": "NU"},
    "electrike": {"tier": "LC"},
    "manectric": {"tier": "NU"},
    "plusle": {"tier": "NU"},
    "minun": {"tier": "NU"},
    "volbeat": {"tier": "NU"},
    "illumise": {"tier": "NU"},
    "budew": {"tier": "LC"},
    "roselia": {"tier": "NU"},
    "roserade": {"tier": "OU"},
    "gulpin": {"tier": "LC"},
    "swalot": {"tier": "NU"},
    "carvanha": {"tier": "LC"},
    "sharpedo": {"tier": "NU"},
    "wailmer": {"tier": "LC"},
    "wailord": {"tier": "NU"},
    "numel": {"tier": "LC"},
    "camerupt": {"tier": "NU"},
    "torkoal": {"tier": "NU"},
    "spoink": {"tier": "LC"},
    "grumpig": {"tier": "NU"},
    "spinda": {"tier": "NU"},
    "trapinch": {"tier": "LC"},
    "vibrava": {"tier": "NFE"},
    "flygon": {"tier": "OU"},
    "cacnea": {"tier": "LC"},
    "cacturne": {"tier": "NU"},
    "swablu": {"tier": "LC"},
    "altaria": {"tier": "UU"},
    "zangoose": {"tier": "NU"},
    "seviper": {"tier": "NU"},
    "lunatone": {"tier": "NU"},
    "solrock": {"tier": "NU"},
    "barboach": {"tier": "LC"},
    "whiscash": {"tier": "NU"},
    "corphish": {"tier": "LC"},
    "crawdaunt": {"tier": "NU"},
    "baltoy": {"tier": "LC"},
    "claydol": {"tier": "UU"},
    "lileep": {"tier": "LC"},
    "cradily": {"tier": "NU"},
    "anorith": {"tier": "LC"},
    "armaldo": {"tier": "NU"},
    "feebas": {"tier": "LC"},
    "milotic": {"tier": "UU"},
    "castform": {"tier": "NU"},
    "castformsunny": {},
    "castformrainy": {},
    "castformsnowy": {},
    "kecleon": {"tier": "NU"},
    "shuppet": {"tier": "LC"},
    "banette": {"tier": "NU"},
    "duskull": {"tier": "LC"},
    "dusclops": {"tier": "NU"},
    "dusknoir": {"tier": "(OU)"},
    "tropius": {"tier": "NU"},
    "chingling": {"tier": "LC"},
    "chimecho": {"tier": "NU"},
    "absol": {"tier": "UU"},
    "snorunt": {"tier": "LC"},
    "glalie": {"tier": "NU"},
    "froslass": {"tier": "UUBL"},
    "spheal": {"tier": "LC"},
    "sealeo": {"tier": "NFE"},
    "walrein": {"tier": "NU"},
    "clamperl": {"tier": "NU"},
    "huntail": {"tier": "NU"},
    "gorebyss": {"tier": "NU"},
    "relicanth": {"tier": "NU"},
    "luvdisc": {"tier": "NU"},
    "bagon": {"tier": "LC"},
    "shelgon": {"tier": "NU"},
    "salamence": {"tier": "Uber"},
    "beldum": {"tier": "LC"},
    "metang": {"tier": "NU"},
    "metagross": {"tier": "OU"},
    "regirock": {"tier": "NU"},
    "regice": {"tier": "NU"},
    "registeel": {"tier": "UU"},
    "latias": {"tier": "OU"},
    "latios": {"tier": "Uber"},
    "kyogre": {"tier": "Uber"},
    "groudon": {"tier": "Uber"},
    "rayquaza": {"tier": "Uber"},
    "jirachi": {"tier": "OU"},
    "deoxys": {"tier": "Uber"},
    "deoxysattack": {"tier": "Uber"},
    "deoxysdefense": {"tier": "Uber"},
    "deoxysspeed": {"tier": "Uber"},
    "turtwig": {"tier": "LC"},
    "grotle": {"tier": "NFE"},
    "torterra": {"tier": "UU"},
    "chimchar": {"tier": "LC"},
    "monferno": {"tier": "NU"},
    "infernape": {"tier": "OU"},
    "piplup": {"tier": "LC"},
    "prinplup": {"tier": "NFE"},
    "empoleon": {"tier": "OU"},
    "starly": {"tier": "LC"},
    "staravia": {"tier": "NFE"},
    "staraptor": {"tier": "UUBL"},
    "bidoof": {"tier": "LC"},
    "bibarel": {"tier": "NU"},
    "kricketot": {"tier": "LC"},
    "kricketune": {"tier": "NU"},
    "shinx": {"tier": "LC"},
    "luxio": {"tier": "NFE"},
    "luxray": {"tier": "NU"},
    "cranidos": {"tier": "LC"},
    "rampardos": {"tier": "NU"},
    "shieldon": {"tier": "LC"},
    "bastiodon": {"tier": "NU"},
    "burmy": {"tier": "LC"},
    "wormadam": {"tier": "NU"},
    "wormadamsandy": {"tier": "NU"},
    "wormadamtrash": {"tier": "NU"},
    "mothim": {"tier": "NU"},
    "combee": {"tier": "LC"},
    "vespiquen": {"tier": "NU"},
    "pachirisu": {"tier": "NU"},
    "buizel": {"tier": "LC"},
    "floatzel": {"tier": "NU"},
    "cherubi": {"tier": "LC"},
    "cherrim": {"tier": "NU"},
    "cherrimsunshine": {},
    "shellos": {"tier": "LC"},
    "gastrodon": {"tier": "NU"},
    "drifloon": {"tier": "LC"},
    "drifblim": {"tier": "NU"},
    "buneary": {"tier": "LC"},
    "lopunny": {"tier": "NU"},
    "glameow": {"tier": "LC"},
    "purugly": {"tier": "NU"},
    "stunky": {"tier": "LC"},
    "skuntank": {"tier": "NU"},
    "bronzor": {"tier": "LC"},
    "bronzong": {"tier": "OU"},
    "chatot": {"tier": "NU"},
    "spiritomb": {"tier": "UU"},
    "gible": {"tier": "LC"},
    "gabite": {"tier": "NU"},
    "garchomp": {"tier": "Uber"},
    "riolu": {"tier": "LC"},
    "lucario": {"tier": "OU"},
    "hippopotas": {"tier": "NUBL"},
    "hippowdon": {"tier": "OU"},
    "skorupi": {"tier": "LC"},
    "drapion": {"tier": "UU"},
    "croagunk": {"tier": "LC"},
    "toxicroak": {"tier": "UU"},
    "carnivine": {"tier": "NU"},
    "finneon": {"tier": "LC"},
    "lumineon": {"tier": "NU"},
    "snover": {"tier": "NUBL"},
    "abomasnow": {"tier": "UUBL"},
    "rotom": {"tier": "UU"},
    "rotomheat": {"tier": "OU"},
    "rotomwash": {"tier": "OU"},
    "rotomfrost": {"tier": "OU"},
    "rotomfan": {"tier": "OU"},
    "rotommow": {"tier": "OU"},
    "uxie": {"tier": "UU"},
    "mesprit": {"tier": "UU"},
    "azelf": {"tier": "OU"},
    "dialga": {"tier": "Uber"},
    "palkia": {"tier": "Uber"},
    "heatran": {"tier": "OU"},
    "regigigas": {"tier": "NU"},
    "giratina": {"tier": "Uber"},
    "giratinaorigin": {},
    "cresselia": {"tier": "UUBL"},
    "phione": {"tier": "NU"},
    "manaphy": {"tier": "Uber"},
    "darkrai": {"tier": "Uber"},
    "shaymin": {"tier": "(OU)"},
    "shayminsky": {"tier": "Uber"},
    "arceus": {"tier": "AG"},
    "arceusbug": {},
    "arceusdark": {},
    "arceusdragon": {},
    "arceuselectric": {},
    "arceusfighting": {},
    "arceusfire": {},
    "arceusflying": {},
    "arceusghost": {},
    "arceusgrass": {},
    "arceusground": {},
    "arceusice": {},
    "arceuspoison": {},
    "arceuspsychic": {},
    "arceusrock": {},
    "arceussteel": {},
    "arceuswater": {}
  },
  "5": {
    "bulbasaur": {"tier": "LC"},
    "ivysaur": {"tier": "NFE"},
    "venusaur": {"tier": "(OU)", "doublesTier": "DOU"},
    "charmander": {"tier": "LC"},
    "charmeleon": {"tier": "NFE"},
    "charizard": {"tier": "NU", "doublesTier": "DOU"},
    "squirtle": {"tier": "LC"},
    "wartortle": {"tier": "NU", "doublesTier": "NFE"},
    "blastoise": {"tier": "UU", "doublesTier": "DUU"},
    "caterpie": {"tier": "LC"},
    "metapod": {"tier": "NFE"},
    "butterfree": {"tier": "ZU", "doublesTier": "DUU"},
    "weedle": {"tier": "LC"},
    "kakuna": {"tier": "NFE"},
    "beedrill": {"tier": "ZU", "doublesTier": "DUU"},
    "pidgey": {"tier": "LC"},
    "pidgeotto": {"tier": "NFE"},
    "pidgeot": {"tier": "ZU", "doublesTier": "DUU"},
    "rattata": {"tier": "LC"},
    "raticate": {"tier": "ZU", "doublesTier": "DUU"},
    "spearow": {"tier": "LC"},
    "fearow": {"tier": "ZU", "doublesTier": "DUU"},
    "ekans": {"tier": "LC"},
    "arbok": {"tier": "ZU", "doublesTier": "DUU"},
    "pichu": {"tier": "LC"},
    "pikachu": {"tier": "NFE"},
    "raichu": {"tier": "ZU", "doublesTier": "DUU"},
    "sandshrew": {"tier": "LC"},
    "sandslash": {"tier": "RU", "doublesTier": "DUU"},
    "nidoranf": {"tier": "LC"},
    "nidorina": {"tier": "NFE"},
    "nidoqueen": {"tier": "UU", "doublesTier": "DUU"},
    "nidoranm": {"tier": "LC"},
    "nidorino": {"tier": "NFE"},
    "nidoking": {"tier": "UU", "doublesTier": "DUU"},
    "cleffa": {"tier": "LC"},
    "clefairy": {"tier": "NFE"},
    "clefable": {"tier": "RU", "doublesTier": "DUU"},
    "vulpix": {"tier": "NFE"},
    "ninetales": {"tier": "OU", "doublesTier": "DOU"},
    "igglybuff": {"tier": "LC"},
    "jigglypuff": {"tier": "NFE"},
    "wigglytuff": {"tier": "ZU", "doublesTier": "DUU"},
    "zubat": {"tier": "LC"},
    "golbat": {"tier": "NU", "doublesTier": "NFE"},
    "crobat": {"tier": "UU", "doublesTier": "DUU"},
    "oddish": {"tier": "LC"},
    "gloom": {"tier": "NFE"},
    "vileplume": {"tier": "PU", "doublesTier": "DUU"},
    "bellossom": {"tier": "ZU", "doublesTier": "DUU"},
    "paras": {"tier": "LC"},
    "parasect": {"tier": "ZU", "doublesTier": "DUU"},
    "venonat": {"tier": "LC"},
    "venomoth": {"tier": "RUBL", "doublesTier": "DUU"},
    "diglett": {"tier": "LC"},
    "dugtrio": {"tier": "(OU)", "doublesTier": "DUU"},
    "meowth": {"tier": "LC"},
    "persian": {"tier": "ZU", "doublesTier": "DUU"},
    "psyduck": {"tier": "LC"},
    "golduck": {"tier": "PU", "doublesTier": "DUU"},
    "mankey": {"tier": "LC"},
    "primeape": {"tier": "NU", "doublesTier": "DUU"},
    "growlithe": {"tier": "LC"},
    "arcanine": {"tier": "UU", "doublesTier": "DOU"},
    "poliwag": {"tier": "LC"},
    "poliwhirl": {"tier": "NFE"},
    "poliwrath": {"tier": "RU", "doublesTier": "DUU"},
    "politoed": {"tier": "OU", "doublesTier": "DOU"},
    "abra": {"tier": "LC"},
    "kadabra": {"tier": "NU", "doublesTier": "NFE"},
    "alakazam": {"tier": "OU", "doublesTier": "DUU"},
    "machop": {"tier": "LC"},
    "machoke": {"tier": "ZUBL", "doublesTier": "NFE"},
    "machamp": {"tier": "UU", "doublesTier": "DUU"},
    "bellsprout": {"tier": "LC"},
    "weepinbell": {"tier": "NFE"},
    "victreebel": {"tier": "PU", "doublesTier": "DUU"},
    "tentacool": {"tier": "PU", "doublesTier": "LC"},
    "tentacruel": {"tier": "OU", "doublesTier": "DUU"},
    "geodude": {"tier": "LC"},
    "graveler": {"tier": "NFE"},
    "golem": {"tier": "NU", "doublesTier": "DUU"},
    "ponyta": {"tier": "LC"},
    "rapidash": {"tier": "PU", "doublesTier": "DUU"},
    "slowpoke": {"tier": "LC"},
    "slowbro": {"tier": "UU", "doublesTier": "DUU"},
    "slowking": {"tier": "RU", "doublesTier": "DUU"},
    "magnemite": {"tier": "LC"},
    "magneton": {"tier": "RU", "doublesTier": "NFE"},
    "magnezone": {"tier": "OU", "doublesTier": "DUU"},
    "farfetchd": {"tier": "ZU", "doublesTier": "DUU"},
    "doduo": {"tier": "LC"},
    "dodrio": {"tier": "PU", "doublesTier": "DUU"},
    "seel": {"tier": "LC"},
    "dewgong": {"tier": "ZU", "doublesTier": "DUU"},
    "grimer": {"tier": "LC"},
    "muk": {"tier": "ZU", "doublesTier": "DUU"},
    "shellder": {"tier": "LC"},
    "cloyster": {"tier": "OU", "doublesTier": "DOU"},
    "gastly": {"tier": "LC"},
    "haunter": {"tier": "NU", "doublesTier": "NFE"},
    "gengar": {"tier": "OU", "doublesTier": "DOU"},
    "onix": {"tier": "LC"},
    "steelix": {"tier": "RU", "doublesTier": "DUU"},
    "drowzee": {"tier": "LC"},
    "hypno": {"tier": "ZU", "doublesTier": "DUU"},
    "krabby": {"tier": "LC"},
    "kingler": {"tier": "ZU", "doublesTier": "DUU"},
    "voltorb": {"tier": "LC"},
    "electrode": {"tier": "NU", "doublesTier": "DUU"},
    "exeggcute": {"tier": "LC"},
    "exeggutor": {"tier": "NU", "doublesTier": "DUU"},
    "cubone": {"tier": "LC"},
    "marowak": {"tier": "ZUBL", "doublesTier": "DUU"},
    "tyrogue": {"tier": "LC"},
    "hitmonlee": {"tier": "RU", "doublesTier": "DUU"},
    "hitmonchan": {"tier": "RU", "doublesTier": "DUU"},
    "hitmontop": {"tier": "UU", "doublesTier": "DOU"},
    "lickitung": {"tier": "LC"},
    "lickilicky": {"tier": "NU", "doublesTier": "DUU"},
    "koffing": {"tier": "LC"},
    "weezing": {"tier": "NU", "doublesTier": "DUU"},
    "rhyhorn": {"tier": "LC"},
    "rhydon": {"tier": "RU", "doublesTier": "NFE"},
    "rhyperior": {"tier": "UU", "doublesTier": "DUU"},
    "happiny": {"tier": "LC"},
    "chansey": {"tier": "UUBL", "doublesTier": "NFE"},
    "blissey": {"tier": "(OU)", "doublesTier": "DUU"},
    "tangela": {"tier": "NU", "doublesTier": "NFE"},
    "tangrowth": {"tier": "RU", "doublesTier": "DUU"},
    "kangaskhan": {"tier": "NU", "doublesTier": "DUU"},
    "horsea": {"tier": "LC"},
    "seadra": {"tier": "NFE"},
    "kingdra": {"tier": "UU", "doublesTier": "DOU"},
    "goldeen": {"tier": "LC"},
    "seaking": {"tier": "ZU", "doublesTier": "DUU"},
    "staryu": {"tier": "LC"},
    "starmie": {"tier": "OU", "doublesTier": "DUU"},
    "mimejr": {"tier": "LC"},
    "mrmime": {"tier": "ZU", "doublesTier": "DUU"},
    "scyther": {"tier": "RU", "doublesTier": "NFE"},
    "scizor": {"tier": "OU", "doublesTier": "DOU"},
    "smoochum": {"tier": "LC"},
    "jynx": {"tier": "NUBL", "doublesTier": "DUU"},
    "elekid": {"tier": "LC"},
    "electabuzz": {"tier": "NU", "doublesTier": "NFE"},
    "electivire": {"tier": "RU", "doublesTier": "DOU"},
    "magby": {"tier": "LC"},
    "magmar": {"tier": "NFE"},
    "magmortar": {"tier": "RU", "doublesTier": "DUU"},
    "pinsir": {"tier": "NU", "doublesTier": "DUU"},
    "tauros": {"tier": "NU", "doublesTier": "DUU"},
    "magikarp": {"tier": "LC"},
    "gyarados": {"tier": "OU", "doublesTier": "DOU"},
    "lapras": {"tier": "NU", "doublesTier": "DUU"},
    "ditto": {"tier": "NU", "doublesTier": "DUU"},
    "eevee": {"tier": "LC"},
    "vaporeon": {"tier": "(OU)", "doublesTier": "DOU"},
    "jolteon": {"tier": "(OU)", "doublesTier": "DOU"},
    "flareon": {"tier": "ZU", "doublesTier": "DUU"},
    "espeon": {"tier": "(OU)", "doublesTier": "DOU"},
    "umbreon": {"tier": "UU", "doublesTier": "DUU"},
    "leafeon": {"tier": "ZU", "doublesTier": "DUU"},
    "glaceon": {"tier": "ZU", "doublesTier": "DUU"},
    "porygon": {"tier": "LC"},
    "porygon2": {"tier": "UU", "doublesTier": "NFE"},
    "porygonz": {"tier": "UU", "doublesTier": "DUU"},
    "omanyte": {"tier": "ZUBL", "doublesTier": "LC"},
    "omastar": {"tier": "RU", "doublesTier": "DUU"},
    "kabuto": {"tier": "LC"},
    "kabutops": {"tier": "RU", "doublesTier": "DUU"},
    "aerodactyl": {"tier": "RU", "doublesTier": "DUU"},
    "munchlax": {"tier": "LC"},
    "snorlax": {"tier": "UU", "doublesTier": "DUU"},
    "articuno": {"tier": "ZUBL", "doublesTier": "DUU"},
    "zapdos": {"tier": "UU", "doublesTier": "DOU"},
    "moltres": {"tier": "RU", "doublesTier": "DUU"},
    "dratini": {"tier": "LC"},
    "dragonair": {"tier": "ZUBL", "doublesTier": "NFE"},
    "dragonite": {"tier": "OU", "doublesTier": "DOU"},
    "mewtwo": {"tier": "Uber", "doublesTier": "DUber"},
    "mew": {"tier": "UU", "doublesTier": "DUU"},
    "chikorita": {"tier": "LC"},
    "bayleef": {"tier": "NFE"},
    "meganium": {"tier": "ZU", "doublesTier": "DUU"},
    "cyndaquil": {"tier": "LC"},
    "quilava": {"tier": "NFE"},
    "typhlosion": {"tier": "RU", "doublesTier": "DUU"},
    "totodile": {"tier": "LC"},
    "croconaw": {"tier": "NFE"},
    "feraligatr": {"tier": "RU", "doublesTier": "DUU"},
    "sentret": {"tier": "LC"},
    "furret": {"tier": "ZU", "doublesTier": "DUU"},
    "hoothoot": {"tier": "LC"},
    "noctowl": {"tier": "ZU", "doublesTier": "DUU"},
    "ledyba": {"tier": "LC"},
    "ledian": {"tier": "ZU", "doublesTier": "DUU"},
    "spinarak": {"tier": "LC"},
    "ariados": {"tier": "ZU", "doublesTier": "DUU"},
    "chinchou": {"tier": "LC"},
    "lanturn": {"tier": "RU", "doublesTier": "DUU"},
    "togepi": {"tier": "LC"},
    "togetic": {"tier": "NFE"},
    "togekiss": {"tier": "UU", "doublesTier": "DOU"},
    "natu": {"tier": "PU", "doublesTier": "LC"},
    "xatu": {"tier": "UU", "doublesTier": "DUU"},
    "mareep": {"tier": "LC"},
    "flaaffy": {"tier": "NFE"},
    "ampharos": {"tier": "NU", "doublesTier": "DUU"},
    "azurill": {"tier": "LC"},
    "marill": {"tier": "NFE"},
    "azumarill": {"tier": "UU", "doublesTier": "DUU"},
    "bonsly": {"tier": "LC"},
    "sudowoodo": {"tier": "ZU", "doublesTier": "DUU"},
    "hoppip": {"tier": "LC"},
    "skiploom": {"tier": "NFE"},
    "jumpluff": {"tier": "PU", "doublesTier": "DUU"},
    "aipom": {"tier": "LC"},
    "ambipom": {"tier": "UU", "doublesTier": "DUU"},
    "sunkern": {"tier": "LC"},
    "sunflora": {"tier": "ZU", "doublesTier": "DUU"},
    "yanma": {"tier": "NFE"},
    "yanmega": {"tier": "UU", "doublesTier": "DUU"},
    "wooper": {"tier": "LC"},
    "quagsire": {"tier": "RU", "doublesTier": "DUU"},
    "murkrow": {"tier": "PU", "doublesTier": "NFE"},
    "honchkrow": {"tier": "UU", "doublesTier": "DUU"},
    "misdreavus": {"tier": "NU", "doublesTier": "NFE"},
    "mismagius": {"tier": "UU", "doublesTier": "DUU"},
    "unown": {"tier": "ZU", "doublesTier": "DUU"},
    "wynaut": {"tier": "LC"},
    "wobbuffet": {"tier": "UUBL", "doublesTier": "DUU"},
    "girafarig": {"tier": "ZU", "doublesTier": "DUU"},
    "pineco": {"tier": "LC"},
    "forretress": {"tier": "OU", "doublesTier": "DUU"},
    "dunsparce": {"tier": "ZU", "doublesTier": "DUU"},
    "gligar": {"tier": "UU", "doublesTier": "NFE"},
    "gliscor": {"tier": "OU", "doublesTier": "DOU"},
    "snubbull": {"tier": "LC"},
    "granbull": {"tier": "ZU", "doublesTier": "DUU"},
    "qwilfish": {"tier": "RU", "doublesTier": "DUU"},
    "shuckle": {"tier": "ZU", "doublesTier": "DUU"},
    "heracross": {"tier": "UU", "doublesTier": "DUU"},
    "sneasel": {"tier": "PU", "doublesTier": "NFE"},
    "weavile": {"tier": "UU", "doublesTier": "DOU"},
    "teddiursa": {"tier": "LC"},
    "ursaring": {"tier": "PU", "doublesTier": "DUU"},
    "slugma": {"tier": "LC"},
    "magcargo": {"tier": "ZU", "doublesTier": "DUU"},
    "swinub": {"tier": "LC"},
    "piloswine": {"tier": "NU", "doublesTier": "NFE"},
    "mamoswine": {"tier": "OU", "doublesTier": "DOU"},
    "corsola": {"tier": "ZU", "doublesTier": "DUU"},
    "remoraid": {"tier": "LC"},
    "octillery": {"tier": "ZU", "doublesTier": "DUU"},
    "delibird": {"tier": "ZU", "doublesTier": "DUU"},
    "mantyke": {"tier": "LC"},
    "mantine": {"tier": "PU", "doublesTier": "DUU"},
    "skarmory": {"tier": "OU", "doublesTier": "DUU"},
    "houndour": {"tier": "LC"},
    "houndoom": {"tier": "UU", "doublesTier": "DUU"},
    "phanpy": {"tier": "LC"},
    "donphan": {"tier": "(OU)", "doublesTier": "DUU"},
    "stantler": {"tier": "ZU", "doublesTier": "DUU"},
    "smeargle": {"tier": "RU", "doublesTier": "DUU"},
    "miltank": {"tier": "NU", "doublesTier": "DUU"},
    "raikou": {"tier": "UU", "doublesTier": "DUU"},
    "entei": {"tier": "RU", "doublesTier": "DUU"},
    "suicune": {"tier": "UU", "doublesTier": "DUU"},
    "larvitar": {"tier": "LC"},
    "pupitar": {"tier": "NFE"},
    "tyranitar": {"tier": "OU", "doublesTier": "DOU"},
    "lugia": {"tier": "Uber", "doublesTier": "DUber"},
    "hooh": {"tier": "Uber", "doublesTier": "DUber"},
    "celebi": {"tier": "OU", "doublesTier": "DUU"},
    "treecko": {"tier": "LC"},
    "grovyle": {"tier": "NFE"},
    "sceptile": {"tier": "RU", "doublesTier": "DUU"},
    "torchic": {"tier": "LC"},
    "combusken": {"tier": "PUBL", "doublesTier": "DUU"},
    "blaziken": {"tier": "Uber", "doublesTier": "DOU"},
    "mudkip": {"tier": "LC"},
    "marshtomp": {"tier": "NFE"},
    "swampert": {"tier": "UU", "doublesTier": "DUU"},
    "poochyena": {"tier": "LC"},
    "mightyena": {"tier": "ZU", "doublesTier": "DUU"},
    "zigzagoon": {"tier": "LC"},
    "linoone": {"tier": "PUBL", "doublesTier": "DUU"},
    "wurmple": {"tier": "LC"},
    "silcoon": {"tier": "NFE"},
    "beautifly": {"tier": "ZU", "doublesTier": "DUU"},
    "cascoon": {"tier": "NFE"},
    "dustox": {"tier": "ZU", "doublesTier": "DUU"},
    "lotad": {"tier": "LC"},
    "lombre": {"tier": "NFE"},
    "ludicolo": {"tier": "NU", "doublesTier": "DOU"},
    "seedot": {"tier": "LC"},
    "nuzleaf": {"tier": "NFE"},
    "shiftry": {"tier": "PU", "doublesTier": "DUU"},
    "taillow": {"tier": "LC"},
    "swellow": {"tier": "NU", "doublesTier": "DUU"},
    "wingull": {"tier": "LC"},
    "pelipper": {"tier": "ZU", "doublesTier": "DUU"},
    "ralts": {"tier": "LC"},
    "kirlia": {"tier": "NFE"},
    "gardevoir": {"tier": "NU", "doublesTier": "DUU"},
    "gallade": {"tier": "RU", "doublesTier": "DUU"},
    "surskit": {"tier": "LC"},
    "masquerain": {"tier": "ZU", "doublesTier": "DUU"},
    "shroomish": {"tier": "LC"},
    "breloom": {"tier": "OU", "doublesTier": "DOU"},
    "slakoth": {"tier": "LC"},
    "vigoroth": {"tier": "PUBL", "doublesTier": "NFE"},
    "slaking": {"tier": "ZU", "doublesTier": "DUU"},
    "nincada": {"tier": "LC"},
    "ninjask": {"tier": "NU", "doublesTier": "DUU"},
    "shedinja": {"tier": "ZU", "doublesTier": "DUU"},
    "whismur": {"tier": "LC"},
    "loudred": {"tier": "NFE"},
    "exploud": {"tier": "ZU", "doublesTier": "DUU"},
    "makuhita": {"tier": "LC"},
    "hariyama": {"tier": "RU", "doublesTier": "DUU"},
    "nosepass": {"tier": "LC"},
    "probopass": {"tier": "NU", "doublesTier": "DUU"},
    "skitty": {"tier": "LC"},
    "delcatty": {"tier": "ZU", "doublesTier": "DUU"},
    "sableye": {"tier": "UU", "doublesTier": "DOU"},
    "mawile": {"tier": "PU", "doublesTier": "DUU"},
    "aron": {"tier": "LC"},
    "lairon": {"tier": "NFE"},
    "aggron": {"tier": "RU", "doublesTier": "DUU"},
    "meditite": {"tier": "NFE"},
    "medicham": {"tier": "RU", "doublesTier": "DUU"},
    "electrike": {"tier": "LC"},
    "manectric": {"tier": "RU", "doublesTier": "DUU"},
    "plusle": {"tier": "ZU", "doublesTier": "DUU"},
    "minun": {"tier": "ZU", "doublesTier": "DUU"},
    "volbeat": {"tier": "PU", "doublesTier": "DUU"},
    "illumise": {"tier": "ZU", "doublesTier": "DUU"},
    "budew": {"tier": "LC"},
    "roselia": {"tier": "NU", "doublesTier": "NFE"},
    "roserade": {"tier": "UU", "doublesTier": "DUU"},
    "gulpin": {"tier": "LC"},
    "swalot": {"tier": "ZU", "doublesTier": "DUU"},
    "carvanha": {"tier": "LC"},
    "sharpedo": {"tier": "UU", "doublesTier": "DUU"},
    "wailmer": {"tier": "LC"},
    "wailord": {"tier": "ZU", "doublesTier": "DUU"},
    "numel": {"tier": "LC"},
    "camerupt": {"tier": "NU", "doublesTier": "DUU"},
    "torkoal": {"tier": "NU", "doublesTier": "DUU"},
    "spoink": {"tier": "LC"},
    "grumpig": {"tier": "ZU", "doublesTier": "DUU"},
    "spinda": {"tier": "ZU", "doublesTier": "DUU"},
    "trapinch": {"tier": "LC"},
    "vibrava": {"tier": "NFE"},
    "flygon": {"tier": "UU", "doublesTier": "DUU"},
    "cacnea": {"tier": "LC"},
    "cacturne": {"tier": "NU", "doublesTier": "DUU"},
    "swablu": {"tier": "LC"},
    "altaria": {"tier": "NU", "doublesTier": "DUU"},
    "zangoose": {"tier": "NU", "doublesTier": "DUU"},
    "seviper": {"tier": "ZU", "doublesTier": "DUU"},
    "lunatone": {"tier": "ZU", "doublesTier": "DUU"},
    "solrock": {"tier": "ZU", "doublesTier": "DUU"},
    "barboach": {"tier": "LC"},
    "whiscash": {"tier": "ZU", "doublesTier": "DUU"},
    "corphish": {"tier": "LC"},
    "crawdaunt": {"tier": "RU", "doublesTier": "DUU"},
    "baltoy": {"tier": "LC"},
    "claydol": {"tier": "UU", "doublesTier": "DUU"},
    "lileep": {"tier": "LC"},
    "cradily": {"tier": "NU", "doublesTier": "DUU"},
    "anorith": {"tier": "LC"},
    "armaldo": {"tier": "NU", "doublesTier": "DUU"},
    "feebas": {"tier": "LC"},
    "milotic": {"tier": "UU", "doublesTier": "DUU"},
    "castform": {"tier": "ZU", "doublesTier": "DUU"},
    "castformsunny": {},
    "castformrainy": {},
    "castformsnowy": {},
    "kecleon": {"tier": "ZU", "doublesTier": "DUU"},
    "shuppet": {"tier": "LC"},
    "banette": {"tier": "PU", "doublesTier": "DUU"},
    "duskull": {"tier": "LC"},
    "dusclops": {"tier": "UU", "doublesTier": "NFE"},
    "dusknoir": {"tier": "RU", "doublesTier": "DUU"},
    "tropius": {"tier": "ZU", "doublesTier": "DUU"},
    "chingling": {"tier": "LC"},
    "chimecho": {"tier": "ZU", "doublesTier": "DUU"},
    "absol": {"tier": "RU", "doublesTier": "DUU"},
    "snorunt": {"tier": "LC"},
    "glalie": {"tier": "ZUBL", "doublesTier": "DUU"},
    "froslass": {"tier": "UUBL", "doublesTier": "DUU"},
    "spheal": {"tier": "LC"},
    "sealeo": {"tier": "NFE"},
    "walrein": {"tier": "ZU", "doublesTier": "DUU"},
    "clamperl": {"tier": "LC"},
    "huntail": {"tier": "PU", "doublesTier": "DUU"},
    "gorebyss": {"tier": "NU", "doublesTier": "DUU"},
    "relicanth": {"tier": "PU", "doublesTier": "DUU"},
    "luvdisc": {"tier": "ZU", "doublesTier": "DUU"},
    "bagon": {"tier": "LC"},
    "shelgon": {"tier": "NFE"},
    "salamence": {"tier": "OU", "doublesTier": "DOU"},
    "beldum": {"tier": "LC"},
    "metang": {"tier": "NU", "doublesTier": "NFE"},
    "metagross": {"tier": "(OU)", "doublesTier": "DOU"},
    "regirock": {"tier": "NU", "doublesTier": "DUU"},
    "regice": {"tier": "NU", "doublesTier": "DUU"},
    "registeel": {"tier": "UU", "doublesTier": "DUU"},
    "latias": {"tier": "OU", "doublesTier": "DUU"},
    "latios": {"tier": "OU", "doublesTier": "DOU"},
    "kyogre": {"tier": "Uber", "doublesTier": "DUber"},
    "groudon": {"tier": "Uber", "doublesTier": "DUber"},
    "rayquaza": {"tier": "Uber", "doublesTier": "DUber"},
    "jirachi": {"tier": "OU", "doublesTier": "DUber"},
    "deoxys": {"tier": "Uber", "doublesTier": "DUU"},
    "deoxysattack": {"tier": "Uber", "doublesTier": "DUU"},
    "deoxysdefense": {"tier": "Uber", "doublesTier": "DUU"},
    "deoxysspeed": {"tier": "Uber", "doublesTier": "DUU"},
    "turtwig": {"tier": "LC"},
    "grotle": {"tier": "NFE"},
    "torterra": {"tier": "PU", "doublesTier": "DUU"},
    "chimchar": {"tier": "LC"},
    "monferno": {"tier": "PU", "doublesTier": "NFE"},
    "infernape": {"tier": "(OU)", "doublesTier": "DOU"},
    "piplup": {"tier": "LC"},
    "prinplup": {"tier": "NFE"},
    "empoleon": {"tier": "UU", "doublesTier": "DUU"},
    "starly": {"tier": "LC"},
    "staravia": {"tier": "NFE"},
    "staraptor": {"tier": "UUBL", "doublesTier": "DUU"},
    "bidoof": {"tier": "LC"},
    "bibarel": {"tier": "ZU", "doublesTier": "DUU"},
    "kricketot": {"tier": "LC"},
    "kricketune": {"tier": "ZU", "doublesTier": "DUU"},
    "shinx": {"tier": "LC"},
    "luxio": {"tier": "NFE"},
    "luxray": {"tier": "ZU", "doublesTier": "DUU"},
    "cranidos": {"tier": "LC"},
    "rampardos": {"tier": "PU", "doublesTier": "DUU"},
    "shieldon": {"tier": "LC"},
    "bastiodon": {"tier": "NU", "doublesTier": "DUU"},
    "burmy": {"tier": "LC"},
    "wormadam": {"tier": "ZU", "doublesTier": "DUU"},
    "wormadamsandy": {"tier": "ZU", "doublesTier": "DUU"},
    "wormadamtrash": {"tier": "ZU", "doublesTier": "DUU"},
    "mothim": {"tier": "ZU", "doublesTier": "DUU"},
    "combee": {"tier": "LC"},
    "vespiquen": {"tier": "ZU", "doublesTier": "DUU"},
    "pachirisu": {"tier": "ZU", "doublesTier": "DUU"},
    "buizel": {"tier": "LC"},
    "floatzel": {"tier": "NU", "doublesTier": "DUU"},
    "cherubi": {"tier": "LC"},
    "cherrim": {"tier": "ZU", "doublesTier": "DUU"},
    "cherrimsunshine": {},
    "shellos": {"tier": "LC"},
    "gastrodon": {"tier": "OU", "doublesTier": "DOU"},
    "drifloon": {"tier": "LC"},
    "drifblim": {"tier": "NU", "doublesTier": "DUU"},
    "buneary": {"tier": "LC"},
    "lopunny": {"tier": "ZU", "doublesTier": "DUU"},
    "glameow": {"tier": "LC"},
    "purugly": {"tier": "PU", "doublesTier": "DUU"},
    "stunky": {"tier": "LC"},
    "skuntank": {"tier": "NU", "doublesTier": "DUU"},
    "bronzor": {"tier": "PU", "doublesTier": "LC"},
    "bronzong": {"tier": "UU", "doublesTier": "DOU"},
    "chatot": {"tier": "ZU", "doublesTier": "DUU"},
    "spiritomb": {"tier": "RU", "doublesTier": "DUU"},
    "gible": {"tier": "LC"},
    "gabite": {"tier": "PU", "doublesTier": "NFE"},
    "garchomp": {"tier": "OU", "doublesTier": "DOU"},
    "riolu": {"tier": "PUBL", "doublesTier": "LC"},
    "lucario": {"tier": "(OU)", "doublesTier": "DOU"},
    "hippopotas": {"tier": "LC"},
    "hippowdon": {"tier": "(OU)", "doublesTier": "DUU"},
    "skorupi": {"tier": "LC"},
    "drapion": {"tier": "RU", "doublesTier": "DUU"},
    "croagunk": {"tier": "LC"},
    "toxicroak": {"tier": "OU", "doublesTier": "DOU"},
    "carnivine": {"tier": "ZU", "doublesTier": "DUU"},
    "finneon": {"tier": "LC"},
    "lumineon": {"tier": "ZU", "doublesTier": "DUU"},
    "snover": {"tier": "LC"},
    "abomasnow": {"tier": "UU", "doublesTier": "DOU"},
    "rotom": {"tier": "RU", "doublesTier": "DUU"},
    "rotomheat": {"tier": "UU", "doublesTier": "DUU"},
    "rotomwash": {"tier": "OU", "doublesTier": "DOU"},
    "rotomfrost": {"tier": "PUBL", "doublesTier": "DUU"},
    "rotomfan": {"tier": "NU", "doublesTier": "DUU"},
    "rotommow": {"tier": "RU", "doublesTier": "DUU"},
    "uxie": {"tier": "RU", "doublesTier": "DUU"},
    "mesprit": {"tier": "RU", "doublesTier": "DUU"},
    "azelf": {"tier": "UU", "doublesTier": "DUU"},
    "dialga": {"tier": "Uber", "doublesTier": "DUber"},
    "palkia": {"tier": "Uber", "doublesTier": "DUber"},
    "heatran": {"tier": "OU", "doublesTier": "DOU"},
    "regigigas": {"tier": "ZUBL", "doublesTier": "DUU"},
    "giratina": {"tier": "Uber", "doublesTier": "DUber"},
    "giratinaorigin": {"tier": "Uber", "doublesTier": "DUber"},
    "cresselia": {"tier": "RUBL", "doublesTier": "DOU"},
    "phione": {"tier": "ZU", "doublesTier": "DUU"},
    "manaphy": {"tier": "Uber", "doublesTier": "DUU"},
    "darkrai": {"tier": "Uber", "doublesTier": "DUU"},
    "shaymin": {"tier": "UU", "doublesTier": "DUU"},
    "shayminsky": {"tier": "Uber", "doublesTier": "DOU"},
    "arceus": {"tier": "Uber", "doublesTier": "DUber"},
    "arceusbug": {},
    "arceusdark": {},
    "arceusdragon": {},
    "arceuselectric": {},
    "arceusfighting": {},
    "arceusfire": {},
    "arceusflying": {},
    "arceusghost": {},
    "arceusgrass": {},
    "arceusground": {},
    "arceusice": {},
    "arceuspoison": {},
    "arceuspsychic": {},
    "arceusrock": {},
    "arceussteel": {},
    "arceuswater": {},
    "victini": {"tier": "UU", "doublesTier": "DOU"},
    "snivy": {"tier": "LC"},
    "servine": {"tier": "NFE"},
    "serperior": {"tier": "NU", "doublesTier": "DUU"},
    "tepig": {"tier": "LC"},
    "pignite": {"tier": "NFE"},
    "emboar": {"tier": "RU", "doublesTier": "DUU"},
    "oshawott": {"tier": "LC"},
    "dewott": {"tier": "NFE"},
    "samurott": {"tier": "NU", "doublesTier": "DUU"},
    "patrat": {"tier": "LC"},
    "watchog": {"tier": "ZU", "doublesTier": "DUU"},
    "lillipup": {"tier": "LC"},
    "herdier": {"tier": "NFE"},
    "stoutland": {"tier": "PU", "doublesTier": "DUU"},
    "purrloin": {"tier": "LC"},
    "liepard": {"tier": "NU", "doublesTier": "DUU"},
    "pansage": {"tier": "LC"},
    "simisage": {"tier": "PU", "doublesTier": "DUU"},
    "pansear": {"tier": "LC"},
    "simisear": {"tier": "ZU", "doublesTier": "DUU"},
    "panpour": {"tier": "LC"},
    "simipour": {"tier": "PUBL", "doublesTier": "DUU"},
    "munna": {"tier": "LC"},
    "musharna": {"tier": "NU", "doublesTier": "DUU"},
    "pidove": {"tier": "LC"},
    "tranquill": {"tier": "NFE"},
    "unfezant": {"tier": "ZU", "doublesTier": "DUU"},
    "blitzle": {"tier": "LC"},
    "zebstrika": {"tier": "PU", "doublesTier": "DUU"},
    "roggenrola": {"tier": "LC"},
    "boldore": {"tier": "NFE"},
    "gigalith": {"tier": "NU", "doublesTier": "DUU"},
    "woobat": {"tier": "LC"},
    "swoobat": {"tier": "PU", "doublesTier": "DUU"},
    "drilbur": {"tier": "LC"},
    "excadrill": {"tier": "OU", "doublesTier": "DOU"},
    "audino": {"tier": "PU", "doublesTier": "DUU"},
    "timburr": {"tier": "LC"},
    "gurdurr": {"tier": "NU", "doublesTier": "NFE"},
    "conkeldurr": {"tier": "OU", "doublesTier": "DOU"},
    "tympole": {"tier": "LC"},
    "palpitoad": {"tier": "NFE"},
    "seismitoad": {"tier": "NU", "doublesTier": "DUU"},
    "throh": {"tier": "PUBL", "doublesTier": "DUU"},
    "sawk": {"tier": "NU", "doublesTier": "DUU"},
    "sewaddle": {"tier": "LC"},
    "swadloon": {"tier": "NFE"},
    "leavanny": {"tier": "ZU", "doublesTier": "DUU"},
    "venipede": {"tier": "LC"},
    "whirlipede": {"tier": "ZUBL", "doublesTier": "NFE"},
    "scolipede": {"tier": "NUBL", "doublesTier": "DUU"},
    "cottonee": {"tier": "LC"},
    "whimsicott": {"tier": "RU", "doublesTier": "DOU"},
    "petilil": {"tier": "LC"},
    "lilligant": {"tier": "RU", "doublesTier": "DUU"},
    "basculin": {"tier": "NU", "doublesTier": "DUU"},
    "basculinbluestriped": {"tier": "NU", "doublesTier": "DUU"},
    "sandile": {"tier": "LC"},
    "krokorok": {"tier": "PU", "doublesTier": "NFE"},
    "krookodile": {"tier": "UU", "doublesTier": "DUU"},
    "darumaka": {"tier": "LC"},
    "darmanitan": {"tier": "UU", "doublesTier": "DUU"},
    "maractus": {"tier": "PU", "doublesTier": "DUU"},
    "dwebble": {"tier": "PU", "doublesTier": "LC"},
    "crustle": {"tier": "RU", "doublesTier": "DUU"},
    "scraggy": {"tier": "PU", "doublesTier": "NFE"},
    "scrafty": {"tier": "UU", "doublesTier": "DUU"},
    "sigilyph": {"tier": "RU", "doublesTier": "DUU"},
    "yamask": {"tier": "LC"},
    "cofagrigus": {"tier": "UU", "doublesTier": "DUU"},
    "tirtouga": {"tier": "LC"},
    "carracosta": {"tier": "NU", "doublesTier": "DUU"},
    "archen": {"tier": "LC"},
    "archeops": {"tier": "RU", "doublesTier": "DUU"},
    "trubbish": {"tier": "ZUBL", "doublesTier": "LC"},
    "garbodor": {"tier": "NU", "doublesTier": "DUU"},
    "zorua": {"tier": "LC"},
    "zoroark": {"tier": "UU", "doublesTier": "DUU"},
    "minccino": {"tier": "LC"},
    "cinccino": {"tier": "RU", "doublesTier": "DUU"},
    "gothita": {"tier": "LC"},
    "gothorita": {"tier": "PUBL", "doublesTier": "NFE"},
    "gothitelle": {"tier": "UUBL", "doublesTier": "DUU"},
    "solosis": {"tier": "LC"},
    "duosion": {"tier": "PU", "doublesTier": "NFE"},
    "reuniclus": {"tier": "OU", "doublesTier": "DOU"},
    "ducklett": {"tier": "LC"},
    "swanna": {"tier": "PU", "doublesTier": "DUU"},
    "vanillite": {"tier": "LC"},
    "vanillish": {"tier": "NFE"},
    "vanilluxe": {"tier": "ZU", "doublesTier": "DUU"},
    "deerling": {"tier": "LC"},
    "sawsbuck": {"tier": "NU", "doublesTier": "DUU"},
    "emolga": {"tier": "ZU", "doublesTier": "DUU"},
    "karrablast": {"tier": "LC"},
    "escavalier": {"tier": "RU", "doublesTier": "DUU"},
    "foongus": {"tier": "LC"},
    "amoonguss": {"tier": "RU", "doublesTier": "DOU"},
    "frillish": {"tier": "LC"},
    "jellicent": {"tier": "OU", "doublesTier": "DOU"},
    "alomomola": {"tier": "NU", "doublesTier": "DUU"},
    "joltik": {"tier": "LC"},
    "galvantula": {"tier": "RU", "doublesTier": "DUU"},
    "ferroseed": {"tier": "RU", "doublesTier": "LC"},
    "ferrothorn": {"tier": "OU", "doublesTier": "DOU"},
    "klink": {"tier": "LC"},
    "klang": {"tier": "PU", "doublesTier": "NFE"},
    "klinklang": {"tier": "RU", "doublesTier": "DUU"},
    "tynamo": {"tier": "LC"},
    "eelektrik": {"tier": "NFE"},
    "eelektross": {"tier": "NU", "doublesTier": "DUU"},
    "elgyem": {"tier": "LC"},
    "beheeyem": {"tier": "PU", "doublesTier": "DUU"},
    "litwick": {"tier": "LC"},
    "lampent": {"tier": "NFE"},
    "chandelure": {"tier": "UUBL", "doublesTier": "DOU"},
    "axew": {"tier": "LC"},
    "fraxure": {"tier": "PU", "doublesTier": "NFE"},
    "haxorus": {"tier": "(OU)", "doublesTier": "DUU"},
    "cubchoo": {"tier": "LC"},
    "beartic": {"tier": "ZU", "doublesTier": "DUU"},
    "cryogonal": {"tier": "RU", "doublesTier": "DUU"},
    "shelmet": {"tier": "LC"},
    "accelgor": {"tier": "RU", "doublesTier": "DUU"},
    "stunfisk": {"tier": "PU", "doublesTier": "DUU"},
    "mienfoo": {"tier": "LC"},
    "mienshao": {"tier": "UU", "doublesTier": "DUU"},
    "druddigon": {"tier": "RU", "doublesTier": "DUU"},
    "golett": {"tier": "LC"},
    "golurk": {"tier": "NU", "doublesTier": "DUU"},
    "pawniard": {"tier": "LC"},
    "bisharp": {"tier": "UU", "doublesTier": "DUU"},
    "bouffalant": {"tier": "RU", "doublesTier": "DUU"},
    "rufflet": {"tier": "LC"},
    "braviary": {"tier": "NU", "doublesTier": "DUU"},
    "vullaby": {"tier": "LC"},
    "mandibuzz": {"tier": "NU", "doublesTier": "DUU"},
    "heatmor": {"tier": "ZU", "doublesTier": "DUU"},
    "durant": {"tier": "RU", "doublesTier": "DUU"},
    "deino": {"tier": "LC"},
    "zweilous": {"tier": "PU", "doublesTier": "NFE"},
    "hydreigon": {"tier": "OU", "doublesTier": "DOU"},
    "larvesta": {"tier": "LC"},
    "volcarona": {"tier": "OU", "doublesTier": "DOU"},
    "cobalion": {"tier": "UU", "doublesTier": "DUU"},
    "terrakion": {"tier": "OU", "doublesTier": "DOU"},
    "virizion": {"tier": "UU", "doublesTier": "DUU"},
    "tornadus": {"tier": "UU", "doublesTier": "DUU"},
    "tornadustherian": {"tier": "Uber", "doublesTier": "DUU"},
    "thundurus": {"tier": "Uber", "doublesTier": "DOU"},
    "thundurustherian": {"tier": "OU", "doublesTier": "DOU"},
    "reshiram": {"tier": "Uber", "doublesTier": "DUber"},
    "zekrom": {"tier": "Uber", "doublesTier": "DUber"},
    "landorus": {"tier": "Uber", "doublesTier": "DUU"},
    "landorustherian": {"tier": "OU", "doublesTier": "DOU"},
    "kyurem": {"tier": "UUBL", "doublesTier": "DUU"},
    "kyuremblack": {"tier": "OU", "doublesTier": "DOU"},
    "kyuremwhite": {"tier": "Uber", "doublesTier": "DUber"},
    "keldeo": {"tier": "OU", "doublesTier": "DUU"},
    "keldeoresolute": {"tier": "OU", "doublesTier": "DUU"},
    "meloetta": {"tier": "UU", "doublesTier": "DUU"},
    "meloettapirouette": {},
    "genesect": {"tier": "Uber", "doublesTier": "DOU"},
    "genesectburn": {"tier": "Uber", "doublesTier": "(DOU)"},
    "genesectchill": {"tier": "Uber", "doublesTier": "(DOU)"},
    "genesectdouse": {"tier": "Uber", "doublesTier": "(DOU)"},
    "genesectshock": {"tier": "Uber", "doublesTier": "(DOU)"},
    "pokestarsmeargle": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarufo": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarufo2": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarbrycenman": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarmt": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarmt2": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestartransport": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestargiant": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestargiant2": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarhumanoid": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarmonster": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarf00": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarf002": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarspirit": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarblackdoor": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarwhitedoor": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarblackbelt": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestargiantpropo2": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "pokestarufopropu2": {"isNonstandard": "Unobtainable", "tier": "Illegal"}
  },
  "6": {
    "bulbasaur": {"tier": "LC"},
    "ivysaur": {"tier": "NFE"},
    "venusaur": {"tier": "RU", "doublesTier": "DOU"},
    "venusaurmega": {"tier": "OU", "doublesTier": "DOU"},
    "charmander": {"tier": "LC"},
    "charmeleon": {"tier": "NFE"},
    "charizard": {"tier": "NU", "doublesTier": "(DUU)"},
    "charizardmegax": {"tier": "OU", "doublesTier": "(DUU)"},
    "charizardmegay": {"tier": "OU", "doublesTier": "DOU"},
    "squirtle": {"tier": "LC"},
    "wartortle": {"tier": "NFE"},
    "blastoise": {"tier": "RU", "doublesTier": "(DUU)"},
    "blastoisemega": {"tier": "UU", "doublesTier": "DUU"},
    "caterpie": {"tier": "LC"},
    "metapod": {"tier": "NFE"},
    "butterfree": {"tier": "ZU", "doublesTier": "(DUU)"},
    "weedle": {"tier": "LC"},
    "kakuna": {"tier": "NFE"},
    "beedrill": {"tier": "ZU", "doublesTier": "(DUU)"},
    "beedrillmega": {"tier": "UU", "doublesTier": "(DUU)"},
    "pidgey": {"tier": "LC"},
    "pidgeotto": {"tier": "NFE"},
    "pidgeot": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pidgeotmega": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "rattata": {"tier": "LC"},
    "raticate": {"tier": "ZU", "doublesTier": "(DUU)"},
    "spearow": {"tier": "LC"},
    "fearow": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ekans": {"tier": "LC"},
    "arbok": {"tier": "PU", "doublesTier": "(DUU)"},
    "pichu": {"tier": "LC"},
    "pikachu": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachucosplay": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachurockstar": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachubelle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachupopstar": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachuphd": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachulibre": {"tier": "ZU", "doublesTier": "(DUU)"},
    "raichu": {"tier": "PU", "doublesTier": "DOU"},
    "sandshrew": {"tier": "LC"},
    "sandslash": {"tier": "NU", "doublesTier": "(DUU)"},
    "nidoranf": {"tier": "LC"},
    "nidorina": {"tier": "NFE"},
    "nidoqueen": {"tier": "UU", "doublesTier": "(DUU)"},
    "nidoranm": {"tier": "LC"},
    "nidorino": {"tier": "NFE"},
    "nidoking": {"tier": "UU", "doublesTier": "DUU"},
    "cleffa": {"tier": "LC"},
    "clefairy": {"tier": "PU", "doublesTier": "NFE"},
    "clefable": {"tier": "OU", "doublesTier": "DUU"},
    "vulpix": {"tier": "LC"},
    "ninetales": {"tier": "PU", "doublesTier": "DUU"},
    "igglybuff": {"tier": "LC"},
    "jigglypuff": {"tier": "NFE"},
    "wigglytuff": {"tier": "ZU", "doublesTier": "(DUU)"},
    "zubat": {"tier": "LC"},
    "golbat": {"tier": "RU", "doublesTier": "NFE"},
    "crobat": {"tier": "UU", "doublesTier": "DUU"},
    "oddish": {"tier": "LC"},
    "gloom": {"tier": "NFE"},
    "vileplume": {"tier": "NU", "doublesTier": "(DUU)"},
    "bellossom": {"tier": "ZU", "doublesTier": "(DUU)"},
    "paras": {"tier": "LC"},
    "parasect": {"tier": "ZU", "doublesTier": "(DUU)"},
    "venonat": {"tier": "LC"},
    "venomoth": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "diglett": {"tier": "LC"},
    "dugtrio": {"tier": "(OU)", "doublesTier": "(DUU)"},
    "meowth": {"tier": "LC"},
    "persian": {"tier": "ZU", "doublesTier": "(DUU)"},
    "psyduck": {"tier": "LC"},
    "golduck": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mankey": {"tier": "LC"},
    "primeape": {"tier": "NU", "doublesTier": "(DUU)"},
    "growlithe": {"tier": "LC"},
    "arcanine": {"tier": "UU", "doublesTier": "DOU"},
    "poliwag": {"tier": "LC"},
    "poliwhirl": {"tier": "NFE"},
    "poliwrath": {"tier": "NU", "doublesTier": "(DUU)"},
    "politoed": {"tier": "ZU", "doublesTier": "DOU"},
    "abra": {"tier": "LC"},
    "kadabra": {"tier": "PU", "doublesTier": "NFE"},
    "alakazam": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "alakazammega": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "machop": {"tier": "LC"},
    "machoke": {"tier": "PUBL", "doublesTier": "NFE"},
    "machamp": {"tier": "UU", "doublesTier": "DUU"},
    "bellsprout": {"tier": "LC"},
    "weepinbell": {"tier": "NFE"},
    "victreebel": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "tentacool": {"tier": "LC"},
    "tentacruel": {"tier": "UU", "doublesTier": "(DUU)"},
    "geodude": {"tier": "LC"},
    "graveler": {"tier": "NFE"},
    "golem": {"tier": "PU", "doublesTier": "(DUU)"},
    "ponyta": {"tier": "LC"},
    "rapidash": {"tier": "PU", "doublesTier": "(DUU)"},
    "slowpoke": {"tier": "LC"},
    "slowbro": {"tier": "OU", "doublesTier": "(DUU)"},
    "slowbromega": {"tier": "(OU)", "doublesTier": "(DUU)"},
    "slowking": {"tier": "RU", "doublesTier": "DUU"},
    "magnemite": {"tier": "LC"},
    "magneton": {"tier": "RU", "doublesTier": "NFE"},
    "magnezone": {"tier": "OU", "doublesTier": "(DUU)"},
    "farfetchd": {"tier": "ZU", "doublesTier": "(DUU)"},
    "doduo": {"tier": "LC"},
    "dodrio": {"tier": "PU", "doublesTier": "(DUU)"},
    "seel": {"tier": "LC"},
    "dewgong": {"tier": "ZU", "doublesTier": "(DUU)"},
    "grimer": {"tier": "LC"},
    "muk": {"tier": "PU", "doublesTier": "(DUU)"},
    "shellder": {"tier": "LC"},
    "cloyster": {"tier": "UU", "doublesTier": "(DUU)"},
    "gastly": {"tier": "LC"},
    "haunter": {"tier": "NU", "doublesTier": "NFE"},
    "gengar": {"tier": "OU", "doublesTier": "DOU"},
    "gengarmega": {"tier": "Uber", "doublesTier": "DOU"},
    "onix": {"tier": "LC"},
    "steelix": {"tier": "NU", "doublesTier": "(DUU)"},
    "steelixmega": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "drowzee": {"tier": "LC"},
    "hypno": {"tier": "ZU", "doublesTier": "(DUU)"},
    "krabby": {"tier": "LC"},
    "kingler": {"tier": "PU", "doublesTier": "(DUU)"},
    "voltorb": {"tier": "LC"},
    "electrode": {"tier": "PU", "doublesTier": "(DUU)"},
    "exeggcute": {"tier": "LC"},
    "exeggutor": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "cubone": {"tier": "LC"},
    "marowak": {"tier": "PU", "doublesTier": "(DUU)"},
    "tyrogue": {"tier": "LC"},
    "hitmonlee": {"tier": "RU", "doublesTier": "(DUU)"},
    "hitmonchan": {"tier": "NU", "doublesTier": "(DUU)"},
    "hitmontop": {"tier": "RU", "doublesTier": "DOU"},
    "lickitung": {"tier": "LC"},
    "lickilicky": {"tier": "PU", "doublesTier": "(DUU)"},
    "koffing": {"tier": "LC"},
    "weezing": {"tier": "NU", "doublesTier": "(DUU)"},
    "rhyhorn": {"tier": "LC"},
    "rhydon": {"tier": "NU", "doublesTier": "NFE"},
    "rhyperior": {"tier": "RU", "doublesTier": "DUU"},
    "happiny": {"tier": "LC"},
    "chansey": {"tier": "OU", "doublesTier": "NFE"},
    "blissey": {"tier": "UU", "doublesTier": "(DUU)"},
    "tangela": {"tier": "PU", "doublesTier": "NFE"},
    "tangrowth": {"tier": "OU", "doublesTier": "(DUU)"},
    "kangaskhan": {"tier": "NU", "doublesTier": "(DUU)"},
    "kangaskhanmega": {"tier": "Uber", "doublesTier": "DOU"},
    "horsea": {"tier": "LC"},
    "seadra": {"tier": "NFE"},
    "kingdra": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "goldeen": {"tier": "LC"},
    "seaking": {"tier": "ZU", "doublesTier": "(DUU)"},
    "staryu": {"tier": "LC"},
    "starmie": {"tier": "OU", "doublesTier": "(DUU)"},
    "mimejr": {"tier": "LC"},
    "mrmime": {"tier": "PU", "doublesTier": "(DUU)"},
    "scyther": {"tier": "NU", "doublesTier": "NFE"},
    "scizor": {"tier": "OU", "doublesTier": "DOU"},
    "scizormega": {"tier": "OU", "doublesTier": "(DOU)"},
    "smoochum": {"tier": "LC"},
    "jynx": {"tier": "NU", "doublesTier": "(DUU)"},
    "elekid": {"tier": "LC"},
    "electabuzz": {"tier": "NFE"},
    "electivire": {"tier": "NU", "doublesTier": "(DUU)"},
    "magby": {"tier": "LC"},
    "magmar": {"tier": "NFE"},
    "magmortar": {"tier": "NU", "doublesTier": "(DUU)"},
    "pinsir": {"tier": "NU", "doublesTier": "(DUU)"},
    "pinsirmega": {"tier": "OU", "doublesTier": "(DUU)"},
    "tauros": {"tier": "NU", "doublesTier": "(DUU)"},
    "magikarp": {"tier": "LC"},
    "gyarados": {"tier": "UU", "doublesTier": "(DUU)"},
    "gyaradosmega": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "lapras": {"tier": "PU", "doublesTier": "(DUU)"},
    "ditto": {"tier": "ZU", "doublesTier": "(DUU)"},
    "eevee": {"tier": "LC"},
    "vaporeon": {"tier": "UU", "doublesTier": "DUU"},
    "jolteon": {"tier": "RU", "doublesTier": "(DUU)"},
    "flareon": {"tier": "PU", "doublesTier": "(DUU)"},
    "espeon": {"tier": "UU", "doublesTier": "(DUU)"},
    "umbreon": {"tier": "UU", "doublesTier": "(DUU)"},
    "leafeon": {"tier": "PU", "doublesTier": "(DUU)"},
    "glaceon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "porygon": {"tier": "LC"},
    "porygon2": {"tier": "UU", "doublesTier": "DUU"},
    "porygonz": {"tier": "UU", "doublesTier": "(DUU)"},
    "omanyte": {"tier": "LC"},
    "omastar": {"tier": "NU", "doublesTier": "(DUU)"},
    "kabuto": {"tier": "LC"},
    "kabutops": {"tier": "NU", "doublesTier": "(DUU)"},
    "aerodactyl": {"tier": "RU", "doublesTier": "(DUU)"},
    "aerodactylmega": {"tier": "UU", "doublesTier": "DUU"},
    "munchlax": {"tier": "LC"},
    "snorlax": {"tier": "UU", "doublesTier": "DUU"},
    "articuno": {"tier": "PU", "doublesTier": "(DUU)"},
    "zapdos": {"tier": "OU", "doublesTier": "DOU"},
    "moltres": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "dratini": {"tier": "LC"},
    "dragonair": {"tier": "NFE"},
    "dragonite": {"tier": "OU", "doublesTier": "(DUU)"},
    "mewtwo": {"tier": "Uber", "doublesTier": "DUber"},
    "mewtwomegax": {"tier": "Uber", "doublesTier": "DUber"},
    "mewtwomegay": {"tier": "Uber", "doublesTier": "DUber"},
    "mew": {"tier": "OU", "doublesTier": "DUU"},
    "chikorita": {"tier": "LC"},
    "bayleef": {"tier": "NFE"},
    "meganium": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cyndaquil": {"tier": "LC"},
    "quilava": {"tier": "NFE"},
    "typhlosion": {"tier": "RU", "doublesTier": "(DUU)"},
    "totodile": {"tier": "LC"},
    "croconaw": {"tier": "NFE"},
    "feraligatr": {"tier": "UU", "doublesTier": "(DUU)"},
    "sentret": {"tier": "LC"},
    "furret": {"tier": "ZU", "doublesTier": "(DUU)"},
    "hoothoot": {"tier": "LC"},
    "noctowl": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ledyba": {"tier": "LC"},
    "ledian": {"tier": "ZU", "doublesTier": "(DUU)"},
    "spinarak": {"tier": "LC"},
    "ariados": {"tier": "ZU", "doublesTier": "(DUU)"},
    "chinchou": {"tier": "LC"},
    "lanturn": {"tier": "NU", "doublesTier": "DUU"},
    "togepi": {"tier": "LC"},
    "togetic": {"tier": "RU", "doublesTier": "DUU"},
    "togekiss": {"tier": "UUBL", "doublesTier": "DOU"},
    "natu": {"tier": "LC"},
    "xatu": {"tier": "NU", "doublesTier": "(DUU)"},
    "mareep": {"tier": "LC"},
    "flaaffy": {"tier": "NFE"},
    "ampharos": {"tier": "PU", "doublesTier": "(DUU)"},
    "ampharosmega": {"tier": "UU", "doublesTier": "(DUU)"},
    "azurill": {"tier": "LC"},
    "marill": {"tier": "NFE"},
    "azumarill": {"tier": "OU", "doublesTier": "DOU"},
    "bonsly": {"tier": "LC"},
    "sudowoodo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "hoppip": {"tier": "LC"},
    "skiploom": {"tier": "NFE"},
    "jumpluff": {"tier": "ZU", "doublesTier": "(DUU)"},
    "aipom": {"tier": "LC"},
    "ambipom": {"tier": "RU", "doublesTier": "DUU"},
    "sunkern": {"tier": "LC"},
    "sunflora": {"tier": "ZU", "doublesTier": "(DUU)"},
    "yanma": {"tier": "NFE"},
    "yanmega": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "wooper": {"tier": "LC"},
    "quagsire": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "murkrow": {"tier": "NFE"},
    "honchkrow": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "misdreavus": {"tier": "PU", "doublesTier": "NFE"},
    "mismagius": {"tier": "NU", "doublesTier": "(DUU)"},
    "unown": {"tier": "ZU", "doublesTier": "(DUU)"},
    "wynaut": {"tier": "LC"},
    "wobbuffet": {"tier": "ZU", "doublesTier": "(DUU)"},
    "girafarig": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pineco": {"tier": "LC"},
    "forretress": {"tier": "UU", "doublesTier": "(DUU)"},
    "dunsparce": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gligar": {"tier": "UU", "doublesTier": "NFE"},
    "gliscor": {"tier": "OU", "doublesTier": "(DUU)"},
    "snubbull": {"tier": "LC"},
    "granbull": {"tier": "RU", "doublesTier": "(DUU)"},
    "qwilfish": {"tier": "RU", "doublesTier": "(DUU)"},
    "shuckle": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "heracross": {"tier": "UU", "doublesTier": "(DUU)"},
    "heracrossmega": {"tier": "(OU)", "doublesTier": "(DUU)"},
    "sneasel": {"tier": "RU", "doublesTier": "NFE"},
    "weavile": {"tier": "OU", "doublesTier": "DOU"},
    "teddiursa": {"tier": "LC"},
    "ursaring": {"tier": "PU", "doublesTier": "(DUU)"},
    "slugma": {"tier": "LC"},
    "magcargo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "swinub": {"tier": "LC"},
    "piloswine": {"tier": "NU", "doublesTier": "NFE"},
    "mamoswine": {"tier": "UU", "doublesTier": "(DUU)"},
    "corsola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "remoraid": {"tier": "LC"},
    "octillery": {"tier": "ZU", "doublesTier": "(DUU)"},
    "delibird": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mantyke": {"tier": "LC"},
    "mantine": {"tier": "NU", "doublesTier": "(DUU)"},
    "skarmory": {"tier": "OU", "doublesTier": "(DUU)"},
    "houndour": {"tier": "LC"},
    "houndoom": {"tier": "RU", "doublesTier": "(DUU)"},
    "houndoommega": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "phanpy": {"tier": "LC"},
    "donphan": {"tier": "UU", "doublesTier": "(DUU)"},
    "stantler": {"tier": "ZU", "doublesTier": "(DUU)"},
    "smeargle": {"tier": "PU", "doublesTier": "(DUU)"},
    "miltank": {"tier": "NU", "doublesTier": "(DUU)"},
    "raikou": {"tier": "(OU)", "doublesTier": "(DUU)"},
    "entei": {"tier": "UU", "doublesTier": "DUU"},
    "suicune": {"tier": "UU", "doublesTier": "DOU"},
    "larvitar": {"tier": "LC"},
    "pupitar": {"tier": "NFE"},
    "tyranitar": {"tier": "OU", "doublesTier": "DOU"},
    "tyranitarmega": {"tier": "(OU)", "doublesTier": "(DOU)"},
    "lugia": {"tier": "Uber", "doublesTier": "DUber"},
    "hooh": {"tier": "Uber", "doublesTier": "DUber"},
    "celebi": {"tier": "UU", "doublesTier": "(DUU)"},
    "treecko": {"tier": "LC"},
    "grovyle": {"tier": "NFE"},
    "sceptile": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "sceptilemega": {"tier": "UU", "doublesTier": "DUU"},
    "torchic": {"tier": "LC"},
    "combusken": {"tier": "PU", "doublesTier": "NFE"},
    "blaziken": {"tier": "Uber", "doublesTier": "(DUU)"},
    "blazikenmega": {"tier": "Uber", "doublesTier": "(DUU)"},
    "mudkip": {"tier": "LC"},
    "marshtomp": {"tier": "NFE"},
    "swampert": {"tier": "UU", "doublesTier": "(DUU)"},
    "swampertmega": {"tier": "UU", "doublesTier": "(DUU)"},
    "poochyena": {"tier": "LC"},
    "mightyena": {"tier": "ZU", "doublesTier": "(DUU)"},
    "zigzagoon": {"tier": "LC"},
    "linoone": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "wurmple": {"tier": "LC"},
    "silcoon": {"tier": "NFE"},
    "beautifly": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cascoon": {"tier": "NFE"},
    "dustox": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lotad": {"tier": "LC"},
    "lombre": {"tier": "NFE"},
    "ludicolo": {"tier": "NU", "doublesTier": "DOU"},
    "seedot": {"tier": "LC"},
    "nuzleaf": {"tier": "NFE"},
    "shiftry": {"tier": "NU", "doublesTier": "(DUU)"},
    "taillow": {"tier": "LC"},
    "swellow": {"tier": "NU", "doublesTier": "(DUU)"},
    "wingull": {"tier": "LC"},
    "pelipper": {"tier": "PU", "doublesTier": "(DUU)"},
    "ralts": {"tier": "LC"},
    "kirlia": {"tier": "NFE"},
    "gardevoir": {"tier": "UU", "doublesTier": "DUU"},
    "gardevoirmega": {"tier": "OU", "doublesTier": "DOU"},
    "gallade": {"tier": "RU", "doublesTier": "(DUU)"},
    "gallademega": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "surskit": {"tier": "LC"},
    "masquerain": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shroomish": {"tier": "LC"},
    "breloom": {"tier": "OU", "doublesTier": "DOU"},
    "slakoth": {"tier": "LC"},
    "vigoroth": {"tier": "PUBL", "doublesTier": "NFE"},
    "slaking": {"tier": "ZU", "doublesTier": "(DUU)"},
    "nincada": {"tier": "LC"},
    "ninjask": {"tier": "PU", "doublesTier": "(DUU)"},
    "shedinja": {"tier": "ZU", "doublesTier": "(DUU)"},
    "whismur": {"tier": "LC"},
    "loudred": {"tier": "NFE"},
    "exploud": {"tier": "RU", "doublesTier": "(DUU)"},
    "makuhita": {"tier": "LC"},
    "hariyama": {"tier": "NU", "doublesTier": "DUU"},
    "nosepass": {"tier": "LC"},
    "probopass": {"tier": "PU", "doublesTier": "(DUU)"},
    "skitty": {"tier": "LC"},
    "delcatty": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sableye": {"tier": "RUBL", "doublesTier": "DUU"},
    "sableyemega": {"tier": "Uber", "doublesTier": "DUU"},
    "mawile": {"tier": "PU", "doublesTier": "(DUU)"},
    "mawilemega": {"tier": "Uber", "doublesTier": "(DUU)"},
    "aron": {"tier": "LC"},
    "lairon": {"tier": "NFE"},
    "aggron": {"tier": "NU", "doublesTier": "(DUU)"},
    "aggronmega": {"tier": "UU", "doublesTier": "(DUU)"},
    "meditite": {"tier": "NFE"},
    "medicham": {"tier": "RU", "doublesTier": "(DUU)"},
    "medichammega": {"tier": "OU", "doublesTier": "(DUU)"},
    "electrike": {"tier": "LC"},
    "manectric": {"tier": "NU", "doublesTier": "(DUU)"},
    "manectricmega": {"tier": "OU", "doublesTier": "(DUU)"},
    "plusle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "minun": {"tier": "ZU", "doublesTier": "(DUU)"},
    "volbeat": {"tier": "ZU", "doublesTier": "(DUU)"},
    "illumise": {"tier": "ZU", "doublesTier": "(DUU)"},
    "budew": {"tier": "LC"},
    "roselia": {"tier": "PU", "doublesTier": "NFE"},
    "roserade": {"tier": "UU", "doublesTier": "(DUU)"},
    "gulpin": {"tier": "LC"},
    "swalot": {"tier": "ZU", "doublesTier": "(DUU)"},
    "carvanha": {"tier": "LC"},
    "sharpedo": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "sharpedomega": {"tier": "UU", "doublesTier": "(DUU)"},
    "wailmer": {"tier": "LC"},
    "wailord": {"tier": "ZU", "doublesTier": "(DUU)"},
    "numel": {"tier": "LC"},
    "camerupt": {"tier": "PU", "doublesTier": "(DUU)"},
    "cameruptmega": {"tier": "RU", "doublesTier": "DUU"},
    "torkoal": {"tier": "PU", "doublesTier": "(DUU)"},
    "spoink": {"tier": "LC"},
    "grumpig": {"tier": "PU", "doublesTier": "(DUU)"},
    "spinda": {"tier": "ZU", "doublesTier": "(DUU)"},
    "trapinch": {"tier": "LC"},
    "vibrava": {"tier": "NFE"},
    "flygon": {"tier": "RU", "doublesTier": "(DUU)"},
    "cacnea": {"tier": "LC"},
    "cacturne": {"tier": "PU", "doublesTier": "(DUU)"},
    "swablu": {"tier": "LC"},
    "altaria": {"tier": "PU", "doublesTier": "(DUU)"},
    "altariamega": {"tier": "UUBL", "doublesTier": "DUU"},
    "zangoose": {"tier": "NU", "doublesTier": "(DUU)"},
    "seviper": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lunatone": {"tier": "ZU", "doublesTier": "(DUU)"},
    "solrock": {"tier": "ZU", "doublesTier": "(DUU)"},
    "barboach": {"tier": "LC"},
    "whiscash": {"tier": "ZU", "doublesTier": "(DUU)"},
    "corphish": {"tier": "LC"},
    "crawdaunt": {"tier": "UU", "doublesTier": "(DUU)"},
    "baltoy": {"tier": "LC"},
    "claydol": {"tier": "NU", "doublesTier": "(DUU)"},
    "lileep": {"tier": "LC"},
    "cradily": {"tier": "NU", "doublesTier": "DUU"},
    "anorith": {"tier": "LC"},
    "armaldo": {"tier": "PU", "doublesTier": "(DUU)"},
    "feebas": {"tier": "LC"},
    "milotic": {"tier": "UU", "doublesTier": "(DUU)"},
    "castform": {"tier": "ZU", "doublesTier": "(DUU)"},
    "castformsunny": {},
    "castformrainy": {},
    "castformsnowy": {},
    "kecleon": {"tier": "NU", "doublesTier": "DUU"},
    "shuppet": {"tier": "LC"},
    "banette": {"tier": "ZU", "doublesTier": "(DUU)"},
    "banettemega": {"tier": "RU", "doublesTier": "(DUU)"},
    "duskull": {"tier": "LC"},
    "dusclops": {"tier": "NFE"},
    "dusknoir": {"tier": "PU", "doublesTier": "(DUU)"},
    "tropius": {"tier": "ZU", "doublesTier": "(DUU)"},
    "chingling": {"tier": "LC"},
    "chimecho": {"tier": "ZU", "doublesTier": "(DUU)"},
    "absol": {"tier": "RU", "doublesTier": "(DUU)"},
    "absolmega": {"tier": "UU", "doublesTier": "(DUU)"},
    "snorunt": {"tier": "LC"},
    "glalie": {"tier": "ZU", "doublesTier": "(DUU)"},
    "glaliemega": {"tier": "RU", "doublesTier": "(DUU)"},
    "froslass": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "spheal": {"tier": "LC"},
    "sealeo": {"tier": "NFE"},
    "walrein": {"tier": "ZU", "doublesTier": "(DUU)"},
    "clamperl": {"tier": "LC"},
    "huntail": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gorebyss": {"tier": "PU", "doublesTier": "(DUU)"},
    "relicanth": {"tier": "PU", "doublesTier": "(DUU)"},
    "luvdisc": {"tier": "ZU", "doublesTier": "(DUU)"},
    "bagon": {"tier": "LC"},
    "shelgon": {"tier": "NFE"},
    "salamence": {"tier": "UUBL", "doublesTier": "DUU"},
    "salamencemega": {"tier": "Uber", "doublesTier": "DOU"},
    "beldum": {"tier": "LC"},
    "metang": {"tier": "PU", "doublesTier": "NFE"},
    "metagross": {"tier": "UU", "doublesTier": "DUU"},
    "metagrossmega": {"tier": "OU", "doublesTier": "DUU"},
    "regirock": {"tier": "NU", "doublesTier": "(DUU)"},
    "regice": {"tier": "PU", "doublesTier": "(DUU)"},
    "registeel": {"tier": "RU", "doublesTier": "(DUU)"},
    "latias": {"tier": "OU", "doublesTier": "(DUU)"},
    "latiasmega": {"tier": "(OU)", "doublesTier": "(DUU)"},
    "latios": {"tier": "OU", "doublesTier": "DOU"},
    "latiosmega": {"tier": "(OU)", "doublesTier": "(DOU)"},
    "kyogre": {"tier": "Uber", "doublesTier": "DUber"},
    "kyogreprimal": {},
    "groudon": {"tier": "Uber", "doublesTier": "DUber"},
    "groudonprimal": {},
    "rayquaza": {"tier": "Uber", "doublesTier": "DUber"},
    "rayquazamega": {"tier": "AG", "doublesTier": "DUber"},
    "jirachi": {"tier": "OU", "doublesTier": "DUber"},
    "deoxys": {"tier": "Uber", "doublesTier": "(DUU)"},
    "deoxysattack": {"tier": "Uber", "doublesTier": "(DUU)"},
    "deoxysdefense": {"tier": "Uber", "doublesTier": "(DUU)"},
    "deoxysspeed": {"tier": "Uber", "doublesTier": "(DUU)"},
    "turtwig": {"tier": "LC"},
    "grotle": {"tier": "NFE"},
    "torterra": {"tier": "NU", "doublesTier": "(DUU)"},
    "chimchar": {"tier": "LC"},
    "monferno": {"tier": "PU", "doublesTier": "NFE"},
    "infernape": {"tier": "UU", "doublesTier": "DUU"},
    "piplup": {"tier": "LC"},
    "prinplup": {"tier": "PU", "doublesTier": "NFE"},
    "empoleon": {"tier": "UU", "doublesTier": "(DUU)"},
    "starly": {"tier": "LC"},
    "staravia": {"tier": "NFE"},
    "staraptor": {"tier": "UUBL", "doublesTier": "DUU"},
    "bidoof": {"tier": "LC"},
    "bibarel": {"tier": "ZU", "doublesTier": "(DUU)"},
    "kricketot": {"tier": "LC"},
    "kricketune": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shinx": {"tier": "LC"},
    "luxio": {"tier": "NFE"},
    "luxray": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cranidos": {"tier": "LC"},
    "rampardos": {"tier": "PU", "doublesTier": "(DUU)"},
    "shieldon": {"tier": "LC"},
    "bastiodon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "burmy": {"tier": "LC"},
    "wormadam": {"tier": "ZU", "doublesTier": "(DUU)"},
    "wormadamsandy": {"tier": "ZU", "doublesTier": "(DUU)"},
    "wormadamtrash": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mothim": {"tier": "ZU", "doublesTier": "(DUU)"},
    "combee": {"tier": "LC"},
    "vespiquen": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pachirisu": {"tier": "ZU", "doublesTier": "(DUU)"},
    "buizel": {"tier": "LC"},
    "floatzel": {"tier": "PU", "doublesTier": "(DUU)"},
    "cherubi": {"tier": "LC"},
    "cherrim": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cherrimsunshine": {},
    "shellos": {"tier": "LC"},
    "gastrodon": {"tier": "NU", "doublesTier": "DOU"},
    "drifloon": {"tier": "NFE"},
    "drifblim": {"tier": "PU", "doublesTier": "(DUU)"},
    "buneary": {"tier": "LC"},
    "lopunny": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lopunnymega": {"tier": "OU", "doublesTier": "(DUU)"},
    "glameow": {"tier": "LC"},
    "purugly": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "stunky": {"tier": "LC"},
    "skuntank": {"tier": "NU", "doublesTier": "(DUU)"},
    "bronzor": {"tier": "LC"},
    "bronzong": {"tier": "RU", "doublesTier": "DUU"},
    "chatot": {"tier": "PU", "doublesTier": "(DUU)"},
    "spiritomb": {"tier": "RU", "doublesTier": "(DUU)"},
    "gible": {"tier": "LC"},
    "gabite": {"tier": "PU", "doublesTier": "NFE"},
    "garchomp": {"tier": "OU", "doublesTier": "DOU"},
    "garchompmega": {"tier": "(OU)", "doublesTier": "(DOU)"},
    "riolu": {"tier": "LC"},
    "lucario": {"tier": "UU", "doublesTier": "(DUU)"},
    "lucariomega": {"tier": "Uber", "doublesTier": "(DUU)"},
    "hippopotas": {"tier": "LC"},
    "hippowdon": {"tier": "OU", "doublesTier": "DUU"},
    "skorupi": {"tier": "LC"},
    "drapion": {"tier": "RU", "doublesTier": "(DUU)"},
    "croagunk": {"tier": "LC"},
    "toxicroak": {"tier": "UU", "doublesTier": "(DUU)"},
    "carnivine": {"tier": "ZU", "doublesTier": "(DUU)"},
    "finneon": {"tier": "LC"},
    "lumineon": {"tier": "PU", "doublesTier": "(DUU)"},
    "snover": {"tier": "LC"},
    "abomasnow": {"tier": "NU", "doublesTier": "(DUU)"},
    "abomasnowmega": {"tier": "RUBL", "doublesTier": "DUU"},
    "rotom": {"tier": "NU", "doublesTier": "(DUU)"},
    "rotomheat": {"tier": "UU", "doublesTier": "(DUU)"},
    "rotomwash": {"tier": "OU", "doublesTier": "DOU"},
    "rotomfrost": {"tier": "PU", "doublesTier": "(DUU)"},
    "rotomfan": {"tier": "PU", "doublesTier": "(DUU)"},
    "rotommow": {"tier": "RU", "doublesTier": "(DUU)"},
    "uxie": {"tier": "RU", "doublesTier": "(DUU)"},
    "mesprit": {"tier": "NU", "doublesTier": "(DUU)"},
    "azelf": {"tier": "OU", "doublesTier": "(DUU)"},
    "dialga": {"tier": "Uber", "doublesTier": "DUber"},
    "palkia": {"tier": "Uber", "doublesTier": "DUber"},
    "heatran": {"tier": "OU", "doublesTier": "DOU"},
    "regigigas": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "giratina": {"tier": "Uber", "doublesTier": "DUber"},
    "giratinaorigin": {},
    "cresselia": {"tier": "UU", "doublesTier": "DOU"},
    "phione": {"tier": "ZU", "doublesTier": "(DUU)"},
    "manaphy": {"tier": "OU", "doublesTier": "(DUU)"},
    "darkrai": {"tier": "Uber", "doublesTier": "(DUU)"},
    "shaymin": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "shayminsky": {"tier": "Uber", "doublesTier": "DOU"},
    "arceus": {"tier": "Uber", "doublesTier": "DUber"},
    "arceusbug": {},
    "arceusdark": {},
    "arceusdragon": {},
    "arceuselectric": {},
    "arceusfairy": {},
    "arceusfighting": {},
    "arceusfire": {},
    "arceusflying": {},
    "arceusghost": {},
    "arceusgrass": {},
    "arceusground": {},
    "arceusice": {},
    "arceuspoison": {},
    "arceuspsychic": {},
    "arceusrock": {},
    "arceussteel": {},
    "arceuswater": {},
    "victini": {"tier": "UUBL", "doublesTier": "DUU"},
    "snivy": {"tier": "LC"},
    "servine": {"tier": "NFE"},
    "serperior": {"tier": "OU", "doublesTier": "(DUU)"},
    "tepig": {"tier": "LC"},
    "pignite": {"tier": "NFE"},
    "emboar": {"tier": "RU", "doublesTier": "(DUU)"},
    "oshawott": {"tier": "LC"},
    "dewott": {"tier": "NFE"},
    "samurott": {"tier": "NU", "doublesTier": "(DUU)"},
    "patrat": {"tier": "LC"},
    "watchog": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lillipup": {"tier": "LC"},
    "herdier": {"tier": "NFE"},
    "stoutland": {"tier": "PU", "doublesTier": "(DUU)"},
    "purrloin": {"tier": "LC"},
    "liepard": {"tier": "NU", "doublesTier": "(DUU)"},
    "pansage": {"tier": "LC"},
    "simisage": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pansear": {"tier": "LC"},
    "simisear": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "panpour": {"tier": "LC"},
    "simipour": {"tier": "ZU", "doublesTier": "(DUU)"},
    "munna": {"tier": "LC"},
    "musharna": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "pidove": {"tier": "LC"},
    "tranquill": {"tier": "NFE"},
    "unfezant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "blitzle": {"tier": "LC"},
    "zebstrika": {"tier": "PU", "doublesTier": "(DUU)"},
    "roggenrola": {"tier": "LC"},
    "boldore": {"tier": "NFE"},
    "gigalith": {"tier": "ZU", "doublesTier": "(DUU)"},
    "woobat": {"tier": "LC"},
    "swoobat": {"tier": "ZU", "doublesTier": "(DUU)"},
    "drilbur": {"tier": "LC"},
    "excadrill": {"tier": "OU", "doublesTier": "DUU"},
    "audino": {"tier": "PU", "doublesTier": "(DUU)"},
    "audinomega": {"tier": "NU", "doublesTier": "(DUU)"},
    "timburr": {"tier": "LC"},
    "gurdurr": {"tier": "NU", "doublesTier": "NFE"},
    "conkeldurr": {"tier": "UUBL", "doublesTier": "DOU"},
    "tympole": {"tier": "LC"},
    "palpitoad": {"tier": "NFE"},
    "seismitoad": {"tier": "RU", "doublesTier": "(DUU)"},
    "throh": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "sawk": {"tier": "RU", "doublesTier": "(DUU)"},
    "sewaddle": {"tier": "LC"},
    "swadloon": {"tier": "NFE"},
    "leavanny": {"tier": "PU", "doublesTier": "(DUU)"},
    "venipede": {"tier": "LC"},
    "whirlipede": {"tier": "NFE"},
    "scolipede": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "cottonee": {"tier": "LC"},
    "whimsicott": {"tier": "UU", "doublesTier": "DOU"},
    "petilil": {"tier": "LC"},
    "lilligant": {"tier": "NU", "doublesTier": "(DUU)"},
    "basculin": {"tier": "PU", "doublesTier": "(DUU)"},
    "basculinbluestriped": {"tier": "PU", "doublesTier": "(DUU)"},
    "sandile": {"tier": "LC"},
    "krokorok": {"tier": "NFE"},
    "krookodile": {"tier": "UU", "doublesTier": "DUU"},
    "darumaka": {"tier": "LC"},
    "darmanitan": {"tier": "UU", "doublesTier": "(DUU)"},
    "maractus": {"tier": "ZU", "doublesTier": "(DUU)"},
    "dwebble": {"tier": "LC"},
    "crustle": {"tier": "PU", "doublesTier": "(DUU)"},
    "scraggy": {"tier": "LC"},
    "scrafty": {"tier": "RU", "doublesTier": "DOU"},
    "sigilyph": {"tier": "RU", "doublesTier": "(DUU)"},
    "yamask": {"tier": "LC"},
    "cofagrigus": {"tier": "RU", "doublesTier": "DUU"},
    "tirtouga": {"tier": "LC"},
    "carracosta": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "archen": {"tier": "LC"},
    "archeops": {"tier": "NU", "doublesTier": "(DUU)"},
    "trubbish": {"tier": "LC"},
    "garbodor": {"tier": "NU", "doublesTier": "(DUU)"},
    "zorua": {"tier": "LC"},
    "zoroark": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "minccino": {"tier": "LC"},
    "cinccino": {"tier": "RU", "doublesTier": "(DUU)"},
    "gothita": {"tier": "LC"},
    "gothorita": {"tier": "NFE"},
    "gothitelle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "solosis": {"tier": "LC"},
    "duosion": {"tier": "NFE"},
    "reuniclus": {"tier": "UU", "doublesTier": "DUU"},
    "ducklett": {"tier": "LC"},
    "swanna": {"tier": "PU", "doublesTier": "(DUU)"},
    "vanillite": {"tier": "LC"},
    "vanillish": {"tier": "NFE"},
    "vanilluxe": {"tier": "ZU", "doublesTier": "(DUU)"},
    "deerling": {"tier": "LC"},
    "sawsbuck": {"tier": "ZU", "doublesTier": "(DUU)"},
    "emolga": {"tier": "ZU", "doublesTier": "(DUU)"},
    "karrablast": {"tier": "LC"},
    "escavalier": {"tier": "RU", "doublesTier": "DUU"},
    "foongus": {"tier": "LC"},
    "amoonguss": {"tier": "OU", "doublesTier": "DOU"},
    "frillish": {"tier": "LC"},
    "jellicent": {"tier": "RU", "doublesTier": "DUU"},
    "alomomola": {"tier": "RU", "doublesTier": "(DUU)"},
    "joltik": {"tier": "LC"},
    "galvantula": {"tier": "UU", "doublesTier": "(DUU)"},
    "ferroseed": {"tier": "NU", "doublesTier": "LC"},
    "ferrothorn": {"tier": "OU", "doublesTier": "DOU"},
    "klink": {"tier": "LC"},
    "klang": {"tier": "NFE"},
    "klinklang": {"tier": "NU", "doublesTier": "(DUU)"},
    "tynamo": {"tier": "LC"},
    "eelektrik": {"tier": "NFE"},
    "eelektross": {"tier": "RU", "doublesTier": "(DUU)"},
    "elgyem": {"tier": "LC"},
    "beheeyem": {"tier": "PU", "doublesTier": "(DUU)"},
    "litwick": {"tier": "LC"},
    "lampent": {"tier": "NFE"},
    "chandelure": {"tier": "UU", "doublesTier": "DUU"},
    "axew": {"tier": "LC"},
    "fraxure": {"tier": "ZUBL", "doublesTier": "NFE"},
    "haxorus": {"tier": "UU", "doublesTier": "(DUU)"},
    "cubchoo": {"tier": "LC"},
    "beartic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cryogonal": {"tier": "PU", "doublesTier": "(DUU)"},
    "shelmet": {"tier": "LC"},
    "accelgor": {"tier": "RU", "doublesTier": "(DUU)"},
    "stunfisk": {"tier": "PU", "doublesTier": "(DUU)"},
    "mienfoo": {"tier": "LC"},
    "mienshao": {"tier": "UU", "doublesTier": "(DUU)"},
    "druddigon": {"tier": "RU", "doublesTier": "(DUU)"},
    "golett": {"tier": "LC"},
    "golurk": {"tier": "NU", "doublesTier": "(DUU)"},
    "pawniard": {"tier": "PU", "doublesTier": "LC"},
    "bisharp": {"tier": "OU", "doublesTier": "DOU"},
    "bouffalant": {"tier": "PU", "doublesTier": "(DUU)"},
    "rufflet": {"tier": "LC"},
    "braviary": {"tier": "RU", "doublesTier": "(DUU)"},
    "vullaby": {"tier": "PU", "doublesTier": "LC"},
    "mandibuzz": {"tier": "UU", "doublesTier": "(DUU)"},
    "heatmor": {"tier": "ZU", "doublesTier": "(DUU)"},
    "durant": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "deino": {"tier": "LC"},
    "zweilous": {"tier": "NFE"},
    "hydreigon": {"tier": "UU", "doublesTier": "DOU"},
    "larvesta": {"tier": "LC"},
    "volcarona": {"tier": "UUBL", "doublesTier": "DUU"},
    "cobalion": {"tier": "UU", "doublesTier": "(DUU)"},
    "terrakion": {"tier": "UUBL", "doublesTier": "DOU"},
    "virizion": {"tier": "RU", "doublesTier": "(DUU)"},
    "tornadus": {"tier": "UU", "doublesTier": "DUU"},
    "tornadustherian": {"tier": "OU", "doublesTier": "(DUU)"},
    "thundurus": {"tier": "OU", "doublesTier": "DOU"},
    "thundurustherian": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "reshiram": {"tier": "Uber", "doublesTier": "DUber"},
    "zekrom": {"tier": "Uber", "doublesTier": "DUber"},
    "landorus": {"tier": "Uber", "doublesTier": "(DUU)"},
    "landorustherian": {"tier": "OU", "doublesTier": "DOU"},
    "kyurem": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "kyuremblack": {"tier": "OU", "doublesTier": "DOU"},
    "kyuremwhite": {"tier": "Uber", "doublesTier": "DUber"},
    "keldeo": {"tier": "OU", "doublesTier": "DOU"},
    "keldeoresolute": {"tier": "OU", "doublesTier": "DOU"},
    "meloetta": {"tier": "RU", "doublesTier": "(DUU)"},
    "meloettapirouette": {},
    "genesect": {"tier": "Uber", "doublesTier": "DUU"},
    "genesectburn": {"tier": "Uber", "doublesTier": "(DUU)"},
    "genesectchill": {"tier": "Uber", "doublesTier": "(DUU)"},
    "genesectdouse": {"tier": "Uber", "doublesTier": "(DUU)"},
    "genesectshock": {"tier": "Uber", "doublesTier": "(DUU)"},
    "chespin": {"tier": "LC"},
    "quilladin": {"tier": "NFE"},
    "chesnaught": {"tier": "UU", "doublesTier": "(DUU)"},
    "fennekin": {"tier": "LC"},
    "braixen": {"tier": "NFE"},
    "delphox": {"tier": "RU", "doublesTier": "(DUU)"},
    "froakie": {"tier": "LC"},
    "frogadier": {"tier": "NFE"},
    "greninja": {"tier": "Uber", "doublesTier": "(DUU)"},
    "bunnelby": {"tier": "LC"},
    "diggersby": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "fletchling": {"tier": "LC"},
    "fletchinder": {"tier": "RU", "doublesTier": "NFE"},
    "talonflame": {"tier": "OU", "doublesTier": "DOU"},
    "scatterbug": {"tier": "LC"},
    "spewpa": {"tier": "NFE"},
    "vivillon": {"tier": "NU", "doublesTier": "(DUU)"},
    "litleo": {"tier": "LC"},
    "pyroar": {"tier": "NU", "doublesTier": "(DUU)"},
    "flabebe": {"tier": "LC"},
    "floette": {"tier": "NFE"},
    "floetteeternal": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "florges": {"tier": "UU", "doublesTier": "(DUU)"},
    "skiddo": {"tier": "LC"},
    "gogoat": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pancham": {"tier": "LC"},
    "pangoro": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "furfrou": {"tier": "ZU", "doublesTier": "(DUU)"},
    "espurr": {"tier": "LC"},
    "meowstic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "meowsticf": {"tier": "ZU", "doublesTier": "(DUU)"},
    "honedge": {"tier": "LC"},
    "doublade": {"tier": "UU", "doublesTier": "NFE"},
    "aegislash": {"tier": "Uber", "doublesTier": "DOU"},
    "aegislashblade": {},
    "spritzee": {"tier": "LC"},
    "aromatisse": {"tier": "RU", "doublesTier": "DUU"},
    "swirlix": {"tier": "NFE"},
    "slurpuff": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "inkay": {"tier": "LC"},
    "malamar": {"tier": "NU", "doublesTier": "(DUU)"},
    "binacle": {"tier": "LC"},
    "barbaracle": {"tier": "NU", "doublesTier": "(DUU)"},
    "skrelp": {"tier": "LC"},
    "dragalge": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "clauncher": {"tier": "LC"},
    "clawitzer": {"tier": "RU", "doublesTier": "(DUU)"},
    "helioptile": {"tier": "LC"},
    "heliolisk": {"tier": "UU", "doublesTier": "(DUU)"},
    "tyrunt": {"tier": "LC"},
    "tyrantrum": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "amaura": {"tier": "LC"},
    "aurorus": {"tier": "NU", "doublesTier": "(DUU)"},
    "sylveon": {"tier": "UU", "doublesTier": "DOU"},
    "hawlucha": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "dedenne": {"tier": "ZU", "doublesTier": "(DUU)"},
    "carbink": {"tier": "ZU", "doublesTier": "(DUU)"},
    "goomy": {"tier": "LC"},
    "sliggoo": {"tier": "NFE"},
    "goodra": {"tier": "UU", "doublesTier": "DUU"},
    "klefki": {"tier": "UU", "doublesTier": "(DUU)"},
    "phantump": {"tier": "LC"},
    "trevenant": {"tier": "PU", "doublesTier": "(DUU)"},
    "pumpkaboo": {"tier": "LC"},
    "pumpkaboosmall": {"tier": "LC"},
    "pumpkaboolarge": {"tier": "LC"},
    "pumpkaboosuper": {"tier": "LC"},
    "gourgeist": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gourgeistsmall": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gourgeistlarge": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gourgeistsuper": {"tier": "PU", "doublesTier": "(DUU)"},
    "bergmite": {"tier": "LC"},
    "avalugg": {"tier": "PU", "doublesTier": "(DUU)"},
    "noibat": {"tier": "LC"},
    "noivern": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "xerneas": {"tier": "Uber", "doublesTier": "DUber"},
    "yveltal": {"tier": "Uber", "doublesTier": "DUber"},
    "zygarde": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "diancie": {"tier": "RU", "doublesTier": "DUU"},
    "dianciemega": {"tier": "OU", "doublesTier": "DOU"},
    "hoopa": {"tier": "RU", "doublesTier": "(DUU)"},
    "hoopaunbound": {"tier": "Uber", "doublesTier": "DOU"},
    "volcanion": {"tier": "OU", "doublesTier": "DOU"}
  },
  "7": {
    "bulbasaur": {"tier": "LC"},
    "ivysaur": {"tier": "NFE"},
    "venusaur": {"tier": "RU", "doublesTier": "(DUU)"},
    "venusaurmega": {"tier": "UUBL", "doublesTier": "DUU"},
    "charmander": {"tier": "LC"},
    "charmeleon": {"tier": "NFE"},
    "charizard": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "charizardmegax": {"tier": "OU", "doublesTier": "(DUU)"},
    "charizardmegay": {"tier": "OU", "doublesTier": "DOU"},
    "squirtle": {"tier": "LC"},
    "wartortle": {"tier": "NFE"},
    "blastoise": {"tier": "NU", "doublesTier": "(DUU)"},
    "blastoisemega": {"tier": "RU", "doublesTier": "(DUU)"},
    "caterpie": {"tier": "LC"},
    "metapod": {"tier": "NFE"},
    "butterfree": {"tier": "ZU", "doublesTier": "(DUU)"},
    "weedle": {"tier": "LC"},
    "kakuna": {"tier": "NFE"},
    "beedrill": {"tier": "ZU", "doublesTier": "(DUU)"},
    "beedrillmega": {"tier": "UU", "doublesTier": "DUU"},
    "pidgey": {"tier": "LC"},
    "pidgeotto": {"tier": "NFE"},
    "pidgeot": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pidgeotmega": {"tier": "UU", "doublesTier": "(DUU)"},
    "rattata": {"tier": "LC"},
    "rattataalola": {"tier": "LC"},
    "raticate": {"tier": "ZU", "doublesTier": "(DUU)"},
    "raticatealola": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "raticatealolatotem": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "spearow": {"tier": "LC"},
    "fearow": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ekans": {"tier": "LC"},
    "arbok": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pichu": {"tier": "LC"},
    "pikachu": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachuoriginal": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachuhoenn": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachusinnoh": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachuunova": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachukalos": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachualola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachupartner": {"tier": "ZU", "doublesTier": "(DUU)"},
    "raichu": {"tier": "ZU", "doublesTier": "DUU"},
    "raichualola": {"tier": "PU", "doublesTier": "(DUU)"},
    "sandshrew": {"tier": "LC"},
    "sandshrewalola": {"tier": "LC"},
    "sandslash": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sandslashalola": {"tier": "PU", "doublesTier": "(DUU)"},
    "nidoranf": {"tier": "LC"},
    "nidorina": {"tier": "NFE"},
    "nidoqueen": {"tier": "RU", "doublesTier": "(DUU)"},
    "nidoranm": {"tier": "LC"},
    "nidorino": {"tier": "NFE"},
    "nidoking": {"tier": "UU", "doublesTier": "(DUU)"},
    "cleffa": {"tier": "LC"},
    "clefairy": {"tier": "PU", "doublesTier": "NFE"},
    "clefable": {"tier": "OU", "doublesTier": "DUU"},
    "vulpix": {"tier": "NFE"},
    "vulpixalola": {"tier": "LC"},
    "ninetales": {"tier": "RU", "doublesTier": "DUU"},
    "ninetalesalola": {"tier": "UUBL", "doublesTier": "DOU"},
    "igglybuff": {"tier": "LC"},
    "jigglypuff": {"tier": "NFE"},
    "wigglytuff": {"tier": "ZU", "doublesTier": "(DUU)"},
    "zubat": {"tier": "LC"},
    "golbat": {"tier": "NU", "doublesTier": "NFE"},
    "crobat": {"tier": "UU", "doublesTier": "(DUU)"},
    "oddish": {"tier": "LC"},
    "gloom": {"tier": "NFE"},
    "vileplume": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "bellossom": {"tier": "ZU", "doublesTier": "(DUU)"},
    "paras": {"tier": "LC"},
    "parasect": {"tier": "ZU", "doublesTier": "(DUU)"},
    "venonat": {"tier": "LC"},
    "venomoth": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "diglett": {"tier": "LC"},
    "diglettalola": {"tier": "LC"},
    "dugtrio": {"tier": "ZU", "doublesTier": "(DUU)"},
    "dugtrioalola": {"tier": "PU", "doublesTier": "(DUU)"},
    "meowth": {"tier": "LC"},
    "meowthalola": {"tier": "LC"},
    "persian": {"tier": "ZU", "doublesTier": "(DUU)"},
    "persianalola": {"tier": "PU", "doublesTier": "(DUU)"},
    "psyduck": {"tier": "LC"},
    "golduck": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mankey": {"tier": "LC"},
    "primeape": {"tier": "PU", "doublesTier": "(DUU)"},
    "growlithe": {"tier": "LC"},
    "arcanine": {"tier": "RU", "doublesTier": "DUU"},
    "poliwag": {"tier": "LC"},
    "poliwhirl": {"tier": "NFE"},
    "poliwrath": {"tier": "ZU", "doublesTier": "(DUU)"},
    "politoed": {"tier": "ZU", "doublesTier": "DOU"},
    "abra": {"tier": "LC"},
    "kadabra": {"tier": "ZU", "doublesTier": "NFE"},
    "alakazam": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "alakazammega": {"tier": "OU", "doublesTier": "(DUU)"},
    "machop": {"tier": "LC"},
    "machoke": {"tier": "NFE"},
    "machamp": {"tier": "RU", "doublesTier": "(DUU)"},
    "bellsprout": {"tier": "LC"},
    "weepinbell": {"tier": "NFE"},
    "victreebel": {"tier": "PU", "doublesTier": "(DUU)"},
    "tentacool": {"tier": "LC"},
    "tentacruel": {"tier": "UU", "doublesTier": "(DUU)"},
    "geodude": {"tier": "LC"},
    "geodudealola": {"tier": "LC"},
    "graveler": {"tier": "NFE"},
    "graveleralola": {"tier": "NFE"},
    "golem": {"tier": "ZU", "doublesTier": "(DUU)"},
    "golemalola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ponyta": {"tier": "LC"},
    "rapidash": {"tier": "ZU", "doublesTier": "(DUU)"},
    "slowpoke": {"tier": "LC"},
    "slowbro": {"tier": "RU", "doublesTier": "(DUU)"},
    "slowbromega": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "slowking": {"tier": "NU", "doublesTier": "(DUU)"},
    "magnemite": {"tier": "LC"},
    "magneton": {"tier": "UU", "doublesTier": "NFE"},
    "magnezone": {"tier": "OU", "doublesTier": "(DUU)"},
    "farfetchd": {"tier": "ZU", "doublesTier": "(DUU)"},
    "doduo": {"tier": "LC"},
    "dodrio": {"tier": "PU", "doublesTier": "(DUU)"},
    "seel": {"tier": "LC"},
    "dewgong": {"tier": "ZU", "doublesTier": "(DUU)"},
    "grimer": {"tier": "LC"},
    "grimeralola": {"tier": "LC"},
    "muk": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mukalola": {"tier": "UU", "doublesTier": "DUU"},
    "shellder": {"tier": "LC"},
    "cloyster": {"tier": "RU", "doublesTier": "(DUU)"},
    "gastly": {"tier": "LC"},
    "haunter": {"tier": "PU", "doublesTier": "NFE"},
    "gengar": {"tier": "UU", "doublesTier": "DUU"},
    "gengarmega": {"tier": "Uber", "doublesTier": "DUber"},
    "onix": {"tier": "LC"},
    "steelix": {"tier": "NU", "doublesTier": "(DUU)"},
    "steelixmega": {"tier": "UU", "doublesTier": "(DUU)"},
    "drowzee": {"tier": "LC"},
    "hypno": {"tier": "ZU", "doublesTier": "(DUU)"},
    "krabby": {"tier": "LC"},
    "kingler": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "voltorb": {"tier": "LC"},
    "electrode": {"tier": "ZU", "doublesTier": "(DUU)"},
    "exeggcute": {"tier": "LC"},
    "exeggutor": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "exeggutoralola": {"tier": "NU", "doublesTier": "(DUU)"},
    "cubone": {"tier": "LC"},
    "marowak": {"tier": "ZU", "doublesTier": "(DUU)"},
    "marowakalola": {"tier": "RU", "doublesTier": "DUU"},
    "marowakalolatotem": {"tier": "RU", "doublesTier": "DUU"},
    "tyrogue": {"tier": "LC"},
    "hitmonlee": {"tier": "NU", "doublesTier": "(DUU)"},
    "hitmonchan": {"tier": "PU", "doublesTier": "(DUU)"},
    "hitmontop": {"tier": "NU", "doublesTier": "DUU"},
    "lickitung": {"tier": "LC"},
    "lickilicky": {"tier": "ZU", "doublesTier": "(DUU)"},
    "koffing": {"tier": "LC"},
    "weezing": {"tier": "NU", "doublesTier": "(DUU)"},
    "rhyhorn": {"tier": "LC"},
    "rhydon": {"tier": "NU", "doublesTier": "NFE"},
    "rhyperior": {"tier": "RU", "doublesTier": "(DUU)"},
    "happiny": {"tier": "LC"},
    "chansey": {"tier": "OU", "doublesTier": "NFE"},
    "blissey": {"tier": "UU", "doublesTier": "(DUU)"},
    "tangela": {"tier": "PU", "doublesTier": "NFE"},
    "tangrowth": {"tier": "OU", "doublesTier": "(DUU)"},
    "kangaskhan": {"tier": "PU", "doublesTier": "(DUU)"},
    "kangaskhanmega": {"tier": "Uber", "doublesTier": "DUber"},
    "horsea": {"tier": "LC"},
    "seadra": {"tier": "NFE"},
    "kingdra": {"tier": "NUBL", "doublesTier": "DOU"},
    "goldeen": {"tier": "LC"},
    "seaking": {"tier": "ZU", "doublesTier": "(DUU)"},
    "staryu": {"tier": "LC"},
    "starmie": {"tier": "UU", "doublesTier": "DUU"},
    "mimejr": {"tier": "LC"},
    "mrmime": {"tier": "ZU", "doublesTier": "(DUU)"},
    "scyther": {"tier": "PU", "doublesTier": "NFE"},
    "scizor": {"tier": "UU", "doublesTier": "(DUU)"},
    "scizormega": {"tier": "OU", "doublesTier": "DUU"},
    "smoochum": {"tier": "LC"},
    "jynx": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "elekid": {"tier": "LC"},
    "electabuzz": {"tier": "NFE"},
    "electivire": {"tier": "ZU", "doublesTier": "(DUU)"},
    "magby": {"tier": "LC"},
    "magmar": {"tier": "NFE"},
    "magmortar": {"tier": "NU", "doublesTier": "(DUU)"},
    "pinsir": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pinsirmega": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "tauros": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "magikarp": {"tier": "LC"},
    "gyarados": {"tier": "UUBL", "doublesTier": "DUU"},
    "gyaradosmega": {"tier": "OU", "doublesTier": "DUU"},
    "lapras": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ditto": {"tier": "ZU", "doublesTier": "(DUU)"},
    "eevee": {"tier": "LC"},
    "vaporeon": {"tier": "NU", "doublesTier": "(DUU)"},
    "jolteon": {"tier": "RU", "doublesTier": "(DUU)"},
    "flareon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "espeon": {"tier": "RU", "doublesTier": "(DUU)"},
    "umbreon": {"tier": "RU", "doublesTier": "(DUU)"},
    "leafeon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "glaceon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "porygon": {"tier": "NFE"},
    "porygon2": {"tier": "RU", "doublesTier": "DOU"},
    "porygonz": {"tier": "UUBL", "doublesTier": "DUU"},
    "omanyte": {"tier": "LC"},
    "omastar": {"tier": "PU", "doublesTier": "(DUU)"},
    "kabuto": {"tier": "LC"},
    "kabutops": {"tier": "PU", "doublesTier": "(DUU)"},
    "aerodactyl": {"tier": "NU", "doublesTier": "(DUU)"},
    "aerodactylmega": {"tier": "UU", "doublesTier": "DUU"},
    "munchlax": {"tier": "LC"},
    "snorlax": {"tier": "RU", "doublesTier": "DUber"},
    "articuno": {"tier": "PU", "doublesTier": "(DUU)"},
    "zapdos": {"tier": "OU", "doublesTier": "DOU"},
    "moltres": {"tier": "UU", "doublesTier": "(DUU)"},
    "dratini": {"tier": "LC"},
    "dragonair": {"tier": "NFE"},
    "dragonite": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "mewtwo": {"tier": "Uber", "doublesTier": "DUber"},
    "mewtwomegax": {"tier": "Uber", "doublesTier": "DUber"},
    "mewtwomegay": {"tier": "Uber", "doublesTier": "DUber"},
    "mew": {"tier": "UU", "doublesTier": "DOU"},
    "chikorita": {"tier": "LC"},
    "bayleef": {"tier": "NFE"},
    "meganium": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cyndaquil": {"tier": "LC"},
    "quilava": {"tier": "NFE"},
    "typhlosion": {"tier": "NU", "doublesTier": "(DUU)"},
    "totodile": {"tier": "LC"},
    "croconaw": {"tier": "NFE"},
    "feraligatr": {"tier": "UU", "doublesTier": "(DUU)"},
    "sentret": {"tier": "LC"},
    "furret": {"tier": "ZU", "doublesTier": "(DUU)"},
    "hoothoot": {"tier": "LC"},
    "noctowl": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ledyba": {"tier": "LC"},
    "ledian": {"tier": "ZU", "doublesTier": "(DUU)"},
    "spinarak": {"tier": "LC"},
    "ariados": {"tier": "ZU", "doublesTier": "(DUU)"},
    "chinchou": {"tier": "LC"},
    "lanturn": {"tier": "PU", "doublesTier": "(DUU)"},
    "togepi": {"tier": "LC"},
    "togetic": {"tier": "NFE"},
    "togekiss": {"tier": "UU", "doublesTier": "DUU"},
    "natu": {"tier": "LC"},
    "xatu": {"tier": "NU", "doublesTier": "(DUU)"},
    "mareep": {"tier": "LC"},
    "flaaffy": {"tier": "NFE"},
    "ampharos": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ampharosmega": {"tier": "RU", "doublesTier": "DUU"},
    "azurill": {"tier": "LC"},
    "marill": {"tier": "NFE"},
    "azumarill": {"tier": "OU", "doublesTier": "DUU"},
    "bonsly": {"tier": "LC"},
    "sudowoodo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "hoppip": {"tier": "LC"},
    "skiploom": {"tier": "NFE"},
    "jumpluff": {"tier": "ZU", "doublesTier": "(DUU)"},
    "aipom": {"tier": "NFE"},
    "ambipom": {"tier": "NU", "doublesTier": "(DUU)"},
    "sunkern": {"tier": "LC"},
    "sunflora": {"tier": "ZU", "doublesTier": "(DUU)"},
    "yanma": {"tier": "NFE"},
    "yanmega": {"tier": "RU", "doublesTier": "(DUU)"},
    "wooper": {"tier": "LC"},
    "quagsire": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "murkrow": {"tier": "NFE"},
    "honchkrow": {"tier": "RU", "doublesTier": "(DUU)"},
    "misdreavus": {"tier": "NFE"},
    "mismagius": {"tier": "NU", "doublesTier": "(DUU)"},
    "unown": {"tier": "ZU", "doublesTier": "(DUU)"},
    "wynaut": {"tier": "LC"},
    "wobbuffet": {"tier": "ZU", "doublesTier": "(DUU)"},
    "girafarig": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pineco": {"tier": "LC"},
    "forretress": {"tier": "RU", "doublesTier": "(DUU)"},
    "dunsparce": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gligar": {"tier": "UU", "doublesTier": "NFE"},
    "gliscor": {"tier": "OU", "doublesTier": "(DUU)"},
    "snubbull": {"tier": "LC"},
    "granbull": {"tier": "ZU", "doublesTier": "(DUU)"},
    "qwilfish": {"tier": "PU", "doublesTier": "(DUU)"},
    "shuckle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "heracross": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "heracrossmega": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "sneasel": {"tier": "NU", "doublesTier": "NFE"},
    "weavile": {"tier": "UUBL", "doublesTier": "DUU"},
    "teddiursa": {"tier": "LC"},
    "ursaring": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "slugma": {"tier": "LC"},
    "magcargo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "swinub": {"tier": "LC"},
    "piloswine": {"tier": "NU", "doublesTier": "NFE"},
    "mamoswine": {"tier": "UU", "doublesTier": "DUU"},
    "corsola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "remoraid": {"tier": "LC"},
    "octillery": {"tier": "ZU", "doublesTier": "(DUU)"},
    "delibird": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mantyke": {"tier": "LC"},
    "mantine": {"tier": "RU", "doublesTier": "(DUU)"},
    "skarmory": {"tier": "OU", "doublesTier": "(DUU)"},
    "houndour": {"tier": "LC"},
    "houndoom": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "houndoommega": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "phanpy": {"tier": "LC"},
    "donphan": {"tier": "RU", "doublesTier": "(DUU)"},
    "stantler": {"tier": "ZU", "doublesTier": "(DUU)"},
    "smeargle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "miltank": {"tier": "NU", "doublesTier": "(DUU)"},
    "raikou": {"tier": "RU", "doublesTier": "(DUU)"},
    "entei": {"tier": "RUBL", "doublesTier": "DUU"},
    "suicune": {"tier": "UU", "doublesTier": "DOU"},
    "larvitar": {"tier": "LC"},
    "pupitar": {"tier": "NFE"},
    "tyranitar": {"tier": "OU", "doublesTier": "DOU"},
    "tyranitarmega": {"tier": "OU", "doublesTier": "DOU"},
    "lugia": {"tier": "Uber", "doublesTier": "DUber"},
    "hooh": {"tier": "Uber", "doublesTier": "DUber"},
    "celebi": {"tier": "UU", "doublesTier": "(DUU)"},
    "treecko": {"tier": "LC"},
    "grovyle": {"tier": "NFE"},
    "sceptile": {"tier": "NU", "doublesTier": "(DUU)"},
    "sceptilemega": {"tier": "RU", "doublesTier": "DUU"},
    "torchic": {"tier": "LC"},
    "combusken": {"tier": "ZU", "doublesTier": "NFE"},
    "blaziken": {"tier": "Uber", "doublesTier": "(DUU)"},
    "blazikenmega": {"tier": "Uber", "doublesTier": "DUU"},
    "mudkip": {"tier": "LC"},
    "marshtomp": {"tier": "NFE"},
    "swampert": {"tier": "UU", "doublesTier": "(DUU)"},
    "swampertmega": {"tier": "OU", "doublesTier": "DOU"},
    "poochyena": {"tier": "LC"},
    "mightyena": {"tier": "ZU", "doublesTier": "(DUU)"},
    "zigzagoon": {"tier": "LC"},
    "linoone": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "wurmple": {"tier": "LC"},
    "silcoon": {"tier": "NFE"},
    "beautifly": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cascoon": {"tier": "NFE"},
    "dustox": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lotad": {"tier": "LC"},
    "lombre": {"tier": "NFE"},
    "ludicolo": {"tier": "PU", "doublesTier": "(DUU)"},
    "seedot": {"tier": "LC"},
    "nuzleaf": {"tier": "NFE"},
    "shiftry": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "taillow": {"tier": "LC"},
    "swellow": {"tier": "RU", "doublesTier": "(DUU)"},
    "wingull": {"tier": "NFE"},
    "pelipper": {"tier": "OU", "doublesTier": "DOU"},
    "ralts": {"tier": "LC"},
    "kirlia": {"tier": "NFE"},
    "gardevoir": {"tier": "RU", "doublesTier": "DUU"},
    "gardevoirmega": {"tier": "UUBL", "doublesTier": "DOU"},
    "gallade": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "gallademega": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "surskit": {"tier": "LC"},
    "masquerain": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shroomish": {"tier": "LC"},
    "breloom": {"tier": "UUBL", "doublesTier": "DUU"},
    "slakoth": {"tier": "LC"},
    "vigoroth": {"tier": "NFE"},
    "slaking": {"tier": "ZU", "doublesTier": "(DUU)"},
    "nincada": {"tier": "LC"},
    "ninjask": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shedinja": {"tier": "ZU", "doublesTier": "(DUU)"},
    "whismur": {"tier": "LC"},
    "loudred": {"tier": "NFE"},
    "exploud": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "makuhita": {"tier": "LC"},
    "hariyama": {"tier": "NU", "doublesTier": "DUU"},
    "nosepass": {"tier": "LC"},
    "probopass": {"tier": "ZU", "doublesTier": "(DUU)"},
    "skitty": {"tier": "LC"},
    "delcatty": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sableye": {"tier": "PU", "doublesTier": "DUU"},
    "sableyemega": {"tier": "OU", "doublesTier": "DUU"},
    "mawile": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mawilemega": {"tier": "OU", "doublesTier": "DUU"},
    "aron": {"tier": "LC"},
    "lairon": {"tier": "NFE"},
    "aggron": {"tier": "PU", "doublesTier": "(DUU)"},
    "aggronmega": {"tier": "UU", "doublesTier": "(DUU)"},
    "meditite": {"tier": "NFE"},
    "medicham": {"tier": "NU", "doublesTier": "(DUU)"},
    "medichammega": {"tier": "OU", "doublesTier": "(DUU)"},
    "electrike": {"tier": "LC"},
    "manectric": {"tier": "PU", "doublesTier": "(DUU)"},
    "manectricmega": {"tier": "UU", "doublesTier": "DOU"},
    "plusle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "minun": {"tier": "ZU", "doublesTier": "(DUU)"},
    "volbeat": {"tier": "ZU", "doublesTier": "(DUU)"},
    "illumise": {"tier": "ZU", "doublesTier": "(DUU)"},
    "budew": {"tier": "LC"},
    "roselia": {"tier": "PU", "doublesTier": "NFE"},
    "roserade": {"tier": "RU", "doublesTier": "(DUU)"},
    "gulpin": {"tier": "LC"},
    "swalot": {"tier": "ZU", "doublesTier": "(DUU)"},
    "carvanha": {"tier": "LC"},
    "sharpedo": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "sharpedomega": {"tier": "UU", "doublesTier": "(DUU)"},
    "wailmer": {"tier": "LC"},
    "wailord": {"tier": "ZU", "doublesTier": "(DUU)"},
    "numel": {"tier": "LC"},
    "camerupt": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cameruptmega": {"tier": "NUBL", "doublesTier": "DOU"},
    "torkoal": {"tier": "ZU", "doublesTier": "DUU"},
    "spoink": {"tier": "LC"},
    "grumpig": {"tier": "ZU", "doublesTier": "(DUU)"},
    "spinda": {"tier": "ZU", "doublesTier": "(DUU)"},
    "trapinch": {"tier": "NFE"},
    "vibrava": {"tier": "NFE"},
    "flygon": {"tier": "RU", "doublesTier": "(DUU)"},
    "cacnea": {"tier": "LC"},
    "cacturne": {"tier": "ZU", "doublesTier": "(DUU)"},
    "swablu": {"tier": "LC"},
    "altaria": {"tier": "ZU", "doublesTier": "(DUU)"},
    "altariamega": {"tier": "UU", "doublesTier": "(DUU)"},
    "zangoose": {"tier": "PU", "doublesTier": "(DUU)"},
    "seviper": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lunatone": {"tier": "ZU", "doublesTier": "(DUU)"},
    "solrock": {"tier": "ZU", "doublesTier": "(DUU)"},
    "barboach": {"tier": "LC"},
    "whiscash": {"tier": "ZU", "doublesTier": "(DUU)"},
    "corphish": {"tier": "LC"},
    "crawdaunt": {"tier": "UU", "doublesTier": "DUU"},
    "baltoy": {"tier": "LC"},
    "claydol": {"tier": "PU", "doublesTier": "(DUU)"},
    "lileep": {"tier": "LC"},
    "cradily": {"tier": "ZU", "doublesTier": "(DUU)"},
    "anorith": {"tier": "LC"},
    "armaldo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "feebas": {"tier": "LC"},
    "milotic": {"tier": "RU", "doublesTier": "DOU"},
    "castform": {"tier": "ZU", "doublesTier": "(DUU)"},
    "castformsunny": {},
    "castformrainy": {},
    "castformsnowy": {},
    "kecleon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shuppet": {"tier": "LC"},
    "banette": {"tier": "ZU", "doublesTier": "(DUU)"},
    "banettemega": {"tier": "RU", "doublesTier": "(DUU)"},
    "duskull": {"tier": "LC"},
    "dusclops": {"tier": "ZU", "doublesTier": "NFE"},
    "dusknoir": {"tier": "ZU", "doublesTier": "(DUU)"},
    "tropius": {"tier": "ZU", "doublesTier": "(DUU)"},
    "chingling": {"tier": "LC"},
    "chimecho": {"tier": "ZU", "doublesTier": "(DUU)"},
    "absol": {"tier": "PU", "doublesTier": "(DUU)"},
    "absolmega": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "snorunt": {"tier": "LC"},
    "glalie": {"tier": "ZU", "doublesTier": "(DUU)"},
    "glaliemega": {"tier": "NU", "doublesTier": "(DUU)"},
    "froslass": {"tier": "UU", "doublesTier": "(DUU)"},
    "spheal": {"tier": "LC"},
    "sealeo": {"tier": "NFE"},
    "walrein": {"tier": "ZU", "doublesTier": "(DUU)"},
    "clamperl": {"tier": "LC"},
    "huntail": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gorebyss": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "relicanth": {"tier": "ZU", "doublesTier": "(DUU)"},
    "luvdisc": {"tier": "ZU", "doublesTier": "(DUU)"},
    "bagon": {"tier": "LC"},
    "shelgon": {"tier": "NFE"},
    "salamence": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "salamencemega": {"tier": "Uber", "doublesTier": "DOU"},
    "beldum": {"tier": "LC"},
    "metang": {"tier": "NFE"},
    "metagross": {"tier": "RU", "doublesTier": "DUU"},
    "metagrossmega": {"tier": "Uber", "doublesTier": "DOU"},
    "regirock": {"tier": "PU", "doublesTier": "(DUU)"},
    "regice": {"tier": "ZU", "doublesTier": "(DUU)"},
    "registeel": {"tier": "RU", "doublesTier": "(DUU)"},
    "latias": {"tier": "UU", "doublesTier": "(DUU)"},
    "latiasmega": {"tier": "OU", "doublesTier": "(DUU)"},
    "latios": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "latiosmega": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "kyogre": {"tier": "Uber", "doublesTier": "DUber"},
    "kyogreprimal": {"tier": "Uber", "doublesTier": "DUber"},
    "groudon": {"tier": "Uber", "doublesTier": "DUber"},
    "groudonprimal": {"tier": "Uber", "doublesTier": "DUber"},
    "rayquaza": {"tier": "Uber", "doublesTier": "DUber"},
    "rayquazamega": {"tier": "AG", "doublesTier": "DUber"},
    "jirachi": {"tier": "OU", "doublesTier": "DUber"},
    "deoxys": {"tier": "Uber", "doublesTier": "(DUU)"},
    "deoxysattack": {"tier": "Uber", "doublesTier": "DUU"},
    "deoxysdefense": {"tier": "Uber", "doublesTier": "(DUU)"},
    "deoxysspeed": {"tier": "Uber", "doublesTier": "(DUU)"},
    "turtwig": {"tier": "LC"},
    "grotle": {"tier": "NFE"},
    "torterra": {"tier": "ZU", "doublesTier": "(DUU)"},
    "chimchar": {"tier": "LC"},
    "monferno": {"tier": "NFE"},
    "infernape": {"tier": "UU", "doublesTier": "(DUU)"},
    "piplup": {"tier": "LC"},
    "prinplup": {"tier": "NFE"},
    "empoleon": {"tier": "UU", "doublesTier": "(DUU)"},
    "starly": {"tier": "LC"},
    "staravia": {"tier": "NFE"},
    "staraptor": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "bidoof": {"tier": "LC"},
    "bibarel": {"tier": "ZU", "doublesTier": "(DUU)"},
    "kricketot": {"tier": "LC"},
    "kricketune": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shinx": {"tier": "LC"},
    "luxio": {"tier": "NFE"},
    "luxray": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cranidos": {"tier": "LC"},
    "rampardos": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shieldon": {"tier": "LC"},
    "bastiodon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "burmy": {"tier": "LC"},
    "wormadam": {"tier": "ZU", "doublesTier": "(DUU)"},
    "wormadamsandy": {"tier": "ZU", "doublesTier": "(DUU)"},
    "wormadamtrash": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mothim": {"tier": "ZU", "doublesTier": "(DUU)"},
    "combee": {"tier": "LC"},
    "vespiquen": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pachirisu": {"tier": "ZU", "doublesTier": "(DUU)"},
    "buizel": {"tier": "LC"},
    "floatzel": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cherubi": {"tier": "LC"},
    "cherrim": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cherrimsunshine": {},
    "shellos": {"tier": "LC"},
    "gastrodon": {"tier": "PU", "doublesTier": "DOU"},
    "drifloon": {"tier": "NFE"},
    "drifblim": {"tier": "ZU", "doublesTier": "(DUU)"},
    "buneary": {"tier": "LC"},
    "lopunny": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lopunnymega": {"tier": "OU", "doublesTier": "DUU"},
    "glameow": {"tier": "LC"},
    "purugly": {"tier": "ZU", "doublesTier": "(DUU)"},
    "stunky": {"tier": "LC"},
    "skuntank": {"tier": "PU", "doublesTier": "(DUU)"},
    "bronzor": {"tier": "ZU", "doublesTier": "LC"},
    "bronzong": {"tier": "RU", "doublesTier": "DUU"},
    "chatot": {"tier": "ZU", "doublesTier": "(DUU)"},
    "spiritomb": {"tier": "PU", "doublesTier": "(DUU)"},
    "gible": {"tier": "LC"},
    "gabite": {"tier": "NFE"},
    "garchomp": {"tier": "OU", "doublesTier": "DOU"},
    "garchompmega": {"tier": "(OU)", "doublesTier": "(DOU)"},
    "riolu": {"tier": "LC"},
    "lucario": {"tier": "UU", "doublesTier": "(DUU)"},
    "lucariomega": {"tier": "Uber", "doublesTier": "(DUU)"},
    "hippopotas": {"tier": "LC"},
    "hippowdon": {"tier": "UU", "doublesTier": "(DUU)"},
    "skorupi": {"tier": "LC"},
    "drapion": {"tier": "RU", "doublesTier": "(DUU)"},
    "croagunk": {"tier": "LC"},
    "toxicroak": {"tier": "RU", "doublesTier": "(DUU)"},
    "carnivine": {"tier": "ZU", "doublesTier": "(DUU)"},
    "finneon": {"tier": "LC"},
    "lumineon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "snover": {"tier": "LC"},
    "abomasnow": {"tier": "PU", "doublesTier": "(DUU)"},
    "abomasnowmega": {"tier": "NU", "doublesTier": "DUU"},
    "rotom": {"tier": "NU", "doublesTier": "(DUU)"},
    "rotomheat": {"tier": "UU", "doublesTier": "(DUU)"},
    "rotomwash": {"tier": "OU", "doublesTier": "DUU"},
    "rotomfrost": {"tier": "PU", "doublesTier": "(DUU)"},
    "rotomfan": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rotommow": {"tier": "RU", "doublesTier": "(DUU)"},
    "uxie": {"tier": "RU", "doublesTier": "(DUU)"},
    "mesprit": {"tier": "NU", "doublesTier": "(DUU)"},
    "azelf": {"tier": "UU", "doublesTier": "(DUU)"},
    "dialga": {"tier": "Uber", "doublesTier": "DUber"},
    "palkia": {"tier": "Uber", "doublesTier": "DUber"},
    "heatran": {"tier": "OU", "doublesTier": "DOU"},
    "regigigas": {"tier": "ZU", "doublesTier": "(DUU)"},
    "giratina": {"tier": "Uber", "doublesTier": "DUber"},
    "giratinaorigin": {},
    "cresselia": {"tier": "RU", "doublesTier": "DOU"},
    "phione": {"tier": "ZU", "doublesTier": "(DUU)"},
    "manaphy": {"tier": "OU", "doublesTier": "(DUU)"},
    "darkrai": {"tier": "Uber", "doublesTier": "DUU"},
    "shaymin": {"tier": "RU", "doublesTier": "(DUU)"},
    "shayminsky": {"tier": "Uber", "doublesTier": "DUU"},
    "arceus": {"tier": "Uber", "doublesTier": "DUber"},
    "arceusbug": {},
    "arceusdark": {},
    "arceusdragon": {},
    "arceuselectric": {},
    "arceusfairy": {},
    "arceusfighting": {},
    "arceusfire": {},
    "arceusflying": {},
    "arceusghost": {},
    "arceusgrass": {},
    "arceusground": {},
    "arceusice": {},
    "arceuspoison": {},
    "arceuspsychic": {},
    "arceusrock": {},
    "arceussteel": {},
    "arceuswater": {},
    "victini": {"tier": "OU", "doublesTier": "DOU"},
    "snivy": {"tier": "LC"},
    "servine": {"tier": "NFE"},
    "serperior": {"tier": "OU", "doublesTier": "(DUU)"},
    "tepig": {"tier": "LC"},
    "pignite": {"tier": "NFE"},
    "emboar": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "oshawott": {"tier": "LC"},
    "dewott": {"tier": "NFE"},
    "samurott": {"tier": "NU", "doublesTier": "(DUU)"},
    "patrat": {"tier": "LC"},
    "watchog": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lillipup": {"tier": "LC"},
    "herdier": {"tier": "NFE"},
    "stoutland": {"tier": "PU", "doublesTier": "(DUU)"},
    "purrloin": {"tier": "LC"},
    "liepard": {"tier": "PU", "doublesTier": "(DUU)"},
    "pansage": {"tier": "LC"},
    "simisage": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pansear": {"tier": "LC"},
    "simisear": {"tier": "ZU", "doublesTier": "(DUU)"},
    "panpour": {"tier": "LC"},
    "simipour": {"tier": "ZU", "doublesTier": "(DUU)"},
    "munna": {"tier": "LC"},
    "musharna": {"tier": "PU", "doublesTier": "(DUU)"},
    "pidove": {"tier": "LC"},
    "tranquill": {"tier": "NFE"},
    "unfezant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "blitzle": {"tier": "LC"},
    "zebstrika": {"tier": "ZU", "doublesTier": "(DUU)"},
    "roggenrola": {"tier": "LC"},
    "boldore": {"tier": "NFE"},
    "gigalith": {"tier": "RU", "doublesTier": "(DUU)"},
    "woobat": {"tier": "LC"},
    "swoobat": {"tier": "ZU", "doublesTier": "(DUU)"},
    "drilbur": {"tier": "LC"},
    "excadrill": {"tier": "OU", "doublesTier": "DOU"},
    "audino": {"tier": "PU", "doublesTier": "(DUU)"},
    "audinomega": {"tier": "NU", "doublesTier": "(DUU)"},
    "timburr": {"tier": "LC"},
    "gurdurr": {"tier": "PU", "doublesTier": "NFE"},
    "conkeldurr": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "tympole": {"tier": "LC"},
    "palpitoad": {"tier": "NFE"},
    "seismitoad": {"tier": "NU", "doublesTier": "(DUU)"},
    "throh": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "sawk": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "sewaddle": {"tier": "LC"},
    "swadloon": {"tier": "NFE"},
    "leavanny": {"tier": "ZU", "doublesTier": "(DUU)"},
    "venipede": {"tier": "LC"},
    "whirlipede": {"tier": "NFE"},
    "scolipede": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "cottonee": {"tier": "LC"},
    "whimsicott": {"tier": "NU", "doublesTier": "DOU"},
    "petilil": {"tier": "LC"},
    "lilligant": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "basculin": {"tier": "ZU", "doublesTier": "(DUU)"},
    "basculinbluestriped": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sandile": {"tier": "LC"},
    "krokorok": {"tier": "NFE"},
    "krookodile": {"tier": "UU", "doublesTier": "DUU"},
    "darumaka": {"tier": "LC"},
    "darmanitan": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "maractus": {"tier": "ZU", "doublesTier": "(DUU)"},
    "dwebble": {"tier": "LC"},
    "crustle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "scraggy": {"tier": "LC"},
    "scrafty": {"tier": "NU", "doublesTier": "DOU"},
    "sigilyph": {"tier": "NU", "doublesTier": "(DUU)"},
    "yamask": {"tier": "LC"},
    "cofagrigus": {"tier": "NUBL", "doublesTier": "DUU"},
    "tirtouga": {"tier": "LC"},
    "carracosta": {"tier": "PU", "doublesTier": "(DUU)"},
    "archen": {"tier": "LC"},
    "archeops": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "trubbish": {"tier": "LC"},
    "garbodor": {"tier": "NU", "doublesTier": "(DUU)"},
    "zorua": {"tier": "LC"},
    "zoroark": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "minccino": {"tier": "LC"},
    "cinccino": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "gothita": {"tier": "NFE"},
    "gothorita": {"tier": "NFE"},
    "gothitelle": {"tier": "ZU", "doublesTier": "DOU"},
    "solosis": {"tier": "LC"},
    "duosion": {"tier": "NFE"},
    "reuniclus": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "ducklett": {"tier": "LC"},
    "swanna": {"tier": "ZU", "doublesTier": "(DUU)"},
    "vanillite": {"tier": "LC"},
    "vanillish": {"tier": "NFE"},
    "vanilluxe": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "deerling": {"tier": "LC"},
    "sawsbuck": {"tier": "ZU", "doublesTier": "(DUU)"},
    "emolga": {"tier": "ZU", "doublesTier": "(DUU)"},
    "karrablast": {"tier": "LC"},
    "escavalier": {"tier": "RU", "doublesTier": "(DUU)"},
    "foongus": {"tier": "LC"},
    "amoonguss": {"tier": "UU", "doublesTier": "DOU"},
    "frillish": {"tier": "LC"},
    "jellicent": {"tier": "PU", "doublesTier": "(DUU)"},
    "alomomola": {"tier": "UU", "doublesTier": "(DUU)"},
    "joltik": {"tier": "LC"},
    "galvantula": {"tier": "RU", "doublesTier": "(DUU)"},
    "ferroseed": {"tier": "PU", "doublesTier": "LC"},
    "ferrothorn": {"tier": "OU", "doublesTier": "DOU"},
    "klink": {"tier": "LC"},
    "klang": {"tier": "NFE"},
    "klinklang": {"tier": "NU", "doublesTier": "(DUU)"},
    "tynamo": {"tier": "LC"},
    "eelektrik": {"tier": "NFE"},
    "eelektross": {"tier": "PU", "doublesTier": "(DUU)"},
    "elgyem": {"tier": "LC"},
    "beheeyem": {"tier": "ZU", "doublesTier": "(DUU)"},
    "litwick": {"tier": "LC"},
    "lampent": {"tier": "NFE"},
    "chandelure": {"tier": "UU", "doublesTier": "DUU"},
    "axew": {"tier": "LC"},
    "fraxure": {"tier": "NFE"},
    "haxorus": {"tier": "UU", "doublesTier": "(DUU)"},
    "cubchoo": {"tier": "LC"},
    "beartic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cryogonal": {"tier": "PU", "doublesTier": "(DUU)"},
    "shelmet": {"tier": "LC"},
    "accelgor": {"tier": "NU", "doublesTier": "(DUU)"},
    "stunfisk": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mienfoo": {"tier": "LC"},
    "mienshao": {"tier": "RUBL", "doublesTier": "DUU"},
    "druddigon": {"tier": "NU", "doublesTier": "(DUU)"},
    "golett": {"tier": "LC"},
    "golurk": {"tier": "PU", "doublesTier": "(DUU)"},
    "pawniard": {"tier": "ZU", "doublesTier": "LC"},
    "bisharp": {"tier": "UU", "doublesTier": "DUU"},
    "bouffalant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rufflet": {"tier": "LC"},
    "braviary": {"tier": "NU", "doublesTier": "(DUU)"},
    "vullaby": {"tier": "LC"},
    "mandibuzz": {"tier": "RU", "doublesTier": "(DUU)"},
    "heatmor": {"tier": "ZU", "doublesTier": "(DUU)"},
    "durant": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "deino": {"tier": "LC"},
    "zweilous": {"tier": "NFE"},
    "hydreigon": {"tier": "UU", "doublesTier": "DUU"},
    "larvesta": {"tier": "LC"},
    "volcarona": {"tier": "OU", "doublesTier": "DOU"},
    "cobalion": {"tier": "UU", "doublesTier": "(DUU)"},
    "terrakion": {"tier": "UU", "doublesTier": "DOU"},
    "virizion": {"tier": "RU", "doublesTier": "(DUU)"},
    "tornadus": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "tornadustherian": {"tier": "OU", "doublesTier": "(DUU)"},
    "thundurus": {"tier": "UUBL", "doublesTier": "DUU"},
    "thundurustherian": {"tier": "UUBL", "doublesTier": "DUU"},
    "reshiram": {"tier": "Uber", "doublesTier": "DUber"},
    "zekrom": {"tier": "Uber", "doublesTier": "DUber"},
    "landorus": {"tier": "Uber", "doublesTier": "DUU"},
    "landorustherian": {"tier": "OU", "doublesTier": "DOU"},
    "kyurem": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "kyuremblack": {"tier": "OU", "doublesTier": "DOU"},
    "kyuremwhite": {"tier": "Uber", "doublesTier": "DUber"},
    "keldeo": {"tier": "OU", "doublesTier": "(DUU)"},
    "keldeoresolute": {"tier": "OU", "doublesTier": "(DUU)"},
    "meloetta": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "meloettapirouette": {},
    "genesect": {"tier": "Uber", "doublesTier": "DOU"},
    "genesectburn": {"tier": "Uber", "doublesTier": "(DOU)"},
    "genesectchill": {"tier": "Uber", "doublesTier": "(DOU)"},
    "genesectdouse": {"tier": "Uber", "doublesTier": "(DOU)"},
    "genesectshock": {"tier": "Uber", "doublesTier": "(DOU)"},
    "chespin": {"tier": "LC"},
    "quilladin": {"tier": "NFE"},
    "chesnaught": {"tier": "UU", "doublesTier": "(DUU)"},
    "fennekin": {"tier": "LC"},
    "braixen": {"tier": "NFE"},
    "delphox": {"tier": "NU", "doublesTier": "(DUU)"},
    "froakie": {"tier": "LC"},
    "frogadier": {"tier": "NFE"},
    "greninja": {"tier": "OU", "doublesTier": "DUU"},
    "greninjabond": {"tier": "OU", "doublesTier": "DUU"},
    "greninjaash": {"isNonstandard": null, "tier": "OU", "doublesTier": "DUU"},
    "bunnelby": {"tier": "LC"},
    "diggersby": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "fletchling": {"tier": "LC"},
    "fletchinder": {"tier": "NFE"},
    "talonflame": {"tier": "RUBL", "doublesTier": "DUU"},
    "scatterbug": {"tier": "LC"},
    "spewpa": {"tier": "NFE"},
    "vivillon": {"tier": "NU", "doublesTier": "(DUU)"},
    "vivillonfancy": {"tier": "NU", "doublesTier": "(DUU)"},
    "vivillonpokeball": {"tier": "NU", "doublesTier": "(DUU)"},
    "litleo": {"tier": "LC"},
    "pyroar": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "flabebe": {"tier": "LC"},
    "floette": {"tier": "NFE"},
    "floetteeternal": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "florges": {"tier": "RU", "doublesTier": "(DUU)"},
    "skiddo": {"tier": "LC"},
    "gogoat": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pancham": {"tier": "LC"},
    "pangoro": {"tier": "NU", "doublesTier": "(DUU)"},
    "furfrou": {"tier": "ZU", "doublesTier": "(DUU)"},
    "espurr": {"tier": "LC"},
    "meowstic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "meowsticf": {"tier": "ZU", "doublesTier": "(DUU)"},
    "honedge": {"tier": "LC"},
    "doublade": {"tier": "UU", "doublesTier": "NFE"},
    "aegislash": {"tier": "Uber", "doublesTier": "DOU"},
    "aegislashblade": {},
    "spritzee": {"tier": "LC"},
    "aromatisse": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "swirlix": {"tier": "NFE"},
    "slurpuff": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "inkay": {"tier": "LC"},
    "malamar": {"tier": "NU", "doublesTier": "(DUU)"},
    "binacle": {"tier": "LC"},
    "barbaracle": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "skrelp": {"tier": "LC"},
    "dragalge": {"tier": "RU", "doublesTier": "(DUU)"},
    "clauncher": {"tier": "LC"},
    "clawitzer": {"tier": "NU", "doublesTier": "(DUU)"},
    "helioptile": {"tier": "LC"},
    "heliolisk": {"tier": "NU", "doublesTier": "(DUU)"},
    "tyrunt": {"tier": "LC"},
    "tyrantrum": {"tier": "RU", "doublesTier": "(DUU)"},
    "amaura": {"tier": "LC"},
    "aurorus": {"tier": "PU", "doublesTier": "(DUU)"},
    "sylveon": {"tier": "UU", "doublesTier": "DUU"},
    "hawlucha": {"tier": "OU", "doublesTier": "(DUU)"},
    "dedenne": {"tier": "ZU", "doublesTier": "(DUU)"},
    "carbink": {"tier": "ZU", "doublesTier": "(DUU)"},
    "goomy": {"tier": "LC"},
    "sliggoo": {"tier": "NFE"},
    "goodra": {"tier": "RU", "doublesTier": "(DUU)"},
    "klefki": {"tier": "UU", "doublesTier": "(DUU)"},
    "phantump": {"tier": "LC"},
    "trevenant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pumpkaboo": {"tier": "LC"},
    "pumpkaboosmall": {"tier": "LC"},
    "pumpkaboolarge": {"tier": "LC"},
    "pumpkaboosuper": {"tier": "LC"},
    "gourgeist": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gourgeistsmall": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gourgeistlarge": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gourgeistsuper": {"tier": "ZU", "doublesTier": "(DUU)"},
    "bergmite": {"tier": "LC"},
    "avalugg": {"tier": "ZU", "doublesTier": "(DUU)"},
    "noibat": {"tier": "LC"},
    "noivern": {"tier": "RU", "doublesTier": "(DUU)"},
    "xerneas": {"tier": "Uber", "doublesTier": "DUber"},
    "yveltal": {"tier": "Uber", "doublesTier": "DUber"},
    "zygarde": {"tier": "Uber", "doublesTier": "DOU"},
    "zygarde10": {"tier": "RU", "doublesTier": "(DUU)"},
    "zygardecomplete": {"tier": "Uber", "doublesTier": "DUber"},
    "diancie": {"tier": "RU", "doublesTier": "DOU"},
    "dianciemega": {"tier": "OU", "doublesTier": "DOU"},
    "hoopa": {"tier": "RU", "doublesTier": "(DUU)"},
    "hoopaunbound": {"tier": "UUBL", "doublesTier": "DOU"},
    "volcanion": {"tier": "UU", "doublesTier": "DOU"},
    "rowlet": {"tier": "LC"},
    "dartrix": {"tier": "NFE"},
    "decidueye": {"tier": "NU", "doublesTier": "(DUU)"},
    "litten": {"tier": "LC"},
    "torracat": {"tier": "NFE"},
    "incineroar": {"tier": "NU", "doublesTier": "DOU"},
    "popplio": {"tier": "LC"},
    "brionne": {"tier": "NFE"},
    "primarina": {"tier": "UU", "doublesTier": "(DUU)"},
    "pikipek": {"tier": "LC"},
    "trumbeak": {"tier": "NFE"},
    "toucannon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "yungoos": {"tier": "LC"},
    "gumshoos": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gumshoostotem": {"tier": "ZU", "doublesTier": "(DUU)"},
    "grubbin": {"tier": "LC"},
    "charjabug": {"tier": "NFE"},
    "vikavolt": {"tier": "NU", "doublesTier": "DUU"},
    "vikavolttotem": {"tier": "NU", "doublesTier": "DUU"},
    "crabrawler": {"tier": "LC"},
    "crabominable": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "oricorio": {"tier": "ZU", "doublesTier": "(DUU)"},
    "oricoriopompom": {"tier": "PU", "doublesTier": "(DUU)"},
    "oricoriopau": {"tier": "ZU", "doublesTier": "(DUU)"},
    "oricoriosensu": {"tier": "PU", "doublesTier": "(DUU)"},
    "cutiefly": {"tier": "NFE"},
    "ribombee": {"tier": "RU", "doublesTier": "(DUU)"},
    "ribombeetotem": {"tier": "RU", "doublesTier": "(DUU)"},
    "rockruff": {"tier": "LC"},
    "rockruffdusk": {"tier": "LC"},
    "lycanroc": {"tier": "PU", "doublesTier": "(DUU)"},
    "lycanrocmidnight": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lycanrocdusk": {"tier": "RU", "doublesTier": "(DUU)"},
    "wishiwashi": {"tier": "ZU", "doublesTier": "(DUU)"},
    "wishiwashischool": {},
    "mareanie": {"tier": "ZU", "doublesTier": "LC"},
    "toxapex": {"tier": "OU", "doublesTier": "(DUU)"},
    "mudbray": {"tier": "LC"},
    "mudsdale": {"tier": "PU", "doublesTier": "(DUU)"},
    "dewpider": {"tier": "LC"},
    "araquanid": {"tier": "RU", "doublesTier": "DUU"},
    "araquanidtotem": {"tier": "RU", "doublesTier": "DUU"},
    "fomantis": {"tier": "LC"},
    "lurantis": {"tier": "PU", "doublesTier": "(DUU)"},
    "lurantistotem": {"tier": "PU", "doublesTier": "(DUU)"},
    "morelull": {"tier": "LC"},
    "shiinotic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "salandit": {"tier": "LC"},
    "salazzle": {"tier": "RU", "doublesTier": "(DUU)"},
    "salazzletotem": {"tier": "RU", "doublesTier": "(DUU)"},
    "stufful": {"tier": "LC"},
    "bewear": {"tier": "RU", "doublesTier": "(DUU)"},
    "bounsweet": {"tier": "LC"},
    "steenee": {"tier": "NFE"},
    "tsareena": {"tier": "RU", "doublesTier": "DUU"},
    "comfey": {"tier": "NU", "doublesTier": "(DUU)"},
    "oranguru": {"tier": "ZU", "doublesTier": "DUU"},
    "passimian": {"tier": "NU", "doublesTier": "(DUU)"},
    "wimpod": {"tier": "LC"},
    "golisopod": {"tier": "RU", "doublesTier": "(DUU)"},
    "sandygast": {"tier": "LC"},
    "palossand": {"tier": "NU", "doublesTier": "(DUU)"},
    "pyukumuku": {"tier": "ZU", "doublesTier": "(DUU)"},
    "typenull": {"tier": "ZUBL", "doublesTier": "NFE"},
    "silvally": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallybug": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallydark": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallydragon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyelectric": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyfairy": {"tier": "PU", "doublesTier": "(DUU)"},
    "silvallyfighting": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyfire": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyflying": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyghost": {"tier": "PU", "doublesTier": "(DUU)"},
    "silvallygrass": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyground": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyice": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallypoison": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallypsychic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyrock": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallysteel": {"tier": "NU", "doublesTier": "(DUU)"},
    "silvallywater": {"tier": "ZU", "doublesTier": "(DUU)"},
    "minior": {"tier": "NU", "doublesTier": "(DUU)"},
    "miniormeteor": {},
    "komala": {"tier": "ZU", "doublesTier": "(DUU)"},
    "turtonator": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "togedemaru": {"tier": "NU", "doublesTier": "DUU"},
    "togedemarutotem": {"tier": "NU", "doublesTier": "DUU"},
    "mimikyu": {"tier": "UU", "doublesTier": "DUU"},
    "mimikyutotem": {"tier": "UU", "doublesTier": "DUU"},
    "mimikyubustedtotem": {},
    "bruxish": {"tier": "NUBL", "doublesTier": "DUU"},
    "drampa": {"tier": "PU", "doublesTier": "(DUU)"},
    "dhelmise": {"tier": "NU", "doublesTier": "(DUU)"},
    "jangmoo": {"tier": "LC"},
    "hakamoo": {"tier": "NFE"},
    "kommoo": {"tier": "OU", "doublesTier": "DOU"},
    "kommoototem": {"tier": "OU", "doublesTier": "DOU"},
    "tapukoko": {"tier": "OU", "doublesTier": "DOU"},
    "tapulele": {"tier": "OU", "doublesTier": "DOU"},
    "tapubulu": {"tier": "OU", "doublesTier": "DOU"},
    "tapufini": {"tier": "OU", "doublesTier": "DOU"},
    "cosmog": {"tier": "LC"},
    "cosmoem": {"tier": "NFE"},
    "solgaleo": {"tier": "Uber", "doublesTier": "DUber"},
    "lunala": {"tier": "Uber", "doublesTier": "DUber"},
    "nihilego": {"tier": "UU", "doublesTier": "(DUU)"},
    "buzzwole": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "pheromosa": {"tier": "Uber", "doublesTier": "DUU"},
    "xurkitree": {"tier": "UUBL", "doublesTier": "DUU"},
    "celesteela": {"tier": "OU", "doublesTier": "DOU"},
    "kartana": {"tier": "OU", "doublesTier": "DOU"},
    "guzzlord": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "necrozma": {"tier": "RU", "doublesTier": "(DUU)"},
    "necrozmaduskmane": {"tier": "Uber", "doublesTier": "DUber"},
    "necrozmadawnwings": {"tier": "Uber", "doublesTier": "DUber"},
    "necrozmaultra": {"tier": "Uber", "doublesTier": "DUber"},
    "magearna": {"tier": "OU", "doublesTier": "DUber"},
    "magearnaoriginal": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "marshadow": {"tier": "Uber", "doublesTier": "DUber"},
    "poipole": {"tier": "NFE"},
    "naganadel": {"tier": "Uber", "doublesTier": "DUU"},
    "stakataka": {"tier": "RUBL", "doublesTier": "DOU"},
    "blacephalon": {"tier": "OU", "doublesTier": "DUU"},
    "zeraora": {"tier": "UU", "doublesTier": "DOU"},
    "meltan": {"isNonstandard": "LGPE", "tier": "Illegal"},
    "melmetal": {"isNonstandard": "LGPE", "tier": "Illegal"}
  },
  "8": {
    "bulbasaur": {"tier": "LC"},
    "ivysaur": {"tier": "NFE"},
    "venusaur": {"tier": "UU", "doublesTier": "(DUU)"},
    "venusaurmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "venusaurgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "charmander": {"tier": "LC"},
    "charmeleon": {"tier": "NFE"},
    "charizard": {"tier": "PU", "doublesTier": "(DUU)"},
    "charizardmegax": {"isNonstandard": "Past", "tier": "Illegal"},
    "charizardmegay": {"isNonstandard": "Past", "tier": "Illegal"},
    "charizardgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "squirtle": {"tier": "LC"},
    "wartortle": {"tier": "NFE"},
    "blastoise": {"tier": "NUBL", "doublesTier": "DUU"},
    "blastoisemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "blastoisegmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "caterpie": {"tier": "LC"},
    "metapod": {"tier": "NFE"},
    "butterfree": {"tier": "ZU", "doublesTier": "(DUU)"},
    "butterfreegmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "weedle": {"isNonstandard": "Past", "tier": "Illegal"},
    "kakuna": {"isNonstandard": "Past", "tier": "Illegal"},
    "beedrill": {"isNonstandard": "Past", "tier": "Illegal"},
    "beedrillmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "pidgey": {"isNonstandard": "Past", "tier": "Illegal"},
    "pidgeotto": {"isNonstandard": "Past", "tier": "Illegal"},
    "pidgeot": {"isNonstandard": "Past", "tier": "Illegal"},
    "pidgeotmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "rattata": {"isNonstandard": "Past", "tier": "Illegal"},
    "rattataalola": {"isNonstandard": "Past", "tier": "Illegal"},
    "raticate": {"isNonstandard": "Past", "tier": "Illegal"},
    "raticatealola": {"isNonstandard": "Past", "tier": "Illegal"},
    "raticatealolatotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "spearow": {"isNonstandard": "Past", "tier": "Illegal"},
    "fearow": {"isNonstandard": "Past", "tier": "Illegal"},
    "ekans": {"isNonstandard": "Past", "tier": "Illegal"},
    "arbok": {"isNonstandard": "Past", "tier": "Illegal"},
    "pichu": {"tier": "LC"},
    "pichuspikyeared": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachu": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachucosplay": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachurockstar": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachubelle": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachupopstar": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachuphd": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachulibre": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachuoriginal": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachuhoenn": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachusinnoh": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachuunova": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachukalos": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachualola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachupartner": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachustarter": {"isNonstandard": "LGPE", "tier": "Illegal"},
    "pikachugmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "pikachuworld": {"tier": "ZU", "doublesTier": "(DUU)"},
    "raichu": {"tier": "ZU", "doublesTier": "(DUU)"},
    "raichualola": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "sandshrew": {"tier": "LC"},
    "sandshrewalola": {"tier": "NUBL", "doublesTier": "LC"},
    "sandslash": {"tier": "PU", "doublesTier": "(DUU)"},
    "sandslashalola": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "nidoranf": {"tier": "LC"},
    "nidorina": {"tier": "NFE"},
    "nidoqueen": {"tier": "RU", "doublesTier": "(DUU)"},
    "nidoranm": {"tier": "LC"},
    "nidorino": {"tier": "NFE"},
    "nidoking": {"tier": "UU", "doublesTier": "(DUU)"},
    "cleffa": {"tier": "LC"},
    "clefairy": {"tier": "ZU", "doublesTier": "NFE"},
    "clefable": {"tier": "OU", "doublesTier": "(DUU)"},
    "vulpix": {"tier": "LC"},
    "vulpixalola": {"tier": "NFE"},
    "ninetales": {"tier": "ZU", "doublesTier": "DUU"},
    "ninetalesalola": {"tier": "OU", "doublesTier": "DOU"},
    "igglybuff": {"tier": "LC"},
    "jigglypuff": {"tier": "NFE"},
    "wigglytuff": {"tier": "ZU", "doublesTier": "(DUU)"},
    "zubat": {"tier": "LC"},
    "golbat": {"tier": "NFE"},
    "crobat": {"tier": "RU", "doublesTier": "(DUU)"},
    "oddish": {"tier": "LC"},
    "gloom": {"tier": "NFE"},
    "vileplume": {"tier": "NU", "doublesTier": "(DUU)"},
    "bellossom": {"tier": "ZU", "doublesTier": "(DUU)"},
    "paras": {"isNonstandard": "Past", "tier": "Illegal"},
    "parasect": {"isNonstandard": "Past", "tier": "Illegal"},
    "venonat": {"isNonstandard": "Past", "tier": "Illegal"},
    "venomoth": {"isNonstandard": "Past", "tier": "Illegal"},
    "diglett": {"tier": "LC"},
    "diglettalola": {"tier": "LC"},
    "dugtrio": {"tier": "ZU", "doublesTier": "(DUU)"},
    "dugtrioalola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "meowth": {"tier": "LC"},
    "meowthalola": {"tier": "LC"},
    "meowthgalar": {"tier": "LC"},
    "meowthgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "persian": {"tier": "ZU", "doublesTier": "(DUU)"},
    "persianalola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "perrserker": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "psyduck": {"tier": "LC"},
    "golduck": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mankey": {"isNonstandard": "Past", "tier": "Illegal"},
    "primeape": {"isNonstandard": "Past", "tier": "Illegal"},
    "growlithe": {"tier": "LC"},
    "growlithehisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "arcanine": {"tier": "NU", "doublesTier": "DUU"},
    "arcaninehisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "poliwag": {"tier": "LC"},
    "poliwhirl": {"tier": "NFE"},
    "poliwrath": {"tier": "ZU", "doublesTier": "(DUU)"},
    "politoed": {"tier": "ZU", "doublesTier": "DUU"},
    "abra": {"tier": "LC"},
    "kadabra": {"tier": "NFE"},
    "alakazam": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "alakazammega": {"isNonstandard": "Past", "tier": "Illegal"},
    "machop": {"tier": "LC"},
    "machoke": {"tier": "NFE"},
    "machamp": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "machampgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "bellsprout": {"isNonstandard": "Past", "tier": "Illegal"},
    "weepinbell": {"isNonstandard": "Past", "tier": "Illegal"},
    "victreebel": {"isNonstandard": "Past", "tier": "Illegal"},
    "tentacool": {"tier": "LC"},
    "tentacruel": {"tier": "RU", "doublesTier": "(DUU)"},
    "geodude": {"isNonstandard": "Past", "tier": "Illegal"},
    "geodudealola": {"isNonstandard": "Past", "tier": "Illegal"},
    "graveler": {"isNonstandard": "Past", "tier": "Illegal"},
    "graveleralola": {"isNonstandard": "Past", "tier": "Illegal"},
    "golem": {"isNonstandard": "Past", "tier": "Illegal"},
    "golemalola": {"isNonstandard": "Past", "tier": "Illegal"},
    "ponyta": {"tier": "LC"},
    "ponytagalar": {"tier": "LC"},
    "rapidash": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rapidashgalar": {"tier": "ZU", "doublesTier": "(DUU)"},
    "slowpoke": {"tier": "LC"},
    "slowpokegalar": {"tier": "LC"},
    "slowbro": {"tier": "OU", "doublesTier": "(DUU)"},
    "slowbromega": {"isNonstandard": "Past", "tier": "Illegal"},
    "slowbrogalar": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "slowking": {"tier": "UU", "doublesTier": "(DUU)"},
    "slowkinggalar": {"tier": "OU", "doublesTier": "(DUU)"},
    "magnemite": {"tier": "LC"},
    "magneton": {"tier": "PU", "doublesTier": "NFE"},
    "magnezone": {"tier": "OU", "doublesTier": "(DUU)"},
    "farfetchd": {"tier": "ZU", "doublesTier": "(DUU)"},
    "farfetchdgalar": {"tier": "LC"},
    "sirfetchd": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "doduo": {"isNonstandard": "Past", "tier": "Illegal"},
    "dodrio": {"isNonstandard": "Past", "tier": "Illegal"},
    "seel": {"isNonstandard": "Past", "tier": "Illegal"},
    "dewgong": {"isNonstandard": "Past", "tier": "Illegal"},
    "grimer": {"isNonstandard": "Past", "tier": "Illegal"},
    "grimeralola": {"isNonstandard": "Past", "tier": "Illegal"},
    "muk": {"isNonstandard": "Past", "tier": "Illegal"},
    "mukalola": {"isNonstandard": "Past", "tier": "Illegal"},
    "shellder": {"tier": "LC"},
    "cloyster": {"tier": "RU", "doublesTier": "(DUU)"},
    "gastly": {"tier": "NFE"},
    "haunter": {"tier": "ZUBL", "doublesTier": "NFE"},
    "gengar": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "gengarmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "gengargmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "onix": {"tier": "LC"},
    "steelix": {"tier": "RU", "doublesTier": "(DUU)"},
    "steelixmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "drowzee": {"isNonstandard": "Past", "tier": "Illegal"},
    "hypno": {"isNonstandard": "Past", "tier": "Illegal"},
    "krabby": {"tier": "LC"},
    "kingler": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "kinglergmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "voltorb": {"isNonstandard": "Past", "tier": "Illegal"},
    "voltorbhisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "electrode": {"isNonstandard": "Past", "tier": "Illegal"},
    "electrodehisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "exeggcute": {"tier": "LC"},
    "exeggutor": {"tier": "ZU", "doublesTier": "(DUU)"},
    "exeggutoralola": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "cubone": {"tier": "LC"},
    "marowak": {"tier": "ZU", "doublesTier": "(DUU)"},
    "marowakalola": {"tier": "RU", "doublesTier": "(DUU)"},
    "marowakalolatotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "tyrogue": {"tier": "LC"},
    "hitmonlee": {"tier": "PU", "doublesTier": "(DUU)"},
    "hitmonchan": {"tier": "ZU", "doublesTier": "(DUU)"},
    "hitmontop": {"tier": "NU", "doublesTier": "DUU"},
    "lickitung": {"tier": "LC"},
    "lickilicky": {"tier": "ZU", "doublesTier": "(DUU)"},
    "koffing": {"tier": "LC"},
    "weezing": {"tier": "PU", "doublesTier": "(DUU)"},
    "weezinggalar": {"tier": "RU", "doublesTier": "(DUU)"},
    "rhyhorn": {"tier": "LC"},
    "rhydon": {"tier": "ZU", "doublesTier": "NFE"},
    "rhyperior": {"tier": "RU", "doublesTier": "(DUU)"},
    "happiny": {"tier": "LC"},
    "chansey": {"tier": "UU", "doublesTier": "NFE"},
    "blissey": {"tier": "OU", "doublesTier": "(DUU)"},
    "tangela": {"tier": "NFE"},
    "tangrowth": {"tier": "UU", "doublesTier": "(DUU)"},
    "kangaskhan": {"tier": "ZU", "doublesTier": "(DUU)"},
    "kangaskhanmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "horsea": {"tier": "LC"},
    "seadra": {"tier": "NFE"},
    "kingdra": {"tier": "PUBL", "doublesTier": "DUU"},
    "goldeen": {"tier": "LC"},
    "seaking": {"tier": "ZU", "doublesTier": "(DUU)"},
    "staryu": {"tier": "LC"},
    "starmie": {"tier": "NU", "doublesTier": "(DUU)"},
    "mimejr": {"tier": "LC"},
    "mrmime": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mrmimegalar": {"tier": "NFE"},
    "mrrime": {"tier": "ZU", "doublesTier": "(DUU)"},
    "scyther": {"tier": "PUBL", "doublesTier": "NFE"},
    "scizor": {"tier": "UU", "doublesTier": "DUU"},
    "scizormega": {"isNonstandard": "Past", "tier": "Illegal"},
    "smoochum": {"tier": "LC"},
    "jynx": {"tier": "ZU", "doublesTier": "(DUU)"},
    "elekid": {"tier": "LC"},
    "electabuzz": {"tier": "NFE"},
    "electivire": {"tier": "ZU", "doublesTier": "(DUU)"},
    "magby": {"tier": "NFE"},
    "magmar": {"tier": "NFE"},
    "magmortar": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "pinsir": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pinsirmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "tauros": {"tier": "NU", "doublesTier": "(DUU)"},
    "magikarp": {"tier": "LC"},
    "gyarados": {"tier": "UU", "doublesTier": "(DUU)"},
    "gyaradosmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "lapras": {"tier": "ZU", "doublesTier": "(DUU)"},
    "laprasgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "ditto": {"tier": "ZU", "doublesTier": "(DUU)"},
    "eevee": {"tier": "LC"},
    "eeveestarter": {"isNonstandard": "LGPE", "tier": "Illegal"},
    "eeveegmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "vaporeon": {"tier": "NU", "doublesTier": "(DUU)"},
    "jolteon": {"tier": "PU", "doublesTier": "(DUU)"},
    "flareon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "espeon": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "umbreon": {"tier": "RU", "doublesTier": "(DUU)"},
    "leafeon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "glaceon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sylveon": {"tier": "NU", "doublesTier": "DUU"},
    "porygon": {"tier": "LC"},
    "porygon2": {"tier": "RU", "doublesTier": "DOU"},
    "porygonz": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "omanyte": {"tier": "LC"},
    "omastar": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "kabuto": {"tier": "LC"},
    "kabutops": {"tier": "ZU", "doublesTier": "(DUU)"},
    "aerodactyl": {"tier": "NU", "doublesTier": "(DUU)"},
    "aerodactylmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "munchlax": {"tier": "LC"},
    "snorlax": {"tier": "NU", "doublesTier": "(DUU)"},
    "snorlaxgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "articuno": {"tier": "ZU", "doublesTier": "(DUU)"},
    "articunogalar": {"tier": "PU", "doublesTier": "(DUU)"},
    "zapdos": {"tier": "OU", "doublesTier": "DOU"},
    "zapdosgalar": {"tier": "UUBL", "doublesTier": "DUU"},
    "moltres": {"tier": "UU", "doublesTier": "(DUU)"},
    "moltresgalar": {"tier": "UUBL", "doublesTier": "DUU"},
    "dratini": {"tier": "LC"},
    "dragonair": {"tier": "NFE"},
    "dragonite": {"tier": "OU", "doublesTier": "(DUU)"},
    "mewtwo": {"tier": "Uber", "doublesTier": "DUber"},
    "mewtwomegax": {"isNonstandard": "Past", "tier": "Illegal"},
    "mewtwomegay": {"isNonstandard": "Past", "tier": "Illegal"},
    "mew": {"tier": "OU", "doublesTier": "DOU"},
    "chikorita": {"isNonstandard": "Past", "tier": "Illegal"},
    "bayleef": {"isNonstandard": "Past", "tier": "Illegal"},
    "meganium": {"isNonstandard": "Past", "tier": "Illegal"},
    "cyndaquil": {"isNonstandard": "Past", "tier": "Illegal"},
    "quilava": {"isNonstandard": "Past", "tier": "Illegal"},
    "typhlosion": {"isNonstandard": "Past", "tier": "Illegal"},
    "typhlosionhisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "totodile": {"isNonstandard": "Past", "tier": "Illegal"},
    "croconaw": {"isNonstandard": "Past", "tier": "Illegal"},
    "feraligatr": {"isNonstandard": "Past", "tier": "Illegal"},
    "sentret": {"isNonstandard": "Past", "tier": "Illegal"},
    "furret": {"isNonstandard": "Past", "tier": "Illegal"},
    "hoothoot": {"tier": "LC"},
    "noctowl": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ledyba": {"isNonstandard": "Past", "tier": "Illegal"},
    "ledian": {"isNonstandard": "Past", "tier": "Illegal"},
    "spinarak": {"isNonstandard": "Past", "tier": "Illegal"},
    "ariados": {"isNonstandard": "Past", "tier": "Illegal"},
    "chinchou": {"tier": "LC"},
    "lanturn": {"tier": "PU", "doublesTier": "(DUU)"},
    "togepi": {"tier": "LC"},
    "togetic": {"tier": "NFE"},
    "togekiss": {"tier": "RU", "doublesTier": "DUU"},
    "natu": {"tier": "LC"},
    "xatu": {"tier": "NU", "doublesTier": "(DUU)"},
    "mareep": {"isNonstandard": "Past", "tier": "Illegal"},
    "flaaffy": {"isNonstandard": "Past", "tier": "Illegal"},
    "ampharos": {"isNonstandard": "Past", "tier": "Illegal"},
    "ampharosmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "azurill": {"tier": "LC"},
    "marill": {"tier": "NFE"},
    "azumarill": {"tier": "UU", "doublesTier": "(DUU)"},
    "bonsly": {"tier": "LC"},
    "sudowoodo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "hoppip": {"isNonstandard": "Past", "tier": "Illegal"},
    "skiploom": {"isNonstandard": "Past", "tier": "Illegal"},
    "jumpluff": {"isNonstandard": "Past", "tier": "Illegal"},
    "aipom": {"isNonstandard": "Past", "tier": "Illegal"},
    "ambipom": {"isNonstandard": "Past", "tier": "Illegal"},
    "sunkern": {"isNonstandard": "Past", "tier": "Illegal"},
    "sunflora": {"isNonstandard": "Past", "tier": "Illegal"},
    "yanma": {"isNonstandard": "Past", "tier": "Illegal"},
    "yanmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "wooper": {"tier": "LC"},
    "quagsire": {"tier": "PU", "doublesTier": "(DUU)"},
    "murkrow": {"isNonstandard": "Past", "tier": "Illegal"},
    "honchkrow": {"isNonstandard": "Past", "tier": "Illegal"},
    "misdreavus": {"isNonstandard": "Past", "tier": "Illegal"},
    "mismagius": {"isNonstandard": "Past", "tier": "Illegal"},
    "unown": {"isNonstandard": "Past", "tier": "Illegal"},
    "wynaut": {"tier": "LC"},
    "wobbuffet": {"tier": "ZU", "doublesTier": "(DUU)"},
    "girafarig": {"isNonstandard": "Past", "tier": "Illegal"},
    "pineco": {"isNonstandard": "Past", "tier": "Illegal"},
    "forretress": {"isNonstandard": "Past", "tier": "Illegal"},
    "dunsparce": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gligar": {"isNonstandard": "Past", "tier": "Illegal"},
    "gliscor": {"isNonstandard": "Past", "tier": "Illegal"},
    "snubbull": {"isNonstandard": "Past", "tier": "Illegal"},
    "granbull": {"isNonstandard": "Past", "tier": "Illegal"},
    "qwilfish": {"tier": "ZU", "doublesTier": "(DUU)"},
    "qwilfishhisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "shuckle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "heracross": {"tier": "RU", "doublesTier": "(DUU)"},
    "heracrossmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "sneasel": {"tier": "ZUBL", "doublesTier": "NFE"},
    "sneaselhisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "weavile": {"tier": "OU", "doublesTier": "DUU"},
    "teddiursa": {"isNonstandard": "Past", "tier": "Illegal"},
    "ursaring": {"isNonstandard": "Past", "tier": "Illegal"},
    "slugma": {"isNonstandard": "Past", "tier": "Illegal"},
    "magcargo": {"isNonstandard": "Past", "tier": "Illegal"},
    "swinub": {"tier": "LC"},
    "piloswine": {"tier": "ZU", "doublesTier": "NFE"},
    "mamoswine": {"tier": "UU", "doublesTier": "(DUU)"},
    "corsola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "corsolagalar": {"tier": "NFE"},
    "cursola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "remoraid": {"tier": "LC"},
    "octillery": {"tier": "ZU", "doublesTier": "(DUU)"},
    "delibird": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mantyke": {"tier": "LC"},
    "mantine": {"tier": "NU", "doublesTier": "(DUU)"},
    "skarmory": {"tier": "UU", "doublesTier": "(DUU)"},
    "houndour": {"isNonstandard": "Past", "tier": "Illegal"},
    "houndoom": {"isNonstandard": "Past", "tier": "Illegal"},
    "houndoommega": {"isNonstandard": "Past", "tier": "Illegal"},
    "phanpy": {"isNonstandard": "Past", "tier": "Illegal"},
    "donphan": {"isNonstandard": "Past", "tier": "Illegal"},
    "stantler": {"isNonstandard": "Past", "tier": "Illegal"},
    "smeargle": {"isNonstandard": "Past", "tier": "Illegal"},
    "miltank": {"tier": "ZU", "doublesTier": "(DUU)"},
    "raikou": {"tier": "RU", "doublesTier": "(DUU)"},
    "entei": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "suicune": {"tier": "RU", "doublesTier": "(DUU)"},
    "larvitar": {"tier": "LC"},
    "pupitar": {"tier": "NFE"},
    "tyranitar": {"tier": "OU", "doublesTier": "DOU"},
    "tyranitarmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "lugia": {"tier": "Uber", "doublesTier": "DUber"},
    "hooh": {"tier": "Uber", "doublesTier": "DUber"},
    "celebi": {"tier": "RU", "doublesTier": "(DUU)"},
    "treecko": {"tier": "LC"},
    "grovyle": {"tier": "NFE"},
    "sceptile": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "sceptilemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "torchic": {"tier": "LC"},
    "combusken": {"tier": "NFE"},
    "blaziken": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "blazikenmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "mudkip": {"tier": "LC"},
    "marshtomp": {"tier": "NFE"},
    "swampert": {"tier": "UU", "doublesTier": "(DUU)"},
    "swampertmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "poochyena": {"isNonstandard": "Past", "tier": "Illegal"},
    "mightyena": {"isNonstandard": "Past", "tier": "Illegal"},
    "zigzagoon": {"tier": "NFE"},
    "zigzagoongalar": {"tier": "LC"},
    "linoone": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "linoonegalar": {"tier": "NFE"},
    "obstagoon": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "wurmple": {"isNonstandard": "Past", "tier": "Illegal"},
    "silcoon": {"isNonstandard": "Past", "tier": "Illegal"},
    "beautifly": {"isNonstandard": "Past", "tier": "Illegal"},
    "cascoon": {"isNonstandard": "Past", "tier": "Illegal"},
    "dustox": {"isNonstandard": "Past", "tier": "Illegal"},
    "lotad": {"tier": "LC"},
    "lombre": {"tier": "NFE"},
    "ludicolo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "seedot": {"tier": "LC"},
    "nuzleaf": {"tier": "NFE"},
    "shiftry": {"tier": "ZU", "doublesTier": "(DUU)"},
    "taillow": {"isNonstandard": "Past", "tier": "Illegal"},
    "swellow": {"isNonstandard": "Past", "tier": "Illegal"},
    "wingull": {"tier": "LC"},
    "pelipper": {"tier": "OU", "doublesTier": "DOU"},
    "ralts": {"tier": "LC"},
    "kirlia": {"tier": "NFE"},
    "gardevoir": {"tier": "RU", "doublesTier": "(DUU)"},
    "gardevoirmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "gallade": {"tier": "PU", "doublesTier": "(DUU)"},
    "gallademega": {"isNonstandard": "Past", "tier": "Illegal"},
    "surskit": {"isNonstandard": "Past", "tier": "Illegal"},
    "masquerain": {"isNonstandard": "Past", "tier": "Illegal"},
    "shroomish": {"isNonstandard": "Past", "tier": "Illegal"},
    "breloom": {"isNonstandard": "Past", "tier": "Illegal"},
    "slakoth": {"isNonstandard": "Past", "tier": "Illegal"},
    "vigoroth": {"isNonstandard": "Past", "tier": "Illegal"},
    "slaking": {"isNonstandard": "Past", "tier": "Illegal"},
    "nincada": {"tier": "LC"},
    "ninjask": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "shedinja": {"tier": "ZU", "doublesTier": "(DUU)"},
    "whismur": {"tier": "LC"},
    "loudred": {"tier": "NFE"},
    "exploud": {"tier": "NU", "doublesTier": "(DUU)"},
    "makuhita": {"isNonstandard": "Past", "tier": "Illegal"},
    "hariyama": {"isNonstandard": "Past", "tier": "Illegal"},
    "nosepass": {"isNonstandard": "Past", "tier": "Illegal"},
    "probopass": {"isNonstandard": "Past", "tier": "Illegal"},
    "skitty": {"isNonstandard": "Past", "tier": "Illegal"},
    "delcatty": {"isNonstandard": "Past", "tier": "Illegal"},
    "sableye": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sableyemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "mawile": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mawilemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "aron": {"tier": "LC"},
    "lairon": {"tier": "NFE"},
    "aggron": {"tier": "PU", "doublesTier": "(DUU)"},
    "aggronmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "meditite": {"isNonstandard": "Past", "tier": "Illegal"},
    "medicham": {"isNonstandard": "Past", "tier": "Illegal"},
    "medichammega": {"isNonstandard": "Past", "tier": "Illegal"},
    "electrike": {"tier": "LC"},
    "manectric": {"tier": "ZU", "doublesTier": "(DUU)"},
    "manectricmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "plusle": {"isNonstandard": "Past", "tier": "Illegal"},
    "minun": {"isNonstandard": "Past", "tier": "Illegal"},
    "volbeat": {"isNonstandard": "Past", "tier": "Illegal"},
    "illumise": {"isNonstandard": "Past", "tier": "Illegal"},
    "budew": {"tier": "LC"},
    "roselia": {"tier": "NFE"},
    "roserade": {"tier": "RU", "doublesTier": "(DUU)"},
    "gulpin": {"isNonstandard": "Past", "tier": "Illegal"},
    "swalot": {"isNonstandard": "Past", "tier": "Illegal"},
    "carvanha": {"tier": "LC"},
    "sharpedo": {"tier": "RU", "doublesTier": "(DUU)"},
    "sharpedomega": {"isNonstandard": "Past", "tier": "Illegal"},
    "wailmer": {"tier": "LC"},
    "wailord": {"tier": "ZU", "doublesTier": "(DUU)"},
    "numel": {"isNonstandard": "Past", "tier": "Illegal"},
    "camerupt": {"isNonstandard": "Past", "tier": "Illegal"},
    "cameruptmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "torkoal": {"tier": "UU", "doublesTier": "DOU"},
    "spoink": {"isNonstandard": "Past", "tier": "Illegal"},
    "grumpig": {"isNonstandard": "Past", "tier": "Illegal"},
    "spinda": {"isNonstandard": "Past", "tier": "Illegal"},
    "trapinch": {"tier": "LC"},
    "vibrava": {"tier": "NFE"},
    "flygon": {"tier": "RU", "doublesTier": "(DUU)"},
    "cacnea": {"isNonstandard": "Past", "tier": "Illegal"},
    "cacturne": {"isNonstandard": "Past", "tier": "Illegal"},
    "swablu": {"tier": "LC"},
    "altaria": {"tier": "ZU", "doublesTier": "(DUU)"},
    "altariamega": {"isNonstandard": "Past", "tier": "Illegal"},
    "zangoose": {"isNonstandard": "Past", "tier": "Illegal"},
    "seviper": {"isNonstandard": "Past", "tier": "Illegal"},
    "lunatone": {"tier": "ZU", "doublesTier": "(DUU)"},
    "solrock": {"tier": "ZU", "doublesTier": "(DUU)"},
    "barboach": {"tier": "LC"},
    "whiscash": {"tier": "ZU", "doublesTier": "(DUU)"},
    "corphish": {"tier": "LC"},
    "crawdaunt": {"tier": "UU", "doublesTier": "(DUU)"},
    "baltoy": {"tier": "LC"},
    "claydol": {"tier": "PU", "doublesTier": "(DUU)"},
    "lileep": {"tier": "LC"},
    "cradily": {"tier": "ZU", "doublesTier": "(DUU)"},
    "anorith": {"tier": "LC"},
    "armaldo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "feebas": {"tier": "LC"},
    "milotic": {"tier": "RU", "doublesTier": "DUU"},
    "castform": {"isNonstandard": "Past", "tier": "Illegal"},
    "castformsunny": {"isNonstandard": "Past"},
    "castformrainy": {"isNonstandard": "Past"},
    "castformsnowy": {"isNonstandard": "Past"},
    "kecleon": {"isNonstandard": "Past", "tier": "Illegal"},
    "shuppet": {"isNonstandard": "Past", "tier": "Illegal"},
    "banette": {"isNonstandard": "Past", "tier": "Illegal"},
    "banettemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "duskull": {"tier": "LC"},
    "dusclops": {"tier": "NFE"},
    "dusknoir": {"tier": "ZU", "doublesTier": "(DUU)"},
    "tropius": {"isNonstandard": "Past", "tier": "Illegal"},
    "chingling": {"isNonstandard": "Past", "tier": "Illegal"},
    "chimecho": {"isNonstandard": "Past", "tier": "Illegal"},
    "absol": {"tier": "PU", "doublesTier": "(DUU)"},
    "absolmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "snorunt": {"tier": "LC"},
    "glalie": {"tier": "ZU", "doublesTier": "(DUU)"},
    "glaliemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "froslass": {"tier": "ZU", "doublesTier": "(DUU)"},
    "spheal": {"tier": "LC"},
    "sealeo": {"tier": "NFE"},
    "walrein": {"tier": "ZU", "doublesTier": "(DUU)"},
    "clamperl": {"isNonstandard": "Past", "tier": "Illegal"},
    "huntail": {"isNonstandard": "Past", "tier": "Illegal"},
    "gorebyss": {"isNonstandard": "Past", "tier": "Illegal"},
    "relicanth": {"tier": "ZU", "doublesTier": "(DUU)"},
    "luvdisc": {"isNonstandard": "Past", "tier": "Illegal"},
    "bagon": {"tier": "LC"},
    "shelgon": {"tier": "NFE"},
    "salamence": {"tier": "UU", "doublesTier": "(DUU)"},
    "salamencemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "beldum": {"tier": "LC"},
    "metang": {"tier": "NFE"},
    "metagross": {"tier": "RU", "doublesTier": "DOU"},
    "metagrossmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "regirock": {"tier": "PU", "doublesTier": "(DUU)"},
    "regice": {"tier": "ZU", "doublesTier": "(DUU)"},
    "registeel": {"tier": "RU", "doublesTier": "(DUU)"},
    "latias": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "latiasmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "latios": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "latiosmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "kyogre": {"tier": "Uber", "doublesTier": "DUber"},
    "kyogreprimal": {"isNonstandard": "Past", "tier": "Illegal"},
    "groudon": {"tier": "Uber", "doublesTier": "DUber"},
    "groudonprimal": {"isNonstandard": "Past", "tier": "Illegal"},
    "rayquaza": {"tier": "Uber", "doublesTier": "DUber"},
    "rayquazamega": {"isNonstandard": "Past", "tier": "Illegal"},
    "jirachi": {"tier": "UU", "doublesTier": "DUber"},
    "deoxys": {"isNonstandard": "Past", "tier": "Illegal"},
    "deoxysattack": {"isNonstandard": "Past", "tier": "Illegal"},
    "deoxysdefense": {"isNonstandard": "Past", "tier": "Illegal"},
    "deoxysspeed": {"isNonstandard": "Past", "tier": "Illegal"},
    "turtwig": {"isNonstandard": "Past", "tier": "Illegal"},
    "grotle": {"isNonstandard": "Past", "tier": "Illegal"},
    "torterra": {"isNonstandard": "Past", "tier": "Illegal"},
    "chimchar": {"isNonstandard": "Past", "tier": "Illegal"},
    "monferno": {"isNonstandard": "Past", "tier": "Illegal"},
    "infernape": {"isNonstandard": "Past", "tier": "Illegal"},
    "piplup": {"isNonstandard": "Past", "tier": "Illegal"},
    "prinplup": {"isNonstandard": "Past", "tier": "Illegal"},
    "empoleon": {"isNonstandard": "Past", "tier": "Illegal"},
    "starly": {"isNonstandard": "Past", "tier": "Illegal"},
    "staravia": {"isNonstandard": "Past", "tier": "Illegal"},
    "staraptor": {"isNonstandard": "Past", "tier": "Illegal"},
    "bidoof": {"isNonstandard": "Past", "tier": "Illegal"},
    "bibarel": {"isNonstandard": "Past", "tier": "Illegal"},
    "kricketot": {"isNonstandard": "Past", "tier": "Illegal"},
    "kricketune": {"isNonstandard": "Past", "tier": "Illegal"},
    "shinx": {"tier": "LC"},
    "luxio": {"tier": "NFE"},
    "luxray": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cranidos": {"isNonstandard": "Past", "tier": "Illegal"},
    "rampardos": {"isNonstandard": "Past", "tier": "Illegal"},
    "shieldon": {"isNonstandard": "Past", "tier": "Illegal"},
    "bastiodon": {"isNonstandard": "Past", "tier": "Illegal"},
    "burmy": {"isNonstandard": "Past", "tier": "Illegal"},
    "wormadam": {"isNonstandard": "Past", "tier": "Illegal"},
    "wormadamsandy": {"isNonstandard": "Past", "tier": "Illegal"},
    "wormadamtrash": {"isNonstandard": "Past", "tier": "Illegal"},
    "mothim": {"isNonstandard": "Past", "tier": "Illegal"},
    "combee": {"tier": "LC"},
    "vespiquen": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pachirisu": {"isNonstandard": "Past", "tier": "Illegal"},
    "buizel": {"isNonstandard": "Past", "tier": "Illegal"},
    "floatzel": {"isNonstandard": "Past", "tier": "Illegal"},
    "cherubi": {"tier": "NFE"},
    "cherrim": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cherrimsunshine": {},
    "shellos": {"tier": "LC"},
    "gastrodon": {"tier": "NU", "doublesTier": "DOU"},
    "drifloon": {"tier": "NFE"},
    "drifblim": {"tier": "ZU", "doublesTier": "(DUU)"},
    "buneary": {"tier": "LC"},
    "lopunny": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lopunnymega": {"isNonstandard": "Past", "tier": "Illegal"},
    "glameow": {"isNonstandard": "Past", "tier": "Illegal"},
    "purugly": {"isNonstandard": "Past", "tier": "Illegal"},
    "stunky": {"tier": "LC"},
    "skuntank": {"tier": "ZU", "doublesTier": "(DUU)"},
    "bronzor": {"tier": "LC"},
    "bronzong": {"tier": "RU", "doublesTier": "(DUU)"},
    "chatot": {"isNonstandard": "Past", "tier": "Illegal"},
    "spiritomb": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gible": {"tier": "LC"},
    "gabite": {"tier": "NFE"},
    "garchomp": {"tier": "OU", "doublesTier": "(DUU)"},
    "garchompmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "riolu": {"tier": "LC"},
    "lucario": {"tier": "RU", "doublesTier": "(DUU)"},
    "lucariomega": {"isNonstandard": "Past", "tier": "Illegal"},
    "hippopotas": {"tier": "LC"},
    "hippowdon": {"tier": "UU", "doublesTier": "(DUU)"},
    "skorupi": {"tier": "LC"},
    "drapion": {"tier": "NU", "doublesTier": "(DUU)"},
    "croagunk": {"tier": "LC"},
    "toxicroak": {"tier": "NU", "doublesTier": "(DUU)"},
    "carnivine": {"isNonstandard": "Past", "tier": "Illegal"},
    "finneon": {"isNonstandard": "Past", "tier": "Illegal"},
    "lumineon": {"isNonstandard": "Past", "tier": "Illegal"},
    "snover": {"tier": "LC"},
    "abomasnow": {"tier": "ZU", "doublesTier": "(DUU)"},
    "abomasnowmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "rotom": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rotomheat": {"tier": "UU", "doublesTier": "(DUU)"},
    "rotomwash": {"tier": "UU", "doublesTier": "(DUU)"},
    "rotomfrost": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "rotomfan": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rotommow": {"tier": "NU", "doublesTier": "(DUU)"},
    "uxie": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mesprit": {"tier": "PU", "doublesTier": "(DUU)"},
    "azelf": {"tier": "UU", "doublesTier": "(DUU)"},
    "dialga": {"tier": "Uber", "doublesTier": "DUber"},
    "dialgaorigin": {"isNonstandard": "Future", "tier": "Illegal"},
    "palkia": {"tier": "Uber", "doublesTier": "DUber"},
    "palkiaorigin": {"isNonstandard": "Future", "tier": "Illegal"},
    "heatran": {"tier": "OU", "doublesTier": "DOU"},
    "regigigas": {"tier": "ZU", "doublesTier": "(DUU)"},
    "giratina": {"tier": "Uber", "doublesTier": "DUber"},
    "giratinaorigin": {"tier": "Uber", "doublesTier": "DUber"},
    "cresselia": {"tier": "NUBL", "doublesTier": "DUU"},
    "phione": {"isNonstandard": "Past", "tier": "Illegal"},
    "manaphy": {"isNonstandard": "Past", "tier": "Illegal"},
    "darkrai": {"isNonstandard": "Past", "tier": "Illegal"},
    "shaymin": {"isNonstandard": "Past", "tier": "Illegal"},
    "shayminsky": {"isNonstandard": "Past", "tier": "Illegal"},
    "arceus": {"isNonstandard": "Past", "tier": "Illegal"},
    "arceusbug": {"isNonstandard": "Past"},
    "arceusdark": {"isNonstandard": "Past"},
    "arceusdragon": {"isNonstandard": "Past"},
    "arceuselectric": {"isNonstandard": "Past"},
    "arceusfairy": {"isNonstandard": "Past"},
    "arceusfighting": {"isNonstandard": "Past"},
    "arceusfire": {"isNonstandard": "Past"},
    "arceusflying": {"isNonstandard": "Past"},
    "arceusghost": {"isNonstandard": "Past"},
    "arceusgrass": {"isNonstandard": "Past"},
    "arceusground": {"isNonstandard": "Past"},
    "arceusice": {"isNonstandard": "Past"},
    "arceuspoison": {"isNonstandard": "Past"},
    "arceuspsychic": {"isNonstandard": "Past"},
    "arceusrock": {"isNonstandard": "Past"},
    "arceussteel": {"isNonstandard": "Past"},
    "arceuswater": {"isNonstandard": "Past"},
    "victini": {"tier": "OU", "doublesTier": "DUU"},
    "snivy": {"isNonstandard": "Past", "tier": "Illegal"},
    "servine": {"isNonstandard": "Past", "tier": "Illegal"},
    "serperior": {"isNonstandard": "Past", "tier": "Illegal"},
    "tepig": {"isNonstandard": "Past", "tier": "Illegal"},
    "pignite": {"isNonstandard": "Past", "tier": "Illegal"},
    "emboar": {"isNonstandard": "Past", "tier": "Illegal"},
    "oshawott": {"isNonstandard": "Past", "tier": "Illegal"},
    "dewott": {"isNonstandard": "Past", "tier": "Illegal"},
    "samurott": {"isNonstandard": "Past", "tier": "Illegal"},
    "samurotthisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "patrat": {"isNonstandard": "Past", "tier": "Illegal"},
    "watchog": {"isNonstandard": "Past", "tier": "Illegal"},
    "lillipup": {"tier": "LC"},
    "herdier": {"tier": "NFE"},
    "stoutland": {"tier": "ZU", "doublesTier": "(DUU)"},
    "purrloin": {"tier": "LC"},
    "liepard": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pansage": {"isNonstandard": "Past", "tier": "Illegal"},
    "simisage": {"isNonstandard": "Past", "tier": "Illegal"},
    "pansear": {"isNonstandard": "Past", "tier": "Illegal"},
    "simisear": {"isNonstandard": "Past", "tier": "Illegal"},
    "panpour": {"isNonstandard": "Past", "tier": "Illegal"},
    "simipour": {"isNonstandard": "Past", "tier": "Illegal"},
    "munna": {"tier": "LC"},
    "musharna": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pidove": {"tier": "LC"},
    "tranquill": {"tier": "NFE"},
    "unfezant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "blitzle": {"isNonstandard": "Past", "tier": "Illegal"},
    "zebstrika": {"isNonstandard": "Past", "tier": "Illegal"},
    "roggenrola": {"tier": "LC"},
    "boldore": {"tier": "NFE"},
    "gigalith": {"tier": "PU", "doublesTier": "DUU"},
    "woobat": {"tier": "NFE"},
    "swoobat": {"tier": "ZU", "doublesTier": "(DUU)"},
    "drilbur": {"tier": "LC"},
    "excadrill": {"tier": "UU", "doublesTier": "DUU"},
    "audino": {"tier": "PU", "doublesTier": "(DUU)"},
    "audinomega": {"isNonstandard": "Past", "tier": "Illegal"},
    "timburr": {"tier": "LC"},
    "gurdurr": {"tier": "ZU", "doublesTier": "NFE"},
    "conkeldurr": {"tier": "UU", "doublesTier": "(DUU)"},
    "tympole": {"tier": "LC"},
    "palpitoad": {"tier": "NFE"},
    "seismitoad": {"tier": "RU", "doublesTier": "(DUU)"},
    "throh": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sawk": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sewaddle": {"isNonstandard": "Past", "tier": "Illegal"},
    "swadloon": {"isNonstandard": "Past", "tier": "Illegal"},
    "leavanny": {"isNonstandard": "Past", "tier": "Illegal"},
    "venipede": {"tier": "LC"},
    "whirlipede": {"tier": "NFE"},
    "scolipede": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "cottonee": {"tier": "LC"},
    "whimsicott": {"tier": "PU", "doublesTier": "DOU"},
    "petilil": {"tier": "LC"},
    "lilligant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lilliganthisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "basculin": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "basculinbluestriped": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "basculinwhitestriped": {"isNonstandard": "Future", "tier": "Illegal"},
    "sandile": {"tier": "LC"},
    "krokorok": {"tier": "NFE"},
    "krookodile": {"tier": "UU", "doublesTier": "DUU"},
    "darumaka": {"tier": "LC"},
    "darumakagalar": {"tier": "LC"},
    "darmanitan": {"tier": "UU", "doublesTier": "(DUU)"},
    "darmanitanzen": {"tier": "UU", "doublesTier": "(DUU)"},
    "darmanitangalar": {"tier": "Uber", "doublesTier": "(DUU)"},
    "darmanitangalarzen": {"tier": "Uber", "doublesTier": "(DUU)"},
    "maractus": {"tier": "ZU", "doublesTier": "(DUU)"},
    "dwebble": {"tier": "LC"},
    "crustle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "scraggy": {"tier": "NFE"},
    "scrafty": {"tier": "PUBL", "doublesTier": "DUU"},
    "sigilyph": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "yamask": {"tier": "LC"},
    "yamaskgalar": {"tier": "LC"},
    "cofagrigus": {"tier": "ZU", "doublesTier": "(DUU)"},
    "runerigus": {"tier": "ZU", "doublesTier": "(DUU)"},
    "tirtouga": {"tier": "LC"},
    "carracosta": {"tier": "ZU", "doublesTier": "(DUU)"},
    "archen": {"tier": "LC"},
    "archeops": {"tier": "PU", "doublesTier": "(DUU)"},
    "trubbish": {"tier": "LC"},
    "garbodor": {"tier": "PU", "doublesTier": "(DUU)"},
    "garbodorgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "zorua": {"tier": "LC"},
    "zoruahisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "zoroark": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "zoroarkhisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "minccino": {"tier": "LC"},
    "cinccino": {"tier": "PU", "doublesTier": "(DUU)"},
    "gothita": {"tier": "NFE"},
    "gothorita": {"tier": "NFE"},
    "gothitelle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "solosis": {"tier": "LC"},
    "duosion": {"tier": "NFE"},
    "reuniclus": {"tier": "RU", "doublesTier": "(DUU)"},
    "ducklett": {"isNonstandard": "Past", "tier": "Illegal"},
    "swanna": {"isNonstandard": "Past", "tier": "Illegal"},
    "vanillite": {"tier": "LC"},
    "vanillish": {"tier": "NFE"},
    "vanilluxe": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "deerling": {"isNonstandard": "Past", "tier": "Illegal"},
    "sawsbuck": {"isNonstandard": "Past", "tier": "Illegal"},
    "emolga": {"tier": "ZU", "doublesTier": "(DUU)"},
    "karrablast": {"tier": "LC"},
    "escavalier": {"tier": "NU", "doublesTier": "(DUU)"},
    "foongus": {"tier": "LC"},
    "amoonguss": {"tier": "UU", "doublesTier": "DOU"},
    "frillish": {"tier": "LC"},
    "jellicent": {"tier": "PU", "doublesTier": "(DUU)"},
    "alomomola": {"isNonstandard": "Past", "tier": "Illegal"},
    "joltik": {"tier": "LC"},
    "galvantula": {"tier": "PU", "doublesTier": "(DUU)"},
    "ferroseed": {"tier": "PU", "doublesTier": "NFE"},
    "ferrothorn": {"tier": "OU", "doublesTier": "DUU"},
    "klink": {"tier": "LC"},
    "klang": {"tier": "NFE"},
    "klinklang": {"tier": "ZU", "doublesTier": "(DUU)"},
    "tynamo": {"isNonstandard": "Past", "tier": "Illegal"},
    "eelektrik": {"isNonstandard": "Past", "tier": "Illegal"},
    "eelektross": {"isNonstandard": "Past", "tier": "Illegal"},
    "elgyem": {"tier": "LC"},
    "beheeyem": {"tier": "ZU", "doublesTier": "(DUU)"},
    "litwick": {"tier": "LC"},
    "lampent": {"tier": "NFE"},
    "chandelure": {"tier": "RU", "doublesTier": "(DUU)"},
    "axew": {"tier": "LC"},
    "fraxure": {"tier": "NFE"},
    "haxorus": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "cubchoo": {"tier": "LC"},
    "beartic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cryogonal": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shelmet": {"tier": "LC"},
    "accelgor": {"tier": "ZU", "doublesTier": "(DUU)"},
    "stunfisk": {"tier": "ZU", "doublesTier": "(DUU)"},
    "stunfiskgalar": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mienfoo": {"tier": "LC"},
    "mienshao": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "druddigon": {"tier": "PU", "doublesTier": "(DUU)"},
    "golett": {"tier": "LC"},
    "golurk": {"tier": "NU", "doublesTier": "(DUU)"},
    "pawniard": {"tier": "LC"},
    "bisharp": {"tier": "OU", "doublesTier": "(DUU)"},
    "bouffalant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rufflet": {"tier": "NFE"},
    "braviary": {"tier": "NU", "doublesTier": "(DUU)"},
    "braviaryhisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "vullaby": {"tier": "NFE"},
    "mandibuzz": {"tier": "UU", "doublesTier": "(DUU)"},
    "heatmor": {"tier": "ZU", "doublesTier": "(DUU)"},
    "durant": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "deino": {"tier": "LC"},
    "zweilous": {"tier": "NFE"},
    "hydreigon": {"tier": "UU", "doublesTier": "(DUU)"},
    "larvesta": {"tier": "LC"},
    "volcarona": {"tier": "OU", "doublesTier": "(DUU)"},
    "cobalion": {"tier": "UU", "doublesTier": "(DUU)"},
    "terrakion": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "virizion": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "tornadus": {"tier": "NUBL", "doublesTier": "DUU"},
    "tornadustherian": {"tier": "OU", "doublesTier": "(DUU)"},
    "thundurus": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "thundurustherian": {"tier": "UU", "doublesTier": "(DUU)"},
    "reshiram": {"tier": "Uber", "doublesTier": "DUber"},
    "zekrom": {"tier": "Uber", "doublesTier": "DUber"},
    "landorus": {"tier": "Uber", "doublesTier": "DOU"},
    "landorustherian": {"tier": "OU", "doublesTier": "DOU"},
    "kyurem": {"tier": "Uber", "doublesTier": "(DUU)"},
    "kyuremblack": {"tier": "Uber", "doublesTier": "DOU"},
    "kyuremwhite": {"tier": "Uber", "doublesTier": "DUber"},
    "keldeo": {"tier": "UU", "doublesTier": "(DUU)"},
    "keldeoresolute": {},
    "meloetta": {"isNonstandard": "Past", "tier": "Illegal"},
    "meloettapirouette": {"isNonstandard": "Past"},
    "genesect": {"tier": "Uber", "doublesTier": "DOU"},
    "genesectburn": {"tier": "Uber", "doublesTier": "DOU"},
    "genesectchill": {"tier": "Uber", "doublesTier": "DOU"},
    "genesectdouse": {"tier": "Uber", "doublesTier": "DOU"},
    "genesectshock": {"tier": "Uber", "doublesTier": "DOU"},
    "chespin": {"isNonstandard": "Past", "tier": "Illegal"},
    "quilladin": {"isNonstandard": "Past", "tier": "Illegal"},
    "chesnaught": {"isNonstandard": "Past", "tier": "Illegal"},
    "fennekin": {"isNonstandard": "Past", "tier": "Illegal"},
    "braixen": {"isNonstandard": "Past", "tier": "Illegal"},
    "delphox": {"isNonstandard": "Past", "tier": "Illegal"},
    "froakie": {"isNonstandard": "Past", "tier": "Illegal"},
    "frogadier": {"isNonstandard": "Past", "tier": "Illegal"},
    "greninja": {"isNonstandard": "Past", "tier": "Illegal"},
    "greninjabond": {"isNonstandard": "Past", "tier": "Illegal"},
    "greninjaash": {"isNonstandard": "Past"},
    "bunnelby": {"tier": "LC"},
    "diggersby": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "fletchling": {"tier": "LC"},
    "fletchinder": {"tier": "NFE"},
    "talonflame": {"tier": "NU", "doublesTier": "(DUU)"},
    "scatterbug": {"isNonstandard": "Past", "tier": "Illegal"},
    "spewpa": {"isNonstandard": "Past", "tier": "Illegal"},
    "vivillon": {"isNonstandard": "Past", "tier": "Illegal"},
    "vivillonfancy": {"isNonstandard": "Past"},
    "vivillonpokeball": {"isNonstandard": "Past"},
    "litleo": {"isNonstandard": "Past", "tier": "Illegal"},
    "pyroar": {"isNonstandard": "Past", "tier": "Illegal"},
    "flabebe": {"isNonstandard": "Past", "tier": "Illegal"},
    "floette": {"isNonstandard": "Past", "tier": "Illegal"},
    "floetteeternal": {"isNonstandard": "Past", "tier": "Illegal"},
    "florges": {"isNonstandard": "Past", "tier": "Illegal"},
    "skiddo": {"isNonstandard": "Past", "tier": "Illegal"},
    "gogoat": {"isNonstandard": "Past", "tier": "Illegal"},
    "pancham": {"tier": "LC"},
    "pangoro": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "furfrou": {"isNonstandard": "Past", "tier": "Illegal"},
    "espurr": {"tier": "LC"},
    "meowstic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "meowsticf": {"tier": "ZU", "doublesTier": "(DUU)"},
    "honedge": {"tier": "LC"},
    "doublade": {"tier": "PU", "doublesTier": "NFE"},
    "aegislash": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "aegislashblade": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "spritzee": {"tier": "LC"},
    "aromatisse": {"tier": "PU", "doublesTier": "(DUU)"},
    "swirlix": {"tier": "NFE"},
    "slurpuff": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "inkay": {"tier": "LC"},
    "malamar": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "binacle": {"tier": "LC"},
    "barbaracle": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "skrelp": {"tier": "LC"},
    "dragalge": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "clauncher": {"tier": "LC"},
    "clawitzer": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "helioptile": {"tier": "LC"},
    "heliolisk": {"tier": "NU", "doublesTier": "(DUU)"},
    "tyrunt": {"tier": "LC"},
    "tyrantrum": {"tier": "NU", "doublesTier": "(DUU)"},
    "amaura": {"tier": "LC"},
    "aurorus": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "hawlucha": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "dedenne": {"tier": "ZU", "doublesTier": "(DUU)"},
    "carbink": {"tier": "ZU", "doublesTier": "(DUU)"},
    "goomy": {"tier": "LC"},
    "sliggoo": {"tier": "NFE"},
    "sliggoohisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "goodra": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "goodrahisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "klefki": {"tier": "RU", "doublesTier": "(DUU)"},
    "phantump": {"tier": "LC"},
    "trevenant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pumpkaboo": {"tier": "LC"},
    "pumpkaboosmall": {"tier": "LC"},
    "pumpkaboolarge": {"tier": "LC"},
    "pumpkaboosuper": {"tier": "LC"},
    "gourgeist": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gourgeistsmall": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gourgeistlarge": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gourgeistsuper": {"tier": "ZU", "doublesTier": "(DUU)"},
    "bergmite": {"tier": "LC"},
    "avalugg": {"tier": "ZU", "doublesTier": "(DUU)"},
    "avalugghisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "noibat": {"tier": "LC"},
    "noivern": {"tier": "RU", "doublesTier": "(DUU)"},
    "xerneas": {"tier": "Uber", "doublesTier": "DUber"},
    "xerneasneutral": {"isNonstandard": "Custom", "tier": "Illegal"},
    "yveltal": {"tier": "Uber", "doublesTier": "DUber"},
    "zygarde": {"tier": "Uber", "doublesTier": "DOU"},
    "zygarde10": {"tier": "UU", "doublesTier": "(DUU)"},
    "zygardecomplete": {"tier": "Uber", "doublesTier": "DUber"},
    "diancie": {"tier": "NU", "doublesTier": "DOU"},
    "dianciemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "hoopa": {"isNonstandard": "Past", "tier": "Illegal"},
    "hoopaunbound": {"isNonstandard": "Past", "tier": "Illegal"},
    "volcanion": {"tier": "OU", "doublesTier": "DOU"},
    "rowlet": {"tier": "LC"},
    "dartrix": {"tier": "NFE"},
    "decidueye": {"tier": "NU", "doublesTier": "(DUU)"},
    "decidueyehisui": {"isNonstandard": "Future", "tier": "Illegal"},
    "litten": {"tier": "LC"},
    "torracat": {"tier": "NFE"},
    "incineroar": {"tier": "RU", "doublesTier": "DOU"},
    "popplio": {"tier": "LC"},
    "brionne": {"tier": "NFE"},
    "primarina": {"tier": "UU", "doublesTier": "DUU"},
    "pikipek": {"isNonstandard": "Past", "tier": "Illegal"},
    "trumbeak": {"isNonstandard": "Past", "tier": "Illegal"},
    "toucannon": {"isNonstandard": "Past", "tier": "Illegal"},
    "yungoos": {"isNonstandard": "Past", "tier": "Illegal"},
    "gumshoos": {"isNonstandard": "Past", "tier": "Illegal"},
    "gumshoostotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "grubbin": {"tier": "LC"},
    "charjabug": {"tier": "NFE"},
    "vikavolt": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "vikavolttotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "crabrawler": {"isNonstandard": "Past", "tier": "Illegal"},
    "crabominable": {"isNonstandard": "Past", "tier": "Illegal"},
    "oricorio": {"isNonstandard": "Past", "tier": "Illegal"},
    "oricoriopompom": {"isNonstandard": "Past", "tier": "Illegal"},
    "oricoriopau": {"isNonstandard": "Past", "tier": "Illegal"},
    "oricoriosensu": {"isNonstandard": "Past", "tier": "Illegal"},
    "cutiefly": {"tier": "NFE"},
    "ribombee": {"tier": "PU", "doublesTier": "(DUU)"},
    "ribombeetotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "rockruff": {"tier": "LC"},
    "rockruffdusk": {"tier": "LC"},
    "lycanroc": {"tier": "PU", "doublesTier": "(DUU)"},
    "lycanrocmidnight": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lycanrocdusk": {"tier": "UU", "doublesTier": "(DUU)"},
    "wishiwashi": {"tier": "PU", "doublesTier": "(DUU)"},
    "wishiwashischool": {},
    "mareanie": {"tier": "LC"},
    "toxapex": {"tier": "OU", "doublesTier": "(DUU)"},
    "mudbray": {"tier": "LC"},
    "mudsdale": {"tier": "NU", "doublesTier": "(DUU)"},
    "dewpider": {"tier": "LC"},
    "araquanid": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "araquanidtotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "fomantis": {"tier": "LC"},
    "lurantis": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lurantistotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "morelull": {"tier": "LC"},
    "shiinotic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "salandit": {"tier": "LC"},
    "salazzle": {"tier": "NU", "doublesTier": "(DUU)"},
    "salazzletotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "stufful": {"tier": "LC"},
    "bewear": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "bounsweet": {"tier": "LC"},
    "steenee": {"tier": "NFE"},
    "tsareena": {"tier": "NU", "doublesTier": "DOU"},
    "comfey": {"tier": "PU", "doublesTier": "DUU"},
    "oranguru": {"tier": "ZU", "doublesTier": "(DUU)"},
    "passimian": {"tier": "NU", "doublesTier": "(DUU)"},
    "wimpod": {"tier": "LC"},
    "golisopod": {"tier": "RU", "doublesTier": "(DUU)"},
    "sandygast": {"tier": "LC"},
    "palossand": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pyukumuku": {"tier": "ZU", "doublesTier": "(DUU)"},
    "typenull": {"tier": "NFE"},
    "silvally": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallybug": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallydark": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallydragon": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "silvallyelectric": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyfairy": {"tier": "PU", "doublesTier": "(DUU)"},
    "silvallyfighting": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyfire": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyflying": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyghost": {"tier": "PU", "doublesTier": "(DUU)"},
    "silvallygrass": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyground": {"tier": "NU", "doublesTier": "(DUU)"},
    "silvallyice": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallypoison": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallypsychic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallyrock": {"tier": "ZU", "doublesTier": "(DUU)"},
    "silvallysteel": {"tier": "PU", "doublesTier": "(DUU)"},
    "silvallywater": {"tier": "ZU", "doublesTier": "(DUU)"},
    "minior": {"isNonstandard": "Past", "tier": "Illegal"},
    "miniormeteor": {"isNonstandard": "Past"},
    "komala": {"isNonstandard": "Past", "tier": "Illegal"},
    "turtonator": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "togedemaru": {"tier": "PU", "doublesTier": "(DUU)"},
    "togedemarutotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "mimikyu": {"tier": "RU", "doublesTier": "(DUU)"},
    "mimikyutotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "mimikyubustedtotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "bruxish": {"isNonstandard": "Past", "tier": "Illegal"},
    "drampa": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "dhelmise": {"tier": "NU", "doublesTier": "(DUU)"},
    "jangmoo": {"tier": "LC"},
    "hakamoo": {"tier": "NFE"},
    "kommoo": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "kommoototem": {"isNonstandard": "Past", "tier": "Illegal"},
    "tapukoko": {"tier": "OU", "doublesTier": "DOU"},
    "tapulele": {"tier": "OU", "doublesTier": "DOU"},
    "tapubulu": {"tier": "UU", "doublesTier": "DUU"},
    "tapufini": {"tier": "OU", "doublesTier": "DOU"},
    "cosmog": {"tier": "LC"},
    "cosmoem": {"tier": "NFE"},
    "solgaleo": {"tier": "Uber", "doublesTier": "DUber"},
    "lunala": {"tier": "Uber", "doublesTier": "DUber"},
    "nihilego": {"tier": "UU", "doublesTier": "(DUU)"},
    "buzzwole": {"tier": "OU", "doublesTier": "(DUU)"},
    "pheromosa": {"tier": "Uber", "doublesTier": "(DUU)"},
    "xurkitree": {"tier": "RU", "doublesTier": "(DUU)"},
    "celesteela": {"tier": "UU", "doublesTier": "DOU"},
    "kartana": {"tier": "OU", "doublesTier": "DUber"},
    "guzzlord": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "necrozma": {"tier": "RUBL", "doublesTier": "DOU"},
    "necrozmaduskmane": {"tier": "Uber", "doublesTier": "DUber"},
    "necrozmadawnwings": {"tier": "Uber", "doublesTier": "DUber"},
    "necrozmaultra": {"isNonstandard": "Past", "tier": "Illegal"},
    "magearna": {"tier": "Uber", "doublesTier": "DUber"},
    "magearnaoriginal": {},
    "marshadow": {"tier": "Uber", "doublesTier": "DUber"},
    "poipole": {"tier": "NFE"},
    "naganadel": {"tier": "Uber", "doublesTier": "DUU"},
    "stakataka": {"tier": "NU", "doublesTier": "DOU"},
    "blacephalon": {"tier": "OU", "doublesTier": "(DUU)"},
    "zeraora": {"tier": "OU", "doublesTier": "DOU"},
    "meltan": {"tier": "ZU", "doublesTier": "(DUU)"},
    "melmetal": {"tier": "OU", "doublesTier": "DUber"},
    "melmetalgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "grookey": {"tier": "LC"},
    "thwackey": {"tier": "ZU", "doublesTier": "NFE"},
    "rillaboom": {"tier": "OU", "doublesTier": "DOU"},
    "rillaboomgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "scorbunny": {"tier": "LC"},
    "raboot": {"tier": "NFE"},
    "cinderace": {"tier": "Uber", "doublesTier": "DUU"},
    "cinderacegmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "sobble": {"tier": "LC"},
    "drizzile": {"tier": "NFE"},
    "inteleon": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "inteleongmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "skwovet": {"tier": "LC"},
    "greedent": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rookidee": {"tier": "LC"},
    "corvisquire": {"tier": "NFE"},
    "corviknight": {"tier": "OU", "doublesTier": "(DUU)"},
    "corviknightgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "blipbug": {"tier": "LC"},
    "dottler": {"tier": "NFE"},
    "orbeetle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "orbeetlegmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "nickit": {"tier": "LC"},
    "thievul": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gossifleur": {"tier": "LC"},
    "eldegoss": {"tier": "PU", "doublesTier": "(DUU)"},
    "wooloo": {"tier": "LC"},
    "dubwool": {"tier": "ZU", "doublesTier": "(DUU)"},
    "chewtle": {"tier": "LC"},
    "drednaw": {"tier": "ZU", "doublesTier": "(DUU)"},
    "drednawgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "yamper": {"tier": "LC"},
    "boltund": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rolycoly": {"tier": "LC"},
    "carkol": {"tier": "NFE"},
    "coalossal": {"tier": "ZU", "doublesTier": "(DUU)"},
    "coalossalgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "applin": {"tier": "LC"},
    "flapple": {"tier": "ZU", "doublesTier": "(DUU)"},
    "flapplegmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "appletun": {"tier": "ZU", "doublesTier": "(DUU)"},
    "appletungmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "silicobra": {"tier": "LC"},
    "sandaconda": {"tier": "PU", "doublesTier": "(DUU)"},
    "sandacondagmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "cramorant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cramorantgulping": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cramorantgorging": {"tier": "ZU", "doublesTier": "(DUU)"},
    "arrokuda": {"tier": "LC"},
    "barraskewda": {"tier": "OU", "doublesTier": "(DUU)"},
    "toxel": {"tier": "LC"},
    "toxtricity": {"tier": "RU", "doublesTier": "(DUU)"},
    "toxtricitylowkey": {"tier": "RU", "doublesTier": "(DUU)"},
    "toxtricitygmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "toxtricitylowkeygmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "sizzlipede": {"tier": "LC"},
    "centiskorch": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "centiskorchgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "clobbopus": {"tier": "LC"},
    "grapploct": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sinistea": {"tier": "LC"},
    "sinisteaantique": {"tier": "LC"},
    "polteageist": {"tier": "RU", "doublesTier": "(DUU)"},
    "hatenna": {"tier": "LC"},
    "hattrem": {"tier": "NFE"},
    "hatterene": {"tier": "UU", "doublesTier": "DOU"},
    "hatterenegmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "impidimp": {"tier": "LC"},
    "morgrem": {"tier": "NFE"},
    "grimmsnarl": {"tier": "NU", "doublesTier": "DUU"},
    "grimmsnarlgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "milcery": {"tier": "LC"},
    "alcremie": {"tier": "ZU", "doublesTier": "(DUU)"},
    "alcremiegmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "falinks": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pincurchin": {"tier": "ZU", "doublesTier": "(DUU)"},
    "snom": {"tier": "LC"},
    "frosmoth": {"tier": "PU", "doublesTier": "(DUU)"},
    "stonjourner": {"tier": "ZU", "doublesTier": "(DUU)"},
    "eiscue": {"tier": "ZU", "doublesTier": "(DUU)"},
    "indeedee": {"tier": "NUBL", "doublesTier": "DUU"},
    "indeedeef": {"tier": "NUBL", "doublesTier": "DOU"},
    "morpeko": {"tier": "ZU", "doublesTier": "(DUU)"},
    "morpekohangry": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cufant": {"tier": "LC"},
    "copperajah": {"tier": "NU", "doublesTier": "(DUU)"},
    "copperajahgmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "dracozolt": {"tier": "UUBL", "doublesTier": "DUU"},
    "arctozolt": {"tier": "UUBL", "doublesTier": "(DUU)"},
    "dracovish": {"tier": "Uber", "doublesTier": "DOU"},
    "arctovish": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "duraludon": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "duraludongmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "dreepy": {"tier": "LC"},
    "drakloak": {"tier": "NFE"},
    "dragapult": {"tier": "OU", "doublesTier": "DOU"},
    "zacian": {"tier": "AG", "doublesTier": "DUber"},
    "zaciancrowned": {"tier": "AG", "doublesTier": "DUber"},
    "zamazenta": {"tier": "Uber", "doublesTier": "DUber"},
    "zamazentacrowned": {"tier": "Uber", "doublesTier": "DUber"},
    "eternatus": {"tier": "Uber", "doublesTier": "DUber"},
    "eternatuseternamax": {"isNonstandard": "Unobtainable", "tier": "Illegal"},
    "kubfu": {"tier": "NFE"},
    "urshifu": {"tier": "Uber", "doublesTier": "DUber"},
    "urshifurapidstrike": {"tier": "OU", "doublesTier": "DOU"},
    "urshifugmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "urshifurapidstrikegmax": {
      "isNonstandard": "Gigantamax",
      "tier": "AG",
      "doublesTier": "(DUber)"
    },
    "zarude": {"tier": "UU", "doublesTier": "(DUU)"},
    "zarudedada": {},
    "regieleki": {"tier": "UU", "doublesTier": "DOU"},
    "regidrago": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "glastrier": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "spectrier": {"tier": "Uber", "doublesTier": "DUU"},
    "calyrex": {"tier": "ZU", "doublesTier": "(DUU)"},
    "calyrexice": {"tier": "Uber", "doublesTier": "DUber"},
    "calyrexshadow": {"tier": "Uber", "doublesTier": "DUber"},
    "wyrdeer": {"isNonstandard": "Future", "tier": "Illegal"},
    "kleavor": {"isNonstandard": "Future", "tier": "Illegal"},
    "ursaluna": {"isNonstandard": "Future", "tier": "Illegal"},
    "basculegion": {"isNonstandard": "Future", "tier": "Illegal"},
    "basculegionf": {"isNonstandard": "Future", "tier": "Illegal"},
    "sneasler": {"isNonstandard": "Future", "tier": "Illegal"},
    "overqwil": {"isNonstandard": "Future", "tier": "Illegal"},
    "enamorus": {"isNonstandard": "Future", "tier": "Illegal"},
    "enamorustherian": {"isNonstandard": "Future", "tier": "Illegal"},
    "missingno": {"isNonstandard": "Custom", "tier": "Illegal"},
    "syclar": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "syclant": {"isNonstandard": "CAP", "tier": "CAP"},
    "revenankh": {"isNonstandard": "CAP", "tier": "CAP"},
    "embirch": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "flarelm": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "pyroak": {"isNonstandard": "CAP", "tier": "CAP"},
    "breezi": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "fidgit": {"isNonstandard": "CAP", "tier": "CAP"},
    "rebble": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "tactite": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "stratagem": {"isNonstandard": "CAP", "tier": "CAP"},
    "privatyke": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "arghonaut": {"isNonstandard": "CAP", "tier": "CAP"},
    "nohface": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "kitsunoh": {"isNonstandard": "CAP", "tier": "CAP"},
    "monohm": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "duohm": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "cyclohm": {"isNonstandard": "CAP", "tier": "CAP"},
    "dorsoil": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "colossoil": {"isNonstandard": "CAP", "tier": "CAP"},
    "protowatt": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "krilowatt": {"isNonstandard": "CAP", "tier": "CAP"},
    "voodoll": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "voodoom": {"isNonstandard": "CAP", "tier": "CAP"},
    "scratchet": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "tomohawk": {"isNonstandard": "CAP", "tier": "CAP"},
    "necturine": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "necturna": {"isNonstandard": "CAP", "tier": "CAP"},
    "mollux": {"isNonstandard": "CAP", "tier": "CAP"},
    "cupra": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "argalis": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "aurumoth": {"isNonstandard": "CAP", "tier": "CAP"},
    "brattler": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "malaconda": {"isNonstandard": "CAP", "tier": "CAP"},
    "cawdet": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "cawmodore": {"isNonstandard": "CAP", "tier": "CAP"},
    "volkritter": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "volkraken": {"isNonstandard": "CAP", "tier": "CAP"},
    "snugglow": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "plasmanta": {"isNonstandard": "CAP", "tier": "CAP"},
    "floatoy": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "caimanoe": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "naviathan": {"isNonstandard": "CAP", "tier": "CAP"},
    "crucibelle": {"isNonstandard": "CAP", "tier": "CAP"},
    "crucibellemega": {"isNonstandard": "CAP", "tier": "CAP"},
    "pluffle": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "kerfluffle": {"isNonstandard": "CAP", "tier": "CAP"},
    "pajantom": {"isNonstandard": "CAP", "tier": "CAP"},
    "mumbao": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "jumbao": {"isNonstandard": "CAP", "tier": "CAP"},
    "fawnifer": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "electrelk": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "caribolt": {"isNonstandard": "CAP", "tier": "CAP"},
    "smogecko": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "smoguana": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "smokomodo": {"isNonstandard": "CAP", "tier": "CAP"},
    "swirlpool": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "coribalis": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "snaelstrom": {"isNonstandard": "CAP", "tier": "CAP"},
    "justyke": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "equilibra": {"isNonstandard": "CAP", "tier": "CAP"},
    "solotl": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "astrolotl": {"isNonstandard": "CAP", "tier": "CAP"},
    "miasmite": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "miasmaw": {"isNonstandard": "CAP", "tier": "CAP"},
    "chromera": {"isNonstandard": "CAP", "tier": "CAP"},
    "venomicon": {"isNonstandard": "CAP", "tier": "CAP"},
    "venomiconepilogue": {"isNonstandard": "CAP", "tier": "CAP"},
    "saharaja": {"isNonstandard": "CAP", "tier": "CAP"},
    "pokestarsmeargle": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarufo": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarufo2": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarbrycenman": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarmt": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarmt2": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestartransport": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestargiant": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestargiant2": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarhumanoid": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarmonster": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarf00": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarf002": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarspirit": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarblackdoor": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarwhitedoor": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarblackbelt": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestargiantpropo2": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarufopropu2": {"isNonstandard": "Custom", "tier": "Illegal"}
  },
  "9": {
    "bulbasaur": {"isNonstandard": "Past", "tier": "Illegal"},
    "ivysaur": {"isNonstandard": "Past", "tier": "Illegal"},
    "venusaur": {"isNonstandard": "Past", "tier": "Illegal"},
    "venusaurmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "venusaurgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "charmander": {"tier": "LC"},
    "charmeleon": {"tier": "NFE"},
    "charizard": {"tier": "PU", "doublesTier": "(DUU)"},
    "charizardmegax": {"isNonstandard": "Past", "tier": "Illegal"},
    "charizardmegay": {"isNonstandard": "Past", "tier": "Illegal"},
    "charizardgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "squirtle": {"isNonstandard": "Past", "tier": "Illegal"},
    "wartortle": {"isNonstandard": "Past", "tier": "Illegal"},
    "blastoise": {"isNonstandard": "Past", "tier": "Illegal"},
    "blastoisemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "blastoisegmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "caterpie": {"isNonstandard": "Past", "tier": "Illegal"},
    "metapod": {"isNonstandard": "Past", "tier": "Illegal"},
    "butterfree": {"isNonstandard": "Past", "tier": "Illegal"},
    "butterfreegmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "weedle": {"isNonstandard": "Past", "tier": "Illegal"},
    "kakuna": {"isNonstandard": "Past", "tier": "Illegal"},
    "beedrill": {"isNonstandard": "Past", "tier": "Illegal"},
    "beedrillmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "pidgey": {"isNonstandard": "Past", "tier": "Illegal"},
    "pidgeotto": {"isNonstandard": "Past", "tier": "Illegal"},
    "pidgeot": {"isNonstandard": "Past", "tier": "Illegal"},
    "pidgeotmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "rattata": {"isNonstandard": "Past", "tier": "Illegal"},
    "rattataalola": {"isNonstandard": "Past", "tier": "Illegal"},
    "raticate": {"isNonstandard": "Past", "tier": "Illegal"},
    "raticatealola": {"isNonstandard": "Past", "tier": "Illegal"},
    "raticatealolatotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "spearow": {"isNonstandard": "Past", "tier": "Illegal"},
    "fearow": {"isNonstandard": "Past", "tier": "Illegal"},
    "ekans": {"tier": "LC"},
    "arbok": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pichu": {"tier": "LC"},
    "pichuspikyeared": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachu": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachucosplay": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachurockstar": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachubelle": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachupopstar": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachuphd": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachulibre": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachuoriginal": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachuhoenn": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachusinnoh": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachuunova": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachukalos": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachualola": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachupartner": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pikachustarter": {"isNonstandard": "LGPE", "tier": "Illegal"},
    "pikachugmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikachuworld": {"tier": "ZU", "doublesTier": "(DUU)"},
    "raichu": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "raichualola": {"tier": "PU", "doublesTier": "(DUU)"},
    "sandshrew": {"tier": "LC"},
    "sandshrewalola": {"tier": "LC"},
    "sandslash": {"tier": "NU", "doublesTier": "(DUU)"},
    "sandslashalola": {"tier": "NU", "doublesTier": "(DUU)"},
    "nidoranf": {"isNonstandard": "Past", "tier": "Illegal"},
    "nidorina": {"isNonstandard": "Past", "tier": "Illegal"},
    "nidoqueen": {"isNonstandard": "Past", "tier": "Illegal"},
    "nidoranm": {"isNonstandard": "Past", "tier": "Illegal"},
    "nidorino": {"isNonstandard": "Past", "tier": "Illegal"},
    "nidoking": {"isNonstandard": "Past", "tier": "Illegal"},
    "cleffa": {"tier": "LC"},
    "clefairy": {"tier": "NFE", "doublesTier": "DUU"},
    "clefable": {"tier": "OU", "doublesTier": "(DUU)"},
    "vulpix": {"tier": "NUBL", "doublesTier": "NFE"},
    "vulpixalola": {"tier": "NFE"},
    "ninetales": {"tier": "NU", "doublesTier": "DUU"},
    "ninetalesalola": {"tier": "UU", "doublesTier": "DOU"},
    "igglybuff": {"tier": "LC"},
    "jigglypuff": {"tier": "NFE"},
    "wigglytuff": {"tier": "ZU", "doublesTier": "(DUU)"},
    "zubat": {"isNonstandard": "Past", "tier": "Illegal"},
    "golbat": {"isNonstandard": "Past", "tier": "Illegal"},
    "crobat": {"isNonstandard": "Past", "tier": "Illegal"},
    "oddish": {"isNonstandard": "Past", "tier": "Illegal"},
    "gloom": {"isNonstandard": "Past", "tier": "Illegal"},
    "vileplume": {"isNonstandard": "Past", "tier": "Illegal"},
    "bellossom": {"isNonstandard": "Past", "tier": "Illegal"},
    "paras": {"isNonstandard": "Past", "tier": "Illegal"},
    "parasect": {"isNonstandard": "Past", "tier": "Illegal"},
    "venonat": {"tier": "LC"},
    "venomoth": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "diglett": {"tier": "NFE"},
    "diglettalola": {"tier": "LC"},
    "dugtrio": {"tier": "ZU", "doublesTier": "(DUU)"},
    "dugtrioalola": {"tier": "PU", "doublesTier": "(DUU)"},
    "meowth": {"tier": "LC"},
    "meowthalola": {"tier": "LC"},
    "meowthgalar": {"tier": "LC"},
    "meowthgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "persian": {"tier": "ZU", "doublesTier": "(DUU)"},
    "persianalola": {"tier": "PU", "doublesTier": "(DUU)"},
    "perrserker": {"tier": "ZU", "doublesTier": "(DUU)"},
    "psyduck": {"tier": "LC"},
    "golduck": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mankey": {"tier": "LC"},
    "primeape": {"tier": "PUBL", "doublesTier": "NFE"},
    "growlithe": {"tier": "LC"},
    "growlithehisui": {"tier": "NFE"},
    "arcanine": {"tier": "NU", "doublesTier": "DUU"},
    "arcaninehisui": {"tier": "UU", "doublesTier": "DOU"},
    "poliwag": {"tier": "LC"},
    "poliwhirl": {"tier": "NFE"},
    "poliwrath": {"tier": "PU", "doublesTier": "(DUU)"},
    "politoed": {"tier": "RU", "doublesTier": "DUU"},
    "abra": {"isNonstandard": "Past", "tier": "Illegal"},
    "kadabra": {"isNonstandard": "Past", "tier": "Illegal"},
    "alakazam": {"isNonstandard": "Past", "tier": "Illegal"},
    "alakazammega": {"isNonstandard": "Past", "tier": "Illegal"},
    "machop": {"isNonstandard": "Past", "tier": "Illegal"},
    "machoke": {"isNonstandard": "Past", "tier": "Illegal"},
    "machamp": {"isNonstandard": "Past", "tier": "Illegal"},
    "machampgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "bellsprout": {"tier": "LC"},
    "weepinbell": {"tier": "NFE"},
    "victreebel": {"tier": "ZU", "doublesTier": "(DUU)"},
    "tentacool": {"isNonstandard": "Past", "tier": "Illegal"},
    "tentacruel": {"isNonstandard": "Past", "tier": "Illegal"},
    "geodude": {"tier": "LC"},
    "geodudealola": {"tier": "LC"},
    "graveler": {"tier": "NFE"},
    "graveleralola": {"tier": "NFE"},
    "golem": {"tier": "PU", "doublesTier": "(DUU)"},
    "golemalola": {"tier": "PU", "doublesTier": "(DUU)"},
    "ponyta": {"isNonstandard": "Past", "tier": "Illegal"},
    "ponytagalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "rapidash": {"isNonstandard": "Past", "tier": "Illegal"},
    "rapidashgalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "slowpoke": {"tier": "LC"},
    "slowpokegalar": {"tier": "LC"},
    "slowbro": {"tier": "RU", "doublesTier": "(DUU)"},
    "slowbromega": {"isNonstandard": "Past", "tier": "Illegal"},
    "slowbrogalar": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "slowking": {"tier": "UU", "doublesTier": "(DUU)"},
    "slowkinggalar": {"tier": "OU", "doublesTier": "(DUU)"},
    "magnemite": {"tier": "LC"},
    "magneton": {"tier": "PUBL", "doublesTier": "NFE"},
    "magnezone": {"tier": "RU", "doublesTier": "(DUU)"},
    "farfetchd": {"isNonstandard": "Past", "tier": "Illegal"},
    "farfetchdgalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "sirfetchd": {"isNonstandard": "Past", "tier": "Illegal"},
    "doduo": {"isNonstandard": "Past", "tier": "Illegal"},
    "dodrio": {"isNonstandard": "Past", "tier": "Illegal"},
    "seel": {"isNonstandard": "Past", "tier": "Illegal"},
    "dewgong": {"isNonstandard": "Past", "tier": "Illegal"},
    "grimer": {"tier": "LC"},
    "grimeralola": {"tier": "LC"},
    "muk": {"tier": "PU", "doublesTier": "(DUU)"},
    "mukalola": {"tier": "RU", "doublesTier": "(DUU)"},
    "shellder": {"tier": "LC"},
    "cloyster": {"tier": "RU", "doublesTier": "(DUU)"},
    "gastly": {"tier": "NFE"},
    "haunter": {"tier": "PU", "doublesTier": "NFE"},
    "gengar": {"tier": "UU", "doublesTier": "(DUU)"},
    "gengarmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "gengargmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "onix": {"isNonstandard": "Past", "tier": "Illegal"},
    "steelix": {"isNonstandard": "Past", "tier": "Illegal"},
    "steelixmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "drowzee": {"tier": "LC"},
    "hypno": {"tier": "ZU", "doublesTier": "(DUU)"},
    "krabby": {"isNonstandard": "Past", "tier": "Illegal"},
    "kingler": {"isNonstandard": "Past", "tier": "Illegal"},
    "kinglergmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "voltorb": {"tier": "LC"},
    "voltorbhisui": {"tier": "LC"},
    "electrode": {"tier": "ZU", "doublesTier": "(DUU)"},
    "electrodehisui": {"tier": "NU", "doublesTier": "(DUU)"},
    "exeggcute": {"isNonstandard": "Past", "tier": "Illegal"},
    "exeggutor": {"isNonstandard": "Past", "tier": "Illegal"},
    "exeggutoralola": {"isNonstandard": "Past", "tier": "Illegal"},
    "cubone": {"isNonstandard": "Past", "tier": "Illegal"},
    "marowak": {"isNonstandard": "Past", "tier": "Illegal"},
    "marowakalola": {"isNonstandard": "Past", "tier": "Illegal"},
    "marowakalolatotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "tyrogue": {"isNonstandard": "Past", "tier": "Illegal"},
    "hitmonlee": {"isNonstandard": "Past", "tier": "Illegal"},
    "hitmonchan": {"isNonstandard": "Past", "tier": "Illegal"},
    "hitmontop": {"isNonstandard": "Past", "tier": "Illegal"},
    "lickitung": {"isNonstandard": "Past", "tier": "Illegal"},
    "lickilicky": {"isNonstandard": "Past", "tier": "Illegal"},
    "koffing": {"tier": "LC"},
    "weezing": {"tier": "PU", "doublesTier": "(DUU)"},
    "weezinggalar": {"tier": "UU", "doublesTier": "(DUU)"},
    "rhyhorn": {"isNonstandard": "Past", "tier": "Illegal"},
    "rhydon": {"isNonstandard": "Past", "tier": "Illegal"},
    "rhyperior": {"isNonstandard": "Past", "tier": "Illegal"},
    "happiny": {"tier": "LC"},
    "chansey": {"tier": "NU", "doublesTier": "NFE"},
    "blissey": {"tier": "OU", "doublesTier": "(DUU)"},
    "tangela": {"isNonstandard": "Past", "tier": "Illegal"},
    "tangrowth": {"isNonstandard": "Past", "tier": "Illegal"},
    "kangaskhan": {"isNonstandard": "Past", "tier": "Illegal"},
    "kangaskhanmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "horsea": {"isNonstandard": "Past", "tier": "Illegal"},
    "seadra": {"isNonstandard": "Past", "tier": "Illegal"},
    "kingdra": {"isNonstandard": "Past", "tier": "Illegal"},
    "goldeen": {"isNonstandard": "Past", "tier": "Illegal"},
    "seaking": {"isNonstandard": "Past", "tier": "Illegal"},
    "staryu": {"isNonstandard": "Past", "tier": "Illegal"},
    "starmie": {"isNonstandard": "Past", "tier": "Illegal"},
    "mimejr": {"isNonstandard": "Past", "tier": "Illegal"},
    "mrmime": {"isNonstandard": "Past", "tier": "Illegal"},
    "mrmimegalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "mrrime": {"isNonstandard": "Past", "tier": "Illegal"},
    "scyther": {"tier": "NU", "doublesTier": "NFE"},
    "scizor": {"tier": "UU", "doublesTier": "DUU"},
    "scizormega": {"isNonstandard": "Past", "tier": "Illegal"},
    "kleavor": {"tier": "UU", "doublesTier": "DOU"},
    "smoochum": {"isNonstandard": "Past", "tier": "Illegal"},
    "jynx": {"isNonstandard": "Past", "tier": "Illegal"},
    "elekid": {"isNonstandard": "Past", "tier": "Illegal"},
    "electabuzz": {"isNonstandard": "Past", "tier": "Illegal"},
    "electivire": {"isNonstandard": "Past", "tier": "Illegal"},
    "magby": {"isNonstandard": "Past", "tier": "Illegal"},
    "magmar": {"isNonstandard": "Past", "tier": "Illegal"},
    "magmortar": {"isNonstandard": "Past", "tier": "Illegal"},
    "pinsir": {"isNonstandard": "Past", "tier": "Illegal"},
    "pinsirmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "tauros": {"tier": "ZU", "doublesTier": "(DUU)"},
    "taurospaldeacombat": {"tier": "PU", "doublesTier": "(DUU)"},
    "taurospaldeablaze": {"tier": "NU", "doublesTier": "(DUU)"},
    "taurospaldeaaqua": {"tier": "NU", "doublesTier": "(DUU)"},
    "magikarp": {"tier": "LC"},
    "gyarados": {"tier": "RUBL", "doublesTier": "DUU"},
    "gyaradosmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "lapras": {"isNonstandard": "Past", "tier": "Illegal"},
    "laprasgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "ditto": {"tier": "ZU", "doublesTier": "(DUU)"},
    "eevee": {"tier": "LC"},
    "eeveestarter": {"isNonstandard": "LGPE", "tier": "Illegal"},
    "eeveegmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "vaporeon": {"tier": "RU", "doublesTier": "(DUU)"},
    "jolteon": {"tier": "NU", "doublesTier": "(DUU)"},
    "flareon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "espeon": {"tier": "RU", "doublesTier": "(DUU)"},
    "umbreon": {"tier": "NU", "doublesTier": "(DUU)"},
    "leafeon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "glaceon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "sylveon": {"tier": "NU", "doublesTier": "DUU"},
    "porygon": {"isNonstandard": "Past", "tier": "Illegal"},
    "porygon2": {"isNonstandard": "Past", "tier": "Illegal"},
    "porygonz": {"isNonstandard": "Past", "tier": "Illegal"},
    "omanyte": {"isNonstandard": "Past", "tier": "Illegal"},
    "omastar": {"isNonstandard": "Past", "tier": "Illegal"},
    "kabuto": {"isNonstandard": "Past", "tier": "Illegal"},
    "kabutops": {"isNonstandard": "Past", "tier": "Illegal"},
    "aerodactyl": {"isNonstandard": "Past", "tier": "Illegal"},
    "aerodactylmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "munchlax": {"tier": "LC"},
    "snorlax": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "snorlaxgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "articuno": {"tier": "PU", "doublesTier": "(DUU)"},
    "articunogalar": {"tier": "NU", "doublesTier": "(DUU)"},
    "zapdos": {"tier": "OU", "doublesTier": "DUU"},
    "zapdosgalar": {"tier": "UU", "doublesTier": "(DUU)"},
    "moltres": {"tier": "UU", "doublesTier": "(DUU)"},
    "moltresgalar": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "dratini": {"tier": "LC"},
    "dragonair": {"tier": "NFE"},
    "dragonite": {"tier": "OU", "doublesTier": "DOU"},
    "mewtwo": {"tier": "Uber", "doublesTier": "DUber"},
    "mewtwomegax": {"isNonstandard": "Past", "tier": "Illegal"},
    "mewtwomegay": {"isNonstandard": "Past", "tier": "Illegal"},
    "mew": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "chikorita": {"isNonstandard": "Past", "tier": "Illegal"},
    "bayleef": {"isNonstandard": "Past", "tier": "Illegal"},
    "meganium": {"isNonstandard": "Past", "tier": "Illegal"},
    "cyndaquil": {"tier": "LC"},
    "quilava": {"tier": "NFE"},
    "typhlosion": {"tier": "NU", "doublesTier": "(DUU)"},
    "typhlosionhisui": {"tier": "RU", "doublesTier": "DUU"},
    "totodile": {"isNonstandard": "Past", "tier": "Illegal"},
    "croconaw": {"isNonstandard": "Past", "tier": "Illegal"},
    "feraligatr": {"isNonstandard": "Past", "tier": "Illegal"},
    "sentret": {"tier": "LC"},
    "furret": {"tier": "ZU", "doublesTier": "(DUU)"},
    "hoothoot": {"tier": "LC"},
    "noctowl": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ledyba": {"isNonstandard": "Past", "tier": "Illegal"},
    "ledian": {"isNonstandard": "Past", "tier": "Illegal"},
    "spinarak": {"tier": "LC"},
    "ariados": {"tier": "ZU", "doublesTier": "(DUU)"},
    "chinchou": {"isNonstandard": "Past", "tier": "Illegal"},
    "lanturn": {"isNonstandard": "Past", "tier": "Illegal"},
    "togepi": {"isNonstandard": "Past", "tier": "Illegal"},
    "togetic": {"isNonstandard": "Past", "tier": "Illegal"},
    "togekiss": {"isNonstandard": "Past", "tier": "Illegal"},
    "natu": {"isNonstandard": "Past", "tier": "Illegal"},
    "xatu": {"isNonstandard": "Past", "tier": "Illegal"},
    "mareep": {"tier": "LC"},
    "flaaffy": {"tier": "NFE"},
    "ampharos": {"tier": "ZU", "doublesTier": "(DUU)"},
    "ampharosmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "azurill": {"tier": "LC"},
    "marill": {"tier": "NFE"},
    "azumarill": {"tier": "UU", "doublesTier": "DUU"},
    "bonsly": {"tier": "LC"},
    "sudowoodo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "hoppip": {"tier": "LC"},
    "skiploom": {"tier": "NFE"},
    "jumpluff": {"tier": "ZU", "doublesTier": "(DUU)"},
    "aipom": {"tier": "NFE"},
    "ambipom": {"tier": "NU", "doublesTier": "(DUU)"},
    "sunkern": {"tier": "LC"},
    "sunflora": {"tier": "ZU", "doublesTier": "(DUU)"},
    "yanma": {"tier": "NFE"},
    "yanmega": {"tier": "RU", "doublesTier": "(DUU)"},
    "wooper": {"tier": "LC"},
    "wooperpaldea": {"tier": "LC"},
    "quagsire": {"tier": "RU", "doublesTier": "(DUU)"},
    "murkrow": {"tier": "NFE", "doublesTier": "DUU"},
    "honchkrow": {"tier": "PU", "doublesTier": "(DUU)"},
    "misdreavus": {"tier": "ZU", "doublesTier": "NFE"},
    "mismagius": {"tier": "NU", "doublesTier": "(DUU)"},
    "unown": {"isNonstandard": "Past", "tier": "Illegal"},
    "wynaut": {"isNonstandard": "Past", "tier": "Illegal"},
    "wobbuffet": {"isNonstandard": "Past", "tier": "Illegal"},
    "girafarig": {"tier": "NFE"},
    "farigiraf": {"tier": "PU", "doublesTier": "DOU"},
    "pineco": {"tier": "LC"},
    "forretress": {"tier": "RU", "doublesTier": "(DUU)"},
    "dunsparce": {"tier": "NFE"},
    "dudunsparce": {"tier": "NU", "doublesTier": "(DUU)"},
    "gligar": {"tier": "NU", "doublesTier": "NFE"},
    "gliscor": {"tier": "Uber", "doublesTier": "DUU"},
    "snubbull": {"isNonstandard": "Past", "tier": "Illegal"},
    "granbull": {"isNonstandard": "Past", "tier": "Illegal"},
    "qwilfish": {"tier": "NU", "doublesTier": "(DUU)"},
    "qwilfishhisui": {"tier": "NU", "doublesTier": "NFE"},
    "overqwil": {"tier": "RU", "doublesTier": "(DUU)"},
    "shuckle": {"isNonstandard": "Past", "tier": "Illegal"},
    "heracross": {"tier": "NU", "doublesTier": "(DUU)"},
    "heracrossmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "sneasel": {"tier": "ZU", "doublesTier": "NFE"},
    "sneaselhisui": {"tier": "PU", "doublesTier": "NFE"},
    "weavile": {"tier": "UU", "doublesTier": "(DUU)"},
    "sneasler": {"tier": "Uber", "doublesTier": "(DUU)"},
    "teddiursa": {"tier": "LC"},
    "ursaring": {"tier": "ZU", "doublesTier": "NFE"},
    "ursaluna": {"tier": "UU", "doublesTier": "DOU"},
    "ursalunabloodmoon": {"tier": "Uber", "doublesTier": "DOU"},
    "slugma": {"tier": "LC"},
    "magcargo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "swinub": {"tier": "LC"},
    "piloswine": {"tier": "NU", "doublesTier": "NFE"},
    "mamoswine": {"tier": "UU", "doublesTier": "(DUU)"},
    "corsola": {"isNonstandard": "Past", "tier": "Illegal"},
    "corsolagalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "cursola": {"isNonstandard": "Past", "tier": "Illegal"},
    "remoraid": {"isNonstandard": "Past", "tier": "Illegal"},
    "octillery": {"isNonstandard": "Past", "tier": "Illegal"},
    "delibird": {"tier": "ZU", "doublesTier": "(DUU)"},
    "mantyke": {"isNonstandard": "Past", "tier": "Illegal"},
    "mantine": {"isNonstandard": "Past", "tier": "Illegal"},
    "skarmory": {"isNonstandard": "Past", "tier": "Illegal"},
    "houndour": {"tier": "LC"},
    "houndoom": {"tier": "PU", "doublesTier": "(DUU)"},
    "houndoommega": {"isNonstandard": "Past", "tier": "Illegal"},
    "phanpy": {"tier": "LC"},
    "donphan": {"tier": "RU", "doublesTier": "(DUU)"},
    "stantler": {"tier": "NFE"},
    "wyrdeer": {"tier": "ZU", "doublesTier": "(DUU)"},
    "smeargle": {"isNonstandard": "Past", "tier": "Illegal"},
    "miltank": {"isNonstandard": "Past", "tier": "Illegal"},
    "raikou": {"isNonstandard": "Past", "tier": "Illegal"},
    "entei": {"isNonstandard": "Past", "tier": "Illegal"},
    "suicune": {"isNonstandard": "Past", "tier": "Illegal"},
    "larvitar": {"tier": "LC"},
    "pupitar": {"tier": "NFE"},
    "tyranitar": {"tier": "RU", "doublesTier": "DOU"},
    "tyranitarmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "lugia": {"isNonstandard": "Past", "tier": "Illegal"},
    "hooh": {"isNonstandard": "Past", "tier": "Illegal"},
    "celebi": {"isNonstandard": "Past", "tier": "Illegal"},
    "treecko": {"isNonstandard": "Past", "tier": "Illegal"},
    "grovyle": {"isNonstandard": "Past", "tier": "Illegal"},
    "sceptile": {"isNonstandard": "Past", "tier": "Illegal"},
    "sceptilemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "torchic": {"isNonstandard": "Past", "tier": "Illegal"},
    "combusken": {"isNonstandard": "Past", "tier": "Illegal"},
    "blaziken": {"isNonstandard": "Past", "tier": "Illegal"},
    "blazikenmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "mudkip": {"isNonstandard": "Past", "tier": "Illegal"},
    "marshtomp": {"isNonstandard": "Past", "tier": "Illegal"},
    "swampert": {"isNonstandard": "Past", "tier": "Illegal"},
    "swampertmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "poochyena": {"tier": "LC"},
    "mightyena": {"tier": "ZU", "doublesTier": "(DUU)"},
    "zigzagoon": {"isNonstandard": "Past", "tier": "Illegal"},
    "zigzagoongalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "linoone": {"isNonstandard": "Past", "tier": "Illegal"},
    "linoonegalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "obstagoon": {"isNonstandard": "Past", "tier": "Illegal"},
    "wurmple": {"isNonstandard": "Past", "tier": "Illegal"},
    "silcoon": {"isNonstandard": "Past", "tier": "Illegal"},
    "beautifly": {"isNonstandard": "Past", "tier": "Illegal"},
    "cascoon": {"isNonstandard": "Past", "tier": "Illegal"},
    "dustox": {"isNonstandard": "Past", "tier": "Illegal"},
    "lotad": {"tier": "LC"},
    "lombre": {"tier": "NFE"},
    "ludicolo": {"tier": "ZU", "doublesTier": "(DUU)"},
    "seedot": {"tier": "LC"},
    "nuzleaf": {"tier": "NFE"},
    "shiftry": {"tier": "NU", "doublesTier": "(DUU)"},
    "taillow": {"isNonstandard": "Past", "tier": "Illegal"},
    "swellow": {"isNonstandard": "Past", "tier": "Illegal"},
    "wingull": {"tier": "LC"},
    "pelipper": {"tier": "UU", "doublesTier": "DUU"},
    "ralts": {"tier": "LC"},
    "kirlia": {"tier": "NFE"},
    "gardevoir": {"tier": "RU", "doublesTier": "(DUU)"},
    "gardevoirmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "gallade": {"tier": "NU", "doublesTier": "(DUU)"},
    "gallademega": {"isNonstandard": "Past", "tier": "Illegal"},
    "surskit": {"tier": "LC"},
    "masquerain": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shroomish": {"tier": "LC"},
    "breloom": {"tier": "UU", "doublesTier": "(DUU)"},
    "slakoth": {"tier": "LC"},
    "vigoroth": {"tier": "NFE"},
    "slaking": {"tier": "ZU", "doublesTier": "(DUU)"},
    "nincada": {"isNonstandard": "Past", "tier": "Illegal"},
    "ninjask": {"isNonstandard": "Past", "tier": "Illegal"},
    "shedinja": {"isNonstandard": "Past", "tier": "Illegal"},
    "whismur": {"isNonstandard": "Past", "tier": "Illegal"},
    "loudred": {"isNonstandard": "Past", "tier": "Illegal"},
    "exploud": {"isNonstandard": "Past", "tier": "Illegal"},
    "makuhita": {"tier": "LC"},
    "hariyama": {"tier": "NU", "doublesTier": "(DUU)"},
    "nosepass": {"tier": "LC"},
    "probopass": {"tier": "PU", "doublesTier": "(DUU)"},
    "skitty": {"isNonstandard": "Past", "tier": "Illegal"},
    "delcatty": {"isNonstandard": "Past", "tier": "Illegal"},
    "sableye": {"tier": "PU", "doublesTier": "DUU"},
    "sableyemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "mawile": {"isNonstandard": "Past", "tier": "Illegal"},
    "mawilemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "aron": {"isNonstandard": "Past", "tier": "Illegal"},
    "lairon": {"isNonstandard": "Past", "tier": "Illegal"},
    "aggron": {"isNonstandard": "Past", "tier": "Illegal"},
    "aggronmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "meditite": {"tier": "NFE"},
    "medicham": {"tier": "PU", "doublesTier": "(DUU)"},
    "medichammega": {"isNonstandard": "Past", "tier": "Illegal"},
    "electrike": {"isNonstandard": "Past", "tier": "Illegal"},
    "manectric": {"isNonstandard": "Past", "tier": "Illegal"},
    "manectricmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "plusle": {"isNonstandard": "Past", "tier": "Illegal"},
    "minun": {"isNonstandard": "Past", "tier": "Illegal"},
    "volbeat": {"tier": "ZU", "doublesTier": "(DUU)"},
    "illumise": {"tier": "ZU", "doublesTier": "(DUU)"},
    "budew": {"isNonstandard": "Past", "tier": "Illegal"},
    "roselia": {"isNonstandard": "Past", "tier": "Illegal"},
    "roserade": {"isNonstandard": "Past", "tier": "Illegal"},
    "gulpin": {"tier": "LC"},
    "swalot": {"tier": "ZU", "doublesTier": "(DUU)"},
    "carvanha": {"isNonstandard": "Past", "tier": "Illegal"},
    "sharpedo": {"isNonstandard": "Past", "tier": "Illegal"},
    "sharpedomega": {"isNonstandard": "Past", "tier": "Illegal"},
    "wailmer": {"isNonstandard": "Past", "tier": "Illegal"},
    "wailord": {"isNonstandard": "Past", "tier": "Illegal"},
    "numel": {"tier": "LC"},
    "camerupt": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cameruptmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "torkoal": {"tier": "RU", "doublesTier": "DOU"},
    "spoink": {"tier": "LC"},
    "grumpig": {"tier": "ZU", "doublesTier": "(DUU)"},
    "spinda": {"isNonstandard": "Past", "tier": "Illegal"},
    "trapinch": {"isNonstandard": "Past", "tier": "Illegal"},
    "vibrava": {"isNonstandard": "Past", "tier": "Illegal"},
    "flygon": {"isNonstandard": "Past", "tier": "Illegal"},
    "cacnea": {"tier": "LC"},
    "cacturne": {"tier": "ZU", "doublesTier": "(DUU)"},
    "swablu": {"tier": "LC"},
    "altaria": {"tier": "NU", "doublesTier": "(DUU)"},
    "altariamega": {"isNonstandard": "Past", "tier": "Illegal"},
    "zangoose": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "seviper": {"tier": "ZU", "doublesTier": "(DUU)"},
    "lunatone": {"isNonstandard": "Past", "tier": "Illegal"},
    "solrock": {"isNonstandard": "Past", "tier": "Illegal"},
    "barboach": {"tier": "LC"},
    "whiscash": {"tier": "PU", "doublesTier": "(DUU)"},
    "corphish": {"tier": "LC"},
    "crawdaunt": {"tier": "UU", "doublesTier": "(DUU)"},
    "baltoy": {"isNonstandard": "Past", "tier": "Illegal"},
    "claydol": {"isNonstandard": "Past", "tier": "Illegal"},
    "lileep": {"isNonstandard": "Past", "tier": "Illegal"},
    "cradily": {"isNonstandard": "Past", "tier": "Illegal"},
    "anorith": {"isNonstandard": "Past", "tier": "Illegal"},
    "armaldo": {"isNonstandard": "Past", "tier": "Illegal"},
    "feebas": {"tier": "LC"},
    "milotic": {"tier": "RU", "doublesTier": "(DUU)"},
    "castform": {"isNonstandard": "Past", "tier": "Illegal"},
    "castformsunny": {"isNonstandard": "Past"},
    "castformrainy": {"isNonstandard": "Past"},
    "castformsnowy": {"isNonstandard": "Past"},
    "kecleon": {"isNonstandard": "Past", "tier": "Illegal"},
    "shuppet": {"tier": "LC"},
    "banette": {"tier": "ZU", "doublesTier": "(DUU)"},
    "banettemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "duskull": {"tier": "LC"},
    "dusclops": {"tier": "NFE"},
    "dusknoir": {"tier": "ZU", "doublesTier": "(DUU)"},
    "tropius": {"tier": "ZU", "doublesTier": "(DUU)"},
    "chingling": {"tier": "LC"},
    "chimecho": {"tier": "ZU", "doublesTier": "(DUU)"},
    "absol": {"isNonstandard": "Past", "tier": "Illegal"},
    "absolmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "snorunt": {"tier": "LC"},
    "glalie": {"tier": "ZU", "doublesTier": "(DUU)"},
    "glaliemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "froslass": {"tier": "PU", "doublesTier": "(DUU)"},
    "spheal": {"isNonstandard": "Past", "tier": "Illegal"},
    "sealeo": {"isNonstandard": "Past", "tier": "Illegal"},
    "walrein": {"isNonstandard": "Past", "tier": "Illegal"},
    "clamperl": {"isNonstandard": "Past", "tier": "Illegal"},
    "huntail": {"isNonstandard": "Past", "tier": "Illegal"},
    "gorebyss": {"isNonstandard": "Past", "tier": "Illegal"},
    "relicanth": {"isNonstandard": "Past", "tier": "Illegal"},
    "luvdisc": {"tier": "ZU", "doublesTier": "(DUU)"},
    "bagon": {"tier": "LC"},
    "shelgon": {"tier": "NFE"},
    "salamence": {"tier": "UU", "doublesTier": "(DUU)"},
    "salamencemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "beldum": {"isNonstandard": "Past", "tier": "Illegal"},
    "metang": {"isNonstandard": "Past", "tier": "Illegal"},
    "metagross": {"isNonstandard": "Past", "tier": "Illegal"},
    "metagrossmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "regirock": {"isNonstandard": "Past", "tier": "Illegal"},
    "regice": {"isNonstandard": "Past", "tier": "Illegal"},
    "registeel": {"isNonstandard": "Past", "tier": "Illegal"},
    "latias": {"isNonstandard": "Past", "tier": "Illegal"},
    "latiasmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "latios": {"isNonstandard": "Past", "tier": "Illegal"},
    "latiosmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "kyogre": {"tier": "Uber", "doublesTier": "DUber"},
    "kyogreprimal": {"isNonstandard": "Past", "tier": "Illegal"},
    "groudon": {"tier": "Uber", "doublesTier": "DUber"},
    "groudonprimal": {"isNonstandard": "Past", "tier": "Illegal"},
    "rayquaza": {"tier": "Uber", "doublesTier": "DUber"},
    "rayquazamega": {"isNonstandard": "Past", "tier": "Illegal"},
    "jirachi": {"tier": "UU", "doublesTier": "(DUU)"},
    "deoxys": {"isNonstandard": "Past", "tier": "Illegal"},
    "deoxysattack": {"isNonstandard": "Past", "tier": "Illegal"},
    "deoxysdefense": {"isNonstandard": "Past", "tier": "Illegal"},
    "deoxysspeed": {"isNonstandard": "Past", "tier": "Illegal"},
    "turtwig": {"tier": "LC"},
    "grotle": {"tier": "NFE"},
    "torterra": {"tier": "RU", "doublesTier": "(DUU)"},
    "chimchar": {"tier": "LC"},
    "monferno": {"tier": "NFE"},
    "infernape": {"tier": "UU", "doublesTier": "(DUU)"},
    "piplup": {"tier": "LC"},
    "prinplup": {"tier": "NFE"},
    "empoleon": {"tier": "UU", "doublesTier": "DUU"},
    "starly": {"tier": "LC"},
    "staravia": {"tier": "NFE"},
    "staraptor": {"tier": "RU", "doublesTier": "(DUU)"},
    "bidoof": {"isNonstandard": "Past", "tier": "Illegal"},
    "bibarel": {"isNonstandard": "Past", "tier": "Illegal"},
    "kricketot": {"tier": "LC"},
    "kricketune": {"tier": "ZU", "doublesTier": "(DUU)"},
    "shinx": {"tier": "LC"},
    "luxio": {"tier": "NFE"},
    "luxray": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cranidos": {"isNonstandard": "Past", "tier": "Illegal"},
    "rampardos": {"isNonstandard": "Past", "tier": "Illegal"},
    "shieldon": {"isNonstandard": "Past", "tier": "Illegal"},
    "bastiodon": {"isNonstandard": "Past", "tier": "Illegal"},
    "burmy": {"isNonstandard": "Past", "tier": "Illegal"},
    "wormadam": {"isNonstandard": "Past", "tier": "Illegal"},
    "wormadamsandy": {"isNonstandard": "Past", "tier": "Illegal"},
    "wormadamtrash": {"isNonstandard": "Past", "tier": "Illegal"},
    "mothim": {"isNonstandard": "Past", "tier": "Illegal"},
    "combee": {"tier": "LC"},
    "vespiquen": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pachirisu": {"tier": "ZU", "doublesTier": "(DUU)"},
    "buizel": {"tier": "LC"},
    "floatzel": {"tier": "PU", "doublesTier": "(DUU)"},
    "cherubi": {"isNonstandard": "Past", "tier": "Illegal"},
    "cherrim": {"isNonstandard": "Past", "tier": "Illegal"},
    "cherrimsunshine": {"isNonstandard": "Past"},
    "shellos": {"tier": "LC"},
    "gastrodon": {"tier": "UU", "doublesTier": "DUU"},
    "drifloon": {"tier": "LC"},
    "drifblim": {"tier": "NU", "doublesTier": "(DUU)"},
    "buneary": {"isNonstandard": "Past", "tier": "Illegal"},
    "lopunny": {"isNonstandard": "Past", "tier": "Illegal"},
    "lopunnymega": {"isNonstandard": "Past", "tier": "Illegal"},
    "glameow": {"isNonstandard": "Past", "tier": "Illegal"},
    "purugly": {"isNonstandard": "Past", "tier": "Illegal"},
    "stunky": {"tier": "LC"},
    "skuntank": {"tier": "PU", "doublesTier": "(DUU)"},
    "bronzor": {"tier": "LC"},
    "bronzong": {"tier": "NU", "doublesTier": "(DUU)"},
    "chatot": {"isNonstandard": "Past", "tier": "Illegal"},
    "spiritomb": {"tier": "PU", "doublesTier": "(DUU)"},
    "gible": {"tier": "LC"},
    "gabite": {"tier": "ZU", "doublesTier": "NFE"},
    "garchomp": {"tier": "OU", "doublesTier": "DUU"},
    "garchompmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "riolu": {"tier": "LC"},
    "lucario": {"tier": "RU", "doublesTier": "(DUU)"},
    "lucariomega": {"isNonstandard": "Past", "tier": "Illegal"},
    "hippopotas": {"tier": "LC"},
    "hippowdon": {"tier": "RU", "doublesTier": "(DUU)"},
    "skorupi": {"isNonstandard": "Past", "tier": "Illegal"},
    "drapion": {"isNonstandard": "Past", "tier": "Illegal"},
    "croagunk": {"tier": "LC"},
    "toxicroak": {"tier": "NU", "doublesTier": "(DUU)"},
    "carnivine": {"isNonstandard": "Past", "tier": "Illegal"},
    "finneon": {"tier": "LC"},
    "lumineon": {"tier": "ZU", "doublesTier": "(DUU)"},
    "snover": {"tier": "LC"},
    "abomasnow": {"tier": "NU", "doublesTier": "DUU"},
    "abomasnowmega": {"isNonstandard": "Past", "tier": "Illegal"},
    "rotom": {"tier": "PU", "doublesTier": "(DUU)"},
    "rotomheat": {"tier": "RU", "doublesTier": "(DUU)"},
    "rotomwash": {"tier": "UU", "doublesTier": "(DUU)"},
    "rotomfrost": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rotomfan": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rotommow": {"tier": "NU", "doublesTier": "(DUU)"},
    "uxie": {"tier": "NU", "doublesTier": "(DUU)"},
    "mesprit": {"tier": "PU", "doublesTier": "(DUU)"},
    "azelf": {"tier": "RU", "doublesTier": "(DUU)"},
    "dialga": {"tier": "Uber", "doublesTier": "DUber"},
    "dialgaorigin": {"tier": "Uber", "doublesTier": "DUber"},
    "palkia": {"tier": "Uber", "doublesTier": "DUber"},
    "palkiaorigin": {"tier": "Uber", "doublesTier": "DUber"},
    "heatran": {"tier": "OU", "doublesTier": "DOU"},
    "regigigas": {"isNonstandard": "Past", "tier": "Illegal"},
    "giratina": {"tier": "Uber", "doublesTier": "DUber"},
    "giratinaorigin": {"tier": "Uber", "doublesTier": "DUber"},
    "cresselia": {"tier": "UU", "doublesTier": "DOU"},
    "phione": {"tier": "ZU", "doublesTier": "(DUU)"},
    "manaphy": {"tier": "OU", "doublesTier": "(DUU)"},
    "darkrai": {"tier": "Uber", "doublesTier": "DUber"},
    "shaymin": {"tier": "NU", "doublesTier": "(DUU)"},
    "shayminsky": {"tier": "Uber", "doublesTier": "(DUU)"},
    "arceus": {"tier": "Uber", "doublesTier": "DUber"},
    "victini": {"isNonstandard": "Past", "tier": "Illegal"},
    "snivy": {"isNonstandard": "Past", "tier": "Illegal"},
    "servine": {"isNonstandard": "Past", "tier": "Illegal"},
    "serperior": {"isNonstandard": "Past", "tier": "Illegal"},
    "tepig": {"isNonstandard": "Past", "tier": "Illegal"},
    "pignite": {"isNonstandard": "Past", "tier": "Illegal"},
    "emboar": {"isNonstandard": "Past", "tier": "Illegal"},
    "oshawott": {"tier": "LC"},
    "dewott": {"tier": "NFE"},
    "samurott": {"tier": "PU", "doublesTier": "(DUU)"},
    "samurotthisui": {"tier": "OU", "doublesTier": "(DUU)"},
    "patrat": {"isNonstandard": "Past", "tier": "Illegal"},
    "watchog": {"isNonstandard": "Past", "tier": "Illegal"},
    "lillipup": {"isNonstandard": "Past", "tier": "Illegal"},
    "herdier": {"isNonstandard": "Past", "tier": "Illegal"},
    "stoutland": {"isNonstandard": "Past", "tier": "Illegal"},
    "purrloin": {"isNonstandard": "Past", "tier": "Illegal"},
    "liepard": {"isNonstandard": "Past", "tier": "Illegal"},
    "pansage": {"isNonstandard": "Past", "tier": "Illegal"},
    "simisage": {"isNonstandard": "Past", "tier": "Illegal"},
    "pansear": {"isNonstandard": "Past", "tier": "Illegal"},
    "simisear": {"isNonstandard": "Past", "tier": "Illegal"},
    "panpour": {"isNonstandard": "Past", "tier": "Illegal"},
    "simipour": {"isNonstandard": "Past", "tier": "Illegal"},
    "munna": {"isNonstandard": "Past", "tier": "Illegal"},
    "musharna": {"isNonstandard": "Past", "tier": "Illegal"},
    "pidove": {"isNonstandard": "Past", "tier": "Illegal"},
    "tranquill": {"isNonstandard": "Past", "tier": "Illegal"},
    "unfezant": {"isNonstandard": "Past", "tier": "Illegal"},
    "blitzle": {"isNonstandard": "Past", "tier": "Illegal"},
    "zebstrika": {"isNonstandard": "Past", "tier": "Illegal"},
    "roggenrola": {"isNonstandard": "Past", "tier": "Illegal"},
    "boldore": {"isNonstandard": "Past", "tier": "Illegal"},
    "gigalith": {"isNonstandard": "Past", "tier": "Illegal"},
    "woobat": {"isNonstandard": "Past", "tier": "Illegal"},
    "swoobat": {"isNonstandard": "Past", "tier": "Illegal"},
    "drilbur": {"isNonstandard": "Past", "tier": "Illegal"},
    "excadrill": {"isNonstandard": "Past", "tier": "Illegal"},
    "audino": {"isNonstandard": "Past", "tier": "Illegal"},
    "audinomega": {"isNonstandard": "Past", "tier": "Illegal"},
    "timburr": {"tier": "LC"},
    "gurdurr": {"tier": "PU", "doublesTier": "NFE"},
    "conkeldurr": {"tier": "UU", "doublesTier": "DUU"},
    "tympole": {"isNonstandard": "Past", "tier": "Illegal"},
    "palpitoad": {"isNonstandard": "Past", "tier": "Illegal"},
    "seismitoad": {"isNonstandard": "Past", "tier": "Illegal"},
    "throh": {"isNonstandard": "Past", "tier": "Illegal"},
    "sawk": {"isNonstandard": "Past", "tier": "Illegal"},
    "sewaddle": {"tier": "LC"},
    "swadloon": {"tier": "NFE"},
    "leavanny": {"tier": "ZU", "doublesTier": "(DUU)"},
    "venipede": {"isNonstandard": "Past", "tier": "Illegal"},
    "whirlipede": {"isNonstandard": "Past", "tier": "Illegal"},
    "scolipede": {"isNonstandard": "Past", "tier": "Illegal"},
    "cottonee": {"isNonstandard": "Past", "tier": "Illegal"},
    "whimsicott": {"isNonstandard": "Past", "tier": "Illegal"},
    "petilil": {"tier": "LC"},
    "lilligant": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "lilliganthisui": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "basculin": {"tier": "PU", "doublesTier": "(DUU)"},
    "basculegion": {"tier": "RU", "doublesTier": "DUber"},
    "basculegionf": {"tier": "UU", "doublesTier": "DUU"},
    "sandile": {"tier": "LC"},
    "krokorok": {"tier": "NFE"},
    "krookodile": {"tier": "RU", "doublesTier": "(DUU)"},
    "darumaka": {"isNonstandard": "Past", "tier": "Illegal"},
    "darumakagalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "darmanitan": {"isNonstandard": "Past", "tier": "Illegal"},
    "darmanitanzen": {"isNonstandard": "Past"},
    "darmanitangalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "darmanitangalarzen": {"isNonstandard": "Past"},
    "maractus": {"isNonstandard": "Past", "tier": "Illegal"},
    "dwebble": {"isNonstandard": "Past", "tier": "Illegal"},
    "crustle": {"isNonstandard": "Past", "tier": "Illegal"},
    "scraggy": {"isNonstandard": "Past", "tier": "Illegal"},
    "scrafty": {"isNonstandard": "Past", "tier": "Illegal"},
    "sigilyph": {"isNonstandard": "Past", "tier": "Illegal"},
    "yamask": {"isNonstandard": "Past", "tier": "Illegal"},
    "yamaskgalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "cofagrigus": {"isNonstandard": "Past", "tier": "Illegal"},
    "runerigus": {"isNonstandard": "Past", "tier": "Illegal"},
    "tirtouga": {"isNonstandard": "Past", "tier": "Illegal"},
    "carracosta": {"isNonstandard": "Past", "tier": "Illegal"},
    "archen": {"isNonstandard": "Past", "tier": "Illegal"},
    "archeops": {"isNonstandard": "Past", "tier": "Illegal"},
    "trubbish": {"isNonstandard": "Past", "tier": "Illegal"},
    "garbodor": {"isNonstandard": "Past", "tier": "Illegal"},
    "garbodorgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "zorua": {"tier": "LC"},
    "zoruahisui": {"tier": "LC"},
    "zoroark": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "zoroarkhisui": {"tier": "RU", "doublesTier": "(DUU)"},
    "minccino": {"isNonstandard": "Past", "tier": "Illegal"},
    "cinccino": {"isNonstandard": "Past", "tier": "Illegal"},
    "gothita": {"tier": "LC"},
    "gothorita": {"tier": "NFE"},
    "gothitelle": {"tier": "ZU", "doublesTier": "(DUU)"},
    "solosis": {"isNonstandard": "Past", "tier": "Illegal"},
    "duosion": {"isNonstandard": "Past", "tier": "Illegal"},
    "reuniclus": {"isNonstandard": "Past", "tier": "Illegal"},
    "ducklett": {"tier": "LC"},
    "swanna": {"tier": "PU", "doublesTier": "(DUU)"},
    "vanillite": {"isNonstandard": "Past", "tier": "Illegal"},
    "vanillish": {"isNonstandard": "Past", "tier": "Illegal"},
    "vanilluxe": {"isNonstandard": "Past", "tier": "Illegal"},
    "deerling": {"tier": "LC"},
    "sawsbuck": {"tier": "ZU", "doublesTier": "(DUU)"},
    "emolga": {"isNonstandard": "Past", "tier": "Illegal"},
    "karrablast": {"isNonstandard": "Past", "tier": "Illegal"},
    "escavalier": {"isNonstandard": "Past", "tier": "Illegal"},
    "foongus": {"tier": "LC"},
    "amoonguss": {"tier": "OU", "doublesTier": "DOU"},
    "frillish": {"isNonstandard": "Past", "tier": "Illegal"},
    "jellicent": {"isNonstandard": "Past", "tier": "Illegal"},
    "alomomola": {"tier": "OU", "doublesTier": "(DUU)"},
    "joltik": {"isNonstandard": "Past", "tier": "Illegal"},
    "galvantula": {"isNonstandard": "Past", "tier": "Illegal"},
    "ferroseed": {"isNonstandard": "Past", "tier": "Illegal"},
    "ferrothorn": {"isNonstandard": "Past", "tier": "Illegal"},
    "klink": {"isNonstandard": "Past", "tier": "Illegal"},
    "klang": {"isNonstandard": "Past", "tier": "Illegal"},
    "klinklang": {"isNonstandard": "Past", "tier": "Illegal"},
    "tynamo": {"tier": "LC"},
    "eelektrik": {"tier": "NFE"},
    "eelektross": {"tier": "PU", "doublesTier": "(DUU)"},
    "elgyem": {"isNonstandard": "Past", "tier": "Illegal"},
    "beheeyem": {"isNonstandard": "Past", "tier": "Illegal"},
    "litwick": {"tier": "LC"},
    "lampent": {"tier": "NFE"},
    "chandelure": {"tier": "RU", "doublesTier": "(DUU)"},
    "axew": {"tier": "LC"},
    "fraxure": {"tier": "NFE"},
    "haxorus": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "cubchoo": {"tier": "LC"},
    "beartic": {"tier": "ZU", "doublesTier": "(DUU)"},
    "cryogonal": {"tier": "PU", "doublesTier": "(DUU)"},
    "shelmet": {"isNonstandard": "Past", "tier": "Illegal"},
    "accelgor": {"isNonstandard": "Past", "tier": "Illegal"},
    "stunfisk": {"isNonstandard": "Past", "tier": "Illegal"},
    "stunfiskgalar": {"isNonstandard": "Past", "tier": "Illegal"},
    "mienfoo": {"tier": "LC"},
    "mienshao": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "druddigon": {"isNonstandard": "Past", "tier": "Illegal"},
    "golett": {"isNonstandard": "Past", "tier": "Illegal"},
    "golurk": {"isNonstandard": "Past", "tier": "Illegal"},
    "pawniard": {"tier": "LC"},
    "bisharp": {"tier": "RU", "doublesTier": "NFE"},
    "bouffalant": {"isNonstandard": "Past", "tier": "Illegal"},
    "rufflet": {"tier": "NFE"},
    "braviary": {"tier": "PU", "doublesTier": "(DUU)"},
    "braviaryhisui": {"tier": "NU", "doublesTier": "(DUU)"},
    "vullaby": {"tier": "LC"},
    "mandibuzz": {"tier": "UU", "doublesTier": "(DUU)"},
    "heatmor": {"isNonstandard": "Past", "tier": "Illegal"},
    "durant": {"isNonstandard": "Past", "tier": "Illegal"},
    "deino": {"tier": "LC"},
    "zweilous": {"tier": "NFE"},
    "hydreigon": {"tier": "UU", "doublesTier": "(DUU)"},
    "larvesta": {"tier": "LC"},
    "volcarona": {"tier": "Uber", "doublesTier": "DUU"},
    "cobalion": {"isNonstandard": "Past", "tier": "Illegal"},
    "terrakion": {"isNonstandard": "Past", "tier": "Illegal"},
    "virizion": {"isNonstandard": "Past", "tier": "Illegal"},
    "tornadus": {"tier": "NUBL", "doublesTier": "DOU"},
    "tornadustherian": {"tier": "UU", "doublesTier": "(DUU)"},
    "thundurus": {"tier": "RU", "doublesTier": "DUU"},
    "thundurustherian": {"tier": "UU", "doublesTier": "(DUU)"},
    "reshiram": {"isNonstandard": "Past", "tier": "Illegal"},
    "zekrom": {"isNonstandard": "Past", "tier": "Illegal"},
    "landorus": {"tier": "Uber", "doublesTier": "DUU"},
    "landorustherian": {"tier": "OU", "doublesTier": "DOU"},
    "kyurem": {"isNonstandard": "Past", "tier": "Illegal"},
    "kyuremblack": {"isNonstandard": "Past", "tier": "Illegal"},
    "kyuremwhite": {"isNonstandard": "Past", "tier": "Illegal"},
    "keldeo": {"isNonstandard": "Past", "tier": "Illegal"},
    "keldeoresolute": {"isNonstandard": "Past"},
    "meloetta": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "genesect": {"isNonstandard": "Past", "tier": "Illegal"},
    "genesectburn": {"isNonstandard": "Past", "tier": "Illegal"},
    "genesectchill": {"isNonstandard": "Past", "tier": "Illegal"},
    "genesectdouse": {"isNonstandard": "Past", "tier": "Illegal"},
    "genesectshock": {"isNonstandard": "Past", "tier": "Illegal"},
    "chespin": {"tier": "LC"},
    "quilladin": {"tier": "NFE"},
    "chesnaught": {"tier": "RU", "doublesTier": "(DUU)"},
    "fennekin": {"tier": "LC"},
    "braixen": {"tier": "NFE"},
    "delphox": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "froakie": {"tier": "LC"},
    "frogadier": {"tier": "ZU", "doublesTier": "NFE"},
    "greninja": {"tier": "OU", "doublesTier": "(DUU)"},
    "greninjaash": {"isNonstandard": "Past", "tier": "Illegal"},
    "bunnelby": {"isNonstandard": "Past", "tier": "Illegal"},
    "diggersby": {"isNonstandard": "Past", "tier": "Illegal"},
    "fletchling": {"tier": "LC"},
    "fletchinder": {"tier": "NFE"},
    "talonflame": {"tier": "RU", "doublesTier": "DUU"},
    "scatterbug": {"tier": "LC"},
    "spewpa": {"tier": "NFE"},
    "vivillon": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "litleo": {"tier": "LC"},
    "pyroar": {"tier": "PU", "doublesTier": "(DUU)"},
    "flabebe": {"tier": "LC"},
    "floette": {"tier": "NFE"},
    "floetteeternal": {"isNonstandard": "Past", "tier": "Illegal"},
    "florges": {"tier": "NU", "doublesTier": "(DUU)"},
    "skiddo": {"tier": "LC"},
    "gogoat": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pancham": {"isNonstandard": "Past", "tier": "Illegal"},
    "pangoro": {"isNonstandard": "Past", "tier": "Illegal"},
    "furfrou": {"isNonstandard": "Past", "tier": "Illegal"},
    "espurr": {"isNonstandard": "Past", "tier": "Illegal"},
    "meowstic": {"isNonstandard": "Past", "tier": "Illegal"},
    "meowsticf": {"isNonstandard": "Past", "tier": "Illegal"},
    "honedge": {"isNonstandard": "Past", "tier": "Illegal"},
    "doublade": {"isNonstandard": "Past", "tier": "Illegal"},
    "aegislash": {"isNonstandard": "Past", "tier": "Illegal"},
    "aegislashblade": {"isNonstandard": "Past"},
    "spritzee": {"isNonstandard": "Past", "tier": "Illegal"},
    "aromatisse": {"isNonstandard": "Past", "tier": "Illegal"},
    "swirlix": {"isNonstandard": "Past", "tier": "Illegal"},
    "slurpuff": {"isNonstandard": "Past", "tier": "Illegal"},
    "inkay": {"isNonstandard": "Past", "tier": "Illegal"},
    "malamar": {"isNonstandard": "Past", "tier": "Illegal"},
    "binacle": {"isNonstandard": "Past", "tier": "Illegal"},
    "barbaracle": {"isNonstandard": "Past", "tier": "Illegal"},
    "skrelp": {"tier": "LC"},
    "dragalge": {"tier": "RU", "doublesTier": "(DUU)"},
    "clauncher": {"tier": "LC"},
    "clawitzer": {"tier": "PU", "doublesTier": "(DUU)"},
    "helioptile": {"isNonstandard": "Past", "tier": "Illegal"},
    "heliolisk": {"isNonstandard": "Past", "tier": "Illegal"},
    "tyrunt": {"isNonstandard": "Past", "tier": "Illegal"},
    "tyrantrum": {"isNonstandard": "Past", "tier": "Illegal"},
    "amaura": {"isNonstandard": "Past", "tier": "Illegal"},
    "aurorus": {"isNonstandard": "Past", "tier": "Illegal"},
    "hawlucha": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "dedenne": {"tier": "ZU", "doublesTier": "(DUU)"},
    "carbink": {"tier": "PU", "doublesTier": "(DUU)"},
    "goomy": {"tier": "LC"},
    "sliggoo": {"tier": "NFE"},
    "sliggoohisui": {"tier": "NFE"},
    "goodra": {"tier": "NU", "doublesTier": "(DUU)"},
    "goodrahisui": {"tier": "UU", "doublesTier": "(DUU)"},
    "klefki": {"tier": "NU", "doublesTier": "(DUU)"},
    "phantump": {"tier": "LC"},
    "trevenant": {"tier": "ZU", "doublesTier": "(DUU)"},
    "pumpkaboo": {"isNonstandard": "Past", "tier": "Illegal"},
    "pumpkaboosmall": {"isNonstandard": "Past"},
    "pumpkaboolarge": {"isNonstandard": "Past"},
    "pumpkaboosuper": {"isNonstandard": "Past"},
    "gourgeist": {"isNonstandard": "Past", "tier": "Illegal"},
    "gourgeistsmall": {"isNonstandard": "Past"},
    "gourgeistlarge": {"isNonstandard": "Past"},
    "gourgeistsuper": {"isNonstandard": "Past"},
    "bergmite": {"tier": "LC"},
    "avalugg": {"tier": "NU", "doublesTier": "(DUU)"},
    "avalugghisui": {"tier": "PU", "doublesTier": "(DUU)"},
    "noibat": {"tier": "LC"},
    "noivern": {"tier": "RU", "doublesTier": "(DUU)"},
    "xerneas": {"isNonstandard": "Past", "tier": "Illegal"},
    "xerneasneutral": {"isNonstandard": "Custom", "tier": "Illegal"},
    "yveltal": {"isNonstandard": "Past", "tier": "Illegal"},
    "zygarde": {"isNonstandard": "Past", "tier": "Illegal"},
    "zygarde10": {"isNonstandard": "Past", "tier": "Illegal"},
    "zygardecomplete": {"isNonstandard": "Past", "tier": "Illegal"},
    "diancie": {"tier": "RU", "doublesTier": "DOU"},
    "dianciemega": {"isNonstandard": "Past", "tier": "Illegal"},
    "hoopa": {"tier": "NU", "doublesTier": "(DUU)"},
    "hoopaunbound": {"tier": "UU", "doublesTier": "(DUU)"},
    "volcanion": {"tier": "UU", "doublesTier": "DOU"},
    "rowlet": {"tier": "LC"},
    "dartrix": {"tier": "NFE"},
    "decidueye": {"tier": "NU", "doublesTier": "(DUU)"},
    "decidueyehisui": {"tier": "NU", "doublesTier": "(DUU)"},
    "litten": {"isNonstandard": "Past", "tier": "Illegal"},
    "torracat": {"isNonstandard": "Past", "tier": "Illegal"},
    "incineroar": {"isNonstandard": "Past", "tier": "Illegal"},
    "popplio": {"isNonstandard": "Past", "tier": "Illegal"},
    "brionne": {"isNonstandard": "Past", "tier": "Illegal"},
    "primarina": {"isNonstandard": "Past", "tier": "Illegal"},
    "pikipek": {"isNonstandard": "Past", "tier": "Illegal"},
    "trumbeak": {"isNonstandard": "Past", "tier": "Illegal"},
    "toucannon": {"isNonstandard": "Past", "tier": "Illegal"},
    "yungoos": {"tier": "LC"},
    "gumshoos": {"tier": "ZU", "doublesTier": "(DUU)"},
    "gumshoostotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "grubbin": {"tier": "LC"},
    "charjabug": {"tier": "NFE"},
    "vikavolt": {"tier": "NU", "doublesTier": "(DUU)"},
    "vikavolttotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "crabrawler": {"tier": "LC"},
    "crabominable": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "oricorio": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "oricoriopompom": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "oricoriopau": {"tier": "PUBL", "doublesTier": "(DUU)"},
    "oricoriosensu": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "cutiefly": {"tier": "NFE"},
    "ribombee": {"tier": "OU", "doublesTier": "(DUU)"},
    "ribombeetotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "rockruff": {"tier": "LC"},
    "rockruffdusk": {"tier": "LC"},
    "lycanroc": {"tier": "NU", "doublesTier": "(DUU)"},
    "lycanrocmidnight": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "lycanrocdusk": {"tier": "RU", "doublesTier": "(DUU)"},
    "wishiwashi": {"isNonstandard": "Past", "tier": "Illegal"},
    "wishiwashischool": {"isNonstandard": "Past"},
    "mareanie": {"tier": "LC"},
    "toxapex": {"tier": "OU", "doublesTier": "(DUU)"},
    "mudbray": {"tier": "LC"},
    "mudsdale": {"tier": "NU", "doublesTier": "(DUU)"},
    "dewpider": {"isNonstandard": "Past", "tier": "Illegal"},
    "araquanid": {"isNonstandard": "Past", "tier": "Illegal"},
    "araquanidtotem": {"isNonstandard": "Past"},
    "fomantis": {"tier": "LC"},
    "lurantis": {"tier": "PU", "doublesTier": "(DUU)"},
    "lurantistotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "morelull": {"isNonstandard": "Past", "tier": "Illegal"},
    "shiinotic": {"isNonstandard": "Past", "tier": "Illegal"},
    "salandit": {"tier": "LC"},
    "salazzle": {"tier": "NU", "doublesTier": "(DUU)"},
    "salazzletotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "stufful": {"isNonstandard": "Past", "tier": "Illegal"},
    "bewear": {"isNonstandard": "Past", "tier": "Illegal"},
    "bounsweet": {"tier": "LC"},
    "steenee": {"tier": "NFE"},
    "tsareena": {"tier": "NU", "doublesTier": "(DUU)"},
    "comfey": {"isNonstandard": "Past", "tier": "Illegal"},
    "oranguru": {"tier": "ZU", "doublesTier": "DUU"},
    "passimian": {"tier": "PU", "doublesTier": "(DUU)"},
    "wimpod": {"isNonstandard": "Past", "tier": "Illegal"},
    "golisopod": {"isNonstandard": "Past", "tier": "Illegal"},
    "sandygast": {"tier": "LC"},
    "palossand": {"tier": "PU", "doublesTier": "(DUU)"},
    "pyukumuku": {"isNonstandard": "Past", "tier": "Illegal"},
    "typenull": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvally": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallybug": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallydark": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallydragon": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallyelectric": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallyfairy": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallyfighting": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallyfire": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallyflying": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallyghost": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallygrass": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallyground": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallyice": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallypoison": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallypsychic": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallyrock": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallysteel": {"isNonstandard": "Past", "tier": "Illegal"},
    "silvallywater": {"isNonstandard": "Past", "tier": "Illegal"},
    "minior": {"isNonstandard": "Past", "tier": "Illegal"},
    "miniormeteor": {"isNonstandard": "Past"},
    "komala": {"tier": "PU", "doublesTier": "(DUU)"},
    "turtonator": {"isNonstandard": "Past", "tier": "Illegal"},
    "togedemaru": {"isNonstandard": "Past", "tier": "Illegal"},
    "togedemarutotem": {"isNonstandard": "Past"},
    "mimikyu": {"tier": "RU", "doublesTier": "(DUU)"},
    "mimikyutotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "mimikyubustedtotem": {"isNonstandard": "Past", "tier": "Illegal"},
    "bruxish": {"tier": "NU", "doublesTier": "(DUU)"},
    "drampa": {"isNonstandard": "Past", "tier": "Illegal"},
    "dhelmise": {"isNonstandard": "Past", "tier": "Illegal"},
    "jangmoo": {"tier": "LC"},
    "hakamoo": {"tier": "NFE"},
    "kommoo": {"tier": "UUBL", "doublesTier": "DOU"},
    "kommoototem": {"isNonstandard": "Past", "tier": "Illegal"},
    "tapukoko": {"isNonstandard": "Past", "tier": "Illegal"},
    "tapulele": {"isNonstandard": "Past", "tier": "Illegal"},
    "tapubulu": {"isNonstandard": "Past", "tier": "Illegal"},
    "tapufini": {"isNonstandard": "Past", "tier": "Illegal"},
    "cosmog": {"isNonstandard": "Past", "tier": "Illegal"},
    "cosmoem": {"isNonstandard": "Past", "tier": "Illegal"},
    "solgaleo": {"isNonstandard": "Past", "tier": "Illegal"},
    "lunala": {"isNonstandard": "Past", "tier": "Illegal"},
    "nihilego": {"isNonstandard": "Past", "tier": "Illegal"},
    "buzzwole": {"isNonstandard": "Past", "tier": "Illegal"},
    "pheromosa": {"isNonstandard": "Past", "tier": "Illegal"},
    "xurkitree": {"isNonstandard": "Past", "tier": "Illegal"},
    "celesteela": {"isNonstandard": "Past", "tier": "Illegal"},
    "kartana": {"isNonstandard": "Past", "tier": "Illegal"},
    "guzzlord": {"isNonstandard": "Past", "tier": "Illegal"},
    "necrozma": {"isNonstandard": "Past", "tier": "Illegal"},
    "necrozmaduskmane": {"isNonstandard": "Past", "tier": "Illegal"},
    "necrozmadawnwings": {"isNonstandard": "Past", "tier": "Illegal"},
    "necrozmaultra": {"isNonstandard": "Past", "tier": "Illegal"},
    "magearna": {"tier": "Uber", "doublesTier": "DUber"},
    "marshadow": {"isNonstandard": "Past", "tier": "Illegal"},
    "poipole": {"isNonstandard": "Past", "tier": "Illegal"},
    "naganadel": {"isNonstandard": "Past", "tier": "Illegal"},
    "stakataka": {"isNonstandard": "Past", "tier": "Illegal"},
    "blacephalon": {"isNonstandard": "Past", "tier": "Illegal"},
    "zeraora": {"isNonstandard": "Past", "tier": "Illegal"},
    "meltan": {"isNonstandard": "Past", "tier": "Illegal"},
    "melmetal": {"isNonstandard": "Past", "tier": "Illegal"},
    "melmetalgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "grookey": {"tier": "LC"},
    "thwackey": {"tier": "ZU", "doublesTier": "NFE"},
    "rillaboom": {"tier": "OU", "doublesTier": "DOU"},
    "rillaboomgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "scorbunny": {"tier": "LC"},
    "raboot": {"tier": "NFE"},
    "cinderace": {"tier": "OU", "doublesTier": "(DUU)"},
    "cinderacegmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "sobble": {"tier": "LC"},
    "drizzile": {"tier": "NFE"},
    "inteleon": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "inteleongmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "skwovet": {"tier": "LC"},
    "greedent": {"tier": "ZU", "doublesTier": "(DUU)"},
    "rookidee": {"tier": "LC"},
    "corvisquire": {"tier": "NFE"},
    "corviknight": {"tier": "OU", "doublesTier": "(DUU)"},
    "corviknightgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "blipbug": {"isNonstandard": "Past", "tier": "Illegal"},
    "dottler": {"isNonstandard": "Past", "tier": "Illegal"},
    "orbeetle": {"isNonstandard": "Past", "tier": "Illegal"},
    "orbeetlegmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "nickit": {"isNonstandard": "Past", "tier": "Illegal"},
    "thievul": {"isNonstandard": "Past", "tier": "Illegal"},
    "gossifleur": {"isNonstandard": "Past", "tier": "Illegal"},
    "eldegoss": {"isNonstandard": "Past", "tier": "Illegal"},
    "wooloo": {"isNonstandard": "Past", "tier": "Illegal"},
    "dubwool": {"isNonstandard": "Past", "tier": "Illegal"},
    "chewtle": {"tier": "LC"},
    "drednaw": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "drednawgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "yamper": {"isNonstandard": "Past", "tier": "Illegal"},
    "boltund": {"isNonstandard": "Past", "tier": "Illegal"},
    "rolycoly": {"tier": "LC"},
    "carkol": {"tier": "NFE"},
    "coalossal": {"tier": "NU", "doublesTier": "(DUU)"},
    "coalossalgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "applin": {"tier": "LC"},
    "flapple": {"tier": "ZU", "doublesTier": "(DUU)"},
    "flapplegmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "appletun": {"tier": "PU", "doublesTier": "(DUU)"},
    "appletungmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "dipplin": {"tier": "NU", "doublesTier": "(DUU)"},
    "silicobra": {"tier": "LC"},
    "sandaconda": {"tier": "NU", "doublesTier": "(DUU)"},
    "sandacondagmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "cramorant": {"tier": "PU", "doublesTier": "(DUU)"},
    "arrokuda": {"tier": "LC"},
    "barraskewda": {"tier": "UU", "doublesTier": "(DUU)"},
    "toxel": {"tier": "LC"},
    "toxtricity": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "toxtricitygmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "toxtricitylowkeygmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "sizzlipede": {"isNonstandard": "Past", "tier": "Illegal"},
    "centiskorch": {"isNonstandard": "Past", "tier": "Illegal"},
    "centiskorchgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "clobbopus": {"isNonstandard": "Past", "tier": "Illegal"},
    "grapploct": {"isNonstandard": "Past", "tier": "Illegal"},
    "sinistea": {"tier": "LC"},
    "polteageist": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "hatenna": {"tier": "LC"},
    "hattrem": {"tier": "ZU", "doublesTier": "NFE"},
    "hatterene": {"tier": "OU", "doublesTier": "DUU"},
    "hatterenegmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "impidimp": {"tier": "LC"},
    "morgrem": {"tier": "NFE"},
    "grimmsnarl": {"tier": "UU", "doublesTier": "DOU"},
    "grimmsnarlgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "milcery": {"isNonstandard": "Past", "tier": "Illegal"},
    "alcremie": {"isNonstandard": "Past", "tier": "Illegal"},
    "alcremiegmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "falinks": {"tier": "ZUBL", "doublesTier": "(DUU)"},
    "pincurchin": {"tier": "ZU", "doublesTier": "(DUU)"},
    "snom": {"tier": "LC"},
    "frosmoth": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "stonjourner": {"tier": "ZU", "doublesTier": "(DUU)"},
    "eiscue": {"tier": "ZU", "doublesTier": "(DUU)"},
    "indeedee": {"tier": "PU", "doublesTier": "(DUU)"},
    "indeedeef": {"tier": "ZU", "doublesTier": "DOU"},
    "morpeko": {"tier": "PU", "doublesTier": "(DUU)"},
    "cufant": {"tier": "LC"},
    "copperajah": {"tier": "NU", "doublesTier": "(DUU)"},
    "copperajahgmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "dracozolt": {"isNonstandard": "Past", "tier": "Illegal"},
    "arctozolt": {"isNonstandard": "Past", "tier": "Illegal"},
    "dracovish": {"isNonstandard": "Past", "tier": "Illegal"},
    "arctovish": {"isNonstandard": "Past", "tier": "Illegal"},
    "duraludon": {"isNonstandard": "Past", "tier": "Illegal"},
    "duraludongmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "dreepy": {"tier": "LC"},
    "drakloak": {"tier": "NFE"},
    "dragapult": {"tier": "OU", "doublesTier": "DUU"},
    "zacian": {"tier": "Uber", "doublesTier": "DUber"},
    "zaciancrowned": {"tier": "Uber", "doublesTier": "DUber"},
    "zamazenta": {"tier": "OU", "doublesTier": "DUber"},
    "zamazentacrowned": {"tier": "Uber", "doublesTier": "DUber"},
    "eternatus": {"tier": "Uber", "doublesTier": "DUber"},
    "eternatuseternamax": {"isNonstandard": "Past", "tier": "Illegal"},
    "kubfu": {"tier": "NFE"},
    "urshifu": {"tier": "Uber", "doublesTier": "DUber"},
    "urshifurapidstrike": {"tier": "Uber", "doublesTier": "DUber"},
    "urshifugmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "urshifurapidstrikegmax": {"isNonstandard": "Past", "tier": "Illegal"},
    "zarude": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "regieleki": {"tier": "Uber", "doublesTier": "DUU"},
    "regidrago": {"tier": "RUBL", "doublesTier": "(DUU)"},
    "glastrier": {"tier": "NU", "doublesTier": "(DUU)"},
    "spectrier": {"tier": "Uber", "doublesTier": "(DUU)"},
    "calyrex": {"tier": "ZU", "doublesTier": "(DUU)"},
    "calyrexice": {"tier": "Uber", "doublesTier": "DUber"},
    "calyrexshadow": {"tier": "AG", "doublesTier": "DUber"},
    "enamorus": {"tier": "OU", "doublesTier": "DUU"},
    "enamorustherian": {"tier": "UU", "doublesTier": "(DUU)"},
    "sprigatito": {"tier": "LC"},
    "floragato": {"tier": "NFE"},
    "meowscarada": {"tier": "UU", "doublesTier": "DUU"},
    "fuecoco": {"tier": "LC"},
    "crocalor": {"tier": "NFE"},
    "skeledirge": {"tier": "OU", "doublesTier": "(DUU)"},
    "quaxly": {"tier": "LC"},
    "quaxwell": {"tier": "ZU", "doublesTier": "NFE"},
    "quaquaval": {"tier": "UU", "doublesTier": "(DUU)"},
    "lechonk": {"tier": "LC"},
    "oinkologne": {"tier": "ZU", "doublesTier": "(DUU)"},
    "oinkolognef": {"tier": "ZU", "doublesTier": "(DUU)"},
    "tarountula": {"tier": "LC"},
    "spidops": {"tier": "ZU", "doublesTier": "(DUU)"},
    "nymble": {"tier": "LC"},
    "lokix": {"tier": "UU", "doublesTier": "(DUU)"},
    "rellor": {"tier": "LC"},
    "rabsca": {"tier": "ZU", "doublesTier": "(DUU)"},
    "greavard": {"tier": "LC"},
    "houndstone": {"tier": "PU", "doublesTier": "DUU"},
    "flittle": {"tier": "NFE"},
    "espathra": {"tier": "Uber", "doublesTier": "(DUU)"},
    "wiglett": {"tier": "LC"},
    "wugtrio": {"tier": "ZU", "doublesTier": "(DUU)"},
    "dondozo": {"tier": "OU", "doublesTier": "(DUU)"},
    "veluza": {"tier": "PU", "doublesTier": "(DUU)"},
    "finizen": {"tier": "LC"},
    "palafin": {"tier": "Uber", "doublesTier": "DOU"},
    "smoliv": {"tier": "LC"},
    "dolliv": {"tier": "NFE"},
    "arboliva": {"tier": "PU", "doublesTier": "(DUU)"},
    "capsakid": {"tier": "LC"},
    "scovillain": {"tier": "ZU", "doublesTier": "(DUU)"},
    "tadbulb": {"tier": "LC"},
    "bellibolt": {"tier": "RU", "doublesTier": "(DUU)"},
    "varoom": {"tier": "LC"},
    "revavroom": {"tier": "RU", "doublesTier": "(DUU)"},
    "orthworm": {"tier": "PU", "doublesTier": "(DUU)"},
    "tandemaus": {"tier": "LC"},
    "maushold": {"tier": "RU", "doublesTier": "DUU"},
    "cetoddle": {"tier": "LC"},
    "cetitan": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "frigibax": {"tier": "LC"},
    "arctibax": {"tier": "NFE"},
    "baxcalibur": {"tier": "Uber", "doublesTier": "DUU"},
    "tatsugiri": {"tier": "NU", "doublesTier": "DUber"},
    "cyclizar": {"tier": "RU", "doublesTier": "(DUU)"},
    "pawmi": {"tier": "LC"},
    "pawmo": {"tier": "NFE"},
    "pawmot": {"tier": "RU", "doublesTier": "(DUU)"},
    "wattrel": {"tier": "LC"},
    "kilowattrel": {"tier": "RU", "doublesTier": "(DUU)"},
    "bombirdier": {"tier": "PU", "doublesTier": "(DUU)"},
    "squawkabilly": {"tier": "ZU", "doublesTier": "(DUU)"},
    "flamigo": {"tier": "NUBL", "doublesTier": "(DUU)"},
    "klawf": {"tier": "ZU", "doublesTier": "(DUU)"},
    "nacli": {"tier": "LC"},
    "naclstack": {"tier": "PU", "doublesTier": "NFE"},
    "garganacl": {"tier": "OU", "doublesTier": "DUU"},
    "glimmet": {"tier": "LC"},
    "glimmora": {"tier": "OU", "doublesTier": "DOU"},
    "shroodle": {"tier": "LC"},
    "grafaiai": {"tier": "NU", "doublesTier": "(DUU)"},
    "fidough": {"tier": "LC"},
    "dachsbun": {"tier": "ZU", "doublesTier": "(DUU)"},
    "maschiff": {"tier": "LC"},
    "mabosstiff": {"tier": "ZU", "doublesTier": "(DUU)"},
    "bramblin": {"tier": "LC"},
    "brambleghast": {"tier": "RU", "doublesTier": "(DUU)"},
    "gimmighoul": {"tier": "LC"},
    "gimmighoulroaming": {"tier": "LC"},
    "gholdengo": {"tier": "OU", "doublesTier": "DOU"},
    "greattusk": {"tier": "OU", "doublesTier": "DUU"},
    "brutebonnet": {"tier": "NUBL", "doublesTier": "DUU"},
    "sandyshocks": {"tier": "UU", "doublesTier": "(DUU)"},
    "screamtail": {"tier": "NU", "doublesTier": "(DUU)"},
    "fluttermane": {"tier": "Uber", "doublesTier": "DOU"},
    "slitherwing": {"tier": "RU", "doublesTier": "(DUU)"},
    "roaringmoon": {"tier": "Uber", "doublesTier": "DOU"},
    "irontreads": {"tier": "UU", "doublesTier": "(DUU)"},
    "ironmoth": {"tier": "OU", "doublesTier": "DUU"},
    "ironhands": {"tier": "UU", "doublesTier": "DOU"},
    "ironjugulis": {"tier": "RU", "doublesTier": "(DUU)"},
    "ironthorns": {"tier": "RU", "doublesTier": "(DUU)"},
    "ironbundle": {"tier": "Uber", "doublesTier": "DOU"},
    "ironvaliant": {"tier": "OU", "doublesTier": "(DUU)"},
    "tinglu": {"tier": "OU", "doublesTier": "(DUU)"},
    "chienpao": {"tier": "Uber", "doublesTier": "DOU"},
    "wochien": {"tier": "NU", "doublesTier": "(DUU)"},
    "chiyu": {"tier": "Uber", "doublesTier": "DOU"},
    "koraidon": {"tier": "Uber", "doublesTier": "DUber"},
    "miraidon": {"tier": "Uber", "doublesTier": "DUber"},
    "tinkatink": {"tier": "LC"},
    "tinkatuff": {"tier": "ZU", "doublesTier": "NFE"},
    "tinkaton": {"tier": "RU", "doublesTier": "(DUU)"},
    "charcadet": {"tier": "LC"},
    "armarouge": {"tier": "RU", "doublesTier": "DOU"},
    "ceruledge": {"tier": "OU", "doublesTier": "(DUU)"},
    "toedscool": {"tier": "LC"},
    "toedscruel": {"tier": "NU", "doublesTier": "(DUU)"},
    "kingambit": {"tier": "OU", "doublesTier": "DOU"},
    "clodsire": {"tier": "UU", "doublesTier": "(DUU)"},
    "annihilape": {"tier": "Uber", "doublesTier": "DUber"},
    "walkingwake": {"tier": "OU", "doublesTier": "DUU"},
    "ironleaves": {"tier": "UU", "doublesTier": "(DUU)"},
    "poltchageist": {"tier": "LC"},
    "sinistcha": {"tier": "UU", "doublesTier": "DOU"},
    "okidogi": {"tier": "UU", "doublesTier": "(DUU)"},
    "munkidori": {"tier": "RU", "doublesTier": "(DUU)"},
    "fezandipiti": {"tier": "RU", "doublesTier": "(DUU)"},
    "ogerpon": {"tier": "UU", "doublesTier": "(DUU)"},
    "ogerponwellspring": {"tier": "OU", "doublesTier": "DOU"},
    "ogerponhearthflame": {"tier": "Uber", "doublesTier": "DOU"},
    "ogerponcornerstone": {"tier": "OU", "doublesTier": "DUU"},
    "missingno": {"isNonstandard": "Custom", "tier": "Illegal"},
    "syclar": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "syclant": {"isNonstandard": "CAP", "tier": "CAP"},
    "revenankh": {"isNonstandard": "CAP", "tier": "CAP"},
    "embirch": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "flarelm": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "pyroak": {"isNonstandard": "CAP", "tier": "CAP"},
    "breezi": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "fidgit": {"isNonstandard": "CAP", "tier": "CAP"},
    "rebble": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "tactite": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "stratagem": {"isNonstandard": "CAP", "tier": "CAP"},
    "privatyke": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "arghonaut": {"isNonstandard": "CAP", "tier": "CAP"},
    "nohface": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "kitsunoh": {"isNonstandard": "CAP", "tier": "CAP"},
    "monohm": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "duohm": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "cyclohm": {"isNonstandard": "CAP", "tier": "CAP"},
    "dorsoil": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "colossoil": {"isNonstandard": "CAP", "tier": "CAP"},
    "protowatt": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "krilowatt": {"isNonstandard": "CAP", "tier": "CAP"},
    "voodoll": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "voodoom": {"isNonstandard": "CAP", "tier": "CAP"},
    "scratchet": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "tomohawk": {"isNonstandard": "CAP", "tier": "CAP"},
    "necturine": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "necturna": {"isNonstandard": "CAP", "tier": "CAP"},
    "mollux": {"isNonstandard": "CAP", "tier": "CAP"},
    "cupra": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "argalis": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "aurumoth": {"isNonstandard": "CAP", "tier": "CAP"},
    "brattler": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "malaconda": {"isNonstandard": "CAP", "tier": "CAP"},
    "cawdet": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "cawmodore": {"isNonstandard": "CAP", "tier": "CAP"},
    "volkritter": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "volkraken": {"isNonstandard": "CAP", "tier": "CAP"},
    "snugglow": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "plasmanta": {"isNonstandard": "CAP", "tier": "CAP"},
    "floatoy": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "caimanoe": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "naviathan": {"isNonstandard": "CAP", "tier": "CAP"},
    "crucibelle": {"isNonstandard": "CAP", "tier": "CAP"},
    "crucibellemega": {"isNonstandard": "CAP", "tier": "CAP"},
    "pluffle": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "kerfluffle": {"isNonstandard": "CAP", "tier": "CAP"},
    "pajantom": {"isNonstandard": "CAP", "tier": "CAP"},
    "mumbao": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "jumbao": {"isNonstandard": "CAP", "tier": "CAP"},
    "fawnifer": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "electrelk": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "caribolt": {"isNonstandard": "CAP", "tier": "CAP"},
    "smogecko": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "smoguana": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "smokomodo": {"isNonstandard": "CAP", "tier": "CAP"},
    "swirlpool": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "coribalis": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "snaelstrom": {"isNonstandard": "CAP", "tier": "CAP"},
    "justyke": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "equilibra": {"isNonstandard": "CAP", "tier": "CAP"},
    "solotl": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "astrolotl": {"isNonstandard": "CAP", "tier": "CAP"},
    "miasmite": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "miasmaw": {"isNonstandard": "CAP", "tier": "CAP"},
    "chromera": {"isNonstandard": "CAP", "tier": "CAP"},
    "venomicon": {"isNonstandard": "CAP", "tier": "CAP"},
    "venomiconepilogue": {"isNonstandard": "CAP", "tier": "CAP"},
    "saharascal": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "saharaja": {"isNonstandard": "CAP", "tier": "CAP"},
    "ababo": {"isNonstandard": "CAP", "tier": "CAP LC"},
    "scattervein": {"isNonstandard": "CAP", "tier": "CAP NFE"},
    "hemogoblin": {"isNonstandard": "CAP", "tier": "CAP"},
    "cresceidon": {"isNonstandard": "CAP", "tier": "CAP"},
    "pokestarsmeargle": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarufo": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarufo2": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarbrycenman": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarmt": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarmt2": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestartransport": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestargiant": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestargiant2": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarhumanoid": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarmonster": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarf00": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarf002": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarspirit": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarblackdoor": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarwhitedoor": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarblackbelt": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestargiantpropo2": {"isNonstandard": "Custom", "tier": "Illegal"},
    "pokestarufopropu2": {"isNonstandard": "Custom", "tier": "Illegal"}
  }
}