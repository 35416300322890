import { render, Fragment } from 'preact'
import { html } from 'htm/preact'
import { Dex } from '@pkmn/dex'
import { PokemonIcon } from './util'

const pokedex = Dex.forGen(9).data.Species

const pickData = JSON.parse(
  window.decodeURIComponent(window.location.hash.slice(1)),
)
const { picks, options: draftOptions, users, fullPicks, megas } = pickData
const POINTS = [null, 50, 30, 20, 10, 5]
const MEGA_POINTS = [-50, 0, 30, 50]

const Pokemon = ({ name, picked, compact, points }) => {
  console.log(`loading pokemon: ${name}`)
  const [, data] = Object.entries(pokedex).find(
    p => p[1].name === name.replace('-*', '').replace(/'/g, '\u2019'),
  )

  if (compact) {
    return html`
      <div class=${picked ? 'pokemon picked' : 'pokemon'}>
        <div class="typing-icons">
          ${data.types.map(
            type =>
              html`<img
                src="https://cdn.pikalytics.com/images/sstypes/${type.toLowerCase()}.png"
                class="type-icon"
              />`,
          )}
        </div>
        <${PokemonIcon} pokemon=${name} />
        <span class="name">${name}</span>
      </div>
    `
  }
  return html`
    <div class=${picked ? 'pokemon picked' : 'pokemon'}>
      ${points != null
        ? html`<span class="point-cost"
            >(${points > 0
              ? `+${points} `
              : points < 0
              ? `${points}`
              : '\u{b1}0'}${' '}
            points)</span
          >`
        : ''}
      <div class="typing-named">
        ${data.types.map(
          type => html`<span class="type-name-${type.toLowerCase()}"></span>`,
        )}
      </div>
      <${PokemonIcon} pokemon=${name} />
      <span class="name">${name}</span>
      <span class="stats"
        >${data.baseStats.hp}${' '}/${' '}${data.baseStats
          .atk}${' '}/${' '}${data.baseStats.def}${' '}
        /${' '}${data.baseStats.spa}${' '}/${' '}${data.baseStats
          .spd}${' '}/${' '}
        ${data.baseStats.spe}
      </span>
      <ul class="abilities">
        ${Object.values(data.abilities).map(
          ability => html`<li>${ability}</li>`,
        )}
      </ul>
    </div>
  `
}

const Picks = ({ player }) =>
  html`<div class="picks">
    ${picks[player].map(
      pick => html`<${Pokemon} name=${pick} compact=${!fullPicks} />`,
    )}
  </div>`

const Tier = ({ tier }) =>
  draftOptions[tier].length
    ? html` <div
        class=${pickData.tier === tier ? 'pick-section active' : 'pick-section'}
      >
        <h1>
          Tier${' '}
          ${tier}${tier === 0
            ? ''
            : html`${' '}<span class="point-cost"
                  >(${POINTS[tier]} points)</span
                >`}
        </h1>
        <div class="options">
          ${draftOptions[tier]
            .sort()
            .map(
              pokemon =>
                html`<${Pokemon}
                  name=${pokemon}
                  picked=${picks.findIndex(
                    picks =>
                      picks.findIndex(
                        pick => pokemon.split('-')[0] === pick.split('-')[0],
                      ) !== -1,
                  ) !== -1}
                  compact=${pickData.tier !== tier}
                />`,
            )}
        </div>
      </div>`
    : html``

const Megas = () =>
  megas.length
    ? html` <div
        class=${pickData.tier === -1 ? 'pick-section active' : 'pick-section'}
      >
        <h1>Megas</h1>
        <div class="options">
          ${megas.map(
            pokemon =>
              html`<${Pokemon}
                name=${pokemon.name}
                picked=${picks.findIndex(picks =>
                  picks.includes(pokemon.name),
                ) !== -1}
                compact=${pickData.tier !== -1}
                points=${MEGA_POINTS[pokemon.tier]}
              />`,
          )}
        </div>
      </div>`
    : html``

const Users = () => html` <div id="users">
  ${users.map(
    (user, index) => html` <div class="pick-section">
      <h1>${user}</h1>
      <${Picks} player=${index} />
    </div>`,
  )}
</div>`

const Tiers = () => html`<div id="tiers">
  <${Megas} />
  ${draftOptions.map((_options, index) => html`<${Tier} tier=${index} />`)}
</div>`

const App = () => html`<${Users} /> <${Tiers} />`

render(html`<${App} />`, document.querySelector('#app'))
