{
  "2": {
    "berryjuice": {"inherit": true, "isNonstandard": null},
    "blackbelt": {"inherit": true},
    "blackglasses": {"inherit": true},
    "brightpowder": {
      "inherit": true,
      "desc": "An attack against the holder has its accuracy out of 255 lowered by 20."
    },
    "charcoal": {"inherit": true},
    "dragonfang": {"inherit": true, "desc": "No competitive use."},
    "dragonscale": {
      "inherit": true,
      "desc": "Holder's Dragon-type attacks have 1.1x power. Evolves Seadra (trade)."
    },
    "fastball": {"inherit": true, "isNonstandard": null},
    "focusband": {
      "inherit": true,
      "desc": "Holder has a ~11.7% chance to survive an attack that would KO it with 1 HP."
    },
    "hardstone": {"inherit": true},
    "heavyball": {"inherit": true, "isNonstandard": null},
    "kingsrock": {"inherit": true},
    "leftovers": {
      "inherit": true,
      "onResidualOrder": 5,
      "onResidualSubOrder": 1
    },
    "levelball": {"inherit": true, "isNonstandard": null},
    "lightball": {"inherit": true},
    "loveball": {"inherit": true, "isNonstandard": null},
    "luckypunch": {
      "inherit": true,
      "onModifyCritRatioPriority": -1,
      "desc": "If held by a Chansey, its critical hit ratio is always at stage 2. (25% crit rate)"
    },
    "lureball": {"inherit": true, "isNonstandard": null},
    "magnet": {"inherit": true},
    "metalcoat": {"inherit": true},
    "metalpowder": {
      "inherit": true,
      "desc": "If held by a Ditto, its Defense and Sp. Def are 1.5x, even while Transformed."
    },
    "miracleseed": {"inherit": true},
    "moonball": {"inherit": true, "isNonstandard": null},
    "mysticwater": {"inherit": true},
    "nevermeltice": {"inherit": true},
    "poisonbarb": {"inherit": true},
    "sharpbeak": {"inherit": true},
    "silverpowder": {"inherit": true},
    "softsand": {"inherit": true},
    "spelltag": {"inherit": true},
    "sportball": {"inherit": true, "isNonstandard": null},
    "stick": {
      "inherit": true,
      "onModifyCritRatioPriority": -1,
      "desc": "If held by a Farfetch’d, its critical hit ratio is always at stage 2. (25% crit rate)"
    },
    "thickclub": {"inherit": true},
    "twistedspoon": {"inherit": true},
    "berserkgene": {"inherit": true, "isNonstandard": null},
    "berry": {"inherit": true, "isNonstandard": null},
    "bitterberry": {"inherit": true, "isNonstandard": null},
    "burntberry": {"inherit": true, "isNonstandard": null},
    "goldberry": {"inherit": true, "isNonstandard": null},
    "iceberry": {"inherit": true, "isNonstandard": null},
    "mintberry": {"inherit": true, "isNonstandard": null},
    "miracleberry": {"inherit": true, "isNonstandard": null},
    "mysteryberry": {"inherit": true, "isNonstandard": null},
    "pinkbow": {"inherit": true, "isNonstandard": null},
    "polkadotbow": {"inherit": true, "isNonstandard": null},
    "przcureberry": {"inherit": true, "isNonstandard": null},
    "psncureberry": {"inherit": true, "isNonstandard": null},
    "quickclaw": {
      "inherit": true,
      "desc": "Each turn, holder has a ~23.4% chance to move first in its priority bracket."
    }
  },
  "3": {
    "aguavberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "apicotberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "berryjuice": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4,
      "isNonstandard": "Unobtainable"
    },
    "blackbelt": {
      "inherit": true,
      "onModifyAtkPriority": 1,
      "desc": "Holder's Fighting-type attacks have 1.1x power."
    },
    "blackglasses": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "desc": "Holder's Dark-type attacks have 1.1x power."
    },
    "charcoal": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "desc": "Holder's Fire-type attacks have 1.1x power."
    },
    "dragonfang": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "desc": "Holder's Dragon-type attacks have 1.1x power."
    },
    "enigmaberry": {
      "name": "Enigma Berry",
      "isBerry": true,
      "num": 208,
      "gen": 3,
      "isNonstandard": "Unobtainable",
      "desc": "No competitive use."
    },
    "fastball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "figyberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "ganlonberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "hardstone": {
      "inherit": true,
      "onModifyAtkPriority": 1,
      "desc": "Holder's Rock-type attacks have 1.1x power."
    },
    "heavyball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "iapapaberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "kingsrock": {"inherit": true},
    "lansatberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "laxincense": {
      "inherit": true,
      "desc": "The accuracy of attacks against the holder is 0.95x."
    },
    "levelball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "liechiberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "lightball": {
      "inherit": true,
      "desc": "If held by a Pikachu, its Special Attack is doubled."
    },
    "loveball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "lureball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "magnet": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "desc": "Holder's Electric-type attacks have 1.1x power."
    },
    "magoberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "metalcoat": {
      "inherit": true,
      "onModifyAtkPriority": 1,
      "desc": "Holder's Steel-type attacks have 1.1x power. Evolves Onix into Steelix and Scyther into Scizor when traded.",
      "shortDesc": "Holder's Steel-type attacks have 1.1x power."
    },
    "miracleseed": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "desc": "Holder's Grass-type attacks have 1.1x power."
    },
    "moonball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "mysticwater": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "desc": "Holder's Water-type attacks have 1.1x power."
    },
    "nevermeltice": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "desc": "Holder's Ice-type attacks have 1.1x power."
    },
    "oranberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "petayaberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "poisonbarb": {
      "inherit": true,
      "onModifyAtkPriority": 1,
      "desc": "Holder's Poison-type attacks have 1.1x power."
    },
    "quickclaw": {"inherit": true},
    "salacberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "seaincense": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "desc": "Holder's Water-type attacks have 1.05x power."
    },
    "sharpbeak": {
      "inherit": true,
      "onModifyAtkPriority": 1,
      "desc": "Holder's Flying-type attacks have 1.1x power."
    },
    "silkscarf": {
      "inherit": true,
      "onModifyAtkPriority": 1,
      "desc": "Holder's Normal-type attacks have 1.1x power."
    },
    "silverpowder": {
      "inherit": true,
      "onModifyAtkPriority": 1,
      "desc": "Holder's Bug-type attacks have 1.1x power."
    },
    "sitrusberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4,
      "desc": "Restores 30 HP when at 1/2 max HP or less. Single use."
    },
    "softsand": {
      "inherit": true,
      "onModifyAtkPriority": 1,
      "desc": "Holder's Ground-type attacks have 1.1x power."
    },
    "spelltag": {
      "inherit": true,
      "onModifyAtkPriority": 1,
      "desc": "Holder's Ghost-type attacks have 1.1x power."
    },
    "sportball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "starfberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "twistedspoon": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "desc": "Holder's Psychic-type attacks have 1.1x power."
    },
    "wikiberry": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    }
  },
  "4": {
    "adamantorb": {"inherit": true},
    "bigroot": {"inherit": true},
    "blacksludge": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "brightpowder": {"inherit": true, "onModifyAccuracyPriority": 5},
    "choiceband": {"inherit": true},
    "choicescarf": {"inherit": true},
    "choicespecs": {"inherit": true},
    "chopleberry": {"inherit": true},
    "custapberry": {"inherit": true},
    "deepseascale": {"inherit": true},
    "deepseatooth": {"inherit": true},
    "fastball": {"inherit": true, "isNonstandard": null},
    "flameorb": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 20
    },
    "focussash": {
      "inherit": true,
      "condition": {"duration": 1},
      "desc": "If holder's HP is full, survives all hits of one attack with at least 1 HP. Single use."
    },
    "griseousorb": {
      "inherit": true,
      "desc": "Can only be held by Giratina. Its Ghost- & Dragon-type attacks have 1.2x power."
    },
    "heavyball": {"inherit": true, "isNonstandard": null},
    "ironball": {
      "inherit": true,
      "desc": "Holder's Speed is halved and it becomes grounded."
    },
    "kingsrock": {"inherit": true},
    "laxincense": {"inherit": true, "onModifyAccuracyPriority": 5},
    "leftovers": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 4
    },
    "levelball": {"inherit": true, "isNonstandard": null},
    "lifeorb": {"inherit": true, "condition": {"duration": 1}},
    "lightball": {
      "inherit": true,
      "desc": "If held by a Pikachu, its attacks have their power doubled."
    },
    "loveball": {"inherit": true, "isNonstandard": null},
    "luckypunch": {"inherit": true},
    "lureball": {"inherit": true, "isNonstandard": null},
    "lustrousorb": {"inherit": true},
    "mentalherb": {
      "inherit": true,
      "fling": {"basePower": 10},
      "desc": "Holder is cured if it is infatuated. Single use."
    },
    "metronome": {
      "inherit": true,
      "condition": {"onTryMovePriority": -2},
      "desc": "Damage of moves used on consecutive turns is increased. Max 2x after 10 turns."
    },
    "micleberry": {
      "inherit": true,
      "condition": {"duration": 2, "onSourceModifyAccuracyPriority": 3}
    },
    "moonball": {"inherit": true, "isNonstandard": null},
    "razorfang": {"inherit": true},
    "sportball": {"inherit": true, "isNonstandard": null},
    "stick": {"inherit": true},
    "stickybarb": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 20
    },
    "thickclub": {"inherit": true},
    "toxicorb": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 20
    },
    "widelens": {"inherit": true, "onSourceModifyAccuracyPriority": 4},
    "zoomlens": {"inherit": true, "onSourceModifyAccuracyPriority": 4}
  },
  "5": {
    "aguavberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Dragon"}
    },
    "apicotberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Ground"}
    },
    "aspearberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Ice"}
    },
    "babiriberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Steel"}
    },
    "belueberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Electric"}
    },
    "blukberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Fire"}
    },
    "buggem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Bug-type attack will have 1.5x power. Single use."
    },
    "chartiberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Rock"}
    },
    "cheriberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Fire"}
    },
    "chestoberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Water"}
    },
    "chilanberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Normal"}
    },
    "chopleberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Fighting"}
    },
    "cobaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Flying"}
    },
    "colburberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Dark"}
    },
    "cornnberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Bug"}
    },
    "custapberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Ghost"}
    },
    "darkgem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Dark-type attack will have 1.5x power. Single use."
    },
    "dragongem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Dragon-type attack will have 1.5x power. Single use."
    },
    "durinberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Water"}
    },
    "electricgem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Electric-type attack will have 1.5x power. Single use."
    },
    "enigmaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Bug"}
    },
    "fightinggem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Fighting-type attack will have 1.5x power. Single use."
    },
    "figyberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Bug"}
    },
    "firegem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Fire-type attack will have 1.5x power. Single use."
    },
    "flyinggem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Flying-type attack will have 1.5x power. Single use."
    },
    "ganlonberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Ice"}
    },
    "ghostgem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Ghost-type attack will have 1.5x power. Single use."
    },
    "grassgem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Grass-type attack will have 1.5x power. Single use."
    },
    "grepaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Flying"}
    },
    "groundgem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Ground-type attack will have 1.5x power. Single use."
    },
    "habanberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Dragon"}
    },
    "hondewberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Ground"}
    },
    "iapapaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Dark"}
    },
    "icegem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Ice-type attack will have 1.5x power. Single use."
    },
    "jabocaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Dragon"}
    },
    "kasibberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Ghost"}
    },
    "kebiaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Poison"}
    },
    "kelpsyberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Fighting"}
    },
    "lansatberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Flying"}
    },
    "leppaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Fighting"}
    },
    "liechiberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Grass"}
    },
    "lumberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Flying"}
    },
    "mail": {"inherit": true, "isNonstandard": null},
    "magoberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Ghost"}
    },
    "magostberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Rock"}
    },
    "micleberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Rock"}
    },
    "nanabberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Water"}
    },
    "nomelberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Dragon"}
    },
    "occaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Fire"}
    },
    "oranberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Poison"}
    },
    "pamtreberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Steel"}
    },
    "passhoberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Water"}
    },
    "payapaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Psychic"}
    },
    "pechaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Electric"}
    },
    "persimberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Ground"}
    },
    "petayaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Poison"}
    },
    "pinapberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Grass"}
    },
    "poisongem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Poison-type attack will have 1.5x power. Single use."
    },
    "pomegberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Ice"}
    },
    "psychicgem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Psychic-type attack will have 1.5x power. Single use."
    },
    "qualotberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Poison"}
    },
    "rabutaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Ghost"}
    },
    "rawstberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Grass"}
    },
    "razzberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Steel"}
    },
    "rindoberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Grass"}
    },
    "rockgem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Rock-type attack will have 1.5x power. Single use."
    },
    "rowapberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Dark"}
    },
    "salacberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Fighting"}
    },
    "shucaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Ground"}
    },
    "sitrusberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Psychic"}
    },
    "spelonberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Dark"}
    },
    "starfberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Psychic"}
    },
    "steelgem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Steel-type attack will have 1.5x power. Single use."
    },
    "tamatoberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Psychic"}
    },
    "tangaberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Bug"}
    },
    "wacanberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Electric"}
    },
    "watergem": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Holder's first successful Water-type attack will have 1.5x power. Single use."
    },
    "watmelberry": {
      "inherit": true,
      "naturalGift": {"basePower": 80, "type": "Fire"}
    },
    "wepearberry": {
      "inherit": true,
      "naturalGift": {"basePower": 70, "type": "Electric"}
    },
    "wikiberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Rock"}
    },
    "yacheberry": {
      "inherit": true,
      "naturalGift": {"basePower": 60, "type": "Ice"}
    },
    "normalgem": {
      "inherit": true,
      "desc": "Holder's first successful Normal-type attack will have 1.5x power. Single use."
    }
  },
  "6": {
    "aguavberry": {
      "inherit": true,
      "desc": "Restores 1/8 max HP at 1/2 max HP or less; confuses if -SpD Nature. Single use."
    },
    "belueberry": {"inherit": true, "isNonstandard": null},
    "cornnberry": {"inherit": true, "isNonstandard": null},
    "durinberry": {"inherit": true, "isNonstandard": null},
    "fastball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "figyberry": {
      "inherit": true,
      "desc": "Restores 1/8 max HP at 1/2 max HP or less; confuses if -Atk Nature. Single use."
    },
    "heavyball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "iapapaberry": {
      "inherit": true,
      "desc": "Restores 1/8 max HP at 1/2 max HP or less; confuses if -Def Nature. Single use."
    },
    "jabocaberry": {"inherit": true},
    "levelball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "lifeorb": {"inherit": true},
    "loveball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "lureball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "machobrace": {"inherit": true, "isNonstandard": null},
    "magoberry": {
      "inherit": true,
      "desc": "Restores 1/8 max HP at 1/2 max HP or less; confuses if -Spe Nature. Single use."
    },
    "magostberry": {"inherit": true, "isNonstandard": null},
    "moonball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "nanabberry": {"inherit": true, "isNonstandard": null},
    "nomelberry": {"inherit": true, "isNonstandard": null},
    "oldamber": {"inherit": true, "isNonstandard": null},
    "pamtreberry": {"inherit": true, "isNonstandard": null},
    "rabutaberry": {"inherit": true, "isNonstandard": null},
    "razzberry": {"inherit": true, "isNonstandard": null},
    "rockyhelmet": {"inherit": true},
    "rowapberry": {"inherit": true},
    "spelonberry": {"inherit": true, "isNonstandard": null},
    "souldew": {
      "inherit": true,
      "onModifySpAPriority": 1,
      "onModifySpDPriority": 2,
      "desc": "If held by a Latias or a Latios, its Sp. Atk and Sp. Def are 1.5x."
    },
    "watmelberry": {"inherit": true, "isNonstandard": null},
    "wepearberry": {"inherit": true, "isNonstandard": null},
    "wikiberry": {
      "inherit": true,
      "desc": "Restores 1/8 max HP at 1/2 max HP or less; confuses if -SpA Nature. Single use."
    },
    "bigroot": {
      "inherit": true,
      "desc": "Holder gains 1.3x HP from draining moves, Aqua Ring, Ingrain, and Leech Seed."
    },
    "lightclay": {
      "inherit": true,
      "desc": "Holder's use of Light Screen or Reflect lasts 8 turns instead of 5."
    }
  },
  "7": {
    "abomasite": {"inherit": true, "isNonstandard": null},
    "absolite": {"inherit": true, "isNonstandard": null},
    "aerodactylite": {"inherit": true, "isNonstandard": null},
    "aggronite": {"inherit": true, "isNonstandard": null},
    "aguavberry": {
      "inherit": true,
      "desc": "Restores 1/2 max HP at 1/4 max HP or less; confuses if -SpD Nature. Single use."
    },
    "alakazite": {"inherit": true, "isNonstandard": null},
    "altarianite": {"inherit": true, "isNonstandard": null},
    "aloraichiumz": {"inherit": true, "isNonstandard": null},
    "ampharosite": {"inherit": true, "isNonstandard": null},
    "armorfossil": {"inherit": true, "isNonstandard": null},
    "audinite": {"inherit": true, "isNonstandard": null},
    "banettite": {"inherit": true, "isNonstandard": null},
    "beedrillite": {"inherit": true, "isNonstandard": null},
    "belueberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "bignugget": {"inherit": true, "fling": {"basePower": 30}},
    "blastoisinite": {"inherit": true, "isNonstandard": null},
    "blazikenite": {"inherit": true, "isNonstandard": null},
    "blueorb": {"inherit": true, "isNonstandard": null},
    "blukberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "buggem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "buginiumz": {"inherit": true, "isNonstandard": null},
    "cameruptite": {"inherit": true, "isNonstandard": null},
    "charizarditex": {"inherit": true, "isNonstandard": null},
    "charizarditey": {"inherit": true, "isNonstandard": null},
    "clawfossil": {"inherit": true, "isNonstandard": null},
    "cornnberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "coverfossil": {"inherit": true, "isNonstandard": null},
    "darkgem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "darkiniumz": {"inherit": true, "isNonstandard": null},
    "decidiumz": {"inherit": true, "isNonstandard": null},
    "diancite": {"inherit": true, "isNonstandard": null},
    "domefossil": {"inherit": true, "isNonstandard": null},
    "dracoplate": {"inherit": true, "isNonstandard": null},
    "dragongem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "dragoniumz": {"inherit": true, "isNonstandard": null},
    "dreadplate": {"inherit": true, "isNonstandard": null},
    "dreamball": {
      "inherit": true,
      "isNonstandard": "Unobtainable",
      "desc": "A special Poke Ball that appears out of nowhere in a bag at the Entree Forest."
    },
    "durinberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "earthplate": {"inherit": true, "isNonstandard": null},
    "eeviumz": {"inherit": true, "isNonstandard": null},
    "electricgem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "electriumz": {"inherit": true, "isNonstandard": null},
    "fairiumz": {"inherit": true, "isNonstandard": null},
    "fairygem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "fightinggem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "fightiniumz": {"inherit": true, "isNonstandard": null},
    "figyberry": {
      "inherit": true,
      "desc": "Restores 1/2 max HP at 1/4 max HP or less; confuses if -Atk Nature. Single use."
    },
    "firegem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "firiumz": {"inherit": true, "isNonstandard": null},
    "fistplate": {"inherit": true, "isNonstandard": null},
    "flameplate": {"inherit": true, "isNonstandard": null},
    "flyinggem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "flyiniumz": {"inherit": true, "isNonstandard": null},
    "galladite": {"inherit": true, "isNonstandard": null},
    "garchompite": {"inherit": true, "isNonstandard": null},
    "gardevoirite": {"inherit": true, "isNonstandard": null},
    "gengarite": {"inherit": true, "isNonstandard": null},
    "ghostgem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "ghostiumz": {"inherit": true, "isNonstandard": null},
    "glalitite": {"inherit": true, "isNonstandard": null},
    "grassgem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "grassiumz": {"inherit": true, "isNonstandard": null},
    "groundgem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "groundiumz": {"inherit": true, "isNonstandard": null},
    "gyaradosite": {"inherit": true, "isNonstandard": null},
    "helixfossil": {"inherit": true, "isNonstandard": null},
    "heracronite": {"inherit": true, "isNonstandard": null},
    "houndoominite": {"inherit": true, "isNonstandard": null},
    "iapapaberry": {
      "inherit": true,
      "desc": "Restores 1/2 max HP at 1/4 max HP or less; confuses if -Def Nature. Single use."
    },
    "icegem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "icicleplate": {"inherit": true, "isNonstandard": null},
    "iciumz": {"inherit": true, "isNonstandard": null},
    "inciniumz": {"inherit": true, "isNonstandard": null},
    "insectplate": {"inherit": true, "isNonstandard": null},
    "ironplate": {"inherit": true, "isNonstandard": null},
    "jawfossil": {"inherit": true, "isNonstandard": null},
    "kangaskhanite": {"inherit": true, "isNonstandard": null},
    "kommoniumz": {"inherit": true, "isNonstandard": null},
    "latiasite": {"inherit": true, "isNonstandard": null},
    "latiosite": {"inherit": true, "isNonstandard": null},
    "lopunnite": {"inherit": true, "isNonstandard": null},
    "lucarionite": {"inherit": true, "isNonstandard": null},
    "luckypunch": {"inherit": true, "isNonstandard": null},
    "lunaliumz": {"inherit": true, "isNonstandard": null},
    "lycaniumz": {"inherit": true, "isNonstandard": null},
    "machobrace": {"inherit": true, "isNonstandard": "Unobtainable"},
    "magoberry": {
      "inherit": true,
      "desc": "Restores 1/2 max HP at 1/4 max HP or less; confuses if -Spe Nature. Single use."
    },
    "magostberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "mail": {"inherit": true, "isNonstandard": "Unobtainable"},
    "manectite": {"inherit": true, "isNonstandard": null},
    "marshadiumz": {"inherit": true, "isNonstandard": null},
    "mawilite": {"inherit": true, "isNonstandard": null},
    "meadowplate": {"inherit": true, "isNonstandard": null},
    "medichamite": {"inherit": true, "isNonstandard": null},
    "metagrossite": {"inherit": true, "isNonstandard": null},
    "mewniumz": {"inherit": true, "isNonstandard": null},
    "mewtwonitex": {"inherit": true, "isNonstandard": null},
    "mewtwonitey": {"inherit": true, "isNonstandard": null},
    "mimikiumz": {"inherit": true, "isNonstandard": null},
    "mindplate": {"inherit": true, "isNonstandard": null},
    "nanabberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "nomelberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "normaliumz": {"inherit": true, "isNonstandard": null},
    "pamtreberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "pidgeotite": {"inherit": true, "isNonstandard": null},
    "pikaniumz": {"inherit": true, "isNonstandard": null},
    "pikashuniumz": {"inherit": true, "isNonstandard": null},
    "pinapberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "pinsirite": {"inherit": true, "isNonstandard": null},
    "plumefossil": {"inherit": true, "isNonstandard": null},
    "poisongem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "poisoniumz": {"inherit": true, "isNonstandard": null},
    "primariumz": {"inherit": true, "isNonstandard": null},
    "psychicgem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "psychiumz": {"inherit": true, "isNonstandard": null},
    "rabutaberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "razorfang": {"inherit": true, "isNonstandard": null},
    "razzberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "redorb": {"inherit": true, "isNonstandard": null},
    "rockgem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "rockiumz": {"inherit": true, "isNonstandard": null},
    "rootfossil": {"inherit": true, "isNonstandard": null},
    "sablenite": {"inherit": true, "isNonstandard": null},
    "safariball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "sailfossil": {"inherit": true, "isNonstandard": null},
    "salamencite": {"inherit": true, "isNonstandard": null},
    "sceptilite": {"inherit": true, "isNonstandard": null},
    "scizorite": {"inherit": true, "isNonstandard": null},
    "sharpedonite": {"inherit": true, "isNonstandard": null},
    "skullfossil": {"inherit": true, "isNonstandard": null},
    "skyplate": {"inherit": true, "isNonstandard": null},
    "slowbronite": {"inherit": true, "isNonstandard": null},
    "snorliumz": {"inherit": true, "isNonstandard": null},
    "solganiumz": {"inherit": true, "isNonstandard": null},
    "spelonberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "splashplate": {"inherit": true, "isNonstandard": null},
    "spookyplate": {"inherit": true, "isNonstandard": null},
    "sportball": {"inherit": true, "isNonstandard": "Unobtainable"},
    "steelgem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "steeliumz": {"inherit": true, "isNonstandard": null},
    "steelixite": {"inherit": true, "isNonstandard": null},
    "stick": {"inherit": true, "isNonstandard": null},
    "stoneplate": {"inherit": true, "isNonstandard": null},
    "swampertite": {"inherit": true, "isNonstandard": null},
    "tapuniumz": {"inherit": true, "isNonstandard": null},
    "toxicplate": {"inherit": true, "isNonstandard": null},
    "tyranitarite": {"inherit": true, "isNonstandard": null},
    "ultranecroziumz": {"inherit": true, "isNonstandard": null},
    "venusaurite": {"inherit": true, "isNonstandard": null},
    "watergem": {"inherit": true, "isNonstandard": "Unobtainable"},
    "wateriumz": {"inherit": true, "isNonstandard": null},
    "watmelberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "wepearberry": {"inherit": true, "isNonstandard": "Unobtainable"},
    "wikiberry": {
      "inherit": true,
      "isNonstandard": null,
      "desc": "Restores 1/2 max HP at 1/4 max HP or less; confuses if -SpA Nature. Single use."
    },
    "zapplate": {"inherit": true, "isNonstandard": null},
    "icestone": {
      "inherit": true,
      "desc": "Evolves Alolan Sandshrew into Alolan Sandslash and Alolan Vulpix into Alolan Ninetales when used."
    },
    "leafstone": {
      "inherit": true,
      "desc": "Evolves Gloom into Vileplume, Weepinbell into Victreebel, Exeggcute into Exeggutor or Alolan Exeggutor, Nuzleaf into Shiftry, and Pansage into Simisage when used."
    },
    "thunderstone": {
      "inherit": true,
      "desc": "Evolves Pikachu into Raichu or Alolan Raichu, Eevee into Jolteon, and Eelektrik into Eelektross when used."
    }
  },
  "8": {
    "adamantcrystal": {"inherit": true, "isNonstandard": "Future"},
    "berryjuice": {"inherit": true, "isNonstandard": null},
    "berrysweet": {"inherit": true, "isNonstandard": null},
    "bugmemory": {"inherit": true, "isNonstandard": null},
    "burndrive": {"inherit": true, "isNonstandard": null},
    "chilldrive": {"inherit": true, "isNonstandard": null},
    "cloversweet": {"inherit": true, "isNonstandard": null},
    "custapberry": {"inherit": true, "isNonstandard": null},
    "darkmemory": {"inherit": true, "isNonstandard": null},
    "deepseascale": {"inherit": true, "isNonstandard": null},
    "deepseatooth": {"inherit": true, "isNonstandard": null},
    "dousedrive": {"inherit": true, "isNonstandard": null},
    "dracoplate": {"inherit": true, "isNonstandard": "Past"},
    "dragonmemory": {"inherit": true, "isNonstandard": null},
    "dragonscale": {"inherit": true, "isNonstandard": null},
    "dreadplate": {"inherit": true, "isNonstandard": "Past"},
    "dubiousdisc": {"inherit": true, "isNonstandard": null},
    "earthplate": {"inherit": true, "isNonstandard": "Past"},
    "electirizer": {"inherit": true, "isNonstandard": null},
    "electricmemory": {"inherit": true, "isNonstandard": null},
    "enigmaberry": {"inherit": true, "isNonstandard": null},
    "fairymemory": {"inherit": true, "isNonstandard": null},
    "fightingmemory": {"inherit": true, "isNonstandard": null},
    "firememory": {"inherit": true, "isNonstandard": null},
    "fistplate": {"inherit": true, "isNonstandard": "Past"},
    "flameplate": {"inherit": true, "isNonstandard": "Past"},
    "flowersweet": {"inherit": true, "isNonstandard": null},
    "flyingmemory": {"inherit": true, "isNonstandard": null},
    "fossilizedbird": {"inherit": true, "isNonstandard": null},
    "fossilizeddino": {"inherit": true, "isNonstandard": null},
    "fossilizeddrake": {"inherit": true, "isNonstandard": null},
    "fossilizedfish": {"inherit": true, "isNonstandard": null},
    "fullincense": {"inherit": true, "isNonstandard": null},
    "galaricacuff": {"inherit": true, "isNonstandard": null},
    "galaricawreath": {"inherit": true, "isNonstandard": null},
    "ghostmemory": {"inherit": true, "isNonstandard": null},
    "grassmemory": {"inherit": true, "isNonstandard": null},
    "griseouscore": {"inherit": true, "isNonstandard": "Future"},
    "griseousorb": {
      "inherit": true,
      "forcedForme": "Giratina-Origin",
      "itemUser": ["Giratina-Origin"]
    },
    "groundmemory": {"inherit": true, "isNonstandard": null},
    "icememory": {"inherit": true, "isNonstandard": null},
    "icicleplate": {"inherit": true, "isNonstandard": "Past"},
    "insectplate": {"inherit": true, "isNonstandard": "Past"},
    "ironplate": {"inherit": true, "isNonstandard": "Past"},
    "jabocaberry": {"inherit": true, "isNonstandard": null},
    "keeberry": {"inherit": true, "isNonstandard": null},
    "laxincense": {"inherit": true, "isNonstandard": null},
    "leek": {"inherit": true, "isNonstandard": null},
    "lovesweet": {"inherit": true, "isNonstandard": null},
    "lustrousglobe": {"inherit": true, "isNonstandard": "Future"},
    "machobrace": {"inherit": true, "isNonstandard": null},
    "magmarizer": {"inherit": true, "isNonstandard": null},
    "marangaberry": {"inherit": true, "isNonstandard": null},
    "meadowplate": {"inherit": true, "isNonstandard": "Past"},
    "metalpowder": {"inherit": true, "isNonstandard": null},
    "micleberry": {"inherit": true, "isNonstandard": null},
    "mindplate": {"inherit": true, "isNonstandard": "Past"},
    "oddincense": {"inherit": true, "isNonstandard": null},
    "poisonmemory": {"inherit": true, "isNonstandard": null},
    "protector": {"inherit": true, "isNonstandard": null},
    "psychicmemory": {"inherit": true, "isNonstandard": null},
    "quickpowder": {"inherit": true, "isNonstandard": null},
    "razorfang": {"inherit": true, "isNonstandard": "Past"},
    "ribbonsweet": {"inherit": true, "isNonstandard": null},
    "rockincense": {"inherit": true, "isNonstandard": null},
    "rockmemory": {"inherit": true, "isNonstandard": null},
    "roseincense": {"inherit": true, "isNonstandard": null},
    "rowapberry": {"inherit": true, "isNonstandard": null},
    "sachet": {"inherit": true, "isNonstandard": null},
    "safariball": {"inherit": true, "isNonstandard": null},
    "seaincense": {"inherit": true, "isNonstandard": null},
    "shockdrive": {"inherit": true, "isNonstandard": null},
    "skyplate": {"inherit": true, "isNonstandard": "Past"},
    "souldew": {"inherit": true, "isNonstandard": null},
    "splashplate": {"inherit": true, "isNonstandard": "Past"},
    "spookyplate": {"inherit": true, "isNonstandard": "Past"},
    "sportball": {"inherit": true, "isNonstandard": null},
    "starsweet": {"inherit": true, "isNonstandard": null},
    "stoneplate": {"inherit": true, "isNonstandard": "Past"},
    "strawberrysweet": {"inherit": true, "isNonstandard": null},
    "steelmemory": {"inherit": true, "isNonstandard": null},
    "strangeball": {"inherit": true, "isNonstandard": "Future"},
    "thickclub": {"inherit": true, "isNonstandard": null},
    "toxicplate": {"inherit": true, "isNonstandard": "Past"},
    "tr00": {"inherit": true, "isNonstandard": null},
    "tr01": {"inherit": true, "isNonstandard": null},
    "tr02": {"inherit": true, "isNonstandard": null},
    "tr03": {"inherit": true, "isNonstandard": null},
    "tr04": {"inherit": true, "isNonstandard": null},
    "tr05": {"inherit": true, "isNonstandard": null},
    "tr06": {"inherit": true, "isNonstandard": null},
    "tr07": {"inherit": true, "isNonstandard": null},
    "tr08": {"inherit": true, "isNonstandard": null},
    "tr09": {"inherit": true, "isNonstandard": null},
    "tr10": {"inherit": true, "isNonstandard": null},
    "tr11": {"inherit": true, "isNonstandard": null},
    "tr12": {"inherit": true, "isNonstandard": null},
    "tr13": {"inherit": true, "isNonstandard": null},
    "tr14": {"inherit": true, "isNonstandard": null},
    "tr15": {"inherit": true, "isNonstandard": null},
    "tr16": {"inherit": true, "isNonstandard": null},
    "tr17": {"inherit": true, "isNonstandard": null},
    "tr18": {"inherit": true, "isNonstandard": null},
    "tr19": {"inherit": true, "isNonstandard": null},
    "tr20": {"inherit": true, "isNonstandard": null},
    "tr21": {"inherit": true, "isNonstandard": null},
    "tr22": {"inherit": true, "isNonstandard": null},
    "tr23": {"inherit": true, "isNonstandard": null},
    "tr24": {"inherit": true, "isNonstandard": null},
    "tr25": {"inherit": true, "isNonstandard": null},
    "tr26": {"inherit": true, "isNonstandard": null},
    "tr27": {"inherit": true, "isNonstandard": null},
    "tr28": {"inherit": true, "isNonstandard": null},
    "tr29": {"inherit": true, "isNonstandard": null},
    "tr30": {"inherit": true, "isNonstandard": null},
    "tr31": {"inherit": true, "isNonstandard": null},
    "tr32": {"inherit": true, "isNonstandard": null},
    "tr33": {"inherit": true, "isNonstandard": null},
    "tr34": {"inherit": true, "isNonstandard": null},
    "tr35": {"inherit": true, "isNonstandard": null},
    "tr36": {"inherit": true, "isNonstandard": null},
    "tr37": {"inherit": true, "isNonstandard": null},
    "tr38": {"inherit": true, "isNonstandard": null},
    "tr39": {"inherit": true, "isNonstandard": null},
    "tr40": {"inherit": true, "isNonstandard": null},
    "tr41": {"inherit": true, "isNonstandard": null},
    "tr42": {"inherit": true, "isNonstandard": null},
    "tr43": {"inherit": true, "isNonstandard": null},
    "tr44": {"inherit": true, "isNonstandard": null},
    "tr45": {"inherit": true, "isNonstandard": null},
    "tr46": {"inherit": true, "isNonstandard": null},
    "tr47": {"inherit": true, "isNonstandard": null},
    "tr48": {"inherit": true, "isNonstandard": null},
    "tr49": {"inherit": true, "isNonstandard": null},
    "tr50": {"inherit": true, "isNonstandard": null},
    "tr51": {"inherit": true, "isNonstandard": null},
    "tr52": {"inherit": true, "isNonstandard": null},
    "tr53": {"inherit": true, "isNonstandard": null},
    "tr54": {"inherit": true, "isNonstandard": null},
    "tr55": {"inherit": true, "isNonstandard": null},
    "tr56": {"inherit": true, "isNonstandard": null},
    "tr57": {"inherit": true, "isNonstandard": null},
    "tr58": {"inherit": true, "isNonstandard": null},
    "tr59": {"inherit": true, "isNonstandard": null},
    "tr60": {"inherit": true, "isNonstandard": null},
    "tr61": {"inherit": true, "isNonstandard": null},
    "tr62": {"inherit": true, "isNonstandard": null},
    "tr63": {"inherit": true, "isNonstandard": null},
    "tr64": {"inherit": true, "isNonstandard": null},
    "tr65": {"inherit": true, "isNonstandard": null},
    "tr66": {"inherit": true, "isNonstandard": null},
    "tr67": {"inherit": true, "isNonstandard": null},
    "tr68": {"inherit": true, "isNonstandard": null},
    "tr69": {"inherit": true, "isNonstandard": null},
    "tr70": {"inherit": true, "isNonstandard": null},
    "tr71": {"inherit": true, "isNonstandard": null},
    "tr72": {"inherit": true, "isNonstandard": null},
    "tr73": {"inherit": true, "isNonstandard": null},
    "tr74": {"inherit": true, "isNonstandard": null},
    "tr75": {"inherit": true, "isNonstandard": null},
    "tr76": {"inherit": true, "isNonstandard": null},
    "tr77": {"inherit": true, "isNonstandard": null},
    "tr78": {"inherit": true, "isNonstandard": null},
    "tr79": {"inherit": true, "isNonstandard": null},
    "tr80": {"inherit": true, "isNonstandard": null},
    "tr81": {"inherit": true, "isNonstandard": null},
    "tr82": {"inherit": true, "isNonstandard": null},
    "tr83": {"inherit": true, "isNonstandard": null},
    "tr84": {"inherit": true, "isNonstandard": null},
    "tr85": {"inherit": true, "isNonstandard": null},
    "tr86": {"inherit": true, "isNonstandard": null},
    "tr87": {"inherit": true, "isNonstandard": null},
    "tr88": {"inherit": true, "isNonstandard": null},
    "tr89": {"inherit": true, "isNonstandard": null},
    "tr90": {"inherit": true, "isNonstandard": null},
    "tr91": {"inherit": true, "isNonstandard": null},
    "tr92": {"inherit": true, "isNonstandard": null},
    "tr93": {"inherit": true, "isNonstandard": null},
    "tr94": {"inherit": true, "isNonstandard": null},
    "tr95": {"inherit": true, "isNonstandard": null},
    "tr96": {"inherit": true, "isNonstandard": null},
    "tr97": {"inherit": true, "isNonstandard": null},
    "tr98": {"inherit": true, "isNonstandard": null},
    "tr99": {"inherit": true, "isNonstandard": null},
    "upgrade": {"inherit": true, "isNonstandard": null},
    "watermemory": {"inherit": true, "isNonstandard": null},
    "waveincense": {"inherit": true, "isNonstandard": null},
    "whippeddream": {"inherit": true, "isNonstandard": null},
    "zapplate": {"inherit": true, "isNonstandard": "Past"}
  },
  "9": {
    "abilityshield": {
      "name": "Ability Shield",
      "fling": {"basePower": 30},
      "ignoreKlutz": true,
      "num": 1881,
      "gen": 9,
      "desc": "Holder's Ability cannot be changed by any effect."
    },
    "abomasite": {
      "name": "Abomasite",
      "megaStone": "Abomasnow-Mega",
      "megaEvolves": "Abomasnow",
      "itemUser": ["Abomasnow"],
      "num": 674,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by an Abomasnow, this item allows it to Mega Evolve in battle."
    },
    "absolite": {
      "name": "Absolite",
      "megaStone": "Absol-Mega",
      "megaEvolves": "Absol",
      "itemUser": ["Absol"],
      "num": 677,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by an Absol, this item allows it to Mega Evolve in battle."
    },
    "absorbbulb": {
      "name": "Absorb Bulb",
      "fling": {"basePower": 30},
      "boosts": {"spa": 1},
      "num": 545,
      "gen": 5,
      "desc": "Raises holder's Sp. Atk by 1 stage if hit by a Water-type attack. Single use."
    },
    "adamantcrystal": {
      "name": "Adamant Crystal",
      "onBasePowerPriority": 15,
      "forcedForme": "Dialga-Origin",
      "itemUser": ["Dialga-Origin"],
      "num": 1777,
      "gen": 8,
      "desc": "If held by a Dialga, its Steel- and Dragon-type attacks have 1.2x power."
    },
    "adamantorb": {
      "name": "Adamant Orb",
      "fling": {"basePower": 60},
      "onBasePowerPriority": 15,
      "itemUser": ["Dialga"],
      "num": 135,
      "gen": 4,
      "desc": "If held by a Dialga, its Steel- and Dragon-type attacks have 1.2x power."
    },
    "adrenalineorb": {
      "name": "Adrenaline Orb",
      "fling": {"basePower": 30},
      "boosts": {"spe": 1},
      "num": 846,
      "gen": 7,
      "desc": "Raises holder's Speed by 1 stage if it gets affected by Intimidate. Single use."
    },
    "aerodactylite": {
      "name": "Aerodactylite",
      "megaStone": "Aerodactyl-Mega",
      "megaEvolves": "Aerodactyl",
      "itemUser": ["Aerodactyl"],
      "num": 672,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by an Aerodactyl, this item allows it to Mega Evolve in battle."
    },
    "aggronite": {
      "name": "Aggronite",
      "megaStone": "Aggron-Mega",
      "megaEvolves": "Aggron",
      "itemUser": ["Aggron"],
      "num": 667,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by an Aggron, this item allows it to Mega Evolve in battle."
    },
    "aguavberry": {
      "name": "Aguav Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Dragon"},
      "num": 162,
      "gen": 3,
      "desc": "Restores 1/3 max HP at 1/4 max HP or less; confuses if -SpD Nature. Single use."
    },
    "airballoon": {
      "name": "Air Balloon",
      "fling": {"basePower": 10},
      "num": 541,
      "gen": 5,
      "desc": "Holder is immune to Ground-type attacks. Pops when holder is hit."
    },
    "alakazite": {
      "name": "Alakazite",
      "megaStone": "Alakazam-Mega",
      "megaEvolves": "Alakazam",
      "itemUser": ["Alakazam"],
      "num": 679,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by an Alakazam, this item allows it to Mega Evolve in battle."
    },
    "aloraichiumz": {
      "name": "Aloraichium Z",
      "onTakeItem": false,
      "zMove": "Stoked Sparksurfer",
      "zMoveFrom": "Thunderbolt",
      "itemUser": ["Raichu-Alola"],
      "num": 803,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by an Alolan Raichu with Thunderbolt, it can use Stoked Sparksurfer."
    },
    "altarianite": {
      "name": "Altarianite",
      "megaStone": "Altaria-Mega",
      "megaEvolves": "Altaria",
      "itemUser": ["Altaria"],
      "num": 755,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by an Altaria, this item allows it to Mega Evolve in battle."
    },
    "ampharosite": {
      "name": "Ampharosite",
      "megaStone": "Ampharos-Mega",
      "megaEvolves": "Ampharos",
      "itemUser": ["Ampharos"],
      "num": 658,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by an Ampharos, this item allows it to Mega Evolve in battle."
    },
    "apicotberry": {
      "name": "Apicot Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Ground"},
      "num": 205,
      "gen": 3,
      "desc": "Raises holder's Sp. Def by 1 stage when at 1/4 max HP or less. Single use."
    },
    "armorfossil": {
      "name": "Armor Fossil",
      "fling": {"basePower": 100},
      "num": 104,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Can be revived into Shieldon."
    },
    "aspearberry": {
      "name": "Aspear Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Ice"},
      "num": 153,
      "gen": 3,
      "desc": "Holder is cured if it is frozen. Single use."
    },
    "assaultvest": {
      "name": "Assault Vest",
      "fling": {"basePower": 80},
      "onModifySpDPriority": 1,
      "num": 640,
      "gen": 6,
      "desc": "Holder's Sp. Def is 1.5x, but it can only select damaging moves."
    },
    "audinite": {
      "name": "Audinite",
      "megaStone": "Audino-Mega",
      "megaEvolves": "Audino",
      "itemUser": ["Audino"],
      "num": 757,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by an Audino, this item allows it to Mega Evolve in battle."
    },
    "auspiciousarmor": {
      "name": "Auspicious Armor",
      "fling": {"basePower": 30},
      "num": 2344,
      "gen": 9,
      "desc": "Evolves Charcadet into Armarouge when used."
    },
    "babiriberry": {
      "name": "Babiri Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Steel"},
      "num": 199,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Steel-type attack. Single use."
    },
    "banettite": {
      "name": "Banettite",
      "megaStone": "Banette-Mega",
      "megaEvolves": "Banette",
      "itemUser": ["Banette"],
      "num": 668,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Banette, this item allows it to Mega Evolve in battle."
    },
    "beastball": {
      "name": "Beast Ball",
      "num": 851,
      "gen": 7,
      "isPokeball": true,
      "desc": "A special Poke Ball designed to catch Ultra Beasts."
    },
    "beedrillite": {
      "name": "Beedrillite",
      "megaStone": "Beedrill-Mega",
      "megaEvolves": "Beedrill",
      "itemUser": ["Beedrill"],
      "num": 770,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Beedrill, this item allows it to Mega Evolve in battle."
    },
    "belueberry": {
      "name": "Belue Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Electric"},
      "onEat": false,
      "num": 183,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "berryjuice": {
      "name": "Berry Juice",
      "fling": {"basePower": 30},
      "num": 43,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "Restores 20 HP when at 1/2 max HP or less. Single use."
    },
    "berrysweet": {
      "name": "Berry Sweet",
      "fling": {"basePower": 10},
      "num": 1111,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Evolves Milcery into Alcremie when held and spun around."
    },
    "bignugget": {
      "name": "Big Nugget",
      "fling": {"basePower": 130},
      "num": 581,
      "gen": 5,
      "desc": "A big nugget of pure gold that gives off a lustrous gleam."
    },
    "bigroot": {
      "name": "Big Root",
      "fling": {"basePower": 10},
      "onTryHealPriority": 1,
      "num": 296,
      "gen": 4,
      "desc": "Holder gains 1.3x HP from draining/Aqua Ring/Ingrain/Leech Seed/Strength Sap."
    },
    "bindingband": {
      "name": "Binding Band",
      "fling": {"basePower": 30},
      "num": 544,
      "gen": 5,
      "desc": "Holder's partial-trapping moves deal 1/6 max HP per turn instead of 1/8."
    },
    "blackbelt": {
      "name": "Black Belt",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 241,
      "gen": 2,
      "desc": "Holder's Fighting-type attacks have 1.2x power."
    },
    "blackglasses": {
      "name": "Black Glasses",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 240,
      "gen": 2,
      "desc": "Holder's Dark-type attacks have 1.2x power."
    },
    "blacksludge": {
      "name": "Black Sludge",
      "fling": {"basePower": 30},
      "onResidualOrder": 5,
      "onResidualSubOrder": 4,
      "num": 281,
      "gen": 4,
      "desc": "Each turn, if holder is a Poison type, restores 1/16 max HP; loses 1/8 if not."
    },
    "blastoisinite": {
      "name": "Blastoisinite",
      "megaStone": "Blastoise-Mega",
      "megaEvolves": "Blastoise",
      "itemUser": ["Blastoise"],
      "num": 661,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Blastoise, this item allows it to Mega Evolve in battle."
    },
    "blazikenite": {
      "name": "Blazikenite",
      "megaStone": "Blaziken-Mega",
      "megaEvolves": "Blaziken",
      "itemUser": ["Blaziken"],
      "num": 664,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Blaziken, this item allows it to Mega Evolve in battle."
    },
    "blueorb": {
      "name": "Blue Orb",
      "itemUser": ["Kyogre"],
      "num": 535,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Kyogre, this item triggers its Primal Reversion in battle."
    },
    "blukberry": {
      "name": "Bluk Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Fire"},
      "onEat": false,
      "num": 165,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "blunderpolicy": {
      "name": "Blunder Policy",
      "fling": {"basePower": 80},
      "num": 1121,
      "gen": 8,
      "desc": "If the holder misses due to accuracy, its Speed is raised by 2 stages. Single use."
    },
    "boosterenergy": {
      "name": "Booster Energy",
      "fling": {"basePower": 30},
      "num": 1880,
      "gen": 9,
      "desc": "Activates the Protosynthesis or Quark Drive Abilities. Single use."
    },
    "bottlecap": {
      "name": "Bottle Cap",
      "fling": {"basePower": 30},
      "num": 795,
      "gen": 7,
      "desc": "Used for Hyper Training. One of a Pokemon's stats is calculated with an IV of 31."
    },
    "brightpowder": {
      "name": "Bright Powder",
      "fling": {"basePower": 10},
      "onModifyAccuracyPriority": -2,
      "num": 213,
      "gen": 2,
      "desc": "The accuracy of attacks against the holder is 0.9x."
    },
    "buggem": {
      "name": "Bug Gem",
      "isGem": true,
      "num": 558,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Bug-type attack will have 1.3x power. Single use."
    },
    "bugmemory": {
      "name": "Bug Memory",
      "onMemory": "Bug",
      "forcedForme": "Silvally-Bug",
      "itemUser": ["Silvally-Bug"],
      "num": 909,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Bug type."
    },
    "buginiumz": {
      "name": "Buginium Z",
      "onPlate": "Bug",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Bug",
      "forcedForme": "Arceus-Bug",
      "num": 787,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Bug move, this item allows it to use a Bug Z-Move."
    },
    "burndrive": {
      "name": "Burn Drive",
      "onDrive": "Fire",
      "forcedForme": "Genesect-Burn",
      "itemUser": ["Genesect-Burn"],
      "num": 118,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's Techno Blast is Fire type."
    },
    "cameruptite": {
      "name": "Cameruptite",
      "megaStone": "Camerupt-Mega",
      "megaEvolves": "Camerupt",
      "itemUser": ["Camerupt"],
      "num": 767,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Camerupt, this item allows it to Mega Evolve in battle."
    },
    "cellbattery": {
      "name": "Cell Battery",
      "fling": {"basePower": 30},
      "boosts": {"atk": 1},
      "num": 546,
      "gen": 5,
      "desc": "Raises holder's Attack by 1 if hit by an Electric-type attack. Single use."
    },
    "charcoal": {
      "name": "Charcoal",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 249,
      "gen": 2,
      "desc": "Holder's Fire-type attacks have 1.2x power."
    },
    "charizarditex": {
      "name": "Charizardite X",
      "megaStone": "Charizard-Mega-X",
      "megaEvolves": "Charizard",
      "itemUser": ["Charizard"],
      "num": 660,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Charizard, this item allows it to Mega Evolve in battle."
    },
    "charizarditey": {
      "name": "Charizardite Y",
      "megaStone": "Charizard-Mega-Y",
      "megaEvolves": "Charizard",
      "itemUser": ["Charizard"],
      "num": 678,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Charizard, this item allows it to Mega Evolve in battle."
    },
    "chartiberry": {
      "name": "Charti Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Rock"},
      "num": 195,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Rock-type attack. Single use."
    },
    "cheriberry": {
      "name": "Cheri Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Fire"},
      "num": 149,
      "gen": 3,
      "desc": "Holder cures itself if it is paralyzed. Single use."
    },
    "cherishball": {
      "name": "Cherish Ball",
      "num": 16,
      "gen": 4,
      "isPokeball": true,
      "isNonstandard": "Unobtainable",
      "desc": "A rare Poke Ball that has been crafted to commemorate an occasion."
    },
    "chestoberry": {
      "name": "Chesto Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Water"},
      "num": 150,
      "gen": 3,
      "desc": "Holder wakes up if it is asleep. Single use."
    },
    "chilanberry": {
      "name": "Chilan Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Normal"},
      "num": 200,
      "gen": 4,
      "desc": "Halves damage taken from a Normal-type attack. Single use."
    },
    "chilldrive": {
      "name": "Chill Drive",
      "onDrive": "Ice",
      "forcedForme": "Genesect-Chill",
      "itemUser": ["Genesect-Chill"],
      "num": 119,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's Techno Blast is Ice type."
    },
    "chippedpot": {
      "name": "Chipped Pot",
      "fling": {"basePower": 80},
      "num": 1254,
      "gen": 8,
      "desc": "Evolves Sinistea-Antique into Polteageist-Antique when used."
    },
    "choiceband": {
      "name": "Choice Band",
      "fling": {"basePower": 10},
      "onModifyAtkPriority": 1,
      "isChoice": true,
      "num": 220,
      "gen": 3,
      "desc": "Holder's Attack is 1.5x, but it can only select the first move it executes."
    },
    "choicescarf": {
      "name": "Choice Scarf",
      "fling": {"basePower": 10},
      "isChoice": true,
      "num": 287,
      "gen": 4,
      "desc": "Holder's Speed is 1.5x, but it can only select the first move it executes."
    },
    "choicespecs": {
      "name": "Choice Specs",
      "fling": {"basePower": 10},
      "onModifySpAPriority": 1,
      "isChoice": true,
      "num": 297,
      "gen": 4,
      "desc": "Holder's Sp. Atk is 1.5x, but it can only select the first move it executes."
    },
    "chopleberry": {
      "name": "Chople Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Fighting"},
      "num": 189,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Fighting-type attack. Single use."
    },
    "clawfossil": {
      "name": "Claw Fossil",
      "fling": {"basePower": 100},
      "num": 100,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Can be revived into Anorith."
    },
    "clearamulet": {
      "name": "Clear Amulet",
      "fling": {"basePower": 30},
      "num": 1882,
      "gen": 9,
      "desc": "Prevents other Pokemon from lowering the holder's stat stages."
    },
    "cloversweet": {
      "name": "Clover Sweet",
      "fling": {"basePower": 10},
      "num": 1112,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Evolves Milcery into Alcremie when held and spun around."
    },
    "cobaberry": {
      "name": "Coba Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Flying"},
      "num": 192,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Flying-type attack. Single use."
    },
    "colburberry": {
      "name": "Colbur Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Dark"},
      "num": 198,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Dark-type attack. Single use."
    },
    "cornerstonemask": {
      "name": "Cornerstone Mask",
      "fling": {"basePower": 60},
      "onBasePowerPriority": 15,
      "forcedForme": "Ogerpon-Cornerstone",
      "itemUser": ["Ogerpon-Cornerstone"],
      "num": 2406,
      "gen": 9,
      "desc": "Ogerpon-Cornerstone: 1.2x power attacks; Terastallize to gain Embody Aspect."
    },
    "cornnberry": {
      "name": "Cornn Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Bug"},
      "onEat": false,
      "num": 175,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "coverfossil": {
      "name": "Cover Fossil",
      "fling": {"basePower": 100},
      "num": 572,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Can be revived into Tirtouga."
    },
    "covertcloak": {
      "name": "Covert Cloak",
      "fling": {"basePower": 30},
      "num": 1885,
      "gen": 9,
      "desc": "Holder is not affected by the secondary effect of another Pokemon's attack."
    },
    "crackedpot": {
      "name": "Cracked Pot",
      "fling": {"basePower": 80},
      "num": 1253,
      "gen": 8,
      "desc": "Evolves Sinistea into Polteageist when used."
    },
    "custapberry": {
      "name": "Custap Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Ghost"},
      "onFractionalPriorityPriority": -2,
      "num": 210,
      "gen": 4,
      "desc": "Holder moves first in its priority bracket when at 1/4 max HP or less. Single use."
    },
    "damprock": {
      "name": "Damp Rock",
      "fling": {"basePower": 60},
      "num": 285,
      "gen": 4,
      "desc": "Holder's use of Rain Dance lasts 8 turns instead of 5."
    },
    "darkgem": {
      "name": "Dark Gem",
      "isGem": true,
      "num": 562,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Dark-type attack will have 1.3x power. Single use."
    },
    "darkmemory": {
      "name": "Dark Memory",
      "onMemory": "Dark",
      "forcedForme": "Silvally-Dark",
      "itemUser": ["Silvally-Dark"],
      "num": 919,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Dark type."
    },
    "darkiniumz": {
      "name": "Darkinium Z",
      "onPlate": "Dark",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Dark",
      "forcedForme": "Arceus-Dark",
      "num": 791,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Dark move, this item allows it to use a Dark Z-Move."
    },
    "dawnstone": {
      "name": "Dawn Stone",
      "fling": {"basePower": 80},
      "num": 109,
      "gen": 4,
      "desc": "Evolves male Kirlia into Gallade and female Snorunt into Froslass when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "decidiumz": {
      "name": "Decidium Z",
      "onTakeItem": false,
      "zMove": "Sinister Arrow Raid",
      "zMoveFrom": "Spirit Shackle",
      "itemUser": ["Decidueye"],
      "num": 798,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by a Decidueye with Spirit Shackle, it can use Sinister Arrow Raid."
    },
    "deepseascale": {
      "name": "Deep Sea Scale",
      "fling": {"basePower": 30},
      "onModifySpDPriority": 2,
      "itemUser": ["Clamperl"],
      "num": 227,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "If held by a Clamperl, its Sp. Def is doubled. Evolves Clamperl into Gorebyss when traded.",
      "shortDesc": "If held by a Clamperl, its Sp. Def is doubled."
    },
    "deepseatooth": {
      "name": "Deep Sea Tooth",
      "fling": {"basePower": 90},
      "onModifySpAPriority": 1,
      "itemUser": ["Clamperl"],
      "num": 226,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "If held by a Clamperl, its Sp. Atk is doubled. Evolves Clamperl into Huntail when traded.",
      "shortDesc": "If held by a Clamperl, its Sp. Atk is doubled."
    },
    "destinyknot": {
      "name": "Destiny Knot",
      "fling": {"basePower": 10},
      "onAttractPriority": -100,
      "num": 280,
      "gen": 4,
      "desc": "If holder becomes infatuated, the other Pokemon also becomes infatuated."
    },
    "diancite": {
      "name": "Diancite",
      "megaStone": "Diancie-Mega",
      "megaEvolves": "Diancie",
      "itemUser": ["Diancie"],
      "num": 764,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Diancie, this item allows it to Mega Evolve in battle."
    },
    "diveball": {
      "name": "Dive Ball",
      "num": 7,
      "gen": 3,
      "isPokeball": true,
      "desc": "A Poke Ball that works especially well on Pokemon that live underwater."
    },
    "domefossil": {
      "name": "Dome Fossil",
      "fling": {"basePower": 100},
      "num": 102,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Can be revived into Kabuto."
    },
    "dousedrive": {
      "name": "Douse Drive",
      "onDrive": "Water",
      "forcedForme": "Genesect-Douse",
      "itemUser": ["Genesect-Douse"],
      "num": 116,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's Techno Blast is Water type."
    },
    "dracoplate": {
      "name": "Draco Plate",
      "onPlate": "Dragon",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Dragon",
      "num": 311,
      "gen": 4,
      "desc": "Holder's Dragon-type attacks have 1.2x power. Judgment is Dragon type."
    },
    "dragonfang": {
      "name": "Dragon Fang",
      "fling": {"basePower": 70},
      "onBasePowerPriority": 15,
      "num": 250,
      "gen": 2,
      "desc": "Holder's Dragon-type attacks have 1.2x power."
    },
    "dragongem": {
      "name": "Dragon Gem",
      "isGem": true,
      "num": 561,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Dragon-type attack will have 1.3x power. Single use."
    },
    "dragonmemory": {
      "name": "Dragon Memory",
      "onMemory": "Dragon",
      "forcedForme": "Silvally-Dragon",
      "itemUser": ["Silvally-Dragon"],
      "num": 918,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Dragon type."
    },
    "dragonscale": {
      "name": "Dragon Scale",
      "fling": {"basePower": 30},
      "num": 235,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "Evolves Seadra into Kingdra when traded."
    },
    "dragoniumz": {
      "name": "Dragonium Z",
      "onPlate": "Dragon",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Dragon",
      "forcedForme": "Arceus-Dragon",
      "num": 790,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Dragon move, this item allows it to use a Dragon Z-Move."
    },
    "dreadplate": {
      "name": "Dread Plate",
      "onPlate": "Dark",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Dark",
      "num": 312,
      "gen": 4,
      "desc": "Holder's Dark-type attacks have 1.2x power. Judgment is Dark type."
    },
    "dreamball": {
      "name": "Dream Ball",
      "num": 576,
      "gen": 5,
      "isPokeball": true,
      "desc": "A Poke Ball that makes it easier to catch wild Pokémon while they're asleep."
    },
    "dubiousdisc": {
      "name": "Dubious Disc",
      "fling": {"basePower": 50},
      "num": 324,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Evolves Porygon2 into Porygon-Z when traded."
    },
    "durinberry": {
      "name": "Durin Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Water"},
      "onEat": false,
      "num": 182,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "duskball": {
      "name": "Dusk Ball",
      "num": 13,
      "gen": 4,
      "isPokeball": true,
      "desc": "A Poke Ball that makes it easier to catch wild Pokemon at night or in caves."
    },
    "duskstone": {
      "name": "Dusk Stone",
      "fling": {"basePower": 80},
      "num": 108,
      "gen": 4,
      "desc": "Evolves Murkrow into Honchkrow, Misdreavus into Mismagius, Lampent into Chandelure, and Doublade into Aegislash when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "earthplate": {
      "name": "Earth Plate",
      "onPlate": "Ground",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Ground",
      "num": 305,
      "gen": 4,
      "desc": "Holder's Ground-type attacks have 1.2x power. Judgment is Ground type."
    },
    "eeviumz": {
      "name": "Eevium Z",
      "onTakeItem": false,
      "zMove": "Extreme Evoboost",
      "zMoveFrom": "Last Resort",
      "itemUser": ["Eevee"],
      "num": 805,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by an Eevee with Last Resort, it can use Extreme Evoboost."
    },
    "ejectbutton": {
      "name": "Eject Button",
      "fling": {"basePower": 30},
      "onAfterMoveSecondaryPriority": 2,
      "num": 547,
      "gen": 5,
      "desc": "If holder survives a hit, it immediately switches out to a chosen ally. Single use."
    },
    "ejectpack": {
      "name": "Eject Pack",
      "fling": {"basePower": 50},
      "num": 1119,
      "gen": 8,
      "desc": "If the holder's stat stages are lowered, it switches to a chosen ally. Single use."
    },
    "electirizer": {
      "name": "Electirizer",
      "fling": {"basePower": 80},
      "num": 322,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Evolves Electabuzz into Electivire when traded."
    },
    "electricgem": {
      "name": "Electric Gem",
      "isGem": true,
      "num": 550,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Electric-type attack will have 1.3x power. Single use."
    },
    "electricmemory": {
      "name": "Electric Memory",
      "onMemory": "Electric",
      "forcedForme": "Silvally-Electric",
      "itemUser": ["Silvally-Electric"],
      "num": 915,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Electric type."
    },
    "electricseed": {
      "name": "Electric Seed",
      "fling": {"basePower": 10},
      "boosts": {"def": 1},
      "num": 881,
      "gen": 7,
      "desc": "If the terrain is Electric Terrain, raises holder's Defense by 1 stage. Single use."
    },
    "electriumz": {
      "name": "Electrium Z",
      "onPlate": "Electric",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Electric",
      "forcedForme": "Arceus-Electric",
      "num": 779,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has an Electric move, this item allows it to use an Electric Z-Move."
    },
    "enigmaberry": {
      "name": "Enigma Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Bug"},
      "num": 208,
      "gen": 3,
      "desc": "Restores 1/4 max HP after holder is hit by a supereffective move. Single use."
    },
    "eviolite": {
      "name": "Eviolite",
      "fling": {"basePower": 40},
      "onModifyDefPriority": 2,
      "onModifySpDPriority": 2,
      "num": 538,
      "gen": 5,
      "desc": "If holder's species can evolve, its Defense and Sp. Def are 1.5x."
    },
    "expertbelt": {
      "name": "Expert Belt",
      "fling": {"basePower": 10},
      "num": 268,
      "gen": 4,
      "desc": "Holder's attacks that are super effective against the target do 1.2x damage."
    },
    "fairiumz": {
      "name": "Fairium Z",
      "onPlate": "Fairy",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Fairy",
      "forcedForme": "Arceus-Fairy",
      "num": 793,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Fairy move, this item allows it to use a Fairy Z-Move."
    },
    "fairyfeather": {
      "name": "Fairy Feather",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 15,
      "num": 2401,
      "gen": 9,
      "desc": "Holder's Fairy-type attacks have 1.2x power."
    },
    "fairygem": {
      "name": "Fairy Gem",
      "isGem": true,
      "num": 715,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Fairy-type attack will have 1.3x power. Single use."
    },
    "fairymemory": {
      "name": "Fairy Memory",
      "onMemory": "Fairy",
      "forcedForme": "Silvally-Fairy",
      "itemUser": ["Silvally-Fairy"],
      "num": 920,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Fairy type."
    },
    "fastball": {
      "name": "Fast Ball",
      "num": 492,
      "gen": 2,
      "isPokeball": true,
      "desc": "A Poke Ball that makes it easier to catch Pokemon which are quick to run away."
    },
    "fightinggem": {
      "name": "Fighting Gem",
      "isGem": true,
      "num": 553,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Fighting-type attack will have 1.3x power. Single use."
    },
    "fightingmemory": {
      "name": "Fighting Memory",
      "onMemory": "Fighting",
      "forcedForme": "Silvally-Fighting",
      "itemUser": ["Silvally-Fighting"],
      "num": 904,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Fighting type."
    },
    "fightiniumz": {
      "name": "Fightinium Z",
      "onPlate": "Fighting",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Fighting",
      "forcedForme": "Arceus-Fighting",
      "num": 782,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Fighting move, this item allows it to use a Fighting Z-Move."
    },
    "figyberry": {
      "name": "Figy Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Bug"},
      "num": 159,
      "gen": 3,
      "desc": "Restores 1/3 max HP at 1/4 max HP or less; confuses if -Atk Nature. Single use."
    },
    "firegem": {
      "name": "Fire Gem",
      "isGem": true,
      "num": 548,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Fire-type attack will have 1.3x power. Single use."
    },
    "firememory": {
      "name": "Fire Memory",
      "onMemory": "Fire",
      "forcedForme": "Silvally-Fire",
      "itemUser": ["Silvally-Fire"],
      "num": 912,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Fire type."
    },
    "firestone": {
      "name": "Fire Stone",
      "fling": {"basePower": 30},
      "num": 82,
      "gen": 1,
      "desc": "Evolves Vulpix into Ninetales, Growlithe into Arcanine, Eevee into Flareon, and Pansear into Simisear when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "firiumz": {
      "name": "Firium Z",
      "onPlate": "Fire",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Fire",
      "forcedForme": "Arceus-Fire",
      "num": 777,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Fire move, this item allows it to use a Fire Z-Move."
    },
    "fistplate": {
      "name": "Fist Plate",
      "onPlate": "Fighting",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Fighting",
      "num": 303,
      "gen": 4,
      "desc": "Holder's Fighting-type attacks have 1.2x power. Judgment is Fighting type."
    },
    "flameorb": {
      "name": "Flame Orb",
      "fling": {"basePower": 30, "status": "brn"},
      "onResidualOrder": 28,
      "onResidualSubOrder": 3,
      "num": 273,
      "gen": 4,
      "desc": "At the end of every turn, this item attempts to burn the holder."
    },
    "flameplate": {
      "name": "Flame Plate",
      "onPlate": "Fire",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Fire",
      "num": 298,
      "gen": 4,
      "desc": "Holder's Fire-type attacks have 1.2x power. Judgment is Fire type."
    },
    "floatstone": {
      "name": "Float Stone",
      "fling": {"basePower": 30},
      "num": 539,
      "gen": 5,
      "desc": "Holder's weight is halved."
    },
    "flowersweet": {
      "name": "Flower Sweet",
      "fling": {"basePower": 0},
      "num": 1113,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Evolves Milcery into Alcremie when held and spun around."
    },
    "flyinggem": {
      "name": "Flying Gem",
      "isGem": true,
      "num": 556,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Flying-type attack will have 1.3x power. Single use."
    },
    "flyingmemory": {
      "name": "Flying Memory",
      "onMemory": "Flying",
      "forcedForme": "Silvally-Flying",
      "itemUser": ["Silvally-Flying"],
      "num": 905,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Flying type."
    },
    "flyiniumz": {
      "name": "Flyinium Z",
      "onPlate": "Flying",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Flying",
      "forcedForme": "Arceus-Flying",
      "num": 785,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Flying move, this item allows it to use a Flying Z-Move."
    },
    "focusband": {
      "name": "Focus Band",
      "fling": {"basePower": 10},
      "onDamagePriority": -40,
      "num": 230,
      "gen": 2,
      "desc": "Holder has a 10% chance to survive an attack that would KO it with 1 HP."
    },
    "focussash": {
      "name": "Focus Sash",
      "fling": {"basePower": 10},
      "onDamagePriority": -40,
      "num": 275,
      "gen": 4,
      "desc": "If holder's HP is full, will survive an attack that would KO it with 1 HP. Single use."
    },
    "fossilizedbird": {
      "name": "Fossilized Bird",
      "fling": {"basePower": 100},
      "num": 1105,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Can revive into Dracozolt with Fossilized Drake or Arctozolt with Fossilized Dino."
    },
    "fossilizeddino": {
      "name": "Fossilized Dino",
      "fling": {"basePower": 100},
      "num": 1108,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Can revive into Arctovish with Fossilized Fish or Arctozolt with Fossilized Bird."
    },
    "fossilizeddrake": {
      "name": "Fossilized Drake",
      "fling": {"basePower": 100},
      "num": 1107,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Can revive into Dracozolt with Fossilized Bird or Dracovish with Fossilized Fish."
    },
    "fossilizedfish": {
      "name": "Fossilized Fish",
      "fling": {"basePower": 100},
      "num": 1106,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Can revive into Dracovish with Fossilized Drake or Arctovish with Fossilized Dino."
    },
    "friendball": {
      "name": "Friend Ball",
      "num": 497,
      "gen": 2,
      "isPokeball": true,
      "desc": "A Poke Ball that makes caught Pokemon more friendly."
    },
    "fullincense": {
      "name": "Full Incense",
      "fling": {"basePower": 10},
      "onFractionalPriority": -0.1,
      "num": 316,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Holder moves last in its priority bracket."
    },
    "galaricacuff": {
      "name": "Galarica Cuff",
      "fling": {"basePower": 30},
      "num": 1582,
      "gen": 8,
      "isNonstandard": "Unobtainable",
      "desc": "Evolves Galarian Slowpoke into Galarian Slowbro when used."
    },
    "galaricawreath": {
      "name": "Galarica Wreath",
      "fling": {"basePower": 30},
      "num": 1592,
      "gen": 8,
      "isNonstandard": "Unobtainable",
      "desc": "Evolves Galarian Slowpoke into Galarian Slowking when used."
    },
    "galladite": {
      "name": "Galladite",
      "megaStone": "Gallade-Mega",
      "megaEvolves": "Gallade",
      "itemUser": ["Gallade"],
      "num": 756,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Gallade, this item allows it to Mega Evolve in battle."
    },
    "ganlonberry": {
      "name": "Ganlon Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Ice"},
      "num": 202,
      "gen": 3,
      "desc": "Raises holder's Defense by 1 stage when at 1/4 max HP or less. Single use."
    },
    "garchompite": {
      "name": "Garchompite",
      "megaStone": "Garchomp-Mega",
      "megaEvolves": "Garchomp",
      "itemUser": ["Garchomp"],
      "num": 683,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Garchomp, this item allows it to Mega Evolve in battle."
    },
    "gardevoirite": {
      "name": "Gardevoirite",
      "megaStone": "Gardevoir-Mega",
      "megaEvolves": "Gardevoir",
      "itemUser": ["Gardevoir"],
      "num": 657,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Gardevoir, this item allows it to Mega Evolve in battle."
    },
    "gengarite": {
      "name": "Gengarite",
      "megaStone": "Gengar-Mega",
      "megaEvolves": "Gengar",
      "itemUser": ["Gengar"],
      "num": 656,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Gengar, this item allows it to Mega Evolve in battle."
    },
    "ghostgem": {
      "name": "Ghost Gem",
      "isGem": true,
      "num": 560,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Ghost-type attack will have 1.3x power. Single use."
    },
    "ghostmemory": {
      "name": "Ghost Memory",
      "onMemory": "Ghost",
      "forcedForme": "Silvally-Ghost",
      "itemUser": ["Silvally-Ghost"],
      "num": 910,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Ghost type."
    },
    "ghostiumz": {
      "name": "Ghostium Z",
      "onPlate": "Ghost",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Ghost",
      "forcedForme": "Arceus-Ghost",
      "num": 789,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Ghost move, this item allows it to use a Ghost Z-Move."
    },
    "glalitite": {
      "name": "Glalitite",
      "megaStone": "Glalie-Mega",
      "megaEvolves": "Glalie",
      "itemUser": ["Glalie"],
      "num": 763,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Glalie, this item allows it to Mega Evolve in battle."
    },
    "goldbottlecap": {
      "name": "Gold Bottle Cap",
      "fling": {"basePower": 30},
      "num": 796,
      "gen": 7,
      "desc": "Used for Hyper Training. All of a Pokemon's stats are calculated with an IV of 31."
    },
    "grassgem": {
      "name": "Grass Gem",
      "isGem": true,
      "num": 551,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Grass-type attack will have 1.3x power. Single use."
    },
    "grassmemory": {
      "name": "Grass Memory",
      "onMemory": "Grass",
      "forcedForme": "Silvally-Grass",
      "itemUser": ["Silvally-Grass"],
      "num": 914,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Grass type."
    },
    "grassiumz": {
      "name": "Grassium Z",
      "onPlate": "Grass",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Grass",
      "forcedForme": "Arceus-Grass",
      "num": 780,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Grass move, this item allows it to use a Grass Z-Move."
    },
    "grassyseed": {
      "name": "Grassy Seed",
      "fling": {"basePower": 10},
      "boosts": {"def": 1},
      "num": 884,
      "gen": 7,
      "desc": "If the terrain is Grassy Terrain, raises holder's Defense by 1 stage. Single use."
    },
    "greatball": {
      "name": "Great Ball",
      "num": 3,
      "gen": 1,
      "isPokeball": true,
      "desc": "A high-performance Ball that provides a higher catch rate than a Poke Ball."
    },
    "grepaberry": {
      "name": "Grepa Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Flying"},
      "onEat": false,
      "num": 173,
      "gen": 3,
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "gripclaw": {
      "name": "Grip Claw",
      "fling": {"basePower": 90},
      "num": 286,
      "gen": 4,
      "desc": "Holder's partial-trapping moves always last 7 turns."
    },
    "griseouscore": {
      "name": "Griseous Core",
      "onBasePowerPriority": 15,
      "forcedForme": "Giratina-Origin",
      "itemUser": ["Giratina-Origin"],
      "num": 1779,
      "gen": 8,
      "desc": "If held by a Giratina, its Ghost- and Dragon-type attacks have 1.2x power."
    },
    "griseousorb": {
      "name": "Griseous Orb",
      "fling": {"basePower": 60},
      "onBasePowerPriority": 15,
      "itemUser": ["Giratina"],
      "num": 112,
      "gen": 4,
      "desc": "If held by a Giratina, its Ghost- and Dragon-type attacks have 1.2x power."
    },
    "groundgem": {
      "name": "Ground Gem",
      "isGem": true,
      "num": 555,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Ground-type attack will have 1.3x power. Single use."
    },
    "groundmemory": {
      "name": "Ground Memory",
      "onMemory": "Ground",
      "forcedForme": "Silvally-Ground",
      "itemUser": ["Silvally-Ground"],
      "num": 907,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Ground type."
    },
    "groundiumz": {
      "name": "Groundium Z",
      "onPlate": "Ground",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Ground",
      "forcedForme": "Arceus-Ground",
      "num": 784,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Ground move, this item allows it to use a Ground Z-Move."
    },
    "gyaradosite": {
      "name": "Gyaradosite",
      "megaStone": "Gyarados-Mega",
      "megaEvolves": "Gyarados",
      "itemUser": ["Gyarados"],
      "num": 676,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Gyarados, this item allows it to Mega Evolve in battle."
    },
    "habanberry": {
      "name": "Haban Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Dragon"},
      "num": 197,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Dragon-type attack. Single use."
    },
    "hardstone": {
      "name": "Hard Stone",
      "fling": {"basePower": 100},
      "onBasePowerPriority": 15,
      "num": 238,
      "gen": 2,
      "desc": "Holder's Rock-type attacks have 1.2x power."
    },
    "healball": {
      "name": "Heal Ball",
      "num": 14,
      "gen": 4,
      "isPokeball": true,
      "desc": "A remedial Poke Ball that restores the caught Pokemon's HP and status problem."
    },
    "hearthflamemask": {
      "name": "Hearthflame Mask",
      "fling": {"basePower": 60},
      "onBasePowerPriority": 15,
      "forcedForme": "Ogerpon-Hearthflame",
      "itemUser": ["Ogerpon-Hearthflame"],
      "num": 2408,
      "gen": 9,
      "desc": "Ogerpon-Hearthflame: 1.2x power attacks; Terastallize to gain Embody Aspect."
    },
    "heatrock": {
      "name": "Heat Rock",
      "fling": {"basePower": 60},
      "num": 284,
      "gen": 4,
      "desc": "Holder's use of Sunny Day lasts 8 turns instead of 5."
    },
    "heavyball": {
      "name": "Heavy Ball",
      "num": 495,
      "gen": 2,
      "isPokeball": true,
      "desc": "A Poke Ball for catching very heavy Pokemon."
    },
    "heavydutyboots": {
      "name": "Heavy-Duty Boots",
      "fling": {"basePower": 80},
      "num": 1120,
      "gen": 8,
      "desc": "When switching in, the holder is unaffected by hazards on its side of the field."
    },
    "helixfossil": {
      "name": "Helix Fossil",
      "fling": {"basePower": 100},
      "num": 101,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Can be revived into Omanyte."
    },
    "heracronite": {
      "name": "Heracronite",
      "megaStone": "Heracross-Mega",
      "megaEvolves": "Heracross",
      "itemUser": ["Heracross"],
      "num": 680,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Heracross, this item allows it to Mega Evolve in battle."
    },
    "hondewberry": {
      "name": "Hondew Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Ground"},
      "onEat": false,
      "num": 172,
      "gen": 3,
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "houndoominite": {
      "name": "Houndoominite",
      "megaStone": "Houndoom-Mega",
      "megaEvolves": "Houndoom",
      "itemUser": ["Houndoom"],
      "num": 666,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Houndoom, this item allows it to Mega Evolve in battle."
    },
    "iapapaberry": {
      "name": "Iapapa Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Dark"},
      "num": 163,
      "gen": 3,
      "desc": "Restores 1/3 max HP at 1/4 max HP or less; confuses if -Def Nature. Single use."
    },
    "icegem": {
      "name": "Ice Gem",
      "isGem": true,
      "num": 552,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Ice-type attack will have 1.3x power. Single use."
    },
    "icememory": {
      "name": "Ice Memory",
      "onMemory": "Ice",
      "forcedForme": "Silvally-Ice",
      "itemUser": ["Silvally-Ice"],
      "num": 917,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Ice type."
    },
    "icestone": {
      "name": "Ice Stone",
      "fling": {"basePower": 30},
      "num": 849,
      "gen": 7,
      "desc": "Evolves Alolan Sandshrew into Alolan Sandslash, Alolan Vulpix into Alolan Ninetales, Eevee into Glaceon, and Galarian Darumaka into Galarian Darmanitan when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "icicleplate": {
      "name": "Icicle Plate",
      "onPlate": "Ice",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Ice",
      "num": 302,
      "gen": 4,
      "desc": "Holder's Ice-type attacks have 1.2x power. Judgment is Ice type."
    },
    "iciumz": {
      "name": "Icium Z",
      "onPlate": "Ice",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Ice",
      "forcedForme": "Arceus-Ice",
      "num": 781,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has an Ice move, this item allows it to use an Ice Z-Move."
    },
    "icyrock": {
      "name": "Icy Rock",
      "fling": {"basePower": 40},
      "num": 282,
      "gen": 4,
      "desc": "Holder's use of Hail lasts 8 turns instead of 5."
    },
    "inciniumz": {
      "name": "Incinium Z",
      "onTakeItem": false,
      "zMove": "Malicious Moonsault",
      "zMoveFrom": "Darkest Lariat",
      "itemUser": ["Incineroar"],
      "num": 799,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by an Incineroar with Darkest Lariat, it can use Malicious Moonsault."
    },
    "insectplate": {
      "name": "Insect Plate",
      "onPlate": "Bug",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Bug",
      "num": 308,
      "gen": 4,
      "desc": "Holder's Bug-type attacks have 1.2x power. Judgment is Bug type."
    },
    "ironball": {
      "name": "Iron Ball",
      "fling": {"basePower": 130},
      "num": 278,
      "gen": 4,
      "desc": "Holder is grounded, Speed halved. If Flying type, takes neutral Ground damage."
    },
    "ironplate": {
      "name": "Iron Plate",
      "onPlate": "Steel",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Steel",
      "num": 313,
      "gen": 4,
      "desc": "Holder's Steel-type attacks have 1.2x power. Judgment is Steel type."
    },
    "jabocaberry": {
      "name": "Jaboca Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Dragon"},
      "num": 211,
      "gen": 4,
      "desc": "If holder is hit by a physical move, attacker loses 1/8 of its max HP. Single use."
    },
    "jawfossil": {
      "name": "Jaw Fossil",
      "fling": {"basePower": 100},
      "num": 710,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "Can be revived into Tyrunt."
    },
    "kasibberry": {
      "name": "Kasib Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Ghost"},
      "num": 196,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Ghost-type attack. Single use."
    },
    "kebiaberry": {
      "name": "Kebia Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Poison"},
      "num": 190,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Poison-type attack. Single use."
    },
    "keeberry": {
      "name": "Kee Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Fairy"},
      "num": 687,
      "gen": 6,
      "desc": "Raises holder's Defense by 1 stage after it is hit by a physical attack. Single use."
    },
    "kelpsyberry": {
      "name": "Kelpsy Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Fighting"},
      "onEat": false,
      "num": 170,
      "gen": 3,
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "kangaskhanite": {
      "name": "Kangaskhanite",
      "megaStone": "Kangaskhan-Mega",
      "megaEvolves": "Kangaskhan",
      "itemUser": ["Kangaskhan"],
      "num": 675,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Kangaskhan, this item allows it to Mega Evolve in battle."
    },
    "kingsrock": {
      "name": "King's Rock",
      "fling": {"basePower": 30, "volatileStatus": "flinch"},
      "onModifyMovePriority": -1,
      "num": 221,
      "gen": 2,
      "desc": "Holder's attacks without a chance to make the target flinch gain a 10% chance to make the target flinch. Evolves Poliwhirl into Politoed and Slowpoke into Slowking when traded.",
      "shortDesc": "Holder's attacks without a chance to flinch gain a 10% chance to flinch."
    },
    "kommoniumz": {
      "name": "Kommonium Z",
      "onTakeItem": false,
      "zMove": "Clangorous Soulblaze",
      "zMoveFrom": "Clanging Scales",
      "itemUser": ["Kommo-o", "Kommo-o-Totem"],
      "num": 926,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by a Kommo-o with Clanging Scales, it can use Clangorous Soulblaze."
    },
    "laggingtail": {
      "name": "Lagging Tail",
      "fling": {"basePower": 10},
      "onFractionalPriority": -0.1,
      "num": 279,
      "gen": 4,
      "desc": "Holder moves last in its priority bracket."
    },
    "lansatberry": {
      "name": "Lansat Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Flying"},
      "num": 206,
      "gen": 3,
      "desc": "Holder gains the Focus Energy effect when at 1/4 max HP or less. Single use."
    },
    "latiasite": {
      "name": "Latiasite",
      "megaStone": "Latias-Mega",
      "megaEvolves": "Latias",
      "itemUser": ["Latias"],
      "num": 684,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Latias, this item allows it to Mega Evolve in battle."
    },
    "latiosite": {
      "name": "Latiosite",
      "megaStone": "Latios-Mega",
      "megaEvolves": "Latios",
      "itemUser": ["Latios"],
      "num": 685,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Latios, this item allows it to Mega Evolve in battle."
    },
    "laxincense": {
      "name": "Lax Incense",
      "fling": {"basePower": 10},
      "onModifyAccuracyPriority": -2,
      "num": 255,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "The accuracy of attacks against the holder is 0.9x."
    },
    "leafstone": {
      "name": "Leaf Stone",
      "fling": {"basePower": 30},
      "num": 85,
      "gen": 1,
      "desc": "Evolves Gloom into Vileplume, Weepinbell into Victreebel, Exeggcute into Exeggutor or Alolan Exeggutor, Eevee into Leafeon, Nuzleaf into Shiftry, and Pansage into Simisage when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "leek": {
      "name": "Leek",
      "fling": {"basePower": 60},
      "itemUser": ["Farfetch’d", "Farfetch’d-Galar", "Sirfetch’d"],
      "num": 259,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "If held by a Farfetch’d or Sirfetch’d, its critical hit ratio is raised by 2 stages."
    },
    "leftovers": {
      "name": "Leftovers",
      "fling": {"basePower": 10},
      "onResidualOrder": 5,
      "onResidualSubOrder": 4,
      "num": 234,
      "gen": 2,
      "desc": "At the end of every turn, holder restores 1/16 of its max HP."
    },
    "leppaberry": {
      "name": "Leppa Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Fighting"},
      "num": 154,
      "gen": 3,
      "desc": "Restores 10 PP to the first of the holder's moves to reach 0 PP. Single use."
    },
    "levelball": {
      "name": "Level Ball",
      "num": 493,
      "gen": 2,
      "isPokeball": true,
      "desc": "A Poke Ball for catching Pokemon that are a lower level than your own."
    },
    "liechiberry": {
      "name": "Liechi Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Grass"},
      "num": 201,
      "gen": 3,
      "desc": "Raises holder's Attack by 1 stage when at 1/4 max HP or less. Single use."
    },
    "lifeorb": {
      "name": "Life Orb",
      "fling": {"basePower": 30},
      "num": 270,
      "gen": 4,
      "desc": "Holder's attacks do 1.3x damage, and it loses 1/10 its max HP after the attack."
    },
    "lightball": {
      "name": "Light Ball",
      "fling": {"basePower": 30, "status": "par"},
      "onModifyAtkPriority": 1,
      "onModifySpAPriority": 1,
      "itemUser": [
        "Pikachu",
        "Pikachu-Cosplay",
        "Pikachu-Rock-Star",
        "Pikachu-Belle",
        "Pikachu-Pop-Star",
        "Pikachu-PhD",
        "Pikachu-Libre",
        "Pikachu-Original",
        "Pikachu-Hoenn",
        "Pikachu-Sinnoh",
        "Pikachu-Unova",
        "Pikachu-Kalos",
        "Pikachu-Alola",
        "Pikachu-Partner",
        "Pikachu-Starter",
        "Pikachu-World"
      ],
      "num": 236,
      "gen": 2,
      "desc": "If held by a Pikachu, its Attack and Sp. Atk are doubled."
    },
    "lightclay": {
      "name": "Light Clay",
      "fling": {"basePower": 30},
      "num": 269,
      "gen": 4,
      "desc": "Holder's use of Aurora Veil, Light Screen, or Reflect lasts 8 turns instead of 5."
    },
    "loadeddice": {
      "name": "Loaded Dice",
      "fling": {"basePower": 30},
      "num": 1886,
      "gen": 9,
      "desc": "Holder's moves that hit 2-5 times hit 4-5 times; Population Bomb hits 4-10 times."
    },
    "lopunnite": {
      "name": "Lopunnite",
      "megaStone": "Lopunny-Mega",
      "megaEvolves": "Lopunny",
      "itemUser": ["Lopunny"],
      "num": 768,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Lopunny, this item allows it to Mega Evolve in battle."
    },
    "loveball": {
      "name": "Love Ball",
      "num": 496,
      "gen": 2,
      "isPokeball": true,
      "desc": "Poke Ball for catching Pokemon that are the opposite gender of your Pokemon."
    },
    "lovesweet": {
      "name": "Love Sweet",
      "fling": {"basePower": 10},
      "num": 1110,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Evolves Milcery into Alcremie when held and spun around."
    },
    "lucarionite": {
      "name": "Lucarionite",
      "megaStone": "Lucario-Mega",
      "megaEvolves": "Lucario",
      "itemUser": ["Lucario"],
      "num": 673,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Lucario, this item allows it to Mega Evolve in battle."
    },
    "luckypunch": {
      "name": "Lucky Punch",
      "fling": {"basePower": 40},
      "itemUser": ["Chansey"],
      "num": 256,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "If held by a Chansey, its critical hit ratio is raised by 2 stages."
    },
    "lumberry": {
      "name": "Lum Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Flying"},
      "onAfterSetStatusPriority": -1,
      "num": 157,
      "gen": 3,
      "desc": "Holder cures itself if it has a non-volatile status or is confused. Single use."
    },
    "luminousmoss": {
      "name": "Luminous Moss",
      "fling": {"basePower": 30},
      "boosts": {"spd": 1},
      "num": 648,
      "gen": 6,
      "desc": "Raises holder's Sp. Def by 1 stage if hit by a Water-type attack. Single use."
    },
    "lunaliumz": {
      "name": "Lunalium Z",
      "onTakeItem": false,
      "zMove": "Menacing Moonraze Maelstrom",
      "zMoveFrom": "Moongeist Beam",
      "itemUser": ["Lunala", "Necrozma-Dawn-Wings"],
      "num": 922,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Lunala or Dawn Wings Necrozma with Moongeist Beam can use a special Z-Move."
    },
    "lureball": {
      "name": "Lure Ball",
      "num": 494,
      "gen": 2,
      "isPokeball": true,
      "desc": "A Poke Ball for catching Pokemon hooked by a Rod when fishing."
    },
    "lustrousglobe": {
      "name": "Lustrous Globe",
      "onBasePowerPriority": 15,
      "forcedForme": "Palkia-Origin",
      "itemUser": ["Palkia-Origin"],
      "num": 1778,
      "gen": 8,
      "desc": "If held by a Palkia, its Water- and Dragon-type attacks have 1.2x power."
    },
    "lustrousorb": {
      "name": "Lustrous Orb",
      "fling": {"basePower": 60},
      "onBasePowerPriority": 15,
      "itemUser": ["Palkia"],
      "num": 136,
      "gen": 4,
      "desc": "If held by a Palkia, its Water- and Dragon-type attacks have 1.2x power."
    },
    "luxuryball": {
      "name": "Luxury Ball",
      "num": 11,
      "gen": 3,
      "isPokeball": true,
      "desc": "A comfortable Poke Ball that makes a caught wild Pokemon quickly grow friendly."
    },
    "lycaniumz": {
      "name": "Lycanium Z",
      "onTakeItem": false,
      "zMove": "Splintered Stormshards",
      "zMoveFrom": "Stone Edge",
      "itemUser": ["Lycanroc", "Lycanroc-Midnight", "Lycanroc-Dusk"],
      "num": 925,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by a Lycanroc forme with Stone Edge, it can use Splintered Stormshards."
    },
    "machobrace": {
      "name": "Macho Brace",
      "ignoreKlutz": true,
      "fling": {"basePower": 60},
      "num": 215,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Holder's Speed is halved. The Klutz Ability does not ignore this effect."
    },
    "magmarizer": {
      "name": "Magmarizer",
      "fling": {"basePower": 80},
      "num": 323,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Evolves Magmar into Magmortar when traded."
    },
    "magnet": {
      "name": "Magnet",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 242,
      "gen": 2,
      "desc": "Holder's Electric-type attacks have 1.2x power."
    },
    "magoberry": {
      "name": "Mago Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Ghost"},
      "num": 161,
      "gen": 3,
      "desc": "Restores 1/3 max HP at 1/4 max HP or less; confuses if -Spe Nature. Single use."
    },
    "magostberry": {
      "name": "Magost Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Rock"},
      "onEat": false,
      "num": 176,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "mail": {
      "name": "Mail",
      "num": 137,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "Cannot be given to or taken from a Pokemon, except by Covet/Knock Off/Thief."
    },
    "maliciousarmor": {
      "name": "Malicious Armor",
      "fling": {"basePower": 30},
      "num": 1861,
      "gen": 9,
      "desc": "Evolves Charcadet into Ceruledge when used."
    },
    "manectite": {
      "name": "Manectite",
      "megaStone": "Manectric-Mega",
      "megaEvolves": "Manectric",
      "itemUser": ["Manectric"],
      "num": 682,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Manectric, this item allows it to Mega Evolve in battle."
    },
    "marangaberry": {
      "name": "Maranga Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Dark"},
      "num": 688,
      "gen": 6,
      "desc": "Raises holder's Sp. Def by 1 stage after it is hit by a special attack. Single use."
    },
    "marshadiumz": {
      "name": "Marshadium Z",
      "onTakeItem": false,
      "zMove": "Soul-Stealing 7-Star Strike",
      "zMoveFrom": "Spectral Thief",
      "itemUser": ["Marshadow"],
      "num": 802,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by Marshadow with Spectral Thief, it can use Soul-Stealing 7-Star Strike."
    },
    "masterball": {
      "name": "Master Ball",
      "num": 1,
      "gen": 1,
      "isPokeball": true,
      "desc": "The best Ball with the ultimate performance. It will catch any wild Pokemon."
    },
    "masterpieceteacup": {
      "name": "Masterpiece Teacup",
      "fling": {"basePower": 80},
      "num": 2404,
      "gen": 9,
      "desc": "Evolves Poltchageist-Artisan into Sinistcha-Masterpiece when used."
    },
    "mawilite": {
      "name": "Mawilite",
      "megaStone": "Mawile-Mega",
      "megaEvolves": "Mawile",
      "itemUser": ["Mawile"],
      "num": 681,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Mawile, this item allows it to Mega Evolve in battle."
    },
    "meadowplate": {
      "name": "Meadow Plate",
      "onPlate": "Grass",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Grass",
      "num": 301,
      "gen": 4,
      "desc": "Holder's Grass-type attacks have 1.2x power. Judgment is Grass type."
    },
    "medichamite": {
      "name": "Medichamite",
      "megaStone": "Medicham-Mega",
      "megaEvolves": "Medicham",
      "itemUser": ["Medicham"],
      "num": 665,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Medicham, this item allows it to Mega Evolve in battle."
    },
    "mentalherb": {
      "name": "Mental Herb",
      "fling": {"basePower": 10},
      "num": 219,
      "gen": 3,
      "desc": "Cures holder of Attract, Disable, Encore, Heal Block, Taunt, Torment. Single use."
    },
    "metagrossite": {
      "name": "Metagrossite",
      "megaStone": "Metagross-Mega",
      "megaEvolves": "Metagross",
      "itemUser": ["Metagross"],
      "num": 758,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Metagross, this item allows it to Mega Evolve in battle."
    },
    "metalcoat": {
      "name": "Metal Coat",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 233,
      "gen": 2,
      "desc": "Holder's Steel-type attacks have 1.2x power. Evolves Onix into Steelix and Scyther into Scizor when traded.",
      "shortDesc": "Holder's Steel-type attacks have 1.2x power."
    },
    "metalpowder": {
      "name": "Metal Powder",
      "fling": {"basePower": 10},
      "onModifyDefPriority": 2,
      "itemUser": ["Ditto"],
      "num": 257,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "If held by a Ditto that hasn't Transformed, its Defense is doubled."
    },
    "metronome": {
      "name": "Metronome",
      "fling": {"basePower": 30},
      "condition": {"onTryMovePriority": -2},
      "num": 277,
      "gen": 4,
      "desc": "Damage of moves used on consecutive turns is increased. Max 2x after 5 turns."
    },
    "mewniumz": {
      "name": "Mewnium Z",
      "onTakeItem": false,
      "zMove": "Genesis Supernova",
      "zMoveFrom": "Psychic",
      "itemUser": ["Mew"],
      "num": 806,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by a Mew with Psychic, it can use Genesis Supernova."
    },
    "mewtwonitex": {
      "name": "Mewtwonite X",
      "megaStone": "Mewtwo-Mega-X",
      "megaEvolves": "Mewtwo",
      "itemUser": ["Mewtwo"],
      "num": 662,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Mewtwo, this item allows it to Mega Evolve in battle."
    },
    "mewtwonitey": {
      "name": "Mewtwonite Y",
      "megaStone": "Mewtwo-Mega-Y",
      "megaEvolves": "Mewtwo",
      "itemUser": ["Mewtwo"],
      "num": 663,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Mewtwo, this item allows it to Mega Evolve in battle."
    },
    "micleberry": {
      "name": "Micle Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Rock"},
      "condition": {"duration": 2},
      "num": 209,
      "gen": 4,
      "desc": "Holder's next move has 1.2x accuracy when at 1/4 max HP or less. Single use."
    },
    "mimikiumz": {
      "name": "Mimikium Z",
      "onTakeItem": false,
      "zMove": "Let's Snuggle Forever",
      "zMoveFrom": "Play Rough",
      "itemUser": [
        "Mimikyu",
        "Mimikyu-Busted",
        "Mimikyu-Totem",
        "Mimikyu-Busted-Totem"
      ],
      "num": 924,
      "isNonstandard": "Past",
      "gen": 7,
      "desc": "If held by a Mimikyu with Play Rough, it can use Let's Snuggle Forever."
    },
    "mindplate": {
      "name": "Mind Plate",
      "onPlate": "Psychic",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Psychic",
      "num": 307,
      "gen": 4,
      "desc": "Holder's Psychic-type attacks have 1.2x power. Judgment is Psychic type."
    },
    "miracleseed": {
      "name": "Miracle Seed",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 239,
      "gen": 2,
      "desc": "Holder's Grass-type attacks have 1.2x power."
    },
    "mirrorherb": {
      "name": "Mirror Herb",
      "fling": {"basePower": 30},
      "num": 1883,
      "gen": 9,
      "desc": "When an opposing Pokemon raises a stat stage, the holder copies it. Single use."
    },
    "mistyseed": {
      "name": "Misty Seed",
      "fling": {"basePower": 10},
      "boosts": {"spd": 1},
      "num": 883,
      "gen": 7,
      "desc": "If the terrain is Misty Terrain, raises holder's Sp. Def by 1 stage. Single use."
    },
    "moonball": {
      "name": "Moon Ball",
      "num": 498,
      "gen": 2,
      "isPokeball": true,
      "desc": "A Poke Ball for catching Pokemon that evolve using the Moon Stone."
    },
    "moonstone": {
      "name": "Moon Stone",
      "fling": {"basePower": 30},
      "num": 81,
      "gen": 1,
      "desc": "Evolves Nidorina into Nidoqueen, Nidorino into Nidoking, Clefairy into Clefable, Jigglypuff into Wigglytuff, Skitty into Delcatty, and Munna into Musharna when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "muscleband": {
      "name": "Muscle Band",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 16,
      "num": 266,
      "gen": 4,
      "desc": "Holder's physical attacks have 1.1x power."
    },
    "mysticwater": {
      "name": "Mystic Water",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 243,
      "gen": 2,
      "desc": "Holder's Water-type attacks have 1.2x power."
    },
    "nanabberry": {
      "name": "Nanab Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Water"},
      "onEat": false,
      "num": 166,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "nestball": {
      "name": "Nest Ball",
      "num": 8,
      "gen": 3,
      "isPokeball": true,
      "desc": "A Poke Ball that works especially well on weaker Pokemon in the wild."
    },
    "netball": {
      "name": "Net Ball",
      "num": 6,
      "gen": 3,
      "isPokeball": true,
      "desc": "A Poke Ball that works especially well on Water- and Bug-type Pokemon."
    },
    "nevermeltice": {
      "name": "Never-Melt Ice",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 246,
      "gen": 2,
      "desc": "Holder's Ice-type attacks have 1.2x power."
    },
    "nomelberry": {
      "name": "Nomel Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Dragon"},
      "onEat": false,
      "num": 178,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "normalgem": {
      "name": "Normal Gem",
      "isGem": true,
      "num": 564,
      "gen": 5,
      "desc": "Holder's first successful Normal-type attack will have 1.3x power. Single use."
    },
    "normaliumz": {
      "name": "Normalium Z",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Normal",
      "num": 776,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Normal move, this item allows it to use a Normal Z-Move."
    },
    "occaberry": {
      "name": "Occa Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Fire"},
      "num": 184,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Fire-type attack. Single use."
    },
    "oddincense": {
      "name": "Odd Incense",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 15,
      "num": 314,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Holder's Psychic-type attacks have 1.2x power."
    },
    "oldamber": {
      "name": "Old Amber",
      "fling": {"basePower": 100},
      "num": 103,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Can be revived into Aerodactyl."
    },
    "oranberry": {
      "name": "Oran Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Poison"},
      "num": 155,
      "gen": 3,
      "desc": "Restores 10 HP when at 1/2 max HP or less. Single use."
    },
    "ovalstone": {
      "name": "Oval Stone",
      "fling": {"basePower": 80},
      "num": 110,
      "gen": 4,
      "desc": "Evolves Happiny into Chansey when held and leveled up during the day."
    },
    "pamtreberry": {
      "name": "Pamtre Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Steel"},
      "onEat": false,
      "num": 180,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "parkball": {
      "name": "Park Ball",
      "num": 500,
      "gen": 4,
      "isPokeball": true,
      "isNonstandard": "Unobtainable",
      "desc": "A special Poke Ball for the Pal Park."
    },
    "passhoberry": {
      "name": "Passho Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Water"},
      "num": 185,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Water-type attack. Single use."
    },
    "payapaberry": {
      "name": "Payapa Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Psychic"},
      "num": 193,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Psychic-type attack. Single use."
    },
    "pechaberry": {
      "name": "Pecha Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Electric"},
      "num": 151,
      "gen": 3,
      "desc": "Holder is cured if it is poisoned. Single use."
    },
    "persimberry": {
      "name": "Persim Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Ground"},
      "num": 156,
      "gen": 3,
      "desc": "Holder is cured if it is confused. Single use."
    },
    "petayaberry": {
      "name": "Petaya Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Poison"},
      "num": 204,
      "gen": 3,
      "desc": "Raises holder's Sp. Atk by 1 stage when at 1/4 max HP or less. Single use."
    },
    "pidgeotite": {
      "name": "Pidgeotite",
      "megaStone": "Pidgeot-Mega",
      "megaEvolves": "Pidgeot",
      "itemUser": ["Pidgeot"],
      "num": 762,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Pidgeot, this item allows it to Mega Evolve in battle."
    },
    "pikaniumz": {
      "name": "Pikanium Z",
      "onTakeItem": false,
      "zMove": "Catastropika",
      "zMoveFrom": "Volt Tackle",
      "itemUser": ["Pikachu"],
      "num": 794,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by a Pikachu with Volt Tackle, it can use Catastropika."
    },
    "pikashuniumz": {
      "name": "Pikashunium Z",
      "onTakeItem": false,
      "zMove": "10,000,000 Volt Thunderbolt",
      "zMoveFrom": "Thunderbolt",
      "itemUser": [
        "Pikachu-Original",
        "Pikachu-Hoenn",
        "Pikachu-Sinnoh",
        "Pikachu-Unova",
        "Pikachu-Kalos",
        "Pikachu-Alola",
        "Pikachu-Partner"
      ],
      "num": 836,
      "isNonstandard": "Past",
      "gen": 7,
      "desc": "If held by cap Pikachu with Thunderbolt, it can use 10,000,000 Volt Thunderbolt."
    },
    "pinapberry": {
      "name": "Pinap Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Grass"},
      "onEat": false,
      "num": 168,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "pinsirite": {
      "name": "Pinsirite",
      "megaStone": "Pinsir-Mega",
      "megaEvolves": "Pinsir",
      "itemUser": ["Pinsir"],
      "num": 671,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Pinsir, this item allows it to Mega Evolve in battle."
    },
    "pixieplate": {
      "name": "Pixie Plate",
      "onPlate": "Fairy",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Fairy",
      "num": 644,
      "gen": 6,
      "desc": "Holder's Fairy-type attacks have 1.2x power. Judgment is Fairy type."
    },
    "plumefossil": {
      "name": "Plume Fossil",
      "fling": {"basePower": 100},
      "num": 573,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Can be revived into Archen."
    },
    "poisonbarb": {
      "name": "Poison Barb",
      "fling": {"basePower": 70, "status": "psn"},
      "onBasePowerPriority": 15,
      "num": 245,
      "gen": 2,
      "desc": "Holder's Poison-type attacks have 1.2x power."
    },
    "poisongem": {
      "name": "Poison Gem",
      "isGem": true,
      "num": 554,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Poison-type attack will have 1.3x power. Single use."
    },
    "poisonmemory": {
      "name": "Poison Memory",
      "onMemory": "Poison",
      "forcedForme": "Silvally-Poison",
      "itemUser": ["Silvally-Poison"],
      "num": 906,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Poison type."
    },
    "poisoniumz": {
      "name": "Poisonium Z",
      "onPlate": "Poison",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Poison",
      "forcedForme": "Arceus-Poison",
      "num": 783,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Poison move, this item allows it to use a Poison Z-Move."
    },
    "pokeball": {
      "name": "Poke Ball",
      "num": 4,
      "gen": 1,
      "isPokeball": true,
      "desc": "A device for catching wild Pokemon. It is designed as a capsule system."
    },
    "pomegberry": {
      "name": "Pomeg Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Ice"},
      "onEat": false,
      "num": 169,
      "gen": 3,
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "poweranklet": {
      "name": "Power Anklet",
      "ignoreKlutz": true,
      "fling": {"basePower": 70},
      "num": 293,
      "gen": 4,
      "desc": "Holder's Speed is halved. The Klutz Ability does not ignore this effect."
    },
    "powerband": {
      "name": "Power Band",
      "ignoreKlutz": true,
      "fling": {"basePower": 70},
      "num": 292,
      "gen": 4,
      "desc": "Holder's Speed is halved. The Klutz Ability does not ignore this effect."
    },
    "powerbelt": {
      "name": "Power Belt",
      "ignoreKlutz": true,
      "fling": {"basePower": 70},
      "num": 290,
      "gen": 4,
      "desc": "Holder's Speed is halved. The Klutz Ability does not ignore this effect."
    },
    "powerbracer": {
      "name": "Power Bracer",
      "ignoreKlutz": true,
      "fling": {"basePower": 70},
      "num": 289,
      "gen": 4,
      "desc": "Holder's Speed is halved. The Klutz Ability does not ignore this effect."
    },
    "powerherb": {
      "name": "Power Herb",
      "fling": {"basePower": 10},
      "num": 271,
      "gen": 4,
      "desc": "Holder's two-turn moves complete in one turn (except Sky Drop). Single use."
    },
    "powerlens": {
      "name": "Power Lens",
      "ignoreKlutz": true,
      "fling": {"basePower": 70},
      "num": 291,
      "gen": 4,
      "desc": "Holder's Speed is halved. The Klutz Ability does not ignore this effect."
    },
    "powerweight": {
      "name": "Power Weight",
      "ignoreKlutz": true,
      "fling": {"basePower": 70},
      "num": 294,
      "gen": 4,
      "desc": "Holder's Speed is halved. The Klutz Ability does not ignore this effect."
    },
    "premierball": {
      "name": "Premier Ball",
      "num": 12,
      "gen": 3,
      "isPokeball": true,
      "desc": "A rare Poke Ball that has been crafted to commemorate an event."
    },
    "primariumz": {
      "name": "Primarium Z",
      "onTakeItem": false,
      "zMove": "Oceanic Operetta",
      "zMoveFrom": "Sparkling Aria",
      "itemUser": ["Primarina"],
      "num": 800,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by a Primarina with Sparkling Aria, it can use Oceanic Operetta."
    },
    "prismscale": {
      "name": "Prism Scale",
      "fling": {"basePower": 30},
      "num": 537,
      "gen": 5,
      "desc": "Evolves Feebas into Milotic when traded."
    },
    "protectivepads": {
      "name": "Protective Pads",
      "fling": {"basePower": 30},
      "num": 880,
      "gen": 7,
      "desc": "Holder's moves are protected from adverse contact effects, except Pickpocket."
    },
    "protector": {
      "name": "Protector",
      "fling": {"basePower": 80},
      "num": 321,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Evolves Rhydon into Rhyperior when traded."
    },
    "psychicgem": {
      "name": "Psychic Gem",
      "isGem": true,
      "num": 557,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Psychic-type attack will have 1.3x power. Single use."
    },
    "psychicmemory": {
      "name": "Psychic Memory",
      "onMemory": "Psychic",
      "forcedForme": "Silvally-Psychic",
      "itemUser": ["Silvally-Psychic"],
      "num": 916,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Psychic type."
    },
    "psychicseed": {
      "name": "Psychic Seed",
      "fling": {"basePower": 10},
      "boosts": {"spd": 1},
      "num": 882,
      "gen": 7,
      "desc": "If the terrain is Psychic Terrain, raises holder's Sp. Def by 1 stage. Single use."
    },
    "psychiumz": {
      "name": "Psychium Z",
      "onPlate": "Psychic",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Psychic",
      "forcedForme": "Arceus-Psychic",
      "num": 786,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Psychic move, this item allows it to use a Psychic Z-Move."
    },
    "punchingglove": {
      "name": "Punching Glove",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 23,
      "onModifyMovePriority": 1,
      "num": 1884,
      "gen": 9,
      "desc": "Holder's punch-based attacks have 1.1x power and do not make contact."
    },
    "qualotberry": {
      "name": "Qualot Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Poison"},
      "onEat": false,
      "num": 171,
      "gen": 3,
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "quickball": {
      "name": "Quick Ball",
      "num": 15,
      "gen": 4,
      "isPokeball": true,
      "desc": "A Poke Ball that provides a better catch rate at the start of a wild encounter."
    },
    "quickclaw": {
      "onFractionalPriorityPriority": -2,
      "name": "Quick Claw",
      "fling": {"basePower": 80},
      "num": 217,
      "gen": 2,
      "desc": "Each turn, holder has a 20% chance to move first in its priority bracket."
    },
    "quickpowder": {
      "name": "Quick Powder",
      "fling": {"basePower": 10},
      "itemUser": ["Ditto"],
      "num": 274,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "If held by a Ditto that hasn't Transformed, its Speed is doubled."
    },
    "rabutaberry": {
      "name": "Rabuta Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Ghost"},
      "onEat": false,
      "num": 177,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "rarebone": {
      "name": "Rare Bone",
      "fling": {"basePower": 100},
      "num": 106,
      "gen": 4,
      "desc": "No competitive use other than when used with Fling."
    },
    "rawstberry": {
      "name": "Rawst Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Grass"},
      "num": 152,
      "gen": 3,
      "desc": "Holder is cured if it is burned. Single use."
    },
    "razorclaw": {
      "name": "Razor Claw",
      "fling": {"basePower": 80},
      "num": 326,
      "gen": 4,
      "desc": "Holder's critical hit ratio is raised by 1 stage. Evolves Sneasel into Weavile when held and leveled up during the night.",
      "shortDesc": "Holder's critical hit ratio is raised by 1 stage."
    },
    "razorfang": {
      "name": "Razor Fang",
      "fling": {"basePower": 30, "volatileStatus": "flinch"},
      "onModifyMovePriority": -1,
      "num": 327,
      "gen": 4,
      "desc": "Holder's attacks without a chance to make the target flinch gain a 10% chance to make the target flinch. Evolves Gligar into Gliscor when held and leveled up during the night.",
      "shortDesc": "Holder's attacks without a chance to flinch gain a 10% chance to flinch."
    },
    "razzberry": {
      "name": "Razz Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Steel"},
      "onEat": false,
      "num": 164,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "reapercloth": {
      "name": "Reaper Cloth",
      "fling": {"basePower": 10},
      "num": 325,
      "gen": 4,
      "desc": "Evolves Dusclops into Dusknoir when traded."
    },
    "redcard": {
      "name": "Red Card",
      "fling": {"basePower": 10},
      "num": 542,
      "gen": 5,
      "desc": "If holder survives a hit, attacker is forced to switch to a random ally. Single use."
    },
    "redorb": {
      "name": "Red Orb",
      "itemUser": ["Groudon"],
      "num": 534,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Groudon, this item triggers its Primal Reversion in battle."
    },
    "repeatball": {
      "name": "Repeat Ball",
      "num": 9,
      "gen": 3,
      "isPokeball": true,
      "desc": "A Poke Ball that works well on Pokemon species that were previously caught."
    },
    "ribbonsweet": {
      "name": "Ribbon Sweet",
      "fling": {"basePower": 10},
      "num": 1115,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Evolves Milcery into Alcremie when held and spun around."
    },
    "rindoberry": {
      "name": "Rindo Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Grass"},
      "num": 187,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Grass-type attack. Single use."
    },
    "ringtarget": {
      "name": "Ring Target",
      "fling": {"basePower": 10},
      "onNegateImmunity": false,
      "num": 543,
      "gen": 5,
      "desc": "The holder's type immunities granted solely by its typing are negated."
    },
    "rockgem": {
      "name": "Rock Gem",
      "isGem": true,
      "num": 559,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Rock-type attack will have 1.3x power. Single use."
    },
    "rockincense": {
      "name": "Rock Incense",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 15,
      "num": 315,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Holder's Rock-type attacks have 1.2x power."
    },
    "rockmemory": {
      "name": "Rock Memory",
      "onMemory": "Rock",
      "forcedForme": "Silvally-Rock",
      "itemUser": ["Silvally-Rock"],
      "num": 908,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Rock type."
    },
    "rockiumz": {
      "name": "Rockium Z",
      "onPlate": "Rock",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Rock",
      "forcedForme": "Arceus-Rock",
      "num": 788,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Rock move, this item allows it to use a Rock Z-Move."
    },
    "rockyhelmet": {
      "name": "Rocky Helmet",
      "fling": {"basePower": 60},
      "onDamagingHitOrder": 2,
      "num": 540,
      "gen": 5,
      "desc": "If holder is hit by a contact move, the attacker loses 1/6 of its max HP."
    },
    "roomservice": {
      "name": "Room Service",
      "fling": {"basePower": 100},
      "boosts": {"spe": -1},
      "num": 1122,
      "gen": 8,
      "desc": "If Trick Room is active, the holder's Speed is lowered by 1 stage. Single use."
    },
    "rootfossil": {
      "name": "Root Fossil",
      "fling": {"basePower": 100},
      "num": 99,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Can be revived into Lileep."
    },
    "roseincense": {
      "name": "Rose Incense",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 15,
      "num": 318,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Holder's Grass-type attacks have 1.2x power."
    },
    "roseliberry": {
      "name": "Roseli Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Fairy"},
      "num": 686,
      "gen": 6,
      "desc": "Halves damage taken from a supereffective Fairy-type attack. Single use."
    },
    "rowapberry": {
      "name": "Rowap Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Dark"},
      "num": 212,
      "gen": 4,
      "desc": "If holder is hit by a special move, attacker loses 1/8 of its max HP. Single use."
    },
    "rustedshield": {
      "name": "Rusted Shield",
      "itemUser": ["Zamazenta-Crowned"],
      "num": 1104,
      "gen": 8,
      "desc": "If held by a Zamazenta, this item changes its forme to Crowned Shield."
    },
    "rustedsword": {
      "name": "Rusted Sword",
      "itemUser": ["Zacian-Crowned"],
      "num": 1103,
      "gen": 8,
      "desc": "If held by a Zacian, this item changes its forme to Crowned Sword."
    },
    "sablenite": {
      "name": "Sablenite",
      "megaStone": "Sableye-Mega",
      "megaEvolves": "Sableye",
      "itemUser": ["Sableye"],
      "num": 754,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Sableye, this item allows it to Mega Evolve in battle."
    },
    "sachet": {
      "name": "Sachet",
      "fling": {"basePower": 80},
      "num": 647,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "Evolves Spritzee into Aromatisse when traded."
    },
    "safariball": {
      "name": "Safari Ball",
      "num": 5,
      "gen": 1,
      "isPokeball": true,
      "isNonstandard": "Unobtainable",
      "desc": "A special Poke Ball that is used only in the Safari Zone and Great Marsh."
    },
    "safetygoggles": {
      "name": "Safety Goggles",
      "fling": {"basePower": 80},
      "num": 650,
      "gen": 6,
      "desc": "Holder is immune to powder moves and damage from Sandstorm or Hail."
    },
    "sailfossil": {
      "name": "Sail Fossil",
      "fling": {"basePower": 100},
      "num": 711,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "Can be revived into Amaura."
    },
    "salacberry": {
      "name": "Salac Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Fighting"},
      "num": 203,
      "gen": 3,
      "desc": "Raises holder's Speed by 1 stage when at 1/4 max HP or less. Single use."
    },
    "salamencite": {
      "name": "Salamencite",
      "megaStone": "Salamence-Mega",
      "megaEvolves": "Salamence",
      "itemUser": ["Salamence"],
      "num": 769,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Salamence, this item allows it to Mega Evolve in battle."
    },
    "sceptilite": {
      "name": "Sceptilite",
      "megaStone": "Sceptile-Mega",
      "megaEvolves": "Sceptile",
      "itemUser": ["Sceptile"],
      "num": 753,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Sceptile, this item allows it to Mega Evolve in battle."
    },
    "scizorite": {
      "name": "Scizorite",
      "megaStone": "Scizor-Mega",
      "megaEvolves": "Scizor",
      "itemUser": ["Scizor"],
      "num": 670,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Scizor, this item allows it to Mega Evolve in battle."
    },
    "scopelens": {
      "name": "Scope Lens",
      "fling": {"basePower": 30},
      "num": 232,
      "gen": 2,
      "desc": "Holder's critical hit ratio is raised by 1 stage."
    },
    "seaincense": {
      "name": "Sea Incense",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 15,
      "num": 254,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Holder's Water-type attacks have 1.2x power."
    },
    "sharpbeak": {
      "name": "Sharp Beak",
      "fling": {"basePower": 50},
      "onBasePowerPriority": 15,
      "num": 244,
      "gen": 2,
      "desc": "Holder's Flying-type attacks have 1.2x power."
    },
    "sharpedonite": {
      "name": "Sharpedonite",
      "megaStone": "Sharpedo-Mega",
      "megaEvolves": "Sharpedo",
      "itemUser": ["Sharpedo"],
      "num": 759,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Sharpedo, this item allows it to Mega Evolve in battle."
    },
    "shedshell": {
      "name": "Shed Shell",
      "fling": {"basePower": 10},
      "onTrapPokemonPriority": -10,
      "num": 295,
      "gen": 4,
      "desc": "Holder may switch out even when trapped by another Pokemon, or by Ingrain."
    },
    "shellbell": {
      "name": "Shell Bell",
      "fling": {"basePower": 30},
      "onAfterMoveSecondarySelfPriority": -1,
      "num": 253,
      "gen": 3,
      "desc": "After an attack, holder gains 1/8 of the damage in HP dealt to other Pokemon."
    },
    "shinystone": {
      "name": "Shiny Stone",
      "fling": {"basePower": 80},
      "num": 107,
      "gen": 4,
      "desc": "Evolves Togetic into Togekiss, Roselia into Roserade, Minccino into Cinccino, and Floette into Florges when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "shockdrive": {
      "name": "Shock Drive",
      "onDrive": "Electric",
      "forcedForme": "Genesect-Shock",
      "itemUser": ["Genesect-Shock"],
      "num": 117,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's Techno Blast is Electric type."
    },
    "shucaberry": {
      "name": "Shuca Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Ground"},
      "num": 191,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Ground-type attack. Single use."
    },
    "silkscarf": {
      "name": "Silk Scarf",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 15,
      "num": 251,
      "gen": 3,
      "desc": "Holder's Normal-type attacks have 1.2x power."
    },
    "silverpowder": {
      "name": "Silver Powder",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 15,
      "num": 222,
      "gen": 2,
      "desc": "Holder's Bug-type attacks have 1.2x power."
    },
    "sitrusberry": {
      "name": "Sitrus Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Psychic"},
      "num": 158,
      "gen": 3,
      "desc": "Restores 1/4 max HP when at 1/2 max HP or less. Single use."
    },
    "skullfossil": {
      "name": "Skull Fossil",
      "fling": {"basePower": 100},
      "num": 105,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Can be revived into Cranidos."
    },
    "skyplate": {
      "name": "Sky Plate",
      "onPlate": "Flying",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Flying",
      "num": 306,
      "gen": 4,
      "desc": "Holder's Flying-type attacks have 1.2x power. Judgment is Flying type."
    },
    "slowbronite": {
      "name": "Slowbronite",
      "megaStone": "Slowbro-Mega",
      "megaEvolves": "Slowbro",
      "itemUser": ["Slowbro"],
      "num": 760,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Slowbro, this item allows it to Mega Evolve in battle."
    },
    "smoothrock": {
      "name": "Smooth Rock",
      "fling": {"basePower": 10},
      "num": 283,
      "gen": 4,
      "desc": "Holder's use of Sandstorm lasts 8 turns instead of 5."
    },
    "snorliumz": {
      "name": "Snorlium Z",
      "onTakeItem": false,
      "zMove": "Pulverizing Pancake",
      "zMoveFrom": "Giga Impact",
      "itemUser": ["Snorlax"],
      "num": 804,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by a Snorlax with Giga Impact, it can use Pulverizing Pancake."
    },
    "snowball": {
      "name": "Snowball",
      "fling": {"basePower": 30},
      "boosts": {"atk": 1},
      "num": 649,
      "gen": 6,
      "desc": "Raises holder's Attack by 1 if hit by an Ice-type attack. Single use."
    },
    "softsand": {
      "name": "Soft Sand",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 15,
      "num": 237,
      "gen": 2,
      "desc": "Holder's Ground-type attacks have 1.2x power."
    },
    "solganiumz": {
      "name": "Solganium Z",
      "onTakeItem": false,
      "zMove": "Searing Sunraze Smash",
      "zMoveFrom": "Sunsteel Strike",
      "itemUser": ["Solgaleo", "Necrozma-Dusk-Mane"],
      "num": 921,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Solgaleo or Dusk Mane Necrozma with Sunsteel Strike can use a special Z-Move."
    },
    "souldew": {
      "name": "Soul Dew",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "itemUser": ["Latios", "Latias"],
      "num": 225,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "If held by a Latias/Latios, its Dragon- and Psychic-type moves have 1.2x power."
    },
    "spelltag": {
      "name": "Spell Tag",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 247,
      "gen": 2,
      "desc": "Holder's Ghost-type attacks have 1.2x power."
    },
    "spelonberry": {
      "name": "Spelon Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Dark"},
      "onEat": false,
      "num": 179,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "splashplate": {
      "name": "Splash Plate",
      "onPlate": "Water",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Water",
      "num": 299,
      "gen": 4,
      "desc": "Holder's Water-type attacks have 1.2x power. Judgment is Water type."
    },
    "spookyplate": {
      "name": "Spooky Plate",
      "onPlate": "Ghost",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Ghost",
      "num": 310,
      "gen": 4,
      "desc": "Holder's Ghost-type attacks have 1.2x power. Judgment is Ghost type."
    },
    "sportball": {
      "name": "Sport Ball",
      "num": 499,
      "gen": 2,
      "isPokeball": true,
      "isNonstandard": "Unobtainable",
      "desc": "A special Poke Ball for the Bug-Catching Contest."
    },
    "starfberry": {
      "name": "Starf Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Psychic"},
      "num": 207,
      "gen": 3,
      "desc": "Raises a random stat by 2 when at 1/4 max HP or less (not acc/eva). Single use."
    },
    "starsweet": {
      "name": "Star Sweet",
      "fling": {"basePower": 10},
      "num": 1114,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Evolves Milcery into Alcremie when held and spun around."
    },
    "steelixite": {
      "name": "Steelixite",
      "megaStone": "Steelix-Mega",
      "megaEvolves": "Steelix",
      "itemUser": ["Steelix"],
      "num": 761,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Steelix, this item allows it to Mega Evolve in battle."
    },
    "steelgem": {
      "name": "Steel Gem",
      "isGem": true,
      "num": 563,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Steel-type attack will have 1.3x power. Single use."
    },
    "steelmemory": {
      "name": "Steel Memory",
      "onMemory": "Steel",
      "forcedForme": "Silvally-Steel",
      "itemUser": ["Silvally-Steel"],
      "num": 911,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Steel type."
    },
    "steeliumz": {
      "name": "Steelium Z",
      "onPlate": "Steel",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Steel",
      "forcedForme": "Arceus-Steel",
      "num": 792,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Steel move, this item allows it to use a Steel Z-Move."
    },
    "stick": {
      "name": "Stick",
      "fling": {"basePower": 60},
      "itemUser": ["Farfetch’d"],
      "num": 259,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "If held by a Farfetch’d, its critical hit ratio is raised by 2 stages."
    },
    "stickybarb": {
      "name": "Sticky Barb",
      "fling": {"basePower": 80},
      "onResidualOrder": 28,
      "onResidualSubOrder": 3,
      "num": 288,
      "gen": 4,
      "desc": "Each turn, holder loses 1/8 max HP. An attacker making contact can receive it."
    },
    "stoneplate": {
      "name": "Stone Plate",
      "onPlate": "Rock",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Rock",
      "num": 309,
      "gen": 4,
      "desc": "Holder's Rock-type attacks have 1.2x power. Judgment is Rock type."
    },
    "strangeball": {
      "name": "Strange Ball",
      "num": 1785,
      "gen": 8,
      "isPokeball": true,
      "isNonstandard": "Unobtainable"
    },
    "strawberrysweet": {
      "name": "Strawberry Sweet",
      "fling": {"basePower": 10},
      "num": 1109,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Evolves Milcery into Alcremie when held and spun around."
    },
    "sunstone": {
      "name": "Sun Stone",
      "fling": {"basePower": 30},
      "num": 80,
      "gen": 2,
      "desc": "Evolves Gloom into Bellossom, Sunkern into Sunflora, Cottonee into Whimsicott, Petilil into Lilligant, and Helioptile into Heliolisk when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "swampertite": {
      "name": "Swampertite",
      "megaStone": "Swampert-Mega",
      "megaEvolves": "Swampert",
      "itemUser": ["Swampert"],
      "num": 752,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Swampert, this item allows it to Mega Evolve in battle."
    },
    "sweetapple": {
      "name": "Sweet Apple",
      "fling": {"basePower": 30},
      "num": 1116,
      "gen": 8,
      "desc": "Evolves Applin into Appletun when used."
    },
    "syrupyapple": {
      "name": "Syrupy Apple",
      "fling": {"basePower": 30},
      "num": 2402,
      "gen": 9,
      "desc": "Evolves Applin into Dipplin when used."
    },
    "tamatoberry": {
      "name": "Tamato Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Psychic"},
      "onEat": false,
      "num": 174,
      "gen": 3,
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "tangaberry": {
      "name": "Tanga Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Bug"},
      "num": 194,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Bug-type attack. Single use."
    },
    "tapuniumz": {
      "name": "Tapunium Z",
      "onTakeItem": false,
      "zMove": "Guardian of Alola",
      "zMoveFrom": "Nature's Madness",
      "itemUser": ["Tapu Koko", "Tapu Lele", "Tapu Bulu", "Tapu Fini"],
      "num": 801,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If held by a Tapu with Nature's Madness, it can use Guardian of Alola."
    },
    "tartapple": {
      "name": "Tart Apple",
      "fling": {"basePower": 30},
      "num": 1117,
      "gen": 8,
      "desc": "Evolves Applin into Flapple when used."
    },
    "terrainextender": {
      "name": "Terrain Extender",
      "fling": {"basePower": 60},
      "num": 879,
      "gen": 7,
      "desc": "Holder's use of Electric/Grassy/Misty/Psychic Terrain lasts 8 turns instead of 5."
    },
    "thickclub": {
      "name": "Thick Club",
      "fling": {"basePower": 90},
      "onModifyAtkPriority": 1,
      "itemUser": ["Marowak", "Marowak-Alola", "Marowak-Alola-Totem", "Cubone"],
      "num": 258,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "If held by a Cubone or a Marowak, its Attack is doubled."
    },
    "throatspray": {
      "name": "Throat Spray",
      "fling": {"basePower": 30},
      "boosts": {"spa": 1},
      "num": 1118,
      "gen": 8,
      "desc": "Raises holder's Special Attack by 1 stage after it uses a sound move. Single use."
    },
    "thunderstone": {
      "name": "Thunder Stone",
      "fling": {"basePower": 30},
      "num": 83,
      "gen": 1,
      "desc": "Evolves Pikachu into Raichu or Alolan Raichu, Eevee into Jolteon, Eelektrik into Eelektross, and Charjabug into Vikavolt when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "timerball": {
      "name": "Timer Ball",
      "num": 10,
      "gen": 3,
      "isPokeball": true,
      "desc": "A Poke Ball that becomes better the more turns there are in a battle."
    },
    "toxicorb": {
      "name": "Toxic Orb",
      "fling": {"basePower": 30, "status": "tox"},
      "onResidualOrder": 28,
      "onResidualSubOrder": 3,
      "num": 272,
      "gen": 4,
      "desc": "At the end of every turn, this item attempts to badly poison the holder."
    },
    "toxicplate": {
      "name": "Toxic Plate",
      "onPlate": "Poison",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Poison",
      "num": 304,
      "gen": 4,
      "desc": "Holder's Poison-type attacks have 1.2x power. Judgment is Poison type."
    },
    "tr00": {
      "name": "TR00",
      "fling": {"basePower": 10},
      "num": 1130,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Swords Dance. One use."
    },
    "tr01": {
      "name": "TR01",
      "fling": {"basePower": 85},
      "num": 1131,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Body Slam. One use."
    },
    "tr02": {
      "name": "TR02",
      "fling": {"basePower": 90},
      "num": 1132,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Flamethrower. One use."
    },
    "tr03": {
      "name": "TR03",
      "fling": {"basePower": 110},
      "num": 1133,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Hydro Pump. One use."
    },
    "tr04": {
      "name": "TR04",
      "fling": {"basePower": 90},
      "num": 1134,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Surf. One use."
    },
    "tr05": {
      "name": "TR05",
      "fling": {"basePower": 90},
      "num": 1135,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Ice Beam. One use."
    },
    "tr06": {
      "name": "TR06",
      "fling": {"basePower": 110},
      "num": 1136,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Blizzard. One use."
    },
    "tr07": {
      "name": "TR07",
      "fling": {"basePower": 10},
      "num": 1137,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Low Kick. One use."
    },
    "tr08": {
      "name": "TR08",
      "fling": {"basePower": 90},
      "num": 1138,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Thunderbolt. One use."
    },
    "tr09": {
      "name": "TR09",
      "fling": {"basePower": 110},
      "num": 1139,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Thunder. One use."
    },
    "tr10": {
      "name": "TR10",
      "fling": {"basePower": 100},
      "num": 1140,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Earthquake. One use."
    },
    "tr11": {
      "name": "TR11",
      "fling": {"basePower": 90},
      "num": 1141,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Psychic. One use."
    },
    "tr12": {
      "name": "TR12",
      "fling": {"basePower": 10},
      "num": 1142,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Agility. One use."
    },
    "tr13": {
      "name": "TR13",
      "fling": {"basePower": 10},
      "num": 1143,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Focus Energy. One use."
    },
    "tr14": {
      "name": "TR14",
      "fling": {"basePower": 10},
      "num": 1144,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Metronome. One use."
    },
    "tr15": {
      "name": "TR15",
      "fling": {"basePower": 110},
      "num": 1145,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Fire Blast. One use."
    },
    "tr16": {
      "name": "TR16",
      "fling": {"basePower": 80},
      "num": 1146,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Waterfall. One use."
    },
    "tr17": {
      "name": "TR17",
      "fling": {"basePower": 10},
      "num": 1147,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Amnesia. One use."
    },
    "tr18": {
      "name": "TR18",
      "fling": {"basePower": 80},
      "num": 1148,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Leech Life. One use."
    },
    "tr19": {
      "name": "TR19",
      "fling": {"basePower": 80},
      "num": 1149,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Tri Attack. One use."
    },
    "tr20": {
      "name": "TR20",
      "fling": {"basePower": 10},
      "num": 1150,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Substitute. One use."
    },
    "tr21": {
      "name": "TR21",
      "fling": {"basePower": 10},
      "num": 1151,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Reversal. One use."
    },
    "tr22": {
      "name": "TR22",
      "fling": {"basePower": 90},
      "num": 1152,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Sludge Bomb. One use."
    },
    "tr23": {
      "name": "TR23",
      "fling": {"basePower": 10},
      "num": 1153,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Spikes. One use."
    },
    "tr24": {
      "name": "TR24",
      "fling": {"basePower": 120},
      "num": 1154,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Outrage. One use."
    },
    "tr25": {
      "name": "TR25",
      "fling": {"basePower": 80},
      "num": 1155,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Psyshock. One use."
    },
    "tr26": {
      "name": "TR26",
      "fling": {"basePower": 10},
      "num": 1156,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Endure. One use."
    },
    "tr27": {
      "name": "TR27",
      "fling": {"basePower": 10},
      "num": 1157,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Sleep Talk. One use."
    },
    "tr28": {
      "name": "TR28",
      "fling": {"basePower": 120},
      "num": 1158,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Megahorn. One use."
    },
    "tr29": {
      "name": "TR29",
      "fling": {"basePower": 10},
      "num": 1159,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Baton Pass. One use."
    },
    "tr30": {
      "name": "TR30",
      "fling": {"basePower": 10},
      "num": 1160,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Encore. One use."
    },
    "tr31": {
      "name": "TR31",
      "fling": {"basePower": 100},
      "num": 1161,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Iron Tail. One use."
    },
    "tr32": {
      "name": "TR32",
      "fling": {"basePower": 80},
      "num": 1162,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Crunch. One use."
    },
    "tr33": {
      "name": "TR33",
      "fling": {"basePower": 80},
      "num": 1163,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Shadow Ball. One use."
    },
    "tr34": {
      "name": "TR34",
      "fling": {"basePower": 120},
      "num": 1164,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Future Sight. One use."
    },
    "tr35": {
      "name": "TR35",
      "fling": {"basePower": 90},
      "num": 1165,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Uproar. One use."
    },
    "tr36": {
      "name": "TR36",
      "fling": {"basePower": 95},
      "num": 1166,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Heat Wave. One use."
    },
    "tr37": {
      "name": "TR37",
      "fling": {"basePower": 10},
      "num": 1167,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Taunt. One use."
    },
    "tr38": {
      "name": "TR38",
      "fling": {"basePower": 10},
      "num": 1168,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Trick. One use."
    },
    "tr39": {
      "name": "TR39",
      "fling": {"basePower": 120},
      "num": 1169,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Superpower. One use."
    },
    "tr40": {
      "name": "TR40",
      "fling": {"basePower": 10},
      "num": 1170,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Skill Swap. One use."
    },
    "tr41": {
      "name": "TR41",
      "fling": {"basePower": 85},
      "num": 1171,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Blaze Kick. One use."
    },
    "tr42": {
      "name": "TR42",
      "fling": {"basePower": 90},
      "num": 1172,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Hyper Voice. One use."
    },
    "tr43": {
      "name": "TR43",
      "fling": {"basePower": 130},
      "num": 1173,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Overheat. One use."
    },
    "tr44": {
      "name": "TR44",
      "fling": {"basePower": 10},
      "num": 1174,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Cosmic Power. One use."
    },
    "tr45": {
      "name": "TR45",
      "fling": {"basePower": 90},
      "num": 1175,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Muddy Water. One use."
    },
    "tr46": {
      "name": "TR46",
      "fling": {"basePower": 10},
      "num": 1176,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Iron Defense. One use."
    },
    "tr47": {
      "name": "TR47",
      "fling": {"basePower": 80},
      "num": 1177,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Dragon Claw. One use."
    },
    "tr48": {
      "name": "TR48",
      "fling": {"basePower": 10},
      "num": 1178,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Bulk Up. One use."
    },
    "tr49": {
      "name": "TR49",
      "fling": {"basePower": 10},
      "num": 1179,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Calm Mind. One use."
    },
    "tr50": {
      "name": "TR50",
      "fling": {"basePower": 90},
      "num": 1180,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Leaf Blade. One use."
    },
    "tr51": {
      "name": "TR51",
      "fling": {"basePower": 10},
      "num": 1181,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Dragon Dance. One use."
    },
    "tr52": {
      "name": "TR52",
      "fling": {"basePower": 10},
      "num": 1182,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Gyro Ball. One use."
    },
    "tr53": {
      "name": "TR53",
      "fling": {"basePower": 120},
      "num": 1183,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Close Combat. One use."
    },
    "tr54": {
      "name": "TR54",
      "fling": {"basePower": 10},
      "num": 1184,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Toxic Spikes. One use."
    },
    "tr55": {
      "name": "TR55",
      "fling": {"basePower": 120},
      "num": 1185,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Flare Blitz. One use."
    },
    "tr56": {
      "name": "TR56",
      "fling": {"basePower": 80},
      "num": 1186,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Aura Sphere. One use."
    },
    "tr57": {
      "name": "TR57",
      "fling": {"basePower": 80},
      "num": 1187,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Poison Jab. One use."
    },
    "tr58": {
      "name": "TR58",
      "fling": {"basePower": 80},
      "num": 1188,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Dark Pulse. One use."
    },
    "tr59": {
      "name": "TR59",
      "fling": {"basePower": 80},
      "num": 1189,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Seed Bomb. One use."
    },
    "tr60": {
      "name": "TR60",
      "fling": {"basePower": 80},
      "num": 1190,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move X-Scissor. One use."
    },
    "tr61": {
      "name": "TR61",
      "fling": {"basePower": 90},
      "num": 1191,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Bug Buzz. One use."
    },
    "tr62": {
      "name": "TR62",
      "fling": {"basePower": 85},
      "num": 1192,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Dragon Pulse. One use."
    },
    "tr63": {
      "name": "TR63",
      "fling": {"basePower": 80},
      "num": 1193,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Power Gem. One use."
    },
    "tr64": {
      "name": "TR64",
      "fling": {"basePower": 120},
      "num": 1194,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Focus Blast. One use."
    },
    "tr65": {
      "name": "TR65",
      "fling": {"basePower": 90},
      "num": 1195,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Energy Ball. One use."
    },
    "tr66": {
      "name": "TR66",
      "fling": {"basePower": 120},
      "num": 1196,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Brave Bird. One use."
    },
    "tr67": {
      "name": "TR67",
      "fling": {"basePower": 90},
      "num": 1197,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Earth Power. One use."
    },
    "tr68": {
      "name": "TR68",
      "fling": {"basePower": 10},
      "num": 1198,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Nasty Plot. One use."
    },
    "tr69": {
      "name": "TR69",
      "fling": {"basePower": 80},
      "num": 1199,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Zen Headbutt. One use."
    },
    "tr70": {
      "name": "TR70",
      "fling": {"basePower": 80},
      "num": 1200,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Flash Cannon. One use."
    },
    "tr71": {
      "name": "TR71",
      "fling": {"basePower": 130},
      "num": 1201,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Leaf Storm. One use."
    },
    "tr72": {
      "name": "TR72",
      "fling": {"basePower": 120},
      "num": 1202,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Power Whip. One use."
    },
    "tr73": {
      "name": "TR73",
      "fling": {"basePower": 120},
      "num": 1203,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Gunk Shot. One use."
    },
    "tr74": {
      "name": "TR74",
      "fling": {"basePower": 80},
      "num": 1204,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Iron Head. One use."
    },
    "tr75": {
      "name": "TR75",
      "fling": {"basePower": 100},
      "num": 1205,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Stone Edge. One use."
    },
    "tr76": {
      "name": "TR76",
      "fling": {"basePower": 10},
      "num": 1206,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Stealth Rock. One use."
    },
    "tr77": {
      "name": "TR77",
      "fling": {"basePower": 10},
      "num": 1207,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Grass Knot. One use."
    },
    "tr78": {
      "name": "TR78",
      "fling": {"basePower": 95},
      "num": 1208,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Sludge Wave. One use."
    },
    "tr79": {
      "name": "TR79",
      "fling": {"basePower": 10},
      "num": 1209,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Heavy Slam. One use."
    },
    "tr80": {
      "name": "TR80",
      "fling": {"basePower": 10},
      "num": 1210,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Electro Ball. One use."
    },
    "tr81": {
      "name": "TR81",
      "fling": {"basePower": 95},
      "num": 1211,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Foul Play. One use."
    },
    "tr82": {
      "name": "TR82",
      "fling": {"basePower": 20},
      "num": 1212,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Stored Power. One use."
    },
    "tr83": {
      "name": "TR83",
      "fling": {"basePower": 10},
      "num": 1213,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Ally Switch. One use."
    },
    "tr84": {
      "name": "TR84",
      "fling": {"basePower": 80},
      "num": 1214,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Scald. One use."
    },
    "tr85": {
      "name": "TR85",
      "fling": {"basePower": 10},
      "num": 1215,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Work Up. One use."
    },
    "tr86": {
      "name": "TR86",
      "fling": {"basePower": 90},
      "num": 1216,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Wild Charge. One use."
    },
    "tr87": {
      "name": "TR87",
      "fling": {"basePower": 80},
      "num": 1217,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Drill Run. One use."
    },
    "tr88": {
      "name": "TR88",
      "fling": {"basePower": 10},
      "num": 1218,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Heat Crash. One use."
    },
    "tr89": {
      "name": "TR89",
      "fling": {"basePower": 110},
      "num": 1219,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Hurricane. One use."
    },
    "tr90": {
      "name": "TR90",
      "fling": {"basePower": 90},
      "num": 1220,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Play Rough. One use."
    },
    "tr91": {
      "name": "TR91",
      "fling": {"basePower": 10},
      "num": 1221,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Venom Drench. One use."
    },
    "tr92": {
      "name": "TR92",
      "fling": {"basePower": 80},
      "num": 1222,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Dazzling Gleam. One use."
    },
    "tr93": {
      "name": "TR93",
      "fling": {"basePower": 85},
      "num": 1223,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Darkest Lariat. One use."
    },
    "tr94": {
      "name": "TR94",
      "fling": {"basePower": 95},
      "num": 1224,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move High Horsepower. One use."
    },
    "tr95": {
      "name": "TR95",
      "fling": {"basePower": 80},
      "num": 1225,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Throat Chop. One use."
    },
    "tr96": {
      "name": "TR96",
      "fling": {"basePower": 90},
      "num": 1226,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Pollen Puff. One use."
    },
    "tr97": {
      "name": "TR97",
      "fling": {"basePower": 85},
      "num": 1227,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Psychic Fangs. One use."
    },
    "tr98": {
      "name": "TR98",
      "fling": {"basePower": 85},
      "num": 1228,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Liquidation. One use."
    },
    "tr99": {
      "name": "TR99",
      "fling": {"basePower": 80},
      "num": 1229,
      "gen": 8,
      "isNonstandard": "Past",
      "desc": "Teaches certain Pokemon the move Body Press. One use."
    },
    "twistedspoon": {
      "name": "Twisted Spoon",
      "fling": {"basePower": 30},
      "onBasePowerPriority": 15,
      "num": 248,
      "gen": 2,
      "desc": "Holder's Psychic-type attacks have 1.2x power."
    },
    "tyranitarite": {
      "name": "Tyranitarite",
      "megaStone": "Tyranitar-Mega",
      "megaEvolves": "Tyranitar",
      "itemUser": ["Tyranitar"],
      "num": 669,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Tyranitar, this item allows it to Mega Evolve in battle."
    },
    "ultraball": {
      "name": "Ultra Ball",
      "num": 2,
      "gen": 1,
      "isPokeball": true,
      "desc": "An ultra-performance Ball that provides a higher catch rate than a Great Ball."
    },
    "ultranecroziumz": {
      "name": "Ultranecrozium Z",
      "onTakeItem": false,
      "zMove": "Light That Burns the Sky",
      "zMoveFrom": "Photon Geyser",
      "itemUser": ["Necrozma-Ultra"],
      "num": 923,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Dusk Mane/Dawn Wings Necrozma: Ultra Burst, then Z-Move w/ Photon Geyser."
    },
    "unremarkableteacup": {
      "name": "Unremarkable Teacup",
      "fling": {"basePower": 80},
      "num": 2403,
      "gen": 9,
      "desc": "Evolves Poltchageist into Sinistcha when used."
    },
    "upgrade": {
      "name": "Up-Grade",
      "fling": {"basePower": 30},
      "num": 252,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "Evolves Porygon into Porygon2 when traded."
    },
    "utilityumbrella": {
      "name": "Utility Umbrella",
      "fling": {"basePower": 60},
      "num": 1123,
      "gen": 8,
      "desc": "The holder ignores rain- and sun-based effects. Damage and accuracy calculations from attacks used by the holder are affected by rain and sun, but not attacks used against the holder.",
      "shortDesc": "The holder ignores rain- and sun-based effects."
    },
    "venusaurite": {
      "name": "Venusaurite",
      "megaStone": "Venusaur-Mega",
      "megaEvolves": "Venusaur",
      "itemUser": ["Venusaur"],
      "num": 659,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "If held by a Venusaur, this item allows it to Mega Evolve in battle."
    },
    "wacanberry": {
      "name": "Wacan Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Electric"},
      "num": 186,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Electric-type attack. Single use."
    },
    "watergem": {
      "name": "Water Gem",
      "isGem": true,
      "num": 549,
      "gen": 5,
      "isNonstandard": "Past",
      "desc": "Holder's first successful Water-type attack will have 1.3x power. Single use."
    },
    "watermemory": {
      "name": "Water Memory",
      "onMemory": "Water",
      "forcedForme": "Silvally-Water",
      "itemUser": ["Silvally-Water"],
      "num": 913,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "Holder's Multi-Attack is Water type."
    },
    "waterstone": {
      "name": "Water Stone",
      "fling": {"basePower": 30},
      "num": 84,
      "gen": 1,
      "desc": "Evolves Poliwhirl into Poliwrath, Shellder into Cloyster, Staryu into Starmie, Eevee into Vaporeon, Lombre into Ludicolo, and Panpour into Simipour when used.",
      "shortDesc": "Evolves certain species of Pokemon when used."
    },
    "wateriumz": {
      "name": "Waterium Z",
      "onPlate": "Water",
      "onTakeItem": false,
      "zMove": true,
      "zMoveType": "Water",
      "forcedForme": "Arceus-Water",
      "num": 778,
      "gen": 7,
      "isNonstandard": "Past",
      "desc": "If holder has a Water move, this item allows it to use a Water Z-Move."
    },
    "watmelberry": {
      "name": "Watmel Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 100, "type": "Fire"},
      "onEat": false,
      "num": 181,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "waveincense": {
      "name": "Wave Incense",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 15,
      "num": 317,
      "gen": 4,
      "isNonstandard": "Past",
      "desc": "Holder's Water-type attacks have 1.2x power."
    },
    "weaknesspolicy": {
      "name": "Weakness Policy",
      "fling": {"basePower": 80},
      "boosts": {"atk": 2, "spa": 2},
      "num": 639,
      "gen": 6,
      "desc": "If holder is hit super effectively, raises Attack, Sp. Atk by 2 stages. Single use."
    },
    "wellspringmask": {
      "name": "Wellspring Mask",
      "fling": {"basePower": 60},
      "onBasePowerPriority": 15,
      "forcedForme": "Ogerpon-Wellspring",
      "itemUser": ["Ogerpon-Wellspring"],
      "num": 2407,
      "gen": 9,
      "desc": "Ogerpon-Wellspring: 1.2x power attacks; Terastallize to gain Embody Aspect."
    },
    "wepearberry": {
      "name": "Wepear Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 90, "type": "Electric"},
      "onEat": false,
      "num": 167,
      "gen": 3,
      "isNonstandard": "Past",
      "desc": "Cannot be eaten by the holder. No effect when eaten with Bug Bite or Pluck."
    },
    "whippeddream": {
      "name": "Whipped Dream",
      "fling": {"basePower": 80},
      "num": 646,
      "gen": 6,
      "isNonstandard": "Past",
      "desc": "Evolves Swirlix into Slurpuff when traded."
    },
    "whiteherb": {
      "name": "White Herb",
      "fling": {"basePower": 10},
      "num": 214,
      "gen": 3,
      "desc": "Restores all lowered stat stages to 0 when one is less than 0. Single use."
    },
    "widelens": {
      "name": "Wide Lens",
      "fling": {"basePower": 10},
      "onSourceModifyAccuracyPriority": -2,
      "num": 265,
      "gen": 4,
      "desc": "The accuracy of attacks by the holder is 1.1x."
    },
    "wikiberry": {
      "name": "Wiki Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Rock"},
      "num": 160,
      "gen": 3,
      "desc": "Restores 1/3 max HP at 1/4 max HP or less; confuses if -SpA Nature. Single use."
    },
    "wiseglasses": {
      "name": "Wise Glasses",
      "fling": {"basePower": 10},
      "onBasePowerPriority": 16,
      "num": 267,
      "gen": 4,
      "desc": "Holder's special attacks have 1.1x power."
    },
    "yacheberry": {
      "name": "Yache Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Ice"},
      "num": 188,
      "gen": 4,
      "desc": "Halves damage taken from a supereffective Ice-type attack. Single use."
    },
    "zapplate": {
      "name": "Zap Plate",
      "onPlate": "Electric",
      "onBasePowerPriority": 15,
      "forcedForme": "Arceus-Electric",
      "num": 300,
      "gen": 4,
      "desc": "Holder's Electric-type attacks have 1.2x power. Judgment is Electric type."
    },
    "zoomlens": {
      "name": "Zoom Lens",
      "fling": {"basePower": 10},
      "onSourceModifyAccuracyPriority": -2,
      "num": 276,
      "gen": 4,
      "desc": "The accuracy of attacks by the holder is 1.2x if it moves after its target."
    },
    "berserkgene": {
      "name": "Berserk Gene",
      "boosts": {"atk": 2},
      "num": 0,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) On switch-in, raises holder's Attack by 2 and confuses it. Single use."
    },
    "berry": {
      "name": "Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Poison"},
      "onResidualOrder": 5,
      "num": 155,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Restores 10 HP when at 1/2 max HP or less. Single use."
    },
    "bitterberry": {
      "name": "Bitter Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Ground"},
      "num": 156,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Holder is cured if it is confused. Single use."
    },
    "burntberry": {
      "name": "Burnt Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Ice"},
      "num": 153,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Holder is cured if it is frozen. Single use."
    },
    "goldberry": {
      "name": "Gold Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Psychic"},
      "onResidualOrder": 5,
      "num": 158,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Restores 30 HP when at 1/2 max HP or less. Single use."
    },
    "iceberry": {
      "name": "Ice Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Grass"},
      "num": 152,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Holder is cured if it is burned. Single use."
    },
    "mintberry": {
      "name": "Mint Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Water"},
      "num": 150,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Holder wakes up if it is asleep. Single use."
    },
    "miracleberry": {
      "name": "Miracle Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Flying"},
      "num": 157,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Holder cures itself if it is confused or has a status condition. Single use."
    },
    "mysteryberry": {
      "name": "Mystery Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Fighting"},
      "num": 154,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Restores 5 PP to the first of the holder's moves to reach 0 PP. Single use."
    },
    "pinkbow": {
      "name": "Pink Bow",
      "num": 251,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Holder's Normal-type attacks have 1.1x power."
    },
    "polkadotbow": {
      "name": "Polkadot Bow",
      "num": 251,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Holder's Normal-type attacks have 1.1x power."
    },
    "przcureberry": {
      "name": "PRZ Cure Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Fire"},
      "num": 149,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Holder cures itself if it is paralyzed. Single use."
    },
    "psncureberry": {
      "name": "PSN Cure Berry",
      "isBerry": true,
      "naturalGift": {"basePower": 80, "type": "Electric"},
      "num": 151,
      "gen": 2,
      "isNonstandard": "Past",
      "desc": "(Gen 2) Holder is cured if it is poisoned. Single use."
    },
    "crucibellite": {
      "name": "Crucibellite",
      "megaStone": "Crucibelle-Mega",
      "megaEvolves": "Crucibelle",
      "itemUser": ["Crucibelle"],
      "num": -1,
      "gen": 6,
      "isNonstandard": "CAP",
      "desc": "If held by a Crucibelle, this item allows it to Mega Evolve in battle."
    },
    "vilevial": {
      "name": "Vile Vial",
      "fling": {"basePower": 60},
      "onBasePowerPriority": 15,
      "forcedForme": "Venomicon-Epilogue",
      "itemUser": ["Venomicon-Epilogue"],
      "num": -2,
      "gen": 8,
      "isNonstandard": "CAP",
      "desc": "If held by a Venomicon, its Poison- and Flying-type attacks have 1.2x power."
    }
  }
}