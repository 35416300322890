{
  "1": {
    "missingno": {
      "inherit": true,
      "baseStats": {
        "hp": 33,
        "atk": 136,
        "def": 0,
        "spa": 6,
        "spd": 6,
        "spe": 29
      }
    },
    "bulbasaur": {
      "inherit": true,
      "baseStats": {
        "hp": 45,
        "atk": 49,
        "def": 49,
        "spa": 65,
        "spd": 65,
        "spe": 45
      }
    },
    "ivysaur": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 62,
        "def": 63,
        "spa": 80,
        "spd": 80,
        "spe": 60
      }
    },
    "venusaur": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 82,
        "def": 83,
        "spa": 100,
        "spd": 100,
        "spe": 80
      }
    },
    "charmander": {
      "inherit": true,
      "baseStats": {
        "hp": 39,
        "atk": 52,
        "def": 43,
        "spa": 50,
        "spd": 50,
        "spe": 65
      }
    },
    "charmeleon": {
      "inherit": true,
      "baseStats": {
        "hp": 58,
        "atk": 64,
        "def": 58,
        "spa": 65,
        "spd": 65,
        "spe": 80
      }
    },
    "charizard": {
      "inherit": true,
      "baseStats": {
        "hp": 78,
        "atk": 84,
        "def": 78,
        "spa": 85,
        "spd": 85,
        "spe": 100
      }
    },
    "squirtle": {
      "inherit": true,
      "baseStats": {
        "hp": 44,
        "atk": 48,
        "def": 65,
        "spa": 50,
        "spd": 50,
        "spe": 43
      }
    },
    "wartortle": {
      "inherit": true,
      "baseStats": {
        "hp": 59,
        "atk": 63,
        "def": 80,
        "spa": 65,
        "spd": 65,
        "spe": 58
      }
    },
    "blastoise": {
      "inherit": true,
      "baseStats": {
        "hp": 79,
        "atk": 83,
        "def": 100,
        "spa": 85,
        "spd": 85,
        "spe": 78
      }
    },
    "caterpie": {
      "inherit": true,
      "baseStats": {
        "hp": 45,
        "atk": 30,
        "def": 35,
        "spa": 20,
        "spd": 20,
        "spe": 45
      }
    },
    "metapod": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 20,
        "def": 55,
        "spa": 25,
        "spd": 25,
        "spe": 30
      }
    },
    "butterfree": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 45,
        "def": 50,
        "spa": 80,
        "spd": 80,
        "spe": 70
      }
    },
    "weedle": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 35,
        "def": 30,
        "spa": 20,
        "spd": 20,
        "spe": 50
      }
    },
    "kakuna": {
      "inherit": true,
      "baseStats": {
        "hp": 45,
        "atk": 25,
        "def": 50,
        "spa": 25,
        "spd": 25,
        "spe": 35
      }
    },
    "beedrill": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 80,
        "def": 40,
        "spa": 45,
        "spd": 45,
        "spe": 75
      }
    },
    "pidgey": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 40,
        "spa": 35,
        "spd": 35,
        "spe": 56
      }
    },
    "pidgeotto": {
      "inherit": true,
      "baseStats": {
        "hp": 63,
        "atk": 60,
        "def": 55,
        "spa": 50,
        "spd": 50,
        "spe": 71
      }
    },
    "pidgeot": {
      "inherit": true,
      "baseStats": {
        "hp": 83,
        "atk": 80,
        "def": 75,
        "spa": 70,
        "spd": 70,
        "spe": 91
      }
    },
    "rattata": {
      "inherit": true,
      "baseStats": {
        "hp": 30,
        "atk": 56,
        "def": 35,
        "spa": 25,
        "spd": 25,
        "spe": 72
      }
    },
    "raticate": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 81,
        "def": 60,
        "spa": 50,
        "spd": 50,
        "spe": 97
      }
    },
    "spearow": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 60,
        "def": 30,
        "spa": 31,
        "spd": 31,
        "spe": 70
      }
    },
    "fearow": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 65,
        "spa": 61,
        "spd": 61,
        "spe": 100
      }
    },
    "ekans": {
      "inherit": true,
      "baseStats": {
        "hp": 35,
        "atk": 60,
        "def": 44,
        "spa": 40,
        "spd": 40,
        "spe": 55
      }
    },
    "arbok": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 69,
        "spa": 65,
        "spd": 65,
        "spe": 80
      }
    },
    "pikachu": {
      "inherit": true,
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 30,
        "spa": 50,
        "spd": 50,
        "spe": 90
      }
    },
    "raichu": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 90,
        "def": 55,
        "spa": 90,
        "spd": 90,
        "spe": 100
      }
    },
    "sandshrew": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 75,
        "def": 85,
        "spa": 30,
        "spd": 30,
        "spe": 40
      }
    },
    "sandslash": {
      "inherit": true,
      "baseStats": {
        "hp": 75,
        "atk": 100,
        "def": 110,
        "spa": 55,
        "spd": 55,
        "spe": 65
      }
    },
    "nidoranf": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 47,
        "def": 52,
        "spa": 40,
        "spd": 40,
        "spe": 41
      }
    },
    "nidorina": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 62,
        "def": 67,
        "spa": 55,
        "spd": 55,
        "spe": 56
      }
    },
    "nidoqueen": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 82,
        "def": 87,
        "spa": 75,
        "spd": 75,
        "spe": 76
      }
    },
    "nidoranm": {
      "inherit": true,
      "baseStats": {
        "hp": 46,
        "atk": 57,
        "def": 40,
        "spa": 40,
        "spd": 40,
        "spe": 50
      }
    },
    "nidorino": {
      "inherit": true,
      "baseStats": {
        "hp": 61,
        "atk": 72,
        "def": 57,
        "spa": 55,
        "spd": 55,
        "spe": 65
      }
    },
    "nidoking": {
      "inherit": true,
      "baseStats": {
        "hp": 81,
        "atk": 92,
        "def": 77,
        "spa": 75,
        "spd": 75,
        "spe": 85
      }
    },
    "clefairy": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 45,
        "def": 48,
        "spa": 60,
        "spd": 60,
        "spe": 35
      }
    },
    "clefable": {
      "inherit": true,
      "baseStats": {
        "hp": 95,
        "atk": 70,
        "def": 73,
        "spa": 85,
        "spd": 85,
        "spe": 60
      }
    },
    "vulpix": {
      "inherit": true,
      "baseStats": {
        "hp": 38,
        "atk": 41,
        "def": 40,
        "spa": 65,
        "spd": 65,
        "spe": 65
      }
    },
    "ninetales": {
      "inherit": true,
      "baseStats": {
        "hp": 73,
        "atk": 76,
        "def": 75,
        "spa": 100,
        "spd": 100,
        "spe": 100
      }
    },
    "jigglypuff": {
      "inherit": true,
      "baseStats": {
        "hp": 115,
        "atk": 45,
        "def": 20,
        "spa": 25,
        "spd": 25,
        "spe": 20
      }
    },
    "wigglytuff": {
      "inherit": true,
      "baseStats": {
        "hp": 140,
        "atk": 70,
        "def": 45,
        "spa": 50,
        "spd": 50,
        "spe": 45
      }
    },
    "zubat": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 35,
        "spa": 40,
        "spd": 40,
        "spe": 55
      }
    },
    "golbat": {
      "inherit": true,
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 70,
        "spa": 75,
        "spd": 75,
        "spe": 90
      }
    },
    "oddish": {
      "inherit": true,
      "baseStats": {
        "hp": 45,
        "atk": 50,
        "def": 55,
        "spa": 75,
        "spd": 75,
        "spe": 30
      }
    },
    "gloom": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 65,
        "def": 70,
        "spa": 85,
        "spd": 85,
        "spe": 40
      }
    },
    "vileplume": {
      "inherit": true,
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 85,
        "spa": 100,
        "spd": 100,
        "spe": 50
      }
    },
    "paras": {
      "inherit": true,
      "baseStats": {
        "hp": 35,
        "atk": 70,
        "def": 55,
        "spa": 55,
        "spd": 55,
        "spe": 25
      }
    },
    "parasect": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 95,
        "def": 80,
        "spa": 80,
        "spd": 80,
        "spe": 30
      }
    },
    "venonat": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 55,
        "def": 50,
        "spa": 40,
        "spd": 40,
        "spe": 45
      }
    },
    "venomoth": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 65,
        "def": 60,
        "spa": 90,
        "spd": 90,
        "spe": 90
      }
    },
    "diglett": {
      "inherit": true,
      "baseStats": {
        "hp": 10,
        "atk": 55,
        "def": 25,
        "spa": 45,
        "spd": 45,
        "spe": 95
      }
    },
    "dugtrio": {
      "inherit": true,
      "baseStats": {
        "hp": 35,
        "atk": 80,
        "def": 50,
        "spa": 70,
        "spd": 70,
        "spe": 120
      }
    },
    "meowth": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 35,
        "spa": 40,
        "spd": 40,
        "spe": 90
      }
    },
    "persian": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 70,
        "def": 60,
        "spa": 65,
        "spd": 65,
        "spe": 115
      }
    },
    "psyduck": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 52,
        "def": 48,
        "spa": 50,
        "spd": 50,
        "spe": 55
      }
    },
    "golduck": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 82,
        "def": 78,
        "spa": 80,
        "spd": 80,
        "spe": 85
      }
    },
    "mankey": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 80,
        "def": 35,
        "spa": 35,
        "spd": 35,
        "spe": 70
      }
    },
    "primeape": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 105,
        "def": 60,
        "spa": 60,
        "spd": 60,
        "spe": 95
      }
    },
    "growlithe": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 70,
        "def": 45,
        "spa": 50,
        "spd": 50,
        "spe": 60
      }
    },
    "arcanine": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 110,
        "def": 80,
        "spa": 80,
        "spd": 80,
        "spe": 95
      }
    },
    "poliwag": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 50,
        "def": 40,
        "spa": 40,
        "spd": 40,
        "spe": 90
      }
    },
    "poliwhirl": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 65,
        "def": 65,
        "spa": 50,
        "spd": 50,
        "spe": 90
      }
    },
    "poliwrath": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 85,
        "def": 95,
        "spa": 70,
        "spd": 70,
        "spe": 70
      }
    },
    "abra": {
      "inherit": true,
      "baseStats": {
        "hp": 25,
        "atk": 20,
        "def": 15,
        "spa": 105,
        "spd": 105,
        "spe": 90
      }
    },
    "kadabra": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 35,
        "def": 30,
        "spa": 120,
        "spd": 120,
        "spe": 105
      }
    },
    "alakazam": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 50,
        "def": 45,
        "spa": 135,
        "spd": 135,
        "spe": 120
      }
    },
    "machop": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 80,
        "def": 50,
        "spa": 35,
        "spd": 35,
        "spe": 35
      }
    },
    "machoke": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 100,
        "def": 70,
        "spa": 50,
        "spd": 50,
        "spe": 45
      }
    },
    "machamp": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 130,
        "def": 80,
        "spa": 65,
        "spd": 65,
        "spe": 55
      }
    },
    "bellsprout": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 75,
        "def": 35,
        "spa": 70,
        "spd": 70,
        "spe": 40
      }
    },
    "weepinbell": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 50,
        "spa": 85,
        "spd": 85,
        "spe": 55
      }
    },
    "victreebel": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 105,
        "def": 65,
        "spa": 100,
        "spd": 100,
        "spe": 70
      }
    },
    "tentacool": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 40,
        "def": 35,
        "spa": 100,
        "spd": 100,
        "spe": 70
      }
    },
    "tentacruel": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 70,
        "def": 65,
        "spa": 120,
        "spd": 120,
        "spe": 100
      }
    },
    "geodude": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 80,
        "def": 100,
        "spa": 30,
        "spd": 30,
        "spe": 20
      }
    },
    "graveler": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 95,
        "def": 115,
        "spa": 45,
        "spd": 45,
        "spe": 35
      }
    },
    "golem": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 110,
        "def": 130,
        "spa": 55,
        "spd": 55,
        "spe": 45
      }
    },
    "ponyta": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 85,
        "def": 55,
        "spa": 65,
        "spd": 65,
        "spe": 90
      }
    },
    "rapidash": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 100,
        "def": 70,
        "spa": 80,
        "spd": 80,
        "spe": 105
      }
    },
    "slowpoke": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 65,
        "def": 65,
        "spa": 40,
        "spd": 40,
        "spe": 15
      }
    },
    "slowbro": {
      "inherit": true,
      "baseStats": {
        "hp": 95,
        "atk": 75,
        "def": 110,
        "spa": 80,
        "spd": 80,
        "spe": 30
      }
    },
    "magnemite": {
      "inherit": true,
      "types": ["Electric"],
      "baseStats": {
        "hp": 25,
        "atk": 35,
        "def": 70,
        "spa": 95,
        "spd": 95,
        "spe": 45
      }
    },
    "magneton": {
      "inherit": true,
      "types": ["Electric"],
      "baseStats": {
        "hp": 50,
        "atk": 60,
        "def": 95,
        "spa": 120,
        "spd": 120,
        "spe": 70
      }
    },
    "farfetchd": {
      "inherit": true,
      "baseStats": {
        "hp": 52,
        "atk": 65,
        "def": 55,
        "spa": 58,
        "spd": 58,
        "spe": 60
      }
    },
    "doduo": {
      "inherit": true,
      "baseStats": {
        "hp": 35,
        "atk": 85,
        "def": 45,
        "spa": 35,
        "spd": 35,
        "spe": 75
      }
    },
    "dodrio": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 110,
        "def": 70,
        "spa": 60,
        "spd": 60,
        "spe": 100
      }
    },
    "seel": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 45,
        "def": 55,
        "spa": 70,
        "spd": 70,
        "spe": 45
      }
    },
    "dewgong": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 70,
        "def": 80,
        "spa": 95,
        "spd": 95,
        "spe": 70
      }
    },
    "grimer": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 80,
        "def": 50,
        "spa": 40,
        "spd": 40,
        "spe": 25
      }
    },
    "muk": {
      "inherit": true,
      "baseStats": {
        "hp": 105,
        "atk": 105,
        "def": 75,
        "spa": 65,
        "spd": 65,
        "spe": 50
      }
    },
    "shellder": {
      "inherit": true,
      "baseStats": {
        "hp": 30,
        "atk": 65,
        "def": 100,
        "spa": 45,
        "spd": 45,
        "spe": 40
      }
    },
    "cloyster": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 95,
        "def": 180,
        "spa": 85,
        "spd": 85,
        "spe": 70
      }
    },
    "gastly": {
      "inherit": true,
      "baseStats": {
        "hp": 30,
        "atk": 35,
        "def": 30,
        "spa": 100,
        "spd": 100,
        "spe": 80
      }
    },
    "haunter": {
      "inherit": true,
      "baseStats": {
        "hp": 45,
        "atk": 50,
        "def": 45,
        "spa": 115,
        "spd": 115,
        "spe": 95
      }
    },
    "gengar": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 65,
        "def": 60,
        "spa": 130,
        "spd": 130,
        "spe": 110
      }
    },
    "onix": {
      "inherit": true,
      "baseStats": {
        "hp": 35,
        "atk": 45,
        "def": 160,
        "spa": 30,
        "spd": 30,
        "spe": 70
      }
    },
    "drowzee": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 48,
        "def": 45,
        "spa": 90,
        "spd": 90,
        "spe": 42
      }
    },
    "hypno": {
      "inherit": true,
      "baseStats": {
        "hp": 85,
        "atk": 73,
        "def": 70,
        "spa": 115,
        "spd": 115,
        "spe": 67
      }
    },
    "krabby": {
      "inherit": true,
      "baseStats": {
        "hp": 30,
        "atk": 105,
        "def": 90,
        "spa": 25,
        "spd": 25,
        "spe": 50
      }
    },
    "kingler": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 130,
        "def": 115,
        "spa": 50,
        "spd": 50,
        "spe": 75
      }
    },
    "voltorb": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 30,
        "def": 50,
        "spa": 55,
        "spd": 55,
        "spe": 100
      }
    },
    "electrode": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 70,
        "spa": 80,
        "spd": 80,
        "spe": 140
      }
    },
    "exeggcute": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 40,
        "def": 80,
        "spa": 60,
        "spd": 60,
        "spe": 40
      }
    },
    "exeggutor": {
      "inherit": true,
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 85,
        "spa": 125,
        "spd": 125,
        "spe": 55
      }
    },
    "cubone": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 95,
        "spa": 40,
        "spd": 40,
        "spe": 35
      }
    },
    "marowak": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 80,
        "def": 110,
        "spa": 50,
        "spd": 50,
        "spe": 45
      }
    },
    "hitmonlee": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 120,
        "def": 53,
        "spa": 35,
        "spd": 35,
        "spe": 87
      }
    },
    "hitmonchan": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 105,
        "def": 79,
        "spa": 35,
        "spd": 35,
        "spe": 76
      }
    },
    "lickitung": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 55,
        "def": 75,
        "spa": 60,
        "spd": 60,
        "spe": 30
      }
    },
    "koffing": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 65,
        "def": 95,
        "spa": 60,
        "spd": 60,
        "spe": 35
      }
    },
    "weezing": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 120,
        "spa": 85,
        "spd": 85,
        "spe": 60
      }
    },
    "rhyhorn": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 85,
        "def": 95,
        "spa": 30,
        "spd": 30,
        "spe": 25
      }
    },
    "rhydon": {
      "inherit": true,
      "baseStats": {
        "hp": 105,
        "atk": 130,
        "def": 120,
        "spa": 45,
        "spd": 45,
        "spe": 40
      }
    },
    "chansey": {
      "inherit": true,
      "baseStats": {
        "hp": 250,
        "atk": 5,
        "def": 5,
        "spa": 105,
        "spd": 105,
        "spe": 50
      }
    },
    "tangela": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 55,
        "def": 115,
        "spa": 100,
        "spd": 100,
        "spe": 60
      }
    },
    "kangaskhan": {
      "inherit": true,
      "baseStats": {
        "hp": 105,
        "atk": 95,
        "def": 80,
        "spa": 40,
        "spd": 40,
        "spe": 90
      }
    },
    "horsea": {
      "inherit": true,
      "baseStats": {
        "hp": 30,
        "atk": 40,
        "def": 70,
        "spa": 70,
        "spd": 70,
        "spe": 60
      }
    },
    "seadra": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 65,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 85
      }
    },
    "goldeen": {
      "inherit": true,
      "baseStats": {
        "hp": 45,
        "atk": 67,
        "def": 60,
        "spa": 50,
        "spd": 50,
        "spe": 63
      }
    },
    "seaking": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 92,
        "def": 65,
        "spa": 80,
        "spd": 80,
        "spe": 68
      }
    },
    "staryu": {
      "inherit": true,
      "baseStats": {
        "hp": 30,
        "atk": 45,
        "def": 55,
        "spa": 70,
        "spd": 70,
        "spe": 85
      }
    },
    "starmie": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 75,
        "def": 85,
        "spa": 100,
        "spd": 100,
        "spe": 115
      }
    },
    "mrmime": {
      "inherit": true,
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 65,
        "spa": 100,
        "spd": 100,
        "spe": 90
      }
    },
    "scyther": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 110,
        "def": 80,
        "spa": 55,
        "spd": 55,
        "spe": 105
      }
    },
    "jynx": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 50,
        "def": 35,
        "spa": 95,
        "spd": 95,
        "spe": 95
      }
    },
    "electabuzz": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 83,
        "def": 57,
        "spa": 85,
        "spd": 85,
        "spe": 105
      }
    },
    "magmar": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 95,
        "def": 57,
        "spa": 85,
        "spd": 85,
        "spe": 93
      }
    },
    "pinsir": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 125,
        "def": 100,
        "spa": 55,
        "spd": 55,
        "spe": 85
      }
    },
    "tauros": {
      "inherit": true,
      "baseStats": {
        "hp": 75,
        "atk": 100,
        "def": 95,
        "spa": 70,
        "spd": 70,
        "spe": 110
      }
    },
    "magikarp": {
      "inherit": true,
      "baseStats": {
        "hp": 20,
        "atk": 10,
        "def": 55,
        "spa": 20,
        "spd": 20,
        "spe": 80
      }
    },
    "gyarados": {
      "inherit": true,
      "baseStats": {
        "hp": 95,
        "atk": 125,
        "def": 79,
        "spa": 100,
        "spd": 100,
        "spe": 81
      }
    },
    "lapras": {
      "inherit": true,
      "baseStats": {
        "hp": 130,
        "atk": 85,
        "def": 80,
        "spa": 95,
        "spd": 95,
        "spe": 60
      }
    },
    "ditto": {
      "inherit": true,
      "baseStats": {
        "hp": 48,
        "atk": 48,
        "def": 48,
        "spa": 48,
        "spd": 48,
        "spe": 48
      }
    },
    "eevee": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 55,
        "def": 50,
        "spa": 65,
        "spd": 65,
        "spe": 55
      }
    },
    "vaporeon": {
      "inherit": true,
      "baseStats": {
        "hp": 130,
        "atk": 65,
        "def": 60,
        "spa": 110,
        "spd": 110,
        "spe": 65
      }
    },
    "jolteon": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 65,
        "def": 60,
        "spa": 110,
        "spd": 110,
        "spe": 130
      }
    },
    "flareon": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 130,
        "def": 60,
        "spa": 110,
        "spd": 110,
        "spe": 65
      }
    },
    "porygon": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 60,
        "def": 70,
        "spa": 75,
        "spd": 75,
        "spe": 40
      }
    },
    "omanyte": {
      "inherit": true,
      "baseStats": {
        "hp": 35,
        "atk": 40,
        "def": 100,
        "spa": 90,
        "spd": 90,
        "spe": 35
      }
    },
    "omastar": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 60,
        "def": 125,
        "spa": 115,
        "spd": 115,
        "spe": 55
      }
    },
    "kabuto": {
      "inherit": true,
      "baseStats": {
        "hp": 30,
        "atk": 80,
        "def": 90,
        "spa": 45,
        "spd": 45,
        "spe": 55
      }
    },
    "kabutops": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 115,
        "def": 105,
        "spa": 70,
        "spd": 70,
        "spe": 80
      }
    },
    "aerodactyl": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 105,
        "def": 65,
        "spa": 60,
        "spd": 60,
        "spe": 130
      }
    },
    "snorlax": {
      "inherit": true,
      "baseStats": {
        "hp": 160,
        "atk": 110,
        "def": 65,
        "spa": 65,
        "spd": 65,
        "spe": 30
      }
    },
    "articuno": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 85,
        "def": 100,
        "spa": 125,
        "spd": 125,
        "spe": 85
      }
    },
    "zapdos": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 90,
        "def": 85,
        "spa": 125,
        "spd": 125,
        "spe": 100
      }
    },
    "moltres": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 100,
        "def": 90,
        "spa": 125,
        "spd": 125,
        "spe": 90
      }
    },
    "dratini": {
      "inherit": true,
      "baseStats": {
        "hp": 41,
        "atk": 64,
        "def": 45,
        "spa": 50,
        "spd": 50,
        "spe": 50
      }
    },
    "dragonair": {
      "inherit": true,
      "baseStats": {
        "hp": 61,
        "atk": 84,
        "def": 65,
        "spa": 70,
        "spd": 70,
        "spe": 70
      }
    },
    "dragonite": {
      "inherit": true,
      "baseStats": {
        "hp": 91,
        "atk": 134,
        "def": 95,
        "spa": 100,
        "spd": 100,
        "spe": 80
      }
    },
    "mewtwo": {
      "inherit": true,
      "baseStats": {
        "hp": 106,
        "atk": 110,
        "def": 90,
        "spa": 154,
        "spd": 154,
        "spe": 130
      }
    },
    "mew": {
      "inherit": true,
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      }
    }
  },
  "2": {
    "unown": {
      "inherit": true,
      "cosmeticFormes": [
        "Unown-B",
        "Unown-C",
        "Unown-D",
        "Unown-E",
        "Unown-F",
        "Unown-G",
        "Unown-H",
        "Unown-I",
        "Unown-J",
        "Unown-K",
        "Unown-L",
        "Unown-M",
        "Unown-N",
        "Unown-O",
        "Unown-P",
        "Unown-Q",
        "Unown-R",
        "Unown-S",
        "Unown-T",
        "Unown-U",
        "Unown-V",
        "Unown-W",
        "Unown-X",
        "Unown-Y",
        "Unown-Z"
      ],
      "formeOrder": [
        "Unown",
        "Unown-B",
        "Unown-C",
        "Unown-D",
        "Unown-E",
        "Unown-F",
        "Unown-G",
        "Unown-H",
        "Unown-I",
        "Unown-J",
        "Unown-K",
        "Unown-L",
        "Unown-M",
        "Unown-N",
        "Unown-O",
        "Unown-P",
        "Unown-Q",
        "Unown-R",
        "Unown-S",
        "Unown-T",
        "Unown-U",
        "Unown-V",
        "Unown-W",
        "Unown-X",
        "Unown-Y",
        "Unown-Z"
      ]
    }
  },
  "4": {
    "milotic": {
      "inherit": true,
      "evoType": "levelExtra",
      "evoCondition": "with high Beauty"
    },
    "rotomheat": {"inherit": true, "types": ["Electric", "Ghost"]},
    "rotomwash": {"inherit": true, "types": ["Electric", "Ghost"]},
    "rotomfrost": {"inherit": true, "types": ["Electric", "Ghost"]},
    "rotomfan": {"inherit": true, "types": ["Electric", "Ghost"]},
    "rotommow": {"inherit": true, "types": ["Electric", "Ghost"]}
  },
  "5": {
    "bulbasaur": {"inherit": true, "maleOnlyHidden": true},
    "ivysaur": {"inherit": true, "maleOnlyHidden": true},
    "venusaur": {"inherit": true, "maleOnlyHidden": true},
    "charmander": {"inherit": true, "maleOnlyHidden": true},
    "charmeleon": {"inherit": true, "maleOnlyHidden": true},
    "charizard": {"inherit": true, "maleOnlyHidden": true},
    "squirtle": {"inherit": true, "maleOnlyHidden": true},
    "wartortle": {"inherit": true, "maleOnlyHidden": true},
    "blastoise": {"inherit": true, "maleOnlyHidden": true},
    "butterfree": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 45,
        "def": 50,
        "spa": 80,
        "spd": 80,
        "spe": 70
      }
    },
    "beedrill": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 80,
        "def": 40,
        "spa": 45,
        "spd": 80,
        "spe": 75
      }
    },
    "pidgeot": {
      "inherit": true,
      "baseStats": {
        "hp": 83,
        "atk": 80,
        "def": 75,
        "spa": 70,
        "spd": 70,
        "spe": 91
      }
    },
    "pikachu": {
      "inherit": true,
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 30,
        "spa": 50,
        "spd": 40,
        "spe": 90
      }
    },
    "raichu": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 90,
        "def": 55,
        "spa": 90,
        "spd": 80,
        "spe": 100
      }
    },
    "nidoqueen": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 82,
        "def": 87,
        "spa": 75,
        "spd": 85,
        "spe": 76
      }
    },
    "nidoking": {
      "inherit": true,
      "baseStats": {
        "hp": 81,
        "atk": 92,
        "def": 77,
        "spa": 85,
        "spd": 75,
        "spe": 85
      }
    },
    "clefairy": {"inherit": true, "types": ["Normal"]},
    "clefable": {
      "inherit": true,
      "types": ["Normal"],
      "baseStats": {
        "hp": 95,
        "atk": 70,
        "def": 73,
        "spa": 85,
        "spd": 90,
        "spe": 60
      }
    },
    "jigglypuff": {
      "inherit": true,
      "types": ["Normal"],
      "abilities": {"0": "Cute Charm", "H": "Friend Guard"}
    },
    "wigglytuff": {
      "inherit": true,
      "types": ["Normal"],
      "baseStats": {
        "hp": 140,
        "atk": 70,
        "def": 45,
        "spa": 75,
        "spd": 50,
        "spe": 45
      },
      "abilities": {"0": "Cute Charm", "H": "Frisk"}
    },
    "vileplume": {
      "inherit": true,
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 85,
        "spa": 100,
        "spd": 90,
        "spe": 50
      }
    },
    "poliwrath": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 85,
        "def": 95,
        "spa": 70,
        "spd": 90,
        "spe": 70
      }
    },
    "alakazam": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 50,
        "def": 45,
        "spa": 135,
        "spd": 85,
        "spe": 120
      }
    },
    "victreebel": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 105,
        "def": 65,
        "spa": 100,
        "spd": 60,
        "spe": 70
      }
    },
    "golem": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 110,
        "def": 130,
        "spa": 55,
        "spd": 65,
        "spe": 45
      }
    },
    "mrmime": {"inherit": true, "types": ["Psychic"]},
    "articuno": {"inherit": true, "unreleasedHidden": true},
    "zapdos": {
      "inherit": true,
      "abilities": {"0": "Pressure", "H": "Lightning Rod"},
      "unreleasedHidden": true
    },
    "moltres": {"inherit": true, "unreleasedHidden": true},
    "chikorita": {"inherit": true, "unreleasedHidden": true},
    "bayleef": {"inherit": true, "unreleasedHidden": true},
    "meganium": {"inherit": true, "unreleasedHidden": true},
    "cyndaquil": {"inherit": true, "unreleasedHidden": true},
    "quilava": {"inherit": true, "unreleasedHidden": true},
    "typhlosion": {"inherit": true, "unreleasedHidden": true},
    "totodile": {"inherit": true, "unreleasedHidden": true},
    "croconaw": {"inherit": true, "unreleasedHidden": true},
    "feraligatr": {"inherit": true, "unreleasedHidden": true},
    "igglybuff": {
      "inherit": true,
      "types": ["Normal"],
      "abilities": {"0": "Cute Charm", "H": "Friend Guard"}
    },
    "togepi": {"inherit": true, "types": ["Normal"]},
    "togetic": {"inherit": true, "types": ["Normal", "Flying"]},
    "cleffa": {"inherit": true, "types": ["Normal"]},
    "ampharos": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 75,
        "def": 75,
        "spa": 115,
        "spd": 90,
        "spe": 55
      }
    },
    "bellossom": {
      "inherit": true,
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 85,
        "spa": 90,
        "spd": 100,
        "spe": 50
      }
    },
    "marill": {"inherit": true, "types": ["Water"]},
    "azumarill": {
      "inherit": true,
      "types": ["Water"],
      "baseStats": {
        "hp": 100,
        "atk": 50,
        "def": 80,
        "spa": 50,
        "spd": 80,
        "spe": 50
      }
    },
    "jumpluff": {
      "inherit": true,
      "baseStats": {
        "hp": 75,
        "atk": 55,
        "def": 70,
        "spa": 55,
        "spd": 85,
        "spe": 110
      }
    },
    "snubbull": {"inherit": true, "types": ["Normal"]},
    "granbull": {"inherit": true, "types": ["Normal"]},
    "tyrogue": {"inherit": true, "maleOnlyHidden": true},
    "hitmonlee": {"inherit": true, "maleOnlyHidden": true},
    "hitmonchan": {"inherit": true, "maleOnlyHidden": true},
    "hitmontop": {"inherit": true, "maleOnlyHidden": true},
    "treecko": {"inherit": true, "maleOnlyHidden": true},
    "grovyle": {"inherit": true, "maleOnlyHidden": true},
    "sceptile": {"inherit": true, "maleOnlyHidden": true},
    "torchic": {"inherit": true, "maleOnlyHidden": true},
    "combusken": {"inherit": true, "maleOnlyHidden": true},
    "blaziken": {"inherit": true, "maleOnlyHidden": true},
    "mudkip": {"inherit": true, "maleOnlyHidden": true},
    "marshtomp": {"inherit": true, "maleOnlyHidden": true},
    "swampert": {"inherit": true, "maleOnlyHidden": true},
    "beautifly": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 70,
        "def": 50,
        "spa": 90,
        "spd": 50,
        "spe": 65
      }
    },
    "ralts": {"inherit": true, "types": ["Psychic"]},
    "kirlia": {"inherit": true, "types": ["Psychic"]},
    "gardevoir": {"inherit": true, "types": ["Psychic"]},
    "exploud": {
      "inherit": true,
      "baseStats": {
        "hp": 104,
        "atk": 91,
        "def": 63,
        "spa": 91,
        "spd": 63,
        "spe": 68
      }
    },
    "azurill": {"inherit": true, "types": ["Normal"]},
    "mawile": {"inherit": true, "types": ["Steel"]},
    "plusle": {"inherit": true, "abilities": {"0": "Plus"}},
    "minun": {"inherit": true, "abilities": {"0": "Minus"}},
    "kecleon": {"inherit": true, "abilities": {"0": "Color Change"}},
    "feebas": {
      "inherit": true,
      "abilities": {"0": "Swift Swim", "H": "Adaptability"}
    },
    "milotic": {
      "inherit": true,
      "abilities": {"0": "Marvel Scale", "H": "Cute Charm"}
    },
    "duskull": {"inherit": true, "abilities": {"0": "Levitate"}},
    "dusclops": {"inherit": true, "abilities": {"0": "Pressure"}},
    "regirock": {"inherit": true, "unreleasedHidden": true},
    "regice": {"inherit": true, "unreleasedHidden": true},
    "registeel": {"inherit": true, "unreleasedHidden": true},
    "turtwig": {"inherit": true, "maleOnlyHidden": true},
    "grotle": {"inherit": true, "maleOnlyHidden": true},
    "torterra": {"inherit": true, "maleOnlyHidden": true},
    "chimchar": {"inherit": true, "maleOnlyHidden": true},
    "monferno": {"inherit": true, "maleOnlyHidden": true},
    "infernape": {"inherit": true, "maleOnlyHidden": true},
    "piplup": {"inherit": true, "maleOnlyHidden": true},
    "prinplup": {"inherit": true, "maleOnlyHidden": true},
    "empoleon": {"inherit": true, "maleOnlyHidden": true},
    "starly": {"inherit": true, "abilities": {"0": "Keen Eye"}},
    "staraptor": {
      "inherit": true,
      "baseStats": {
        "hp": 85,
        "atk": 120,
        "def": 70,
        "spa": 50,
        "spd": 50,
        "spe": 100
      }
    },
    "roserade": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 70,
        "def": 55,
        "spa": 125,
        "spd": 105,
        "spe": 90
      }
    },
    "mimejr": {"inherit": true, "types": ["Psychic"]},
    "togekiss": {"inherit": true, "types": ["Normal", "Flying"]},
    "dusknoir": {"inherit": true, "abilities": {"0": "Pressure"}},
    "snivy": {"inherit": true, "unreleasedHidden": true},
    "servine": {"inherit": true, "unreleasedHidden": true},
    "serperior": {"inherit": true, "unreleasedHidden": true},
    "tepig": {"inherit": true, "unreleasedHidden": true},
    "pignite": {"inherit": true, "unreleasedHidden": true},
    "emboar": {"inherit": true, "unreleasedHidden": true},
    "oshawott": {"inherit": true, "unreleasedHidden": true},
    "dewott": {"inherit": true, "unreleasedHidden": true},
    "samurott": {"inherit": true, "unreleasedHidden": true},
    "stoutland": {
      "inherit": true,
      "baseStats": {
        "hp": 85,
        "atk": 100,
        "def": 90,
        "spa": 45,
        "spd": 90,
        "spe": 80
      }
    },
    "pansage": {"inherit": true, "maleOnlyHidden": true},
    "simisage": {"inherit": true, "maleOnlyHidden": true},
    "pansear": {"inherit": true, "maleOnlyHidden": true},
    "simisear": {"inherit": true, "maleOnlyHidden": true},
    "panpour": {"inherit": true, "maleOnlyHidden": true},
    "simipour": {"inherit": true, "maleOnlyHidden": true},
    "unfezant": {
      "inherit": true,
      "baseStats": {
        "hp": 80,
        "atk": 105,
        "def": 80,
        "spa": 65,
        "spd": 55,
        "spe": 93
      }
    },
    "gigalith": {
      "inherit": true,
      "baseStats": {
        "hp": 85,
        "atk": 135,
        "def": 130,
        "spa": 60,
        "spd": 70,
        "spe": 25
      }
    },
    "seismitoad": {
      "inherit": true,
      "baseStats": {
        "hp": 105,
        "atk": 85,
        "def": 75,
        "spa": 85,
        "spd": 75,
        "spe": 74
      }
    },
    "leavanny": {
      "inherit": true,
      "baseStats": {
        "hp": 75,
        "atk": 103,
        "def": 80,
        "spa": 70,
        "spd": 70,
        "spe": 92
      }
    },
    "venipede": {
      "inherit": true,
      "abilities": {"0": "Poison Point", "1": "Swarm", "H": "Quick Feet"}
    },
    "whirlipede": {
      "inherit": true,
      "abilities": {"0": "Poison Point", "1": "Swarm", "H": "Quick Feet"}
    },
    "scolipede": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 90,
        "def": 89,
        "spa": 55,
        "spd": 69,
        "spe": 112
      },
      "abilities": {"0": "Poison Point", "1": "Swarm", "H": "Quick Feet"}
    },
    "cottonee": {"inherit": true, "types": ["Grass"]},
    "whimsicott": {"inherit": true, "types": ["Grass"]},
    "basculinbluestriped": {
      "inherit": true,
      "abilities": {
        "0": "Rock Head",
        "1": "Adaptability",
        "H": "Mold Breaker",
        "S": "Reckless"
      }
    },
    "krookodile": {
      "inherit": true,
      "baseStats": {
        "hp": 95,
        "atk": 117,
        "def": 70,
        "spa": 65,
        "spd": 70,
        "spe": 92
      }
    },
    "gothita": {"inherit": true, "unreleasedHidden": true},
    "gothorita": {
      "inherit": true,
      "abilities": {"0": "Frisk", "H": "Shadow Tag"},
      "maleOnlyHidden": true
    },
    "gothitelle": {
      "inherit": true,
      "abilities": {"0": "Frisk", "H": "Shadow Tag"},
      "maleOnlyHidden": true
    },
    "ferrothorn": {"inherit": true, "abilities": {"0": "Iron Barbs"}},
    "klink": {"inherit": true, "unreleasedHidden": true},
    "litwick": {
      "inherit": true,
      "abilities": {"0": "Flash Fire", "1": "Flame Body", "H": "Shadow Tag"},
      "unreleasedHidden": true
    },
    "lampent": {
      "inherit": true,
      "abilities": {"0": "Flash Fire", "1": "Flame Body", "H": "Shadow Tag"},
      "unreleasedHidden": true
    },
    "chandelure": {
      "inherit": true,
      "abilities": {"0": "Flash Fire", "1": "Flame Body", "H": "Shadow Tag"},
      "unreleasedHidden": true
    },
    "rufflet": {"inherit": true, "unreleasedHidden": true},
    "larvesta": {"inherit": true, "unreleasedHidden": true},
    "volcarona": {"inherit": true, "unreleasedHidden": true}
  },
  "6": {
    "charizardmegax": {"inherit": true},
    "arbok": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 69,
        "spa": 65,
        "spd": 79,
        "spe": 80
      }
    },
    "pikachu": {
      "inherit": true,
      "formeOrder": [
        "Pikachu",
        "Pikachu-Rock-Star",
        "Pikachu-Belle",
        "Pikachu-Pop-Star",
        "Pikachu-PhD",
        "Pikachu-Libre",
        "Pikachu-Cosplay"
      ]
    },
    "dugtrio": {
      "inherit": true,
      "baseStats": {
        "hp": 35,
        "atk": 80,
        "def": 50,
        "spa": 50,
        "spd": 70,
        "spe": 120
      }
    },
    "alakazammega": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 50,
        "def": 65,
        "spa": 175,
        "spd": 95,
        "spe": 150
      }
    },
    "farfetchd": {
      "inherit": true,
      "baseStats": {
        "hp": 52,
        "atk": 65,
        "def": 55,
        "spa": 58,
        "spd": 62,
        "spe": 60
      }
    },
    "dodrio": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 110,
        "def": 70,
        "spa": 60,
        "spd": 60,
        "spe": 100
      }
    },
    "gengar": {"inherit": true, "abilities": {"0": "Levitate"}},
    "electrode": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 70,
        "spa": 80,
        "spd": 80,
        "spe": 140
      }
    },
    "exeggutor": {
      "inherit": true,
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 85,
        "spa": 125,
        "spd": 65,
        "spe": 55
      }
    },
    "noctowl": {
      "inherit": true,
      "baseStats": {
        "hp": 100,
        "atk": 50,
        "def": 50,
        "spa": 76,
        "spd": 96,
        "spe": 70
      }
    },
    "ariados": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 90,
        "def": 70,
        "spa": 60,
        "spd": 60,
        "spe": 40
      }
    },
    "qwilfish": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 95,
        "def": 75,
        "spa": 55,
        "spd": 55,
        "spe": 85
      }
    },
    "magcargo": {
      "inherit": true,
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 120,
        "spa": 80,
        "spd": 80,
        "spe": 30
      }
    },
    "corsola": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 55,
        "def": 85,
        "spa": 65,
        "spd": 85,
        "spe": 35
      }
    },
    "mantine": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 40,
        "def": 70,
        "spa": 80,
        "spd": 140,
        "spe": 70
      }
    },
    "raikou": {
      "inherit": true,
      "abilities": {"0": "Pressure", "H": "Volt Absorb"},
      "unreleasedHidden": true
    },
    "entei": {
      "inherit": true,
      "abilities": {"0": "Pressure", "H": "Flash Fire"},
      "unreleasedHidden": true
    },
    "suicune": {
      "inherit": true,
      "abilities": {"0": "Pressure", "H": "Water Absorb"},
      "unreleasedHidden": true
    },
    "swellow": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 60,
        "spa": 50,
        "spd": 50,
        "spe": 125
      }
    },
    "wingull": {
      "inherit": true,
      "abilities": {"0": "Keen Eye", "H": "Rain Dish"}
    },
    "pelipper": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 100,
        "spa": 85,
        "spd": 70,
        "spe": 65
      },
      "abilities": {"0": "Keen Eye", "H": "Rain Dish"}
    },
    "masquerain": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 60,
        "def": 62,
        "spa": 80,
        "spd": 82,
        "spe": 60
      }
    },
    "delcatty": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 65,
        "def": 65,
        "spa": 55,
        "spd": 55,
        "spe": 70
      }
    },
    "volbeat": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 73,
        "def": 55,
        "spa": 47,
        "spd": 75,
        "spe": 85
      },
      "abilities": {"0": "Illuminate", "1": "Swarm", "H": "Prankster"}
    },
    "illumise": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 47,
        "def": 55,
        "spa": 73,
        "spd": 75,
        "spe": 85
      }
    },
    "torkoal": {
      "inherit": true,
      "abilities": {"0": "White Smoke", "H": "Shell Armor"}
    },
    "lunatone": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 55,
        "def": 65,
        "spa": 95,
        "spd": 85,
        "spe": 70
      }
    },
    "solrock": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 95,
        "def": 85,
        "spa": 55,
        "spd": 65,
        "spe": 70
      }
    },
    "castform": {"inherit": true},
    "castformsunny": {"inherit": true},
    "castformrainy": {"inherit": true},
    "chimecho": {
      "inherit": true,
      "baseStats": {
        "hp": 65,
        "atk": 50,
        "def": 70,
        "spa": 95,
        "spd": 80,
        "spe": 65
      }
    },
    "latiasmega": {"inherit": true},
    "latiosmega": {"inherit": true},
    "burmy": {"inherit": true},
    "wormadam": {"inherit": true},
    "wormadamsandy": {"inherit": true},
    "wormadamtrash": {"inherit": true},
    "cherrim": {"inherit": true},
    "arceus": {"inherit": true},
    "arceusbug": {"inherit": true, "requiredItems": ["Insect Plate"]},
    "arceusdark": {"inherit": true, "requiredItems": ["Dread Plate"]},
    "arceusdragon": {"inherit": true, "requiredItems": ["Draco Plate"]},
    "arceuselectric": {"inherit": true, "requiredItems": ["Zap Plate"]},
    "arceusfairy": {"inherit": true, "requiredItems": ["Pixie Plate"]},
    "arceusfighting": {"inherit": true, "requiredItems": ["Fist Plate"]},
    "arceusfire": {"inherit": true, "requiredItems": ["Flame Plate"]},
    "arceusflying": {"inherit": true, "requiredItems": ["Sky Plate"]},
    "arceusghost": {"inherit": true, "requiredItems": ["Spooky Plate"]},
    "arceusgrass": {"inherit": true, "requiredItems": ["Meadow Plate"]},
    "arceusground": {"inherit": true, "requiredItems": ["Earth Plate"]},
    "arceusice": {"inherit": true, "requiredItems": ["Icicle Plate"]},
    "arceuspoison": {"inherit": true, "requiredItems": ["Toxic Plate"]},
    "arceuspsychic": {"inherit": true, "requiredItems": ["Mind Plate"]},
    "arceusrock": {"inherit": true, "requiredItems": ["Stone Plate"]},
    "arceussteel": {"inherit": true, "requiredItems": ["Iron Plate"]},
    "arceuswater": {"inherit": true, "requiredItems": ["Splash Plate"]},
    "roggenrola": {
      "inherit": true,
      "abilities": {"0": "Sturdy", "H": "Sand Force"}
    },
    "boldore": {
      "inherit": true,
      "abilities": {"0": "Sturdy", "H": "Sand Force"}
    },
    "gigalith": {
      "inherit": true,
      "abilities": {"0": "Sturdy", "H": "Sand Force"}
    },
    "woobat": {
      "inherit": true,
      "baseStats": {
        "hp": 55,
        "atk": 45,
        "def": 43,
        "spa": 55,
        "spd": 43,
        "spe": 72
      }
    },
    "audinomega": {"inherit": true},
    "darmanitanzen": {"inherit": true},
    "crustle": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 95,
        "def": 125,
        "spa": 65,
        "spd": 75,
        "spe": 45
      }
    },
    "vanillite": {
      "inherit": true,
      "abilities": {"0": "Ice Body", "H": "Weak Armor"}
    },
    "vanillish": {
      "inherit": true,
      "abilities": {"0": "Ice Body", "H": "Weak Armor"}
    },
    "vanilluxe": {
      "inherit": true,
      "abilities": {"0": "Ice Body", "H": "Weak Armor"}
    },
    "deerling": {"inherit": true},
    "cubchoo": {
      "inherit": true,
      "abilities": {"0": "Snow Cloak", "H": "Rattled"}
    },
    "beartic": {
      "inherit": true,
      "baseStats": {
        "hp": 95,
        "atk": 110,
        "def": 80,
        "spa": 70,
        "spd": 80,
        "spe": 50
      },
      "abilities": {"0": "Snow Cloak", "H": "Swift Swim"}
    },
    "cryogonal": {
      "inherit": true,
      "baseStats": {
        "hp": 70,
        "atk": 50,
        "def": 30,
        "spa": 95,
        "spd": 135,
        "spe": 105
      }
    },
    "greninja": {
      "inherit": true,
      "abilities": {"0": "Torrent", "H": "Protean"}
    },
    "vivillon": {"inherit": true},
    "meowstic": {"inherit": true},
    "zygarde": {"inherit": true, "abilities": {"0": "Aura Break"}},
    "necturna": {
      "inherit": true,
      "baseStats": {
        "hp": 64,
        "atk": 120,
        "def": 100,
        "spa": 85,
        "spd": 120,
        "spe": 81
      }
    },
    "malaconda": {
      "inherit": true,
      "abilities": {"0": "Harvest", "1": "Infiltrator"}
    },
    "naviathan": {
      "inherit": true,
      "abilities": {"0": "Water Veil", "1": "Heatproof", "H": "Light Metal"}
    },
    "crucibellemega": {
      "inherit": true,
      "baseStats": {
        "hp": 106,
        "atk": 135,
        "def": 75,
        "spa": 85,
        "spd": 125,
        "spe": 114
      }
    },
    "syclant": {
      "inherit": true,
      "abilities": {"0": "Compound Eyes", "1": "Mountaineer"}
    },
    "revenankh": {
      "inherit": true,
      "abilities": {"0": "Air Lock", "H": "Shed Skin"}
    },
    "pyroak": {
      "inherit": true,
      "abilities": {"0": "Rock Head", "1": "Battle Armor"}
    },
    "fidgit": {
      "inherit": true,
      "abilities": {"0": "Persistent", "1": "Vital Spirit"}
    },
    "stratagem": {
      "inherit": true,
      "abilities": {"0": "Levitate", "1": "Technician"}
    },
    "arghonaut": {"inherit": true, "abilities": {"0": "Unaware"}},
    "kitsunoh": {"inherit": true, "abilities": {"0": "Frisk", "1": "Limber"}},
    "cyclohm": {
      "inherit": true,
      "abilities": {"0": "Shield Dust", "1": "Static"}
    },
    "colossoil": {"inherit": true, "abilities": {"0": "Rebound", "1": "Guts"}},
    "krilowatt": {
      "inherit": true,
      "abilities": {"0": "Trace", "1": "Magic Guard"}
    },
    "voodoom": {
      "inherit": true,
      "abilities": {"0": "Volt Absorb", "1": "Lightning Rod"}
    }
  },
  "7": {
    "pikachuoriginal": {"inherit": true, "abilities": {"0": "Static"}},
    "pikachuhoenn": {"inherit": true, "abilities": {"0": "Static"}},
    "pikachusinnoh": {"inherit": true, "abilities": {"0": "Static"}},
    "pikachuunova": {"inherit": true, "abilities": {"0": "Static"}},
    "pikachukalos": {"inherit": true, "abilities": {"0": "Static"}},
    "pikachualola": {"inherit": true, "abilities": {"0": "Static"}},
    "pikachupartner": {"inherit": true, "abilities": {"0": "Static"}},
    "koffing": {"inherit": true, "abilities": {"0": "Levitate"}},
    "weezing": {"inherit": true, "abilities": {"0": "Levitate"}},
    "ralts": {"inherit": true, "eggGroups": ["Amorphous"]},
    "kirlia": {"inherit": true, "eggGroups": ["Amorphous"]},
    "gardevoir": {"inherit": true, "eggGroups": ["Amorphous"]},
    "trapinch": {"inherit": true, "eggGroups": ["Bug"]},
    "vibrava": {"inherit": true, "eggGroups": ["Bug"]},
    "flygon": {"inherit": true, "eggGroups": ["Bug"]},
    "magnezone": {
      "inherit": true,
      "evoType": "levelExtra",
      "evoCondition": "in a special magnetic field"
    },
    "leafeon": {
      "inherit": true,
      "evoType": "levelExtra",
      "evoCondition": "near a Moss Rock"
    },
    "glaceon": {
      "inherit": true,
      "evoType": "levelExtra",
      "evoCondition": "near an Ice Rock"
    },
    "gallade": {"inherit": true, "eggGroups": ["Amorphous"]},
    "heatran": {
      "inherit": true,
      "abilities": {"0": "Flash Fire", "H": "Flame Body"},
      "unreleasedHidden": true
    },
    "aegislash": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 150,
        "spa": 50,
        "spd": 150,
        "spe": 60
      }
    },
    "aegislashblade": {
      "inherit": true,
      "baseStats": {
        "hp": 60,
        "atk": 150,
        "def": 50,
        "spa": 150,
        "spd": 50,
        "spe": 60
      }
    },
    "pumpkaboosmall": {
      "inherit": true,
      "abilities": {"0": "Pickup", "1": "Frisk"}
    },
    "pumpkaboolarge": {
      "inherit": true,
      "abilities": {"0": "Pickup", "1": "Frisk"}
    },
    "gourgeistsmall": {
      "inherit": true,
      "abilities": {"0": "Pickup", "1": "Frisk"}
    },
    "gourgeistlarge": {
      "inherit": true,
      "abilities": {"0": "Pickup", "1": "Frisk"}
    },
    "hawlucha": {"inherit": true, "eggGroups": ["Human-Like"]},
    "bergmite": {"inherit": true, "eggGroups": ["Monster"]},
    "avalugg": {"inherit": true, "eggGroups": ["Monster"]},
    "noibat": {"inherit": true, "eggGroups": ["Flying"]},
    "noivern": {"inherit": true, "eggGroups": ["Flying"]},
    "vikavolt": {
      "inherit": true,
      "evoType": "levelExtra",
      "evoCondition": "near a special magnetic field"
    },
    "crabominable": {
      "inherit": true,
      "evoType": "levelExtra",
      "evoCondition": "at Mount Lanakila"
    },
    "lycanroc": {"inherit": true, "evoCondition": "in Pokemon Sun/Ultra Sun"},
    "lycanrocmidnight": {
      "inherit": true,
      "evoCondition": "in Pokemon Moon/Ultra Moon"
    },
    "tapukoko": {"inherit": true, "abilities": {"0": "Electric Surge"}},
    "tapulele": {"inherit": true, "abilities": {"0": "Psychic Surge"}},
    "tapubulu": {"inherit": true, "abilities": {"0": "Grassy Surge"}},
    "tapufini": {"inherit": true, "abilities": {"0": "Misty Surge"}},
    "pyroak": {
      "inherit": true,
      "baseStats": {
        "hp": 120,
        "atk": 70,
        "def": 105,
        "spa": 95,
        "spd": 90,
        "spe": 60
      },
      "abilities": {"0": "Rock Head", "1": "Battle Armor", "H": "White Smoke"}
    },
    "voodoom": {
      "inherit": true,
      "baseStats": {
        "hp": 90,
        "atk": 85,
        "def": 80,
        "spa": 105,
        "spd": 80,
        "spe": 110
      }
    },
    "mumbao": {"inherit": true, "unreleasedHidden": true},
    "jumbao": {"inherit": true, "unreleasedHidden": true}
  },
  "8": {
    "growlithehisui": {
      "inherit": true,
      "abilities": {"0": "Intimidate", "1": "Flash Fire", "H": "Justified"}
    },
    "arcaninehisui": {
      "inherit": true,
      "abilities": {"0": "Intimidate", "1": "Flash Fire", "H": "Justified"}
    },
    "typhlosionhisui": {
      "inherit": true,
      "abilities": {"0": "Blaze", "H": "Flash Fire"}
    },
    "sneaselhisui": {
      "inherit": true,
      "abilities": {"0": "Inner Focus", "1": "Keen Eye", "H": "Poison Touch"}
    },
    "shiftry": {
      "inherit": true,
      "abilities": {"0": "Chlorophyll", "1": "Early Bird", "H": "Pickpocket"}
    },
    "piplup": {"inherit": true, "abilities": {"0": "Torrent", "H": "Defiant"}},
    "prinplup": {
      "inherit": true,
      "abilities": {"0": "Torrent", "H": "Defiant"}
    },
    "empoleon": {
      "inherit": true,
      "abilities": {"0": "Torrent", "H": "Defiant"}
    },
    "gallade": {
      "inherit": true,
      "abilities": {"0": "Steadfast", "H": "Justified"}
    },
    "giratinaorigin": {"inherit": true, "requiredItem": "Griseous Orb"},
    "cresselia": {
      "inherit": true,
      "baseStats": {
        "hp": 120,
        "atk": 70,
        "def": 120,
        "spa": 75,
        "spd": 130,
        "spe": 85
      }
    },
    "samurotthisui": {
      "inherit": true,
      "abilities": {"0": "Torrent", "H": "Shell Armor"}
    },
    "braviaryhisui": {
      "inherit": true,
      "abilities": {"0": "Keen Eye", "1": "Sheer Force", "H": "Defiant"}
    },
    "spewpa": {"inherit": true, "evos": ["Vivillon"]},
    "vivillonfancy": {
      "inherit": true,
      "abilities": {"0": "Shield Dust", "1": "Compound Eyes"}
    },
    "vivillonpokeball": {
      "inherit": true,
      "abilities": {"0": "Shield Dust", "1": "Compound Eyes"}
    },
    "sliggoohisui": {
      "inherit": true,
      "abilities": {"0": "Sap Sipper", "1": "Overcoat", "H": "Gooey"}
    },
    "goodrahisui": {
      "inherit": true,
      "abilities": {"0": "Sap Sipper", "1": "Overcoat", "H": "Gooey"}
    },
    "decidueyehisui": {
      "inherit": true,
      "abilities": {"0": "Overgrow", "H": "Long Reach"}
    },
    "zacian": {
      "inherit": true,
      "baseStats": {
        "hp": 92,
        "atk": 130,
        "def": 115,
        "spa": 80,
        "spd": 115,
        "spe": 138
      }
    },
    "zaciancrowned": {
      "inherit": true,
      "baseStats": {
        "hp": 92,
        "atk": 170,
        "def": 115,
        "spa": 80,
        "spd": 115,
        "spe": 148
      }
    },
    "zamazenta": {
      "inherit": true,
      "baseStats": {
        "hp": 92,
        "atk": 130,
        "def": 115,
        "spa": 80,
        "spd": 115,
        "spe": 138
      }
    },
    "zamazentacrowned": {
      "inherit": true,
      "baseStats": {
        "hp": 92,
        "atk": 130,
        "def": 145,
        "spa": 80,
        "spd": 145,
        "spe": 128
      }
    },
    "kleavor": {
      "inherit": true,
      "abilities": {"0": "Swarm", "1": "Sheer Force", "H": "Steadfast"}
    },
    "basculegion": {
      "inherit": true,
      "abilities": {"0": "Rattled", "1": "Adaptability", "H": "Mold Breaker"}
    },
    "basculegionf": {
      "inherit": true,
      "abilities": {"0": "Rattled", "1": "Adaptability", "H": "Mold Breaker"}
    },
    "sneasler": {
      "inherit": true,
      "abilities": {"0": "Pressure", "H": "Poison Touch"},
      "evoType": "useItem",
      "evoItem": "Razor Claw",
      "evoCondition": "during the day"
    },
    "enamorus": {"inherit": true, "abilities": {"0": "Healer", "H": "Contrary"}}
  },
  "9": {
    "bulbasaur": {
      "num": 1,
      "name": "Bulbasaur",
      "types": ["Grass", "Poison"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 45,
        "atk": 49,
        "def": 49,
        "spa": 65,
        "spd": 65,
        "spe": 45
      },
      "abilities": {"0": "Overgrow", "H": "Chlorophyll"},
      "weightkg": 6.9,
      "evos": ["Ivysaur"],
      "eggGroups": ["Monster", "Grass"]
    },
    "ivysaur": {
      "num": 2,
      "name": "Ivysaur",
      "types": ["Grass", "Poison"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 60,
        "atk": 62,
        "def": 63,
        "spa": 80,
        "spd": 80,
        "spe": 60
      },
      "abilities": {"0": "Overgrow", "H": "Chlorophyll"},
      "weightkg": 13,
      "prevo": "Bulbasaur",
      "evoLevel": 16,
      "evos": ["Venusaur"],
      "eggGroups": ["Monster", "Grass"]
    },
    "venusaur": {
      "num": 3,
      "name": "Venusaur",
      "types": ["Grass", "Poison"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 82,
        "def": 83,
        "spa": 100,
        "spd": 100,
        "spe": 80
      },
      "abilities": {"0": "Overgrow", "H": "Chlorophyll"},
      "weightkg": 100,
      "prevo": "Ivysaur",
      "evoLevel": 32,
      "eggGroups": ["Monster", "Grass"],
      "otherFormes": ["Venusaur-Mega"],
      "formeOrder": ["Venusaur", "Venusaur-Mega"],
      "canGigantamax": "G-Max Vine Lash"
    },
    "venusaurmega": {
      "num": 3,
      "name": "Venusaur-Mega",
      "baseSpecies": "Venusaur",
      "forme": "Mega",
      "types": ["Grass", "Poison"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 100,
        "def": 123,
        "spa": 122,
        "spd": 120,
        "spe": 80
      },
      "abilities": {"0": "Thick Fat"},
      "weightkg": 155.5,
      "eggGroups": ["Monster", "Grass"],
      "requiredItem": "Venusaurite"
    },
    "venusaurgmax": {
      "num": 3,
      "name": "Venusaur-Gmax",
      "baseSpecies": "Venusaur",
      "forme": "Gmax",
      "types": ["Grass", "Poison"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 82,
        "def": 83,
        "spa": 100,
        "spd": 100,
        "spe": 80
      },
      "abilities": {"0": "Overgrow", "H": "Chlorophyll"},
      "weightkg": 0,
      "eggGroups": ["Monster", "Grass"],
      "changesFrom": "Venusaur"
    },
    "charmander": {
      "num": 4,
      "name": "Charmander",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 39,
        "atk": 52,
        "def": 43,
        "spa": 60,
        "spd": 50,
        "spe": 65
      },
      "abilities": {"0": "Blaze", "H": "Solar Power"},
      "weightkg": 8.5,
      "evos": ["Charmeleon"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "charmeleon": {
      "num": 5,
      "name": "Charmeleon",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 58,
        "atk": 64,
        "def": 58,
        "spa": 80,
        "spd": 65,
        "spe": 80
      },
      "abilities": {"0": "Blaze", "H": "Solar Power"},
      "weightkg": 19,
      "prevo": "Charmander",
      "evoLevel": 16,
      "evos": ["Charizard"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "charizard": {
      "num": 6,
      "name": "Charizard",
      "types": ["Fire", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 78,
        "atk": 84,
        "def": 78,
        "spa": 109,
        "spd": 85,
        "spe": 100
      },
      "abilities": {"0": "Blaze", "H": "Solar Power"},
      "weightkg": 90.5,
      "prevo": "Charmeleon",
      "evoLevel": 36,
      "eggGroups": ["Monster", "Dragon"],
      "otherFormes": ["Charizard-Mega-X", "Charizard-Mega-Y"],
      "formeOrder": ["Charizard", "Charizard-Mega-X", "Charizard-Mega-Y"],
      "canGigantamax": "G-Max Wildfire"
    },
    "charizardmegax": {
      "num": 6,
      "name": "Charizard-Mega-X",
      "baseSpecies": "Charizard",
      "forme": "Mega-X",
      "types": ["Fire", "Dragon"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 78,
        "atk": 130,
        "def": 111,
        "spa": 130,
        "spd": 85,
        "spe": 100
      },
      "abilities": {"0": "Tough Claws"},
      "weightkg": 110.5,
      "eggGroups": ["Monster", "Dragon"],
      "requiredItem": "Charizardite X"
    },
    "charizardmegay": {
      "num": 6,
      "name": "Charizard-Mega-Y",
      "baseSpecies": "Charizard",
      "forme": "Mega-Y",
      "types": ["Fire", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 78,
        "atk": 104,
        "def": 78,
        "spa": 159,
        "spd": 115,
        "spe": 100
      },
      "abilities": {"0": "Drought"},
      "weightkg": 100.5,
      "eggGroups": ["Monster", "Dragon"],
      "requiredItem": "Charizardite Y"
    },
    "charizardgmax": {
      "num": 6,
      "name": "Charizard-Gmax",
      "baseSpecies": "Charizard",
      "forme": "Gmax",
      "types": ["Fire", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 78,
        "atk": 84,
        "def": 78,
        "spa": 109,
        "spd": 85,
        "spe": 100
      },
      "abilities": {"0": "Blaze", "H": "Solar Power"},
      "weightkg": 0,
      "eggGroups": ["Monster", "Dragon"],
      "changesFrom": "Charizard"
    },
    "squirtle": {
      "num": 7,
      "name": "Squirtle",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 44,
        "atk": 48,
        "def": 65,
        "spa": 50,
        "spd": 64,
        "spe": 43
      },
      "abilities": {"0": "Torrent", "H": "Rain Dish"},
      "weightkg": 9,
      "evos": ["Wartortle"],
      "eggGroups": ["Monster", "Water 1"]
    },
    "wartortle": {
      "num": 8,
      "name": "Wartortle",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 59,
        "atk": 63,
        "def": 80,
        "spa": 65,
        "spd": 80,
        "spe": 58
      },
      "abilities": {"0": "Torrent", "H": "Rain Dish"},
      "weightkg": 22.5,
      "prevo": "Squirtle",
      "evoLevel": 16,
      "evos": ["Blastoise"],
      "eggGroups": ["Monster", "Water 1"]
    },
    "blastoise": {
      "num": 9,
      "name": "Blastoise",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 79,
        "atk": 83,
        "def": 100,
        "spa": 85,
        "spd": 105,
        "spe": 78
      },
      "abilities": {"0": "Torrent", "H": "Rain Dish"},
      "weightkg": 85.5,
      "prevo": "Wartortle",
      "evoLevel": 36,
      "eggGroups": ["Monster", "Water 1"],
      "otherFormes": ["Blastoise-Mega"],
      "formeOrder": ["Blastoise", "Blastoise-Mega"],
      "canGigantamax": "G-Max Cannonade"
    },
    "blastoisemega": {
      "num": 9,
      "name": "Blastoise-Mega",
      "baseSpecies": "Blastoise",
      "forme": "Mega",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 79,
        "atk": 103,
        "def": 120,
        "spa": 135,
        "spd": 115,
        "spe": 78
      },
      "abilities": {"0": "Mega Launcher"},
      "weightkg": 101.1,
      "eggGroups": ["Monster", "Water 1"],
      "requiredItem": "Blastoisinite"
    },
    "blastoisegmax": {
      "num": 9,
      "name": "Blastoise-Gmax",
      "baseSpecies": "Blastoise",
      "forme": "Gmax",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 79,
        "atk": 83,
        "def": 100,
        "spa": 85,
        "spd": 105,
        "spe": 78
      },
      "abilities": {"0": "Torrent", "H": "Rain Dish"},
      "weightkg": 0,
      "eggGroups": ["Monster", "Water 1"],
      "changesFrom": "Blastoise"
    },
    "caterpie": {
      "num": 10,
      "name": "Caterpie",
      "types": ["Bug"],
      "baseStats": {
        "hp": 45,
        "atk": 30,
        "def": 35,
        "spa": 20,
        "spd": 20,
        "spe": 45
      },
      "abilities": {"0": "Shield Dust", "H": "Run Away"},
      "weightkg": 2.9,
      "evos": ["Metapod"],
      "eggGroups": ["Bug"]
    },
    "metapod": {
      "num": 11,
      "name": "Metapod",
      "types": ["Bug"],
      "baseStats": {
        "hp": 50,
        "atk": 20,
        "def": 55,
        "spa": 25,
        "spd": 25,
        "spe": 30
      },
      "abilities": {"0": "Shed Skin"},
      "weightkg": 9.9,
      "prevo": "Caterpie",
      "evoLevel": 7,
      "evos": ["Butterfree"],
      "eggGroups": ["Bug"]
    },
    "butterfree": {
      "num": 12,
      "name": "Butterfree",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 60,
        "atk": 45,
        "def": 50,
        "spa": 90,
        "spd": 80,
        "spe": 70
      },
      "abilities": {"0": "Compound Eyes", "H": "Tinted Lens"},
      "weightkg": 32,
      "prevo": "Metapod",
      "evoLevel": 10,
      "eggGroups": ["Bug"],
      "canGigantamax": "G-Max Befuddle"
    },
    "butterfreegmax": {
      "num": 12,
      "name": "Butterfree-Gmax",
      "baseSpecies": "Butterfree",
      "forme": "Gmax",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 60,
        "atk": 45,
        "def": 50,
        "spa": 90,
        "spd": 80,
        "spe": 70
      },
      "abilities": {"0": "Compound Eyes", "H": "Tinted Lens"},
      "weightkg": 0,
      "eggGroups": ["Bug"],
      "changesFrom": "Butterfree"
    },
    "weedle": {
      "num": 13,
      "name": "Weedle",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 40,
        "atk": 35,
        "def": 30,
        "spa": 20,
        "spd": 20,
        "spe": 50
      },
      "abilities": {"0": "Shield Dust", "H": "Run Away"},
      "weightkg": 3.2,
      "evos": ["Kakuna"],
      "eggGroups": ["Bug"]
    },
    "kakuna": {
      "num": 14,
      "name": "Kakuna",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 45,
        "atk": 25,
        "def": 50,
        "spa": 25,
        "spd": 25,
        "spe": 35
      },
      "abilities": {"0": "Shed Skin"},
      "weightkg": 10,
      "prevo": "Weedle",
      "evoLevel": 7,
      "evos": ["Beedrill"],
      "eggGroups": ["Bug"]
    },
    "beedrill": {
      "num": 15,
      "name": "Beedrill",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 40,
        "spa": 45,
        "spd": 80,
        "spe": 75
      },
      "abilities": {"0": "Swarm", "H": "Sniper"},
      "weightkg": 29.5,
      "prevo": "Kakuna",
      "evoLevel": 10,
      "eggGroups": ["Bug"],
      "otherFormes": ["Beedrill-Mega"],
      "formeOrder": ["Beedrill", "Beedrill-Mega"]
    },
    "beedrillmega": {
      "num": 15,
      "name": "Beedrill-Mega",
      "baseSpecies": "Beedrill",
      "forme": "Mega",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 65,
        "atk": 150,
        "def": 40,
        "spa": 15,
        "spd": 80,
        "spe": 145
      },
      "abilities": {"0": "Adaptability"},
      "weightkg": 40.5,
      "eggGroups": ["Bug"],
      "requiredItem": "Beedrillite"
    },
    "pidgey": {
      "num": 16,
      "name": "Pidgey",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 40,
        "spa": 35,
        "spd": 35,
        "spe": 56
      },
      "abilities": {"0": "Keen Eye", "1": "Tangled Feet", "H": "Big Pecks"},
      "weightkg": 1.8,
      "evos": ["Pidgeotto"],
      "eggGroups": ["Flying"]
    },
    "pidgeotto": {
      "num": 17,
      "name": "Pidgeotto",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 63,
        "atk": 60,
        "def": 55,
        "spa": 50,
        "spd": 50,
        "spe": 71
      },
      "abilities": {"0": "Keen Eye", "1": "Tangled Feet", "H": "Big Pecks"},
      "weightkg": 30,
      "prevo": "Pidgey",
      "evoLevel": 18,
      "evos": ["Pidgeot"],
      "eggGroups": ["Flying"]
    },
    "pidgeot": {
      "num": 18,
      "name": "Pidgeot",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 83,
        "atk": 80,
        "def": 75,
        "spa": 70,
        "spd": 70,
        "spe": 101
      },
      "abilities": {"0": "Keen Eye", "1": "Tangled Feet", "H": "Big Pecks"},
      "weightkg": 39.5,
      "prevo": "Pidgeotto",
      "evoLevel": 36,
      "eggGroups": ["Flying"],
      "otherFormes": ["Pidgeot-Mega"],
      "formeOrder": ["Pidgeot", "Pidgeot-Mega"]
    },
    "pidgeotmega": {
      "num": 18,
      "name": "Pidgeot-Mega",
      "baseSpecies": "Pidgeot",
      "forme": "Mega",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 83,
        "atk": 80,
        "def": 80,
        "spa": 135,
        "spd": 80,
        "spe": 121
      },
      "abilities": {"0": "No Guard"},
      "weightkg": 50.5,
      "eggGroups": ["Flying"],
      "requiredItem": "Pidgeotite"
    },
    "rattata": {
      "num": 19,
      "name": "Rattata",
      "types": ["Normal"],
      "baseStats": {
        "hp": 30,
        "atk": 56,
        "def": 35,
        "spa": 25,
        "spd": 35,
        "spe": 72
      },
      "abilities": {"0": "Run Away", "1": "Guts", "H": "Hustle"},
      "weightkg": 3.5,
      "evos": ["Raticate"],
      "eggGroups": ["Field"],
      "otherFormes": ["Rattata-Alola"],
      "formeOrder": ["Rattata", "Rattata-Alola"]
    },
    "rattataalola": {
      "num": 19,
      "name": "Rattata-Alola",
      "baseSpecies": "Rattata",
      "forme": "Alola",
      "types": ["Dark", "Normal"],
      "baseStats": {
        "hp": 30,
        "atk": 56,
        "def": 35,
        "spa": 25,
        "spd": 35,
        "spe": 72
      },
      "abilities": {"0": "Gluttony", "1": "Hustle", "H": "Thick Fat"},
      "weightkg": 3.8,
      "evos": ["Raticate-Alola"],
      "eggGroups": ["Field"]
    },
    "raticate": {
      "num": 20,
      "name": "Raticate",
      "types": ["Normal"],
      "baseStats": {
        "hp": 55,
        "atk": 81,
        "def": 60,
        "spa": 50,
        "spd": 70,
        "spe": 97
      },
      "abilities": {"0": "Run Away", "1": "Guts", "H": "Hustle"},
      "weightkg": 18.5,
      "prevo": "Rattata",
      "evoLevel": 20,
      "eggGroups": ["Field"],
      "otherFormes": ["Raticate-Alola", "Raticate-Alola-Totem"],
      "formeOrder": ["Raticate", "Raticate-Alola", "Raticate-Alola-Totem"]
    },
    "raticatealola": {
      "num": 20,
      "name": "Raticate-Alola",
      "baseSpecies": "Raticate",
      "forme": "Alola",
      "types": ["Dark", "Normal"],
      "baseStats": {
        "hp": 75,
        "atk": 71,
        "def": 70,
        "spa": 40,
        "spd": 80,
        "spe": 77
      },
      "abilities": {"0": "Gluttony", "1": "Hustle", "H": "Thick Fat"},
      "weightkg": 25.5,
      "prevo": "Rattata-Alola",
      "evoLevel": 20,
      "evoCondition": "at night",
      "eggGroups": ["Field"]
    },
    "raticatealolatotem": {
      "num": 20,
      "name": "Raticate-Alola-Totem",
      "baseSpecies": "Raticate",
      "forme": "Alola-Totem",
      "types": ["Dark", "Normal"],
      "baseStats": {
        "hp": 75,
        "atk": 71,
        "def": 70,
        "spa": 40,
        "spd": 80,
        "spe": 77
      },
      "abilities": {"0": "Thick Fat"},
      "weightkg": 105,
      "eggGroups": ["Field"]
    },
    "spearow": {
      "num": 21,
      "name": "Spearow",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 40,
        "atk": 60,
        "def": 30,
        "spa": 31,
        "spd": 31,
        "spe": 70
      },
      "abilities": {"0": "Keen Eye", "H": "Sniper"},
      "weightkg": 2,
      "evos": ["Fearow"],
      "eggGroups": ["Flying"]
    },
    "fearow": {
      "num": 22,
      "name": "Fearow",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 65,
        "spa": 61,
        "spd": 61,
        "spe": 100
      },
      "abilities": {"0": "Keen Eye", "H": "Sniper"},
      "weightkg": 38,
      "prevo": "Spearow",
      "evoLevel": 20,
      "eggGroups": ["Flying"]
    },
    "ekans": {
      "num": 23,
      "name": "Ekans",
      "types": ["Poison"],
      "baseStats": {
        "hp": 35,
        "atk": 60,
        "def": 44,
        "spa": 40,
        "spd": 54,
        "spe": 55
      },
      "abilities": {"0": "Intimidate", "1": "Shed Skin", "H": "Unnerve"},
      "weightkg": 6.9,
      "evos": ["Arbok"],
      "eggGroups": ["Field", "Dragon"]
    },
    "arbok": {
      "num": 24,
      "name": "Arbok",
      "types": ["Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 95,
        "def": 69,
        "spa": 65,
        "spd": 79,
        "spe": 80
      },
      "abilities": {"0": "Intimidate", "1": "Shed Skin", "H": "Unnerve"},
      "weightkg": 65,
      "prevo": "Ekans",
      "evoLevel": 22,
      "eggGroups": ["Field", "Dragon"]
    },
    "pikachu": {
      "num": 25,
      "name": "Pikachu",
      "types": ["Electric"],
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "prevo": "Pichu",
      "evoType": "levelFriendship",
      "evos": ["Raichu", "Raichu-Alola"],
      "eggGroups": ["Field", "Fairy"],
      "otherFormes": [
        "Pikachu-Cosplay",
        "Pikachu-Rock-Star",
        "Pikachu-Belle",
        "Pikachu-Pop-Star",
        "Pikachu-PhD",
        "Pikachu-Libre",
        "Pikachu-Original",
        "Pikachu-Hoenn",
        "Pikachu-Sinnoh",
        "Pikachu-Unova",
        "Pikachu-Kalos",
        "Pikachu-Alola",
        "Pikachu-Partner",
        "Pikachu-Starter",
        "Pikachu-World"
      ],
      "formeOrder": [
        "Pikachu",
        "Pikachu-Original",
        "Pikachu-Hoenn",
        "Pikachu-Sinnoh",
        "Pikachu-Unova",
        "Pikachu-Kalos",
        "Pikachu-Alola",
        "Pikachu-Partner",
        "Pikachu-Starter",
        "Pikachu-World",
        "Pikachu-Rock-Star",
        "Pikachu-Belle",
        "Pikachu-Pop-Star",
        "Pikachu-PhD",
        "Pikachu-Libre",
        "Pikachu-Cosplay"
      ],
      "canGigantamax": "G-Max Volt Crash"
    },
    "pikachucosplay": {
      "num": 25,
      "name": "Pikachu-Cosplay",
      "baseSpecies": "Pikachu",
      "forme": "Cosplay",
      "types": ["Electric"],
      "gender": "F",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "gen": 6
    },
    "pikachurockstar": {
      "num": 25,
      "name": "Pikachu-Rock-Star",
      "baseSpecies": "Pikachu",
      "forme": "Rock-Star",
      "types": ["Electric"],
      "gender": "F",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Pikachu-Cosplay",
      "gen": 6
    },
    "pikachubelle": {
      "num": 25,
      "name": "Pikachu-Belle",
      "baseSpecies": "Pikachu",
      "forme": "Belle",
      "types": ["Electric"],
      "gender": "F",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Pikachu-Cosplay",
      "gen": 6
    },
    "pikachupopstar": {
      "num": 25,
      "name": "Pikachu-Pop-Star",
      "baseSpecies": "Pikachu",
      "forme": "Pop-Star",
      "types": ["Electric"],
      "gender": "F",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Pikachu-Cosplay",
      "gen": 6
    },
    "pikachuphd": {
      "num": 25,
      "name": "Pikachu-PhD",
      "baseSpecies": "Pikachu",
      "forme": "PhD",
      "types": ["Electric"],
      "gender": "F",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Pikachu-Cosplay",
      "gen": 6
    },
    "pikachulibre": {
      "num": 25,
      "name": "Pikachu-Libre",
      "baseSpecies": "Pikachu",
      "forme": "Libre",
      "types": ["Electric"],
      "gender": "F",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Pikachu-Cosplay",
      "gen": 6
    },
    "pikachuoriginal": {
      "num": 25,
      "name": "Pikachu-Original",
      "baseSpecies": "Pikachu",
      "forme": "Original",
      "types": ["Electric"],
      "gender": "M",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "gen": 7
    },
    "pikachuhoenn": {
      "num": 25,
      "name": "Pikachu-Hoenn",
      "baseSpecies": "Pikachu",
      "forme": "Hoenn",
      "types": ["Electric"],
      "gender": "M",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "gen": 7
    },
    "pikachusinnoh": {
      "num": 25,
      "name": "Pikachu-Sinnoh",
      "baseSpecies": "Pikachu",
      "forme": "Sinnoh",
      "types": ["Electric"],
      "gender": "M",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "gen": 7
    },
    "pikachuunova": {
      "num": 25,
      "name": "Pikachu-Unova",
      "baseSpecies": "Pikachu",
      "forme": "Unova",
      "types": ["Electric"],
      "gender": "M",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "gen": 7
    },
    "pikachukalos": {
      "num": 25,
      "name": "Pikachu-Kalos",
      "baseSpecies": "Pikachu",
      "forme": "Kalos",
      "types": ["Electric"],
      "gender": "M",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "gen": 7
    },
    "pikachualola": {
      "num": 25,
      "name": "Pikachu-Alola",
      "baseSpecies": "Pikachu",
      "forme": "Alola",
      "types": ["Electric"],
      "gender": "M",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "gen": 7
    },
    "pikachupartner": {
      "num": 25,
      "name": "Pikachu-Partner",
      "baseSpecies": "Pikachu",
      "forme": "Partner",
      "types": ["Electric"],
      "gender": "M",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "gen": 7
    },
    "pikachustarter": {
      "num": 25,
      "name": "Pikachu-Starter",
      "baseSpecies": "Pikachu",
      "forme": "Starter",
      "types": ["Electric"],
      "baseStats": {
        "hp": 45,
        "atk": 80,
        "def": 50,
        "spa": 75,
        "spd": 60,
        "spe": 120
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"]
    },
    "pikachugmax": {
      "num": 25,
      "name": "Pikachu-Gmax",
      "baseSpecies": "Pikachu",
      "forme": "Gmax",
      "types": ["Electric"],
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 0,
      "eggGroups": ["Field", "Fairy"],
      "changesFrom": "Pikachu"
    },
    "pikachuworld": {
      "num": 25,
      "name": "Pikachu-World",
      "baseSpecies": "Pikachu",
      "forme": "World",
      "types": ["Electric"],
      "gender": "M",
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 6,
      "eggGroups": ["Undiscovered"],
      "gen": 8
    },
    "raichu": {
      "num": 26,
      "name": "Raichu",
      "types": ["Electric"],
      "baseStats": {
        "hp": 60,
        "atk": 90,
        "def": 55,
        "spa": 90,
        "spd": 80,
        "spe": 110
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 30,
      "prevo": "Pikachu",
      "evoType": "useItem",
      "evoItem": "Thunder Stone",
      "eggGroups": ["Field", "Fairy"],
      "otherFormes": ["Raichu-Alola"],
      "formeOrder": ["Raichu", "Raichu-Alola"]
    },
    "raichualola": {
      "num": 26,
      "name": "Raichu-Alola",
      "baseSpecies": "Raichu",
      "forme": "Alola",
      "types": ["Electric", "Psychic"],
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 50,
        "spa": 95,
        "spd": 85,
        "spe": 110
      },
      "abilities": {"0": "Surge Surfer"},
      "weightkg": 21,
      "prevo": "Pikachu",
      "evoType": "useItem",
      "evoItem": "Thunder Stone",
      "evoRegion": "Alola",
      "eggGroups": ["Field", "Fairy"]
    },
    "sandshrew": {
      "num": 27,
      "name": "Sandshrew",
      "types": ["Ground"],
      "baseStats": {
        "hp": 50,
        "atk": 75,
        "def": 85,
        "spa": 20,
        "spd": 30,
        "spe": 40
      },
      "abilities": {"0": "Sand Veil", "H": "Sand Rush"},
      "weightkg": 12,
      "evos": ["Sandslash"],
      "eggGroups": ["Field"],
      "otherFormes": ["Sandshrew-Alola"],
      "formeOrder": ["Sandshrew", "Sandshrew-Alola"]
    },
    "sandshrewalola": {
      "num": 27,
      "name": "Sandshrew-Alola",
      "baseSpecies": "Sandshrew",
      "forme": "Alola",
      "types": ["Ice", "Steel"],
      "baseStats": {
        "hp": 50,
        "atk": 75,
        "def": 90,
        "spa": 10,
        "spd": 35,
        "spe": 40
      },
      "abilities": {"0": "Snow Cloak", "H": "Slush Rush"},
      "weightkg": 40,
      "evos": ["Sandslash-Alola"],
      "eggGroups": ["Field"]
    },
    "sandslash": {
      "num": 28,
      "name": "Sandslash",
      "types": ["Ground"],
      "baseStats": {
        "hp": 75,
        "atk": 100,
        "def": 110,
        "spa": 45,
        "spd": 55,
        "spe": 65
      },
      "abilities": {"0": "Sand Veil", "H": "Sand Rush"},
      "weightkg": 29.5,
      "prevo": "Sandshrew",
      "evoLevel": 22,
      "eggGroups": ["Field"],
      "otherFormes": ["Sandslash-Alola"],
      "formeOrder": ["Sandslash", "Sandslash-Alola"]
    },
    "sandslashalola": {
      "num": 28,
      "name": "Sandslash-Alola",
      "baseSpecies": "Sandslash",
      "forme": "Alola",
      "types": ["Ice", "Steel"],
      "baseStats": {
        "hp": 75,
        "atk": 100,
        "def": 120,
        "spa": 25,
        "spd": 65,
        "spe": 65
      },
      "abilities": {"0": "Snow Cloak", "H": "Slush Rush"},
      "weightkg": 55,
      "prevo": "Sandshrew-Alola",
      "evoType": "useItem",
      "evoItem": "Ice Stone",
      "eggGroups": ["Field"]
    },
    "nidoranf": {
      "num": 29,
      "name": "Nidoran-F",
      "types": ["Poison"],
      "gender": "F",
      "baseStats": {
        "hp": 55,
        "atk": 47,
        "def": 52,
        "spa": 40,
        "spd": 40,
        "spe": 41
      },
      "abilities": {"0": "Poison Point", "1": "Rivalry", "H": "Hustle"},
      "weightkg": 7,
      "evos": ["Nidorina"],
      "eggGroups": ["Monster", "Field"]
    },
    "nidorina": {
      "num": 30,
      "name": "Nidorina",
      "types": ["Poison"],
      "gender": "F",
      "baseStats": {
        "hp": 70,
        "atk": 62,
        "def": 67,
        "spa": 55,
        "spd": 55,
        "spe": 56
      },
      "abilities": {"0": "Poison Point", "1": "Rivalry", "H": "Hustle"},
      "weightkg": 20,
      "prevo": "Nidoran-F",
      "evoLevel": 16,
      "evos": ["Nidoqueen"],
      "eggGroups": ["Undiscovered"]
    },
    "nidoqueen": {
      "num": 31,
      "name": "Nidoqueen",
      "types": ["Poison", "Ground"],
      "gender": "F",
      "baseStats": {
        "hp": 90,
        "atk": 92,
        "def": 87,
        "spa": 75,
        "spd": 85,
        "spe": 76
      },
      "abilities": {"0": "Poison Point", "1": "Rivalry", "H": "Sheer Force"},
      "weightkg": 60,
      "prevo": "Nidorina",
      "evoType": "useItem",
      "evoItem": "Moon Stone",
      "eggGroups": ["Undiscovered"]
    },
    "nidoranm": {
      "num": 32,
      "name": "Nidoran-M",
      "types": ["Poison"],
      "gender": "M",
      "baseStats": {
        "hp": 46,
        "atk": 57,
        "def": 40,
        "spa": 40,
        "spd": 40,
        "spe": 50
      },
      "abilities": {"0": "Poison Point", "1": "Rivalry", "H": "Hustle"},
      "weightkg": 9,
      "evos": ["Nidorino"],
      "eggGroups": ["Monster", "Field"]
    },
    "nidorino": {
      "num": 33,
      "name": "Nidorino",
      "types": ["Poison"],
      "gender": "M",
      "baseStats": {
        "hp": 61,
        "atk": 72,
        "def": 57,
        "spa": 55,
        "spd": 55,
        "spe": 65
      },
      "abilities": {"0": "Poison Point", "1": "Rivalry", "H": "Hustle"},
      "weightkg": 19.5,
      "prevo": "Nidoran-M",
      "evoLevel": 16,
      "evos": ["Nidoking"],
      "eggGroups": ["Monster", "Field"]
    },
    "nidoking": {
      "num": 34,
      "name": "Nidoking",
      "types": ["Poison", "Ground"],
      "gender": "M",
      "baseStats": {
        "hp": 81,
        "atk": 102,
        "def": 77,
        "spa": 85,
        "spd": 75,
        "spe": 85
      },
      "abilities": {"0": "Poison Point", "1": "Rivalry", "H": "Sheer Force"},
      "weightkg": 62,
      "prevo": "Nidorino",
      "evoType": "useItem",
      "evoItem": "Moon Stone",
      "eggGroups": ["Monster", "Field"]
    },
    "clefairy": {
      "num": 35,
      "name": "Clefairy",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 70,
        "atk": 45,
        "def": 48,
        "spa": 60,
        "spd": 65,
        "spe": 35
      },
      "abilities": {"0": "Cute Charm", "1": "Magic Guard", "H": "Friend Guard"},
      "weightkg": 7.5,
      "prevo": "Cleffa",
      "evoType": "levelFriendship",
      "evos": ["Clefable"],
      "eggGroups": ["Fairy"]
    },
    "clefable": {
      "num": 36,
      "name": "Clefable",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 95,
        "atk": 70,
        "def": 73,
        "spa": 95,
        "spd": 90,
        "spe": 60
      },
      "abilities": {"0": "Cute Charm", "1": "Magic Guard", "H": "Unaware"},
      "weightkg": 40,
      "prevo": "Clefairy",
      "evoType": "useItem",
      "evoItem": "Moon Stone",
      "eggGroups": ["Fairy"]
    },
    "vulpix": {
      "num": 37,
      "name": "Vulpix",
      "types": ["Fire"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 38,
        "atk": 41,
        "def": 40,
        "spa": 50,
        "spd": 65,
        "spe": 65
      },
      "abilities": {"0": "Flash Fire", "H": "Drought"},
      "weightkg": 9.9,
      "evos": ["Ninetales"],
      "eggGroups": ["Field"],
      "otherFormes": ["Vulpix-Alola"],
      "formeOrder": ["Vulpix", "Vulpix-Alola"]
    },
    "vulpixalola": {
      "num": 37,
      "name": "Vulpix-Alola",
      "baseSpecies": "Vulpix",
      "forme": "Alola",
      "types": ["Ice"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 38,
        "atk": 41,
        "def": 40,
        "spa": 50,
        "spd": 65,
        "spe": 65
      },
      "abilities": {"0": "Snow Cloak", "H": "Snow Warning"},
      "weightkg": 9.9,
      "evos": ["Ninetales-Alola"],
      "eggGroups": ["Field"]
    },
    "ninetales": {
      "num": 38,
      "name": "Ninetales",
      "types": ["Fire"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 73,
        "atk": 76,
        "def": 75,
        "spa": 81,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Flash Fire", "H": "Drought"},
      "weightkg": 19.9,
      "prevo": "Vulpix",
      "evoType": "useItem",
      "evoItem": "Fire Stone",
      "eggGroups": ["Field"],
      "otherFormes": ["Ninetales-Alola"],
      "formeOrder": ["Ninetales", "Ninetales-Alola"]
    },
    "ninetalesalola": {
      "num": 38,
      "name": "Ninetales-Alola",
      "baseSpecies": "Ninetales",
      "forme": "Alola",
      "types": ["Ice", "Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 73,
        "atk": 67,
        "def": 75,
        "spa": 81,
        "spd": 100,
        "spe": 109
      },
      "abilities": {"0": "Snow Cloak", "H": "Snow Warning"},
      "weightkg": 19.9,
      "prevo": "Vulpix-Alola",
      "evoType": "useItem",
      "evoItem": "Ice Stone",
      "eggGroups": ["Field"]
    },
    "jigglypuff": {
      "num": 39,
      "name": "Jigglypuff",
      "types": ["Normal", "Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 115,
        "atk": 45,
        "def": 20,
        "spa": 45,
        "spd": 25,
        "spe": 20
      },
      "abilities": {"0": "Cute Charm", "1": "Competitive", "H": "Friend Guard"},
      "weightkg": 5.5,
      "prevo": "Igglybuff",
      "evoType": "levelFriendship",
      "evos": ["Wigglytuff"],
      "eggGroups": ["Fairy"]
    },
    "wigglytuff": {
      "num": 40,
      "name": "Wigglytuff",
      "types": ["Normal", "Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 140,
        "atk": 70,
        "def": 45,
        "spa": 85,
        "spd": 50,
        "spe": 45
      },
      "abilities": {"0": "Cute Charm", "1": "Competitive", "H": "Frisk"},
      "weightkg": 12,
      "prevo": "Jigglypuff",
      "evoType": "useItem",
      "evoItem": "Moon Stone",
      "eggGroups": ["Fairy"]
    },
    "zubat": {
      "num": 41,
      "name": "Zubat",
      "types": ["Poison", "Flying"],
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 35,
        "spa": 30,
        "spd": 40,
        "spe": 55
      },
      "abilities": {"0": "Inner Focus", "H": "Infiltrator"},
      "weightkg": 7.5,
      "evos": ["Golbat"],
      "eggGroups": ["Flying"]
    },
    "golbat": {
      "num": 42,
      "name": "Golbat",
      "types": ["Poison", "Flying"],
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 70,
        "spa": 65,
        "spd": 75,
        "spe": 90
      },
      "abilities": {"0": "Inner Focus", "H": "Infiltrator"},
      "weightkg": 55,
      "prevo": "Zubat",
      "evoLevel": 22,
      "evos": ["Crobat"],
      "eggGroups": ["Flying"]
    },
    "oddish": {
      "num": 43,
      "name": "Oddish",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 45,
        "atk": 50,
        "def": 55,
        "spa": 75,
        "spd": 65,
        "spe": 30
      },
      "abilities": {"0": "Chlorophyll", "H": "Run Away"},
      "weightkg": 5.4,
      "evos": ["Gloom"],
      "eggGroups": ["Grass"]
    },
    "gloom": {
      "num": 44,
      "name": "Gloom",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 65,
        "def": 70,
        "spa": 85,
        "spd": 75,
        "spe": 40
      },
      "abilities": {"0": "Chlorophyll", "H": "Stench"},
      "weightkg": 8.6,
      "prevo": "Oddish",
      "evoLevel": 21,
      "evos": ["Vileplume", "Bellossom"],
      "eggGroups": ["Grass"]
    },
    "vileplume": {
      "num": 45,
      "name": "Vileplume",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 85,
        "spa": 110,
        "spd": 90,
        "spe": 50
      },
      "abilities": {"0": "Chlorophyll", "H": "Effect Spore"},
      "weightkg": 18.6,
      "prevo": "Gloom",
      "evoType": "useItem",
      "evoItem": "Leaf Stone",
      "eggGroups": ["Grass"]
    },
    "paras": {
      "num": 46,
      "name": "Paras",
      "types": ["Bug", "Grass"],
      "baseStats": {
        "hp": 35,
        "atk": 70,
        "def": 55,
        "spa": 45,
        "spd": 55,
        "spe": 25
      },
      "abilities": {"0": "Effect Spore", "1": "Dry Skin", "H": "Damp"},
      "weightkg": 5.4,
      "evos": ["Parasect"],
      "eggGroups": ["Bug", "Grass"]
    },
    "parasect": {
      "num": 47,
      "name": "Parasect",
      "types": ["Bug", "Grass"],
      "baseStats": {
        "hp": 60,
        "atk": 95,
        "def": 80,
        "spa": 60,
        "spd": 80,
        "spe": 30
      },
      "abilities": {"0": "Effect Spore", "1": "Dry Skin", "H": "Damp"},
      "weightkg": 29.5,
      "prevo": "Paras",
      "evoLevel": 24,
      "eggGroups": ["Bug", "Grass"]
    },
    "venonat": {
      "num": 48,
      "name": "Venonat",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 55,
        "def": 50,
        "spa": 40,
        "spd": 55,
        "spe": 45
      },
      "abilities": {"0": "Compound Eyes", "1": "Tinted Lens", "H": "Run Away"},
      "weightkg": 30,
      "evos": ["Venomoth"],
      "eggGroups": ["Bug"]
    },
    "venomoth": {
      "num": 49,
      "name": "Venomoth",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 70,
        "atk": 65,
        "def": 60,
        "spa": 90,
        "spd": 75,
        "spe": 90
      },
      "abilities": {"0": "Shield Dust", "1": "Tinted Lens", "H": "Wonder Skin"},
      "weightkg": 12.5,
      "prevo": "Venonat",
      "evoLevel": 31,
      "eggGroups": ["Bug"]
    },
    "diglett": {
      "num": 50,
      "name": "Diglett",
      "types": ["Ground"],
      "baseStats": {
        "hp": 10,
        "atk": 55,
        "def": 25,
        "spa": 35,
        "spd": 45,
        "spe": 95
      },
      "abilities": {"0": "Sand Veil", "1": "Arena Trap", "H": "Sand Force"},
      "weightkg": 0.8,
      "evos": ["Dugtrio"],
      "eggGroups": ["Field"],
      "otherFormes": ["Diglett-Alola"],
      "formeOrder": ["Diglett", "Diglett-Alola"]
    },
    "diglettalola": {
      "num": 50,
      "name": "Diglett-Alola",
      "baseSpecies": "Diglett",
      "forme": "Alola",
      "types": ["Ground", "Steel"],
      "baseStats": {
        "hp": 10,
        "atk": 55,
        "def": 30,
        "spa": 35,
        "spd": 45,
        "spe": 90
      },
      "abilities": {"0": "Sand Veil", "1": "Tangling Hair", "H": "Sand Force"},
      "weightkg": 1,
      "evos": ["Dugtrio-Alola"],
      "eggGroups": ["Field"]
    },
    "dugtrio": {
      "num": 51,
      "name": "Dugtrio",
      "types": ["Ground"],
      "baseStats": {
        "hp": 35,
        "atk": 100,
        "def": 50,
        "spa": 50,
        "spd": 70,
        "spe": 120
      },
      "abilities": {"0": "Sand Veil", "1": "Arena Trap", "H": "Sand Force"},
      "weightkg": 33.3,
      "prevo": "Diglett",
      "evoLevel": 26,
      "eggGroups": ["Field"],
      "otherFormes": ["Dugtrio-Alola"],
      "formeOrder": ["Dugtrio", "Dugtrio-Alola"]
    },
    "dugtrioalola": {
      "num": 51,
      "name": "Dugtrio-Alola",
      "baseSpecies": "Dugtrio",
      "forme": "Alola",
      "types": ["Ground", "Steel"],
      "baseStats": {
        "hp": 35,
        "atk": 100,
        "def": 60,
        "spa": 50,
        "spd": 70,
        "spe": 110
      },
      "abilities": {"0": "Sand Veil", "1": "Tangling Hair", "H": "Sand Force"},
      "weightkg": 66.6,
      "prevo": "Diglett-Alola",
      "evoLevel": 26,
      "eggGroups": ["Field"]
    },
    "meowth": {
      "num": 52,
      "name": "Meowth",
      "types": ["Normal"],
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 35,
        "spa": 40,
        "spd": 40,
        "spe": 90
      },
      "abilities": {"0": "Pickup", "1": "Technician", "H": "Unnerve"},
      "weightkg": 4.2,
      "evos": ["Persian"],
      "eggGroups": ["Field"],
      "otherFormes": ["Meowth-Alola", "Meowth-Galar"],
      "formeOrder": ["Meowth", "Meowth-Alola", "Meowth-Galar"],
      "canGigantamax": "G-Max Gold Rush"
    },
    "meowthalola": {
      "num": 52,
      "name": "Meowth-Alola",
      "baseSpecies": "Meowth",
      "forme": "Alola",
      "types": ["Dark"],
      "baseStats": {
        "hp": 40,
        "atk": 35,
        "def": 35,
        "spa": 50,
        "spd": 40,
        "spe": 90
      },
      "abilities": {"0": "Pickup", "1": "Technician", "H": "Rattled"},
      "weightkg": 4.2,
      "evos": ["Persian-Alola"],
      "eggGroups": ["Field"]
    },
    "meowthgalar": {
      "num": 52,
      "name": "Meowth-Galar",
      "baseSpecies": "Meowth",
      "forme": "Galar",
      "types": ["Steel"],
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 55,
        "spa": 40,
        "spd": 40,
        "spe": 40
      },
      "abilities": {"0": "Pickup", "1": "Tough Claws", "H": "Unnerve"},
      "weightkg": 7.5,
      "evos": ["Perrserker"],
      "eggGroups": ["Field"]
    },
    "meowthgmax": {
      "num": 52,
      "name": "Meowth-Gmax",
      "baseSpecies": "Meowth",
      "forme": "Gmax",
      "types": ["Normal"],
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 35,
        "spa": 40,
        "spd": 40,
        "spe": 90
      },
      "abilities": {"0": "Pickup", "1": "Technician", "H": "Unnerve"},
      "weightkg": 0,
      "eggGroups": ["Field"],
      "changesFrom": "Meowth"
    },
    "persian": {
      "num": 53,
      "name": "Persian",
      "types": ["Normal"],
      "baseStats": {
        "hp": 65,
        "atk": 70,
        "def": 60,
        "spa": 65,
        "spd": 65,
        "spe": 115
      },
      "abilities": {"0": "Limber", "1": "Technician", "H": "Unnerve"},
      "weightkg": 32,
      "prevo": "Meowth",
      "evoLevel": 28,
      "eggGroups": ["Field"],
      "otherFormes": ["Persian-Alola"],
      "formeOrder": ["Persian", "Persian-Alola"]
    },
    "persianalola": {
      "num": 53,
      "name": "Persian-Alola",
      "baseSpecies": "Persian",
      "forme": "Alola",
      "types": ["Dark"],
      "baseStats": {
        "hp": 65,
        "atk": 60,
        "def": 60,
        "spa": 75,
        "spd": 65,
        "spe": 115
      },
      "abilities": {"0": "Fur Coat", "1": "Technician", "H": "Rattled"},
      "weightkg": 33,
      "prevo": "Meowth-Alola",
      "evoType": "levelFriendship",
      "eggGroups": ["Field"]
    },
    "psyduck": {
      "num": 54,
      "name": "Psyduck",
      "types": ["Water"],
      "baseStats": {
        "hp": 50,
        "atk": 52,
        "def": 48,
        "spa": 65,
        "spd": 50,
        "spe": 55
      },
      "abilities": {"0": "Damp", "1": "Cloud Nine", "H": "Swift Swim"},
      "weightkg": 19.6,
      "evos": ["Golduck"],
      "eggGroups": ["Water 1", "Field"]
    },
    "golduck": {
      "num": 55,
      "name": "Golduck",
      "types": ["Water"],
      "baseStats": {
        "hp": 80,
        "atk": 82,
        "def": 78,
        "spa": 95,
        "spd": 80,
        "spe": 85
      },
      "abilities": {"0": "Damp", "1": "Cloud Nine", "H": "Swift Swim"},
      "weightkg": 76.6,
      "prevo": "Psyduck",
      "evoLevel": 33,
      "eggGroups": ["Water 1", "Field"]
    },
    "mankey": {
      "num": 56,
      "name": "Mankey",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 40,
        "atk": 80,
        "def": 35,
        "spa": 35,
        "spd": 45,
        "spe": 70
      },
      "abilities": {"0": "Vital Spirit", "1": "Anger Point", "H": "Defiant"},
      "weightkg": 28,
      "evos": ["Primeape"],
      "eggGroups": ["Field"]
    },
    "primeape": {
      "num": 57,
      "name": "Primeape",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 65,
        "atk": 105,
        "def": 60,
        "spa": 60,
        "spd": 70,
        "spe": 95
      },
      "abilities": {"0": "Vital Spirit", "1": "Anger Point", "H": "Defiant"},
      "weightkg": 32,
      "prevo": "Mankey",
      "evoLevel": 28,
      "evos": ["Annihilape"],
      "eggGroups": ["Field"]
    },
    "growlithe": {
      "num": 58,
      "name": "Growlithe",
      "types": ["Fire"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 55,
        "atk": 70,
        "def": 45,
        "spa": 70,
        "spd": 50,
        "spe": 60
      },
      "abilities": {"0": "Intimidate", "1": "Flash Fire", "H": "Justified"},
      "weightkg": 19,
      "evos": ["Arcanine"],
      "eggGroups": ["Field"],
      "otherFormes": ["Growlithe-Hisui"],
      "formeOrder": ["Growlithe", "Growlithe-Hisui"]
    },
    "growlithehisui": {
      "num": 58,
      "name": "Growlithe-Hisui",
      "baseSpecies": "Growlithe",
      "forme": "Hisui",
      "types": ["Fire", "Rock"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 60,
        "atk": 75,
        "def": 45,
        "spa": 65,
        "spd": 50,
        "spe": 55
      },
      "abilities": {"0": "Intimidate", "1": "Flash Fire", "H": "Rock Head"},
      "weightkg": 22.7,
      "evos": ["Arcanine-Hisui"],
      "eggGroups": ["Field"]
    },
    "arcanine": {
      "num": 59,
      "name": "Arcanine",
      "types": ["Fire"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 90,
        "atk": 110,
        "def": 80,
        "spa": 100,
        "spd": 80,
        "spe": 95
      },
      "abilities": {"0": "Intimidate", "1": "Flash Fire", "H": "Justified"},
      "weightkg": 155,
      "prevo": "Growlithe",
      "evoType": "useItem",
      "evoItem": "Fire Stone",
      "eggGroups": ["Field"],
      "otherFormes": ["Arcanine-Hisui"],
      "formeOrder": ["Arcanine", "Arcanine-Hisui"]
    },
    "arcaninehisui": {
      "num": 59,
      "name": "Arcanine-Hisui",
      "baseSpecies": "Arcanine",
      "forme": "Hisui",
      "types": ["Fire", "Rock"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 95,
        "atk": 115,
        "def": 80,
        "spa": 95,
        "spd": 80,
        "spe": 90
      },
      "abilities": {"0": "Intimidate", "1": "Flash Fire", "H": "Rock Head"},
      "weightkg": 168,
      "prevo": "Growlithe-Hisui",
      "evoType": "useItem",
      "evoItem": "Fire Stone",
      "eggGroups": ["Field"]
    },
    "poliwag": {
      "num": 60,
      "name": "Poliwag",
      "types": ["Water"],
      "baseStats": {
        "hp": 40,
        "atk": 50,
        "def": 40,
        "spa": 40,
        "spd": 40,
        "spe": 90
      },
      "abilities": {"0": "Water Absorb", "1": "Damp", "H": "Swift Swim"},
      "weightkg": 12.4,
      "evos": ["Poliwhirl"],
      "eggGroups": ["Water 1"]
    },
    "poliwhirl": {
      "num": 61,
      "name": "Poliwhirl",
      "types": ["Water"],
      "baseStats": {
        "hp": 65,
        "atk": 65,
        "def": 65,
        "spa": 50,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Water Absorb", "1": "Damp", "H": "Swift Swim"},
      "weightkg": 20,
      "prevo": "Poliwag",
      "evoLevel": 25,
      "evos": ["Poliwrath", "Politoed"],
      "eggGroups": ["Water 1"]
    },
    "poliwrath": {
      "num": 62,
      "name": "Poliwrath",
      "types": ["Water", "Fighting"],
      "baseStats": {
        "hp": 90,
        "atk": 95,
        "def": 95,
        "spa": 70,
        "spd": 90,
        "spe": 70
      },
      "abilities": {"0": "Water Absorb", "1": "Damp", "H": "Swift Swim"},
      "weightkg": 54,
      "prevo": "Poliwhirl",
      "evoType": "useItem",
      "evoItem": "Water Stone",
      "eggGroups": ["Water 1"]
    },
    "abra": {
      "num": 63,
      "name": "Abra",
      "types": ["Psychic"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 25,
        "atk": 20,
        "def": 15,
        "spa": 105,
        "spd": 55,
        "spe": 90
      },
      "abilities": {"0": "Synchronize", "1": "Inner Focus", "H": "Magic Guard"},
      "weightkg": 19.5,
      "evos": ["Kadabra"],
      "eggGroups": ["Human-Like"]
    },
    "kadabra": {
      "num": 64,
      "name": "Kadabra",
      "types": ["Psychic"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 40,
        "atk": 35,
        "def": 30,
        "spa": 120,
        "spd": 70,
        "spe": 105
      },
      "abilities": {"0": "Synchronize", "1": "Inner Focus", "H": "Magic Guard"},
      "weightkg": 56.5,
      "prevo": "Abra",
      "evoLevel": 16,
      "evos": ["Alakazam"],
      "eggGroups": ["Human-Like"]
    },
    "alakazam": {
      "num": 65,
      "name": "Alakazam",
      "types": ["Psychic"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 55,
        "atk": 50,
        "def": 45,
        "spa": 135,
        "spd": 95,
        "spe": 120
      },
      "abilities": {"0": "Synchronize", "1": "Inner Focus", "H": "Magic Guard"},
      "weightkg": 48,
      "prevo": "Kadabra",
      "evoType": "trade",
      "eggGroups": ["Human-Like"],
      "otherFormes": ["Alakazam-Mega"],
      "formeOrder": ["Alakazam", "Alakazam-Mega"]
    },
    "alakazammega": {
      "num": 65,
      "name": "Alakazam-Mega",
      "baseSpecies": "Alakazam",
      "forme": "Mega",
      "types": ["Psychic"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 55,
        "atk": 50,
        "def": 65,
        "spa": 175,
        "spd": 105,
        "spe": 150
      },
      "abilities": {"0": "Trace"},
      "weightkg": 48,
      "eggGroups": ["Human-Like"],
      "requiredItem": "Alakazite"
    },
    "machop": {
      "num": 66,
      "name": "Machop",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 70,
        "atk": 80,
        "def": 50,
        "spa": 35,
        "spd": 35,
        "spe": 35
      },
      "abilities": {"0": "Guts", "1": "No Guard", "H": "Steadfast"},
      "weightkg": 19.5,
      "evos": ["Machoke"],
      "eggGroups": ["Human-Like"]
    },
    "machoke": {
      "num": 67,
      "name": "Machoke",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 80,
        "atk": 100,
        "def": 70,
        "spa": 50,
        "spd": 60,
        "spe": 45
      },
      "abilities": {"0": "Guts", "1": "No Guard", "H": "Steadfast"},
      "weightkg": 70.5,
      "prevo": "Machop",
      "evoLevel": 28,
      "evos": ["Machamp"],
      "eggGroups": ["Human-Like"]
    },
    "machamp": {
      "num": 68,
      "name": "Machamp",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 90,
        "atk": 130,
        "def": 80,
        "spa": 65,
        "spd": 85,
        "spe": 55
      },
      "abilities": {"0": "Guts", "1": "No Guard", "H": "Steadfast"},
      "weightkg": 130,
      "prevo": "Machoke",
      "evoType": "trade",
      "eggGroups": ["Human-Like"],
      "canGigantamax": "G-Max Chi Strike"
    },
    "machampgmax": {
      "num": 68,
      "name": "Machamp-Gmax",
      "baseSpecies": "Machamp",
      "forme": "Gmax",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 90,
        "atk": 130,
        "def": 80,
        "spa": 65,
        "spd": 85,
        "spe": 55
      },
      "abilities": {"0": "Guts", "1": "No Guard", "H": "Steadfast"},
      "weightkg": 0,
      "eggGroups": ["Human-Like"],
      "changesFrom": "Machamp"
    },
    "bellsprout": {
      "num": 69,
      "name": "Bellsprout",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 50,
        "atk": 75,
        "def": 35,
        "spa": 70,
        "spd": 30,
        "spe": 40
      },
      "abilities": {"0": "Chlorophyll", "H": "Gluttony"},
      "weightkg": 4,
      "evos": ["Weepinbell"],
      "eggGroups": ["Grass"]
    },
    "weepinbell": {
      "num": 70,
      "name": "Weepinbell",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 50,
        "spa": 85,
        "spd": 45,
        "spe": 55
      },
      "abilities": {"0": "Chlorophyll", "H": "Gluttony"},
      "weightkg": 6.4,
      "prevo": "Bellsprout",
      "evoLevel": 21,
      "evos": ["Victreebel"],
      "eggGroups": ["Grass"]
    },
    "victreebel": {
      "num": 71,
      "name": "Victreebel",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 80,
        "atk": 105,
        "def": 65,
        "spa": 100,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Chlorophyll", "H": "Gluttony"},
      "weightkg": 15.5,
      "prevo": "Weepinbell",
      "evoType": "useItem",
      "evoItem": "Leaf Stone",
      "eggGroups": ["Grass"]
    },
    "tentacool": {
      "num": 72,
      "name": "Tentacool",
      "types": ["Water", "Poison"],
      "baseStats": {
        "hp": 40,
        "atk": 40,
        "def": 35,
        "spa": 50,
        "spd": 100,
        "spe": 70
      },
      "abilities": {"0": "Clear Body", "1": "Liquid Ooze", "H": "Rain Dish"},
      "weightkg": 45.5,
      "evos": ["Tentacruel"],
      "eggGroups": ["Water 3"]
    },
    "tentacruel": {
      "num": 73,
      "name": "Tentacruel",
      "types": ["Water", "Poison"],
      "baseStats": {
        "hp": 80,
        "atk": 70,
        "def": 65,
        "spa": 80,
        "spd": 120,
        "spe": 100
      },
      "abilities": {"0": "Clear Body", "1": "Liquid Ooze", "H": "Rain Dish"},
      "weightkg": 55,
      "prevo": "Tentacool",
      "evoLevel": 30,
      "eggGroups": ["Water 3"]
    },
    "geodude": {
      "num": 74,
      "name": "Geodude",
      "types": ["Rock", "Ground"],
      "baseStats": {
        "hp": 40,
        "atk": 80,
        "def": 100,
        "spa": 30,
        "spd": 30,
        "spe": 20
      },
      "abilities": {"0": "Rock Head", "1": "Sturdy", "H": "Sand Veil"},
      "weightkg": 20,
      "evos": ["Graveler"],
      "eggGroups": ["Mineral"],
      "otherFormes": ["Geodude-Alola"],
      "formeOrder": ["Geodude", "Geodude-Alola"]
    },
    "geodudealola": {
      "num": 74,
      "name": "Geodude-Alola",
      "baseSpecies": "Geodude",
      "forme": "Alola",
      "types": ["Rock", "Electric"],
      "baseStats": {
        "hp": 40,
        "atk": 80,
        "def": 100,
        "spa": 30,
        "spd": 30,
        "spe": 20
      },
      "abilities": {"0": "Magnet Pull", "1": "Sturdy", "H": "Galvanize"},
      "weightkg": 20.3,
      "evos": ["Graveler-Alola"],
      "eggGroups": ["Mineral"]
    },
    "graveler": {
      "num": 75,
      "name": "Graveler",
      "types": ["Rock", "Ground"],
      "baseStats": {
        "hp": 55,
        "atk": 95,
        "def": 115,
        "spa": 45,
        "spd": 45,
        "spe": 35
      },
      "abilities": {"0": "Rock Head", "1": "Sturdy", "H": "Sand Veil"},
      "weightkg": 105,
      "prevo": "Geodude",
      "evoLevel": 25,
      "evos": ["Golem"],
      "eggGroups": ["Mineral"],
      "otherFormes": ["Graveler-Alola"],
      "formeOrder": ["Graveler", "Graveler-Alola"]
    },
    "graveleralola": {
      "num": 75,
      "name": "Graveler-Alola",
      "baseSpecies": "Graveler",
      "forme": "Alola",
      "types": ["Rock", "Electric"],
      "baseStats": {
        "hp": 55,
        "atk": 95,
        "def": 115,
        "spa": 45,
        "spd": 45,
        "spe": 35
      },
      "abilities": {"0": "Magnet Pull", "1": "Sturdy", "H": "Galvanize"},
      "weightkg": 110,
      "prevo": "Geodude-Alola",
      "evoLevel": 25,
      "evos": ["Golem-Alola"],
      "eggGroups": ["Mineral"]
    },
    "golem": {
      "num": 76,
      "name": "Golem",
      "types": ["Rock", "Ground"],
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 130,
        "spa": 55,
        "spd": 65,
        "spe": 45
      },
      "abilities": {"0": "Rock Head", "1": "Sturdy", "H": "Sand Veil"},
      "weightkg": 300,
      "prevo": "Graveler",
      "evoType": "trade",
      "eggGroups": ["Mineral"],
      "otherFormes": ["Golem-Alola"],
      "formeOrder": ["Golem", "Golem-Alola"]
    },
    "golemalola": {
      "num": 76,
      "name": "Golem-Alola",
      "baseSpecies": "Golem",
      "forme": "Alola",
      "types": ["Rock", "Electric"],
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 130,
        "spa": 55,
        "spd": 65,
        "spe": 45
      },
      "abilities": {"0": "Magnet Pull", "1": "Sturdy", "H": "Galvanize"},
      "weightkg": 316,
      "prevo": "Graveler-Alola",
      "evoType": "trade",
      "eggGroups": ["Mineral"]
    },
    "ponyta": {
      "num": 77,
      "name": "Ponyta",
      "types": ["Fire"],
      "baseStats": {
        "hp": 50,
        "atk": 85,
        "def": 55,
        "spa": 65,
        "spd": 65,
        "spe": 90
      },
      "abilities": {"0": "Run Away", "1": "Flash Fire", "H": "Flame Body"},
      "weightkg": 30,
      "evos": ["Rapidash"],
      "eggGroups": ["Field"],
      "otherFormes": ["Ponyta-Galar"],
      "formeOrder": ["Ponyta", "Ponyta-Galar"]
    },
    "ponytagalar": {
      "num": 77,
      "name": "Ponyta-Galar",
      "baseSpecies": "Ponyta",
      "forme": "Galar",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 50,
        "atk": 85,
        "def": 55,
        "spa": 65,
        "spd": 65,
        "spe": 90
      },
      "abilities": {"0": "Run Away", "1": "Pastel Veil", "H": "Anticipation"},
      "weightkg": 24,
      "evos": ["Rapidash-Galar"],
      "eggGroups": ["Field"]
    },
    "rapidash": {
      "num": 78,
      "name": "Rapidash",
      "types": ["Fire"],
      "baseStats": {
        "hp": 65,
        "atk": 100,
        "def": 70,
        "spa": 80,
        "spd": 80,
        "spe": 105
      },
      "abilities": {"0": "Run Away", "1": "Flash Fire", "H": "Flame Body"},
      "weightkg": 95,
      "prevo": "Ponyta",
      "evoLevel": 40,
      "eggGroups": ["Field"],
      "otherFormes": ["Rapidash-Galar"],
      "formeOrder": ["Rapidash", "Rapidash-Galar"]
    },
    "rapidashgalar": {
      "num": 78,
      "name": "Rapidash-Galar",
      "baseSpecies": "Rapidash",
      "forme": "Galar",
      "types": ["Psychic", "Fairy"],
      "baseStats": {
        "hp": 65,
        "atk": 100,
        "def": 70,
        "spa": 80,
        "spd": 80,
        "spe": 105
      },
      "abilities": {"0": "Run Away", "1": "Pastel Veil", "H": "Anticipation"},
      "weightkg": 80,
      "prevo": "Ponyta-Galar",
      "evoLevel": 40,
      "eggGroups": ["Field"]
    },
    "slowpoke": {
      "num": 79,
      "name": "Slowpoke",
      "types": ["Water", "Psychic"],
      "baseStats": {
        "hp": 90,
        "atk": 65,
        "def": 65,
        "spa": 40,
        "spd": 40,
        "spe": 15
      },
      "abilities": {"0": "Oblivious", "1": "Own Tempo", "H": "Regenerator"},
      "weightkg": 36,
      "evos": ["Slowbro", "Slowking"],
      "eggGroups": ["Monster", "Water 1"],
      "otherFormes": ["Slowpoke-Galar"],
      "formeOrder": ["Slowpoke", "Slowpoke-Galar"]
    },
    "slowpokegalar": {
      "num": 79,
      "name": "Slowpoke-Galar",
      "baseSpecies": "Slowpoke",
      "forme": "Galar",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 90,
        "atk": 65,
        "def": 65,
        "spa": 40,
        "spd": 40,
        "spe": 15
      },
      "abilities": {"0": "Gluttony", "1": "Own Tempo", "H": "Regenerator"},
      "weightkg": 36,
      "evos": ["Slowbro-Galar", "Slowking-Galar"],
      "eggGroups": ["Monster", "Water 1"]
    },
    "slowbro": {
      "num": 80,
      "name": "Slowbro",
      "types": ["Water", "Psychic"],
      "baseStats": {
        "hp": 95,
        "atk": 75,
        "def": 110,
        "spa": 100,
        "spd": 80,
        "spe": 30
      },
      "abilities": {"0": "Oblivious", "1": "Own Tempo", "H": "Regenerator"},
      "weightkg": 78.5,
      "prevo": "Slowpoke",
      "evoLevel": 37,
      "eggGroups": ["Monster", "Water 1"],
      "otherFormes": ["Slowbro-Mega", "Slowbro-Galar"],
      "formeOrder": ["Slowbro", "Slowbro-Mega", "Slowbro-Galar"]
    },
    "slowbromega": {
      "num": 80,
      "name": "Slowbro-Mega",
      "baseSpecies": "Slowbro",
      "forme": "Mega",
      "types": ["Water", "Psychic"],
      "baseStats": {
        "hp": 95,
        "atk": 75,
        "def": 180,
        "spa": 130,
        "spd": 80,
        "spe": 30
      },
      "abilities": {"0": "Shell Armor"},
      "weightkg": 120,
      "eggGroups": ["Monster", "Water 1"],
      "requiredItem": "Slowbronite"
    },
    "slowbrogalar": {
      "num": 80,
      "name": "Slowbro-Galar",
      "baseSpecies": "Slowbro",
      "forme": "Galar",
      "types": ["Poison", "Psychic"],
      "baseStats": {
        "hp": 95,
        "atk": 100,
        "def": 95,
        "spa": 100,
        "spd": 70,
        "spe": 30
      },
      "abilities": {"0": "Quick Draw", "1": "Own Tempo", "H": "Regenerator"},
      "weightkg": 70.5,
      "prevo": "Slowpoke-Galar",
      "evoType": "useItem",
      "evoItem": "Galarica Cuff",
      "eggGroups": ["Monster", "Water 1"]
    },
    "magnemite": {
      "num": 81,
      "name": "Magnemite",
      "types": ["Electric", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 25,
        "atk": 35,
        "def": 70,
        "spa": 95,
        "spd": 55,
        "spe": 45
      },
      "abilities": {"0": "Magnet Pull", "1": "Sturdy", "H": "Analytic"},
      "weightkg": 6,
      "evos": ["Magneton"],
      "eggGroups": ["Mineral"]
    },
    "magneton": {
      "num": 82,
      "name": "Magneton",
      "types": ["Electric", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 60,
        "def": 95,
        "spa": 120,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Magnet Pull", "1": "Sturdy", "H": "Analytic"},
      "weightkg": 60,
      "prevo": "Magnemite",
      "evoLevel": 30,
      "evos": ["Magnezone"],
      "eggGroups": ["Mineral"]
    },
    "farfetchd": {
      "num": 83,
      "name": "Farfetch’d",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 52,
        "atk": 90,
        "def": 55,
        "spa": 58,
        "spd": 62,
        "spe": 60
      },
      "abilities": {"0": "Keen Eye", "1": "Inner Focus", "H": "Defiant"},
      "weightkg": 15,
      "eggGroups": ["Flying", "Field"],
      "otherFormes": ["Farfetch’d-Galar"],
      "formeOrder": ["Farfetch’d", "Farfetch’d-Galar"]
    },
    "farfetchdgalar": {
      "num": 83,
      "name": "Farfetch’d-Galar",
      "baseSpecies": "Farfetch’d",
      "forme": "Galar",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 52,
        "atk": 95,
        "def": 55,
        "spa": 58,
        "spd": 62,
        "spe": 55
      },
      "abilities": {"0": "Steadfast", "H": "Scrappy"},
      "weightkg": 42,
      "evos": ["Sirfetch’d"],
      "eggGroups": ["Flying", "Field"]
    },
    "doduo": {
      "num": 84,
      "name": "Doduo",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 35,
        "atk": 85,
        "def": 45,
        "spa": 35,
        "spd": 35,
        "spe": 75
      },
      "abilities": {"0": "Run Away", "1": "Early Bird", "H": "Tangled Feet"},
      "weightkg": 39.2,
      "evos": ["Dodrio"],
      "eggGroups": ["Flying"]
    },
    "dodrio": {
      "num": 85,
      "name": "Dodrio",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 60,
        "atk": 110,
        "def": 70,
        "spa": 60,
        "spd": 60,
        "spe": 110
      },
      "abilities": {"0": "Run Away", "1": "Early Bird", "H": "Tangled Feet"},
      "weightkg": 85.2,
      "prevo": "Doduo",
      "evoLevel": 31,
      "eggGroups": ["Flying"]
    },
    "seel": {
      "num": 86,
      "name": "Seel",
      "types": ["Water"],
      "baseStats": {
        "hp": 65,
        "atk": 45,
        "def": 55,
        "spa": 45,
        "spd": 70,
        "spe": 45
      },
      "abilities": {"0": "Thick Fat", "1": "Hydration", "H": "Ice Body"},
      "weightkg": 90,
      "evos": ["Dewgong"],
      "eggGroups": ["Water 1", "Field"]
    },
    "dewgong": {
      "num": 87,
      "name": "Dewgong",
      "types": ["Water", "Ice"],
      "baseStats": {
        "hp": 90,
        "atk": 70,
        "def": 80,
        "spa": 70,
        "spd": 95,
        "spe": 70
      },
      "abilities": {"0": "Thick Fat", "1": "Hydration", "H": "Ice Body"},
      "weightkg": 120,
      "prevo": "Seel",
      "evoLevel": 34,
      "eggGroups": ["Water 1", "Field"]
    },
    "grimer": {
      "num": 88,
      "name": "Grimer",
      "types": ["Poison"],
      "baseStats": {
        "hp": 80,
        "atk": 80,
        "def": 50,
        "spa": 40,
        "spd": 50,
        "spe": 25
      },
      "abilities": {"0": "Stench", "1": "Sticky Hold", "H": "Poison Touch"},
      "weightkg": 30,
      "evos": ["Muk"],
      "eggGroups": ["Amorphous"],
      "otherFormes": ["Grimer-Alola"],
      "formeOrder": ["Grimer", "Grimer-Alola"]
    },
    "grimeralola": {
      "num": 88,
      "name": "Grimer-Alola",
      "baseSpecies": "Grimer",
      "forme": "Alola",
      "types": ["Poison", "Dark"],
      "baseStats": {
        "hp": 80,
        "atk": 80,
        "def": 50,
        "spa": 40,
        "spd": 50,
        "spe": 25
      },
      "abilities": {
        "0": "Poison Touch",
        "1": "Gluttony",
        "H": "Power of Alchemy"
      },
      "weightkg": 42,
      "evos": ["Muk-Alola"],
      "eggGroups": ["Amorphous"]
    },
    "muk": {
      "num": 89,
      "name": "Muk",
      "types": ["Poison"],
      "baseStats": {
        "hp": 105,
        "atk": 105,
        "def": 75,
        "spa": 65,
        "spd": 100,
        "spe": 50
      },
      "abilities": {"0": "Stench", "1": "Sticky Hold", "H": "Poison Touch"},
      "weightkg": 30,
      "prevo": "Grimer",
      "evoLevel": 38,
      "eggGroups": ["Amorphous"],
      "otherFormes": ["Muk-Alola"],
      "formeOrder": ["Muk", "Muk-Alola"]
    },
    "mukalola": {
      "num": 89,
      "name": "Muk-Alola",
      "baseSpecies": "Muk",
      "forme": "Alola",
      "types": ["Poison", "Dark"],
      "baseStats": {
        "hp": 105,
        "atk": 105,
        "def": 75,
        "spa": 65,
        "spd": 100,
        "spe": 50
      },
      "abilities": {
        "0": "Poison Touch",
        "1": "Gluttony",
        "H": "Power of Alchemy"
      },
      "weightkg": 52,
      "prevo": "Grimer-Alola",
      "evoLevel": 38,
      "eggGroups": ["Amorphous"]
    },
    "shellder": {
      "num": 90,
      "name": "Shellder",
      "types": ["Water"],
      "baseStats": {
        "hp": 30,
        "atk": 65,
        "def": 100,
        "spa": 45,
        "spd": 25,
        "spe": 40
      },
      "abilities": {"0": "Shell Armor", "1": "Skill Link", "H": "Overcoat"},
      "weightkg": 4,
      "evos": ["Cloyster"],
      "eggGroups": ["Water 3"]
    },
    "cloyster": {
      "num": 91,
      "name": "Cloyster",
      "types": ["Water", "Ice"],
      "baseStats": {
        "hp": 50,
        "atk": 95,
        "def": 180,
        "spa": 85,
        "spd": 45,
        "spe": 70
      },
      "abilities": {"0": "Shell Armor", "1": "Skill Link", "H": "Overcoat"},
      "weightkg": 132.5,
      "prevo": "Shellder",
      "evoType": "useItem",
      "evoItem": "Water Stone",
      "eggGroups": ["Water 3"]
    },
    "gastly": {
      "num": 92,
      "name": "Gastly",
      "types": ["Ghost", "Poison"],
      "baseStats": {
        "hp": 30,
        "atk": 35,
        "def": 30,
        "spa": 100,
        "spd": 35,
        "spe": 80
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.1,
      "evos": ["Haunter"],
      "eggGroups": ["Amorphous"]
    },
    "haunter": {
      "num": 93,
      "name": "Haunter",
      "types": ["Ghost", "Poison"],
      "baseStats": {
        "hp": 45,
        "atk": 50,
        "def": 45,
        "spa": 115,
        "spd": 55,
        "spe": 95
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.1,
      "prevo": "Gastly",
      "evoLevel": 25,
      "evos": ["Gengar"],
      "eggGroups": ["Amorphous"]
    },
    "gengar": {
      "num": 94,
      "name": "Gengar",
      "types": ["Ghost", "Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 65,
        "def": 60,
        "spa": 130,
        "spd": 75,
        "spe": 110
      },
      "abilities": {"0": "Cursed Body"},
      "weightkg": 40.5,
      "prevo": "Haunter",
      "evoType": "trade",
      "eggGroups": ["Amorphous"],
      "otherFormes": ["Gengar-Mega"],
      "formeOrder": ["Gengar", "Gengar-Mega"],
      "canGigantamax": "G-Max Terror"
    },
    "gengarmega": {
      "num": 94,
      "name": "Gengar-Mega",
      "baseSpecies": "Gengar",
      "forme": "Mega",
      "types": ["Ghost", "Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 65,
        "def": 80,
        "spa": 170,
        "spd": 95,
        "spe": 130
      },
      "abilities": {"0": "Shadow Tag"},
      "weightkg": 40.5,
      "eggGroups": ["Amorphous"],
      "requiredItem": "Gengarite"
    },
    "gengargmax": {
      "num": 94,
      "name": "Gengar-Gmax",
      "baseSpecies": "Gengar",
      "forme": "Gmax",
      "types": ["Ghost", "Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 65,
        "def": 60,
        "spa": 130,
        "spd": 75,
        "spe": 110
      },
      "abilities": {"0": "Cursed Body"},
      "weightkg": 0,
      "eggGroups": ["Amorphous"],
      "changesFrom": "Gengar"
    },
    "onix": {
      "num": 95,
      "name": "Onix",
      "types": ["Rock", "Ground"],
      "baseStats": {
        "hp": 35,
        "atk": 45,
        "def": 160,
        "spa": 30,
        "spd": 45,
        "spe": 70
      },
      "abilities": {"0": "Rock Head", "1": "Sturdy", "H": "Weak Armor"},
      "weightkg": 210,
      "evos": ["Steelix"],
      "eggGroups": ["Mineral"]
    },
    "drowzee": {
      "num": 96,
      "name": "Drowzee",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 60,
        "atk": 48,
        "def": 45,
        "spa": 43,
        "spd": 90,
        "spe": 42
      },
      "abilities": {"0": "Insomnia", "1": "Forewarn", "H": "Inner Focus"},
      "weightkg": 32.4,
      "evos": ["Hypno"],
      "eggGroups": ["Human-Like"]
    },
    "hypno": {
      "num": 97,
      "name": "Hypno",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 85,
        "atk": 73,
        "def": 70,
        "spa": 73,
        "spd": 115,
        "spe": 67
      },
      "abilities": {"0": "Insomnia", "1": "Forewarn", "H": "Inner Focus"},
      "weightkg": 75.6,
      "prevo": "Drowzee",
      "evoLevel": 26,
      "eggGroups": ["Human-Like"]
    },
    "krabby": {
      "num": 98,
      "name": "Krabby",
      "types": ["Water"],
      "baseStats": {
        "hp": 30,
        "atk": 105,
        "def": 90,
        "spa": 25,
        "spd": 25,
        "spe": 50
      },
      "abilities": {
        "0": "Hyper Cutter",
        "1": "Shell Armor",
        "H": "Sheer Force"
      },
      "weightkg": 6.5,
      "evos": ["Kingler"],
      "eggGroups": ["Water 3"]
    },
    "kingler": {
      "num": 99,
      "name": "Kingler",
      "types": ["Water"],
      "baseStats": {
        "hp": 55,
        "atk": 130,
        "def": 115,
        "spa": 50,
        "spd": 50,
        "spe": 75
      },
      "abilities": {
        "0": "Hyper Cutter",
        "1": "Shell Armor",
        "H": "Sheer Force"
      },
      "weightkg": 60,
      "prevo": "Krabby",
      "evoLevel": 28,
      "eggGroups": ["Water 3"],
      "canGigantamax": "G-Max Foam Burst"
    },
    "kinglergmax": {
      "num": 99,
      "name": "Kingler-Gmax",
      "baseSpecies": "Kingler",
      "forme": "Gmax",
      "types": ["Water"],
      "baseStats": {
        "hp": 55,
        "atk": 130,
        "def": 115,
        "spa": 50,
        "spd": 50,
        "spe": 75
      },
      "abilities": {
        "0": "Hyper Cutter",
        "1": "Shell Armor",
        "H": "Sheer Force"
      },
      "weightkg": 0,
      "eggGroups": ["Water 3"],
      "changesFrom": "Kingler"
    },
    "voltorb": {
      "num": 100,
      "name": "Voltorb",
      "types": ["Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 40,
        "atk": 30,
        "def": 50,
        "spa": 55,
        "spd": 55,
        "spe": 100
      },
      "abilities": {"0": "Soundproof", "1": "Static", "H": "Aftermath"},
      "weightkg": 10.4,
      "evos": ["Electrode"],
      "eggGroups": ["Mineral"],
      "otherFormes": ["Voltorb-Hisui"],
      "formeOrder": ["Voltorb", "Voltorb-Hisui"]
    },
    "voltorbhisui": {
      "num": 100,
      "name": "Voltorb-Hisui",
      "baseSpecies": "Voltorb",
      "forme": "Hisui",
      "types": ["Electric", "Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 40,
        "atk": 30,
        "def": 50,
        "spa": 55,
        "spd": 55,
        "spe": 100
      },
      "abilities": {"0": "Soundproof", "1": "Static", "H": "Aftermath"},
      "weightkg": 13,
      "evos": ["Electrode-Hisui"],
      "eggGroups": ["Mineral"]
    },
    "electrode": {
      "num": 101,
      "name": "Electrode",
      "types": ["Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 70,
        "spa": 80,
        "spd": 80,
        "spe": 150
      },
      "abilities": {"0": "Soundproof", "1": "Static", "H": "Aftermath"},
      "weightkg": 66.6,
      "prevo": "Voltorb",
      "evoLevel": 30,
      "eggGroups": ["Mineral"],
      "otherFormes": ["Electrode-Hisui"],
      "formeOrder": ["Electrode", "Electrode-Hisui"]
    },
    "electrodehisui": {
      "num": 101,
      "name": "Electrode-Hisui",
      "baseSpecies": "Electrode",
      "forme": "Hisui",
      "types": ["Electric", "Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 70,
        "spa": 80,
        "spd": 80,
        "spe": 150
      },
      "abilities": {"0": "Soundproof", "1": "Static", "H": "Aftermath"},
      "weightkg": 71,
      "prevo": "Voltorb-Hisui",
      "evoType": "useItem",
      "evoItem": "Leaf Stone",
      "eggGroups": ["Mineral"]
    },
    "exeggcute": {
      "num": 102,
      "name": "Exeggcute",
      "types": ["Grass", "Psychic"],
      "baseStats": {
        "hp": 60,
        "atk": 40,
        "def": 80,
        "spa": 60,
        "spd": 45,
        "spe": 40
      },
      "abilities": {"0": "Chlorophyll", "H": "Harvest"},
      "weightkg": 2.5,
      "evos": ["Exeggutor", "Exeggutor-Alola"],
      "eggGroups": ["Grass"]
    },
    "exeggutor": {
      "num": 103,
      "name": "Exeggutor",
      "types": ["Grass", "Psychic"],
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 85,
        "spa": 125,
        "spd": 75,
        "spe": 55
      },
      "abilities": {"0": "Chlorophyll", "H": "Harvest"},
      "weightkg": 120,
      "prevo": "Exeggcute",
      "evoType": "useItem",
      "evoItem": "Leaf Stone",
      "eggGroups": ["Grass"],
      "otherFormes": ["Exeggutor-Alola"],
      "formeOrder": ["Exeggutor", "Exeggutor-Alola"]
    },
    "exeggutoralola": {
      "num": 103,
      "name": "Exeggutor-Alola",
      "baseSpecies": "Exeggutor",
      "forme": "Alola",
      "types": ["Grass", "Dragon"],
      "baseStats": {
        "hp": 95,
        "atk": 105,
        "def": 85,
        "spa": 125,
        "spd": 75,
        "spe": 45
      },
      "abilities": {"0": "Frisk", "H": "Harvest"},
      "weightkg": 415.6,
      "prevo": "Exeggcute",
      "evoType": "useItem",
      "evoItem": "Leaf Stone",
      "evoRegion": "Alola",
      "eggGroups": ["Grass"]
    },
    "cubone": {
      "num": 104,
      "name": "Cubone",
      "types": ["Ground"],
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 95,
        "spa": 40,
        "spd": 50,
        "spe": 35
      },
      "abilities": {
        "0": "Rock Head",
        "1": "Lightning Rod",
        "H": "Battle Armor"
      },
      "weightkg": 6.5,
      "evos": ["Marowak", "Marowak-Alola"],
      "eggGroups": ["Monster"]
    },
    "marowak": {
      "num": 105,
      "name": "Marowak",
      "types": ["Ground"],
      "baseStats": {
        "hp": 60,
        "atk": 80,
        "def": 110,
        "spa": 50,
        "spd": 80,
        "spe": 45
      },
      "abilities": {
        "0": "Rock Head",
        "1": "Lightning Rod",
        "H": "Battle Armor"
      },
      "weightkg": 45,
      "prevo": "Cubone",
      "evoLevel": 28,
      "eggGroups": ["Monster"],
      "otherFormes": ["Marowak-Alola", "Marowak-Alola-Totem"],
      "formeOrder": ["Marowak", "Marowak-Alola", "Marowak-Alola-Totem"]
    },
    "marowakalola": {
      "num": 105,
      "name": "Marowak-Alola",
      "baseSpecies": "Marowak",
      "forme": "Alola",
      "types": ["Fire", "Ghost"],
      "baseStats": {
        "hp": 60,
        "atk": 80,
        "def": 110,
        "spa": 50,
        "spd": 80,
        "spe": 45
      },
      "abilities": {"0": "Cursed Body", "1": "Lightning Rod", "H": "Rock Head"},
      "weightkg": 34,
      "prevo": "Cubone",
      "evoLevel": 28,
      "evoCondition": "at night",
      "evoRegion": "Alola",
      "eggGroups": ["Monster"]
    },
    "marowakalolatotem": {
      "num": 105,
      "name": "Marowak-Alola-Totem",
      "baseSpecies": "Marowak",
      "forme": "Alola-Totem",
      "types": ["Fire", "Ghost"],
      "baseStats": {
        "hp": 60,
        "atk": 80,
        "def": 110,
        "spa": 50,
        "spd": 80,
        "spe": 45
      },
      "abilities": {"0": "Rock Head"},
      "weightkg": 98,
      "eggGroups": ["Monster"]
    },
    "hitmonlee": {
      "num": 106,
      "name": "Hitmonlee",
      "types": ["Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 50,
        "atk": 120,
        "def": 53,
        "spa": 35,
        "spd": 110,
        "spe": 87
      },
      "abilities": {"0": "Limber", "1": "Reckless", "H": "Unburden"},
      "weightkg": 49.8,
      "prevo": "Tyrogue",
      "evoLevel": 20,
      "evoCondition": "with an Atk stat > its Def stat",
      "eggGroups": ["Human-Like"]
    },
    "hitmonchan": {
      "num": 107,
      "name": "Hitmonchan",
      "types": ["Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 50,
        "atk": 105,
        "def": 79,
        "spa": 35,
        "spd": 110,
        "spe": 76
      },
      "abilities": {"0": "Keen Eye", "1": "Iron Fist", "H": "Inner Focus"},
      "weightkg": 50.2,
      "prevo": "Tyrogue",
      "evoLevel": 20,
      "evoCondition": "with an Atk stat < its Def stat",
      "eggGroups": ["Human-Like"]
    },
    "lickitung": {
      "num": 108,
      "name": "Lickitung",
      "types": ["Normal"],
      "baseStats": {
        "hp": 90,
        "atk": 55,
        "def": 75,
        "spa": 60,
        "spd": 75,
        "spe": 30
      },
      "abilities": {"0": "Own Tempo", "1": "Oblivious", "H": "Cloud Nine"},
      "weightkg": 65.5,
      "evos": ["Lickilicky"],
      "eggGroups": ["Monster"]
    },
    "koffing": {
      "num": 109,
      "name": "Koffing",
      "types": ["Poison"],
      "baseStats": {
        "hp": 40,
        "atk": 65,
        "def": 95,
        "spa": 60,
        "spd": 45,
        "spe": 35
      },
      "abilities": {"0": "Levitate", "1": "Neutralizing Gas", "H": "Stench"},
      "weightkg": 1,
      "evos": ["Weezing", "Weezing-Galar"],
      "eggGroups": ["Amorphous"]
    },
    "weezing": {
      "num": 110,
      "name": "Weezing",
      "types": ["Poison"],
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 120,
        "spa": 85,
        "spd": 70,
        "spe": 60
      },
      "abilities": {"0": "Levitate", "1": "Neutralizing Gas", "H": "Stench"},
      "weightkg": 9.5,
      "prevo": "Koffing",
      "evoLevel": 35,
      "eggGroups": ["Amorphous"],
      "otherFormes": ["Weezing-Galar"],
      "formeOrder": ["Weezing", "Weezing-Galar"]
    },
    "weezinggalar": {
      "num": 110,
      "name": "Weezing-Galar",
      "baseSpecies": "Weezing",
      "forme": "Galar",
      "types": ["Poison", "Fairy"],
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 120,
        "spa": 85,
        "spd": 70,
        "spe": 60
      },
      "abilities": {
        "0": "Levitate",
        "1": "Neutralizing Gas",
        "H": "Misty Surge"
      },
      "weightkg": 16,
      "prevo": "Koffing",
      "evoLevel": 35,
      "evoRegion": "Galar",
      "eggGroups": ["Amorphous"]
    },
    "rhyhorn": {
      "num": 111,
      "name": "Rhyhorn",
      "types": ["Ground", "Rock"],
      "baseStats": {
        "hp": 80,
        "atk": 85,
        "def": 95,
        "spa": 30,
        "spd": 30,
        "spe": 25
      },
      "abilities": {"0": "Lightning Rod", "1": "Rock Head", "H": "Reckless"},
      "weightkg": 115,
      "evos": ["Rhydon"],
      "eggGroups": ["Monster", "Field"]
    },
    "rhydon": {
      "num": 112,
      "name": "Rhydon",
      "types": ["Ground", "Rock"],
      "baseStats": {
        "hp": 105,
        "atk": 130,
        "def": 120,
        "spa": 45,
        "spd": 45,
        "spe": 40
      },
      "abilities": {"0": "Lightning Rod", "1": "Rock Head", "H": "Reckless"},
      "weightkg": 120,
      "prevo": "Rhyhorn",
      "evoLevel": 42,
      "evos": ["Rhyperior"],
      "eggGroups": ["Monster", "Field"]
    },
    "chansey": {
      "num": 113,
      "name": "Chansey",
      "types": ["Normal"],
      "gender": "F",
      "baseStats": {
        "hp": 250,
        "atk": 5,
        "def": 5,
        "spa": 35,
        "spd": 105,
        "spe": 50
      },
      "abilities": {"0": "Natural Cure", "1": "Serene Grace", "H": "Healer"},
      "weightkg": 34.6,
      "prevo": "Happiny",
      "evoType": "levelHold",
      "evoItem": "Oval Stone",
      "evoCondition": "during the day",
      "evos": ["Blissey"],
      "eggGroups": ["Fairy"],
      "canHatch": true
    },
    "tangela": {
      "num": 114,
      "name": "Tangela",
      "types": ["Grass"],
      "baseStats": {
        "hp": 65,
        "atk": 55,
        "def": 115,
        "spa": 100,
        "spd": 40,
        "spe": 60
      },
      "abilities": {"0": "Chlorophyll", "1": "Leaf Guard", "H": "Regenerator"},
      "weightkg": 35,
      "evos": ["Tangrowth"],
      "eggGroups": ["Grass"]
    },
    "kangaskhan": {
      "num": 115,
      "name": "Kangaskhan",
      "types": ["Normal"],
      "gender": "F",
      "baseStats": {
        "hp": 105,
        "atk": 95,
        "def": 80,
        "spa": 40,
        "spd": 80,
        "spe": 90
      },
      "abilities": {"0": "Early Bird", "1": "Scrappy", "H": "Inner Focus"},
      "weightkg": 80,
      "eggGroups": ["Monster"],
      "otherFormes": ["Kangaskhan-Mega"],
      "formeOrder": ["Kangaskhan", "Kangaskhan-Mega"]
    },
    "kangaskhanmega": {
      "num": 115,
      "name": "Kangaskhan-Mega",
      "baseSpecies": "Kangaskhan",
      "forme": "Mega",
      "types": ["Normal"],
      "gender": "F",
      "baseStats": {
        "hp": 105,
        "atk": 125,
        "def": 100,
        "spa": 60,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Parental Bond"},
      "weightkg": 100,
      "eggGroups": ["Monster"],
      "requiredItem": "Kangaskhanite"
    },
    "horsea": {
      "num": 116,
      "name": "Horsea",
      "types": ["Water"],
      "baseStats": {
        "hp": 30,
        "atk": 40,
        "def": 70,
        "spa": 70,
        "spd": 25,
        "spe": 60
      },
      "abilities": {"0": "Swift Swim", "1": "Sniper", "H": "Damp"},
      "weightkg": 8,
      "evos": ["Seadra"],
      "eggGroups": ["Water 1", "Dragon"]
    },
    "seadra": {
      "num": 117,
      "name": "Seadra",
      "types": ["Water"],
      "baseStats": {
        "hp": 55,
        "atk": 65,
        "def": 95,
        "spa": 95,
        "spd": 45,
        "spe": 85
      },
      "abilities": {"0": "Poison Point", "1": "Sniper", "H": "Damp"},
      "weightkg": 25,
      "prevo": "Horsea",
      "evoLevel": 32,
      "evos": ["Kingdra"],
      "eggGroups": ["Water 1", "Dragon"]
    },
    "goldeen": {
      "num": 118,
      "name": "Goldeen",
      "types": ["Water"],
      "baseStats": {
        "hp": 45,
        "atk": 67,
        "def": 60,
        "spa": 35,
        "spd": 50,
        "spe": 63
      },
      "abilities": {"0": "Swift Swim", "1": "Water Veil", "H": "Lightning Rod"},
      "weightkg": 15,
      "evos": ["Seaking"],
      "eggGroups": ["Water 2"]
    },
    "seaking": {
      "num": 119,
      "name": "Seaking",
      "types": ["Water"],
      "baseStats": {
        "hp": 80,
        "atk": 92,
        "def": 65,
        "spa": 65,
        "spd": 80,
        "spe": 68
      },
      "abilities": {"0": "Swift Swim", "1": "Water Veil", "H": "Lightning Rod"},
      "weightkg": 39,
      "prevo": "Goldeen",
      "evoLevel": 33,
      "eggGroups": ["Water 2"]
    },
    "staryu": {
      "num": 120,
      "name": "Staryu",
      "types": ["Water"],
      "gender": "N",
      "baseStats": {
        "hp": 30,
        "atk": 45,
        "def": 55,
        "spa": 70,
        "spd": 55,
        "spe": 85
      },
      "abilities": {"0": "Illuminate", "1": "Natural Cure", "H": "Analytic"},
      "weightkg": 34.5,
      "evos": ["Starmie"],
      "eggGroups": ["Water 3"]
    },
    "starmie": {
      "num": 121,
      "name": "Starmie",
      "types": ["Water", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 75,
        "def": 85,
        "spa": 100,
        "spd": 85,
        "spe": 115
      },
      "abilities": {"0": "Illuminate", "1": "Natural Cure", "H": "Analytic"},
      "weightkg": 80,
      "prevo": "Staryu",
      "evoType": "useItem",
      "evoItem": "Water Stone",
      "eggGroups": ["Water 3"]
    },
    "mrmime": {
      "num": 122,
      "name": "Mr. Mime",
      "types": ["Psychic", "Fairy"],
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 65,
        "spa": 100,
        "spd": 120,
        "spe": 90
      },
      "abilities": {"0": "Soundproof", "1": "Filter", "H": "Technician"},
      "weightkg": 54.5,
      "prevo": "Mime Jr.",
      "evoType": "levelMove",
      "evoMove": "Mimic",
      "eggGroups": ["Human-Like"],
      "canHatch": true,
      "otherFormes": ["Mr. Mime-Galar"],
      "formeOrder": ["Mr. Mime", "Mr. Mime-Galar"]
    },
    "mrmimegalar": {
      "num": 122,
      "name": "Mr. Mime-Galar",
      "baseSpecies": "Mr. Mime",
      "forme": "Galar",
      "types": ["Ice", "Psychic"],
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 65,
        "spa": 90,
        "spd": 90,
        "spe": 100
      },
      "abilities": {
        "0": "Vital Spirit",
        "1": "Screen Cleaner",
        "H": "Ice Body"
      },
      "weightkg": 56.8,
      "prevo": "Mime Jr.",
      "evoType": "levelMove",
      "evoMove": "Mimic",
      "evoRegion": "Galar",
      "evos": ["Mr. Rime"],
      "eggGroups": ["Human-Like"],
      "canHatch": true
    },
    "scyther": {
      "num": 123,
      "name": "Scyther",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 70,
        "atk": 110,
        "def": 80,
        "spa": 55,
        "spd": 80,
        "spe": 105
      },
      "abilities": {"0": "Swarm", "1": "Technician", "H": "Steadfast"},
      "weightkg": 56,
      "evos": ["Scizor", "Kleavor"],
      "eggGroups": ["Bug"]
    },
    "jynx": {
      "num": 124,
      "name": "Jynx",
      "types": ["Ice", "Psychic"],
      "gender": "F",
      "baseStats": {
        "hp": 65,
        "atk": 50,
        "def": 35,
        "spa": 115,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "Oblivious", "1": "Forewarn", "H": "Dry Skin"},
      "weightkg": 40.6,
      "prevo": "Smoochum",
      "evoLevel": 30,
      "eggGroups": ["Human-Like"]
    },
    "electabuzz": {
      "num": 125,
      "name": "Electabuzz",
      "types": ["Electric"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 65,
        "atk": 83,
        "def": 57,
        "spa": 95,
        "spd": 85,
        "spe": 105
      },
      "abilities": {"0": "Static", "H": "Vital Spirit"},
      "weightkg": 30,
      "prevo": "Elekid",
      "evoLevel": 30,
      "evos": ["Electivire"],
      "eggGroups": ["Human-Like"]
    },
    "magmar": {
      "num": 126,
      "name": "Magmar",
      "types": ["Fire"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 65,
        "atk": 95,
        "def": 57,
        "spa": 100,
        "spd": 85,
        "spe": 93
      },
      "abilities": {"0": "Flame Body", "H": "Vital Spirit"},
      "weightkg": 44.5,
      "prevo": "Magby",
      "evoLevel": 30,
      "evos": ["Magmortar"],
      "eggGroups": ["Human-Like"]
    },
    "pinsir": {
      "num": 127,
      "name": "Pinsir",
      "types": ["Bug"],
      "baseStats": {
        "hp": 65,
        "atk": 125,
        "def": 100,
        "spa": 55,
        "spd": 70,
        "spe": 85
      },
      "abilities": {"0": "Hyper Cutter", "1": "Mold Breaker", "H": "Moxie"},
      "weightkg": 55,
      "eggGroups": ["Bug"],
      "otherFormes": ["Pinsir-Mega"],
      "formeOrder": ["Pinsir", "Pinsir-Mega"]
    },
    "pinsirmega": {
      "num": 127,
      "name": "Pinsir-Mega",
      "baseSpecies": "Pinsir",
      "forme": "Mega",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 65,
        "atk": 155,
        "def": 120,
        "spa": 65,
        "spd": 90,
        "spe": 105
      },
      "abilities": {"0": "Aerilate"},
      "weightkg": 59,
      "eggGroups": ["Bug"],
      "requiredItem": "Pinsirite"
    },
    "tauros": {
      "num": 128,
      "name": "Tauros",
      "types": ["Normal"],
      "gender": "M",
      "baseStats": {
        "hp": 75,
        "atk": 100,
        "def": 95,
        "spa": 40,
        "spd": 70,
        "spe": 110
      },
      "abilities": {"0": "Intimidate", "1": "Anger Point", "H": "Sheer Force"},
      "weightkg": 88.4,
      "otherFormes": [
        "Tauros-Paldea-Combat",
        "Tauros-Paldea-Blaze",
        "Tauros-Paldea-Aqua"
      ],
      "formeOrder": [
        "Tauros",
        "Tauros-Paldea-Combat",
        "Tauros-Paldea-Blaze",
        "Tauros-Paldea-Aqua"
      ],
      "eggGroups": ["Field"]
    },
    "taurospaldeacombat": {
      "num": 128,
      "name": "Tauros-Paldea-Combat",
      "baseSpecies": "Tauros",
      "forme": "Paldea-Combat",
      "types": ["Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 75,
        "atk": 110,
        "def": 105,
        "spa": 30,
        "spd": 70,
        "spe": 100
      },
      "abilities": {"0": "Intimidate", "1": "Anger Point", "H": "Cud Chew"},
      "weightkg": 115,
      "eggGroups": ["Field"]
    },
    "taurospaldeablaze": {
      "num": 128,
      "name": "Tauros-Paldea-Blaze",
      "baseSpecies": "Tauros",
      "forme": "Paldea-Blaze",
      "types": ["Fighting", "Fire"],
      "gender": "M",
      "baseStats": {
        "hp": 75,
        "atk": 110,
        "def": 105,
        "spa": 30,
        "spd": 70,
        "spe": 100
      },
      "abilities": {"0": "Intimidate", "1": "Anger Point", "H": "Cud Chew"},
      "weightkg": 85,
      "eggGroups": ["Field"]
    },
    "taurospaldeaaqua": {
      "num": 128,
      "name": "Tauros-Paldea-Aqua",
      "baseSpecies": "Tauros",
      "forme": "Paldea-Aqua",
      "types": ["Fighting", "Water"],
      "gender": "M",
      "baseStats": {
        "hp": 75,
        "atk": 110,
        "def": 105,
        "spa": 30,
        "spd": 70,
        "spe": 100
      },
      "abilities": {"0": "Intimidate", "1": "Anger Point", "H": "Cud Chew"},
      "weightkg": 110,
      "eggGroups": ["Field"]
    },
    "magikarp": {
      "num": 129,
      "name": "Magikarp",
      "types": ["Water"],
      "baseStats": {
        "hp": 20,
        "atk": 10,
        "def": 55,
        "spa": 15,
        "spd": 20,
        "spe": 80
      },
      "abilities": {"0": "Swift Swim", "H": "Rattled"},
      "weightkg": 10,
      "evos": ["Gyarados"],
      "eggGroups": ["Water 2", "Dragon"]
    },
    "gyarados": {
      "num": 130,
      "name": "Gyarados",
      "types": ["Water", "Flying"],
      "baseStats": {
        "hp": 95,
        "atk": 125,
        "def": 79,
        "spa": 60,
        "spd": 100,
        "spe": 81
      },
      "abilities": {"0": "Intimidate", "H": "Moxie"},
      "weightkg": 235,
      "prevo": "Magikarp",
      "evoLevel": 20,
      "eggGroups": ["Water 2", "Dragon"],
      "otherFormes": ["Gyarados-Mega"],
      "formeOrder": ["Gyarados", "Gyarados-Mega"]
    },
    "gyaradosmega": {
      "num": 130,
      "name": "Gyarados-Mega",
      "baseSpecies": "Gyarados",
      "forme": "Mega",
      "types": ["Water", "Dark"],
      "baseStats": {
        "hp": 95,
        "atk": 155,
        "def": 109,
        "spa": 70,
        "spd": 130,
        "spe": 81
      },
      "abilities": {"0": "Mold Breaker"},
      "weightkg": 305,
      "eggGroups": ["Water 2", "Dragon"],
      "requiredItem": "Gyaradosite"
    },
    "lapras": {
      "num": 131,
      "name": "Lapras",
      "types": ["Water", "Ice"],
      "baseStats": {
        "hp": 130,
        "atk": 85,
        "def": 80,
        "spa": 85,
        "spd": 95,
        "spe": 60
      },
      "abilities": {"0": "Water Absorb", "1": "Shell Armor", "H": "Hydration"},
      "weightkg": 220,
      "eggGroups": ["Monster", "Water 1"],
      "canGigantamax": "G-Max Resonance"
    },
    "laprasgmax": {
      "num": 131,
      "name": "Lapras-Gmax",
      "baseSpecies": "Lapras",
      "forme": "Gmax",
      "types": ["Water", "Ice"],
      "baseStats": {
        "hp": 130,
        "atk": 85,
        "def": 80,
        "spa": 85,
        "spd": 95,
        "spe": 60
      },
      "abilities": {"0": "Water Absorb", "1": "Shell Armor", "H": "Hydration"},
      "weightkg": 0,
      "eggGroups": ["Monster", "Water 1"],
      "changesFrom": "Lapras"
    },
    "ditto": {
      "num": 132,
      "name": "Ditto",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 48,
        "atk": 48,
        "def": 48,
        "spa": 48,
        "spd": 48,
        "spe": 48
      },
      "abilities": {"0": "Limber", "H": "Imposter"},
      "weightkg": 4,
      "eggGroups": ["Ditto"]
    },
    "eevee": {
      "num": 133,
      "name": "Eevee",
      "types": ["Normal"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 55,
        "atk": 55,
        "def": 50,
        "spa": 45,
        "spd": 65,
        "spe": 55
      },
      "abilities": {"0": "Run Away", "1": "Adaptability", "H": "Anticipation"},
      "weightkg": 6.5,
      "evos": [
        "Vaporeon",
        "Jolteon",
        "Flareon",
        "Espeon",
        "Umbreon",
        "Leafeon",
        "Glaceon",
        "Sylveon"
      ],
      "eggGroups": ["Field"],
      "otherFormes": ["Eevee-Starter"],
      "formeOrder": ["Eevee", "Eevee-Starter"],
      "canGigantamax": "G-Max Cuddle"
    },
    "eeveestarter": {
      "num": 133,
      "name": "Eevee-Starter",
      "baseSpecies": "Eevee",
      "forme": "Starter",
      "types": ["Normal"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 75,
        "def": 70,
        "spa": 65,
        "spd": 85,
        "spe": 75
      },
      "abilities": {"0": "Run Away", "1": "Adaptability", "H": "Anticipation"},
      "weightkg": 6.5,
      "eggGroups": ["Undiscovered"]
    },
    "eeveegmax": {
      "num": 133,
      "name": "Eevee-Gmax",
      "baseSpecies": "Eevee",
      "forme": "Gmax",
      "types": ["Normal"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 55,
        "atk": 55,
        "def": 50,
        "spa": 45,
        "spd": 65,
        "spe": 55
      },
      "abilities": {"0": "Run Away", "1": "Adaptability", "H": "Anticipation"},
      "weightkg": 0,
      "eggGroups": ["Field"],
      "changesFrom": "Eevee"
    },
    "vaporeon": {
      "num": 134,
      "name": "Vaporeon",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 130,
        "atk": 65,
        "def": 60,
        "spa": 110,
        "spd": 95,
        "spe": 65
      },
      "abilities": {"0": "Water Absorb", "H": "Hydration"},
      "weightkg": 29,
      "prevo": "Eevee",
      "evoType": "useItem",
      "evoItem": "Water Stone",
      "eggGroups": ["Field"]
    },
    "jolteon": {
      "num": 135,
      "name": "Jolteon",
      "types": ["Electric"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 65,
        "def": 60,
        "spa": 110,
        "spd": 95,
        "spe": 130
      },
      "abilities": {"0": "Volt Absorb", "H": "Quick Feet"},
      "weightkg": 24.5,
      "prevo": "Eevee",
      "evoType": "useItem",
      "evoItem": "Thunder Stone",
      "eggGroups": ["Field"]
    },
    "flareon": {
      "num": 136,
      "name": "Flareon",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 130,
        "def": 60,
        "spa": 95,
        "spd": 110,
        "spe": 65
      },
      "abilities": {"0": "Flash Fire", "H": "Guts"},
      "weightkg": 25,
      "prevo": "Eevee",
      "evoType": "useItem",
      "evoItem": "Fire Stone",
      "eggGroups": ["Field"]
    },
    "porygon": {
      "num": 137,
      "name": "Porygon",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 65,
        "atk": 60,
        "def": 70,
        "spa": 85,
        "spd": 75,
        "spe": 40
      },
      "abilities": {"0": "Trace", "1": "Download", "H": "Analytic"},
      "weightkg": 36.5,
      "evos": ["Porygon2"],
      "eggGroups": ["Mineral"]
    },
    "omanyte": {
      "num": 138,
      "name": "Omanyte",
      "types": ["Rock", "Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 35,
        "atk": 40,
        "def": 100,
        "spa": 90,
        "spd": 55,
        "spe": 35
      },
      "abilities": {"0": "Swift Swim", "1": "Shell Armor", "H": "Weak Armor"},
      "weightkg": 7.5,
      "evos": ["Omastar"],
      "eggGroups": ["Water 1", "Water 3"]
    },
    "omastar": {
      "num": 139,
      "name": "Omastar",
      "types": ["Rock", "Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 60,
        "def": 125,
        "spa": 115,
        "spd": 70,
        "spe": 55
      },
      "abilities": {"0": "Swift Swim", "1": "Shell Armor", "H": "Weak Armor"},
      "weightkg": 35,
      "prevo": "Omanyte",
      "evoLevel": 40,
      "eggGroups": ["Water 1", "Water 3"]
    },
    "kabuto": {
      "num": 140,
      "name": "Kabuto",
      "types": ["Rock", "Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 30,
        "atk": 80,
        "def": 90,
        "spa": 55,
        "spd": 45,
        "spe": 55
      },
      "abilities": {"0": "Swift Swim", "1": "Battle Armor", "H": "Weak Armor"},
      "weightkg": 11.5,
      "evos": ["Kabutops"],
      "eggGroups": ["Water 1", "Water 3"]
    },
    "kabutops": {
      "num": 141,
      "name": "Kabutops",
      "types": ["Rock", "Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 60,
        "atk": 115,
        "def": 105,
        "spa": 65,
        "spd": 70,
        "spe": 80
      },
      "abilities": {"0": "Swift Swim", "1": "Battle Armor", "H": "Weak Armor"},
      "weightkg": 40.5,
      "prevo": "Kabuto",
      "evoLevel": 40,
      "eggGroups": ["Water 1", "Water 3"]
    },
    "aerodactyl": {
      "num": 142,
      "name": "Aerodactyl",
      "types": ["Rock", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 105,
        "def": 65,
        "spa": 60,
        "spd": 75,
        "spe": 130
      },
      "abilities": {"0": "Rock Head", "1": "Pressure", "H": "Unnerve"},
      "weightkg": 59,
      "eggGroups": ["Flying"],
      "otherFormes": ["Aerodactyl-Mega"],
      "formeOrder": ["Aerodactyl", "Aerodactyl-Mega"]
    },
    "aerodactylmega": {
      "num": 142,
      "name": "Aerodactyl-Mega",
      "baseSpecies": "Aerodactyl",
      "forme": "Mega",
      "types": ["Rock", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 135,
        "def": 85,
        "spa": 70,
        "spd": 95,
        "spe": 150
      },
      "abilities": {"0": "Tough Claws"},
      "weightkg": 79,
      "eggGroups": ["Flying"],
      "requiredItem": "Aerodactylite"
    },
    "snorlax": {
      "num": 143,
      "name": "Snorlax",
      "types": ["Normal"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 160,
        "atk": 110,
        "def": 65,
        "spa": 65,
        "spd": 110,
        "spe": 30
      },
      "abilities": {"0": "Immunity", "1": "Thick Fat", "H": "Gluttony"},
      "weightkg": 460,
      "prevo": "Munchlax",
      "evoType": "levelFriendship",
      "eggGroups": ["Monster"],
      "canHatch": true,
      "canGigantamax": "G-Max Replenish"
    },
    "snorlaxgmax": {
      "num": 143,
      "name": "Snorlax-Gmax",
      "baseSpecies": "Snorlax",
      "forme": "Gmax",
      "types": ["Normal"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 160,
        "atk": 110,
        "def": 65,
        "spa": 65,
        "spd": 110,
        "spe": 30
      },
      "abilities": {"0": "Immunity", "1": "Thick Fat", "H": "Gluttony"},
      "weightkg": 0,
      "eggGroups": ["Monster"],
      "changesFrom": "Snorlax"
    },
    "articuno": {
      "num": 144,
      "name": "Articuno",
      "types": ["Ice", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 85,
        "def": 100,
        "spa": 95,
        "spd": 125,
        "spe": 85
      },
      "abilities": {"0": "Pressure", "H": "Snow Cloak"},
      "weightkg": 55.4,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Articuno-Galar"],
      "formeOrder": ["Articuno", "Articuno-Galar"]
    },
    "articunogalar": {
      "num": 144,
      "name": "Articuno-Galar",
      "baseSpecies": "Articuno",
      "forme": "Galar",
      "types": ["Psychic", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 85,
        "def": 85,
        "spa": 125,
        "spd": 100,
        "spe": 95
      },
      "abilities": {"0": "Competitive"},
      "weightkg": 50.9,
      "eggGroups": ["Undiscovered"]
    },
    "zapdos": {
      "num": 145,
      "name": "Zapdos",
      "types": ["Electric", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 90,
        "def": 85,
        "spa": 125,
        "spd": 90,
        "spe": 100
      },
      "abilities": {"0": "Pressure", "H": "Static"},
      "weightkg": 52.6,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Zapdos-Galar"],
      "formeOrder": ["Zapdos", "Zapdos-Galar"]
    },
    "zapdosgalar": {
      "num": 145,
      "name": "Zapdos-Galar",
      "baseSpecies": "Zapdos",
      "forme": "Galar",
      "types": ["Fighting", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 125,
        "def": 90,
        "spa": 85,
        "spd": 90,
        "spe": 100
      },
      "abilities": {"0": "Defiant"},
      "weightkg": 58.2,
      "eggGroups": ["Undiscovered"]
    },
    "moltres": {
      "num": 146,
      "name": "Moltres",
      "types": ["Fire", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 100,
        "def": 90,
        "spa": 125,
        "spd": 85,
        "spe": 90
      },
      "abilities": {"0": "Pressure", "H": "Flame Body"},
      "weightkg": 60,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Moltres-Galar"],
      "formeOrder": ["Moltres", "Moltres-Galar"]
    },
    "moltresgalar": {
      "num": 146,
      "name": "Moltres-Galar",
      "baseSpecies": "Moltres",
      "forme": "Galar",
      "types": ["Dark", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 85,
        "def": 90,
        "spa": 100,
        "spd": 125,
        "spe": 90
      },
      "abilities": {"0": "Berserk"},
      "weightkg": 66,
      "eggGroups": ["Undiscovered"]
    },
    "dratini": {
      "num": 147,
      "name": "Dratini",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 41,
        "atk": 64,
        "def": 45,
        "spa": 50,
        "spd": 50,
        "spe": 50
      },
      "abilities": {"0": "Shed Skin", "H": "Marvel Scale"},
      "weightkg": 3.3,
      "evos": ["Dragonair"],
      "eggGroups": ["Water 1", "Dragon"]
    },
    "dragonair": {
      "num": 148,
      "name": "Dragonair",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 61,
        "atk": 84,
        "def": 65,
        "spa": 70,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Shed Skin", "H": "Marvel Scale"},
      "weightkg": 16.5,
      "prevo": "Dratini",
      "evoLevel": 30,
      "evos": ["Dragonite"],
      "eggGroups": ["Water 1", "Dragon"]
    },
    "dragonite": {
      "num": 149,
      "name": "Dragonite",
      "types": ["Dragon", "Flying"],
      "baseStats": {
        "hp": 91,
        "atk": 134,
        "def": 95,
        "spa": 100,
        "spd": 100,
        "spe": 80
      },
      "abilities": {"0": "Inner Focus", "H": "Multiscale"},
      "weightkg": 210,
      "prevo": "Dragonair",
      "evoLevel": 55,
      "eggGroups": ["Water 1", "Dragon"]
    },
    "mewtwo": {
      "num": 150,
      "name": "Mewtwo",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 106,
        "atk": 110,
        "def": 90,
        "spa": 154,
        "spd": 90,
        "spe": 130
      },
      "abilities": {"0": "Pressure", "H": "Unnerve"},
      "weightkg": 122,
      "eggGroups": ["Undiscovered"],
      "tags": ["Restricted Legendary"],
      "otherFormes": ["Mewtwo-Mega-X", "Mewtwo-Mega-Y"],
      "formeOrder": ["Mewtwo", "Mewtwo-Mega-X", "Mewtwo-Mega-Y"]
    },
    "mewtwomegax": {
      "num": 150,
      "name": "Mewtwo-Mega-X",
      "baseSpecies": "Mewtwo",
      "forme": "Mega-X",
      "types": ["Psychic", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 106,
        "atk": 190,
        "def": 100,
        "spa": 154,
        "spd": 100,
        "spe": 130
      },
      "abilities": {"0": "Steadfast"},
      "weightkg": 127,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Mewtwonite X"
    },
    "mewtwomegay": {
      "num": 150,
      "name": "Mewtwo-Mega-Y",
      "baseSpecies": "Mewtwo",
      "forme": "Mega-Y",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 106,
        "atk": 150,
        "def": 70,
        "spa": 194,
        "spd": 120,
        "spe": 140
      },
      "abilities": {"0": "Insomnia"},
      "weightkg": 33,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Mewtwonite Y"
    },
    "mew": {
      "num": 151,
      "name": "Mew",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Synchronize"},
      "weightkg": 4,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"]
    },
    "chikorita": {
      "num": 152,
      "name": "Chikorita",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 45,
        "atk": 49,
        "def": 65,
        "spa": 49,
        "spd": 65,
        "spe": 45
      },
      "abilities": {"0": "Overgrow", "H": "Leaf Guard"},
      "weightkg": 6.4,
      "evos": ["Bayleef"],
      "eggGroups": ["Monster", "Grass"]
    },
    "bayleef": {
      "num": 153,
      "name": "Bayleef",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 60,
        "atk": 62,
        "def": 80,
        "spa": 63,
        "spd": 80,
        "spe": 60
      },
      "abilities": {"0": "Overgrow", "H": "Leaf Guard"},
      "weightkg": 15.8,
      "prevo": "Chikorita",
      "evoLevel": 16,
      "evos": ["Meganium"],
      "eggGroups": ["Monster", "Grass"]
    },
    "meganium": {
      "num": 154,
      "name": "Meganium",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 82,
        "def": 100,
        "spa": 83,
        "spd": 100,
        "spe": 80
      },
      "abilities": {"0": "Overgrow", "H": "Leaf Guard"},
      "weightkg": 100.5,
      "prevo": "Bayleef",
      "evoLevel": 32,
      "eggGroups": ["Monster", "Grass"]
    },
    "cyndaquil": {
      "num": 155,
      "name": "Cyndaquil",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 39,
        "atk": 52,
        "def": 43,
        "spa": 60,
        "spd": 50,
        "spe": 65
      },
      "abilities": {"0": "Blaze", "H": "Flash Fire"},
      "weightkg": 7.9,
      "evos": ["Quilava"],
      "eggGroups": ["Field"]
    },
    "quilava": {
      "num": 156,
      "name": "Quilava",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 58,
        "atk": 64,
        "def": 58,
        "spa": 80,
        "spd": 65,
        "spe": 80
      },
      "abilities": {"0": "Blaze", "H": "Flash Fire"},
      "weightkg": 19,
      "prevo": "Cyndaquil",
      "evoLevel": 14,
      "evos": ["Typhlosion", "Typhlosion-Hisui"],
      "eggGroups": ["Field"]
    },
    "typhlosion": {
      "num": 157,
      "name": "Typhlosion",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 78,
        "atk": 84,
        "def": 78,
        "spa": 109,
        "spd": 85,
        "spe": 100
      },
      "abilities": {"0": "Blaze", "H": "Flash Fire"},
      "weightkg": 79.5,
      "prevo": "Quilava",
      "evoLevel": 36,
      "eggGroups": ["Field"],
      "otherFormes": ["Typhlosion-Hisui"],
      "formeOrder": ["Typhlosion", "Typhlosion-Hisui"]
    },
    "typhlosionhisui": {
      "num": 157,
      "name": "Typhlosion-Hisui",
      "baseSpecies": "Typhlosion",
      "forme": "Hisui",
      "types": ["Fire", "Ghost"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 73,
        "atk": 84,
        "def": 78,
        "spa": 119,
        "spd": 85,
        "spe": 95
      },
      "abilities": {"0": "Blaze", "H": "Frisk"},
      "weightkg": 69.8,
      "prevo": "Quilava",
      "evoLevel": 36,
      "eggGroups": ["Field"]
    },
    "totodile": {
      "num": 158,
      "name": "Totodile",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 64,
        "spa": 44,
        "spd": 48,
        "spe": 43
      },
      "abilities": {"0": "Torrent", "H": "Sheer Force"},
      "weightkg": 9.5,
      "evos": ["Croconaw"],
      "eggGroups": ["Monster", "Water 1"]
    },
    "croconaw": {
      "num": 159,
      "name": "Croconaw",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 80,
        "def": 80,
        "spa": 59,
        "spd": 63,
        "spe": 58
      },
      "abilities": {"0": "Torrent", "H": "Sheer Force"},
      "weightkg": 25,
      "prevo": "Totodile",
      "evoLevel": 18,
      "evos": ["Feraligatr"],
      "eggGroups": ["Monster", "Water 1"]
    },
    "feraligatr": {
      "num": 160,
      "name": "Feraligatr",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 85,
        "atk": 105,
        "def": 100,
        "spa": 79,
        "spd": 83,
        "spe": 78
      },
      "abilities": {"0": "Torrent", "H": "Sheer Force"},
      "weightkg": 88.8,
      "prevo": "Croconaw",
      "evoLevel": 30,
      "eggGroups": ["Monster", "Water 1"]
    },
    "sentret": {
      "num": 161,
      "name": "Sentret",
      "types": ["Normal"],
      "baseStats": {
        "hp": 35,
        "atk": 46,
        "def": 34,
        "spa": 35,
        "spd": 45,
        "spe": 20
      },
      "abilities": {"0": "Run Away", "1": "Keen Eye", "H": "Frisk"},
      "weightkg": 6,
      "evos": ["Furret"],
      "eggGroups": ["Field"]
    },
    "furret": {
      "num": 162,
      "name": "Furret",
      "types": ["Normal"],
      "baseStats": {
        "hp": 85,
        "atk": 76,
        "def": 64,
        "spa": 45,
        "spd": 55,
        "spe": 90
      },
      "abilities": {"0": "Run Away", "1": "Keen Eye", "H": "Frisk"},
      "weightkg": 32.5,
      "prevo": "Sentret",
      "evoLevel": 15,
      "eggGroups": ["Field"]
    },
    "hoothoot": {
      "num": 163,
      "name": "Hoothoot",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 60,
        "atk": 30,
        "def": 30,
        "spa": 36,
        "spd": 56,
        "spe": 50
      },
      "abilities": {"0": "Insomnia", "1": "Keen Eye", "H": "Tinted Lens"},
      "weightkg": 21.2,
      "evos": ["Noctowl"],
      "eggGroups": ["Flying"]
    },
    "noctowl": {
      "num": 164,
      "name": "Noctowl",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 100,
        "atk": 50,
        "def": 50,
        "spa": 86,
        "spd": 96,
        "spe": 70
      },
      "abilities": {"0": "Insomnia", "1": "Keen Eye", "H": "Tinted Lens"},
      "weightkg": 40.8,
      "prevo": "Hoothoot",
      "evoLevel": 20,
      "eggGroups": ["Flying"]
    },
    "ledyba": {
      "num": 165,
      "name": "Ledyba",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 40,
        "atk": 20,
        "def": 30,
        "spa": 40,
        "spd": 80,
        "spe": 55
      },
      "abilities": {"0": "Swarm", "1": "Early Bird", "H": "Rattled"},
      "weightkg": 10.8,
      "evos": ["Ledian"],
      "eggGroups": ["Bug"]
    },
    "ledian": {
      "num": 166,
      "name": "Ledian",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 55,
        "atk": 35,
        "def": 50,
        "spa": 55,
        "spd": 110,
        "spe": 85
      },
      "abilities": {"0": "Swarm", "1": "Early Bird", "H": "Iron Fist"},
      "weightkg": 35.6,
      "prevo": "Ledyba",
      "evoLevel": 18,
      "eggGroups": ["Bug"]
    },
    "spinarak": {
      "num": 167,
      "name": "Spinarak",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 40,
        "atk": 60,
        "def": 40,
        "spa": 40,
        "spd": 40,
        "spe": 30
      },
      "abilities": {"0": "Swarm", "1": "Insomnia", "H": "Sniper"},
      "weightkg": 8.5,
      "evos": ["Ariados"],
      "eggGroups": ["Bug"]
    },
    "ariados": {
      "num": 168,
      "name": "Ariados",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 70,
        "atk": 90,
        "def": 70,
        "spa": 60,
        "spd": 70,
        "spe": 40
      },
      "abilities": {"0": "Swarm", "1": "Insomnia", "H": "Sniper"},
      "weightkg": 33.5,
      "prevo": "Spinarak",
      "evoLevel": 22,
      "eggGroups": ["Bug"]
    },
    "crobat": {
      "num": 169,
      "name": "Crobat",
      "types": ["Poison", "Flying"],
      "baseStats": {
        "hp": 85,
        "atk": 90,
        "def": 80,
        "spa": 70,
        "spd": 80,
        "spe": 130
      },
      "abilities": {"0": "Inner Focus", "H": "Infiltrator"},
      "weightkg": 75,
      "prevo": "Golbat",
      "evoType": "levelFriendship",
      "eggGroups": ["Flying"]
    },
    "chinchou": {
      "num": 170,
      "name": "Chinchou",
      "types": ["Water", "Electric"],
      "baseStats": {
        "hp": 75,
        "atk": 38,
        "def": 38,
        "spa": 56,
        "spd": 56,
        "spe": 67
      },
      "abilities": {"0": "Volt Absorb", "1": "Illuminate", "H": "Water Absorb"},
      "weightkg": 12,
      "evos": ["Lanturn"],
      "eggGroups": ["Water 2"]
    },
    "lanturn": {
      "num": 171,
      "name": "Lanturn",
      "types": ["Water", "Electric"],
      "baseStats": {
        "hp": 125,
        "atk": 58,
        "def": 58,
        "spa": 76,
        "spd": 76,
        "spe": 67
      },
      "abilities": {"0": "Volt Absorb", "1": "Illuminate", "H": "Water Absorb"},
      "weightkg": 22.5,
      "prevo": "Chinchou",
      "evoLevel": 27,
      "eggGroups": ["Water 2"]
    },
    "pichu": {
      "num": 172,
      "name": "Pichu",
      "types": ["Electric"],
      "baseStats": {
        "hp": 20,
        "atk": 40,
        "def": 15,
        "spa": 35,
        "spd": 35,
        "spe": 60
      },
      "abilities": {"0": "Static", "H": "Lightning Rod"},
      "weightkg": 2,
      "evos": ["Pikachu"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true,
      "otherFormes": ["Pichu-Spiky-eared"],
      "formeOrder": ["Pichu", "Pichu-Spiky-eared"]
    },
    "pichuspikyeared": {
      "num": 172,
      "name": "Pichu-Spiky-eared",
      "baseSpecies": "Pichu",
      "forme": "Spiky-eared",
      "types": ["Electric"],
      "baseStats": {
        "hp": 20,
        "atk": 40,
        "def": 15,
        "spa": 35,
        "spd": 35,
        "spe": 60
      },
      "abilities": {"0": "Static"},
      "weightkg": 2,
      "eggGroups": ["Undiscovered"],
      "gen": 4
    },
    "cleffa": {
      "num": 173,
      "name": "Cleffa",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 50,
        "atk": 25,
        "def": 28,
        "spa": 45,
        "spd": 55,
        "spe": 15
      },
      "abilities": {"0": "Cute Charm", "1": "Magic Guard", "H": "Friend Guard"},
      "weightkg": 3,
      "evos": ["Clefairy"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "igglybuff": {
      "num": 174,
      "name": "Igglybuff",
      "types": ["Normal", "Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 90,
        "atk": 30,
        "def": 15,
        "spa": 40,
        "spd": 20,
        "spe": 15
      },
      "abilities": {"0": "Cute Charm", "1": "Competitive", "H": "Friend Guard"},
      "weightkg": 1,
      "evos": ["Jigglypuff"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "togepi": {
      "num": 175,
      "name": "Togepi",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 35,
        "atk": 20,
        "def": 65,
        "spa": 40,
        "spd": 65,
        "spe": 20
      },
      "abilities": {"0": "Hustle", "1": "Serene Grace", "H": "Super Luck"},
      "weightkg": 1.5,
      "evos": ["Togetic"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "togetic": {
      "num": 176,
      "name": "Togetic",
      "types": ["Fairy", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 55,
        "atk": 40,
        "def": 85,
        "spa": 80,
        "spd": 105,
        "spe": 40
      },
      "abilities": {"0": "Hustle", "1": "Serene Grace", "H": "Super Luck"},
      "weightkg": 3.2,
      "prevo": "Togepi",
      "evoType": "levelFriendship",
      "evos": ["Togekiss"],
      "eggGroups": ["Flying", "Fairy"]
    },
    "natu": {
      "num": 177,
      "name": "Natu",
      "types": ["Psychic", "Flying"],
      "baseStats": {
        "hp": 40,
        "atk": 50,
        "def": 45,
        "spa": 70,
        "spd": 45,
        "spe": 70
      },
      "abilities": {"0": "Synchronize", "1": "Early Bird", "H": "Magic Bounce"},
      "weightkg": 2,
      "evos": ["Xatu"],
      "eggGroups": ["Flying"]
    },
    "xatu": {
      "num": 178,
      "name": "Xatu",
      "types": ["Psychic", "Flying"],
      "baseStats": {
        "hp": 65,
        "atk": 75,
        "def": 70,
        "spa": 95,
        "spd": 70,
        "spe": 95
      },
      "abilities": {"0": "Synchronize", "1": "Early Bird", "H": "Magic Bounce"},
      "weightkg": 15,
      "prevo": "Natu",
      "evoLevel": 25,
      "eggGroups": ["Flying"]
    },
    "mareep": {
      "num": 179,
      "name": "Mareep",
      "types": ["Electric"],
      "baseStats": {
        "hp": 55,
        "atk": 40,
        "def": 40,
        "spa": 65,
        "spd": 45,
        "spe": 35
      },
      "abilities": {"0": "Static", "H": "Plus"},
      "weightkg": 7.8,
      "evos": ["Flaaffy"],
      "eggGroups": ["Monster", "Field"]
    },
    "flaaffy": {
      "num": 180,
      "name": "Flaaffy",
      "types": ["Electric"],
      "baseStats": {
        "hp": 70,
        "atk": 55,
        "def": 55,
        "spa": 80,
        "spd": 60,
        "spe": 45
      },
      "abilities": {"0": "Static", "H": "Plus"},
      "weightkg": 13.3,
      "prevo": "Mareep",
      "evoLevel": 15,
      "evos": ["Ampharos"],
      "eggGroups": ["Monster", "Field"]
    },
    "ampharos": {
      "num": 181,
      "name": "Ampharos",
      "types": ["Electric"],
      "baseStats": {
        "hp": 90,
        "atk": 75,
        "def": 85,
        "spa": 115,
        "spd": 90,
        "spe": 55
      },
      "abilities": {"0": "Static", "H": "Plus"},
      "weightkg": 61.5,
      "prevo": "Flaaffy",
      "evoLevel": 30,
      "eggGroups": ["Monster", "Field"],
      "otherFormes": ["Ampharos-Mega"],
      "formeOrder": ["Ampharos", "Ampharos-Mega"]
    },
    "ampharosmega": {
      "num": 181,
      "name": "Ampharos-Mega",
      "baseSpecies": "Ampharos",
      "forme": "Mega",
      "types": ["Electric", "Dragon"],
      "baseStats": {
        "hp": 90,
        "atk": 95,
        "def": 105,
        "spa": 165,
        "spd": 110,
        "spe": 45
      },
      "abilities": {"0": "Mold Breaker"},
      "weightkg": 61.5,
      "eggGroups": ["Monster", "Field"],
      "requiredItem": "Ampharosite"
    },
    "bellossom": {
      "num": 182,
      "name": "Bellossom",
      "types": ["Grass"],
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 95,
        "spa": 90,
        "spd": 100,
        "spe": 50
      },
      "abilities": {"0": "Chlorophyll", "H": "Healer"},
      "weightkg": 5.8,
      "prevo": "Gloom",
      "evoType": "useItem",
      "evoItem": "Sun Stone",
      "eggGroups": ["Grass"]
    },
    "marill": {
      "num": 183,
      "name": "Marill",
      "types": ["Water", "Fairy"],
      "baseStats": {
        "hp": 70,
        "atk": 20,
        "def": 50,
        "spa": 20,
        "spd": 50,
        "spe": 40
      },
      "abilities": {"0": "Thick Fat", "1": "Huge Power", "H": "Sap Sipper"},
      "weightkg": 8.5,
      "prevo": "Azurill",
      "evoType": "levelFriendship",
      "evos": ["Azumarill"],
      "eggGroups": ["Water 1", "Fairy"],
      "canHatch": true
    },
    "azumarill": {
      "num": 184,
      "name": "Azumarill",
      "types": ["Water", "Fairy"],
      "baseStats": {
        "hp": 100,
        "atk": 50,
        "def": 80,
        "spa": 60,
        "spd": 80,
        "spe": 50
      },
      "abilities": {"0": "Thick Fat", "1": "Huge Power", "H": "Sap Sipper"},
      "weightkg": 28.5,
      "prevo": "Marill",
      "evoLevel": 18,
      "eggGroups": ["Water 1", "Fairy"]
    },
    "sudowoodo": {
      "num": 185,
      "name": "Sudowoodo",
      "types": ["Rock"],
      "baseStats": {
        "hp": 70,
        "atk": 100,
        "def": 115,
        "spa": 30,
        "spd": 65,
        "spe": 30
      },
      "abilities": {"0": "Sturdy", "1": "Rock Head", "H": "Rattled"},
      "weightkg": 38,
      "prevo": "Bonsly",
      "evoType": "levelMove",
      "evoMove": "Mimic",
      "eggGroups": ["Mineral"],
      "canHatch": true
    },
    "politoed": {
      "num": 186,
      "name": "Politoed",
      "types": ["Water"],
      "baseStats": {
        "hp": 90,
        "atk": 75,
        "def": 75,
        "spa": 90,
        "spd": 100,
        "spe": 70
      },
      "abilities": {"0": "Water Absorb", "1": "Damp", "H": "Drizzle"},
      "weightkg": 33.9,
      "prevo": "Poliwhirl",
      "evoType": "trade",
      "evoItem": "King's Rock",
      "eggGroups": ["Water 1"]
    },
    "hoppip": {
      "num": 187,
      "name": "Hoppip",
      "types": ["Grass", "Flying"],
      "baseStats": {
        "hp": 35,
        "atk": 35,
        "def": 40,
        "spa": 35,
        "spd": 55,
        "spe": 50
      },
      "abilities": {"0": "Chlorophyll", "1": "Leaf Guard", "H": "Infiltrator"},
      "weightkg": 0.5,
      "evos": ["Skiploom"],
      "eggGroups": ["Fairy", "Grass"]
    },
    "skiploom": {
      "num": 188,
      "name": "Skiploom",
      "types": ["Grass", "Flying"],
      "baseStats": {
        "hp": 55,
        "atk": 45,
        "def": 50,
        "spa": 45,
        "spd": 65,
        "spe": 80
      },
      "abilities": {"0": "Chlorophyll", "1": "Leaf Guard", "H": "Infiltrator"},
      "weightkg": 1,
      "prevo": "Hoppip",
      "evoLevel": 18,
      "evos": ["Jumpluff"],
      "eggGroups": ["Fairy", "Grass"]
    },
    "jumpluff": {
      "num": 189,
      "name": "Jumpluff",
      "types": ["Grass", "Flying"],
      "baseStats": {
        "hp": 75,
        "atk": 55,
        "def": 70,
        "spa": 55,
        "spd": 95,
        "spe": 110
      },
      "abilities": {"0": "Chlorophyll", "1": "Leaf Guard", "H": "Infiltrator"},
      "weightkg": 3,
      "prevo": "Skiploom",
      "evoLevel": 27,
      "eggGroups": ["Fairy", "Grass"]
    },
    "aipom": {
      "num": 190,
      "name": "Aipom",
      "types": ["Normal"],
      "baseStats": {
        "hp": 55,
        "atk": 70,
        "def": 55,
        "spa": 40,
        "spd": 55,
        "spe": 85
      },
      "abilities": {"0": "Run Away", "1": "Pickup", "H": "Skill Link"},
      "weightkg": 11.5,
      "evos": ["Ambipom"],
      "eggGroups": ["Field"]
    },
    "sunkern": {
      "num": 191,
      "name": "Sunkern",
      "types": ["Grass"],
      "baseStats": {
        "hp": 30,
        "atk": 30,
        "def": 30,
        "spa": 30,
        "spd": 30,
        "spe": 30
      },
      "abilities": {"0": "Chlorophyll", "1": "Solar Power", "H": "Early Bird"},
      "weightkg": 1.8,
      "evos": ["Sunflora"],
      "eggGroups": ["Grass"]
    },
    "sunflora": {
      "num": 192,
      "name": "Sunflora",
      "types": ["Grass"],
      "baseStats": {
        "hp": 75,
        "atk": 75,
        "def": 55,
        "spa": 105,
        "spd": 85,
        "spe": 30
      },
      "abilities": {"0": "Chlorophyll", "1": "Solar Power", "H": "Early Bird"},
      "weightkg": 8.5,
      "prevo": "Sunkern",
      "evoType": "useItem",
      "evoItem": "Sun Stone",
      "eggGroups": ["Grass"]
    },
    "yanma": {
      "num": 193,
      "name": "Yanma",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 65,
        "atk": 65,
        "def": 45,
        "spa": 75,
        "spd": 45,
        "spe": 95
      },
      "abilities": {"0": "Speed Boost", "1": "Compound Eyes", "H": "Frisk"},
      "weightkg": 38,
      "evos": ["Yanmega"],
      "eggGroups": ["Bug"]
    },
    "wooper": {
      "num": 194,
      "name": "Wooper",
      "types": ["Water", "Ground"],
      "baseStats": {
        "hp": 55,
        "atk": 45,
        "def": 45,
        "spa": 25,
        "spd": 25,
        "spe": 15
      },
      "abilities": {"0": "Damp", "1": "Water Absorb", "H": "Unaware"},
      "weightkg": 8.5,
      "evos": ["Quagsire"],
      "eggGroups": ["Water 1", "Field"],
      "otherFormes": ["Wooper-Paldea"],
      "formeOrder": ["Wooper", "Wooper-Paldea"]
    },
    "wooperpaldea": {
      "num": 194,
      "name": "Wooper-Paldea",
      "baseSpecies": "Wooper",
      "forme": "Paldea",
      "types": ["Poison", "Ground"],
      "baseStats": {
        "hp": 55,
        "atk": 45,
        "def": 45,
        "spa": 25,
        "spd": 25,
        "spe": 15
      },
      "abilities": {"0": "Poison Point", "1": "Water Absorb", "H": "Unaware"},
      "weightkg": 11,
      "evos": ["Clodsire"],
      "eggGroups": ["Water 1", "Field"]
    },
    "quagsire": {
      "num": 195,
      "name": "Quagsire",
      "types": ["Water", "Ground"],
      "baseStats": {
        "hp": 95,
        "atk": 85,
        "def": 85,
        "spa": 65,
        "spd": 65,
        "spe": 35
      },
      "abilities": {"0": "Damp", "1": "Water Absorb", "H": "Unaware"},
      "weightkg": 75,
      "prevo": "Wooper",
      "evoLevel": 20,
      "eggGroups": ["Water 1", "Field"]
    },
    "espeon": {
      "num": 196,
      "name": "Espeon",
      "types": ["Psychic"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 65,
        "def": 60,
        "spa": 130,
        "spd": 95,
        "spe": 110
      },
      "abilities": {"0": "Synchronize", "H": "Magic Bounce"},
      "weightkg": 26.5,
      "prevo": "Eevee",
      "evoType": "levelFriendship",
      "evoCondition": "during the day",
      "eggGroups": ["Field"]
    },
    "umbreon": {
      "num": 197,
      "name": "Umbreon",
      "types": ["Dark"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 95,
        "atk": 65,
        "def": 110,
        "spa": 60,
        "spd": 130,
        "spe": 65
      },
      "abilities": {"0": "Synchronize", "H": "Inner Focus"},
      "weightkg": 27,
      "prevo": "Eevee",
      "evoType": "levelFriendship",
      "evoCondition": "at night",
      "eggGroups": ["Field"]
    },
    "murkrow": {
      "num": 198,
      "name": "Murkrow",
      "types": ["Dark", "Flying"],
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 42,
        "spa": 85,
        "spd": 42,
        "spe": 91
      },
      "abilities": {"0": "Insomnia", "1": "Super Luck", "H": "Prankster"},
      "weightkg": 2.1,
      "evos": ["Honchkrow"],
      "eggGroups": ["Flying"]
    },
    "slowking": {
      "num": 199,
      "name": "Slowking",
      "types": ["Water", "Psychic"],
      "baseStats": {
        "hp": 95,
        "atk": 75,
        "def": 80,
        "spa": 100,
        "spd": 110,
        "spe": 30
      },
      "abilities": {"0": "Oblivious", "1": "Own Tempo", "H": "Regenerator"},
      "weightkg": 79.5,
      "prevo": "Slowpoke",
      "evoType": "trade",
      "evoItem": "King's Rock",
      "eggGroups": ["Monster", "Water 1"],
      "otherFormes": ["Slowking-Galar"],
      "formeOrder": ["Slowking", "Slowking-Galar"]
    },
    "slowkinggalar": {
      "num": 199,
      "name": "Slowking-Galar",
      "baseSpecies": "Slowking",
      "forme": "Galar",
      "types": ["Poison", "Psychic"],
      "baseStats": {
        "hp": 95,
        "atk": 65,
        "def": 80,
        "spa": 110,
        "spd": 110,
        "spe": 30
      },
      "abilities": {
        "0": "Curious Medicine",
        "1": "Own Tempo",
        "H": "Regenerator"
      },
      "weightkg": 79.5,
      "prevo": "Slowpoke-Galar",
      "evoType": "useItem",
      "evoItem": "Galarica Wreath",
      "eggGroups": ["Monster", "Water 1"]
    },
    "misdreavus": {
      "num": 200,
      "name": "Misdreavus",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 60,
        "spa": 85,
        "spd": 85,
        "spe": 85
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 1,
      "evos": ["Mismagius"],
      "eggGroups": ["Amorphous"]
    },
    "unown": {
      "num": 201,
      "name": "Unown",
      "baseForme": "A",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 48,
        "atk": 72,
        "def": 48,
        "spa": 72,
        "spd": 48,
        "spe": 48
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 5,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": [
        "Unown-B",
        "Unown-C",
        "Unown-D",
        "Unown-E",
        "Unown-F",
        "Unown-G",
        "Unown-H",
        "Unown-I",
        "Unown-J",
        "Unown-K",
        "Unown-L",
        "Unown-M",
        "Unown-N",
        "Unown-O",
        "Unown-P",
        "Unown-Q",
        "Unown-R",
        "Unown-S",
        "Unown-T",
        "Unown-U",
        "Unown-V",
        "Unown-W",
        "Unown-X",
        "Unown-Y",
        "Unown-Z",
        "Unown-Exclamation",
        "Unown-Question"
      ],
      "formeOrder": [
        "Unown",
        "Unown-B",
        "Unown-C",
        "Unown-D",
        "Unown-E",
        "Unown-F",
        "Unown-G",
        "Unown-H",
        "Unown-I",
        "Unown-J",
        "Unown-K",
        "Unown-L",
        "Unown-M",
        "Unown-N",
        "Unown-O",
        "Unown-P",
        "Unown-Q",
        "Unown-R",
        "Unown-S",
        "Unown-T",
        "Unown-U",
        "Unown-V",
        "Unown-W",
        "Unown-X",
        "Unown-Y",
        "Unown-Z",
        "Unown-Exclamation",
        "Unown-Question"
      ]
    },
    "wobbuffet": {
      "num": 202,
      "name": "Wobbuffet",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 190,
        "atk": 33,
        "def": 58,
        "spa": 33,
        "spd": 58,
        "spe": 33
      },
      "abilities": {"0": "Shadow Tag", "H": "Telepathy"},
      "weightkg": 28.5,
      "prevo": "Wynaut",
      "evoLevel": 15,
      "eggGroups": ["Amorphous"],
      "canHatch": true
    },
    "girafarig": {
      "num": 203,
      "name": "Girafarig",
      "types": ["Normal", "Psychic"],
      "baseStats": {
        "hp": 70,
        "atk": 80,
        "def": 65,
        "spa": 90,
        "spd": 65,
        "spe": 85
      },
      "abilities": {"0": "Inner Focus", "1": "Early Bird", "H": "Sap Sipper"},
      "weightkg": 41.5,
      "evos": ["Farigiraf"],
      "eggGroups": ["Field"]
    },
    "pineco": {
      "num": 204,
      "name": "Pineco",
      "types": ["Bug"],
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 90,
        "spa": 35,
        "spd": 35,
        "spe": 15
      },
      "abilities": {"0": "Sturdy", "H": "Overcoat"},
      "weightkg": 7.2,
      "evos": ["Forretress"],
      "eggGroups": ["Bug"]
    },
    "forretress": {
      "num": 205,
      "name": "Forretress",
      "types": ["Bug", "Steel"],
      "baseStats": {
        "hp": 75,
        "atk": 90,
        "def": 140,
        "spa": 60,
        "spd": 60,
        "spe": 40
      },
      "abilities": {"0": "Sturdy", "H": "Overcoat"},
      "weightkg": 125.8,
      "prevo": "Pineco",
      "evoLevel": 31,
      "eggGroups": ["Bug"]
    },
    "dunsparce": {
      "num": 206,
      "name": "Dunsparce",
      "types": ["Normal"],
      "baseStats": {
        "hp": 100,
        "atk": 70,
        "def": 70,
        "spa": 65,
        "spd": 65,
        "spe": 45
      },
      "abilities": {"0": "Serene Grace", "1": "Run Away", "H": "Rattled"},
      "weightkg": 14,
      "evos": ["Dudunsparce", "Dudunsparce-Three-Segment"],
      "eggGroups": ["Field"]
    },
    "gligar": {
      "num": 207,
      "name": "Gligar",
      "types": ["Ground", "Flying"],
      "baseStats": {
        "hp": 65,
        "atk": 75,
        "def": 105,
        "spa": 35,
        "spd": 65,
        "spe": 85
      },
      "abilities": {"0": "Hyper Cutter", "1": "Sand Veil", "H": "Immunity"},
      "weightkg": 64.8,
      "evos": ["Gliscor"],
      "eggGroups": ["Bug"]
    },
    "steelix": {
      "num": 208,
      "name": "Steelix",
      "types": ["Steel", "Ground"],
      "baseStats": {
        "hp": 75,
        "atk": 85,
        "def": 200,
        "spa": 55,
        "spd": 65,
        "spe": 30
      },
      "abilities": {"0": "Rock Head", "1": "Sturdy", "H": "Sheer Force"},
      "weightkg": 400,
      "prevo": "Onix",
      "evoType": "trade",
      "evoItem": "Metal Coat",
      "eggGroups": ["Mineral"],
      "otherFormes": ["Steelix-Mega"],
      "formeOrder": ["Steelix", "Steelix-Mega"]
    },
    "steelixmega": {
      "num": 208,
      "name": "Steelix-Mega",
      "baseSpecies": "Steelix",
      "forme": "Mega",
      "types": ["Steel", "Ground"],
      "baseStats": {
        "hp": 75,
        "atk": 125,
        "def": 230,
        "spa": 55,
        "spd": 95,
        "spe": 30
      },
      "abilities": {"0": "Sand Force"},
      "weightkg": 740,
      "eggGroups": ["Mineral"],
      "requiredItem": "Steelixite"
    },
    "snubbull": {
      "num": 209,
      "name": "Snubbull",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 60,
        "atk": 80,
        "def": 50,
        "spa": 40,
        "spd": 40,
        "spe": 30
      },
      "abilities": {"0": "Intimidate", "1": "Run Away", "H": "Rattled"},
      "weightkg": 7.8,
      "evos": ["Granbull"],
      "eggGroups": ["Field", "Fairy"]
    },
    "granbull": {
      "num": 210,
      "name": "Granbull",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 90,
        "atk": 120,
        "def": 75,
        "spa": 60,
        "spd": 60,
        "spe": 45
      },
      "abilities": {"0": "Intimidate", "1": "Quick Feet", "H": "Rattled"},
      "weightkg": 48.7,
      "prevo": "Snubbull",
      "evoLevel": 23,
      "eggGroups": ["Field", "Fairy"]
    },
    "qwilfish": {
      "num": 211,
      "name": "Qwilfish",
      "types": ["Water", "Poison"],
      "baseStats": {
        "hp": 65,
        "atk": 95,
        "def": 85,
        "spa": 55,
        "spd": 55,
        "spe": 85
      },
      "abilities": {"0": "Poison Point", "1": "Swift Swim", "H": "Intimidate"},
      "weightkg": 3.9,
      "eggGroups": ["Water 2"],
      "otherFormes": ["Qwilfish-Hisui"],
      "formeOrder": ["Qwilfish", "Qwilfish-Hisui"]
    },
    "qwilfishhisui": {
      "num": 211,
      "name": "Qwilfish-Hisui",
      "baseSpecies": "Qwilfish",
      "forme": "Hisui",
      "types": ["Dark", "Poison"],
      "baseStats": {
        "hp": 65,
        "atk": 95,
        "def": 85,
        "spa": 55,
        "spd": 55,
        "spe": 85
      },
      "abilities": {"0": "Poison Point", "1": "Swift Swim", "H": "Intimidate"},
      "weightkg": 3.9,
      "evos": ["Overqwil"],
      "eggGroups": ["Water 2"]
    },
    "scizor": {
      "num": 212,
      "name": "Scizor",
      "types": ["Bug", "Steel"],
      "baseStats": {
        "hp": 70,
        "atk": 130,
        "def": 100,
        "spa": 55,
        "spd": 80,
        "spe": 65
      },
      "abilities": {"0": "Swarm", "1": "Technician", "H": "Light Metal"},
      "weightkg": 118,
      "prevo": "Scyther",
      "evoType": "trade",
      "evoItem": "Metal Coat",
      "eggGroups": ["Bug"],
      "otherFormes": ["Scizor-Mega"],
      "formeOrder": ["Scizor", "Scizor-Mega"]
    },
    "scizormega": {
      "num": 212,
      "name": "Scizor-Mega",
      "baseSpecies": "Scizor",
      "forme": "Mega",
      "types": ["Bug", "Steel"],
      "baseStats": {
        "hp": 70,
        "atk": 150,
        "def": 140,
        "spa": 65,
        "spd": 100,
        "spe": 75
      },
      "abilities": {"0": "Technician"},
      "weightkg": 125,
      "eggGroups": ["Bug"],
      "requiredItem": "Scizorite"
    },
    "shuckle": {
      "num": 213,
      "name": "Shuckle",
      "types": ["Bug", "Rock"],
      "baseStats": {
        "hp": 20,
        "atk": 10,
        "def": 230,
        "spa": 10,
        "spd": 230,
        "spe": 5
      },
      "abilities": {"0": "Sturdy", "1": "Gluttony", "H": "Contrary"},
      "weightkg": 20.5,
      "eggGroups": ["Bug"]
    },
    "heracross": {
      "num": 214,
      "name": "Heracross",
      "types": ["Bug", "Fighting"],
      "baseStats": {
        "hp": 80,
        "atk": 125,
        "def": 75,
        "spa": 40,
        "spd": 95,
        "spe": 85
      },
      "abilities": {"0": "Swarm", "1": "Guts", "H": "Moxie"},
      "weightkg": 54,
      "eggGroups": ["Bug"],
      "otherFormes": ["Heracross-Mega"],
      "formeOrder": ["Heracross", "Heracross-Mega"]
    },
    "heracrossmega": {
      "num": 214,
      "name": "Heracross-Mega",
      "baseSpecies": "Heracross",
      "forme": "Mega",
      "types": ["Bug", "Fighting"],
      "baseStats": {
        "hp": 80,
        "atk": 185,
        "def": 115,
        "spa": 40,
        "spd": 105,
        "spe": 75
      },
      "abilities": {"0": "Skill Link"},
      "weightkg": 62.5,
      "eggGroups": ["Bug"],
      "requiredItem": "Heracronite"
    },
    "sneasel": {
      "num": 215,
      "name": "Sneasel",
      "types": ["Dark", "Ice"],
      "baseStats": {
        "hp": 55,
        "atk": 95,
        "def": 55,
        "spa": 35,
        "spd": 75,
        "spe": 115
      },
      "abilities": {"0": "Inner Focus", "1": "Keen Eye", "H": "Pickpocket"},
      "weightkg": 28,
      "evos": ["Weavile"],
      "eggGroups": ["Field"],
      "otherFormes": ["Sneasel-Hisui"],
      "formeOrder": ["Sneasel", "Sneasel-Hisui"]
    },
    "sneaselhisui": {
      "num": 215,
      "name": "Sneasel-Hisui",
      "baseSpecies": "Sneasel",
      "forme": "Hisui",
      "types": ["Fighting", "Poison"],
      "baseStats": {
        "hp": 55,
        "atk": 95,
        "def": 55,
        "spa": 35,
        "spd": 75,
        "spe": 115
      },
      "abilities": {"0": "Inner Focus", "1": "Keen Eye", "H": "Pickpocket"},
      "weightkg": 27,
      "evos": ["Sneasler"],
      "eggGroups": ["Field"]
    },
    "teddiursa": {
      "num": 216,
      "name": "Teddiursa",
      "types": ["Normal"],
      "baseStats": {
        "hp": 60,
        "atk": 80,
        "def": 50,
        "spa": 50,
        "spd": 50,
        "spe": 40
      },
      "abilities": {"0": "Pickup", "1": "Quick Feet", "H": "Honey Gather"},
      "weightkg": 8.8,
      "evos": ["Ursaring"],
      "eggGroups": ["Field"]
    },
    "ursaring": {
      "num": 217,
      "name": "Ursaring",
      "types": ["Normal"],
      "baseStats": {
        "hp": 90,
        "atk": 130,
        "def": 75,
        "spa": 75,
        "spd": 75,
        "spe": 55
      },
      "abilities": {"0": "Guts", "1": "Quick Feet", "H": "Unnerve"},
      "weightkg": 125.8,
      "prevo": "Teddiursa",
      "evoLevel": 30,
      "evos": ["Ursaluna"],
      "eggGroups": ["Field"]
    },
    "slugma": {
      "num": 218,
      "name": "Slugma",
      "types": ["Fire"],
      "baseStats": {
        "hp": 40,
        "atk": 40,
        "def": 40,
        "spa": 70,
        "spd": 40,
        "spe": 20
      },
      "abilities": {"0": "Magma Armor", "1": "Flame Body", "H": "Weak Armor"},
      "weightkg": 35,
      "evos": ["Magcargo"],
      "eggGroups": ["Amorphous"]
    },
    "magcargo": {
      "num": 219,
      "name": "Magcargo",
      "types": ["Fire", "Rock"],
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 120,
        "spa": 90,
        "spd": 80,
        "spe": 30
      },
      "abilities": {"0": "Magma Armor", "1": "Flame Body", "H": "Weak Armor"},
      "weightkg": 55,
      "prevo": "Slugma",
      "evoLevel": 38,
      "eggGroups": ["Amorphous"]
    },
    "swinub": {
      "num": 220,
      "name": "Swinub",
      "types": ["Ice", "Ground"],
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 40,
        "spa": 30,
        "spd": 30,
        "spe": 50
      },
      "abilities": {"0": "Oblivious", "1": "Snow Cloak", "H": "Thick Fat"},
      "weightkg": 6.5,
      "evos": ["Piloswine"],
      "eggGroups": ["Field"]
    },
    "piloswine": {
      "num": 221,
      "name": "Piloswine",
      "types": ["Ice", "Ground"],
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 80,
        "spa": 60,
        "spd": 60,
        "spe": 50
      },
      "abilities": {"0": "Oblivious", "1": "Snow Cloak", "H": "Thick Fat"},
      "weightkg": 55.8,
      "prevo": "Swinub",
      "evoLevel": 33,
      "evos": ["Mamoswine"],
      "eggGroups": ["Field"]
    },
    "corsola": {
      "num": 222,
      "name": "Corsola",
      "types": ["Water", "Rock"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 65,
        "atk": 55,
        "def": 95,
        "spa": 65,
        "spd": 95,
        "spe": 35
      },
      "abilities": {"0": "Hustle", "1": "Natural Cure", "H": "Regenerator"},
      "weightkg": 5,
      "eggGroups": ["Water 1", "Water 3"],
      "otherFormes": ["Corsola-Galar"],
      "formeOrder": ["Corsola", "Corsola-Galar"]
    },
    "corsolagalar": {
      "num": 222,
      "name": "Corsola-Galar",
      "baseSpecies": "Corsola",
      "forme": "Galar",
      "types": ["Ghost"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 60,
        "atk": 55,
        "def": 100,
        "spa": 65,
        "spd": 100,
        "spe": 30
      },
      "abilities": {"0": "Weak Armor", "H": "Cursed Body"},
      "weightkg": 0.5,
      "evos": ["Cursola"],
      "eggGroups": ["Water 1", "Water 3"]
    },
    "remoraid": {
      "num": 223,
      "name": "Remoraid",
      "types": ["Water"],
      "baseStats": {
        "hp": 35,
        "atk": 65,
        "def": 35,
        "spa": 65,
        "spd": 35,
        "spe": 65
      },
      "abilities": {"0": "Hustle", "1": "Sniper", "H": "Moody"},
      "weightkg": 12,
      "evos": ["Octillery"],
      "eggGroups": ["Water 1", "Water 2"]
    },
    "octillery": {
      "num": 224,
      "name": "Octillery",
      "types": ["Water"],
      "baseStats": {
        "hp": 75,
        "atk": 105,
        "def": 75,
        "spa": 105,
        "spd": 75,
        "spe": 45
      },
      "abilities": {"0": "Suction Cups", "1": "Sniper", "H": "Moody"},
      "weightkg": 28.5,
      "prevo": "Remoraid",
      "evoLevel": 25,
      "eggGroups": ["Water 1", "Water 2"]
    },
    "delibird": {
      "num": 225,
      "name": "Delibird",
      "types": ["Ice", "Flying"],
      "baseStats": {
        "hp": 45,
        "atk": 55,
        "def": 45,
        "spa": 65,
        "spd": 45,
        "spe": 75
      },
      "abilities": {"0": "Vital Spirit", "1": "Hustle", "H": "Insomnia"},
      "weightkg": 16,
      "eggGroups": ["Water 1", "Field"]
    },
    "mantine": {
      "num": 226,
      "name": "Mantine",
      "types": ["Water", "Flying"],
      "baseStats": {
        "hp": 85,
        "atk": 40,
        "def": 70,
        "spa": 80,
        "spd": 140,
        "spe": 70
      },
      "abilities": {"0": "Swift Swim", "1": "Water Absorb", "H": "Water Veil"},
      "weightkg": 220,
      "prevo": "Mantyke",
      "evoType": "levelExtra",
      "evoCondition": "with a Remoraid in party",
      "eggGroups": ["Water 1"],
      "canHatch": true
    },
    "skarmory": {
      "num": 227,
      "name": "Skarmory",
      "types": ["Steel", "Flying"],
      "baseStats": {
        "hp": 65,
        "atk": 80,
        "def": 140,
        "spa": 40,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Keen Eye", "1": "Sturdy", "H": "Weak Armor"},
      "weightkg": 50.5,
      "eggGroups": ["Flying"]
    },
    "houndour": {
      "num": 228,
      "name": "Houndour",
      "types": ["Dark", "Fire"],
      "baseStats": {
        "hp": 45,
        "atk": 60,
        "def": 30,
        "spa": 80,
        "spd": 50,
        "spe": 65
      },
      "abilities": {"0": "Early Bird", "1": "Flash Fire", "H": "Unnerve"},
      "weightkg": 10.8,
      "evos": ["Houndoom"],
      "eggGroups": ["Field"]
    },
    "houndoom": {
      "num": 229,
      "name": "Houndoom",
      "types": ["Dark", "Fire"],
      "baseStats": {
        "hp": 75,
        "atk": 90,
        "def": 50,
        "spa": 110,
        "spd": 80,
        "spe": 95
      },
      "abilities": {"0": "Early Bird", "1": "Flash Fire", "H": "Unnerve"},
      "weightkg": 35,
      "prevo": "Houndour",
      "evoLevel": 24,
      "eggGroups": ["Field"],
      "otherFormes": ["Houndoom-Mega"],
      "formeOrder": ["Houndoom", "Houndoom-Mega"]
    },
    "houndoommega": {
      "num": 229,
      "name": "Houndoom-Mega",
      "baseSpecies": "Houndoom",
      "forme": "Mega",
      "types": ["Dark", "Fire"],
      "baseStats": {
        "hp": 75,
        "atk": 90,
        "def": 90,
        "spa": 140,
        "spd": 90,
        "spe": 115
      },
      "abilities": {"0": "Solar Power"},
      "weightkg": 49.5,
      "eggGroups": ["Field"],
      "requiredItem": "Houndoominite"
    },
    "kingdra": {
      "num": 230,
      "name": "Kingdra",
      "types": ["Water", "Dragon"],
      "baseStats": {
        "hp": 75,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 85
      },
      "abilities": {"0": "Swift Swim", "1": "Sniper", "H": "Damp"},
      "weightkg": 152,
      "prevo": "Seadra",
      "evoType": "trade",
      "evoItem": "Dragon Scale",
      "eggGroups": ["Water 1", "Dragon"]
    },
    "phanpy": {
      "num": 231,
      "name": "Phanpy",
      "types": ["Ground"],
      "baseStats": {
        "hp": 90,
        "atk": 60,
        "def": 60,
        "spa": 40,
        "spd": 40,
        "spe": 40
      },
      "abilities": {"0": "Pickup", "H": "Sand Veil"},
      "weightkg": 33.5,
      "evos": ["Donphan"],
      "eggGroups": ["Field"]
    },
    "donphan": {
      "num": 232,
      "name": "Donphan",
      "types": ["Ground"],
      "baseStats": {
        "hp": 90,
        "atk": 120,
        "def": 120,
        "spa": 60,
        "spd": 60,
        "spe": 50
      },
      "abilities": {"0": "Sturdy", "H": "Sand Veil"},
      "weightkg": 120,
      "prevo": "Phanpy",
      "evoLevel": 25,
      "eggGroups": ["Field"]
    },
    "porygon2": {
      "num": 233,
      "name": "Porygon2",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 85,
        "atk": 80,
        "def": 90,
        "spa": 105,
        "spd": 95,
        "spe": 60
      },
      "abilities": {"0": "Trace", "1": "Download", "H": "Analytic"},
      "weightkg": 32.5,
      "prevo": "Porygon",
      "evoType": "trade",
      "evoItem": "Up-Grade",
      "evos": ["Porygon-Z"],
      "eggGroups": ["Mineral"]
    },
    "stantler": {
      "num": 234,
      "name": "Stantler",
      "types": ["Normal"],
      "baseStats": {
        "hp": 73,
        "atk": 95,
        "def": 62,
        "spa": 85,
        "spd": 65,
        "spe": 85
      },
      "abilities": {"0": "Intimidate", "1": "Frisk", "H": "Sap Sipper"},
      "weightkg": 71.2,
      "evos": ["Wyrdeer"],
      "eggGroups": ["Field"]
    },
    "smeargle": {
      "num": 235,
      "name": "Smeargle",
      "types": ["Normal"],
      "baseStats": {
        "hp": 55,
        "atk": 20,
        "def": 35,
        "spa": 20,
        "spd": 45,
        "spe": 75
      },
      "abilities": {"0": "Own Tempo", "1": "Technician", "H": "Moody"},
      "weightkg": 58,
      "eggGroups": ["Field"]
    },
    "tyrogue": {
      "num": 236,
      "name": "Tyrogue",
      "types": ["Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 35,
        "atk": 35,
        "def": 35,
        "spa": 35,
        "spd": 35,
        "spe": 35
      },
      "abilities": {"0": "Guts", "1": "Steadfast", "H": "Vital Spirit"},
      "weightkg": 21,
      "evos": ["Hitmonlee", "Hitmonchan", "Hitmontop"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "hitmontop": {
      "num": 237,
      "name": "Hitmontop",
      "types": ["Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 50,
        "atk": 95,
        "def": 95,
        "spa": 35,
        "spd": 110,
        "spe": 70
      },
      "abilities": {"0": "Intimidate", "1": "Technician", "H": "Steadfast"},
      "weightkg": 48,
      "prevo": "Tyrogue",
      "evoLevel": 20,
      "evoCondition": "with an Atk stat equal to its Def stat",
      "eggGroups": ["Human-Like"]
    },
    "smoochum": {
      "num": 238,
      "name": "Smoochum",
      "types": ["Ice", "Psychic"],
      "gender": "F",
      "baseStats": {
        "hp": 45,
        "atk": 30,
        "def": 15,
        "spa": 85,
        "spd": 65,
        "spe": 65
      },
      "abilities": {"0": "Oblivious", "1": "Forewarn", "H": "Hydration"},
      "weightkg": 6,
      "evos": ["Jynx"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "elekid": {
      "num": 239,
      "name": "Elekid",
      "types": ["Electric"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 45,
        "atk": 63,
        "def": 37,
        "spa": 65,
        "spd": 55,
        "spe": 95
      },
      "abilities": {"0": "Static", "H": "Vital Spirit"},
      "weightkg": 23.5,
      "evos": ["Electabuzz"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "magby": {
      "num": 240,
      "name": "Magby",
      "types": ["Fire"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 45,
        "atk": 75,
        "def": 37,
        "spa": 70,
        "spd": 55,
        "spe": 83
      },
      "abilities": {"0": "Flame Body", "H": "Vital Spirit"},
      "weightkg": 21.4,
      "evos": ["Magmar"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "miltank": {
      "num": 241,
      "name": "Miltank",
      "types": ["Normal"],
      "gender": "F",
      "baseStats": {
        "hp": 95,
        "atk": 80,
        "def": 105,
        "spa": 40,
        "spd": 70,
        "spe": 100
      },
      "abilities": {"0": "Thick Fat", "1": "Scrappy", "H": "Sap Sipper"},
      "weightkg": 75.5,
      "eggGroups": ["Field"]
    },
    "blissey": {
      "num": 242,
      "name": "Blissey",
      "types": ["Normal"],
      "gender": "F",
      "baseStats": {
        "hp": 255,
        "atk": 10,
        "def": 10,
        "spa": 75,
        "spd": 135,
        "spe": 55
      },
      "abilities": {"0": "Natural Cure", "1": "Serene Grace", "H": "Healer"},
      "weightkg": 46.8,
      "prevo": "Chansey",
      "evoType": "levelFriendship",
      "eggGroups": ["Fairy"]
    },
    "raikou": {
      "num": 243,
      "name": "Raikou",
      "types": ["Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 85,
        "def": 75,
        "spa": 115,
        "spd": 100,
        "spe": 115
      },
      "abilities": {"0": "Pressure", "H": "Inner Focus"},
      "weightkg": 178,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "entei": {
      "num": 244,
      "name": "Entei",
      "types": ["Fire"],
      "gender": "N",
      "baseStats": {
        "hp": 115,
        "atk": 115,
        "def": 85,
        "spa": 90,
        "spd": 75,
        "spe": 100
      },
      "abilities": {"0": "Pressure", "H": "Inner Focus"},
      "weightkg": 198,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "suicune": {
      "num": 245,
      "name": "Suicune",
      "types": ["Water"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 75,
        "def": 115,
        "spa": 90,
        "spd": 115,
        "spe": 85
      },
      "abilities": {"0": "Pressure", "H": "Inner Focus"},
      "weightkg": 187,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "larvitar": {
      "num": 246,
      "name": "Larvitar",
      "types": ["Rock", "Ground"],
      "baseStats": {
        "hp": 50,
        "atk": 64,
        "def": 50,
        "spa": 45,
        "spd": 50,
        "spe": 41
      },
      "abilities": {"0": "Guts", "H": "Sand Veil"},
      "weightkg": 72,
      "evos": ["Pupitar"],
      "eggGroups": ["Monster"]
    },
    "pupitar": {
      "num": 247,
      "name": "Pupitar",
      "types": ["Rock", "Ground"],
      "baseStats": {
        "hp": 70,
        "atk": 84,
        "def": 70,
        "spa": 65,
        "spd": 70,
        "spe": 51
      },
      "abilities": {"0": "Shed Skin"},
      "weightkg": 152,
      "prevo": "Larvitar",
      "evoLevel": 30,
      "evos": ["Tyranitar"],
      "eggGroups": ["Monster"]
    },
    "tyranitar": {
      "num": 248,
      "name": "Tyranitar",
      "types": ["Rock", "Dark"],
      "baseStats": {
        "hp": 100,
        "atk": 134,
        "def": 110,
        "spa": 95,
        "spd": 100,
        "spe": 61
      },
      "abilities": {"0": "Sand Stream", "H": "Unnerve"},
      "weightkg": 202,
      "prevo": "Pupitar",
      "evoLevel": 55,
      "eggGroups": ["Monster"],
      "otherFormes": ["Tyranitar-Mega"],
      "formeOrder": ["Tyranitar", "Tyranitar-Mega"]
    },
    "tyranitarmega": {
      "num": 248,
      "name": "Tyranitar-Mega",
      "baseSpecies": "Tyranitar",
      "forme": "Mega",
      "types": ["Rock", "Dark"],
      "baseStats": {
        "hp": 100,
        "atk": 164,
        "def": 150,
        "spa": 95,
        "spd": 120,
        "spe": 71
      },
      "abilities": {"0": "Sand Stream"},
      "weightkg": 255,
      "eggGroups": ["Monster"],
      "requiredItem": "Tyranitarite"
    },
    "lugia": {
      "num": 249,
      "name": "Lugia",
      "types": ["Psychic", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 106,
        "atk": 90,
        "def": 130,
        "spa": 90,
        "spd": 154,
        "spe": 110
      },
      "abilities": {"0": "Pressure", "H": "Multiscale"},
      "weightkg": 216,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "hooh": {
      "num": 250,
      "name": "Ho-Oh",
      "types": ["Fire", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 106,
        "atk": 130,
        "def": 90,
        "spa": 110,
        "spd": 154,
        "spe": 90
      },
      "abilities": {"0": "Pressure", "H": "Regenerator"},
      "weightkg": 199,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "celebi": {
      "num": 251,
      "name": "Celebi",
      "types": ["Psychic", "Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Natural Cure"},
      "weightkg": 5,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"]
    },
    "treecko": {
      "num": 252,
      "name": "Treecko",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 35,
        "spa": 65,
        "spd": 55,
        "spe": 70
      },
      "abilities": {"0": "Overgrow", "H": "Unburden"},
      "weightkg": 5,
      "evos": ["Grovyle"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "grovyle": {
      "num": 253,
      "name": "Grovyle",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 45,
        "spa": 85,
        "spd": 65,
        "spe": 95
      },
      "abilities": {"0": "Overgrow", "H": "Unburden"},
      "weightkg": 21.6,
      "prevo": "Treecko",
      "evoLevel": 16,
      "evos": ["Sceptile"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "sceptile": {
      "num": 254,
      "name": "Sceptile",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 65,
        "spa": 105,
        "spd": 85,
        "spe": 120
      },
      "abilities": {"0": "Overgrow", "H": "Unburden"},
      "weightkg": 52.2,
      "prevo": "Grovyle",
      "evoLevel": 36,
      "eggGroups": ["Monster", "Dragon"],
      "otherFormes": ["Sceptile-Mega"],
      "formeOrder": ["Sceptile", "Sceptile-Mega"]
    },
    "sceptilemega": {
      "num": 254,
      "name": "Sceptile-Mega",
      "baseSpecies": "Sceptile",
      "forme": "Mega",
      "types": ["Grass", "Dragon"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 110,
        "def": 75,
        "spa": 145,
        "spd": 85,
        "spe": 145
      },
      "abilities": {"0": "Lightning Rod"},
      "weightkg": 55.2,
      "eggGroups": ["Monster", "Dragon"],
      "requiredItem": "Sceptilite"
    },
    "torchic": {
      "num": 255,
      "name": "Torchic",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 45,
        "atk": 60,
        "def": 40,
        "spa": 70,
        "spd": 50,
        "spe": 45
      },
      "abilities": {"0": "Blaze", "H": "Speed Boost"},
      "weightkg": 2.5,
      "evos": ["Combusken"],
      "eggGroups": ["Field"]
    },
    "combusken": {
      "num": 256,
      "name": "Combusken",
      "types": ["Fire", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 60,
        "spa": 85,
        "spd": 60,
        "spe": 55
      },
      "abilities": {"0": "Blaze", "H": "Speed Boost"},
      "weightkg": 19.5,
      "prevo": "Torchic",
      "evoLevel": 16,
      "evos": ["Blaziken"],
      "eggGroups": ["Field"]
    },
    "blaziken": {
      "num": 257,
      "name": "Blaziken",
      "types": ["Fire", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 70,
        "spa": 110,
        "spd": 70,
        "spe": 80
      },
      "abilities": {"0": "Blaze", "H": "Speed Boost"},
      "weightkg": 52,
      "prevo": "Combusken",
      "evoLevel": 36,
      "eggGroups": ["Field"],
      "otherFormes": ["Blaziken-Mega"],
      "formeOrder": ["Blaziken", "Blaziken-Mega"]
    },
    "blazikenmega": {
      "num": 257,
      "name": "Blaziken-Mega",
      "baseSpecies": "Blaziken",
      "forme": "Mega",
      "types": ["Fire", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 160,
        "def": 80,
        "spa": 130,
        "spd": 80,
        "spe": 100
      },
      "abilities": {"0": "Speed Boost"},
      "weightkg": 52,
      "eggGroups": ["Field"],
      "requiredItem": "Blazikenite"
    },
    "mudkip": {
      "num": 258,
      "name": "Mudkip",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 70,
        "def": 50,
        "spa": 50,
        "spd": 50,
        "spe": 40
      },
      "abilities": {"0": "Torrent", "H": "Damp"},
      "weightkg": 7.6,
      "evos": ["Marshtomp"],
      "eggGroups": ["Monster", "Water 1"]
    },
    "marshtomp": {
      "num": 259,
      "name": "Marshtomp",
      "types": ["Water", "Ground"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 70,
        "spa": 60,
        "spd": 70,
        "spe": 50
      },
      "abilities": {"0": "Torrent", "H": "Damp"},
      "weightkg": 28,
      "prevo": "Mudkip",
      "evoLevel": 16,
      "evos": ["Swampert"],
      "eggGroups": ["Monster", "Water 1"]
    },
    "swampert": {
      "num": 260,
      "name": "Swampert",
      "types": ["Water", "Ground"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 100,
        "atk": 110,
        "def": 90,
        "spa": 85,
        "spd": 90,
        "spe": 60
      },
      "abilities": {"0": "Torrent", "H": "Damp"},
      "weightkg": 81.9,
      "prevo": "Marshtomp",
      "evoLevel": 36,
      "eggGroups": ["Monster", "Water 1"],
      "otherFormes": ["Swampert-Mega"],
      "formeOrder": ["Swampert", "Swampert-Mega"]
    },
    "swampertmega": {
      "num": 260,
      "name": "Swampert-Mega",
      "baseSpecies": "Swampert",
      "forme": "Mega",
      "types": ["Water", "Ground"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 100,
        "atk": 150,
        "def": 110,
        "spa": 95,
        "spd": 110,
        "spe": 70
      },
      "abilities": {"0": "Swift Swim"},
      "weightkg": 102,
      "eggGroups": ["Monster", "Water 1"],
      "requiredItem": "Swampertite"
    },
    "poochyena": {
      "num": 261,
      "name": "Poochyena",
      "types": ["Dark"],
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 35,
        "spa": 30,
        "spd": 30,
        "spe": 35
      },
      "abilities": {"0": "Run Away", "1": "Quick Feet", "H": "Rattled"},
      "weightkg": 13.6,
      "evos": ["Mightyena"],
      "eggGroups": ["Field"]
    },
    "mightyena": {
      "num": 262,
      "name": "Mightyena",
      "types": ["Dark"],
      "baseStats": {
        "hp": 70,
        "atk": 90,
        "def": 70,
        "spa": 60,
        "spd": 60,
        "spe": 70
      },
      "abilities": {"0": "Intimidate", "1": "Quick Feet", "H": "Moxie"},
      "weightkg": 37,
      "prevo": "Poochyena",
      "evoLevel": 18,
      "eggGroups": ["Field"]
    },
    "zigzagoon": {
      "num": 263,
      "name": "Zigzagoon",
      "types": ["Normal"],
      "baseStats": {
        "hp": 38,
        "atk": 30,
        "def": 41,
        "spa": 30,
        "spd": 41,
        "spe": 60
      },
      "abilities": {"0": "Pickup", "1": "Gluttony", "H": "Quick Feet"},
      "weightkg": 17.5,
      "evos": ["Linoone"],
      "eggGroups": ["Field"],
      "otherFormes": ["Zigzagoon-Galar"],
      "formeOrder": ["Zigzagoon", "Zigzagoon-Galar"]
    },
    "zigzagoongalar": {
      "num": 263,
      "name": "Zigzagoon-Galar",
      "baseSpecies": "Zigzagoon",
      "forme": "Galar",
      "types": ["Dark", "Normal"],
      "baseStats": {
        "hp": 38,
        "atk": 30,
        "def": 41,
        "spa": 30,
        "spd": 41,
        "spe": 60
      },
      "abilities": {"0": "Pickup", "1": "Gluttony", "H": "Quick Feet"},
      "weightkg": 17.5,
      "evos": ["Linoone-Galar"],
      "eggGroups": ["Field"]
    },
    "linoone": {
      "num": 264,
      "name": "Linoone",
      "types": ["Normal"],
      "baseStats": {
        "hp": 78,
        "atk": 70,
        "def": 61,
        "spa": 50,
        "spd": 61,
        "spe": 100
      },
      "abilities": {"0": "Pickup", "1": "Gluttony", "H": "Quick Feet"},
      "weightkg": 32.5,
      "prevo": "Zigzagoon",
      "evoLevel": 20,
      "eggGroups": ["Field"],
      "otherFormes": ["Linoone-Galar"],
      "formeOrder": ["Linoone", "Linoone-Galar"]
    },
    "linoonegalar": {
      "num": 264,
      "name": "Linoone-Galar",
      "baseSpecies": "Linoone",
      "forme": "Galar",
      "types": ["Dark", "Normal"],
      "baseStats": {
        "hp": 78,
        "atk": 70,
        "def": 61,
        "spa": 50,
        "spd": 61,
        "spe": 100
      },
      "abilities": {"0": "Pickup", "1": "Gluttony", "H": "Quick Feet"},
      "weightkg": 32.5,
      "prevo": "Zigzagoon-Galar",
      "evoLevel": 20,
      "evos": ["Obstagoon"],
      "eggGroups": ["Field"]
    },
    "wurmple": {
      "num": 265,
      "name": "Wurmple",
      "types": ["Bug"],
      "baseStats": {
        "hp": 45,
        "atk": 45,
        "def": 35,
        "spa": 20,
        "spd": 30,
        "spe": 20
      },
      "abilities": {"0": "Shield Dust", "H": "Run Away"},
      "weightkg": 3.6,
      "evos": ["Silcoon", "Cascoon"],
      "eggGroups": ["Bug"]
    },
    "silcoon": {
      "num": 266,
      "name": "Silcoon",
      "types": ["Bug"],
      "baseStats": {
        "hp": 50,
        "atk": 35,
        "def": 55,
        "spa": 25,
        "spd": 25,
        "spe": 15
      },
      "abilities": {"0": "Shed Skin"},
      "weightkg": 10,
      "prevo": "Wurmple",
      "evoLevel": 7,
      "evos": ["Beautifly"],
      "eggGroups": ["Bug"]
    },
    "beautifly": {
      "num": 267,
      "name": "Beautifly",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 60,
        "atk": 70,
        "def": 50,
        "spa": 100,
        "spd": 50,
        "spe": 65
      },
      "abilities": {"0": "Swarm", "H": "Rivalry"},
      "weightkg": 28.4,
      "prevo": "Silcoon",
      "evoLevel": 10,
      "eggGroups": ["Bug"]
    },
    "cascoon": {
      "num": 268,
      "name": "Cascoon",
      "types": ["Bug"],
      "baseStats": {
        "hp": 50,
        "atk": 35,
        "def": 55,
        "spa": 25,
        "spd": 25,
        "spe": 15
      },
      "abilities": {"0": "Shed Skin"},
      "weightkg": 11.5,
      "prevo": "Wurmple",
      "evoLevel": 7,
      "evos": ["Dustox"],
      "eggGroups": ["Bug"]
    },
    "dustox": {
      "num": 269,
      "name": "Dustox",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 70,
        "spa": 50,
        "spd": 90,
        "spe": 65
      },
      "abilities": {"0": "Shield Dust", "H": "Compound Eyes"},
      "weightkg": 31.6,
      "prevo": "Cascoon",
      "evoLevel": 10,
      "eggGroups": ["Bug"]
    },
    "lotad": {
      "num": 270,
      "name": "Lotad",
      "types": ["Water", "Grass"],
      "baseStats": {
        "hp": 40,
        "atk": 30,
        "def": 30,
        "spa": 40,
        "spd": 50,
        "spe": 30
      },
      "abilities": {"0": "Swift Swim", "1": "Rain Dish", "H": "Own Tempo"},
      "weightkg": 2.6,
      "evos": ["Lombre"],
      "eggGroups": ["Water 1", "Grass"]
    },
    "lombre": {
      "num": 271,
      "name": "Lombre",
      "types": ["Water", "Grass"],
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 50,
        "spa": 60,
        "spd": 70,
        "spe": 50
      },
      "abilities": {"0": "Swift Swim", "1": "Rain Dish", "H": "Own Tempo"},
      "weightkg": 32.5,
      "prevo": "Lotad",
      "evoLevel": 14,
      "evos": ["Ludicolo"],
      "eggGroups": ["Water 1", "Grass"]
    },
    "ludicolo": {
      "num": 272,
      "name": "Ludicolo",
      "types": ["Water", "Grass"],
      "baseStats": {
        "hp": 80,
        "atk": 70,
        "def": 70,
        "spa": 90,
        "spd": 100,
        "spe": 70
      },
      "abilities": {"0": "Swift Swim", "1": "Rain Dish", "H": "Own Tempo"},
      "weightkg": 55,
      "prevo": "Lombre",
      "evoType": "useItem",
      "evoItem": "Water Stone",
      "eggGroups": ["Water 1", "Grass"]
    },
    "seedot": {
      "num": 273,
      "name": "Seedot",
      "types": ["Grass"],
      "baseStats": {
        "hp": 40,
        "atk": 40,
        "def": 50,
        "spa": 30,
        "spd": 30,
        "spe": 30
      },
      "abilities": {"0": "Chlorophyll", "1": "Early Bird", "H": "Pickpocket"},
      "weightkg": 4,
      "evos": ["Nuzleaf"],
      "eggGroups": ["Field", "Grass"]
    },
    "nuzleaf": {
      "num": 274,
      "name": "Nuzleaf",
      "types": ["Grass", "Dark"],
      "baseStats": {
        "hp": 70,
        "atk": 70,
        "def": 40,
        "spa": 60,
        "spd": 40,
        "spe": 60
      },
      "abilities": {"0": "Chlorophyll", "1": "Early Bird", "H": "Pickpocket"},
      "weightkg": 28,
      "prevo": "Seedot",
      "evoLevel": 14,
      "evos": ["Shiftry"],
      "eggGroups": ["Field", "Grass"]
    },
    "shiftry": {
      "num": 275,
      "name": "Shiftry",
      "types": ["Grass", "Dark"],
      "baseStats": {
        "hp": 90,
        "atk": 100,
        "def": 60,
        "spa": 90,
        "spd": 60,
        "spe": 80
      },
      "abilities": {"0": "Chlorophyll", "1": "Wind Rider", "H": "Pickpocket"},
      "weightkg": 59.6,
      "prevo": "Nuzleaf",
      "evoType": "useItem",
      "evoItem": "Leaf Stone",
      "eggGroups": ["Field", "Grass"]
    },
    "taillow": {
      "num": 276,
      "name": "Taillow",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 40,
        "atk": 55,
        "def": 30,
        "spa": 30,
        "spd": 30,
        "spe": 85
      },
      "abilities": {"0": "Guts", "H": "Scrappy"},
      "weightkg": 2.3,
      "evos": ["Swellow"],
      "eggGroups": ["Flying"]
    },
    "swellow": {
      "num": 277,
      "name": "Swellow",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 60,
        "spa": 75,
        "spd": 50,
        "spe": 125
      },
      "abilities": {"0": "Guts", "H": "Scrappy"},
      "weightkg": 19.8,
      "prevo": "Taillow",
      "evoLevel": 22,
      "eggGroups": ["Flying"]
    },
    "wingull": {
      "num": 278,
      "name": "Wingull",
      "types": ["Water", "Flying"],
      "baseStats": {
        "hp": 40,
        "atk": 30,
        "def": 30,
        "spa": 55,
        "spd": 30,
        "spe": 85
      },
      "abilities": {"0": "Keen Eye", "1": "Hydration", "H": "Rain Dish"},
      "weightkg": 9.5,
      "evos": ["Pelipper"],
      "eggGroups": ["Water 1", "Flying"]
    },
    "pelipper": {
      "num": 279,
      "name": "Pelipper",
      "types": ["Water", "Flying"],
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 100,
        "spa": 95,
        "spd": 70,
        "spe": 65
      },
      "abilities": {"0": "Keen Eye", "1": "Drizzle", "H": "Rain Dish"},
      "weightkg": 28,
      "prevo": "Wingull",
      "evoLevel": 25,
      "eggGroups": ["Water 1", "Flying"]
    },
    "ralts": {
      "num": 280,
      "name": "Ralts",
      "types": ["Psychic", "Fairy"],
      "baseStats": {
        "hp": 28,
        "atk": 25,
        "def": 25,
        "spa": 45,
        "spd": 35,
        "spe": 40
      },
      "abilities": {"0": "Synchronize", "1": "Trace", "H": "Telepathy"},
      "weightkg": 6.6,
      "evos": ["Kirlia"],
      "eggGroups": ["Human-Like", "Amorphous"]
    },
    "kirlia": {
      "num": 281,
      "name": "Kirlia",
      "types": ["Psychic", "Fairy"],
      "baseStats": {
        "hp": 38,
        "atk": 35,
        "def": 35,
        "spa": 65,
        "spd": 55,
        "spe": 50
      },
      "abilities": {"0": "Synchronize", "1": "Trace", "H": "Telepathy"},
      "weightkg": 20.2,
      "prevo": "Ralts",
      "evoLevel": 20,
      "evos": ["Gardevoir", "Gallade"],
      "eggGroups": ["Human-Like", "Amorphous"]
    },
    "gardevoir": {
      "num": 282,
      "name": "Gardevoir",
      "types": ["Psychic", "Fairy"],
      "baseStats": {
        "hp": 68,
        "atk": 65,
        "def": 65,
        "spa": 125,
        "spd": 115,
        "spe": 80
      },
      "abilities": {"0": "Synchronize", "1": "Trace", "H": "Telepathy"},
      "weightkg": 48.4,
      "prevo": "Kirlia",
      "evoLevel": 30,
      "eggGroups": ["Human-Like", "Amorphous"],
      "otherFormes": ["Gardevoir-Mega"],
      "formeOrder": ["Gardevoir", "Gardevoir-Mega"]
    },
    "gardevoirmega": {
      "num": 282,
      "name": "Gardevoir-Mega",
      "baseSpecies": "Gardevoir",
      "forme": "Mega",
      "types": ["Psychic", "Fairy"],
      "baseStats": {
        "hp": 68,
        "atk": 85,
        "def": 65,
        "spa": 165,
        "spd": 135,
        "spe": 100
      },
      "abilities": {"0": "Pixilate"},
      "weightkg": 48.4,
      "eggGroups": ["Amorphous"],
      "requiredItem": "Gardevoirite"
    },
    "surskit": {
      "num": 283,
      "name": "Surskit",
      "types": ["Bug", "Water"],
      "baseStats": {
        "hp": 40,
        "atk": 30,
        "def": 32,
        "spa": 50,
        "spd": 52,
        "spe": 65
      },
      "abilities": {"0": "Swift Swim", "H": "Rain Dish"},
      "weightkg": 1.7,
      "evos": ["Masquerain"],
      "eggGroups": ["Water 1", "Bug"]
    },
    "masquerain": {
      "num": 284,
      "name": "Masquerain",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 70,
        "atk": 60,
        "def": 62,
        "spa": 100,
        "spd": 82,
        "spe": 80
      },
      "abilities": {"0": "Intimidate", "H": "Unnerve"},
      "weightkg": 3.6,
      "prevo": "Surskit",
      "evoLevel": 22,
      "eggGroups": ["Water 1", "Bug"]
    },
    "shroomish": {
      "num": 285,
      "name": "Shroomish",
      "types": ["Grass"],
      "baseStats": {
        "hp": 60,
        "atk": 40,
        "def": 60,
        "spa": 40,
        "spd": 60,
        "spe": 35
      },
      "abilities": {"0": "Effect Spore", "1": "Poison Heal", "H": "Quick Feet"},
      "weightkg": 4.5,
      "evos": ["Breloom"],
      "eggGroups": ["Fairy", "Grass"]
    },
    "breloom": {
      "num": 286,
      "name": "Breloom",
      "types": ["Grass", "Fighting"],
      "baseStats": {
        "hp": 60,
        "atk": 130,
        "def": 80,
        "spa": 60,
        "spd": 60,
        "spe": 70
      },
      "abilities": {"0": "Effect Spore", "1": "Poison Heal", "H": "Technician"},
      "weightkg": 39.2,
      "prevo": "Shroomish",
      "evoLevel": 23,
      "eggGroups": ["Fairy", "Grass"]
    },
    "slakoth": {
      "num": 287,
      "name": "Slakoth",
      "types": ["Normal"],
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 60,
        "spa": 35,
        "spd": 35,
        "spe": 30
      },
      "abilities": {"0": "Truant"},
      "weightkg": 24,
      "evos": ["Vigoroth"],
      "eggGroups": ["Field"]
    },
    "vigoroth": {
      "num": 288,
      "name": "Vigoroth",
      "types": ["Normal"],
      "baseStats": {
        "hp": 80,
        "atk": 80,
        "def": 80,
        "spa": 55,
        "spd": 55,
        "spe": 90
      },
      "abilities": {"0": "Vital Spirit"},
      "weightkg": 46.5,
      "prevo": "Slakoth",
      "evoLevel": 18,
      "evos": ["Slaking"],
      "eggGroups": ["Field"]
    },
    "slaking": {
      "num": 289,
      "name": "Slaking",
      "types": ["Normal"],
      "baseStats": {
        "hp": 150,
        "atk": 160,
        "def": 100,
        "spa": 95,
        "spd": 65,
        "spe": 100
      },
      "abilities": {"0": "Truant"},
      "weightkg": 130.5,
      "prevo": "Vigoroth",
      "evoLevel": 36,
      "eggGroups": ["Field"]
    },
    "nincada": {
      "num": 290,
      "name": "Nincada",
      "types": ["Bug", "Ground"],
      "baseStats": {
        "hp": 31,
        "atk": 45,
        "def": 90,
        "spa": 30,
        "spd": 30,
        "spe": 40
      },
      "abilities": {"0": "Compound Eyes", "H": "Run Away"},
      "weightkg": 5.5,
      "evos": ["Ninjask", "Shedinja"],
      "eggGroups": ["Bug"]
    },
    "ninjask": {
      "num": 291,
      "name": "Ninjask",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 61,
        "atk": 90,
        "def": 45,
        "spa": 50,
        "spd": 50,
        "spe": 160
      },
      "abilities": {"0": "Speed Boost", "H": "Infiltrator"},
      "weightkg": 12,
      "prevo": "Nincada",
      "evoLevel": 20,
      "eggGroups": ["Bug"]
    },
    "shedinja": {
      "num": 292,
      "name": "Shedinja",
      "types": ["Bug", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 1,
        "atk": 90,
        "def": 45,
        "spa": 30,
        "spd": 30,
        "spe": 40
      },
      "maxHP": 1,
      "abilities": {"0": "Wonder Guard"},
      "weightkg": 1.2,
      "prevo": "Nincada",
      "evoLevel": 20,
      "eggGroups": ["Mineral"]
    },
    "whismur": {
      "num": 293,
      "name": "Whismur",
      "types": ["Normal"],
      "baseStats": {
        "hp": 64,
        "atk": 51,
        "def": 23,
        "spa": 51,
        "spd": 23,
        "spe": 28
      },
      "abilities": {"0": "Soundproof", "H": "Rattled"},
      "weightkg": 16.3,
      "evos": ["Loudred"],
      "eggGroups": ["Monster", "Field"]
    },
    "loudred": {
      "num": 294,
      "name": "Loudred",
      "types": ["Normal"],
      "baseStats": {
        "hp": 84,
        "atk": 71,
        "def": 43,
        "spa": 71,
        "spd": 43,
        "spe": 48
      },
      "abilities": {"0": "Soundproof", "H": "Scrappy"},
      "weightkg": 40.5,
      "prevo": "Whismur",
      "evoLevel": 20,
      "evos": ["Exploud"],
      "eggGroups": ["Monster", "Field"]
    },
    "exploud": {
      "num": 295,
      "name": "Exploud",
      "types": ["Normal"],
      "baseStats": {
        "hp": 104,
        "atk": 91,
        "def": 63,
        "spa": 91,
        "spd": 73,
        "spe": 68
      },
      "abilities": {"0": "Soundproof", "H": "Scrappy"},
      "weightkg": 84,
      "prevo": "Loudred",
      "evoLevel": 40,
      "eggGroups": ["Monster", "Field"]
    },
    "makuhita": {
      "num": 296,
      "name": "Makuhita",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 72,
        "atk": 60,
        "def": 30,
        "spa": 20,
        "spd": 30,
        "spe": 25
      },
      "abilities": {"0": "Thick Fat", "1": "Guts", "H": "Sheer Force"},
      "weightkg": 86.4,
      "evos": ["Hariyama"],
      "eggGroups": ["Human-Like"]
    },
    "hariyama": {
      "num": 297,
      "name": "Hariyama",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 144,
        "atk": 120,
        "def": 60,
        "spa": 40,
        "spd": 60,
        "spe": 50
      },
      "abilities": {"0": "Thick Fat", "1": "Guts", "H": "Sheer Force"},
      "weightkg": 253.8,
      "prevo": "Makuhita",
      "evoLevel": 24,
      "eggGroups": ["Human-Like"]
    },
    "azurill": {
      "num": 298,
      "name": "Azurill",
      "types": ["Normal", "Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 50,
        "atk": 20,
        "def": 40,
        "spa": 20,
        "spd": 40,
        "spe": 20
      },
      "abilities": {"0": "Thick Fat", "1": "Huge Power", "H": "Sap Sipper"},
      "weightkg": 2,
      "evos": ["Marill"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "nosepass": {
      "num": 299,
      "name": "Nosepass",
      "types": ["Rock"],
      "baseStats": {
        "hp": 30,
        "atk": 45,
        "def": 135,
        "spa": 45,
        "spd": 90,
        "spe": 30
      },
      "abilities": {"0": "Sturdy", "1": "Magnet Pull", "H": "Sand Force"},
      "weightkg": 97,
      "evos": ["Probopass"],
      "eggGroups": ["Mineral"]
    },
    "skitty": {
      "num": 300,
      "name": "Skitty",
      "types": ["Normal"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 50,
        "atk": 45,
        "def": 45,
        "spa": 35,
        "spd": 35,
        "spe": 50
      },
      "abilities": {"0": "Cute Charm", "1": "Normalize", "H": "Wonder Skin"},
      "weightkg": 11,
      "evos": ["Delcatty"],
      "eggGroups": ["Field", "Fairy"]
    },
    "delcatty": {
      "num": 301,
      "name": "Delcatty",
      "types": ["Normal"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 70,
        "atk": 65,
        "def": 65,
        "spa": 55,
        "spd": 55,
        "spe": 90
      },
      "abilities": {"0": "Cute Charm", "1": "Normalize", "H": "Wonder Skin"},
      "weightkg": 32.6,
      "prevo": "Skitty",
      "evoType": "useItem",
      "evoItem": "Moon Stone",
      "eggGroups": ["Field", "Fairy"]
    },
    "sableye": {
      "num": 302,
      "name": "Sableye",
      "types": ["Dark", "Ghost"],
      "baseStats": {
        "hp": 50,
        "atk": 75,
        "def": 75,
        "spa": 65,
        "spd": 65,
        "spe": 50
      },
      "abilities": {"0": "Keen Eye", "1": "Stall", "H": "Prankster"},
      "weightkg": 11,
      "eggGroups": ["Human-Like"],
      "otherFormes": ["Sableye-Mega"],
      "formeOrder": ["Sableye", "Sableye-Mega"]
    },
    "sableyemega": {
      "num": 302,
      "name": "Sableye-Mega",
      "baseSpecies": "Sableye",
      "forme": "Mega",
      "types": ["Dark", "Ghost"],
      "baseStats": {
        "hp": 50,
        "atk": 85,
        "def": 125,
        "spa": 85,
        "spd": 115,
        "spe": 20
      },
      "abilities": {"0": "Magic Bounce"},
      "weightkg": 161,
      "eggGroups": ["Human-Like"],
      "requiredItem": "Sablenite"
    },
    "mawile": {
      "num": 303,
      "name": "Mawile",
      "types": ["Steel", "Fairy"],
      "baseStats": {
        "hp": 50,
        "atk": 85,
        "def": 85,
        "spa": 55,
        "spd": 55,
        "spe": 50
      },
      "abilities": {"0": "Hyper Cutter", "1": "Intimidate", "H": "Sheer Force"},
      "weightkg": 11.5,
      "eggGroups": ["Field", "Fairy"],
      "otherFormes": ["Mawile-Mega"],
      "formeOrder": ["Mawile", "Mawile-Mega"]
    },
    "mawilemega": {
      "num": 303,
      "name": "Mawile-Mega",
      "baseSpecies": "Mawile",
      "forme": "Mega",
      "types": ["Steel", "Fairy"],
      "baseStats": {
        "hp": 50,
        "atk": 105,
        "def": 125,
        "spa": 55,
        "spd": 95,
        "spe": 50
      },
      "abilities": {"0": "Huge Power"},
      "weightkg": 23.5,
      "eggGroups": ["Field", "Fairy"],
      "requiredItem": "Mawilite"
    },
    "aron": {
      "num": 304,
      "name": "Aron",
      "types": ["Steel", "Rock"],
      "baseStats": {
        "hp": 50,
        "atk": 70,
        "def": 100,
        "spa": 40,
        "spd": 40,
        "spe": 30
      },
      "abilities": {"0": "Sturdy", "1": "Rock Head", "H": "Heavy Metal"},
      "weightkg": 60,
      "evos": ["Lairon"],
      "eggGroups": ["Monster"]
    },
    "lairon": {
      "num": 305,
      "name": "Lairon",
      "types": ["Steel", "Rock"],
      "baseStats": {
        "hp": 60,
        "atk": 90,
        "def": 140,
        "spa": 50,
        "spd": 50,
        "spe": 40
      },
      "abilities": {"0": "Sturdy", "1": "Rock Head", "H": "Heavy Metal"},
      "weightkg": 120,
      "prevo": "Aron",
      "evoLevel": 32,
      "evos": ["Aggron"],
      "eggGroups": ["Monster"]
    },
    "aggron": {
      "num": 306,
      "name": "Aggron",
      "types": ["Steel", "Rock"],
      "baseStats": {
        "hp": 70,
        "atk": 110,
        "def": 180,
        "spa": 60,
        "spd": 60,
        "spe": 50
      },
      "abilities": {"0": "Sturdy", "1": "Rock Head", "H": "Heavy Metal"},
      "weightkg": 360,
      "prevo": "Lairon",
      "evoLevel": 42,
      "eggGroups": ["Monster"],
      "otherFormes": ["Aggron-Mega"],
      "formeOrder": ["Aggron", "Aggron-Mega"]
    },
    "aggronmega": {
      "num": 306,
      "name": "Aggron-Mega",
      "baseSpecies": "Aggron",
      "forme": "Mega",
      "types": ["Steel"],
      "baseStats": {
        "hp": 70,
        "atk": 140,
        "def": 230,
        "spa": 60,
        "spd": 80,
        "spe": 50
      },
      "abilities": {"0": "Filter"},
      "weightkg": 395,
      "eggGroups": ["Monster"],
      "requiredItem": "Aggronite"
    },
    "meditite": {
      "num": 307,
      "name": "Meditite",
      "types": ["Fighting", "Psychic"],
      "baseStats": {
        "hp": 30,
        "atk": 40,
        "def": 55,
        "spa": 40,
        "spd": 55,
        "spe": 60
      },
      "abilities": {"0": "Pure Power", "H": "Telepathy"},
      "weightkg": 11.2,
      "evos": ["Medicham"],
      "eggGroups": ["Human-Like"]
    },
    "medicham": {
      "num": 308,
      "name": "Medicham",
      "types": ["Fighting", "Psychic"],
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 75,
        "spa": 60,
        "spd": 75,
        "spe": 80
      },
      "abilities": {"0": "Pure Power", "H": "Telepathy"},
      "weightkg": 31.5,
      "prevo": "Meditite",
      "evoLevel": 37,
      "eggGroups": ["Human-Like"],
      "otherFormes": ["Medicham-Mega"],
      "formeOrder": ["Medicham", "Medicham-Mega"]
    },
    "medichammega": {
      "num": 308,
      "name": "Medicham-Mega",
      "baseSpecies": "Medicham",
      "forme": "Mega",
      "types": ["Fighting", "Psychic"],
      "baseStats": {
        "hp": 60,
        "atk": 100,
        "def": 85,
        "spa": 80,
        "spd": 85,
        "spe": 100
      },
      "abilities": {"0": "Pure Power"},
      "weightkg": 31.5,
      "eggGroups": ["Human-Like"],
      "requiredItem": "Medichamite"
    },
    "electrike": {
      "num": 309,
      "name": "Electrike",
      "types": ["Electric"],
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 40,
        "spa": 65,
        "spd": 40,
        "spe": 65
      },
      "abilities": {"0": "Static", "1": "Lightning Rod", "H": "Minus"},
      "weightkg": 15.2,
      "evos": ["Manectric"],
      "eggGroups": ["Field"]
    },
    "manectric": {
      "num": 310,
      "name": "Manectric",
      "types": ["Electric"],
      "baseStats": {
        "hp": 70,
        "atk": 75,
        "def": 60,
        "spa": 105,
        "spd": 60,
        "spe": 105
      },
      "abilities": {"0": "Static", "1": "Lightning Rod", "H": "Minus"},
      "weightkg": 40.2,
      "prevo": "Electrike",
      "evoLevel": 26,
      "eggGroups": ["Field"],
      "otherFormes": ["Manectric-Mega"],
      "formeOrder": ["Manectric", "Manectric-Mega"]
    },
    "manectricmega": {
      "num": 310,
      "name": "Manectric-Mega",
      "baseSpecies": "Manectric",
      "forme": "Mega",
      "types": ["Electric"],
      "baseStats": {
        "hp": 70,
        "atk": 75,
        "def": 80,
        "spa": 135,
        "spd": 80,
        "spe": 135
      },
      "abilities": {"0": "Intimidate"},
      "weightkg": 44,
      "eggGroups": ["Field"],
      "requiredItem": "Manectite"
    },
    "plusle": {
      "num": 311,
      "name": "Plusle",
      "types": ["Electric"],
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 40,
        "spa": 85,
        "spd": 75,
        "spe": 95
      },
      "abilities": {"0": "Plus", "H": "Lightning Rod"},
      "weightkg": 4.2,
      "eggGroups": ["Fairy"]
    },
    "minun": {
      "num": 312,
      "name": "Minun",
      "types": ["Electric"],
      "baseStats": {
        "hp": 60,
        "atk": 40,
        "def": 50,
        "spa": 75,
        "spd": 85,
        "spe": 95
      },
      "abilities": {"0": "Minus", "H": "Volt Absorb"},
      "weightkg": 4.2,
      "eggGroups": ["Fairy"]
    },
    "volbeat": {
      "num": 313,
      "name": "Volbeat",
      "types": ["Bug"],
      "gender": "M",
      "baseStats": {
        "hp": 65,
        "atk": 73,
        "def": 75,
        "spa": 47,
        "spd": 85,
        "spe": 85
      },
      "abilities": {"0": "Illuminate", "1": "Swarm", "H": "Prankster"},
      "weightkg": 17.7,
      "eggGroups": ["Bug", "Human-Like"]
    },
    "illumise": {
      "num": 314,
      "name": "Illumise",
      "types": ["Bug"],
      "gender": "F",
      "baseStats": {
        "hp": 65,
        "atk": 47,
        "def": 75,
        "spa": 73,
        "spd": 85,
        "spe": 85
      },
      "abilities": {"0": "Oblivious", "1": "Tinted Lens", "H": "Prankster"},
      "weightkg": 17.7,
      "eggGroups": ["Bug", "Human-Like"]
    },
    "roselia": {
      "num": 315,
      "name": "Roselia",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 50,
        "atk": 60,
        "def": 45,
        "spa": 100,
        "spd": 80,
        "spe": 65
      },
      "abilities": {
        "0": "Natural Cure",
        "1": "Poison Point",
        "H": "Leaf Guard"
      },
      "weightkg": 2,
      "prevo": "Budew",
      "evoType": "levelFriendship",
      "evoCondition": "during the day",
      "evos": ["Roserade"],
      "eggGroups": ["Fairy", "Grass"],
      "canHatch": true
    },
    "gulpin": {
      "num": 316,
      "name": "Gulpin",
      "types": ["Poison"],
      "baseStats": {
        "hp": 70,
        "atk": 43,
        "def": 53,
        "spa": 43,
        "spd": 53,
        "spe": 40
      },
      "abilities": {"0": "Liquid Ooze", "1": "Sticky Hold", "H": "Gluttony"},
      "weightkg": 10.3,
      "evos": ["Swalot"],
      "eggGroups": ["Amorphous"]
    },
    "swalot": {
      "num": 317,
      "name": "Swalot",
      "types": ["Poison"],
      "baseStats": {
        "hp": 100,
        "atk": 73,
        "def": 83,
        "spa": 73,
        "spd": 83,
        "spe": 55
      },
      "abilities": {"0": "Liquid Ooze", "1": "Sticky Hold", "H": "Gluttony"},
      "weightkg": 80,
      "prevo": "Gulpin",
      "evoLevel": 26,
      "eggGroups": ["Amorphous"]
    },
    "carvanha": {
      "num": 318,
      "name": "Carvanha",
      "types": ["Water", "Dark"],
      "baseStats": {
        "hp": 45,
        "atk": 90,
        "def": 20,
        "spa": 65,
        "spd": 20,
        "spe": 65
      },
      "abilities": {"0": "Rough Skin", "H": "Speed Boost"},
      "weightkg": 20.8,
      "evos": ["Sharpedo"],
      "eggGroups": ["Water 2"]
    },
    "sharpedo": {
      "num": 319,
      "name": "Sharpedo",
      "types": ["Water", "Dark"],
      "baseStats": {
        "hp": 70,
        "atk": 120,
        "def": 40,
        "spa": 95,
        "spd": 40,
        "spe": 95
      },
      "abilities": {"0": "Rough Skin", "H": "Speed Boost"},
      "weightkg": 88.8,
      "prevo": "Carvanha",
      "evoLevel": 30,
      "eggGroups": ["Water 2"],
      "otherFormes": ["Sharpedo-Mega"],
      "formeOrder": ["Sharpedo", "Sharpedo-Mega"]
    },
    "sharpedomega": {
      "num": 319,
      "name": "Sharpedo-Mega",
      "baseSpecies": "Sharpedo",
      "forme": "Mega",
      "types": ["Water", "Dark"],
      "baseStats": {
        "hp": 70,
        "atk": 140,
        "def": 70,
        "spa": 110,
        "spd": 65,
        "spe": 105
      },
      "abilities": {"0": "Strong Jaw"},
      "weightkg": 130.3,
      "eggGroups": ["Water 2"],
      "requiredItem": "Sharpedonite"
    },
    "wailmer": {
      "num": 320,
      "name": "Wailmer",
      "types": ["Water"],
      "baseStats": {
        "hp": 130,
        "atk": 70,
        "def": 35,
        "spa": 70,
        "spd": 35,
        "spe": 60
      },
      "abilities": {"0": "Water Veil", "1": "Oblivious", "H": "Pressure"},
      "weightkg": 130,
      "evos": ["Wailord"],
      "eggGroups": ["Field", "Water 2"]
    },
    "wailord": {
      "num": 321,
      "name": "Wailord",
      "types": ["Water"],
      "baseStats": {
        "hp": 170,
        "atk": 90,
        "def": 45,
        "spa": 90,
        "spd": 45,
        "spe": 60
      },
      "abilities": {"0": "Water Veil", "1": "Oblivious", "H": "Pressure"},
      "weightkg": 398,
      "prevo": "Wailmer",
      "evoLevel": 40,
      "eggGroups": ["Field", "Water 2"]
    },
    "numel": {
      "num": 322,
      "name": "Numel",
      "types": ["Fire", "Ground"],
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 40,
        "spa": 65,
        "spd": 45,
        "spe": 35
      },
      "abilities": {"0": "Oblivious", "1": "Simple", "H": "Own Tempo"},
      "weightkg": 24,
      "evos": ["Camerupt"],
      "eggGroups": ["Field"]
    },
    "camerupt": {
      "num": 323,
      "name": "Camerupt",
      "types": ["Fire", "Ground"],
      "baseStats": {
        "hp": 70,
        "atk": 100,
        "def": 70,
        "spa": 105,
        "spd": 75,
        "spe": 40
      },
      "abilities": {"0": "Magma Armor", "1": "Solid Rock", "H": "Anger Point"},
      "weightkg": 220,
      "prevo": "Numel",
      "evoLevel": 33,
      "eggGroups": ["Field"],
      "otherFormes": ["Camerupt-Mega"],
      "formeOrder": ["Camerupt", "Camerupt-Mega"]
    },
    "cameruptmega": {
      "num": 323,
      "name": "Camerupt-Mega",
      "baseSpecies": "Camerupt",
      "forme": "Mega",
      "types": ["Fire", "Ground"],
      "baseStats": {
        "hp": 70,
        "atk": 120,
        "def": 100,
        "spa": 145,
        "spd": 105,
        "spe": 20
      },
      "abilities": {"0": "Sheer Force"},
      "weightkg": 320.5,
      "eggGroups": ["Field"],
      "requiredItem": "Cameruptite"
    },
    "torkoal": {
      "num": 324,
      "name": "Torkoal",
      "types": ["Fire"],
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 140,
        "spa": 85,
        "spd": 70,
        "spe": 20
      },
      "abilities": {"0": "White Smoke", "1": "Drought", "H": "Shell Armor"},
      "weightkg": 80.4,
      "eggGroups": ["Field"]
    },
    "spoink": {
      "num": 325,
      "name": "Spoink",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 60,
        "atk": 25,
        "def": 35,
        "spa": 70,
        "spd": 80,
        "spe": 60
      },
      "abilities": {"0": "Thick Fat", "1": "Own Tempo", "H": "Gluttony"},
      "weightkg": 30.6,
      "evos": ["Grumpig"],
      "eggGroups": ["Field"]
    },
    "grumpig": {
      "num": 326,
      "name": "Grumpig",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 80,
        "atk": 45,
        "def": 65,
        "spa": 90,
        "spd": 110,
        "spe": 80
      },
      "abilities": {"0": "Thick Fat", "1": "Own Tempo", "H": "Gluttony"},
      "weightkg": 71.5,
      "prevo": "Spoink",
      "evoLevel": 32,
      "eggGroups": ["Field"]
    },
    "spinda": {
      "num": 327,
      "name": "Spinda",
      "types": ["Normal"],
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 60,
        "spa": 60,
        "spd": 60,
        "spe": 60
      },
      "abilities": {"0": "Own Tempo", "1": "Tangled Feet", "H": "Contrary"},
      "weightkg": 5,
      "eggGroups": ["Field", "Human-Like"]
    },
    "trapinch": {
      "num": 328,
      "name": "Trapinch",
      "types": ["Ground"],
      "baseStats": {
        "hp": 45,
        "atk": 100,
        "def": 45,
        "spa": 45,
        "spd": 45,
        "spe": 10
      },
      "abilities": {"0": "Hyper Cutter", "1": "Arena Trap", "H": "Sheer Force"},
      "weightkg": 15,
      "evos": ["Vibrava"],
      "eggGroups": ["Bug", "Dragon"]
    },
    "vibrava": {
      "num": 329,
      "name": "Vibrava",
      "types": ["Ground", "Dragon"],
      "baseStats": {
        "hp": 50,
        "atk": 70,
        "def": 50,
        "spa": 50,
        "spd": 50,
        "spe": 70
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 15.3,
      "prevo": "Trapinch",
      "evoLevel": 35,
      "evos": ["Flygon"],
      "eggGroups": ["Bug", "Dragon"]
    },
    "flygon": {
      "num": 330,
      "name": "Flygon",
      "types": ["Ground", "Dragon"],
      "baseStats": {
        "hp": 80,
        "atk": 100,
        "def": 80,
        "spa": 80,
        "spd": 80,
        "spe": 100
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 82,
      "prevo": "Vibrava",
      "evoLevel": 45,
      "eggGroups": ["Bug", "Dragon"]
    },
    "cacnea": {
      "num": 331,
      "name": "Cacnea",
      "types": ["Grass"],
      "baseStats": {
        "hp": 50,
        "atk": 85,
        "def": 40,
        "spa": 85,
        "spd": 40,
        "spe": 35
      },
      "abilities": {"0": "Sand Veil", "H": "Water Absorb"},
      "weightkg": 51.3,
      "evos": ["Cacturne"],
      "eggGroups": ["Grass", "Human-Like"]
    },
    "cacturne": {
      "num": 332,
      "name": "Cacturne",
      "types": ["Grass", "Dark"],
      "baseStats": {
        "hp": 70,
        "atk": 115,
        "def": 60,
        "spa": 115,
        "spd": 60,
        "spe": 55
      },
      "abilities": {"0": "Sand Veil", "H": "Water Absorb"},
      "weightkg": 77.4,
      "prevo": "Cacnea",
      "evoLevel": 32,
      "eggGroups": ["Grass", "Human-Like"]
    },
    "swablu": {
      "num": 333,
      "name": "Swablu",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 45,
        "atk": 40,
        "def": 60,
        "spa": 40,
        "spd": 75,
        "spe": 50
      },
      "abilities": {"0": "Natural Cure", "H": "Cloud Nine"},
      "weightkg": 1.2,
      "evos": ["Altaria"],
      "eggGroups": ["Flying", "Dragon"]
    },
    "altaria": {
      "num": 334,
      "name": "Altaria",
      "types": ["Dragon", "Flying"],
      "baseStats": {
        "hp": 75,
        "atk": 70,
        "def": 90,
        "spa": 70,
        "spd": 105,
        "spe": 80
      },
      "abilities": {"0": "Natural Cure", "H": "Cloud Nine"},
      "weightkg": 20.6,
      "prevo": "Swablu",
      "evoLevel": 35,
      "eggGroups": ["Flying", "Dragon"],
      "otherFormes": ["Altaria-Mega"],
      "formeOrder": ["Altaria", "Altaria-Mega"]
    },
    "altariamega": {
      "num": 334,
      "name": "Altaria-Mega",
      "baseSpecies": "Altaria",
      "forme": "Mega",
      "types": ["Dragon", "Fairy"],
      "baseStats": {
        "hp": 75,
        "atk": 110,
        "def": 110,
        "spa": 110,
        "spd": 105,
        "spe": 80
      },
      "abilities": {"0": "Pixilate"},
      "weightkg": 20.6,
      "eggGroups": ["Flying", "Dragon"],
      "requiredItem": "Altarianite"
    },
    "zangoose": {
      "num": 335,
      "name": "Zangoose",
      "types": ["Normal"],
      "baseStats": {
        "hp": 73,
        "atk": 115,
        "def": 60,
        "spa": 60,
        "spd": 60,
        "spe": 90
      },
      "abilities": {"0": "Immunity", "H": "Toxic Boost"},
      "weightkg": 40.3,
      "eggGroups": ["Field"]
    },
    "seviper": {
      "num": 336,
      "name": "Seviper",
      "types": ["Poison"],
      "baseStats": {
        "hp": 73,
        "atk": 100,
        "def": 60,
        "spa": 100,
        "spd": 60,
        "spe": 65
      },
      "abilities": {"0": "Shed Skin", "H": "Infiltrator"},
      "weightkg": 52.5,
      "eggGroups": ["Field", "Dragon"]
    },
    "lunatone": {
      "num": 337,
      "name": "Lunatone",
      "types": ["Rock", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 55,
        "def": 65,
        "spa": 95,
        "spd": 85,
        "spe": 70
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 168,
      "eggGroups": ["Mineral"]
    },
    "solrock": {
      "num": 338,
      "name": "Solrock",
      "types": ["Rock", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 95,
        "def": 85,
        "spa": 55,
        "spd": 65,
        "spe": 70
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 154,
      "eggGroups": ["Mineral"]
    },
    "barboach": {
      "num": 339,
      "name": "Barboach",
      "types": ["Water", "Ground"],
      "baseStats": {
        "hp": 50,
        "atk": 48,
        "def": 43,
        "spa": 46,
        "spd": 41,
        "spe": 60
      },
      "abilities": {"0": "Oblivious", "1": "Anticipation", "H": "Hydration"},
      "weightkg": 1.9,
      "evos": ["Whiscash"],
      "eggGroups": ["Water 2"]
    },
    "whiscash": {
      "num": 340,
      "name": "Whiscash",
      "types": ["Water", "Ground"],
      "baseStats": {
        "hp": 110,
        "atk": 78,
        "def": 73,
        "spa": 76,
        "spd": 71,
        "spe": 60
      },
      "abilities": {"0": "Oblivious", "1": "Anticipation", "H": "Hydration"},
      "weightkg": 23.6,
      "prevo": "Barboach",
      "evoLevel": 30,
      "eggGroups": ["Water 2"]
    },
    "corphish": {
      "num": 341,
      "name": "Corphish",
      "types": ["Water"],
      "baseStats": {
        "hp": 43,
        "atk": 80,
        "def": 65,
        "spa": 50,
        "spd": 35,
        "spe": 35
      },
      "abilities": {
        "0": "Hyper Cutter",
        "1": "Shell Armor",
        "H": "Adaptability"
      },
      "weightkg": 11.5,
      "evos": ["Crawdaunt"],
      "eggGroups": ["Water 1", "Water 3"]
    },
    "crawdaunt": {
      "num": 342,
      "name": "Crawdaunt",
      "types": ["Water", "Dark"],
      "baseStats": {
        "hp": 63,
        "atk": 120,
        "def": 85,
        "spa": 90,
        "spd": 55,
        "spe": 55
      },
      "abilities": {
        "0": "Hyper Cutter",
        "1": "Shell Armor",
        "H": "Adaptability"
      },
      "weightkg": 32.8,
      "prevo": "Corphish",
      "evoLevel": 30,
      "eggGroups": ["Water 1", "Water 3"]
    },
    "baltoy": {
      "num": 343,
      "name": "Baltoy",
      "types": ["Ground", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 40,
        "atk": 40,
        "def": 55,
        "spa": 40,
        "spd": 70,
        "spe": 55
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 21.5,
      "evos": ["Claydol"],
      "eggGroups": ["Mineral"]
    },
    "claydol": {
      "num": 344,
      "name": "Claydol",
      "types": ["Ground", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 70,
        "def": 105,
        "spa": 70,
        "spd": 120,
        "spe": 75
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 108,
      "prevo": "Baltoy",
      "evoLevel": 36,
      "eggGroups": ["Mineral"]
    },
    "lileep": {
      "num": 345,
      "name": "Lileep",
      "types": ["Rock", "Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 66,
        "atk": 41,
        "def": 77,
        "spa": 61,
        "spd": 87,
        "spe": 23
      },
      "abilities": {"0": "Suction Cups", "H": "Storm Drain"},
      "weightkg": 23.8,
      "evos": ["Cradily"],
      "eggGroups": ["Water 3"]
    },
    "cradily": {
      "num": 346,
      "name": "Cradily",
      "types": ["Rock", "Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 86,
        "atk": 81,
        "def": 97,
        "spa": 81,
        "spd": 107,
        "spe": 43
      },
      "abilities": {"0": "Suction Cups", "H": "Storm Drain"},
      "weightkg": 60.4,
      "prevo": "Lileep",
      "evoLevel": 40,
      "eggGroups": ["Water 3"]
    },
    "anorith": {
      "num": 347,
      "name": "Anorith",
      "types": ["Rock", "Bug"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 45,
        "atk": 95,
        "def": 50,
        "spa": 40,
        "spd": 50,
        "spe": 75
      },
      "abilities": {"0": "Battle Armor", "H": "Swift Swim"},
      "weightkg": 12.5,
      "evos": ["Armaldo"],
      "eggGroups": ["Water 3"]
    },
    "armaldo": {
      "num": 348,
      "name": "Armaldo",
      "types": ["Rock", "Bug"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 75,
        "atk": 125,
        "def": 100,
        "spa": 70,
        "spd": 80,
        "spe": 45
      },
      "abilities": {"0": "Battle Armor", "H": "Swift Swim"},
      "weightkg": 68.2,
      "prevo": "Anorith",
      "evoLevel": 40,
      "eggGroups": ["Water 3"]
    },
    "feebas": {
      "num": 349,
      "name": "Feebas",
      "types": ["Water"],
      "baseStats": {
        "hp": 20,
        "atk": 15,
        "def": 20,
        "spa": 10,
        "spd": 55,
        "spe": 80
      },
      "abilities": {"0": "Swift Swim", "1": "Oblivious", "H": "Adaptability"},
      "weightkg": 7.4,
      "evos": ["Milotic"],
      "eggGroups": ["Water 1", "Dragon"]
    },
    "milotic": {
      "num": 350,
      "name": "Milotic",
      "types": ["Water"],
      "baseStats": {
        "hp": 95,
        "atk": 60,
        "def": 79,
        "spa": 100,
        "spd": 125,
        "spe": 81
      },
      "abilities": {"0": "Marvel Scale", "1": "Competitive", "H": "Cute Charm"},
      "weightkg": 162,
      "prevo": "Feebas",
      "evoType": "trade",
      "evoItem": "Prism Scale",
      "eggGroups": ["Water 1", "Dragon"]
    },
    "castform": {
      "num": 351,
      "name": "Castform",
      "types": ["Normal"],
      "baseStats": {
        "hp": 70,
        "atk": 70,
        "def": 70,
        "spa": 70,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Forecast"},
      "weightkg": 0.8,
      "eggGroups": ["Fairy", "Amorphous"],
      "otherFormes": ["Castform-Sunny", "Castform-Rainy", "Castform-Snowy"],
      "formeOrder": [
        "Castform",
        "Castform-Sunny",
        "Castform-Rainy",
        "Castform-Snowy"
      ]
    },
    "castformsunny": {
      "num": 351,
      "name": "Castform-Sunny",
      "baseSpecies": "Castform",
      "forme": "Sunny",
      "types": ["Fire"],
      "baseStats": {
        "hp": 70,
        "atk": 70,
        "def": 70,
        "spa": 70,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Forecast"},
      "weightkg": 0.8,
      "eggGroups": ["Fairy", "Amorphous"],
      "requiredAbility": "Forecast",
      "battleOnly": "Castform"
    },
    "castformrainy": {
      "num": 351,
      "name": "Castform-Rainy",
      "baseSpecies": "Castform",
      "forme": "Rainy",
      "types": ["Water"],
      "baseStats": {
        "hp": 70,
        "atk": 70,
        "def": 70,
        "spa": 70,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Forecast"},
      "weightkg": 0.8,
      "eggGroups": ["Fairy", "Amorphous"],
      "requiredAbility": "Forecast",
      "battleOnly": "Castform"
    },
    "castformsnowy": {
      "num": 351,
      "name": "Castform-Snowy",
      "baseSpecies": "Castform",
      "forme": "Snowy",
      "types": ["Ice"],
      "baseStats": {
        "hp": 70,
        "atk": 70,
        "def": 70,
        "spa": 70,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Forecast"},
      "weightkg": 0.8,
      "eggGroups": ["Fairy", "Amorphous"],
      "requiredAbility": "Forecast",
      "battleOnly": "Castform"
    },
    "kecleon": {
      "num": 352,
      "name": "Kecleon",
      "types": ["Normal"],
      "baseStats": {
        "hp": 60,
        "atk": 90,
        "def": 70,
        "spa": 60,
        "spd": 120,
        "spe": 40
      },
      "abilities": {"0": "Color Change", "H": "Protean"},
      "weightkg": 22,
      "eggGroups": ["Field"]
    },
    "shuppet": {
      "num": 353,
      "name": "Shuppet",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 44,
        "atk": 75,
        "def": 35,
        "spa": 63,
        "spd": 33,
        "spe": 45
      },
      "abilities": {"0": "Insomnia", "1": "Frisk", "H": "Cursed Body"},
      "weightkg": 2.3,
      "evos": ["Banette"],
      "eggGroups": ["Amorphous"]
    },
    "banette": {
      "num": 354,
      "name": "Banette",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 64,
        "atk": 115,
        "def": 65,
        "spa": 83,
        "spd": 63,
        "spe": 65
      },
      "abilities": {"0": "Insomnia", "1": "Frisk", "H": "Cursed Body"},
      "weightkg": 12.5,
      "prevo": "Shuppet",
      "evoLevel": 37,
      "eggGroups": ["Amorphous"],
      "otherFormes": ["Banette-Mega"],
      "formeOrder": ["Banette", "Banette-Mega"]
    },
    "banettemega": {
      "num": 354,
      "name": "Banette-Mega",
      "baseSpecies": "Banette",
      "forme": "Mega",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 64,
        "atk": 165,
        "def": 75,
        "spa": 93,
        "spd": 83,
        "spe": 75
      },
      "abilities": {"0": "Prankster"},
      "weightkg": 13,
      "eggGroups": ["Amorphous"],
      "requiredItem": "Banettite"
    },
    "duskull": {
      "num": 355,
      "name": "Duskull",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 20,
        "atk": 40,
        "def": 90,
        "spa": 30,
        "spd": 90,
        "spe": 25
      },
      "abilities": {"0": "Levitate", "H": "Frisk"},
      "weightkg": 15,
      "evos": ["Dusclops"],
      "eggGroups": ["Amorphous"]
    },
    "dusclops": {
      "num": 356,
      "name": "Dusclops",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 40,
        "atk": 70,
        "def": 130,
        "spa": 60,
        "spd": 130,
        "spe": 25
      },
      "abilities": {"0": "Pressure", "H": "Frisk"},
      "weightkg": 30.6,
      "prevo": "Duskull",
      "evoLevel": 37,
      "evos": ["Dusknoir"],
      "eggGroups": ["Amorphous"]
    },
    "tropius": {
      "num": 357,
      "name": "Tropius",
      "types": ["Grass", "Flying"],
      "baseStats": {
        "hp": 99,
        "atk": 68,
        "def": 83,
        "spa": 72,
        "spd": 87,
        "spe": 51
      },
      "abilities": {"0": "Chlorophyll", "1": "Solar Power", "H": "Harvest"},
      "weightkg": 100,
      "eggGroups": ["Monster", "Grass"]
    },
    "chimecho": {
      "num": 358,
      "name": "Chimecho",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 75,
        "atk": 50,
        "def": 80,
        "spa": 95,
        "spd": 90,
        "spe": 65
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 1,
      "prevo": "Chingling",
      "evoType": "levelFriendship",
      "evoCondition": "at night",
      "eggGroups": ["Amorphous"],
      "canHatch": true
    },
    "absol": {
      "num": 359,
      "name": "Absol",
      "types": ["Dark"],
      "baseStats": {
        "hp": 65,
        "atk": 130,
        "def": 60,
        "spa": 75,
        "spd": 60,
        "spe": 75
      },
      "abilities": {"0": "Pressure", "1": "Super Luck", "H": "Justified"},
      "weightkg": 47,
      "eggGroups": ["Field"],
      "otherFormes": ["Absol-Mega"],
      "formeOrder": ["Absol", "Absol-Mega"]
    },
    "absolmega": {
      "num": 359,
      "name": "Absol-Mega",
      "baseSpecies": "Absol",
      "forme": "Mega",
      "types": ["Dark"],
      "baseStats": {
        "hp": 65,
        "atk": 150,
        "def": 60,
        "spa": 115,
        "spd": 60,
        "spe": 115
      },
      "abilities": {"0": "Magic Bounce"},
      "weightkg": 49,
      "eggGroups": ["Field"],
      "requiredItem": "Absolite"
    },
    "wynaut": {
      "num": 360,
      "name": "Wynaut",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 95,
        "atk": 23,
        "def": 48,
        "spa": 23,
        "spd": 48,
        "spe": 23
      },
      "abilities": {"0": "Shadow Tag", "H": "Telepathy"},
      "weightkg": 14,
      "evos": ["Wobbuffet"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "snorunt": {
      "num": 361,
      "name": "Snorunt",
      "types": ["Ice"],
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 50,
        "spa": 50,
        "spd": 50,
        "spe": 50
      },
      "abilities": {"0": "Inner Focus", "1": "Ice Body", "H": "Moody"},
      "weightkg": 16.8,
      "evos": ["Glalie", "Froslass"],
      "eggGroups": ["Fairy", "Mineral"]
    },
    "glalie": {
      "num": 362,
      "name": "Glalie",
      "types": ["Ice"],
      "baseStats": {
        "hp": 80,
        "atk": 80,
        "def": 80,
        "spa": 80,
        "spd": 80,
        "spe": 80
      },
      "abilities": {"0": "Inner Focus", "1": "Ice Body", "H": "Moody"},
      "weightkg": 256.5,
      "prevo": "Snorunt",
      "evoLevel": 42,
      "eggGroups": ["Fairy", "Mineral"],
      "otherFormes": ["Glalie-Mega"],
      "formeOrder": ["Glalie", "Glalie-Mega"]
    },
    "glaliemega": {
      "num": 362,
      "name": "Glalie-Mega",
      "baseSpecies": "Glalie",
      "forme": "Mega",
      "types": ["Ice"],
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 80,
        "spa": 120,
        "spd": 80,
        "spe": 100
      },
      "abilities": {"0": "Refrigerate"},
      "weightkg": 350.2,
      "eggGroups": ["Fairy", "Mineral"],
      "requiredItem": "Glalitite"
    },
    "spheal": {
      "num": 363,
      "name": "Spheal",
      "types": ["Ice", "Water"],
      "baseStats": {
        "hp": 70,
        "atk": 40,
        "def": 50,
        "spa": 55,
        "spd": 50,
        "spe": 25
      },
      "abilities": {"0": "Thick Fat", "1": "Ice Body", "H": "Oblivious"},
      "weightkg": 39.5,
      "evos": ["Sealeo"],
      "eggGroups": ["Water 1", "Field"]
    },
    "sealeo": {
      "num": 364,
      "name": "Sealeo",
      "types": ["Ice", "Water"],
      "baseStats": {
        "hp": 90,
        "atk": 60,
        "def": 70,
        "spa": 75,
        "spd": 70,
        "spe": 45
      },
      "abilities": {"0": "Thick Fat", "1": "Ice Body", "H": "Oblivious"},
      "weightkg": 87.6,
      "prevo": "Spheal",
      "evoLevel": 32,
      "evos": ["Walrein"],
      "eggGroups": ["Water 1", "Field"]
    },
    "walrein": {
      "num": 365,
      "name": "Walrein",
      "types": ["Ice", "Water"],
      "baseStats": {
        "hp": 110,
        "atk": 80,
        "def": 90,
        "spa": 95,
        "spd": 90,
        "spe": 65
      },
      "abilities": {"0": "Thick Fat", "1": "Ice Body", "H": "Oblivious"},
      "weightkg": 150.6,
      "prevo": "Sealeo",
      "evoLevel": 44,
      "eggGroups": ["Water 1", "Field"]
    },
    "clamperl": {
      "num": 366,
      "name": "Clamperl",
      "types": ["Water"],
      "baseStats": {
        "hp": 35,
        "atk": 64,
        "def": 85,
        "spa": 74,
        "spd": 55,
        "spe": 32
      },
      "abilities": {"0": "Shell Armor", "H": "Rattled"},
      "weightkg": 52.5,
      "evos": ["Huntail", "Gorebyss"],
      "eggGroups": ["Water 1"]
    },
    "huntail": {
      "num": 367,
      "name": "Huntail",
      "types": ["Water"],
      "baseStats": {
        "hp": 55,
        "atk": 104,
        "def": 105,
        "spa": 94,
        "spd": 75,
        "spe": 52
      },
      "abilities": {"0": "Swift Swim", "H": "Water Veil"},
      "weightkg": 27,
      "prevo": "Clamperl",
      "evoType": "trade",
      "evoItem": "Deep Sea Tooth",
      "eggGroups": ["Water 1"]
    },
    "gorebyss": {
      "num": 368,
      "name": "Gorebyss",
      "types": ["Water"],
      "baseStats": {
        "hp": 55,
        "atk": 84,
        "def": 105,
        "spa": 114,
        "spd": 75,
        "spe": 52
      },
      "abilities": {"0": "Swift Swim", "H": "Hydration"},
      "weightkg": 22.6,
      "prevo": "Clamperl",
      "evoType": "trade",
      "evoItem": "Deep Sea Scale",
      "eggGroups": ["Water 1"]
    },
    "relicanth": {
      "num": 369,
      "name": "Relicanth",
      "types": ["Water", "Rock"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 100,
        "atk": 90,
        "def": 130,
        "spa": 45,
        "spd": 65,
        "spe": 55
      },
      "abilities": {"0": "Swift Swim", "1": "Rock Head", "H": "Sturdy"},
      "weightkg": 23.4,
      "eggGroups": ["Water 1", "Water 2"]
    },
    "luvdisc": {
      "num": 370,
      "name": "Luvdisc",
      "types": ["Water"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 43,
        "atk": 30,
        "def": 55,
        "spa": 40,
        "spd": 65,
        "spe": 97
      },
      "abilities": {"0": "Swift Swim", "H": "Hydration"},
      "weightkg": 8.7,
      "eggGroups": ["Water 2"]
    },
    "bagon": {
      "num": 371,
      "name": "Bagon",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 45,
        "atk": 75,
        "def": 60,
        "spa": 40,
        "spd": 30,
        "spe": 50
      },
      "abilities": {"0": "Rock Head", "H": "Sheer Force"},
      "weightkg": 42.1,
      "evos": ["Shelgon"],
      "eggGroups": ["Dragon"]
    },
    "shelgon": {
      "num": 372,
      "name": "Shelgon",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 65,
        "atk": 95,
        "def": 100,
        "spa": 60,
        "spd": 50,
        "spe": 50
      },
      "abilities": {"0": "Rock Head", "H": "Overcoat"},
      "weightkg": 110.5,
      "prevo": "Bagon",
      "evoLevel": 30,
      "evos": ["Salamence"],
      "eggGroups": ["Dragon"]
    },
    "salamence": {
      "num": 373,
      "name": "Salamence",
      "types": ["Dragon", "Flying"],
      "baseStats": {
        "hp": 95,
        "atk": 135,
        "def": 80,
        "spa": 110,
        "spd": 80,
        "spe": 100
      },
      "abilities": {"0": "Intimidate", "H": "Moxie"},
      "weightkg": 102.6,
      "prevo": "Shelgon",
      "evoLevel": 50,
      "eggGroups": ["Dragon"],
      "otherFormes": ["Salamence-Mega"],
      "formeOrder": ["Salamence", "Salamence-Mega"]
    },
    "salamencemega": {
      "num": 373,
      "name": "Salamence-Mega",
      "baseSpecies": "Salamence",
      "forme": "Mega",
      "types": ["Dragon", "Flying"],
      "baseStats": {
        "hp": 95,
        "atk": 145,
        "def": 130,
        "spa": 120,
        "spd": 90,
        "spe": 120
      },
      "abilities": {"0": "Aerilate"},
      "weightkg": 112.6,
      "eggGroups": ["Dragon"],
      "requiredItem": "Salamencite"
    },
    "beldum": {
      "num": 374,
      "name": "Beldum",
      "types": ["Steel", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 40,
        "atk": 55,
        "def": 80,
        "spa": 35,
        "spd": 60,
        "spe": 30
      },
      "abilities": {"0": "Clear Body", "H": "Light Metal"},
      "weightkg": 95.2,
      "evos": ["Metang"],
      "eggGroups": ["Mineral"]
    },
    "metang": {
      "num": 375,
      "name": "Metang",
      "types": ["Steel", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 75,
        "def": 100,
        "spa": 55,
        "spd": 80,
        "spe": 50
      },
      "abilities": {"0": "Clear Body", "H": "Light Metal"},
      "weightkg": 202.5,
      "prevo": "Beldum",
      "evoLevel": 20,
      "evos": ["Metagross"],
      "eggGroups": ["Mineral"]
    },
    "metagross": {
      "num": 376,
      "name": "Metagross",
      "types": ["Steel", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 135,
        "def": 130,
        "spa": 95,
        "spd": 90,
        "spe": 70
      },
      "abilities": {"0": "Clear Body", "H": "Light Metal"},
      "weightkg": 550,
      "prevo": "Metang",
      "evoLevel": 45,
      "eggGroups": ["Mineral"],
      "otherFormes": ["Metagross-Mega"],
      "formeOrder": ["Metagross", "Metagross-Mega"]
    },
    "metagrossmega": {
      "num": 376,
      "name": "Metagross-Mega",
      "baseSpecies": "Metagross",
      "forme": "Mega",
      "types": ["Steel", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 145,
        "def": 150,
        "spa": 105,
        "spd": 110,
        "spe": 110
      },
      "abilities": {"0": "Tough Claws"},
      "weightkg": 942.9,
      "eggGroups": ["Mineral"],
      "requiredItem": "Metagrossite"
    },
    "regirock": {
      "num": 377,
      "name": "Regirock",
      "types": ["Rock"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 100,
        "def": 200,
        "spa": 50,
        "spd": 100,
        "spe": 50
      },
      "abilities": {"0": "Clear Body", "H": "Sturdy"},
      "weightkg": 230,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "regice": {
      "num": 378,
      "name": "Regice",
      "types": ["Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 50,
        "def": 100,
        "spa": 100,
        "spd": 200,
        "spe": 50
      },
      "abilities": {"0": "Clear Body", "H": "Ice Body"},
      "weightkg": 175,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "registeel": {
      "num": 379,
      "name": "Registeel",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 75,
        "def": 150,
        "spa": 75,
        "spd": 150,
        "spe": 50
      },
      "abilities": {"0": "Clear Body", "H": "Light Metal"},
      "weightkg": 205,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "latias": {
      "num": 380,
      "name": "Latias",
      "types": ["Dragon", "Psychic"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 80,
        "def": 90,
        "spa": 110,
        "spd": 130,
        "spe": 110
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 40,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Latias-Mega"],
      "formeOrder": ["Latias", "Latias-Mega"]
    },
    "latiasmega": {
      "num": 380,
      "name": "Latias-Mega",
      "baseSpecies": "Latias",
      "forme": "Mega",
      "types": ["Dragon", "Psychic"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 100,
        "def": 120,
        "spa": 140,
        "spd": 150,
        "spe": 110
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 52,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Latiasite"
    },
    "latios": {
      "num": 381,
      "name": "Latios",
      "types": ["Dragon", "Psychic"],
      "gender": "M",
      "baseStats": {
        "hp": 80,
        "atk": 90,
        "def": 80,
        "spa": 130,
        "spd": 110,
        "spe": 110
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 60,
      "eggGroups": ["Undiscovered"],
      "tags": ["Sub-Legendary"],
      "otherFormes": ["Latios-Mega"],
      "formeOrder": ["Latios", "Latios-Mega"]
    },
    "latiosmega": {
      "num": 381,
      "name": "Latios-Mega",
      "baseSpecies": "Latios",
      "forme": "Mega",
      "types": ["Dragon", "Psychic"],
      "gender": "M",
      "baseStats": {
        "hp": 80,
        "atk": 130,
        "def": 100,
        "spa": 160,
        "spd": 120,
        "spe": 110
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 70,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Latiosite"
    },
    "kyogre": {
      "num": 382,
      "name": "Kyogre",
      "types": ["Water"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 90,
        "spa": 150,
        "spd": 140,
        "spe": 90
      },
      "abilities": {"0": "Drizzle"},
      "weightkg": 352,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Kyogre-Primal"],
      "formeOrder": ["Kyogre", "Kyogre-Primal"]
    },
    "kyogreprimal": {
      "num": 382,
      "name": "Kyogre-Primal",
      "baseSpecies": "Kyogre",
      "forme": "Primal",
      "types": ["Water"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 150,
        "def": 90,
        "spa": 180,
        "spd": 160,
        "spe": 90
      },
      "abilities": {"0": "Primordial Sea"},
      "weightkg": 430,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Blue Orb"
    },
    "groudon": {
      "num": 383,
      "name": "Groudon",
      "types": ["Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 150,
        "def": 140,
        "spa": 100,
        "spd": 90,
        "spe": 90
      },
      "abilities": {"0": "Drought"},
      "weightkg": 950,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Groudon-Primal"],
      "formeOrder": ["Groudon", "Groudon-Primal"]
    },
    "groudonprimal": {
      "num": 383,
      "name": "Groudon-Primal",
      "baseSpecies": "Groudon",
      "forme": "Primal",
      "types": ["Ground", "Fire"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 180,
        "def": 160,
        "spa": 150,
        "spd": 90,
        "spe": 90
      },
      "abilities": {"0": "Desolate Land"},
      "weightkg": 999.7,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Red Orb"
    },
    "rayquaza": {
      "num": 384,
      "name": "Rayquaza",
      "types": ["Dragon", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 105,
        "atk": 150,
        "def": 90,
        "spa": 150,
        "spd": 90,
        "spe": 95
      },
      "abilities": {"0": "Air Lock"},
      "weightkg": 206.5,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Rayquaza-Mega"],
      "formeOrder": ["Rayquaza", "Rayquaza-Mega"]
    },
    "rayquazamega": {
      "num": 384,
      "name": "Rayquaza-Mega",
      "baseSpecies": "Rayquaza",
      "forme": "Mega",
      "types": ["Dragon", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 105,
        "atk": 180,
        "def": 100,
        "spa": 180,
        "spd": 100,
        "spe": 115
      },
      "abilities": {"0": "Delta Stream"},
      "weightkg": 392,
      "eggGroups": ["Undiscovered"],
      "requiredMove": "Dragon Ascent"
    },
    "jirachi": {
      "num": 385,
      "name": "Jirachi",
      "types": ["Steel", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Serene Grace"},
      "weightkg": 1.1,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"]
    },
    "deoxys": {
      "num": 386,
      "name": "Deoxys",
      "baseForme": "Normal",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 150,
        "def": 50,
        "spa": 150,
        "spd": 50,
        "spe": 150
      },
      "abilities": {"0": "Pressure"},
      "weightkg": 60.8,
      "eggGroups": ["Undiscovered"],
      "tags": ["Mythical"],
      "otherFormes": ["Deoxys-Attack", "Deoxys-Defense", "Deoxys-Speed"],
      "formeOrder": [
        "Deoxys",
        "Deoxys-Attack",
        "Deoxys-Defense",
        "Deoxys-Speed"
      ]
    },
    "deoxysattack": {
      "num": 386,
      "name": "Deoxys-Attack",
      "baseSpecies": "Deoxys",
      "forme": "Attack",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 180,
        "def": 20,
        "spa": 180,
        "spd": 20,
        "spe": 150
      },
      "abilities": {"0": "Pressure"},
      "weightkg": 60.8,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Deoxys"
    },
    "deoxysdefense": {
      "num": 386,
      "name": "Deoxys-Defense",
      "baseSpecies": "Deoxys",
      "forme": "Defense",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 70,
        "def": 160,
        "spa": 70,
        "spd": 160,
        "spe": 90
      },
      "abilities": {"0": "Pressure"},
      "weightkg": 60.8,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Deoxys"
    },
    "deoxysspeed": {
      "num": 386,
      "name": "Deoxys-Speed",
      "baseSpecies": "Deoxys",
      "forme": "Speed",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 95,
        "def": 90,
        "spa": 95,
        "spd": 90,
        "spe": 180
      },
      "abilities": {"0": "Pressure"},
      "weightkg": 60.8,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Deoxys"
    },
    "turtwig": {
      "num": 387,
      "name": "Turtwig",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 55,
        "atk": 68,
        "def": 64,
        "spa": 45,
        "spd": 55,
        "spe": 31
      },
      "abilities": {"0": "Overgrow", "H": "Shell Armor"},
      "weightkg": 10.2,
      "evos": ["Grotle"],
      "eggGroups": ["Monster", "Grass"]
    },
    "grotle": {
      "num": 388,
      "name": "Grotle",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 75,
        "atk": 89,
        "def": 85,
        "spa": 55,
        "spd": 65,
        "spe": 36
      },
      "abilities": {"0": "Overgrow", "H": "Shell Armor"},
      "weightkg": 97,
      "prevo": "Turtwig",
      "evoLevel": 18,
      "evos": ["Torterra"],
      "eggGroups": ["Monster", "Grass"]
    },
    "torterra": {
      "num": 389,
      "name": "Torterra",
      "types": ["Grass", "Ground"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 95,
        "atk": 109,
        "def": 105,
        "spa": 75,
        "spd": 85,
        "spe": 56
      },
      "abilities": {"0": "Overgrow", "H": "Shell Armor"},
      "weightkg": 310,
      "prevo": "Grotle",
      "evoLevel": 32,
      "eggGroups": ["Monster", "Grass"]
    },
    "chimchar": {
      "num": 390,
      "name": "Chimchar",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 44,
        "atk": 58,
        "def": 44,
        "spa": 58,
        "spd": 44,
        "spe": 61
      },
      "abilities": {"0": "Blaze", "H": "Iron Fist"},
      "weightkg": 6.2,
      "evos": ["Monferno"],
      "eggGroups": ["Field", "Human-Like"]
    },
    "monferno": {
      "num": 391,
      "name": "Monferno",
      "types": ["Fire", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 64,
        "atk": 78,
        "def": 52,
        "spa": 78,
        "spd": 52,
        "spe": 81
      },
      "abilities": {"0": "Blaze", "H": "Iron Fist"},
      "weightkg": 22,
      "prevo": "Chimchar",
      "evoLevel": 14,
      "evos": ["Infernape"],
      "eggGroups": ["Field", "Human-Like"]
    },
    "infernape": {
      "num": 392,
      "name": "Infernape",
      "types": ["Fire", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 76,
        "atk": 104,
        "def": 71,
        "spa": 104,
        "spd": 71,
        "spe": 108
      },
      "abilities": {"0": "Blaze", "H": "Iron Fist"},
      "weightkg": 55,
      "prevo": "Monferno",
      "evoLevel": 36,
      "eggGroups": ["Field", "Human-Like"]
    },
    "piplup": {
      "num": 393,
      "name": "Piplup",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 53,
        "atk": 51,
        "def": 53,
        "spa": 61,
        "spd": 56,
        "spe": 40
      },
      "abilities": {"0": "Torrent", "H": "Competitive"},
      "weightkg": 5.2,
      "evos": ["Prinplup"],
      "eggGroups": ["Water 1", "Field"]
    },
    "prinplup": {
      "num": 394,
      "name": "Prinplup",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 64,
        "atk": 66,
        "def": 68,
        "spa": 81,
        "spd": 76,
        "spe": 50
      },
      "abilities": {"0": "Torrent", "H": "Competitive"},
      "weightkg": 23,
      "prevo": "Piplup",
      "evoLevel": 16,
      "evos": ["Empoleon"],
      "eggGroups": ["Water 1", "Field"]
    },
    "empoleon": {
      "num": 395,
      "name": "Empoleon",
      "types": ["Water", "Steel"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 84,
        "atk": 86,
        "def": 88,
        "spa": 111,
        "spd": 101,
        "spe": 60
      },
      "abilities": {"0": "Torrent", "H": "Competitive"},
      "weightkg": 84.5,
      "prevo": "Prinplup",
      "evoLevel": 36,
      "eggGroups": ["Water 1", "Field"]
    },
    "starly": {
      "num": 396,
      "name": "Starly",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 40,
        "atk": 55,
        "def": 30,
        "spa": 30,
        "spd": 30,
        "spe": 60
      },
      "abilities": {"0": "Keen Eye", "H": "Reckless"},
      "weightkg": 2,
      "evos": ["Staravia"],
      "eggGroups": ["Flying"]
    },
    "staravia": {
      "num": 397,
      "name": "Staravia",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 55,
        "atk": 75,
        "def": 50,
        "spa": 40,
        "spd": 40,
        "spe": 80
      },
      "abilities": {"0": "Intimidate", "H": "Reckless"},
      "weightkg": 15.5,
      "prevo": "Starly",
      "evoLevel": 14,
      "evos": ["Staraptor"],
      "eggGroups": ["Flying"]
    },
    "staraptor": {
      "num": 398,
      "name": "Staraptor",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 85,
        "atk": 120,
        "def": 70,
        "spa": 50,
        "spd": 60,
        "spe": 100
      },
      "abilities": {"0": "Intimidate", "H": "Reckless"},
      "weightkg": 24.9,
      "prevo": "Staravia",
      "evoLevel": 34,
      "eggGroups": ["Flying"]
    },
    "bidoof": {
      "num": 399,
      "name": "Bidoof",
      "types": ["Normal"],
      "baseStats": {
        "hp": 59,
        "atk": 45,
        "def": 40,
        "spa": 35,
        "spd": 40,
        "spe": 31
      },
      "abilities": {"0": "Simple", "1": "Unaware", "H": "Moody"},
      "weightkg": 20,
      "evos": ["Bibarel"],
      "eggGroups": ["Water 1", "Field"]
    },
    "bibarel": {
      "num": 400,
      "name": "Bibarel",
      "types": ["Normal", "Water"],
      "baseStats": {
        "hp": 79,
        "atk": 85,
        "def": 60,
        "spa": 55,
        "spd": 60,
        "spe": 71
      },
      "abilities": {"0": "Simple", "1": "Unaware", "H": "Moody"},
      "weightkg": 31.5,
      "prevo": "Bidoof",
      "evoLevel": 15,
      "eggGroups": ["Water 1", "Field"]
    },
    "kricketot": {
      "num": 401,
      "name": "Kricketot",
      "types": ["Bug"],
      "baseStats": {
        "hp": 37,
        "atk": 25,
        "def": 41,
        "spa": 25,
        "spd": 41,
        "spe": 25
      },
      "abilities": {"0": "Shed Skin", "H": "Run Away"},
      "weightkg": 2.2,
      "evos": ["Kricketune"],
      "eggGroups": ["Bug"]
    },
    "kricketune": {
      "num": 402,
      "name": "Kricketune",
      "types": ["Bug"],
      "baseStats": {
        "hp": 77,
        "atk": 85,
        "def": 51,
        "spa": 55,
        "spd": 51,
        "spe": 65
      },
      "abilities": {"0": "Swarm", "H": "Technician"},
      "weightkg": 25.5,
      "prevo": "Kricketot",
      "evoLevel": 10,
      "eggGroups": ["Bug"]
    },
    "shinx": {
      "num": 403,
      "name": "Shinx",
      "types": ["Electric"],
      "baseStats": {
        "hp": 45,
        "atk": 65,
        "def": 34,
        "spa": 40,
        "spd": 34,
        "spe": 45
      },
      "abilities": {"0": "Rivalry", "1": "Intimidate", "H": "Guts"},
      "weightkg": 9.5,
      "evos": ["Luxio"],
      "eggGroups": ["Field"]
    },
    "luxio": {
      "num": 404,
      "name": "Luxio",
      "types": ["Electric"],
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 49,
        "spa": 60,
        "spd": 49,
        "spe": 60
      },
      "abilities": {"0": "Rivalry", "1": "Intimidate", "H": "Guts"},
      "weightkg": 30.5,
      "prevo": "Shinx",
      "evoLevel": 15,
      "evos": ["Luxray"],
      "eggGroups": ["Field"]
    },
    "luxray": {
      "num": 405,
      "name": "Luxray",
      "types": ["Electric"],
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 79,
        "spa": 95,
        "spd": 79,
        "spe": 70
      },
      "abilities": {"0": "Rivalry", "1": "Intimidate", "H": "Guts"},
      "weightkg": 42,
      "prevo": "Luxio",
      "evoLevel": 30,
      "eggGroups": ["Field"]
    },
    "budew": {
      "num": 406,
      "name": "Budew",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 40,
        "atk": 30,
        "def": 35,
        "spa": 50,
        "spd": 70,
        "spe": 55
      },
      "abilities": {
        "0": "Natural Cure",
        "1": "Poison Point",
        "H": "Leaf Guard"
      },
      "weightkg": 1.2,
      "evos": ["Roselia"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "roserade": {
      "num": 407,
      "name": "Roserade",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 70,
        "def": 65,
        "spa": 125,
        "spd": 105,
        "spe": 90
      },
      "abilities": {
        "0": "Natural Cure",
        "1": "Poison Point",
        "H": "Technician"
      },
      "weightkg": 14.5,
      "prevo": "Roselia",
      "evoType": "useItem",
      "evoItem": "Shiny Stone",
      "eggGroups": ["Fairy", "Grass"]
    },
    "cranidos": {
      "num": 408,
      "name": "Cranidos",
      "types": ["Rock"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 67,
        "atk": 125,
        "def": 40,
        "spa": 30,
        "spd": 30,
        "spe": 58
      },
      "abilities": {"0": "Mold Breaker", "H": "Sheer Force"},
      "weightkg": 31.5,
      "evos": ["Rampardos"],
      "eggGroups": ["Monster"]
    },
    "rampardos": {
      "num": 409,
      "name": "Rampardos",
      "types": ["Rock"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 97,
        "atk": 165,
        "def": 60,
        "spa": 65,
        "spd": 50,
        "spe": 58
      },
      "abilities": {"0": "Mold Breaker", "H": "Sheer Force"},
      "weightkg": 102.5,
      "prevo": "Cranidos",
      "evoLevel": 30,
      "eggGroups": ["Monster"]
    },
    "shieldon": {
      "num": 410,
      "name": "Shieldon",
      "types": ["Rock", "Steel"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 30,
        "atk": 42,
        "def": 118,
        "spa": 42,
        "spd": 88,
        "spe": 30
      },
      "abilities": {"0": "Sturdy", "H": "Soundproof"},
      "weightkg": 57,
      "evos": ["Bastiodon"],
      "eggGroups": ["Monster"]
    },
    "bastiodon": {
      "num": 411,
      "name": "Bastiodon",
      "types": ["Rock", "Steel"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 60,
        "atk": 52,
        "def": 168,
        "spa": 47,
        "spd": 138,
        "spe": 30
      },
      "abilities": {"0": "Sturdy", "H": "Soundproof"},
      "weightkg": 149.5,
      "prevo": "Shieldon",
      "evoLevel": 30,
      "eggGroups": ["Monster"]
    },
    "burmy": {
      "num": 412,
      "name": "Burmy",
      "baseForme": "Plant",
      "types": ["Bug"],
      "baseStats": {
        "hp": 40,
        "atk": 29,
        "def": 45,
        "spa": 29,
        "spd": 45,
        "spe": 36
      },
      "abilities": {"0": "Shed Skin", "H": "Overcoat"},
      "weightkg": 3.4,
      "evos": ["Wormadam", "Wormadam-Sandy", "Wormadam-Trash", "Mothim"],
      "eggGroups": ["Bug"],
      "cosmeticFormes": ["Burmy-Sandy", "Burmy-Trash"],
      "formeOrder": ["Burmy", "Burmy-Sandy", "Burmy-Trash"]
    },
    "wormadam": {
      "num": 413,
      "name": "Wormadam",
      "baseForme": "Plant",
      "types": ["Bug", "Grass"],
      "gender": "F",
      "baseStats": {
        "hp": 60,
        "atk": 59,
        "def": 85,
        "spa": 79,
        "spd": 105,
        "spe": 36
      },
      "abilities": {"0": "Anticipation", "H": "Overcoat"},
      "weightkg": 6.5,
      "prevo": "Burmy",
      "evoLevel": 20,
      "eggGroups": ["Bug"],
      "otherFormes": ["Wormadam-Sandy", "Wormadam-Trash"],
      "formeOrder": ["Wormadam", "Wormadam-Sandy", "Wormadam-Trash"]
    },
    "wormadamsandy": {
      "num": 413,
      "name": "Wormadam-Sandy",
      "baseSpecies": "Wormadam",
      "forme": "Sandy",
      "types": ["Bug", "Ground"],
      "gender": "F",
      "baseStats": {
        "hp": 60,
        "atk": 79,
        "def": 105,
        "spa": 59,
        "spd": 85,
        "spe": 36
      },
      "abilities": {"0": "Anticipation", "H": "Overcoat"},
      "weightkg": 6.5,
      "prevo": "Burmy",
      "evoLevel": 20,
      "eggGroups": ["Bug"]
    },
    "wormadamtrash": {
      "num": 413,
      "name": "Wormadam-Trash",
      "baseSpecies": "Wormadam",
      "forme": "Trash",
      "types": ["Bug", "Steel"],
      "gender": "F",
      "baseStats": {
        "hp": 60,
        "atk": 69,
        "def": 95,
        "spa": 69,
        "spd": 95,
        "spe": 36
      },
      "abilities": {"0": "Anticipation", "H": "Overcoat"},
      "weightkg": 6.5,
      "prevo": "Burmy",
      "evoLevel": 20,
      "eggGroups": ["Bug"]
    },
    "mothim": {
      "num": 414,
      "name": "Mothim",
      "types": ["Bug", "Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 70,
        "atk": 94,
        "def": 50,
        "spa": 94,
        "spd": 50,
        "spe": 66
      },
      "abilities": {"0": "Swarm", "H": "Tinted Lens"},
      "weightkg": 23.3,
      "prevo": "Burmy",
      "evoLevel": 20,
      "eggGroups": ["Bug"]
    },
    "combee": {
      "num": 415,
      "name": "Combee",
      "types": ["Bug", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 30,
        "atk": 30,
        "def": 42,
        "spa": 30,
        "spd": 42,
        "spe": 70
      },
      "abilities": {"0": "Honey Gather", "H": "Hustle"},
      "weightkg": 5.5,
      "evos": ["Vespiquen"],
      "eggGroups": ["Bug"]
    },
    "vespiquen": {
      "num": 416,
      "name": "Vespiquen",
      "types": ["Bug", "Flying"],
      "gender": "F",
      "baseStats": {
        "hp": 70,
        "atk": 80,
        "def": 102,
        "spa": 80,
        "spd": 102,
        "spe": 40
      },
      "abilities": {"0": "Pressure", "H": "Unnerve"},
      "weightkg": 38.5,
      "prevo": "Combee",
      "evoLevel": 21,
      "eggGroups": ["Bug"]
    },
    "pachirisu": {
      "num": 417,
      "name": "Pachirisu",
      "types": ["Electric"],
      "baseStats": {
        "hp": 60,
        "atk": 45,
        "def": 70,
        "spa": 45,
        "spd": 90,
        "spe": 95
      },
      "abilities": {"0": "Run Away", "1": "Pickup", "H": "Volt Absorb"},
      "weightkg": 3.9,
      "eggGroups": ["Field", "Fairy"]
    },
    "buizel": {
      "num": 418,
      "name": "Buizel",
      "types": ["Water"],
      "baseStats": {
        "hp": 55,
        "atk": 65,
        "def": 35,
        "spa": 60,
        "spd": 30,
        "spe": 85
      },
      "abilities": {"0": "Swift Swim", "H": "Water Veil"},
      "weightkg": 29.5,
      "evos": ["Floatzel"],
      "eggGroups": ["Water 1", "Field"]
    },
    "floatzel": {
      "num": 419,
      "name": "Floatzel",
      "types": ["Water"],
      "baseStats": {
        "hp": 85,
        "atk": 105,
        "def": 55,
        "spa": 85,
        "spd": 50,
        "spe": 115
      },
      "abilities": {"0": "Swift Swim", "H": "Water Veil"},
      "weightkg": 33.5,
      "prevo": "Buizel",
      "evoLevel": 26,
      "eggGroups": ["Water 1", "Field"]
    },
    "cherubi": {
      "num": 420,
      "name": "Cherubi",
      "types": ["Grass"],
      "baseStats": {
        "hp": 45,
        "atk": 35,
        "def": 45,
        "spa": 62,
        "spd": 53,
        "spe": 35
      },
      "abilities": {"0": "Chlorophyll"},
      "weightkg": 3.3,
      "evos": ["Cherrim"],
      "eggGroups": ["Fairy", "Grass"]
    },
    "cherrim": {
      "num": 421,
      "name": "Cherrim",
      "baseForme": "Overcast",
      "types": ["Grass"],
      "baseStats": {
        "hp": 70,
        "atk": 60,
        "def": 70,
        "spa": 87,
        "spd": 78,
        "spe": 85
      },
      "abilities": {"0": "Flower Gift"},
      "weightkg": 9.3,
      "prevo": "Cherubi",
      "evoLevel": 25,
      "eggGroups": ["Fairy", "Grass"],
      "otherFormes": ["Cherrim-Sunshine"],
      "formeOrder": ["Cherrim", "Cherrim-Sunshine"]
    },
    "cherrimsunshine": {
      "num": 421,
      "name": "Cherrim-Sunshine",
      "baseSpecies": "Cherrim",
      "forme": "Sunshine",
      "types": ["Grass"],
      "baseStats": {
        "hp": 70,
        "atk": 60,
        "def": 70,
        "spa": 87,
        "spd": 78,
        "spe": 85
      },
      "abilities": {"0": "Flower Gift"},
      "weightkg": 9.3,
      "eggGroups": ["Fairy", "Grass"],
      "requiredAbility": "Flower Gift",
      "battleOnly": "Cherrim"
    },
    "shellos": {
      "num": 422,
      "name": "Shellos",
      "baseForme": "West",
      "types": ["Water"],
      "baseStats": {
        "hp": 76,
        "atk": 48,
        "def": 48,
        "spa": 57,
        "spd": 62,
        "spe": 34
      },
      "abilities": {"0": "Sticky Hold", "1": "Storm Drain", "H": "Sand Force"},
      "weightkg": 6.3,
      "evos": ["Gastrodon"],
      "eggGroups": ["Water 1", "Amorphous"],
      "cosmeticFormes": ["Shellos-East"],
      "formeOrder": ["Shellos", "Shellos-East"]
    },
    "gastrodon": {
      "num": 423,
      "name": "Gastrodon",
      "baseForme": "West",
      "types": ["Water", "Ground"],
      "baseStats": {
        "hp": 111,
        "atk": 83,
        "def": 68,
        "spa": 92,
        "spd": 82,
        "spe": 39
      },
      "abilities": {"0": "Sticky Hold", "1": "Storm Drain", "H": "Sand Force"},
      "weightkg": 29.9,
      "prevo": "Shellos",
      "evoLevel": 30,
      "eggGroups": ["Water 1", "Amorphous"],
      "cosmeticFormes": ["Gastrodon-East"],
      "formeOrder": ["Gastrodon", "Gastrodon-East"]
    },
    "ambipom": {
      "num": 424,
      "name": "Ambipom",
      "types": ["Normal"],
      "baseStats": {
        "hp": 75,
        "atk": 100,
        "def": 66,
        "spa": 60,
        "spd": 66,
        "spe": 115
      },
      "abilities": {"0": "Technician", "1": "Pickup", "H": "Skill Link"},
      "weightkg": 20.3,
      "prevo": "Aipom",
      "evoType": "levelMove",
      "evoMove": "Double Hit",
      "eggGroups": ["Field"]
    },
    "drifloon": {
      "num": 425,
      "name": "Drifloon",
      "types": ["Ghost", "Flying"],
      "baseStats": {
        "hp": 90,
        "atk": 50,
        "def": 34,
        "spa": 60,
        "spd": 44,
        "spe": 70
      },
      "abilities": {"0": "Aftermath", "1": "Unburden", "H": "Flare Boost"},
      "weightkg": 1.2,
      "evos": ["Drifblim"],
      "eggGroups": ["Amorphous"]
    },
    "drifblim": {
      "num": 426,
      "name": "Drifblim",
      "types": ["Ghost", "Flying"],
      "baseStats": {
        "hp": 150,
        "atk": 80,
        "def": 44,
        "spa": 90,
        "spd": 54,
        "spe": 80
      },
      "abilities": {"0": "Aftermath", "1": "Unburden", "H": "Flare Boost"},
      "weightkg": 15,
      "prevo": "Drifloon",
      "evoLevel": 28,
      "eggGroups": ["Amorphous"]
    },
    "buneary": {
      "num": 427,
      "name": "Buneary",
      "types": ["Normal"],
      "baseStats": {
        "hp": 55,
        "atk": 66,
        "def": 44,
        "spa": 44,
        "spd": 56,
        "spe": 85
      },
      "abilities": {"0": "Run Away", "1": "Klutz", "H": "Limber"},
      "weightkg": 5.5,
      "evos": ["Lopunny"],
      "eggGroups": ["Field", "Human-Like"]
    },
    "lopunny": {
      "num": 428,
      "name": "Lopunny",
      "types": ["Normal"],
      "baseStats": {
        "hp": 65,
        "atk": 76,
        "def": 84,
        "spa": 54,
        "spd": 96,
        "spe": 105
      },
      "abilities": {"0": "Cute Charm", "1": "Klutz", "H": "Limber"},
      "weightkg": 33.3,
      "prevo": "Buneary",
      "evoType": "levelFriendship",
      "eggGroups": ["Field", "Human-Like"],
      "otherFormes": ["Lopunny-Mega"],
      "formeOrder": ["Lopunny", "Lopunny-Mega"]
    },
    "lopunnymega": {
      "num": 428,
      "name": "Lopunny-Mega",
      "baseSpecies": "Lopunny",
      "forme": "Mega",
      "types": ["Normal", "Fighting"],
      "baseStats": {
        "hp": 65,
        "atk": 136,
        "def": 94,
        "spa": 54,
        "spd": 96,
        "spe": 135
      },
      "abilities": {"0": "Scrappy"},
      "weightkg": 28.3,
      "eggGroups": ["Field", "Human-Like"],
      "requiredItem": "Lopunnite"
    },
    "mismagius": {
      "num": 429,
      "name": "Mismagius",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 60,
        "spa": 105,
        "spd": 105,
        "spe": 105
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 4.4,
      "prevo": "Misdreavus",
      "evoType": "useItem",
      "evoItem": "Dusk Stone",
      "eggGroups": ["Amorphous"]
    },
    "honchkrow": {
      "num": 430,
      "name": "Honchkrow",
      "types": ["Dark", "Flying"],
      "baseStats": {
        "hp": 100,
        "atk": 125,
        "def": 52,
        "spa": 105,
        "spd": 52,
        "spe": 71
      },
      "abilities": {"0": "Insomnia", "1": "Super Luck", "H": "Moxie"},
      "weightkg": 27.3,
      "prevo": "Murkrow",
      "evoType": "useItem",
      "evoItem": "Dusk Stone",
      "eggGroups": ["Flying"]
    },
    "glameow": {
      "num": 431,
      "name": "Glameow",
      "types": ["Normal"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 49,
        "atk": 55,
        "def": 42,
        "spa": 42,
        "spd": 37,
        "spe": 85
      },
      "abilities": {"0": "Limber", "1": "Own Tempo", "H": "Keen Eye"},
      "weightkg": 3.9,
      "evos": ["Purugly"],
      "eggGroups": ["Field"]
    },
    "purugly": {
      "num": 432,
      "name": "Purugly",
      "types": ["Normal"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 71,
        "atk": 82,
        "def": 64,
        "spa": 64,
        "spd": 59,
        "spe": 112
      },
      "abilities": {"0": "Thick Fat", "1": "Own Tempo", "H": "Defiant"},
      "weightkg": 43.8,
      "prevo": "Glameow",
      "evoLevel": 38,
      "eggGroups": ["Field"]
    },
    "chingling": {
      "num": 433,
      "name": "Chingling",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 45,
        "atk": 30,
        "def": 50,
        "spa": 65,
        "spd": 50,
        "spe": 45
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.6,
      "evos": ["Chimecho"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "stunky": {
      "num": 434,
      "name": "Stunky",
      "types": ["Poison", "Dark"],
      "baseStats": {
        "hp": 63,
        "atk": 63,
        "def": 47,
        "spa": 41,
        "spd": 41,
        "spe": 74
      },
      "abilities": {"0": "Stench", "1": "Aftermath", "H": "Keen Eye"},
      "weightkg": 19.2,
      "evos": ["Skuntank"],
      "eggGroups": ["Field"]
    },
    "skuntank": {
      "num": 435,
      "name": "Skuntank",
      "types": ["Poison", "Dark"],
      "baseStats": {
        "hp": 103,
        "atk": 93,
        "def": 67,
        "spa": 71,
        "spd": 61,
        "spe": 84
      },
      "abilities": {"0": "Stench", "1": "Aftermath", "H": "Keen Eye"},
      "weightkg": 38,
      "prevo": "Stunky",
      "evoLevel": 34,
      "eggGroups": ["Field"]
    },
    "bronzor": {
      "num": 436,
      "name": "Bronzor",
      "types": ["Steel", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 57,
        "atk": 24,
        "def": 86,
        "spa": 24,
        "spd": 86,
        "spe": 23
      },
      "abilities": {"0": "Levitate", "1": "Heatproof", "H": "Heavy Metal"},
      "weightkg": 60.5,
      "evos": ["Bronzong"],
      "eggGroups": ["Mineral"]
    },
    "bronzong": {
      "num": 437,
      "name": "Bronzong",
      "types": ["Steel", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 67,
        "atk": 89,
        "def": 116,
        "spa": 79,
        "spd": 116,
        "spe": 33
      },
      "abilities": {"0": "Levitate", "1": "Heatproof", "H": "Heavy Metal"},
      "weightkg": 187,
      "prevo": "Bronzor",
      "evoLevel": 33,
      "eggGroups": ["Mineral"]
    },
    "bonsly": {
      "num": 438,
      "name": "Bonsly",
      "types": ["Rock"],
      "baseStats": {
        "hp": 50,
        "atk": 80,
        "def": 95,
        "spa": 10,
        "spd": 45,
        "spe": 10
      },
      "abilities": {"0": "Sturdy", "1": "Rock Head", "H": "Rattled"},
      "weightkg": 15,
      "evos": ["Sudowoodo"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "mimejr": {
      "num": 439,
      "name": "Mime Jr.",
      "types": ["Psychic", "Fairy"],
      "baseStats": {
        "hp": 20,
        "atk": 25,
        "def": 45,
        "spa": 70,
        "spd": 90,
        "spe": 60
      },
      "abilities": {"0": "Soundproof", "1": "Filter", "H": "Technician"},
      "weightkg": 13,
      "evos": ["Mr. Mime", "Mr. Mime-Galar"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "happiny": {
      "num": 440,
      "name": "Happiny",
      "types": ["Normal"],
      "gender": "F",
      "baseStats": {
        "hp": 100,
        "atk": 5,
        "def": 5,
        "spa": 15,
        "spd": 65,
        "spe": 30
      },
      "abilities": {
        "0": "Natural Cure",
        "1": "Serene Grace",
        "H": "Friend Guard"
      },
      "weightkg": 24.4,
      "evos": ["Chansey"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "chatot": {
      "num": 441,
      "name": "Chatot",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 76,
        "atk": 65,
        "def": 45,
        "spa": 92,
        "spd": 42,
        "spe": 91
      },
      "abilities": {"0": "Keen Eye", "1": "Tangled Feet", "H": "Big Pecks"},
      "weightkg": 1.9,
      "eggGroups": ["Flying"]
    },
    "spiritomb": {
      "num": 442,
      "name": "Spiritomb",
      "types": ["Ghost", "Dark"],
      "baseStats": {
        "hp": 50,
        "atk": 92,
        "def": 108,
        "spa": 92,
        "spd": 108,
        "spe": 35
      },
      "abilities": {"0": "Pressure", "H": "Infiltrator"},
      "weightkg": 108,
      "eggGroups": ["Amorphous"]
    },
    "gible": {
      "num": 443,
      "name": "Gible",
      "types": ["Dragon", "Ground"],
      "baseStats": {
        "hp": 58,
        "atk": 70,
        "def": 45,
        "spa": 40,
        "spd": 45,
        "spe": 42
      },
      "abilities": {"0": "Sand Veil", "H": "Rough Skin"},
      "weightkg": 20.5,
      "evos": ["Gabite"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "gabite": {
      "num": 444,
      "name": "Gabite",
      "types": ["Dragon", "Ground"],
      "baseStats": {
        "hp": 68,
        "atk": 90,
        "def": 65,
        "spa": 50,
        "spd": 55,
        "spe": 82
      },
      "abilities": {"0": "Sand Veil", "H": "Rough Skin"},
      "weightkg": 56,
      "prevo": "Gible",
      "evoLevel": 24,
      "evos": ["Garchomp"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "garchomp": {
      "num": 445,
      "name": "Garchomp",
      "types": ["Dragon", "Ground"],
      "baseStats": {
        "hp": 108,
        "atk": 130,
        "def": 95,
        "spa": 80,
        "spd": 85,
        "spe": 102
      },
      "abilities": {"0": "Sand Veil", "H": "Rough Skin"},
      "weightkg": 95,
      "prevo": "Gabite",
      "evoLevel": 48,
      "eggGroups": ["Monster", "Dragon"],
      "otherFormes": ["Garchomp-Mega"],
      "formeOrder": ["Garchomp", "Garchomp-Mega"]
    },
    "garchompmega": {
      "num": 445,
      "name": "Garchomp-Mega",
      "baseSpecies": "Garchomp",
      "forme": "Mega",
      "types": ["Dragon", "Ground"],
      "baseStats": {
        "hp": 108,
        "atk": 170,
        "def": 115,
        "spa": 120,
        "spd": 95,
        "spe": 92
      },
      "abilities": {"0": "Sand Force"},
      "weightkg": 95,
      "eggGroups": ["Monster", "Dragon"],
      "requiredItem": "Garchompite"
    },
    "munchlax": {
      "num": 446,
      "name": "Munchlax",
      "types": ["Normal"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 135,
        "atk": 85,
        "def": 40,
        "spa": 40,
        "spd": 85,
        "spe": 5
      },
      "abilities": {"0": "Pickup", "1": "Thick Fat", "H": "Gluttony"},
      "weightkg": 105,
      "evos": ["Snorlax"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "riolu": {
      "num": 447,
      "name": "Riolu",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 40,
        "atk": 70,
        "def": 40,
        "spa": 35,
        "spd": 40,
        "spe": 60
      },
      "abilities": {"0": "Steadfast", "1": "Inner Focus", "H": "Prankster"},
      "weightkg": 20.2,
      "evos": ["Lucario"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "lucario": {
      "num": 448,
      "name": "Lucario",
      "types": ["Fighting", "Steel"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 110,
        "def": 70,
        "spa": 115,
        "spd": 70,
        "spe": 90
      },
      "abilities": {"0": "Steadfast", "1": "Inner Focus", "H": "Justified"},
      "weightkg": 54,
      "prevo": "Riolu",
      "evoType": "levelFriendship",
      "evoCondition": "during the day",
      "eggGroups": ["Field", "Human-Like"],
      "otherFormes": ["Lucario-Mega"],
      "formeOrder": ["Lucario", "Lucario-Mega"]
    },
    "lucariomega": {
      "num": 448,
      "name": "Lucario-Mega",
      "baseSpecies": "Lucario",
      "forme": "Mega",
      "types": ["Fighting", "Steel"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 145,
        "def": 88,
        "spa": 140,
        "spd": 70,
        "spe": 112
      },
      "abilities": {"0": "Adaptability"},
      "weightkg": 57.5,
      "eggGroups": ["Field", "Human-Like"],
      "requiredItem": "Lucarionite"
    },
    "hippopotas": {
      "num": 449,
      "name": "Hippopotas",
      "types": ["Ground"],
      "baseStats": {
        "hp": 68,
        "atk": 72,
        "def": 78,
        "spa": 38,
        "spd": 42,
        "spe": 32
      },
      "abilities": {"0": "Sand Stream", "H": "Sand Force"},
      "weightkg": 49.5,
      "evos": ["Hippowdon"],
      "eggGroups": ["Field"]
    },
    "hippowdon": {
      "num": 450,
      "name": "Hippowdon",
      "types": ["Ground"],
      "baseStats": {
        "hp": 108,
        "atk": 112,
        "def": 118,
        "spa": 68,
        "spd": 72,
        "spe": 47
      },
      "abilities": {"0": "Sand Stream", "H": "Sand Force"},
      "weightkg": 300,
      "prevo": "Hippopotas",
      "evoLevel": 34,
      "eggGroups": ["Field"]
    },
    "skorupi": {
      "num": 451,
      "name": "Skorupi",
      "types": ["Poison", "Bug"],
      "baseStats": {
        "hp": 40,
        "atk": 50,
        "def": 90,
        "spa": 30,
        "spd": 55,
        "spe": 65
      },
      "abilities": {"0": "Battle Armor", "1": "Sniper", "H": "Keen Eye"},
      "weightkg": 12,
      "evos": ["Drapion"],
      "eggGroups": ["Bug", "Water 3"]
    },
    "drapion": {
      "num": 452,
      "name": "Drapion",
      "types": ["Poison", "Dark"],
      "baseStats": {
        "hp": 70,
        "atk": 90,
        "def": 110,
        "spa": 60,
        "spd": 75,
        "spe": 95
      },
      "abilities": {"0": "Battle Armor", "1": "Sniper", "H": "Keen Eye"},
      "weightkg": 61.5,
      "prevo": "Skorupi",
      "evoLevel": 40,
      "eggGroups": ["Bug", "Water 3"]
    },
    "croagunk": {
      "num": 453,
      "name": "Croagunk",
      "types": ["Poison", "Fighting"],
      "baseStats": {
        "hp": 48,
        "atk": 61,
        "def": 40,
        "spa": 61,
        "spd": 40,
        "spe": 50
      },
      "abilities": {"0": "Anticipation", "1": "Dry Skin", "H": "Poison Touch"},
      "weightkg": 23,
      "evos": ["Toxicroak"],
      "eggGroups": ["Human-Like"]
    },
    "toxicroak": {
      "num": 454,
      "name": "Toxicroak",
      "types": ["Poison", "Fighting"],
      "baseStats": {
        "hp": 83,
        "atk": 106,
        "def": 65,
        "spa": 86,
        "spd": 65,
        "spe": 85
      },
      "abilities": {"0": "Anticipation", "1": "Dry Skin", "H": "Poison Touch"},
      "weightkg": 44.4,
      "prevo": "Croagunk",
      "evoLevel": 37,
      "eggGroups": ["Human-Like"]
    },
    "carnivine": {
      "num": 455,
      "name": "Carnivine",
      "types": ["Grass"],
      "baseStats": {
        "hp": 74,
        "atk": 100,
        "def": 72,
        "spa": 90,
        "spd": 72,
        "spe": 46
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 27,
      "eggGroups": ["Grass"]
    },
    "finneon": {
      "num": 456,
      "name": "Finneon",
      "types": ["Water"],
      "baseStats": {
        "hp": 49,
        "atk": 49,
        "def": 56,
        "spa": 49,
        "spd": 61,
        "spe": 66
      },
      "abilities": {"0": "Swift Swim", "1": "Storm Drain", "H": "Water Veil"},
      "weightkg": 7,
      "evos": ["Lumineon"],
      "eggGroups": ["Water 2"]
    },
    "lumineon": {
      "num": 457,
      "name": "Lumineon",
      "types": ["Water"],
      "baseStats": {
        "hp": 69,
        "atk": 69,
        "def": 76,
        "spa": 69,
        "spd": 86,
        "spe": 91
      },
      "abilities": {"0": "Swift Swim", "1": "Storm Drain", "H": "Water Veil"},
      "weightkg": 24,
      "prevo": "Finneon",
      "evoLevel": 31,
      "eggGroups": ["Water 2"]
    },
    "mantyke": {
      "num": 458,
      "name": "Mantyke",
      "types": ["Water", "Flying"],
      "baseStats": {
        "hp": 45,
        "atk": 20,
        "def": 50,
        "spa": 60,
        "spd": 120,
        "spe": 50
      },
      "abilities": {"0": "Swift Swim", "1": "Water Absorb", "H": "Water Veil"},
      "weightkg": 65,
      "evos": ["Mantine"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "snover": {
      "num": 459,
      "name": "Snover",
      "types": ["Grass", "Ice"],
      "baseStats": {
        "hp": 60,
        "atk": 62,
        "def": 50,
        "spa": 62,
        "spd": 60,
        "spe": 40
      },
      "abilities": {"0": "Snow Warning", "H": "Soundproof"},
      "weightkg": 50.5,
      "evos": ["Abomasnow"],
      "eggGroups": ["Monster", "Grass"]
    },
    "abomasnow": {
      "num": 460,
      "name": "Abomasnow",
      "types": ["Grass", "Ice"],
      "baseStats": {
        "hp": 90,
        "atk": 92,
        "def": 75,
        "spa": 92,
        "spd": 85,
        "spe": 60
      },
      "abilities": {"0": "Snow Warning", "H": "Soundproof"},
      "weightkg": 135.5,
      "prevo": "Snover",
      "evoLevel": 40,
      "eggGroups": ["Monster", "Grass"],
      "otherFormes": ["Abomasnow-Mega"],
      "formeOrder": ["Abomasnow", "Abomasnow-Mega"]
    },
    "abomasnowmega": {
      "num": 460,
      "name": "Abomasnow-Mega",
      "baseSpecies": "Abomasnow",
      "forme": "Mega",
      "types": ["Grass", "Ice"],
      "baseStats": {
        "hp": 90,
        "atk": 132,
        "def": 105,
        "spa": 132,
        "spd": 105,
        "spe": 30
      },
      "abilities": {"0": "Snow Warning"},
      "weightkg": 185,
      "eggGroups": ["Monster", "Grass"],
      "requiredItem": "Abomasite"
    },
    "weavile": {
      "num": 461,
      "name": "Weavile",
      "types": ["Dark", "Ice"],
      "baseStats": {
        "hp": 70,
        "atk": 120,
        "def": 65,
        "spa": 45,
        "spd": 85,
        "spe": 125
      },
      "abilities": {"0": "Pressure", "H": "Pickpocket"},
      "weightkg": 34,
      "prevo": "Sneasel",
      "evoType": "levelHold",
      "evoItem": "Razor Claw",
      "evoCondition": "at night",
      "eggGroups": ["Field"]
    },
    "magnezone": {
      "num": 462,
      "name": "Magnezone",
      "types": ["Electric", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 70,
        "atk": 70,
        "def": 115,
        "spa": 130,
        "spd": 90,
        "spe": 60
      },
      "abilities": {"0": "Magnet Pull", "1": "Sturdy", "H": "Analytic"},
      "weightkg": 180,
      "prevo": "Magneton",
      "evoType": "useItem",
      "evoItem": "Thunder Stone",
      "eggGroups": ["Mineral"]
    },
    "lickilicky": {
      "num": 463,
      "name": "Lickilicky",
      "types": ["Normal"],
      "baseStats": {
        "hp": 110,
        "atk": 85,
        "def": 95,
        "spa": 80,
        "spd": 95,
        "spe": 50
      },
      "abilities": {"0": "Own Tempo", "1": "Oblivious", "H": "Cloud Nine"},
      "weightkg": 140,
      "prevo": "Lickitung",
      "evoType": "levelMove",
      "evoMove": "Rollout",
      "eggGroups": ["Monster"]
    },
    "rhyperior": {
      "num": 464,
      "name": "Rhyperior",
      "types": ["Ground", "Rock"],
      "baseStats": {
        "hp": 115,
        "atk": 140,
        "def": 130,
        "spa": 55,
        "spd": 55,
        "spe": 40
      },
      "abilities": {"0": "Lightning Rod", "1": "Solid Rock", "H": "Reckless"},
      "weightkg": 282.8,
      "prevo": "Rhydon",
      "evoType": "trade",
      "evoItem": "Protector",
      "eggGroups": ["Monster", "Field"]
    },
    "tangrowth": {
      "num": 465,
      "name": "Tangrowth",
      "types": ["Grass"],
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 125,
        "spa": 110,
        "spd": 50,
        "spe": 50
      },
      "abilities": {"0": "Chlorophyll", "1": "Leaf Guard", "H": "Regenerator"},
      "weightkg": 128.6,
      "prevo": "Tangela",
      "evoType": "levelMove",
      "evoMove": "Ancient Power",
      "eggGroups": ["Grass"]
    },
    "electivire": {
      "num": 466,
      "name": "Electivire",
      "types": ["Electric"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 75,
        "atk": 123,
        "def": 67,
        "spa": 95,
        "spd": 85,
        "spe": 95
      },
      "abilities": {"0": "Motor Drive", "H": "Vital Spirit"},
      "weightkg": 138.6,
      "prevo": "Electabuzz",
      "evoType": "trade",
      "evoItem": "Electirizer",
      "eggGroups": ["Human-Like"]
    },
    "magmortar": {
      "num": 467,
      "name": "Magmortar",
      "types": ["Fire"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 75,
        "atk": 95,
        "def": 67,
        "spa": 125,
        "spd": 95,
        "spe": 83
      },
      "abilities": {"0": "Flame Body", "H": "Vital Spirit"},
      "weightkg": 68,
      "prevo": "Magmar",
      "evoType": "trade",
      "evoItem": "Magmarizer",
      "eggGroups": ["Human-Like"]
    },
    "togekiss": {
      "num": 468,
      "name": "Togekiss",
      "types": ["Fairy", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 85,
        "atk": 50,
        "def": 95,
        "spa": 120,
        "spd": 115,
        "spe": 80
      },
      "abilities": {"0": "Hustle", "1": "Serene Grace", "H": "Super Luck"},
      "weightkg": 38,
      "prevo": "Togetic",
      "evoType": "useItem",
      "evoItem": "Shiny Stone",
      "eggGroups": ["Flying", "Fairy"]
    },
    "yanmega": {
      "num": 469,
      "name": "Yanmega",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 86,
        "atk": 76,
        "def": 86,
        "spa": 116,
        "spd": 56,
        "spe": 95
      },
      "abilities": {"0": "Speed Boost", "1": "Tinted Lens", "H": "Frisk"},
      "weightkg": 51.5,
      "prevo": "Yanma",
      "evoType": "levelMove",
      "evoMove": "Ancient Power",
      "eggGroups": ["Bug"]
    },
    "leafeon": {
      "num": 470,
      "name": "Leafeon",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 110,
        "def": 130,
        "spa": 60,
        "spd": 65,
        "spe": 95
      },
      "abilities": {"0": "Leaf Guard", "H": "Chlorophyll"},
      "weightkg": 25.5,
      "prevo": "Eevee",
      "evoType": "useItem",
      "evoItem": "Leaf Stone",
      "eggGroups": ["Field"]
    },
    "glaceon": {
      "num": 471,
      "name": "Glaceon",
      "types": ["Ice"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 60,
        "def": 110,
        "spa": 130,
        "spd": 95,
        "spe": 65
      },
      "abilities": {"0": "Snow Cloak", "H": "Ice Body"},
      "weightkg": 25.9,
      "prevo": "Eevee",
      "evoType": "useItem",
      "evoItem": "Ice Stone",
      "eggGroups": ["Field"]
    },
    "gliscor": {
      "num": 472,
      "name": "Gliscor",
      "types": ["Ground", "Flying"],
      "baseStats": {
        "hp": 75,
        "atk": 95,
        "def": 125,
        "spa": 45,
        "spd": 75,
        "spe": 95
      },
      "abilities": {"0": "Hyper Cutter", "1": "Sand Veil", "H": "Poison Heal"},
      "weightkg": 42.5,
      "prevo": "Gligar",
      "evoType": "levelHold",
      "evoItem": "Razor Fang",
      "evoCondition": "at night",
      "eggGroups": ["Bug"]
    },
    "mamoswine": {
      "num": 473,
      "name": "Mamoswine",
      "types": ["Ice", "Ground"],
      "baseStats": {
        "hp": 110,
        "atk": 130,
        "def": 80,
        "spa": 70,
        "spd": 60,
        "spe": 80
      },
      "abilities": {"0": "Oblivious", "1": "Snow Cloak", "H": "Thick Fat"},
      "weightkg": 291,
      "prevo": "Piloswine",
      "evoType": "levelMove",
      "evoMove": "Ancient Power",
      "eggGroups": ["Field"]
    },
    "porygonz": {
      "num": 474,
      "name": "Porygon-Z",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 85,
        "atk": 80,
        "def": 70,
        "spa": 135,
        "spd": 75,
        "spe": 90
      },
      "abilities": {"0": "Adaptability", "1": "Download", "H": "Analytic"},
      "weightkg": 34,
      "prevo": "Porygon2",
      "evoType": "trade",
      "evoItem": "Dubious Disc",
      "eggGroups": ["Mineral"]
    },
    "gallade": {
      "num": 475,
      "name": "Gallade",
      "types": ["Psychic", "Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 68,
        "atk": 125,
        "def": 65,
        "spa": 65,
        "spd": 115,
        "spe": 80
      },
      "abilities": {"0": "Steadfast", "1": "Sharpness", "H": "Justified"},
      "weightkg": 52,
      "prevo": "Kirlia",
      "evoType": "useItem",
      "evoItem": "Dawn Stone",
      "eggGroups": ["Human-Like", "Amorphous"],
      "otherFormes": ["Gallade-Mega"],
      "formeOrder": ["Gallade", "Gallade-Mega"]
    },
    "gallademega": {
      "num": 475,
      "name": "Gallade-Mega",
      "baseSpecies": "Gallade",
      "forme": "Mega",
      "types": ["Psychic", "Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 68,
        "atk": 165,
        "def": 95,
        "spa": 65,
        "spd": 115,
        "spe": 110
      },
      "abilities": {"0": "Inner Focus"},
      "weightkg": 56.4,
      "eggGroups": ["Amorphous"],
      "requiredItem": "Galladite"
    },
    "probopass": {
      "num": 476,
      "name": "Probopass",
      "types": ["Rock", "Steel"],
      "baseStats": {
        "hp": 60,
        "atk": 55,
        "def": 145,
        "spa": 75,
        "spd": 150,
        "spe": 40
      },
      "abilities": {"0": "Sturdy", "1": "Magnet Pull", "H": "Sand Force"},
      "weightkg": 340,
      "prevo": "Nosepass",
      "evoType": "levelExtra",
      "evoCondition": "near a special magnetic field",
      "eggGroups": ["Mineral"]
    },
    "dusknoir": {
      "num": 477,
      "name": "Dusknoir",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 45,
        "atk": 100,
        "def": 135,
        "spa": 65,
        "spd": 135,
        "spe": 45
      },
      "abilities": {"0": "Pressure", "H": "Frisk"},
      "weightkg": 106.6,
      "prevo": "Dusclops",
      "evoType": "trade",
      "evoItem": "Reaper Cloth",
      "eggGroups": ["Amorphous"]
    },
    "froslass": {
      "num": 478,
      "name": "Froslass",
      "types": ["Ice", "Ghost"],
      "gender": "F",
      "baseStats": {
        "hp": 70,
        "atk": 80,
        "def": 70,
        "spa": 80,
        "spd": 70,
        "spe": 110
      },
      "abilities": {"0": "Snow Cloak", "H": "Cursed Body"},
      "weightkg": 26.6,
      "prevo": "Snorunt",
      "evoType": "useItem",
      "evoItem": "Dawn Stone",
      "eggGroups": ["Fairy", "Mineral"]
    },
    "rotom": {
      "num": 479,
      "name": "Rotom",
      "types": ["Electric", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 77,
        "spa": 95,
        "spd": 77,
        "spe": 91
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "eggGroups": ["Amorphous"],
      "otherFormes": [
        "Rotom-Heat",
        "Rotom-Wash",
        "Rotom-Frost",
        "Rotom-Fan",
        "Rotom-Mow"
      ],
      "formeOrder": [
        "Rotom",
        "Rotom-Heat",
        "Rotom-Wash",
        "Rotom-Frost",
        "Rotom-Fan",
        "Rotom-Mow"
      ]
    },
    "rotomheat": {
      "num": 479,
      "name": "Rotom-Heat",
      "baseSpecies": "Rotom",
      "forme": "Heat",
      "types": ["Electric", "Fire"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 107,
        "spa": 105,
        "spd": 107,
        "spe": 86
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "eggGroups": ["Amorphous"],
      "changesFrom": "Rotom"
    },
    "rotomwash": {
      "num": 479,
      "name": "Rotom-Wash",
      "baseSpecies": "Rotom",
      "forme": "Wash",
      "types": ["Electric", "Water"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 107,
        "spa": 105,
        "spd": 107,
        "spe": 86
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "eggGroups": ["Amorphous"],
      "changesFrom": "Rotom"
    },
    "rotomfrost": {
      "num": 479,
      "name": "Rotom-Frost",
      "baseSpecies": "Rotom",
      "forme": "Frost",
      "types": ["Electric", "Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 107,
        "spa": 105,
        "spd": 107,
        "spe": 86
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "eggGroups": ["Amorphous"],
      "changesFrom": "Rotom"
    },
    "rotomfan": {
      "num": 479,
      "name": "Rotom-Fan",
      "baseSpecies": "Rotom",
      "forme": "Fan",
      "types": ["Electric", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 107,
        "spa": 105,
        "spd": 107,
        "spe": 86
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "eggGroups": ["Amorphous"],
      "changesFrom": "Rotom"
    },
    "rotommow": {
      "num": 479,
      "name": "Rotom-Mow",
      "baseSpecies": "Rotom",
      "forme": "Mow",
      "types": ["Electric", "Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 107,
        "spa": 105,
        "spd": 107,
        "spe": 86
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "eggGroups": ["Amorphous"],
      "changesFrom": "Rotom"
    },
    "uxie": {
      "num": 480,
      "name": "Uxie",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 75,
        "atk": 75,
        "def": 130,
        "spa": 75,
        "spd": 130,
        "spe": 95
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "mesprit": {
      "num": 481,
      "name": "Mesprit",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 105,
        "def": 105,
        "spa": 105,
        "spd": 105,
        "spe": 80
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "azelf": {
      "num": 482,
      "name": "Azelf",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 75,
        "atk": 125,
        "def": 70,
        "spa": 125,
        "spd": 70,
        "spe": 115
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "dialga": {
      "num": 483,
      "name": "Dialga",
      "types": ["Steel", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 120,
        "def": 120,
        "spa": 150,
        "spd": 100,
        "spe": 90
      },
      "abilities": {"0": "Pressure", "H": "Telepathy"},
      "weightkg": 683,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Dialga-Origin"],
      "formeOrder": ["Dialga", "Dialga-Origin"]
    },
    "dialgaorigin": {
      "num": 483,
      "name": "Dialga-Origin",
      "baseSpecies": "Dialga",
      "forme": "Origin",
      "types": ["Steel", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 120,
        "spa": 150,
        "spd": 120,
        "spe": 90
      },
      "abilities": {"0": "Pressure", "H": "Telepathy"},
      "weightkg": 850,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Adamant Crystal",
      "changesFrom": "Dialga",
      "gen": 8
    },
    "palkia": {
      "num": 484,
      "name": "Palkia",
      "types": ["Water", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 120,
        "def": 100,
        "spa": 150,
        "spd": 120,
        "spe": 100
      },
      "abilities": {"0": "Pressure", "H": "Telepathy"},
      "weightkg": 336,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Palkia-Origin"],
      "formeOrder": ["Palkia", "Palkia-Origin"]
    },
    "palkiaorigin": {
      "num": 484,
      "name": "Palkia-Origin",
      "baseSpecies": "Palkia",
      "forme": "Origin",
      "types": ["Water", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 100,
        "def": 100,
        "spa": 150,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Pressure", "H": "Telepathy"},
      "weightkg": 660,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Lustrous Globe",
      "changesFrom": "Palkia",
      "gen": 8
    },
    "heatran": {
      "num": 485,
      "name": "Heatran",
      "types": ["Fire", "Steel"],
      "baseStats": {
        "hp": 91,
        "atk": 90,
        "def": 106,
        "spa": 130,
        "spd": 106,
        "spe": 77
      },
      "abilities": {"0": "Flash Fire", "H": "Flame Body"},
      "weightkg": 430,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "regigigas": {
      "num": 486,
      "name": "Regigigas",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 110,
        "atk": 160,
        "def": 110,
        "spa": 80,
        "spd": 110,
        "spe": 100
      },
      "abilities": {"0": "Slow Start"},
      "weightkg": 420,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "giratina": {
      "num": 487,
      "name": "Giratina",
      "baseForme": "Altered",
      "types": ["Ghost", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 150,
        "atk": 100,
        "def": 120,
        "spa": 100,
        "spd": 120,
        "spe": 90
      },
      "abilities": {"0": "Pressure", "H": "Telepathy"},
      "weightkg": 750,
      "eggGroups": ["Undiscovered"],
      "tags": ["Restricted Legendary"],
      "otherFormes": ["Giratina-Origin"],
      "formeOrder": ["Giratina", "Giratina-Origin"]
    },
    "giratinaorigin": {
      "num": 487,
      "name": "Giratina-Origin",
      "baseSpecies": "Giratina",
      "forme": "Origin",
      "types": ["Ghost", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 150,
        "atk": 120,
        "def": 100,
        "spa": 120,
        "spd": 100,
        "spe": 90
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 650,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Griseous Core",
      "changesFrom": "Giratina"
    },
    "cresselia": {
      "num": 488,
      "name": "Cresselia",
      "types": ["Psychic"],
      "gender": "F",
      "baseStats": {
        "hp": 120,
        "atk": 70,
        "def": 110,
        "spa": 75,
        "spd": 120,
        "spe": 85
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 85.6,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "phione": {
      "num": 489,
      "name": "Phione",
      "types": ["Water"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 80,
        "def": 80,
        "spa": 80,
        "spd": 80,
        "spe": 80
      },
      "abilities": {"0": "Hydration"},
      "weightkg": 3.1,
      "tags": ["Mythical"],
      "eggGroups": ["Water 1", "Fairy"]
    },
    "manaphy": {
      "num": 490,
      "name": "Manaphy",
      "types": ["Water"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Hydration"},
      "weightkg": 1.4,
      "tags": ["Mythical"],
      "eggGroups": ["Water 1", "Fairy"]
    },
    "darkrai": {
      "num": 491,
      "name": "Darkrai",
      "types": ["Dark"],
      "gender": "N",
      "baseStats": {
        "hp": 70,
        "atk": 90,
        "def": 90,
        "spa": 135,
        "spd": 90,
        "spe": 125
      },
      "abilities": {"0": "Bad Dreams"},
      "weightkg": 50.5,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"]
    },
    "shaymin": {
      "num": 492,
      "name": "Shaymin",
      "baseForme": "Land",
      "types": ["Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Natural Cure"},
      "weightkg": 2.1,
      "eggGroups": ["Undiscovered"],
      "tags": ["Mythical"],
      "otherFormes": ["Shaymin-Sky"],
      "formeOrder": ["Shaymin", "Shaymin-Sky"]
    },
    "shayminsky": {
      "num": 492,
      "name": "Shaymin-Sky",
      "baseSpecies": "Shaymin",
      "forme": "Sky",
      "types": ["Grass", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 103,
        "def": 75,
        "spa": 120,
        "spd": 75,
        "spe": 127
      },
      "abilities": {"0": "Serene Grace"},
      "weightkg": 5.2,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Shaymin"
    },
    "arceus": {
      "num": 493,
      "name": "Arceus",
      "baseForme": "Normal",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": [
        "Arceus-Bug",
        "Arceus-Dark",
        "Arceus-Dragon",
        "Arceus-Electric",
        "Arceus-Fairy",
        "Arceus-Fighting",
        "Arceus-Fire",
        "Arceus-Flying",
        "Arceus-Ghost",
        "Arceus-Grass",
        "Arceus-Ground",
        "Arceus-Ice",
        "Arceus-Poison",
        "Arceus-Psychic",
        "Arceus-Rock",
        "Arceus-Steel",
        "Arceus-Water"
      ],
      "formeOrder": [
        "Arceus",
        "Arceus-Fighting",
        "Arceus-Flying",
        "Arceus-Poison",
        "Arceus-Ground",
        "Arceus-Rock",
        "Arceus-Bug",
        "Arceus-Ghost",
        "Arceus-Steel",
        "Arceus-Fire",
        "Arceus-Water",
        "Arceus-Grass",
        "Arceus-Electric",
        "Arceus-Psychic",
        "Arceus-Ice",
        "Arceus-Dragon",
        "Arceus-Dark",
        "Arceus-Fairy"
      ]
    },
    "arceusbug": {
      "num": 493,
      "name": "Arceus-Bug",
      "baseSpecies": "Arceus",
      "forme": "Bug",
      "types": ["Bug"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Insect Plate", "Buginium Z"],
      "changesFrom": "Arceus"
    },
    "arceusdark": {
      "num": 493,
      "name": "Arceus-Dark",
      "baseSpecies": "Arceus",
      "forme": "Dark",
      "types": ["Dark"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Dread Plate", "Darkinium Z"],
      "changesFrom": "Arceus"
    },
    "arceusdragon": {
      "num": 493,
      "name": "Arceus-Dragon",
      "baseSpecies": "Arceus",
      "forme": "Dragon",
      "types": ["Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Draco Plate", "Dragonium Z"],
      "changesFrom": "Arceus"
    },
    "arceuselectric": {
      "num": 493,
      "name": "Arceus-Electric",
      "baseSpecies": "Arceus",
      "forme": "Electric",
      "types": ["Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Zap Plate", "Electrium Z"],
      "changesFrom": "Arceus"
    },
    "arceusfairy": {
      "num": 493,
      "name": "Arceus-Fairy",
      "baseSpecies": "Arceus",
      "forme": "Fairy",
      "types": ["Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Pixie Plate", "Fairium Z"],
      "changesFrom": "Arceus",
      "gen": 6
    },
    "arceusfighting": {
      "num": 493,
      "name": "Arceus-Fighting",
      "baseSpecies": "Arceus",
      "forme": "Fighting",
      "types": ["Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Fist Plate", "Fightinium Z"],
      "changesFrom": "Arceus"
    },
    "arceusfire": {
      "num": 493,
      "name": "Arceus-Fire",
      "baseSpecies": "Arceus",
      "forme": "Fire",
      "types": ["Fire"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Flame Plate", "Firium Z"],
      "changesFrom": "Arceus"
    },
    "arceusflying": {
      "num": 493,
      "name": "Arceus-Flying",
      "baseSpecies": "Arceus",
      "forme": "Flying",
      "types": ["Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Sky Plate", "Flyinium Z"],
      "changesFrom": "Arceus"
    },
    "arceusghost": {
      "num": 493,
      "name": "Arceus-Ghost",
      "baseSpecies": "Arceus",
      "forme": "Ghost",
      "types": ["Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Spooky Plate", "Ghostium Z"],
      "changesFrom": "Arceus"
    },
    "arceusgrass": {
      "num": 493,
      "name": "Arceus-Grass",
      "baseSpecies": "Arceus",
      "forme": "Grass",
      "types": ["Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Meadow Plate", "Grassium Z"],
      "changesFrom": "Arceus"
    },
    "arceusground": {
      "num": 493,
      "name": "Arceus-Ground",
      "baseSpecies": "Arceus",
      "forme": "Ground",
      "types": ["Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Earth Plate", "Groundium Z"],
      "changesFrom": "Arceus"
    },
    "arceusice": {
      "num": 493,
      "name": "Arceus-Ice",
      "baseSpecies": "Arceus",
      "forme": "Ice",
      "types": ["Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Icicle Plate", "Icium Z"],
      "changesFrom": "Arceus"
    },
    "arceuspoison": {
      "num": 493,
      "name": "Arceus-Poison",
      "baseSpecies": "Arceus",
      "forme": "Poison",
      "types": ["Poison"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Toxic Plate", "Poisonium Z"],
      "changesFrom": "Arceus"
    },
    "arceuspsychic": {
      "num": 493,
      "name": "Arceus-Psychic",
      "baseSpecies": "Arceus",
      "forme": "Psychic",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Mind Plate", "Psychium Z"],
      "changesFrom": "Arceus"
    },
    "arceusrock": {
      "num": 493,
      "name": "Arceus-Rock",
      "baseSpecies": "Arceus",
      "forme": "Rock",
      "types": ["Rock"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Stone Plate", "Rockium Z"],
      "changesFrom": "Arceus"
    },
    "arceussteel": {
      "num": 493,
      "name": "Arceus-Steel",
      "baseSpecies": "Arceus",
      "forme": "Steel",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Iron Plate", "Steelium Z"],
      "changesFrom": "Arceus"
    },
    "arceuswater": {
      "num": 493,
      "name": "Arceus-Water",
      "baseSpecies": "Arceus",
      "forme": "Water",
      "types": ["Water"],
      "gender": "N",
      "baseStats": {
        "hp": 120,
        "atk": 120,
        "def": 120,
        "spa": 120,
        "spd": 120,
        "spe": 120
      },
      "abilities": {"0": "Multitype"},
      "weightkg": 320,
      "eggGroups": ["Undiscovered"],
      "requiredItems": ["Splash Plate", "Waterium Z"],
      "changesFrom": "Arceus"
    },
    "victini": {
      "num": 494,
      "name": "Victini",
      "types": ["Psychic", "Fire"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Victory Star"},
      "weightkg": 4,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"]
    },
    "snivy": {
      "num": 495,
      "name": "Snivy",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 45,
        "atk": 45,
        "def": 55,
        "spa": 45,
        "spd": 55,
        "spe": 63
      },
      "abilities": {"0": "Overgrow", "H": "Contrary"},
      "weightkg": 8.1,
      "evos": ["Servine"],
      "eggGroups": ["Field", "Grass"]
    },
    "servine": {
      "num": 496,
      "name": "Servine",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 75,
        "spa": 60,
        "spd": 75,
        "spe": 83
      },
      "abilities": {"0": "Overgrow", "H": "Contrary"},
      "weightkg": 16,
      "prevo": "Snivy",
      "evoLevel": 17,
      "evos": ["Serperior"],
      "eggGroups": ["Field", "Grass"]
    },
    "serperior": {
      "num": 497,
      "name": "Serperior",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 75,
        "atk": 75,
        "def": 95,
        "spa": 75,
        "spd": 95,
        "spe": 113
      },
      "abilities": {"0": "Overgrow", "H": "Contrary"},
      "weightkg": 63,
      "prevo": "Servine",
      "evoLevel": 36,
      "eggGroups": ["Field", "Grass"]
    },
    "tepig": {
      "num": 498,
      "name": "Tepig",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 63,
        "def": 45,
        "spa": 45,
        "spd": 45,
        "spe": 45
      },
      "abilities": {"0": "Blaze", "H": "Thick Fat"},
      "weightkg": 9.9,
      "evos": ["Pignite"],
      "eggGroups": ["Field"]
    },
    "pignite": {
      "num": 499,
      "name": "Pignite",
      "types": ["Fire", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 90,
        "atk": 93,
        "def": 55,
        "spa": 70,
        "spd": 55,
        "spe": 55
      },
      "abilities": {"0": "Blaze", "H": "Thick Fat"},
      "weightkg": 55.5,
      "prevo": "Tepig",
      "evoLevel": 17,
      "evos": ["Emboar"],
      "eggGroups": ["Field"]
    },
    "emboar": {
      "num": 500,
      "name": "Emboar",
      "types": ["Fire", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 110,
        "atk": 123,
        "def": 65,
        "spa": 100,
        "spd": 65,
        "spe": 65
      },
      "abilities": {"0": "Blaze", "H": "Reckless"},
      "weightkg": 150,
      "prevo": "Pignite",
      "evoLevel": 36,
      "eggGroups": ["Field"]
    },
    "oshawott": {
      "num": 501,
      "name": "Oshawott",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 55,
        "atk": 55,
        "def": 45,
        "spa": 63,
        "spd": 45,
        "spe": 45
      },
      "abilities": {"0": "Torrent", "H": "Shell Armor"},
      "weightkg": 5.9,
      "evos": ["Dewott"],
      "eggGroups": ["Field"]
    },
    "dewott": {
      "num": 502,
      "name": "Dewott",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 75,
        "atk": 75,
        "def": 60,
        "spa": 83,
        "spd": 60,
        "spe": 60
      },
      "abilities": {"0": "Torrent", "H": "Shell Armor"},
      "weightkg": 24.5,
      "prevo": "Oshawott",
      "evoLevel": 17,
      "evos": ["Samurott", "Samurott-Hisui"],
      "eggGroups": ["Field"]
    },
    "samurott": {
      "num": 503,
      "name": "Samurott",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 95,
        "atk": 100,
        "def": 85,
        "spa": 108,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Torrent", "H": "Shell Armor"},
      "weightkg": 94.6,
      "prevo": "Dewott",
      "evoLevel": 36,
      "eggGroups": ["Field"],
      "otherFormes": ["Samurott-Hisui"],
      "formeOrder": ["Samurott", "Samurott-Hisui"]
    },
    "samurotthisui": {
      "num": 503,
      "name": "Samurott-Hisui",
      "baseSpecies": "Samurott",
      "forme": "Hisui",
      "types": ["Water", "Dark"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 90,
        "atk": 108,
        "def": 80,
        "spa": 100,
        "spd": 65,
        "spe": 85
      },
      "abilities": {"0": "Torrent", "H": "Sharpness"},
      "weightkg": 58.2,
      "prevo": "Dewott",
      "evoLevel": 36,
      "eggGroups": ["Field"]
    },
    "patrat": {
      "num": 504,
      "name": "Patrat",
      "types": ["Normal"],
      "baseStats": {
        "hp": 45,
        "atk": 55,
        "def": 39,
        "spa": 35,
        "spd": 39,
        "spe": 42
      },
      "abilities": {"0": "Run Away", "1": "Keen Eye", "H": "Analytic"},
      "weightkg": 11.6,
      "evos": ["Watchog"],
      "eggGroups": ["Field"]
    },
    "watchog": {
      "num": 505,
      "name": "Watchog",
      "types": ["Normal"],
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 69,
        "spa": 60,
        "spd": 69,
        "spe": 77
      },
      "abilities": {"0": "Illuminate", "1": "Keen Eye", "H": "Analytic"},
      "weightkg": 27,
      "prevo": "Patrat",
      "evoLevel": 20,
      "eggGroups": ["Field"]
    },
    "lillipup": {
      "num": 506,
      "name": "Lillipup",
      "types": ["Normal"],
      "baseStats": {
        "hp": 45,
        "atk": 60,
        "def": 45,
        "spa": 25,
        "spd": 45,
        "spe": 55
      },
      "abilities": {"0": "Vital Spirit", "1": "Pickup", "H": "Run Away"},
      "weightkg": 4.1,
      "evos": ["Herdier"],
      "eggGroups": ["Field"]
    },
    "herdier": {
      "num": 507,
      "name": "Herdier",
      "types": ["Normal"],
      "baseStats": {
        "hp": 65,
        "atk": 80,
        "def": 65,
        "spa": 35,
        "spd": 65,
        "spe": 60
      },
      "abilities": {"0": "Intimidate", "1": "Sand Rush", "H": "Scrappy"},
      "weightkg": 14.7,
      "prevo": "Lillipup",
      "evoLevel": 16,
      "evos": ["Stoutland"],
      "eggGroups": ["Field"]
    },
    "stoutland": {
      "num": 508,
      "name": "Stoutland",
      "types": ["Normal"],
      "baseStats": {
        "hp": 85,
        "atk": 110,
        "def": 90,
        "spa": 45,
        "spd": 90,
        "spe": 80
      },
      "abilities": {"0": "Intimidate", "1": "Sand Rush", "H": "Scrappy"},
      "weightkg": 61,
      "prevo": "Herdier",
      "evoLevel": 32,
      "eggGroups": ["Field"]
    },
    "purrloin": {
      "num": 509,
      "name": "Purrloin",
      "types": ["Dark"],
      "baseStats": {
        "hp": 41,
        "atk": 50,
        "def": 37,
        "spa": 50,
        "spd": 37,
        "spe": 66
      },
      "abilities": {"0": "Limber", "1": "Unburden", "H": "Prankster"},
      "weightkg": 10.1,
      "evos": ["Liepard"],
      "eggGroups": ["Field"]
    },
    "liepard": {
      "num": 510,
      "name": "Liepard",
      "types": ["Dark"],
      "baseStats": {
        "hp": 64,
        "atk": 88,
        "def": 50,
        "spa": 88,
        "spd": 50,
        "spe": 106
      },
      "abilities": {"0": "Limber", "1": "Unburden", "H": "Prankster"},
      "weightkg": 37.5,
      "prevo": "Purrloin",
      "evoLevel": 20,
      "eggGroups": ["Field"]
    },
    "pansage": {
      "num": 511,
      "name": "Pansage",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 53,
        "def": 48,
        "spa": 53,
        "spd": 48,
        "spe": 64
      },
      "abilities": {"0": "Gluttony", "H": "Overgrow"},
      "weightkg": 10.5,
      "evos": ["Simisage"],
      "eggGroups": ["Field"]
    },
    "simisage": {
      "num": 512,
      "name": "Simisage",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 75,
        "atk": 98,
        "def": 63,
        "spa": 98,
        "spd": 63,
        "spe": 101
      },
      "abilities": {"0": "Gluttony", "H": "Overgrow"},
      "weightkg": 30.5,
      "prevo": "Pansage",
      "evoType": "useItem",
      "evoItem": "Leaf Stone",
      "eggGroups": ["Field"]
    },
    "pansear": {
      "num": 513,
      "name": "Pansear",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 53,
        "def": 48,
        "spa": 53,
        "spd": 48,
        "spe": 64
      },
      "abilities": {"0": "Gluttony", "H": "Blaze"},
      "weightkg": 11,
      "evos": ["Simisear"],
      "eggGroups": ["Field"]
    },
    "simisear": {
      "num": 514,
      "name": "Simisear",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 75,
        "atk": 98,
        "def": 63,
        "spa": 98,
        "spd": 63,
        "spe": 101
      },
      "abilities": {"0": "Gluttony", "H": "Blaze"},
      "weightkg": 28,
      "prevo": "Pansear",
      "evoType": "useItem",
      "evoItem": "Fire Stone",
      "eggGroups": ["Field"]
    },
    "panpour": {
      "num": 515,
      "name": "Panpour",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 53,
        "def": 48,
        "spa": 53,
        "spd": 48,
        "spe": 64
      },
      "abilities": {"0": "Gluttony", "H": "Torrent"},
      "weightkg": 13.5,
      "evos": ["Simipour"],
      "eggGroups": ["Field"]
    },
    "simipour": {
      "num": 516,
      "name": "Simipour",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 75,
        "atk": 98,
        "def": 63,
        "spa": 98,
        "spd": 63,
        "spe": 101
      },
      "abilities": {"0": "Gluttony", "H": "Torrent"},
      "weightkg": 29,
      "prevo": "Panpour",
      "evoType": "useItem",
      "evoItem": "Water Stone",
      "eggGroups": ["Field"]
    },
    "munna": {
      "num": 517,
      "name": "Munna",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 76,
        "atk": 25,
        "def": 45,
        "spa": 67,
        "spd": 55,
        "spe": 24
      },
      "abilities": {"0": "Forewarn", "1": "Synchronize", "H": "Telepathy"},
      "weightkg": 23.3,
      "evos": ["Musharna"],
      "eggGroups": ["Field"]
    },
    "musharna": {
      "num": 518,
      "name": "Musharna",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 116,
        "atk": 55,
        "def": 85,
        "spa": 107,
        "spd": 95,
        "spe": 29
      },
      "abilities": {"0": "Forewarn", "1": "Synchronize", "H": "Telepathy"},
      "weightkg": 60.5,
      "prevo": "Munna",
      "evoType": "useItem",
      "evoItem": "Moon Stone",
      "eggGroups": ["Field"]
    },
    "pidove": {
      "num": 519,
      "name": "Pidove",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 50,
        "atk": 55,
        "def": 50,
        "spa": 36,
        "spd": 30,
        "spe": 43
      },
      "abilities": {"0": "Big Pecks", "1": "Super Luck", "H": "Rivalry"},
      "weightkg": 2.1,
      "evos": ["Tranquill"],
      "eggGroups": ["Flying"]
    },
    "tranquill": {
      "num": 520,
      "name": "Tranquill",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 62,
        "atk": 77,
        "def": 62,
        "spa": 50,
        "spd": 42,
        "spe": 65
      },
      "abilities": {"0": "Big Pecks", "1": "Super Luck", "H": "Rivalry"},
      "weightkg": 15,
      "prevo": "Pidove",
      "evoLevel": 21,
      "evos": ["Unfezant"],
      "eggGroups": ["Flying"]
    },
    "unfezant": {
      "num": 521,
      "name": "Unfezant",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 80,
        "atk": 115,
        "def": 80,
        "spa": 65,
        "spd": 55,
        "spe": 93
      },
      "abilities": {"0": "Big Pecks", "1": "Super Luck", "H": "Rivalry"},
      "weightkg": 29,
      "prevo": "Tranquill",
      "evoLevel": 32,
      "eggGroups": ["Flying"]
    },
    "blitzle": {
      "num": 522,
      "name": "Blitzle",
      "types": ["Electric"],
      "baseStats": {
        "hp": 45,
        "atk": 60,
        "def": 32,
        "spa": 50,
        "spd": 32,
        "spe": 76
      },
      "abilities": {
        "0": "Lightning Rod",
        "1": "Motor Drive",
        "H": "Sap Sipper"
      },
      "weightkg": 29.8,
      "evos": ["Zebstrika"],
      "eggGroups": ["Field"]
    },
    "zebstrika": {
      "num": 523,
      "name": "Zebstrika",
      "types": ["Electric"],
      "baseStats": {
        "hp": 75,
        "atk": 100,
        "def": 63,
        "spa": 80,
        "spd": 63,
        "spe": 116
      },
      "abilities": {
        "0": "Lightning Rod",
        "1": "Motor Drive",
        "H": "Sap Sipper"
      },
      "weightkg": 79.5,
      "prevo": "Blitzle",
      "evoLevel": 27,
      "eggGroups": ["Field"]
    },
    "roggenrola": {
      "num": 524,
      "name": "Roggenrola",
      "types": ["Rock"],
      "baseStats": {
        "hp": 55,
        "atk": 75,
        "def": 85,
        "spa": 25,
        "spd": 25,
        "spe": 15
      },
      "abilities": {"0": "Sturdy", "1": "Weak Armor", "H": "Sand Force"},
      "weightkg": 18,
      "evos": ["Boldore"],
      "eggGroups": ["Mineral"]
    },
    "boldore": {
      "num": 525,
      "name": "Boldore",
      "types": ["Rock"],
      "baseStats": {
        "hp": 70,
        "atk": 105,
        "def": 105,
        "spa": 50,
        "spd": 40,
        "spe": 20
      },
      "abilities": {"0": "Sturdy", "1": "Weak Armor", "H": "Sand Force"},
      "weightkg": 102,
      "prevo": "Roggenrola",
      "evoLevel": 25,
      "evos": ["Gigalith"],
      "eggGroups": ["Mineral"]
    },
    "gigalith": {
      "num": 526,
      "name": "Gigalith",
      "types": ["Rock"],
      "baseStats": {
        "hp": 85,
        "atk": 135,
        "def": 130,
        "spa": 60,
        "spd": 80,
        "spe": 25
      },
      "abilities": {"0": "Sturdy", "1": "Sand Stream", "H": "Sand Force"},
      "weightkg": 260,
      "prevo": "Boldore",
      "evoType": "trade",
      "eggGroups": ["Mineral"]
    },
    "woobat": {
      "num": 527,
      "name": "Woobat",
      "types": ["Psychic", "Flying"],
      "baseStats": {
        "hp": 65,
        "atk": 45,
        "def": 43,
        "spa": 55,
        "spd": 43,
        "spe": 72
      },
      "abilities": {"0": "Unaware", "1": "Klutz", "H": "Simple"},
      "weightkg": 2.1,
      "evos": ["Swoobat"],
      "eggGroups": ["Flying", "Field"]
    },
    "swoobat": {
      "num": 528,
      "name": "Swoobat",
      "types": ["Psychic", "Flying"],
      "baseStats": {
        "hp": 67,
        "atk": 57,
        "def": 55,
        "spa": 77,
        "spd": 55,
        "spe": 114
      },
      "abilities": {"0": "Unaware", "1": "Klutz", "H": "Simple"},
      "weightkg": 10.5,
      "prevo": "Woobat",
      "evoType": "levelFriendship",
      "eggGroups": ["Flying", "Field"]
    },
    "drilbur": {
      "num": 529,
      "name": "Drilbur",
      "types": ["Ground"],
      "baseStats": {
        "hp": 60,
        "atk": 85,
        "def": 40,
        "spa": 30,
        "spd": 45,
        "spe": 68
      },
      "abilities": {"0": "Sand Rush", "1": "Sand Force", "H": "Mold Breaker"},
      "weightkg": 8.5,
      "evos": ["Excadrill"],
      "eggGroups": ["Field"]
    },
    "excadrill": {
      "num": 530,
      "name": "Excadrill",
      "types": ["Ground", "Steel"],
      "baseStats": {
        "hp": 110,
        "atk": 135,
        "def": 60,
        "spa": 50,
        "spd": 65,
        "spe": 88
      },
      "abilities": {"0": "Sand Rush", "1": "Sand Force", "H": "Mold Breaker"},
      "weightkg": 40.4,
      "prevo": "Drilbur",
      "evoLevel": 31,
      "eggGroups": ["Field"]
    },
    "audino": {
      "num": 531,
      "name": "Audino",
      "types": ["Normal"],
      "baseStats": {
        "hp": 103,
        "atk": 60,
        "def": 86,
        "spa": 60,
        "spd": 86,
        "spe": 50
      },
      "abilities": {"0": "Healer", "1": "Regenerator", "H": "Klutz"},
      "weightkg": 31,
      "eggGroups": ["Fairy"],
      "otherFormes": ["Audino-Mega"],
      "formeOrder": ["Audino", "Audino-Mega"]
    },
    "audinomega": {
      "num": 531,
      "name": "Audino-Mega",
      "baseSpecies": "Audino",
      "forme": "Mega",
      "types": ["Normal", "Fairy"],
      "baseStats": {
        "hp": 103,
        "atk": 60,
        "def": 126,
        "spa": 80,
        "spd": 126,
        "spe": 50
      },
      "abilities": {"0": "Healer"},
      "weightkg": 32,
      "eggGroups": ["Fairy"],
      "requiredItem": "Audinite"
    },
    "timburr": {
      "num": 532,
      "name": "Timburr",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 55,
        "spa": 25,
        "spd": 35,
        "spe": 35
      },
      "abilities": {"0": "Guts", "1": "Sheer Force", "H": "Iron Fist"},
      "weightkg": 12.5,
      "evos": ["Gurdurr"],
      "eggGroups": ["Human-Like"]
    },
    "gurdurr": {
      "num": 533,
      "name": "Gurdurr",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 85,
        "atk": 105,
        "def": 85,
        "spa": 40,
        "spd": 50,
        "spe": 40
      },
      "abilities": {"0": "Guts", "1": "Sheer Force", "H": "Iron Fist"},
      "weightkg": 40,
      "prevo": "Timburr",
      "evoLevel": 25,
      "evos": ["Conkeldurr"],
      "eggGroups": ["Human-Like"]
    },
    "conkeldurr": {
      "num": 534,
      "name": "Conkeldurr",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 105,
        "atk": 140,
        "def": 95,
        "spa": 55,
        "spd": 65,
        "spe": 45
      },
      "abilities": {"0": "Guts", "1": "Sheer Force", "H": "Iron Fist"},
      "weightkg": 87,
      "prevo": "Gurdurr",
      "evoType": "trade",
      "eggGroups": ["Human-Like"]
    },
    "tympole": {
      "num": 535,
      "name": "Tympole",
      "types": ["Water"],
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 40,
        "spa": 50,
        "spd": 40,
        "spe": 64
      },
      "abilities": {"0": "Swift Swim", "1": "Hydration", "H": "Water Absorb"},
      "weightkg": 4.5,
      "evos": ["Palpitoad"],
      "eggGroups": ["Water 1"]
    },
    "palpitoad": {
      "num": 536,
      "name": "Palpitoad",
      "types": ["Water", "Ground"],
      "baseStats": {
        "hp": 75,
        "atk": 65,
        "def": 55,
        "spa": 65,
        "spd": 55,
        "spe": 69
      },
      "abilities": {"0": "Swift Swim", "1": "Hydration", "H": "Water Absorb"},
      "weightkg": 17,
      "prevo": "Tympole",
      "evoLevel": 25,
      "evos": ["Seismitoad"],
      "eggGroups": ["Water 1"]
    },
    "seismitoad": {
      "num": 537,
      "name": "Seismitoad",
      "types": ["Water", "Ground"],
      "baseStats": {
        "hp": 105,
        "atk": 95,
        "def": 75,
        "spa": 85,
        "spd": 75,
        "spe": 74
      },
      "abilities": {
        "0": "Swift Swim",
        "1": "Poison Touch",
        "H": "Water Absorb"
      },
      "weightkg": 62,
      "prevo": "Palpitoad",
      "evoLevel": 36,
      "eggGroups": ["Water 1"]
    },
    "throh": {
      "num": 538,
      "name": "Throh",
      "types": ["Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 120,
        "atk": 100,
        "def": 85,
        "spa": 30,
        "spd": 85,
        "spe": 45
      },
      "abilities": {"0": "Guts", "1": "Inner Focus", "H": "Mold Breaker"},
      "weightkg": 55.5,
      "eggGroups": ["Human-Like"]
    },
    "sawk": {
      "num": 539,
      "name": "Sawk",
      "types": ["Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 75,
        "atk": 125,
        "def": 75,
        "spa": 30,
        "spd": 75,
        "spe": 85
      },
      "abilities": {"0": "Sturdy", "1": "Inner Focus", "H": "Mold Breaker"},
      "weightkg": 51,
      "eggGroups": ["Human-Like"]
    },
    "sewaddle": {
      "num": 540,
      "name": "Sewaddle",
      "types": ["Bug", "Grass"],
      "baseStats": {
        "hp": 45,
        "atk": 53,
        "def": 70,
        "spa": 40,
        "spd": 60,
        "spe": 42
      },
      "abilities": {"0": "Swarm", "1": "Chlorophyll", "H": "Overcoat"},
      "weightkg": 2.5,
      "evos": ["Swadloon"],
      "eggGroups": ["Bug"]
    },
    "swadloon": {
      "num": 541,
      "name": "Swadloon",
      "types": ["Bug", "Grass"],
      "baseStats": {
        "hp": 55,
        "atk": 63,
        "def": 90,
        "spa": 50,
        "spd": 80,
        "spe": 42
      },
      "abilities": {"0": "Leaf Guard", "1": "Chlorophyll", "H": "Overcoat"},
      "weightkg": 7.3,
      "prevo": "Sewaddle",
      "evoLevel": 20,
      "evos": ["Leavanny"],
      "eggGroups": ["Bug"]
    },
    "leavanny": {
      "num": 542,
      "name": "Leavanny",
      "types": ["Bug", "Grass"],
      "baseStats": {
        "hp": 75,
        "atk": 103,
        "def": 80,
        "spa": 70,
        "spd": 80,
        "spe": 92
      },
      "abilities": {"0": "Swarm", "1": "Chlorophyll", "H": "Overcoat"},
      "weightkg": 20.5,
      "prevo": "Swadloon",
      "evoType": "levelFriendship",
      "eggGroups": ["Bug"]
    },
    "venipede": {
      "num": 543,
      "name": "Venipede",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 30,
        "atk": 45,
        "def": 59,
        "spa": 30,
        "spd": 39,
        "spe": 57
      },
      "abilities": {"0": "Poison Point", "1": "Swarm", "H": "Speed Boost"},
      "weightkg": 5.3,
      "evos": ["Whirlipede"],
      "eggGroups": ["Bug"]
    },
    "whirlipede": {
      "num": 544,
      "name": "Whirlipede",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 40,
        "atk": 55,
        "def": 99,
        "spa": 40,
        "spd": 79,
        "spe": 47
      },
      "abilities": {"0": "Poison Point", "1": "Swarm", "H": "Speed Boost"},
      "weightkg": 58.5,
      "prevo": "Venipede",
      "evoLevel": 22,
      "evos": ["Scolipede"],
      "eggGroups": ["Bug"]
    },
    "scolipede": {
      "num": 545,
      "name": "Scolipede",
      "types": ["Bug", "Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 100,
        "def": 89,
        "spa": 55,
        "spd": 69,
        "spe": 112
      },
      "abilities": {"0": "Poison Point", "1": "Swarm", "H": "Speed Boost"},
      "weightkg": 200.5,
      "prevo": "Whirlipede",
      "evoLevel": 30,
      "eggGroups": ["Bug"]
    },
    "cottonee": {
      "num": 546,
      "name": "Cottonee",
      "types": ["Grass", "Fairy"],
      "baseStats": {
        "hp": 40,
        "atk": 27,
        "def": 60,
        "spa": 37,
        "spd": 50,
        "spe": 66
      },
      "abilities": {"0": "Prankster", "1": "Infiltrator", "H": "Chlorophyll"},
      "weightkg": 0.6,
      "evos": ["Whimsicott"],
      "eggGroups": ["Fairy", "Grass"]
    },
    "whimsicott": {
      "num": 547,
      "name": "Whimsicott",
      "types": ["Grass", "Fairy"],
      "baseStats": {
        "hp": 60,
        "atk": 67,
        "def": 85,
        "spa": 77,
        "spd": 75,
        "spe": 116
      },
      "abilities": {"0": "Prankster", "1": "Infiltrator", "H": "Chlorophyll"},
      "weightkg": 6.6,
      "prevo": "Cottonee",
      "evoType": "useItem",
      "evoItem": "Sun Stone",
      "eggGroups": ["Fairy", "Grass"]
    },
    "petilil": {
      "num": 548,
      "name": "Petilil",
      "types": ["Grass"],
      "gender": "F",
      "baseStats": {
        "hp": 45,
        "atk": 35,
        "def": 50,
        "spa": 70,
        "spd": 50,
        "spe": 30
      },
      "abilities": {"0": "Chlorophyll", "1": "Own Tempo", "H": "Leaf Guard"},
      "weightkg": 6.6,
      "evos": ["Lilligant", "Lilligant-Hisui"],
      "eggGroups": ["Grass"]
    },
    "lilligant": {
      "num": 549,
      "name": "Lilligant",
      "types": ["Grass"],
      "gender": "F",
      "baseStats": {
        "hp": 70,
        "atk": 60,
        "def": 75,
        "spa": 110,
        "spd": 75,
        "spe": 90
      },
      "abilities": {"0": "Chlorophyll", "1": "Own Tempo", "H": "Leaf Guard"},
      "weightkg": 16.3,
      "prevo": "Petilil",
      "evoType": "useItem",
      "evoItem": "Sun Stone",
      "eggGroups": ["Grass"],
      "otherFormes": ["Lilligant-Hisui"],
      "formeOrder": ["Lilligant", "Lilligant-Hisui"]
    },
    "lilliganthisui": {
      "num": 549,
      "name": "Lilligant-Hisui",
      "baseSpecies": "Lilligant",
      "forme": "Hisui",
      "types": ["Grass", "Fighting"],
      "gender": "F",
      "baseStats": {
        "hp": 70,
        "atk": 105,
        "def": 75,
        "spa": 50,
        "spd": 75,
        "spe": 105
      },
      "abilities": {"0": "Chlorophyll", "1": "Hustle", "H": "Leaf Guard"},
      "weightkg": 19.2,
      "prevo": "Petilil",
      "evoType": "useItem",
      "evoItem": "Sun Stone",
      "eggGroups": ["Grass"]
    },
    "basculin": {
      "num": 550,
      "name": "Basculin",
      "baseForme": "Red-Striped",
      "types": ["Water"],
      "baseStats": {
        "hp": 70,
        "atk": 92,
        "def": 65,
        "spa": 80,
        "spd": 55,
        "spe": 98
      },
      "abilities": {"0": "Reckless", "1": "Adaptability", "H": "Mold Breaker"},
      "weightkg": 18,
      "eggGroups": ["Water 2"],
      "otherFormes": ["Basculin-Blue-Striped", "Basculin-White-Striped"],
      "formeOrder": [
        "Basculin",
        "Basculin-Blue-Striped",
        "Basculin-White-Striped"
      ]
    },
    "basculinbluestriped": {
      "num": 550,
      "name": "Basculin-Blue-Striped",
      "baseSpecies": "Basculin",
      "forme": "Blue-Striped",
      "types": ["Water"],
      "baseStats": {
        "hp": 70,
        "atk": 92,
        "def": 65,
        "spa": 80,
        "spd": 55,
        "spe": 98
      },
      "abilities": {"0": "Rock Head", "1": "Adaptability", "H": "Mold Breaker"},
      "weightkg": 18,
      "eggGroups": ["Water 2"]
    },
    "basculinwhitestriped": {
      "num": 550,
      "name": "Basculin-White-Striped",
      "baseSpecies": "Basculin",
      "forme": "White-Striped",
      "types": ["Water"],
      "baseStats": {
        "hp": 70,
        "atk": 92,
        "def": 65,
        "spa": 80,
        "spd": 55,
        "spe": 98
      },
      "abilities": {"0": "Rattled", "1": "Adaptability", "H": "Mold Breaker"},
      "weightkg": 18,
      "evos": ["Basculegion", "Basculegion-F"],
      "eggGroups": ["Water 2"],
      "gen": 8
    },
    "sandile": {
      "num": 551,
      "name": "Sandile",
      "types": ["Ground", "Dark"],
      "baseStats": {
        "hp": 50,
        "atk": 72,
        "def": 35,
        "spa": 35,
        "spd": 35,
        "spe": 65
      },
      "abilities": {"0": "Intimidate", "1": "Moxie", "H": "Anger Point"},
      "weightkg": 15.2,
      "evos": ["Krokorok"],
      "eggGroups": ["Field"]
    },
    "krokorok": {
      "num": 552,
      "name": "Krokorok",
      "types": ["Ground", "Dark"],
      "baseStats": {
        "hp": 60,
        "atk": 82,
        "def": 45,
        "spa": 45,
        "spd": 45,
        "spe": 74
      },
      "abilities": {"0": "Intimidate", "1": "Moxie", "H": "Anger Point"},
      "weightkg": 33.4,
      "prevo": "Sandile",
      "evoLevel": 29,
      "evos": ["Krookodile"],
      "eggGroups": ["Field"]
    },
    "krookodile": {
      "num": 553,
      "name": "Krookodile",
      "types": ["Ground", "Dark"],
      "baseStats": {
        "hp": 95,
        "atk": 117,
        "def": 80,
        "spa": 65,
        "spd": 70,
        "spe": 92
      },
      "abilities": {"0": "Intimidate", "1": "Moxie", "H": "Anger Point"},
      "weightkg": 96.3,
      "prevo": "Krokorok",
      "evoLevel": 40,
      "eggGroups": ["Field"]
    },
    "darumaka": {
      "num": 554,
      "name": "Darumaka",
      "types": ["Fire"],
      "baseStats": {
        "hp": 70,
        "atk": 90,
        "def": 45,
        "spa": 15,
        "spd": 45,
        "spe": 50
      },
      "abilities": {"0": "Hustle", "H": "Inner Focus"},
      "weightkg": 37.5,
      "evos": ["Darmanitan"],
      "eggGroups": ["Field"],
      "otherFormes": ["Darumaka-Galar"],
      "formeOrder": ["Darumaka", "Darumaka-Galar"]
    },
    "darumakagalar": {
      "num": 554,
      "name": "Darumaka-Galar",
      "baseSpecies": "Darumaka",
      "forme": "Galar",
      "types": ["Ice"],
      "baseStats": {
        "hp": 70,
        "atk": 90,
        "def": 45,
        "spa": 15,
        "spd": 45,
        "spe": 50
      },
      "abilities": {"0": "Hustle", "H": "Inner Focus"},
      "weightkg": 40,
      "evos": ["Darmanitan-Galar"],
      "eggGroups": ["Field"]
    },
    "darmanitan": {
      "num": 555,
      "name": "Darmanitan",
      "baseForme": "Standard",
      "types": ["Fire"],
      "baseStats": {
        "hp": 105,
        "atk": 140,
        "def": 55,
        "spa": 30,
        "spd": 55,
        "spe": 95
      },
      "abilities": {"0": "Sheer Force", "H": "Zen Mode"},
      "weightkg": 92.9,
      "prevo": "Darumaka",
      "evoLevel": 35,
      "eggGroups": ["Field"],
      "otherFormes": [
        "Darmanitan-Zen",
        "Darmanitan-Galar",
        "Darmanitan-Galar-Zen"
      ],
      "formeOrder": [
        "Darmanitan",
        "Darmanitan-Zen",
        "Darmanitan-Galar",
        "Darmanitan-Galar-Zen"
      ]
    },
    "darmanitanzen": {
      "num": 555,
      "name": "Darmanitan-Zen",
      "baseSpecies": "Darmanitan",
      "forme": "Zen",
      "types": ["Fire", "Psychic"],
      "baseStats": {
        "hp": 105,
        "atk": 30,
        "def": 105,
        "spa": 140,
        "spd": 105,
        "spe": 55
      },
      "abilities": {"0": "Zen Mode"},
      "weightkg": 92.9,
      "eggGroups": ["Field"],
      "requiredAbility": "Zen Mode",
      "battleOnly": "Darmanitan"
    },
    "darmanitangalar": {
      "num": 555,
      "name": "Darmanitan-Galar",
      "baseSpecies": "Darmanitan",
      "forme": "Galar",
      "types": ["Ice"],
      "baseStats": {
        "hp": 105,
        "atk": 140,
        "def": 55,
        "spa": 30,
        "spd": 55,
        "spe": 95
      },
      "abilities": {"0": "Gorilla Tactics", "H": "Zen Mode"},
      "weightkg": 120,
      "prevo": "Darumaka-Galar",
      "evoType": "useItem",
      "evoItem": "Ice Stone",
      "eggGroups": ["Field"]
    },
    "darmanitangalarzen": {
      "num": 555,
      "name": "Darmanitan-Galar-Zen",
      "baseSpecies": "Darmanitan",
      "forme": "Galar-Zen",
      "types": ["Ice", "Fire"],
      "baseStats": {
        "hp": 105,
        "atk": 160,
        "def": 55,
        "spa": 30,
        "spd": 55,
        "spe": 135
      },
      "abilities": {"0": "Zen Mode"},
      "weightkg": 120,
      "eggGroups": ["Field"],
      "requiredAbility": "Zen Mode",
      "battleOnly": "Darmanitan-Galar"
    },
    "maractus": {
      "num": 556,
      "name": "Maractus",
      "types": ["Grass"],
      "baseStats": {
        "hp": 75,
        "atk": 86,
        "def": 67,
        "spa": 106,
        "spd": 67,
        "spe": 60
      },
      "abilities": {
        "0": "Water Absorb",
        "1": "Chlorophyll",
        "H": "Storm Drain"
      },
      "weightkg": 28,
      "eggGroups": ["Grass"]
    },
    "dwebble": {
      "num": 557,
      "name": "Dwebble",
      "types": ["Bug", "Rock"],
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 85,
        "spa": 35,
        "spd": 35,
        "spe": 55
      },
      "abilities": {"0": "Sturdy", "1": "Shell Armor", "H": "Weak Armor"},
      "weightkg": 14.5,
      "evos": ["Crustle"],
      "eggGroups": ["Bug", "Mineral"]
    },
    "crustle": {
      "num": 558,
      "name": "Crustle",
      "types": ["Bug", "Rock"],
      "baseStats": {
        "hp": 70,
        "atk": 105,
        "def": 125,
        "spa": 65,
        "spd": 75,
        "spe": 45
      },
      "abilities": {"0": "Sturdy", "1": "Shell Armor", "H": "Weak Armor"},
      "weightkg": 200,
      "prevo": "Dwebble",
      "evoLevel": 34,
      "eggGroups": ["Bug", "Mineral"]
    },
    "scraggy": {
      "num": 559,
      "name": "Scraggy",
      "types": ["Dark", "Fighting"],
      "baseStats": {
        "hp": 50,
        "atk": 75,
        "def": 70,
        "spa": 35,
        "spd": 70,
        "spe": 48
      },
      "abilities": {"0": "Shed Skin", "1": "Moxie", "H": "Intimidate"},
      "weightkg": 11.8,
      "evos": ["Scrafty"],
      "eggGroups": ["Field", "Dragon"]
    },
    "scrafty": {
      "num": 560,
      "name": "Scrafty",
      "types": ["Dark", "Fighting"],
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 115,
        "spa": 45,
        "spd": 115,
        "spe": 58
      },
      "abilities": {"0": "Shed Skin", "1": "Moxie", "H": "Intimidate"},
      "weightkg": 30,
      "prevo": "Scraggy",
      "evoLevel": 39,
      "eggGroups": ["Field", "Dragon"]
    },
    "sigilyph": {
      "num": 561,
      "name": "Sigilyph",
      "types": ["Psychic", "Flying"],
      "baseStats": {
        "hp": 72,
        "atk": 58,
        "def": 80,
        "spa": 103,
        "spd": 80,
        "spe": 97
      },
      "abilities": {"0": "Wonder Skin", "1": "Magic Guard", "H": "Tinted Lens"},
      "weightkg": 14,
      "eggGroups": ["Flying"]
    },
    "yamask": {
      "num": 562,
      "name": "Yamask",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 38,
        "atk": 30,
        "def": 85,
        "spa": 55,
        "spd": 65,
        "spe": 30
      },
      "abilities": {"0": "Mummy"},
      "weightkg": 1.5,
      "evos": ["Cofagrigus"],
      "eggGroups": ["Mineral", "Amorphous"],
      "otherFormes": ["Yamask-Galar"],
      "formeOrder": ["Yamask", "Yamask-Galar"]
    },
    "yamaskgalar": {
      "num": 562,
      "name": "Yamask-Galar",
      "baseSpecies": "Yamask",
      "forme": "Galar",
      "types": ["Ground", "Ghost"],
      "baseStats": {
        "hp": 38,
        "atk": 55,
        "def": 85,
        "spa": 30,
        "spd": 65,
        "spe": 30
      },
      "abilities": {"0": "Wandering Spirit"},
      "weightkg": 1.5,
      "evos": ["Runerigus"],
      "eggGroups": ["Mineral", "Amorphous"]
    },
    "cofagrigus": {
      "num": 563,
      "name": "Cofagrigus",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 58,
        "atk": 50,
        "def": 145,
        "spa": 95,
        "spd": 105,
        "spe": 30
      },
      "abilities": {"0": "Mummy"},
      "weightkg": 76.5,
      "prevo": "Yamask",
      "evoLevel": 34,
      "eggGroups": ["Mineral", "Amorphous"]
    },
    "tirtouga": {
      "num": 564,
      "name": "Tirtouga",
      "types": ["Water", "Rock"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 54,
        "atk": 78,
        "def": 103,
        "spa": 53,
        "spd": 45,
        "spe": 22
      },
      "abilities": {"0": "Solid Rock", "1": "Sturdy", "H": "Swift Swim"},
      "weightkg": 16.5,
      "evos": ["Carracosta"],
      "eggGroups": ["Water 1", "Water 3"]
    },
    "carracosta": {
      "num": 565,
      "name": "Carracosta",
      "types": ["Water", "Rock"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 74,
        "atk": 108,
        "def": 133,
        "spa": 83,
        "spd": 65,
        "spe": 32
      },
      "abilities": {"0": "Solid Rock", "1": "Sturdy", "H": "Swift Swim"},
      "weightkg": 81,
      "prevo": "Tirtouga",
      "evoLevel": 37,
      "eggGroups": ["Water 1", "Water 3"]
    },
    "archen": {
      "num": 566,
      "name": "Archen",
      "types": ["Rock", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 55,
        "atk": 112,
        "def": 45,
        "spa": 74,
        "spd": 45,
        "spe": 70
      },
      "abilities": {"0": "Defeatist"},
      "weightkg": 9.5,
      "evos": ["Archeops"],
      "eggGroups": ["Flying", "Water 3"]
    },
    "archeops": {
      "num": 567,
      "name": "Archeops",
      "types": ["Rock", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 75,
        "atk": 140,
        "def": 65,
        "spa": 112,
        "spd": 65,
        "spe": 110
      },
      "abilities": {"0": "Defeatist"},
      "weightkg": 32,
      "prevo": "Archen",
      "evoLevel": 37,
      "eggGroups": ["Flying", "Water 3"]
    },
    "trubbish": {
      "num": 568,
      "name": "Trubbish",
      "types": ["Poison"],
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 62,
        "spa": 40,
        "spd": 62,
        "spe": 65
      },
      "abilities": {"0": "Stench", "1": "Sticky Hold", "H": "Aftermath"},
      "weightkg": 31,
      "evos": ["Garbodor"],
      "eggGroups": ["Mineral"]
    },
    "garbodor": {
      "num": 569,
      "name": "Garbodor",
      "types": ["Poison"],
      "baseStats": {
        "hp": 80,
        "atk": 95,
        "def": 82,
        "spa": 60,
        "spd": 82,
        "spe": 75
      },
      "abilities": {"0": "Stench", "1": "Weak Armor", "H": "Aftermath"},
      "weightkg": 107.3,
      "prevo": "Trubbish",
      "evoLevel": 36,
      "eggGroups": ["Mineral"],
      "canGigantamax": "G-Max Malodor"
    },
    "garbodorgmax": {
      "num": 569,
      "name": "Garbodor-Gmax",
      "baseSpecies": "Garbodor",
      "forme": "Gmax",
      "types": ["Poison"],
      "baseStats": {
        "hp": 80,
        "atk": 95,
        "def": 82,
        "spa": 60,
        "spd": 82,
        "spe": 75
      },
      "abilities": {"0": "Stench", "1": "Weak Armor", "H": "Aftermath"},
      "weightkg": 0,
      "eggGroups": ["Mineral"],
      "changesFrom": "Garbodor"
    },
    "zorua": {
      "num": 570,
      "name": "Zorua",
      "types": ["Dark"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 40,
        "atk": 65,
        "def": 40,
        "spa": 80,
        "spd": 40,
        "spe": 65
      },
      "abilities": {"0": "Illusion"},
      "weightkg": 12.5,
      "evos": ["Zoroark"],
      "eggGroups": ["Field"],
      "otherFormes": ["Zorua-Hisui"],
      "formeOrder": ["Zorua", "Zorua-Hisui"]
    },
    "zoruahisui": {
      "num": 570,
      "name": "Zorua-Hisui",
      "baseSpecies": "Zorua",
      "forme": "Hisui",
      "types": ["Normal", "Ghost"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 35,
        "atk": 60,
        "def": 40,
        "spa": 85,
        "spd": 40,
        "spe": 70
      },
      "abilities": {"0": "Illusion"},
      "weightkg": 12.5,
      "evos": ["Zoroark-Hisui"],
      "eggGroups": ["Field"]
    },
    "zoroark": {
      "num": 571,
      "name": "Zoroark",
      "types": ["Dark"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 60,
        "atk": 105,
        "def": 60,
        "spa": 120,
        "spd": 60,
        "spe": 105
      },
      "abilities": {"0": "Illusion"},
      "weightkg": 81.1,
      "prevo": "Zorua",
      "evoLevel": 30,
      "eggGroups": ["Field"],
      "otherFormes": ["Zoroark-Hisui"],
      "formeOrder": ["Zoroark", "Zoroark-Hisui"]
    },
    "zoroarkhisui": {
      "num": 571,
      "name": "Zoroark-Hisui",
      "baseSpecies": "Zoroark",
      "forme": "Hisui",
      "types": ["Normal", "Ghost"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 55,
        "atk": 100,
        "def": 60,
        "spa": 125,
        "spd": 60,
        "spe": 110
      },
      "abilities": {"0": "Illusion"},
      "weightkg": 73,
      "prevo": "Zorua-Hisui",
      "evoLevel": 30,
      "eggGroups": ["Field"]
    },
    "minccino": {
      "num": 572,
      "name": "Minccino",
      "types": ["Normal"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 55,
        "atk": 50,
        "def": 40,
        "spa": 40,
        "spd": 40,
        "spe": 75
      },
      "abilities": {"0": "Cute Charm", "1": "Technician", "H": "Skill Link"},
      "weightkg": 5.8,
      "evos": ["Cinccino"],
      "eggGroups": ["Field"]
    },
    "cinccino": {
      "num": 573,
      "name": "Cinccino",
      "types": ["Normal"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 75,
        "atk": 95,
        "def": 60,
        "spa": 65,
        "spd": 60,
        "spe": 115
      },
      "abilities": {"0": "Cute Charm", "1": "Technician", "H": "Skill Link"},
      "weightkg": 7.5,
      "prevo": "Minccino",
      "evoType": "useItem",
      "evoItem": "Shiny Stone",
      "eggGroups": ["Field"]
    },
    "gothita": {
      "num": 574,
      "name": "Gothita",
      "types": ["Psychic"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 45,
        "atk": 30,
        "def": 50,
        "spa": 55,
        "spd": 65,
        "spe": 45
      },
      "abilities": {"0": "Frisk", "1": "Competitive", "H": "Shadow Tag"},
      "weightkg": 5.8,
      "evos": ["Gothorita"],
      "eggGroups": ["Human-Like"]
    },
    "gothorita": {
      "num": 575,
      "name": "Gothorita",
      "types": ["Psychic"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 60,
        "atk": 45,
        "def": 70,
        "spa": 75,
        "spd": 85,
        "spe": 55
      },
      "abilities": {"0": "Frisk", "1": "Competitive", "H": "Shadow Tag"},
      "weightkg": 18,
      "prevo": "Gothita",
      "evoLevel": 32,
      "evos": ["Gothitelle"],
      "eggGroups": ["Human-Like"]
    },
    "gothitelle": {
      "num": 576,
      "name": "Gothitelle",
      "types": ["Psychic"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 70,
        "atk": 55,
        "def": 95,
        "spa": 95,
        "spd": 110,
        "spe": 65
      },
      "abilities": {"0": "Frisk", "1": "Competitive", "H": "Shadow Tag"},
      "weightkg": 44,
      "prevo": "Gothorita",
      "evoLevel": 41,
      "eggGroups": ["Human-Like"]
    },
    "solosis": {
      "num": 577,
      "name": "Solosis",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 45,
        "atk": 30,
        "def": 40,
        "spa": 105,
        "spd": 50,
        "spe": 20
      },
      "abilities": {"0": "Overcoat", "1": "Magic Guard", "H": "Regenerator"},
      "weightkg": 1,
      "evos": ["Duosion"],
      "eggGroups": ["Amorphous"]
    },
    "duosion": {
      "num": 578,
      "name": "Duosion",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 65,
        "atk": 40,
        "def": 50,
        "spa": 125,
        "spd": 60,
        "spe": 30
      },
      "abilities": {"0": "Overcoat", "1": "Magic Guard", "H": "Regenerator"},
      "weightkg": 8,
      "prevo": "Solosis",
      "evoLevel": 32,
      "evos": ["Reuniclus"],
      "eggGroups": ["Amorphous"]
    },
    "reuniclus": {
      "num": 579,
      "name": "Reuniclus",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 110,
        "atk": 65,
        "def": 75,
        "spa": 125,
        "spd": 85,
        "spe": 30
      },
      "abilities": {"0": "Overcoat", "1": "Magic Guard", "H": "Regenerator"},
      "weightkg": 20.1,
      "prevo": "Duosion",
      "evoLevel": 41,
      "eggGroups": ["Amorphous"]
    },
    "ducklett": {
      "num": 580,
      "name": "Ducklett",
      "types": ["Water", "Flying"],
      "baseStats": {
        "hp": 62,
        "atk": 44,
        "def": 50,
        "spa": 44,
        "spd": 50,
        "spe": 55
      },
      "abilities": {"0": "Keen Eye", "1": "Big Pecks", "H": "Hydration"},
      "weightkg": 5.5,
      "evos": ["Swanna"],
      "eggGroups": ["Water 1", "Flying"]
    },
    "swanna": {
      "num": 581,
      "name": "Swanna",
      "types": ["Water", "Flying"],
      "baseStats": {
        "hp": 75,
        "atk": 87,
        "def": 63,
        "spa": 87,
        "spd": 63,
        "spe": 98
      },
      "abilities": {"0": "Keen Eye", "1": "Big Pecks", "H": "Hydration"},
      "weightkg": 24.2,
      "prevo": "Ducklett",
      "evoLevel": 35,
      "eggGroups": ["Water 1", "Flying"]
    },
    "vanillite": {
      "num": 582,
      "name": "Vanillite",
      "types": ["Ice"],
      "baseStats": {
        "hp": 36,
        "atk": 50,
        "def": 50,
        "spa": 65,
        "spd": 60,
        "spe": 44
      },
      "abilities": {"0": "Ice Body", "1": "Snow Cloak", "H": "Weak Armor"},
      "weightkg": 5.7,
      "evos": ["Vanillish"],
      "eggGroups": ["Mineral"]
    },
    "vanillish": {
      "num": 583,
      "name": "Vanillish",
      "types": ["Ice"],
      "baseStats": {
        "hp": 51,
        "atk": 65,
        "def": 65,
        "spa": 80,
        "spd": 75,
        "spe": 59
      },
      "abilities": {"0": "Ice Body", "1": "Snow Cloak", "H": "Weak Armor"},
      "weightkg": 41,
      "prevo": "Vanillite",
      "evoLevel": 35,
      "evos": ["Vanilluxe"],
      "eggGroups": ["Mineral"]
    },
    "vanilluxe": {
      "num": 584,
      "name": "Vanilluxe",
      "types": ["Ice"],
      "baseStats": {
        "hp": 71,
        "atk": 95,
        "def": 85,
        "spa": 110,
        "spd": 95,
        "spe": 79
      },
      "abilities": {"0": "Ice Body", "1": "Snow Warning", "H": "Weak Armor"},
      "weightkg": 57.5,
      "prevo": "Vanillish",
      "evoLevel": 47,
      "eggGroups": ["Mineral"]
    },
    "deerling": {
      "num": 585,
      "name": "Deerling",
      "baseForme": "Spring",
      "types": ["Normal", "Grass"],
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 50,
        "spa": 40,
        "spd": 50,
        "spe": 75
      },
      "abilities": {"0": "Chlorophyll", "1": "Sap Sipper", "H": "Serene Grace"},
      "weightkg": 19.5,
      "evos": ["Sawsbuck"],
      "eggGroups": ["Field"],
      "cosmeticFormes": [
        "Deerling-Summer",
        "Deerling-Autumn",
        "Deerling-Winter"
      ],
      "formeOrder": [
        "Deerling",
        "Deerling-Summer",
        "Deerling-Autumn",
        "Deerling-Winter"
      ]
    },
    "sawsbuck": {
      "num": 586,
      "name": "Sawsbuck",
      "baseForme": "Spring",
      "types": ["Normal", "Grass"],
      "baseStats": {
        "hp": 80,
        "atk": 100,
        "def": 70,
        "spa": 60,
        "spd": 70,
        "spe": 95
      },
      "abilities": {"0": "Chlorophyll", "1": "Sap Sipper", "H": "Serene Grace"},
      "weightkg": 92.5,
      "prevo": "Deerling",
      "evoLevel": 34,
      "eggGroups": ["Field"],
      "cosmeticFormes": [
        "Sawsbuck-Summer",
        "Sawsbuck-Autumn",
        "Sawsbuck-Winter"
      ],
      "formeOrder": [
        "Sawsbuck",
        "Sawsbuck-Summer",
        "Sawsbuck-Autumn",
        "Sawsbuck-Winter"
      ]
    },
    "emolga": {
      "num": 587,
      "name": "Emolga",
      "types": ["Electric", "Flying"],
      "baseStats": {
        "hp": 55,
        "atk": 75,
        "def": 60,
        "spa": 75,
        "spd": 60,
        "spe": 103
      },
      "abilities": {"0": "Static", "H": "Motor Drive"},
      "weightkg": 5,
      "eggGroups": ["Field"]
    },
    "karrablast": {
      "num": 588,
      "name": "Karrablast",
      "types": ["Bug"],
      "baseStats": {
        "hp": 50,
        "atk": 75,
        "def": 45,
        "spa": 40,
        "spd": 45,
        "spe": 60
      },
      "abilities": {"0": "Swarm", "1": "Shed Skin", "H": "No Guard"},
      "weightkg": 5.9,
      "evos": ["Escavalier"],
      "eggGroups": ["Bug"]
    },
    "escavalier": {
      "num": 589,
      "name": "Escavalier",
      "types": ["Bug", "Steel"],
      "baseStats": {
        "hp": 70,
        "atk": 135,
        "def": 105,
        "spa": 60,
        "spd": 105,
        "spe": 20
      },
      "abilities": {"0": "Swarm", "1": "Shell Armor", "H": "Overcoat"},
      "weightkg": 33,
      "prevo": "Karrablast",
      "evoType": "trade",
      "evoCondition": "with a Shelmet",
      "eggGroups": ["Bug"]
    },
    "foongus": {
      "num": 590,
      "name": "Foongus",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 69,
        "atk": 55,
        "def": 45,
        "spa": 55,
        "spd": 55,
        "spe": 15
      },
      "abilities": {"0": "Effect Spore", "H": "Regenerator"},
      "weightkg": 1,
      "evos": ["Amoonguss"],
      "eggGroups": ["Grass"]
    },
    "amoonguss": {
      "num": 591,
      "name": "Amoonguss",
      "types": ["Grass", "Poison"],
      "baseStats": {
        "hp": 114,
        "atk": 85,
        "def": 70,
        "spa": 85,
        "spd": 80,
        "spe": 30
      },
      "abilities": {"0": "Effect Spore", "H": "Regenerator"},
      "weightkg": 10.5,
      "prevo": "Foongus",
      "evoLevel": 39,
      "eggGroups": ["Grass"]
    },
    "frillish": {
      "num": 592,
      "name": "Frillish",
      "types": ["Water", "Ghost"],
      "baseStats": {
        "hp": 55,
        "atk": 40,
        "def": 50,
        "spa": 65,
        "spd": 85,
        "spe": 40
      },
      "abilities": {"0": "Water Absorb", "1": "Cursed Body", "H": "Damp"},
      "weightkg": 33,
      "evos": ["Jellicent"],
      "eggGroups": ["Amorphous"]
    },
    "jellicent": {
      "num": 593,
      "name": "Jellicent",
      "types": ["Water", "Ghost"],
      "baseStats": {
        "hp": 100,
        "atk": 60,
        "def": 70,
        "spa": 85,
        "spd": 105,
        "spe": 60
      },
      "abilities": {"0": "Water Absorb", "1": "Cursed Body", "H": "Damp"},
      "weightkg": 135,
      "prevo": "Frillish",
      "evoLevel": 40,
      "eggGroups": ["Amorphous"]
    },
    "alomomola": {
      "num": 594,
      "name": "Alomomola",
      "types": ["Water"],
      "baseStats": {
        "hp": 165,
        "atk": 75,
        "def": 80,
        "spa": 40,
        "spd": 45,
        "spe": 65
      },
      "abilities": {"0": "Healer", "1": "Hydration", "H": "Regenerator"},
      "weightkg": 31.6,
      "eggGroups": ["Water 1", "Water 2"]
    },
    "joltik": {
      "num": 595,
      "name": "Joltik",
      "types": ["Bug", "Electric"],
      "baseStats": {
        "hp": 50,
        "atk": 47,
        "def": 50,
        "spa": 57,
        "spd": 50,
        "spe": 65
      },
      "abilities": {"0": "Compound Eyes", "1": "Unnerve", "H": "Swarm"},
      "weightkg": 0.6,
      "evos": ["Galvantula"],
      "eggGroups": ["Bug"]
    },
    "galvantula": {
      "num": 596,
      "name": "Galvantula",
      "types": ["Bug", "Electric"],
      "baseStats": {
        "hp": 70,
        "atk": 77,
        "def": 60,
        "spa": 97,
        "spd": 60,
        "spe": 108
      },
      "abilities": {"0": "Compound Eyes", "1": "Unnerve", "H": "Swarm"},
      "weightkg": 14.3,
      "prevo": "Joltik",
      "evoLevel": 36,
      "eggGroups": ["Bug"]
    },
    "ferroseed": {
      "num": 597,
      "name": "Ferroseed",
      "types": ["Grass", "Steel"],
      "baseStats": {
        "hp": 44,
        "atk": 50,
        "def": 91,
        "spa": 24,
        "spd": 86,
        "spe": 10
      },
      "abilities": {"0": "Iron Barbs"},
      "weightkg": 18.8,
      "evos": ["Ferrothorn"],
      "eggGroups": ["Grass", "Mineral"]
    },
    "ferrothorn": {
      "num": 598,
      "name": "Ferrothorn",
      "types": ["Grass", "Steel"],
      "baseStats": {
        "hp": 74,
        "atk": 94,
        "def": 131,
        "spa": 54,
        "spd": 116,
        "spe": 20
      },
      "abilities": {"0": "Iron Barbs", "H": "Anticipation"},
      "weightkg": 110,
      "prevo": "Ferroseed",
      "evoLevel": 40,
      "eggGroups": ["Grass", "Mineral"]
    },
    "klink": {
      "num": 599,
      "name": "Klink",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 40,
        "atk": 55,
        "def": 70,
        "spa": 45,
        "spd": 60,
        "spe": 30
      },
      "abilities": {"0": "Plus", "1": "Minus", "H": "Clear Body"},
      "weightkg": 21,
      "evos": ["Klang"],
      "eggGroups": ["Mineral"]
    },
    "klang": {
      "num": 600,
      "name": "Klang",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 80,
        "def": 95,
        "spa": 70,
        "spd": 85,
        "spe": 50
      },
      "abilities": {"0": "Plus", "1": "Minus", "H": "Clear Body"},
      "weightkg": 51,
      "prevo": "Klink",
      "evoLevel": 38,
      "evos": ["Klinklang"],
      "eggGroups": ["Mineral"]
    },
    "klinklang": {
      "num": 601,
      "name": "Klinklang",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 100,
        "def": 115,
        "spa": 70,
        "spd": 85,
        "spe": 90
      },
      "abilities": {"0": "Plus", "1": "Minus", "H": "Clear Body"},
      "weightkg": 81,
      "prevo": "Klang",
      "evoLevel": 49,
      "eggGroups": ["Mineral"]
    },
    "tynamo": {
      "num": 602,
      "name": "Tynamo",
      "types": ["Electric"],
      "baseStats": {
        "hp": 35,
        "atk": 55,
        "def": 40,
        "spa": 45,
        "spd": 40,
        "spe": 60
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 0.3,
      "evos": ["Eelektrik"],
      "eggGroups": ["Amorphous"]
    },
    "eelektrik": {
      "num": 603,
      "name": "Eelektrik",
      "types": ["Electric"],
      "baseStats": {
        "hp": 65,
        "atk": 85,
        "def": 70,
        "spa": 75,
        "spd": 70,
        "spe": 40
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 22,
      "prevo": "Tynamo",
      "evoLevel": 39,
      "evos": ["Eelektross"],
      "eggGroups": ["Amorphous"]
    },
    "eelektross": {
      "num": 604,
      "name": "Eelektross",
      "types": ["Electric"],
      "baseStats": {
        "hp": 85,
        "atk": 115,
        "def": 80,
        "spa": 105,
        "spd": 80,
        "spe": 50
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 80.5,
      "prevo": "Eelektrik",
      "evoType": "useItem",
      "evoItem": "Thunder Stone",
      "eggGroups": ["Amorphous"]
    },
    "elgyem": {
      "num": 605,
      "name": "Elgyem",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 55,
        "atk": 55,
        "def": 55,
        "spa": 85,
        "spd": 55,
        "spe": 30
      },
      "abilities": {"0": "Telepathy", "1": "Synchronize", "H": "Analytic"},
      "weightkg": 9,
      "evos": ["Beheeyem"],
      "eggGroups": ["Human-Like"]
    },
    "beheeyem": {
      "num": 606,
      "name": "Beheeyem",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 75,
        "atk": 75,
        "def": 75,
        "spa": 125,
        "spd": 95,
        "spe": 40
      },
      "abilities": {"0": "Telepathy", "1": "Synchronize", "H": "Analytic"},
      "weightkg": 34.5,
      "prevo": "Elgyem",
      "evoLevel": 42,
      "eggGroups": ["Human-Like"]
    },
    "litwick": {
      "num": 607,
      "name": "Litwick",
      "types": ["Ghost", "Fire"],
      "baseStats": {
        "hp": 50,
        "atk": 30,
        "def": 55,
        "spa": 65,
        "spd": 55,
        "spe": 20
      },
      "abilities": {"0": "Flash Fire", "1": "Flame Body", "H": "Infiltrator"},
      "weightkg": 3.1,
      "evos": ["Lampent"],
      "eggGroups": ["Amorphous"]
    },
    "lampent": {
      "num": 608,
      "name": "Lampent",
      "types": ["Ghost", "Fire"],
      "baseStats": {
        "hp": 60,
        "atk": 40,
        "def": 60,
        "spa": 95,
        "spd": 60,
        "spe": 55
      },
      "abilities": {"0": "Flash Fire", "1": "Flame Body", "H": "Infiltrator"},
      "weightkg": 13,
      "prevo": "Litwick",
      "evoLevel": 41,
      "evos": ["Chandelure"],
      "eggGroups": ["Amorphous"]
    },
    "chandelure": {
      "num": 609,
      "name": "Chandelure",
      "types": ["Ghost", "Fire"],
      "baseStats": {
        "hp": 60,
        "atk": 55,
        "def": 90,
        "spa": 145,
        "spd": 90,
        "spe": 80
      },
      "abilities": {"0": "Flash Fire", "1": "Flame Body", "H": "Infiltrator"},
      "weightkg": 34.3,
      "prevo": "Lampent",
      "evoType": "useItem",
      "evoItem": "Dusk Stone",
      "eggGroups": ["Amorphous"]
    },
    "axew": {
      "num": 610,
      "name": "Axew",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 46,
        "atk": 87,
        "def": 60,
        "spa": 30,
        "spd": 40,
        "spe": 57
      },
      "abilities": {"0": "Rivalry", "1": "Mold Breaker", "H": "Unnerve"},
      "weightkg": 18,
      "evos": ["Fraxure"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "fraxure": {
      "num": 611,
      "name": "Fraxure",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 66,
        "atk": 117,
        "def": 70,
        "spa": 40,
        "spd": 50,
        "spe": 67
      },
      "abilities": {"0": "Rivalry", "1": "Mold Breaker", "H": "Unnerve"},
      "weightkg": 36,
      "prevo": "Axew",
      "evoLevel": 38,
      "evos": ["Haxorus"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "haxorus": {
      "num": 612,
      "name": "Haxorus",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 76,
        "atk": 147,
        "def": 90,
        "spa": 60,
        "spd": 70,
        "spe": 97
      },
      "abilities": {"0": "Rivalry", "1": "Mold Breaker", "H": "Unnerve"},
      "weightkg": 105.5,
      "prevo": "Fraxure",
      "evoLevel": 48,
      "eggGroups": ["Monster", "Dragon"]
    },
    "cubchoo": {
      "num": 613,
      "name": "Cubchoo",
      "types": ["Ice"],
      "baseStats": {
        "hp": 55,
        "atk": 70,
        "def": 40,
        "spa": 60,
        "spd": 40,
        "spe": 40
      },
      "abilities": {"0": "Snow Cloak", "1": "Slush Rush", "H": "Rattled"},
      "weightkg": 8.5,
      "evos": ["Beartic"],
      "eggGroups": ["Field"]
    },
    "beartic": {
      "num": 614,
      "name": "Beartic",
      "types": ["Ice"],
      "baseStats": {
        "hp": 95,
        "atk": 130,
        "def": 80,
        "spa": 70,
        "spd": 80,
        "spe": 50
      },
      "abilities": {"0": "Snow Cloak", "1": "Slush Rush", "H": "Swift Swim"},
      "weightkg": 260,
      "prevo": "Cubchoo",
      "evoLevel": 37,
      "eggGroups": ["Field"]
    },
    "cryogonal": {
      "num": 615,
      "name": "Cryogonal",
      "types": ["Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 50,
        "def": 50,
        "spa": 95,
        "spd": 135,
        "spe": 105
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 148,
      "eggGroups": ["Mineral"]
    },
    "shelmet": {
      "num": 616,
      "name": "Shelmet",
      "types": ["Bug"],
      "baseStats": {
        "hp": 50,
        "atk": 40,
        "def": 85,
        "spa": 40,
        "spd": 65,
        "spe": 25
      },
      "abilities": {"0": "Hydration", "1": "Shell Armor", "H": "Overcoat"},
      "weightkg": 7.7,
      "evos": ["Accelgor"],
      "eggGroups": ["Bug"]
    },
    "accelgor": {
      "num": 617,
      "name": "Accelgor",
      "types": ["Bug"],
      "baseStats": {
        "hp": 80,
        "atk": 70,
        "def": 40,
        "spa": 100,
        "spd": 60,
        "spe": 145
      },
      "abilities": {"0": "Hydration", "1": "Sticky Hold", "H": "Unburden"},
      "weightkg": 25.3,
      "prevo": "Shelmet",
      "evoType": "trade",
      "evoCondition": "with a Karrablast",
      "eggGroups": ["Bug"]
    },
    "stunfisk": {
      "num": 618,
      "name": "Stunfisk",
      "types": ["Ground", "Electric"],
      "baseStats": {
        "hp": 109,
        "atk": 66,
        "def": 84,
        "spa": 81,
        "spd": 99,
        "spe": 32
      },
      "abilities": {"0": "Static", "1": "Limber", "H": "Sand Veil"},
      "weightkg": 11,
      "eggGroups": ["Water 1", "Amorphous"],
      "otherFormes": ["Stunfisk-Galar"],
      "formeOrder": ["Stunfisk", "Stunfisk-Galar"]
    },
    "stunfiskgalar": {
      "num": 618,
      "name": "Stunfisk-Galar",
      "baseSpecies": "Stunfisk",
      "forme": "Galar",
      "types": ["Ground", "Steel"],
      "baseStats": {
        "hp": 109,
        "atk": 81,
        "def": 99,
        "spa": 66,
        "spd": 84,
        "spe": 32
      },
      "abilities": {"0": "Mimicry"},
      "weightkg": 20.5,
      "eggGroups": ["Water 1", "Amorphous"]
    },
    "mienfoo": {
      "num": 619,
      "name": "Mienfoo",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 45,
        "atk": 85,
        "def": 50,
        "spa": 55,
        "spd": 50,
        "spe": 65
      },
      "abilities": {"0": "Inner Focus", "1": "Regenerator", "H": "Reckless"},
      "weightkg": 20,
      "evos": ["Mienshao"],
      "eggGroups": ["Field", "Human-Like"]
    },
    "mienshao": {
      "num": 620,
      "name": "Mienshao",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 65,
        "atk": 125,
        "def": 60,
        "spa": 95,
        "spd": 60,
        "spe": 105
      },
      "abilities": {"0": "Inner Focus", "1": "Regenerator", "H": "Reckless"},
      "weightkg": 35.5,
      "prevo": "Mienfoo",
      "evoLevel": 50,
      "eggGroups": ["Field", "Human-Like"]
    },
    "druddigon": {
      "num": 621,
      "name": "Druddigon",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 77,
        "atk": 120,
        "def": 90,
        "spa": 60,
        "spd": 90,
        "spe": 48
      },
      "abilities": {"0": "Rough Skin", "1": "Sheer Force", "H": "Mold Breaker"},
      "weightkg": 139,
      "eggGroups": ["Monster", "Dragon"]
    },
    "golett": {
      "num": 622,
      "name": "Golett",
      "types": ["Ground", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 59,
        "atk": 74,
        "def": 50,
        "spa": 35,
        "spd": 50,
        "spe": 35
      },
      "abilities": {"0": "Iron Fist", "1": "Klutz", "H": "No Guard"},
      "weightkg": 92,
      "evos": ["Golurk"],
      "eggGroups": ["Mineral"]
    },
    "golurk": {
      "num": 623,
      "name": "Golurk",
      "types": ["Ground", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 89,
        "atk": 124,
        "def": 80,
        "spa": 55,
        "spd": 80,
        "spe": 55
      },
      "abilities": {"0": "Iron Fist", "1": "Klutz", "H": "No Guard"},
      "weightkg": 330,
      "prevo": "Golett",
      "evoLevel": 43,
      "eggGroups": ["Mineral"]
    },
    "pawniard": {
      "num": 624,
      "name": "Pawniard",
      "types": ["Dark", "Steel"],
      "baseStats": {
        "hp": 45,
        "atk": 85,
        "def": 70,
        "spa": 40,
        "spd": 40,
        "spe": 60
      },
      "abilities": {"0": "Defiant", "1": "Inner Focus", "H": "Pressure"},
      "weightkg": 10.2,
      "evos": ["Bisharp"],
      "eggGroups": ["Human-Like"]
    },
    "bisharp": {
      "num": 625,
      "name": "Bisharp",
      "types": ["Dark", "Steel"],
      "baseStats": {
        "hp": 65,
        "atk": 125,
        "def": 100,
        "spa": 60,
        "spd": 70,
        "spe": 70
      },
      "abilities": {"0": "Defiant", "1": "Inner Focus", "H": "Pressure"},
      "weightkg": 70,
      "prevo": "Pawniard",
      "evoLevel": 52,
      "evos": ["Kingambit"],
      "eggGroups": ["Human-Like"]
    },
    "bouffalant": {
      "num": 626,
      "name": "Bouffalant",
      "types": ["Normal"],
      "baseStats": {
        "hp": 95,
        "atk": 110,
        "def": 95,
        "spa": 40,
        "spd": 95,
        "spe": 55
      },
      "abilities": {"0": "Reckless", "1": "Sap Sipper", "H": "Soundproof"},
      "weightkg": 94.6,
      "eggGroups": ["Field"]
    },
    "rufflet": {
      "num": 627,
      "name": "Rufflet",
      "types": ["Normal", "Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 70,
        "atk": 83,
        "def": 50,
        "spa": 37,
        "spd": 50,
        "spe": 60
      },
      "abilities": {"0": "Keen Eye", "1": "Sheer Force", "H": "Hustle"},
      "weightkg": 10.5,
      "evos": ["Braviary", "Braviary-Hisui"],
      "eggGroups": ["Flying"]
    },
    "braviary": {
      "num": 628,
      "name": "Braviary",
      "types": ["Normal", "Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 100,
        "atk": 123,
        "def": 75,
        "spa": 57,
        "spd": 75,
        "spe": 80
      },
      "abilities": {"0": "Keen Eye", "1": "Sheer Force", "H": "Defiant"},
      "weightkg": 41,
      "prevo": "Rufflet",
      "evoLevel": 54,
      "eggGroups": ["Flying"],
      "otherFormes": ["Braviary-Hisui"],
      "formeOrder": ["Braviary", "Braviary-Hisui"]
    },
    "braviaryhisui": {
      "num": 628,
      "name": "Braviary-Hisui",
      "baseSpecies": "Braviary",
      "forme": "Hisui",
      "types": ["Psychic", "Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 110,
        "atk": 83,
        "def": 70,
        "spa": 112,
        "spd": 70,
        "spe": 65
      },
      "abilities": {"0": "Keen Eye", "1": "Sheer Force", "H": "Tinted Lens"},
      "weightkg": 43.4,
      "prevo": "Rufflet",
      "evoLevel": 54,
      "eggGroups": ["Flying"]
    },
    "vullaby": {
      "num": 629,
      "name": "Vullaby",
      "types": ["Dark", "Flying"],
      "gender": "F",
      "baseStats": {
        "hp": 70,
        "atk": 55,
        "def": 75,
        "spa": 45,
        "spd": 65,
        "spe": 60
      },
      "abilities": {"0": "Big Pecks", "1": "Overcoat", "H": "Weak Armor"},
      "weightkg": 9,
      "evos": ["Mandibuzz"],
      "eggGroups": ["Flying"]
    },
    "mandibuzz": {
      "num": 630,
      "name": "Mandibuzz",
      "types": ["Dark", "Flying"],
      "gender": "F",
      "baseStats": {
        "hp": 110,
        "atk": 65,
        "def": 105,
        "spa": 55,
        "spd": 95,
        "spe": 80
      },
      "abilities": {"0": "Big Pecks", "1": "Overcoat", "H": "Weak Armor"},
      "weightkg": 39.5,
      "prevo": "Vullaby",
      "evoLevel": 54,
      "eggGroups": ["Flying"]
    },
    "heatmor": {
      "num": 631,
      "name": "Heatmor",
      "types": ["Fire"],
      "baseStats": {
        "hp": 85,
        "atk": 97,
        "def": 66,
        "spa": 105,
        "spd": 66,
        "spe": 65
      },
      "abilities": {"0": "Gluttony", "1": "Flash Fire", "H": "White Smoke"},
      "weightkg": 58,
      "eggGroups": ["Field"]
    },
    "durant": {
      "num": 632,
      "name": "Durant",
      "types": ["Bug", "Steel"],
      "baseStats": {
        "hp": 58,
        "atk": 109,
        "def": 112,
        "spa": 48,
        "spd": 48,
        "spe": 109
      },
      "abilities": {"0": "Swarm", "1": "Hustle", "H": "Truant"},
      "weightkg": 33,
      "eggGroups": ["Bug"]
    },
    "deino": {
      "num": 633,
      "name": "Deino",
      "types": ["Dark", "Dragon"],
      "baseStats": {
        "hp": 52,
        "atk": 65,
        "def": 50,
        "spa": 45,
        "spd": 50,
        "spe": 38
      },
      "abilities": {"0": "Hustle"},
      "weightkg": 17.3,
      "evos": ["Zweilous"],
      "eggGroups": ["Dragon"]
    },
    "zweilous": {
      "num": 634,
      "name": "Zweilous",
      "types": ["Dark", "Dragon"],
      "baseStats": {
        "hp": 72,
        "atk": 85,
        "def": 70,
        "spa": 65,
        "spd": 70,
        "spe": 58
      },
      "abilities": {"0": "Hustle"},
      "weightkg": 50,
      "prevo": "Deino",
      "evoLevel": 50,
      "evos": ["Hydreigon"],
      "eggGroups": ["Dragon"]
    },
    "hydreigon": {
      "num": 635,
      "name": "Hydreigon",
      "types": ["Dark", "Dragon"],
      "baseStats": {
        "hp": 92,
        "atk": 105,
        "def": 90,
        "spa": 125,
        "spd": 90,
        "spe": 98
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 160,
      "prevo": "Zweilous",
      "evoLevel": 64,
      "eggGroups": ["Dragon"]
    },
    "larvesta": {
      "num": 636,
      "name": "Larvesta",
      "types": ["Bug", "Fire"],
      "baseStats": {
        "hp": 55,
        "atk": 85,
        "def": 55,
        "spa": 50,
        "spd": 55,
        "spe": 60
      },
      "abilities": {"0": "Flame Body", "H": "Swarm"},
      "weightkg": 28.8,
      "evos": ["Volcarona"],
      "eggGroups": ["Bug"]
    },
    "volcarona": {
      "num": 637,
      "name": "Volcarona",
      "types": ["Bug", "Fire"],
      "baseStats": {
        "hp": 85,
        "atk": 60,
        "def": 65,
        "spa": 135,
        "spd": 105,
        "spe": 100
      },
      "abilities": {"0": "Flame Body", "H": "Swarm"},
      "weightkg": 46,
      "prevo": "Larvesta",
      "evoLevel": 59,
      "eggGroups": ["Bug"]
    },
    "cobalion": {
      "num": 638,
      "name": "Cobalion",
      "types": ["Steel", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 91,
        "atk": 90,
        "def": 129,
        "spa": 90,
        "spd": 72,
        "spe": 108
      },
      "abilities": {"0": "Justified"},
      "weightkg": 250,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "terrakion": {
      "num": 639,
      "name": "Terrakion",
      "types": ["Rock", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 91,
        "atk": 129,
        "def": 90,
        "spa": 72,
        "spd": 90,
        "spe": 108
      },
      "abilities": {"0": "Justified"},
      "weightkg": 260,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "virizion": {
      "num": 640,
      "name": "Virizion",
      "types": ["Grass", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 91,
        "atk": 90,
        "def": 72,
        "spa": 90,
        "spd": 129,
        "spe": 108
      },
      "abilities": {"0": "Justified"},
      "weightkg": 200,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "tornadus": {
      "num": 641,
      "name": "Tornadus",
      "baseForme": "Incarnate",
      "types": ["Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 79,
        "atk": 115,
        "def": 70,
        "spa": 125,
        "spd": 80,
        "spe": 111
      },
      "abilities": {"0": "Prankster", "H": "Defiant"},
      "weightkg": 63,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Tornadus-Therian"],
      "formeOrder": ["Tornadus", "Tornadus-Therian"]
    },
    "tornadustherian": {
      "num": 641,
      "name": "Tornadus-Therian",
      "baseSpecies": "Tornadus",
      "forme": "Therian",
      "types": ["Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 79,
        "atk": 100,
        "def": 80,
        "spa": 110,
        "spd": 90,
        "spe": 121
      },
      "abilities": {"0": "Regenerator"},
      "weightkg": 63,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Tornadus"
    },
    "thundurus": {
      "num": 642,
      "name": "Thundurus",
      "baseForme": "Incarnate",
      "types": ["Electric", "Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 79,
        "atk": 115,
        "def": 70,
        "spa": 125,
        "spd": 80,
        "spe": 111
      },
      "abilities": {"0": "Prankster", "H": "Defiant"},
      "weightkg": 61,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Thundurus-Therian"],
      "formeOrder": ["Thundurus", "Thundurus-Therian"]
    },
    "thundurustherian": {
      "num": 642,
      "name": "Thundurus-Therian",
      "baseSpecies": "Thundurus",
      "forme": "Therian",
      "types": ["Electric", "Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 79,
        "atk": 105,
        "def": 70,
        "spa": 145,
        "spd": 80,
        "spe": 101
      },
      "abilities": {"0": "Volt Absorb"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Thundurus"
    },
    "reshiram": {
      "num": 643,
      "name": "Reshiram",
      "types": ["Dragon", "Fire"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 120,
        "def": 100,
        "spa": 150,
        "spd": 120,
        "spe": 90
      },
      "abilities": {"0": "Turboblaze"},
      "weightkg": 330,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "zekrom": {
      "num": 644,
      "name": "Zekrom",
      "types": ["Dragon", "Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 150,
        "def": 120,
        "spa": 120,
        "spd": 100,
        "spe": 90
      },
      "abilities": {"0": "Teravolt"},
      "weightkg": 345,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "landorus": {
      "num": 645,
      "name": "Landorus",
      "baseForme": "Incarnate",
      "types": ["Ground", "Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 89,
        "atk": 125,
        "def": 90,
        "spa": 115,
        "spd": 80,
        "spe": 101
      },
      "abilities": {"0": "Sand Force", "H": "Sheer Force"},
      "weightkg": 68,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Landorus-Therian"],
      "formeOrder": ["Landorus", "Landorus-Therian"]
    },
    "landorustherian": {
      "num": 645,
      "name": "Landorus-Therian",
      "baseSpecies": "Landorus",
      "forme": "Therian",
      "types": ["Ground", "Flying"],
      "gender": "M",
      "baseStats": {
        "hp": 89,
        "atk": 145,
        "def": 90,
        "spa": 105,
        "spd": 80,
        "spe": 91
      },
      "abilities": {"0": "Intimidate"},
      "weightkg": 68,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Landorus"
    },
    "kyurem": {
      "num": 646,
      "name": "Kyurem",
      "types": ["Dragon", "Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 125,
        "atk": 130,
        "def": 90,
        "spa": 130,
        "spd": 90,
        "spe": 95
      },
      "abilities": {"0": "Pressure"},
      "weightkg": 325,
      "eggGroups": ["Undiscovered"],
      "tags": ["Restricted Legendary"],
      "otherFormes": ["Kyurem-Black", "Kyurem-White"],
      "formeOrder": ["Kyurem", "Kyurem-White", "Kyurem-Black"]
    },
    "kyuremblack": {
      "num": 646,
      "name": "Kyurem-Black",
      "baseSpecies": "Kyurem",
      "forme": "Black",
      "types": ["Dragon", "Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 125,
        "atk": 170,
        "def": 100,
        "spa": 120,
        "spd": 90,
        "spe": 95
      },
      "abilities": {"0": "Teravolt"},
      "weightkg": 325,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Kyurem"
    },
    "kyuremwhite": {
      "num": 646,
      "name": "Kyurem-White",
      "baseSpecies": "Kyurem",
      "forme": "White",
      "types": ["Dragon", "Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 125,
        "atk": 120,
        "def": 90,
        "spa": 170,
        "spd": 100,
        "spe": 95
      },
      "abilities": {"0": "Turboblaze"},
      "weightkg": 325,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Kyurem"
    },
    "keldeo": {
      "num": 647,
      "name": "Keldeo",
      "baseForme": "Ordinary",
      "types": ["Water", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 91,
        "atk": 72,
        "def": 90,
        "spa": 129,
        "spd": 90,
        "spe": 108
      },
      "abilities": {"0": "Justified"},
      "weightkg": 48.5,
      "eggGroups": ["Undiscovered"],
      "tags": ["Mythical"],
      "otherFormes": ["Keldeo-Resolute"],
      "formeOrder": ["Keldeo", "Keldeo-Resolute"]
    },
    "keldeoresolute": {
      "num": 647,
      "name": "Keldeo-Resolute",
      "baseSpecies": "Keldeo",
      "forme": "Resolute",
      "types": ["Water", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 91,
        "atk": 72,
        "def": 90,
        "spa": 129,
        "spd": 90,
        "spe": 108
      },
      "abilities": {"0": "Justified"},
      "weightkg": 48.5,
      "eggGroups": ["Undiscovered"],
      "requiredMove": "Secret Sword",
      "changesFrom": "Keldeo"
    },
    "meloetta": {
      "num": 648,
      "name": "Meloetta",
      "baseForme": "Aria",
      "types": ["Normal", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 77,
        "def": 77,
        "spa": 128,
        "spd": 128,
        "spe": 90
      },
      "abilities": {"0": "Serene Grace"},
      "weightkg": 6.5,
      "eggGroups": ["Undiscovered"],
      "tags": ["Mythical"],
      "otherFormes": ["Meloetta-Pirouette"],
      "formeOrder": ["Meloetta", "Meloetta-Pirouette"]
    },
    "meloettapirouette": {
      "num": 648,
      "name": "Meloetta-Pirouette",
      "baseSpecies": "Meloetta",
      "forme": "Pirouette",
      "types": ["Normal", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 128,
        "def": 90,
        "spa": 77,
        "spd": 77,
        "spe": 128
      },
      "abilities": {"0": "Serene Grace"},
      "weightkg": 6.5,
      "eggGroups": ["Undiscovered"],
      "requiredMove": "Relic Song",
      "battleOnly": "Meloetta"
    },
    "genesect": {
      "num": 649,
      "name": "Genesect",
      "types": ["Bug", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 71,
        "atk": 120,
        "def": 95,
        "spa": 120,
        "spd": 95,
        "spe": 99
      },
      "abilities": {"0": "Download"},
      "weightkg": 82.5,
      "eggGroups": ["Undiscovered"],
      "tags": ["Mythical"],
      "otherFormes": [
        "Genesect-Douse",
        "Genesect-Shock",
        "Genesect-Burn",
        "Genesect-Chill"
      ],
      "formeOrder": [
        "Genesect",
        "Genesect-Douse",
        "Genesect-Shock",
        "Genesect-Burn",
        "Genesect-Chill"
      ]
    },
    "genesectdouse": {
      "num": 649,
      "name": "Genesect-Douse",
      "baseSpecies": "Genesect",
      "forme": "Douse",
      "types": ["Bug", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 71,
        "atk": 120,
        "def": 95,
        "spa": 120,
        "spd": 95,
        "spe": 99
      },
      "abilities": {"0": "Download"},
      "weightkg": 82.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Douse Drive",
      "changesFrom": "Genesect"
    },
    "genesectshock": {
      "num": 649,
      "name": "Genesect-Shock",
      "baseSpecies": "Genesect",
      "forme": "Shock",
      "types": ["Bug", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 71,
        "atk": 120,
        "def": 95,
        "spa": 120,
        "spd": 95,
        "spe": 99
      },
      "abilities": {"0": "Download"},
      "weightkg": 82.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Shock Drive",
      "changesFrom": "Genesect"
    },
    "genesectburn": {
      "num": 649,
      "name": "Genesect-Burn",
      "baseSpecies": "Genesect",
      "forme": "Burn",
      "types": ["Bug", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 71,
        "atk": 120,
        "def": 95,
        "spa": 120,
        "spd": 95,
        "spe": 99
      },
      "abilities": {"0": "Download"},
      "weightkg": 82.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Burn Drive",
      "changesFrom": "Genesect"
    },
    "genesectchill": {
      "num": 649,
      "name": "Genesect-Chill",
      "baseSpecies": "Genesect",
      "forme": "Chill",
      "types": ["Bug", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 71,
        "atk": 120,
        "def": 95,
        "spa": 120,
        "spd": 95,
        "spe": 99
      },
      "abilities": {"0": "Download"},
      "weightkg": 82.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Chill Drive",
      "changesFrom": "Genesect"
    },
    "chespin": {
      "num": 650,
      "name": "Chespin",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 56,
        "atk": 61,
        "def": 65,
        "spa": 48,
        "spd": 45,
        "spe": 38
      },
      "abilities": {"0": "Overgrow", "H": "Bulletproof"},
      "weightkg": 9,
      "evos": ["Quilladin"],
      "eggGroups": ["Field"]
    },
    "quilladin": {
      "num": 651,
      "name": "Quilladin",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 61,
        "atk": 78,
        "def": 95,
        "spa": 56,
        "spd": 58,
        "spe": 57
      },
      "abilities": {"0": "Overgrow", "H": "Bulletproof"},
      "weightkg": 29,
      "prevo": "Chespin",
      "evoLevel": 16,
      "evos": ["Chesnaught"],
      "eggGroups": ["Field"]
    },
    "chesnaught": {
      "num": 652,
      "name": "Chesnaught",
      "types": ["Grass", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 88,
        "atk": 107,
        "def": 122,
        "spa": 74,
        "spd": 75,
        "spe": 64
      },
      "abilities": {"0": "Overgrow", "H": "Bulletproof"},
      "weightkg": 90,
      "prevo": "Quilladin",
      "evoLevel": 36,
      "eggGroups": ["Field"]
    },
    "fennekin": {
      "num": 653,
      "name": "Fennekin",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 40,
        "spa": 62,
        "spd": 60,
        "spe": 60
      },
      "abilities": {"0": "Blaze", "H": "Magician"},
      "weightkg": 9.4,
      "evos": ["Braixen"],
      "eggGroups": ["Field"]
    },
    "braixen": {
      "num": 654,
      "name": "Braixen",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 59,
        "atk": 59,
        "def": 58,
        "spa": 90,
        "spd": 70,
        "spe": 73
      },
      "abilities": {"0": "Blaze", "H": "Magician"},
      "weightkg": 14.5,
      "prevo": "Fennekin",
      "evoLevel": 16,
      "evos": ["Delphox"],
      "eggGroups": ["Field"]
    },
    "delphox": {
      "num": 655,
      "name": "Delphox",
      "types": ["Fire", "Psychic"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 75,
        "atk": 69,
        "def": 72,
        "spa": 114,
        "spd": 100,
        "spe": 104
      },
      "abilities": {"0": "Blaze", "H": "Magician"},
      "weightkg": 39,
      "prevo": "Braixen",
      "evoLevel": 36,
      "eggGroups": ["Field"]
    },
    "froakie": {
      "num": 656,
      "name": "Froakie",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 41,
        "atk": 56,
        "def": 40,
        "spa": 62,
        "spd": 44,
        "spe": 71
      },
      "abilities": {"0": "Torrent", "H": "Protean"},
      "weightkg": 7,
      "evos": ["Frogadier"],
      "eggGroups": ["Water 1"]
    },
    "frogadier": {
      "num": 657,
      "name": "Frogadier",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 54,
        "atk": 63,
        "def": 52,
        "spa": 83,
        "spd": 56,
        "spe": 97
      },
      "abilities": {"0": "Torrent", "H": "Protean"},
      "weightkg": 10.9,
      "prevo": "Froakie",
      "evoLevel": 16,
      "evos": ["Greninja"],
      "eggGroups": ["Water 1"]
    },
    "greninja": {
      "num": 658,
      "name": "Greninja",
      "types": ["Water", "Dark"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 72,
        "atk": 95,
        "def": 67,
        "spa": 103,
        "spd": 71,
        "spe": 122
      },
      "abilities": {"0": "Torrent", "H": "Protean", "S": "Battle Bond"},
      "weightkg": 40,
      "prevo": "Frogadier",
      "evoLevel": 36,
      "eggGroups": ["Water 1"],
      "otherFormes": ["Greninja-Bond", "Greninja-Ash"],
      "formeOrder": ["Greninja", "Greninja-Bond", "Greninja-Ash"]
    },
    "greninjabond": {
      "num": 658,
      "name": "Greninja-Bond",
      "baseSpecies": "Greninja",
      "forme": "Bond",
      "types": ["Water", "Dark"],
      "gender": "M",
      "baseStats": {
        "hp": 72,
        "atk": 95,
        "def": 67,
        "spa": 103,
        "spd": 71,
        "spe": 122
      },
      "abilities": {"0": "Battle Bond"},
      "weightkg": 40,
      "eggGroups": ["Undiscovered"],
      "gen": 7
    },
    "greninjaash": {
      "num": 658,
      "name": "Greninja-Ash",
      "baseSpecies": "Greninja",
      "forme": "Ash",
      "types": ["Water", "Dark"],
      "gender": "M",
      "baseStats": {
        "hp": 72,
        "atk": 145,
        "def": 67,
        "spa": 153,
        "spd": 71,
        "spe": 132
      },
      "abilities": {"0": "Battle Bond"},
      "weightkg": 40,
      "eggGroups": ["Undiscovered"],
      "requiredAbility": "Battle Bond",
      "battleOnly": "Greninja-Bond",
      "gen": 7
    },
    "bunnelby": {
      "num": 659,
      "name": "Bunnelby",
      "types": ["Normal"],
      "baseStats": {
        "hp": 38,
        "atk": 36,
        "def": 38,
        "spa": 32,
        "spd": 36,
        "spe": 57
      },
      "abilities": {"0": "Pickup", "1": "Cheek Pouch", "H": "Huge Power"},
      "weightkg": 5,
      "evos": ["Diggersby"],
      "eggGroups": ["Field"]
    },
    "diggersby": {
      "num": 660,
      "name": "Diggersby",
      "types": ["Normal", "Ground"],
      "baseStats": {
        "hp": 85,
        "atk": 56,
        "def": 77,
        "spa": 50,
        "spd": 77,
        "spe": 78
      },
      "abilities": {"0": "Pickup", "1": "Cheek Pouch", "H": "Huge Power"},
      "weightkg": 42.4,
      "prevo": "Bunnelby",
      "evoLevel": 20,
      "eggGroups": ["Field"]
    },
    "fletchling": {
      "num": 661,
      "name": "Fletchling",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 45,
        "atk": 50,
        "def": 43,
        "spa": 40,
        "spd": 38,
        "spe": 62
      },
      "abilities": {"0": "Big Pecks", "H": "Gale Wings"},
      "weightkg": 1.7,
      "evos": ["Fletchinder"],
      "eggGroups": ["Flying"]
    },
    "fletchinder": {
      "num": 662,
      "name": "Fletchinder",
      "types": ["Fire", "Flying"],
      "baseStats": {
        "hp": 62,
        "atk": 73,
        "def": 55,
        "spa": 56,
        "spd": 52,
        "spe": 84
      },
      "abilities": {"0": "Flame Body", "H": "Gale Wings"},
      "weightkg": 16,
      "prevo": "Fletchling",
      "evoLevel": 17,
      "evos": ["Talonflame"],
      "eggGroups": ["Flying"]
    },
    "talonflame": {
      "num": 663,
      "name": "Talonflame",
      "types": ["Fire", "Flying"],
      "baseStats": {
        "hp": 78,
        "atk": 81,
        "def": 71,
        "spa": 74,
        "spd": 69,
        "spe": 126
      },
      "abilities": {"0": "Flame Body", "H": "Gale Wings"},
      "weightkg": 24.5,
      "prevo": "Fletchinder",
      "evoLevel": 35,
      "eggGroups": ["Flying"]
    },
    "scatterbug": {
      "num": 664,
      "name": "Scatterbug",
      "types": ["Bug"],
      "baseStats": {
        "hp": 38,
        "atk": 35,
        "def": 40,
        "spa": 27,
        "spd": 25,
        "spe": 35
      },
      "abilities": {
        "0": "Shield Dust",
        "1": "Compound Eyes",
        "H": "Friend Guard"
      },
      "weightkg": 2.5,
      "evos": ["Spewpa"],
      "eggGroups": ["Bug"]
    },
    "spewpa": {
      "num": 665,
      "name": "Spewpa",
      "types": ["Bug"],
      "baseStats": {
        "hp": 45,
        "atk": 22,
        "def": 60,
        "spa": 27,
        "spd": 30,
        "spe": 29
      },
      "abilities": {"0": "Shed Skin", "H": "Friend Guard"},
      "weightkg": 8.4,
      "prevo": "Scatterbug",
      "evoLevel": 9,
      "evos": ["Vivillon", "Vivillon-Fancy"],
      "eggGroups": ["Bug"]
    },
    "vivillon": {
      "num": 666,
      "name": "Vivillon",
      "baseForme": "Meadow",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 80,
        "atk": 52,
        "def": 50,
        "spa": 90,
        "spd": 50,
        "spe": 89
      },
      "abilities": {
        "0": "Shield Dust",
        "1": "Compound Eyes",
        "H": "Friend Guard"
      },
      "weightkg": 17,
      "prevo": "Spewpa",
      "evoLevel": 12,
      "eggGroups": ["Bug"],
      "otherFormes": ["Vivillon-Fancy", "Vivillon-Pokeball"],
      "cosmeticFormes": [
        "Vivillon-Archipelago",
        "Vivillon-Continental",
        "Vivillon-Elegant",
        "Vivillon-Garden",
        "Vivillon-High Plains",
        "Vivillon-Icy Snow",
        "Vivillon-Jungle",
        "Vivillon-Marine",
        "Vivillon-Modern",
        "Vivillon-Monsoon",
        "Vivillon-Ocean",
        "Vivillon-Polar",
        "Vivillon-River",
        "Vivillon-Sandstorm",
        "Vivillon-Savanna",
        "Vivillon-Sun",
        "Vivillon-Tundra"
      ],
      "formeOrder": [
        "Vivillon-Icy Snow",
        "Vivillon-Polar",
        "Vivillon-Tundra",
        "Vivillon-Continental",
        "Vivillon-Garden",
        "Vivillon-Elegant",
        "Vivillon",
        "Vivillon-Modern",
        "Vivillon-Marine",
        "Vivillon-Archipelago",
        "Vivillon-High Plains",
        "Vivillon-Sandstorm",
        "Vivillon-River",
        "Vivillon-Monsoon",
        "Vivillon-Savanna",
        "Vivillon-Sun",
        "Vivillon-Ocean",
        "Vivillon-Jungle",
        "Vivillon-Fancy",
        "Vivillon-Pokeball"
      ]
    },
    "vivillonfancy": {
      "num": 666,
      "name": "Vivillon-Fancy",
      "baseSpecies": "Vivillon",
      "forme": "Fancy",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 80,
        "atk": 52,
        "def": 50,
        "spa": 90,
        "spd": 50,
        "spe": 89
      },
      "abilities": {
        "0": "Shield Dust",
        "1": "Compound Eyes",
        "H": "Friend Guard"
      },
      "weightkg": 17,
      "prevo": "Spewpa",
      "evoLevel": 12,
      "eggGroups": ["Bug"]
    },
    "vivillonpokeball": {
      "num": 666,
      "name": "Vivillon-Pokeball",
      "baseSpecies": "Vivillon",
      "forme": "Pokeball",
      "types": ["Bug", "Flying"],
      "baseStats": {
        "hp": 80,
        "atk": 52,
        "def": 50,
        "spa": 90,
        "spd": 50,
        "spe": 89
      },
      "abilities": {
        "0": "Shield Dust",
        "1": "Compound Eyes",
        "H": "Friend Guard"
      },
      "weightkg": 17,
      "eggGroups": ["Bug"]
    },
    "litleo": {
      "num": 667,
      "name": "Litleo",
      "types": ["Fire", "Normal"],
      "genderRatio": {"M": 0.125, "F": 0.875},
      "baseStats": {
        "hp": 62,
        "atk": 50,
        "def": 58,
        "spa": 73,
        "spd": 54,
        "spe": 72
      },
      "abilities": {"0": "Rivalry", "1": "Unnerve", "H": "Moxie"},
      "weightkg": 13.5,
      "evos": ["Pyroar"],
      "eggGroups": ["Field"]
    },
    "pyroar": {
      "num": 668,
      "name": "Pyroar",
      "types": ["Fire", "Normal"],
      "genderRatio": {"M": 0.125, "F": 0.875},
      "baseStats": {
        "hp": 86,
        "atk": 68,
        "def": 72,
        "spa": 109,
        "spd": 66,
        "spe": 106
      },
      "abilities": {"0": "Rivalry", "1": "Unnerve", "H": "Moxie"},
      "weightkg": 81.5,
      "prevo": "Litleo",
      "evoLevel": 35,
      "eggGroups": ["Field"]
    },
    "flabebe": {
      "num": 669,
      "name": "Flabébé",
      "baseForme": "Red",
      "types": ["Fairy"],
      "gender": "F",
      "baseStats": {
        "hp": 44,
        "atk": 38,
        "def": 39,
        "spa": 61,
        "spd": 79,
        "spe": 42
      },
      "abilities": {"0": "Flower Veil", "H": "Symbiosis"},
      "weightkg": 0.1,
      "evos": ["Floette"],
      "eggGroups": ["Fairy"],
      "cosmeticFormes": [
        "Flabébé-Blue",
        "Flabébé-Orange",
        "Flabébé-White",
        "Flabébé-Yellow"
      ],
      "formeOrder": [
        "Flabébé",
        "Flabébé-Blue",
        "Flabébé-Orange",
        "Flabébé-White",
        "Flabébé-Yellow"
      ]
    },
    "floette": {
      "num": 670,
      "name": "Floette",
      "baseForme": "Red",
      "types": ["Fairy"],
      "gender": "F",
      "baseStats": {
        "hp": 54,
        "atk": 45,
        "def": 47,
        "spa": 75,
        "spd": 98,
        "spe": 52
      },
      "abilities": {"0": "Flower Veil", "H": "Symbiosis"},
      "weightkg": 0.9,
      "prevo": "Flabébé",
      "evoLevel": 19,
      "evos": ["Florges"],
      "eggGroups": ["Fairy"],
      "otherFormes": ["Floette-Eternal"],
      "cosmeticFormes": [
        "Floette-Blue",
        "Floette-Orange",
        "Floette-White",
        "Floette-Yellow"
      ],
      "formeOrder": [
        "Floette",
        "Floette-Blue",
        "Floette-Orange",
        "Floette-White",
        "Floette-Yellow",
        "Floette-Eternal"
      ]
    },
    "floetteeternal": {
      "num": 670,
      "name": "Floette-Eternal",
      "baseSpecies": "Floette",
      "forme": "Eternal",
      "types": ["Fairy"],
      "gender": "F",
      "baseStats": {
        "hp": 74,
        "atk": 65,
        "def": 67,
        "spa": 125,
        "spd": 128,
        "spe": 92
      },
      "abilities": {"0": "Flower Veil"},
      "weightkg": 0.9,
      "eggGroups": ["Undiscovered"]
    },
    "florges": {
      "num": 671,
      "name": "Florges",
      "baseForme": "Red",
      "types": ["Fairy"],
      "gender": "F",
      "baseStats": {
        "hp": 78,
        "atk": 65,
        "def": 68,
        "spa": 112,
        "spd": 154,
        "spe": 75
      },
      "abilities": {"0": "Flower Veil", "H": "Symbiosis"},
      "weightkg": 10,
      "prevo": "Floette",
      "evoType": "useItem",
      "evoItem": "Shiny Stone",
      "eggGroups": ["Fairy"],
      "cosmeticFormes": [
        "Florges-Blue",
        "Florges-Orange",
        "Florges-White",
        "Florges-Yellow"
      ],
      "formeOrder": [
        "Florges",
        "Florges-Blue",
        "Florges-Orange",
        "Florges-White",
        "Florges-Yellow"
      ]
    },
    "skiddo": {
      "num": 672,
      "name": "Skiddo",
      "types": ["Grass"],
      "baseStats": {
        "hp": 66,
        "atk": 65,
        "def": 48,
        "spa": 62,
        "spd": 57,
        "spe": 52
      },
      "abilities": {"0": "Sap Sipper", "H": "Grass Pelt"},
      "weightkg": 31,
      "evos": ["Gogoat"],
      "eggGroups": ["Field"]
    },
    "gogoat": {
      "num": 673,
      "name": "Gogoat",
      "types": ["Grass"],
      "baseStats": {
        "hp": 123,
        "atk": 100,
        "def": 62,
        "spa": 97,
        "spd": 81,
        "spe": 68
      },
      "abilities": {"0": "Sap Sipper", "H": "Grass Pelt"},
      "weightkg": 91,
      "prevo": "Skiddo",
      "evoLevel": 32,
      "eggGroups": ["Field"]
    },
    "pancham": {
      "num": 674,
      "name": "Pancham",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 67,
        "atk": 82,
        "def": 62,
        "spa": 46,
        "spd": 48,
        "spe": 43
      },
      "abilities": {"0": "Iron Fist", "1": "Mold Breaker", "H": "Scrappy"},
      "weightkg": 8,
      "evos": ["Pangoro"],
      "eggGroups": ["Field", "Human-Like"]
    },
    "pangoro": {
      "num": 675,
      "name": "Pangoro",
      "types": ["Fighting", "Dark"],
      "baseStats": {
        "hp": 95,
        "atk": 124,
        "def": 78,
        "spa": 69,
        "spd": 71,
        "spe": 58
      },
      "abilities": {"0": "Iron Fist", "1": "Mold Breaker", "H": "Scrappy"},
      "weightkg": 136,
      "prevo": "Pancham",
      "evoLevel": 32,
      "evoCondition": "with a Dark-type in the party",
      "eggGroups": ["Field", "Human-Like"]
    },
    "furfrou": {
      "num": 676,
      "name": "Furfrou",
      "baseForme": "Natural",
      "types": ["Normal"],
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 60,
        "spa": 65,
        "spd": 90,
        "spe": 102
      },
      "abilities": {"0": "Fur Coat"},
      "weightkg": 28,
      "eggGroups": ["Field"],
      "cosmeticFormes": [
        "Furfrou-Dandy",
        "Furfrou-Debutante",
        "Furfrou-Diamond",
        "Furfrou-Heart",
        "Furfrou-Kabuki",
        "Furfrou-La Reine",
        "Furfrou-Matron",
        "Furfrou-Pharaoh",
        "Furfrou-Star"
      ],
      "formeOrder": [
        "Furfrou",
        "Furfrou-Heart",
        "Furfrou-Star",
        "Furfrou-Diamond",
        "Furfrou-Debutante",
        "Furfrou-Matron",
        "Furfrou-Dandy",
        "Furfrou-La Reine",
        "Furfrou-Kabuki",
        "Furfrou-Pharaoh"
      ]
    },
    "espurr": {
      "num": 677,
      "name": "Espurr",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 62,
        "atk": 48,
        "def": 54,
        "spa": 63,
        "spd": 60,
        "spe": 68
      },
      "abilities": {"0": "Keen Eye", "1": "Infiltrator", "H": "Own Tempo"},
      "weightkg": 3.5,
      "evos": ["Meowstic", "Meowstic-F"],
      "eggGroups": ["Field"]
    },
    "meowstic": {
      "num": 678,
      "name": "Meowstic",
      "baseForme": "M",
      "types": ["Psychic"],
      "gender": "M",
      "baseStats": {
        "hp": 74,
        "atk": 48,
        "def": 76,
        "spa": 83,
        "spd": 81,
        "spe": 104
      },
      "abilities": {"0": "Keen Eye", "1": "Infiltrator", "H": "Prankster"},
      "weightkg": 8.5,
      "prevo": "Espurr",
      "evoLevel": 25,
      "eggGroups": ["Field"],
      "otherFormes": ["Meowstic-F"],
      "formeOrder": ["Meowstic", "Meowstic-F"]
    },
    "meowsticf": {
      "num": 678,
      "name": "Meowstic-F",
      "baseSpecies": "Meowstic",
      "forme": "F",
      "types": ["Psychic"],
      "gender": "F",
      "baseStats": {
        "hp": 74,
        "atk": 48,
        "def": 76,
        "spa": 83,
        "spd": 81,
        "spe": 104
      },
      "abilities": {"0": "Keen Eye", "1": "Infiltrator", "H": "Competitive"},
      "weightkg": 8.5,
      "prevo": "Espurr",
      "evoLevel": 25,
      "eggGroups": ["Field"]
    },
    "honedge": {
      "num": 679,
      "name": "Honedge",
      "types": ["Steel", "Ghost"],
      "baseStats": {
        "hp": 45,
        "atk": 80,
        "def": 100,
        "spa": 35,
        "spd": 37,
        "spe": 28
      },
      "abilities": {"0": "No Guard"},
      "weightkg": 2,
      "evos": ["Doublade"],
      "eggGroups": ["Mineral"]
    },
    "doublade": {
      "num": 680,
      "name": "Doublade",
      "types": ["Steel", "Ghost"],
      "baseStats": {
        "hp": 59,
        "atk": 110,
        "def": 150,
        "spa": 45,
        "spd": 49,
        "spe": 35
      },
      "abilities": {"0": "No Guard"},
      "weightkg": 4.5,
      "prevo": "Honedge",
      "evoLevel": 35,
      "evos": ["Aegislash"],
      "eggGroups": ["Mineral"]
    },
    "aegislash": {
      "num": 681,
      "name": "Aegislash",
      "baseForme": "Shield",
      "types": ["Steel", "Ghost"],
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 140,
        "spa": 50,
        "spd": 140,
        "spe": 60
      },
      "abilities": {"0": "Stance Change"},
      "weightkg": 53,
      "prevo": "Doublade",
      "evoType": "useItem",
      "evoItem": "Dusk Stone",
      "eggGroups": ["Mineral"],
      "otherFormes": ["Aegislash-Blade"],
      "formeOrder": ["Aegislash", "Aegislash-Blade"]
    },
    "aegislashblade": {
      "num": 681,
      "name": "Aegislash-Blade",
      "baseSpecies": "Aegislash",
      "forme": "Blade",
      "types": ["Steel", "Ghost"],
      "baseStats": {
        "hp": 60,
        "atk": 140,
        "def": 50,
        "spa": 140,
        "spd": 50,
        "spe": 60
      },
      "abilities": {"0": "Stance Change"},
      "weightkg": 53,
      "eggGroups": ["Mineral"],
      "requiredAbility": "Stance Change",
      "battleOnly": "Aegislash"
    },
    "spritzee": {
      "num": 682,
      "name": "Spritzee",
      "types": ["Fairy"],
      "baseStats": {
        "hp": 78,
        "atk": 52,
        "def": 60,
        "spa": 63,
        "spd": 65,
        "spe": 23
      },
      "abilities": {"0": "Healer", "H": "Aroma Veil"},
      "weightkg": 0.5,
      "evos": ["Aromatisse"],
      "eggGroups": ["Fairy"]
    },
    "aromatisse": {
      "num": 683,
      "name": "Aromatisse",
      "types": ["Fairy"],
      "baseStats": {
        "hp": 101,
        "atk": 72,
        "def": 72,
        "spa": 99,
        "spd": 89,
        "spe": 29
      },
      "abilities": {"0": "Healer", "H": "Aroma Veil"},
      "weightkg": 15.5,
      "prevo": "Spritzee",
      "evoType": "trade",
      "evoItem": "Sachet",
      "eggGroups": ["Fairy"]
    },
    "swirlix": {
      "num": 684,
      "name": "Swirlix",
      "types": ["Fairy"],
      "baseStats": {
        "hp": 62,
        "atk": 48,
        "def": 66,
        "spa": 59,
        "spd": 57,
        "spe": 49
      },
      "abilities": {"0": "Sweet Veil", "H": "Unburden"},
      "weightkg": 3.5,
      "evos": ["Slurpuff"],
      "eggGroups": ["Fairy"]
    },
    "slurpuff": {
      "num": 685,
      "name": "Slurpuff",
      "types": ["Fairy"],
      "baseStats": {
        "hp": 82,
        "atk": 80,
        "def": 86,
        "spa": 85,
        "spd": 75,
        "spe": 72
      },
      "abilities": {"0": "Sweet Veil", "H": "Unburden"},
      "weightkg": 5,
      "prevo": "Swirlix",
      "evoType": "trade",
      "evoItem": "Whipped Dream",
      "eggGroups": ["Fairy"]
    },
    "inkay": {
      "num": 686,
      "name": "Inkay",
      "types": ["Dark", "Psychic"],
      "baseStats": {
        "hp": 53,
        "atk": 54,
        "def": 53,
        "spa": 37,
        "spd": 46,
        "spe": 45
      },
      "abilities": {"0": "Contrary", "1": "Suction Cups", "H": "Infiltrator"},
      "weightkg": 3.5,
      "evos": ["Malamar"],
      "eggGroups": ["Water 1", "Water 2"]
    },
    "malamar": {
      "num": 687,
      "name": "Malamar",
      "types": ["Dark", "Psychic"],
      "baseStats": {
        "hp": 86,
        "atk": 92,
        "def": 88,
        "spa": 68,
        "spd": 75,
        "spe": 73
      },
      "abilities": {"0": "Contrary", "1": "Suction Cups", "H": "Infiltrator"},
      "weightkg": 47,
      "prevo": "Inkay",
      "evoLevel": 30,
      "evoCondition": "with the console turned upside-down",
      "eggGroups": ["Water 1", "Water 2"]
    },
    "binacle": {
      "num": 688,
      "name": "Binacle",
      "types": ["Rock", "Water"],
      "baseStats": {
        "hp": 42,
        "atk": 52,
        "def": 67,
        "spa": 39,
        "spd": 56,
        "spe": 50
      },
      "abilities": {"0": "Tough Claws", "1": "Sniper", "H": "Pickpocket"},
      "weightkg": 31,
      "evos": ["Barbaracle"],
      "eggGroups": ["Water 3"]
    },
    "barbaracle": {
      "num": 689,
      "name": "Barbaracle",
      "types": ["Rock", "Water"],
      "baseStats": {
        "hp": 72,
        "atk": 105,
        "def": 115,
        "spa": 54,
        "spd": 86,
        "spe": 68
      },
      "abilities": {"0": "Tough Claws", "1": "Sniper", "H": "Pickpocket"},
      "weightkg": 96,
      "prevo": "Binacle",
      "evoLevel": 39,
      "eggGroups": ["Water 3"]
    },
    "skrelp": {
      "num": 690,
      "name": "Skrelp",
      "types": ["Poison", "Water"],
      "baseStats": {
        "hp": 50,
        "atk": 60,
        "def": 60,
        "spa": 60,
        "spd": 60,
        "spe": 30
      },
      "abilities": {
        "0": "Poison Point",
        "1": "Poison Touch",
        "H": "Adaptability"
      },
      "weightkg": 7.3,
      "evos": ["Dragalge"],
      "eggGroups": ["Water 1", "Dragon"]
    },
    "dragalge": {
      "num": 691,
      "name": "Dragalge",
      "types": ["Poison", "Dragon"],
      "baseStats": {
        "hp": 65,
        "atk": 75,
        "def": 90,
        "spa": 97,
        "spd": 123,
        "spe": 44
      },
      "abilities": {
        "0": "Poison Point",
        "1": "Poison Touch",
        "H": "Adaptability"
      },
      "weightkg": 81.5,
      "prevo": "Skrelp",
      "evoLevel": 48,
      "eggGroups": ["Water 1", "Dragon"]
    },
    "clauncher": {
      "num": 692,
      "name": "Clauncher",
      "types": ["Water"],
      "baseStats": {
        "hp": 50,
        "atk": 53,
        "def": 62,
        "spa": 58,
        "spd": 63,
        "spe": 44
      },
      "abilities": {"0": "Mega Launcher"},
      "weightkg": 8.3,
      "evos": ["Clawitzer"],
      "eggGroups": ["Water 1", "Water 3"]
    },
    "clawitzer": {
      "num": 693,
      "name": "Clawitzer",
      "types": ["Water"],
      "baseStats": {
        "hp": 71,
        "atk": 73,
        "def": 88,
        "spa": 120,
        "spd": 89,
        "spe": 59
      },
      "abilities": {"0": "Mega Launcher"},
      "weightkg": 35.3,
      "prevo": "Clauncher",
      "evoLevel": 37,
      "eggGroups": ["Water 1", "Water 3"]
    },
    "helioptile": {
      "num": 694,
      "name": "Helioptile",
      "types": ["Electric", "Normal"],
      "baseStats": {
        "hp": 44,
        "atk": 38,
        "def": 33,
        "spa": 61,
        "spd": 43,
        "spe": 70
      },
      "abilities": {"0": "Dry Skin", "1": "Sand Veil", "H": "Solar Power"},
      "weightkg": 6,
      "evos": ["Heliolisk"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "heliolisk": {
      "num": 695,
      "name": "Heliolisk",
      "types": ["Electric", "Normal"],
      "baseStats": {
        "hp": 62,
        "atk": 55,
        "def": 52,
        "spa": 109,
        "spd": 94,
        "spe": 109
      },
      "abilities": {"0": "Dry Skin", "1": "Sand Veil", "H": "Solar Power"},
      "weightkg": 21,
      "prevo": "Helioptile",
      "evoType": "useItem",
      "evoItem": "Sun Stone",
      "eggGroups": ["Monster", "Dragon"]
    },
    "tyrunt": {
      "num": 696,
      "name": "Tyrunt",
      "types": ["Rock", "Dragon"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 58,
        "atk": 89,
        "def": 77,
        "spa": 45,
        "spd": 45,
        "spe": 48
      },
      "abilities": {"0": "Strong Jaw", "H": "Sturdy"},
      "weightkg": 26,
      "evos": ["Tyrantrum"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "tyrantrum": {
      "num": 697,
      "name": "Tyrantrum",
      "types": ["Rock", "Dragon"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 82,
        "atk": 121,
        "def": 119,
        "spa": 69,
        "spd": 59,
        "spe": 71
      },
      "abilities": {"0": "Strong Jaw", "H": "Rock Head"},
      "weightkg": 270,
      "prevo": "Tyrunt",
      "evoLevel": 39,
      "evoCondition": "during the day",
      "eggGroups": ["Monster", "Dragon"]
    },
    "amaura": {
      "num": 698,
      "name": "Amaura",
      "types": ["Rock", "Ice"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 77,
        "atk": 59,
        "def": 50,
        "spa": 67,
        "spd": 63,
        "spe": 46
      },
      "abilities": {"0": "Refrigerate", "H": "Snow Warning"},
      "weightkg": 25.2,
      "evos": ["Aurorus"],
      "eggGroups": ["Monster"]
    },
    "aurorus": {
      "num": 699,
      "name": "Aurorus",
      "types": ["Rock", "Ice"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 123,
        "atk": 77,
        "def": 72,
        "spa": 99,
        "spd": 92,
        "spe": 58
      },
      "abilities": {"0": "Refrigerate", "H": "Snow Warning"},
      "weightkg": 225,
      "prevo": "Amaura",
      "evoLevel": 39,
      "evoCondition": "at night",
      "eggGroups": ["Monster"]
    },
    "sylveon": {
      "num": 700,
      "name": "Sylveon",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 95,
        "atk": 65,
        "def": 65,
        "spa": 110,
        "spd": 130,
        "spe": 60
      },
      "abilities": {"0": "Cute Charm", "H": "Pixilate"},
      "weightkg": 23.5,
      "prevo": "Eevee",
      "evoType": "levelExtra",
      "evoCondition": "with a Fairy-type move and two levels of Affection",
      "eggGroups": ["Field"]
    },
    "hawlucha": {
      "num": 701,
      "name": "Hawlucha",
      "types": ["Fighting", "Flying"],
      "baseStats": {
        "hp": 78,
        "atk": 92,
        "def": 75,
        "spa": 74,
        "spd": 63,
        "spe": 118
      },
      "abilities": {"0": "Limber", "1": "Unburden", "H": "Mold Breaker"},
      "weightkg": 21.5,
      "eggGroups": ["Flying", "Human-Like"]
    },
    "dedenne": {
      "num": 702,
      "name": "Dedenne",
      "types": ["Electric", "Fairy"],
      "baseStats": {
        "hp": 67,
        "atk": 58,
        "def": 57,
        "spa": 81,
        "spd": 67,
        "spe": 101
      },
      "abilities": {"0": "Cheek Pouch", "1": "Pickup", "H": "Plus"},
      "weightkg": 2.2,
      "eggGroups": ["Field", "Fairy"]
    },
    "carbink": {
      "num": 703,
      "name": "Carbink",
      "types": ["Rock", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 150,
        "spa": 50,
        "spd": 150,
        "spe": 50
      },
      "abilities": {"0": "Clear Body", "H": "Sturdy"},
      "weightkg": 5.7,
      "eggGroups": ["Fairy", "Mineral"]
    },
    "goomy": {
      "num": 704,
      "name": "Goomy",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 45,
        "atk": 50,
        "def": 35,
        "spa": 55,
        "spd": 75,
        "spe": 40
      },
      "abilities": {"0": "Sap Sipper", "1": "Hydration", "H": "Gooey"},
      "weightkg": 2.8,
      "evos": ["Sliggoo", "Sliggoo-Hisui"],
      "eggGroups": ["Dragon"]
    },
    "sliggoo": {
      "num": 705,
      "name": "Sliggoo",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 68,
        "atk": 75,
        "def": 53,
        "spa": 83,
        "spd": 113,
        "spe": 60
      },
      "abilities": {"0": "Sap Sipper", "1": "Hydration", "H": "Gooey"},
      "weightkg": 17.5,
      "prevo": "Goomy",
      "evoLevel": 40,
      "evos": ["Goodra"],
      "eggGroups": ["Dragon"],
      "otherFormes": ["Sliggoo-Hisui"],
      "formeOrder": ["Sliggoo", "Sliggoo-Hisui"]
    },
    "sliggoohisui": {
      "num": 705,
      "name": "Sliggoo-Hisui",
      "baseSpecies": "Sliggoo",
      "forme": "Hisui",
      "types": ["Steel", "Dragon"],
      "baseStats": {
        "hp": 58,
        "atk": 75,
        "def": 83,
        "spa": 83,
        "spd": 113,
        "spe": 40
      },
      "abilities": {"0": "Sap Sipper", "1": "Shell Armor", "H": "Gooey"},
      "weightkg": 68.5,
      "prevo": "Goomy",
      "evoLevel": 40,
      "evos": ["Goodra-Hisui"],
      "eggGroups": ["Dragon"]
    },
    "goodra": {
      "num": 706,
      "name": "Goodra",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 90,
        "atk": 100,
        "def": 70,
        "spa": 110,
        "spd": 150,
        "spe": 80
      },
      "abilities": {"0": "Sap Sipper", "1": "Hydration", "H": "Gooey"},
      "weightkg": 150.5,
      "prevo": "Sliggoo",
      "evoLevel": 50,
      "eggGroups": ["Dragon"],
      "otherFormes": ["Goodra-Hisui"],
      "formeOrder": ["Goodra", "Goodra-Hisui"]
    },
    "goodrahisui": {
      "num": 706,
      "name": "Goodra-Hisui",
      "baseSpecies": "Goodra",
      "forme": "Hisui",
      "types": ["Steel", "Dragon"],
      "baseStats": {
        "hp": 80,
        "atk": 100,
        "def": 100,
        "spa": 110,
        "spd": 150,
        "spe": 60
      },
      "abilities": {"0": "Sap Sipper", "1": "Shell Armor", "H": "Gooey"},
      "weightkg": 334.1,
      "prevo": "Sliggoo-Hisui",
      "evoLevel": 50,
      "eggGroups": ["Dragon"]
    },
    "klefki": {
      "num": 707,
      "name": "Klefki",
      "types": ["Steel", "Fairy"],
      "baseStats": {
        "hp": 57,
        "atk": 80,
        "def": 91,
        "spa": 80,
        "spd": 87,
        "spe": 75
      },
      "abilities": {"0": "Prankster", "H": "Magician"},
      "weightkg": 3,
      "eggGroups": ["Mineral"]
    },
    "phantump": {
      "num": 708,
      "name": "Phantump",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 43,
        "atk": 70,
        "def": 48,
        "spa": 50,
        "spd": 60,
        "spe": 38
      },
      "abilities": {"0": "Natural Cure", "1": "Frisk", "H": "Harvest"},
      "weightkg": 7,
      "evos": ["Trevenant"],
      "eggGroups": ["Grass", "Amorphous"]
    },
    "trevenant": {
      "num": 709,
      "name": "Trevenant",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 85,
        "atk": 110,
        "def": 76,
        "spa": 65,
        "spd": 82,
        "spe": 56
      },
      "abilities": {"0": "Natural Cure", "1": "Frisk", "H": "Harvest"},
      "weightkg": 71,
      "prevo": "Phantump",
      "evoType": "trade",
      "eggGroups": ["Grass", "Amorphous"]
    },
    "pumpkaboo": {
      "num": 710,
      "name": "Pumpkaboo",
      "baseForme": "Average",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 49,
        "atk": 66,
        "def": 70,
        "spa": 44,
        "spd": 55,
        "spe": 51
      },
      "abilities": {"0": "Pickup", "1": "Frisk", "H": "Insomnia"},
      "weightkg": 5,
      "evos": ["Gourgeist"],
      "eggGroups": ["Amorphous"],
      "otherFormes": ["Pumpkaboo-Small", "Pumpkaboo-Large", "Pumpkaboo-Super"],
      "formeOrder": [
        "Pumpkaboo",
        "Pumpkaboo-Small",
        "Pumpkaboo-Large",
        "Pumpkaboo-Super"
      ]
    },
    "pumpkaboosmall": {
      "num": 710,
      "name": "Pumpkaboo-Small",
      "baseSpecies": "Pumpkaboo",
      "forme": "Small",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 44,
        "atk": 66,
        "def": 70,
        "spa": 44,
        "spd": 55,
        "spe": 56
      },
      "abilities": {"0": "Pickup", "1": "Frisk", "H": "Insomnia"},
      "weightkg": 3.5,
      "evos": ["Gourgeist-Small"],
      "eggGroups": ["Amorphous"]
    },
    "pumpkaboolarge": {
      "num": 710,
      "name": "Pumpkaboo-Large",
      "baseSpecies": "Pumpkaboo",
      "forme": "Large",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 54,
        "atk": 66,
        "def": 70,
        "spa": 44,
        "spd": 55,
        "spe": 46
      },
      "abilities": {"0": "Pickup", "1": "Frisk", "H": "Insomnia"},
      "weightkg": 7.5,
      "evos": ["Gourgeist-Large"],
      "eggGroups": ["Amorphous"]
    },
    "pumpkaboosuper": {
      "num": 710,
      "name": "Pumpkaboo-Super",
      "baseSpecies": "Pumpkaboo",
      "forme": "Super",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 59,
        "atk": 66,
        "def": 70,
        "spa": 44,
        "spd": 55,
        "spe": 41
      },
      "abilities": {"0": "Pickup", "1": "Frisk", "H": "Insomnia"},
      "weightkg": 15,
      "evos": ["Gourgeist-Super"],
      "eggGroups": ["Amorphous"]
    },
    "gourgeist": {
      "num": 711,
      "name": "Gourgeist",
      "baseForme": "Average",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 65,
        "atk": 90,
        "def": 122,
        "spa": 58,
        "spd": 75,
        "spe": 84
      },
      "abilities": {"0": "Pickup", "1": "Frisk", "H": "Insomnia"},
      "weightkg": 12.5,
      "prevo": "Pumpkaboo",
      "evoType": "trade",
      "eggGroups": ["Amorphous"],
      "otherFormes": ["Gourgeist-Small", "Gourgeist-Large", "Gourgeist-Super"],
      "formeOrder": [
        "Gourgeist",
        "Gourgeist-Small",
        "Gourgeist-Large",
        "Gourgeist-Super"
      ]
    },
    "gourgeistsmall": {
      "num": 711,
      "name": "Gourgeist-Small",
      "baseSpecies": "Gourgeist",
      "forme": "Small",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 55,
        "atk": 85,
        "def": 122,
        "spa": 58,
        "spd": 75,
        "spe": 99
      },
      "abilities": {"0": "Pickup", "1": "Frisk", "H": "Insomnia"},
      "weightkg": 9.5,
      "prevo": "Pumpkaboo-Small",
      "evoType": "trade",
      "eggGroups": ["Amorphous"]
    },
    "gourgeistlarge": {
      "num": 711,
      "name": "Gourgeist-Large",
      "baseSpecies": "Gourgeist",
      "forme": "Large",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 75,
        "atk": 95,
        "def": 122,
        "spa": 58,
        "spd": 75,
        "spe": 69
      },
      "abilities": {"0": "Pickup", "1": "Frisk", "H": "Insomnia"},
      "weightkg": 14,
      "prevo": "Pumpkaboo-Large",
      "evoType": "trade",
      "eggGroups": ["Amorphous"]
    },
    "gourgeistsuper": {
      "num": 711,
      "name": "Gourgeist-Super",
      "baseSpecies": "Gourgeist",
      "forme": "Super",
      "types": ["Ghost", "Grass"],
      "baseStats": {
        "hp": 85,
        "atk": 100,
        "def": 122,
        "spa": 58,
        "spd": 75,
        "spe": 54
      },
      "abilities": {"0": "Pickup", "1": "Frisk", "H": "Insomnia"},
      "weightkg": 39,
      "prevo": "Pumpkaboo-Super",
      "evoType": "trade",
      "eggGroups": ["Amorphous"]
    },
    "bergmite": {
      "num": 712,
      "name": "Bergmite",
      "types": ["Ice"],
      "baseStats": {
        "hp": 55,
        "atk": 69,
        "def": 85,
        "spa": 32,
        "spd": 35,
        "spe": 28
      },
      "abilities": {"0": "Own Tempo", "1": "Ice Body", "H": "Sturdy"},
      "weightkg": 99.5,
      "evos": ["Avalugg", "Avalugg-Hisui"],
      "eggGroups": ["Monster", "Mineral"]
    },
    "avalugg": {
      "num": 713,
      "name": "Avalugg",
      "types": ["Ice"],
      "baseStats": {
        "hp": 95,
        "atk": 117,
        "def": 184,
        "spa": 44,
        "spd": 46,
        "spe": 28
      },
      "abilities": {"0": "Own Tempo", "1": "Ice Body", "H": "Sturdy"},
      "weightkg": 505,
      "prevo": "Bergmite",
      "evoLevel": 37,
      "eggGroups": ["Monster", "Mineral"],
      "otherFormes": ["Avalugg-Hisui"],
      "formeOrder": ["Avalugg", "Avalugg-Hisui"]
    },
    "avalugghisui": {
      "num": 713,
      "name": "Avalugg-Hisui",
      "baseSpecies": "Avalugg",
      "forme": "Hisui",
      "types": ["Ice", "Rock"],
      "baseStats": {
        "hp": 95,
        "atk": 127,
        "def": 184,
        "spa": 34,
        "spd": 36,
        "spe": 38
      },
      "abilities": {"0": "Strong Jaw", "1": "Ice Body", "H": "Sturdy"},
      "weightkg": 262.4,
      "prevo": "Bergmite",
      "evoLevel": 37,
      "eggGroups": ["Monster", "Mineral"]
    },
    "noibat": {
      "num": 714,
      "name": "Noibat",
      "types": ["Flying", "Dragon"],
      "baseStats": {
        "hp": 40,
        "atk": 30,
        "def": 35,
        "spa": 45,
        "spd": 40,
        "spe": 55
      },
      "abilities": {"0": "Frisk", "1": "Infiltrator", "H": "Telepathy"},
      "weightkg": 8,
      "evos": ["Noivern"],
      "eggGroups": ["Flying", "Dragon"]
    },
    "noivern": {
      "num": 715,
      "name": "Noivern",
      "types": ["Flying", "Dragon"],
      "baseStats": {
        "hp": 85,
        "atk": 70,
        "def": 80,
        "spa": 97,
        "spd": 80,
        "spe": 123
      },
      "abilities": {"0": "Frisk", "1": "Infiltrator", "H": "Telepathy"},
      "weightkg": 85,
      "prevo": "Noibat",
      "evoLevel": 48,
      "eggGroups": ["Flying", "Dragon"]
    },
    "xerneas": {
      "num": 716,
      "name": "Xerneas",
      "baseForme": "Active",
      "types": ["Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 126,
        "atk": 131,
        "def": 95,
        "spa": 131,
        "spd": 98,
        "spe": 99
      },
      "abilities": {"0": "Fairy Aura"},
      "weightkg": 215,
      "eggGroups": ["Undiscovered"],
      "tags": ["Restricted Legendary"],
      "otherFormes": ["Xerneas-Neutral"],
      "formeOrder": ["Xerneas-Neutral", "Xerneas"]
    },
    "xerneasneutral": {
      "num": 716,
      "name": "Xerneas-Neutral",
      "baseSpecies": "Xerneas",
      "forme": "Neutral",
      "types": ["Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 126,
        "atk": 131,
        "def": 95,
        "spa": 131,
        "spd": 98,
        "spe": 99
      },
      "abilities": {"0": "Fairy Aura"},
      "weightkg": 215,
      "eggGroups": ["Undiscovered"]
    },
    "yveltal": {
      "num": 717,
      "name": "Yveltal",
      "types": ["Dark", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 126,
        "atk": 131,
        "def": 95,
        "spa": 131,
        "spd": 98,
        "spe": 99
      },
      "abilities": {"0": "Dark Aura"},
      "weightkg": 203,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "zygarde": {
      "num": 718,
      "name": "Zygarde",
      "baseForme": "50%",
      "types": ["Dragon", "Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 108,
        "atk": 100,
        "def": 121,
        "spa": 81,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "Aura Break", "S": "Power Construct"},
      "weightkg": 305,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Zygarde-10%", "Zygarde-Complete"],
      "formeOrder": [
        "Zygarde",
        "Zygarde-10%",
        "Zygarde-10%",
        "Zygarde",
        "Zygarde-Complete"
      ]
    },
    "zygarde10": {
      "num": 718,
      "name": "Zygarde-10%",
      "baseSpecies": "Zygarde",
      "forme": "10%",
      "types": ["Dragon", "Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 54,
        "atk": 100,
        "def": 71,
        "spa": 61,
        "spd": 85,
        "spe": 115
      },
      "abilities": {"0": "Aura Break", "S": "Power Construct"},
      "weightkg": 33.5,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Zygarde",
      "gen": 7
    },
    "zygardecomplete": {
      "num": 718,
      "name": "Zygarde-Complete",
      "baseSpecies": "Zygarde",
      "forme": "Complete",
      "types": ["Dragon", "Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 216,
        "atk": 100,
        "def": 121,
        "spa": 91,
        "spd": 95,
        "spe": 85
      },
      "abilities": {"0": "Power Construct"},
      "weightkg": 610,
      "eggGroups": ["Undiscovered"],
      "requiredAbility": "Power Construct",
      "battleOnly": ["Zygarde", "Zygarde-10%"],
      "gen": 7
    },
    "diancie": {
      "num": 719,
      "name": "Diancie",
      "types": ["Rock", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 100,
        "def": 150,
        "spa": 100,
        "spd": 150,
        "spe": 50
      },
      "abilities": {"0": "Clear Body"},
      "weightkg": 8.8,
      "eggGroups": ["Undiscovered"],
      "tags": ["Mythical"],
      "otherFormes": ["Diancie-Mega"],
      "formeOrder": ["Diancie", "Diancie-Mega"]
    },
    "dianciemega": {
      "num": 719,
      "name": "Diancie-Mega",
      "baseSpecies": "Diancie",
      "forme": "Mega",
      "types": ["Rock", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 160,
        "def": 110,
        "spa": 160,
        "spd": 110,
        "spe": 110
      },
      "abilities": {"0": "Magic Bounce"},
      "weightkg": 27.8,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Diancite"
    },
    "hoopa": {
      "num": 720,
      "name": "Hoopa",
      "baseForme": "Confined",
      "types": ["Psychic", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 110,
        "def": 60,
        "spa": 150,
        "spd": 130,
        "spe": 70
      },
      "abilities": {"0": "Magician"},
      "weightkg": 9,
      "eggGroups": ["Undiscovered"],
      "tags": ["Mythical"],
      "otherFormes": ["Hoopa-Unbound"],
      "formeOrder": ["Hoopa", "Hoopa-Unbound"]
    },
    "hoopaunbound": {
      "num": 720,
      "name": "Hoopa-Unbound",
      "baseSpecies": "Hoopa",
      "forme": "Unbound",
      "types": ["Psychic", "Dark"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 160,
        "def": 60,
        "spa": 170,
        "spd": 130,
        "spe": 80
      },
      "abilities": {"0": "Magician"},
      "weightkg": 490,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Hoopa"
    },
    "volcanion": {
      "num": 721,
      "name": "Volcanion",
      "types": ["Fire", "Water"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 110,
        "def": 120,
        "spa": 130,
        "spd": 90,
        "spe": 70
      },
      "abilities": {"0": "Water Absorb"},
      "weightkg": 195,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"]
    },
    "rowlet": {
      "num": 722,
      "name": "Rowlet",
      "types": ["Grass", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 68,
        "atk": 55,
        "def": 55,
        "spa": 50,
        "spd": 50,
        "spe": 42
      },
      "abilities": {"0": "Overgrow", "H": "Long Reach"},
      "weightkg": 1.5,
      "evos": ["Dartrix"],
      "eggGroups": ["Flying"]
    },
    "dartrix": {
      "num": 723,
      "name": "Dartrix",
      "types": ["Grass", "Flying"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 78,
        "atk": 75,
        "def": 75,
        "spa": 70,
        "spd": 70,
        "spe": 52
      },
      "abilities": {"0": "Overgrow", "H": "Long Reach"},
      "weightkg": 16,
      "prevo": "Rowlet",
      "evoLevel": 17,
      "evos": ["Decidueye", "Decidueye-Hisui"],
      "eggGroups": ["Flying"]
    },
    "decidueye": {
      "num": 724,
      "name": "Decidueye",
      "types": ["Grass", "Ghost"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 78,
        "atk": 107,
        "def": 75,
        "spa": 100,
        "spd": 100,
        "spe": 70
      },
      "abilities": {"0": "Overgrow", "H": "Long Reach"},
      "weightkg": 36.6,
      "prevo": "Dartrix",
      "evoLevel": 34,
      "eggGroups": ["Flying"],
      "otherFormes": ["Decidueye-Hisui"],
      "formeOrder": ["Decidueye", "Decidueye-Hisui"]
    },
    "decidueyehisui": {
      "num": 724,
      "name": "Decidueye-Hisui",
      "baseSpecies": "Decidueye",
      "forme": "Hisui",
      "types": ["Grass", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 88,
        "atk": 112,
        "def": 80,
        "spa": 95,
        "spd": 95,
        "spe": 60
      },
      "abilities": {"0": "Overgrow", "H": "Scrappy"},
      "weightkg": 37,
      "prevo": "Dartrix",
      "evoLevel": 36,
      "eggGroups": ["Flying"]
    },
    "litten": {
      "num": 725,
      "name": "Litten",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 45,
        "atk": 65,
        "def": 40,
        "spa": 60,
        "spd": 40,
        "spe": 70
      },
      "abilities": {"0": "Blaze", "H": "Intimidate"},
      "weightkg": 4.3,
      "evos": ["Torracat"],
      "eggGroups": ["Field"]
    },
    "torracat": {
      "num": 726,
      "name": "Torracat",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 85,
        "def": 50,
        "spa": 80,
        "spd": 50,
        "spe": 90
      },
      "abilities": {"0": "Blaze", "H": "Intimidate"},
      "weightkg": 25,
      "prevo": "Litten",
      "evoLevel": 17,
      "evos": ["Incineroar"],
      "eggGroups": ["Field"]
    },
    "incineroar": {
      "num": 727,
      "name": "Incineroar",
      "types": ["Fire", "Dark"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 95,
        "atk": 115,
        "def": 90,
        "spa": 80,
        "spd": 90,
        "spe": 60
      },
      "abilities": {"0": "Blaze", "H": "Intimidate"},
      "weightkg": 83,
      "prevo": "Torracat",
      "evoLevel": 34,
      "eggGroups": ["Field"]
    },
    "popplio": {
      "num": 728,
      "name": "Popplio",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 54,
        "def": 54,
        "spa": 66,
        "spd": 56,
        "spe": 40
      },
      "abilities": {"0": "Torrent", "H": "Liquid Voice"},
      "weightkg": 7.5,
      "evos": ["Brionne"],
      "eggGroups": ["Water 1", "Field"]
    },
    "brionne": {
      "num": 729,
      "name": "Brionne",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 60,
        "atk": 69,
        "def": 69,
        "spa": 91,
        "spd": 81,
        "spe": 50
      },
      "abilities": {"0": "Torrent", "H": "Liquid Voice"},
      "weightkg": 17.5,
      "prevo": "Popplio",
      "evoLevel": 17,
      "evos": ["Primarina"],
      "eggGroups": ["Water 1", "Field"]
    },
    "primarina": {
      "num": 730,
      "name": "Primarina",
      "types": ["Water", "Fairy"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 74,
        "def": 74,
        "spa": 126,
        "spd": 116,
        "spe": 60
      },
      "abilities": {"0": "Torrent", "H": "Liquid Voice"},
      "weightkg": 44,
      "prevo": "Brionne",
      "evoLevel": 34,
      "eggGroups": ["Water 1", "Field"]
    },
    "pikipek": {
      "num": 731,
      "name": "Pikipek",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 35,
        "atk": 75,
        "def": 30,
        "spa": 30,
        "spd": 30,
        "spe": 65
      },
      "abilities": {"0": "Keen Eye", "1": "Skill Link", "H": "Pickup"},
      "weightkg": 1.2,
      "evos": ["Trumbeak"],
      "eggGroups": ["Flying"]
    },
    "trumbeak": {
      "num": 732,
      "name": "Trumbeak",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 55,
        "atk": 85,
        "def": 50,
        "spa": 40,
        "spd": 50,
        "spe": 75
      },
      "abilities": {"0": "Keen Eye", "1": "Skill Link", "H": "Pickup"},
      "weightkg": 14.8,
      "prevo": "Pikipek",
      "evoLevel": 14,
      "evos": ["Toucannon"],
      "eggGroups": ["Flying"]
    },
    "toucannon": {
      "num": 733,
      "name": "Toucannon",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 75,
        "spa": 75,
        "spd": 75,
        "spe": 60
      },
      "abilities": {"0": "Keen Eye", "1": "Skill Link", "H": "Sheer Force"},
      "weightkg": 26,
      "prevo": "Trumbeak",
      "evoLevel": 28,
      "eggGroups": ["Flying"]
    },
    "yungoos": {
      "num": 734,
      "name": "Yungoos",
      "types": ["Normal"],
      "baseStats": {
        "hp": 48,
        "atk": 70,
        "def": 30,
        "spa": 30,
        "spd": 30,
        "spe": 45
      },
      "abilities": {"0": "Stakeout", "1": "Strong Jaw", "H": "Adaptability"},
      "weightkg": 6,
      "evos": ["Gumshoos"],
      "eggGroups": ["Field"]
    },
    "gumshoos": {
      "num": 735,
      "name": "Gumshoos",
      "types": ["Normal"],
      "baseStats": {
        "hp": 88,
        "atk": 110,
        "def": 60,
        "spa": 55,
        "spd": 60,
        "spe": 45
      },
      "abilities": {"0": "Stakeout", "1": "Strong Jaw", "H": "Adaptability"},
      "weightkg": 14.2,
      "prevo": "Yungoos",
      "evoLevel": 20,
      "evoCondition": "during the day",
      "eggGroups": ["Field"],
      "otherFormes": ["Gumshoos-Totem"],
      "formeOrder": ["Gumshoos", "Gumshoos-Totem"]
    },
    "gumshoostotem": {
      "num": 735,
      "name": "Gumshoos-Totem",
      "baseSpecies": "Gumshoos",
      "forme": "Totem",
      "types": ["Normal"],
      "baseStats": {
        "hp": 88,
        "atk": 110,
        "def": 60,
        "spa": 55,
        "spd": 60,
        "spe": 45
      },
      "abilities": {"0": "Adaptability"},
      "weightkg": 60,
      "eggGroups": ["Field"]
    },
    "grubbin": {
      "num": 736,
      "name": "Grubbin",
      "types": ["Bug"],
      "baseStats": {
        "hp": 47,
        "atk": 62,
        "def": 45,
        "spa": 55,
        "spd": 45,
        "spe": 46
      },
      "abilities": {"0": "Swarm"},
      "weightkg": 4.4,
      "evos": ["Charjabug"],
      "eggGroups": ["Bug"]
    },
    "charjabug": {
      "num": 737,
      "name": "Charjabug",
      "types": ["Bug", "Electric"],
      "baseStats": {
        "hp": 57,
        "atk": 82,
        "def": 95,
        "spa": 55,
        "spd": 75,
        "spe": 36
      },
      "abilities": {"0": "Battery"},
      "weightkg": 10.5,
      "prevo": "Grubbin",
      "evoLevel": 20,
      "evos": ["Vikavolt"],
      "eggGroups": ["Bug"]
    },
    "vikavolt": {
      "num": 738,
      "name": "Vikavolt",
      "types": ["Bug", "Electric"],
      "baseStats": {
        "hp": 77,
        "atk": 70,
        "def": 90,
        "spa": 145,
        "spd": 75,
        "spe": 43
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 45,
      "prevo": "Charjabug",
      "evoType": "useItem",
      "evoItem": "Thunder Stone",
      "eggGroups": ["Bug"],
      "otherFormes": ["Vikavolt-Totem"],
      "formeOrder": ["Vikavolt", "Vikavolt-Totem"]
    },
    "vikavolttotem": {
      "num": 738,
      "name": "Vikavolt-Totem",
      "baseSpecies": "Vikavolt",
      "forme": "Totem",
      "types": ["Bug", "Electric"],
      "baseStats": {
        "hp": 77,
        "atk": 70,
        "def": 90,
        "spa": 145,
        "spd": 75,
        "spe": 43
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 147.5,
      "eggGroups": ["Bug"]
    },
    "crabrawler": {
      "num": 739,
      "name": "Crabrawler",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 47,
        "atk": 82,
        "def": 57,
        "spa": 42,
        "spd": 47,
        "spe": 63
      },
      "abilities": {"0": "Hyper Cutter", "1": "Iron Fist", "H": "Anger Point"},
      "weightkg": 7,
      "evos": ["Crabominable"],
      "eggGroups": ["Water 3"]
    },
    "crabominable": {
      "num": 740,
      "name": "Crabominable",
      "types": ["Fighting", "Ice"],
      "baseStats": {
        "hp": 97,
        "atk": 132,
        "def": 77,
        "spa": 62,
        "spd": 67,
        "spe": 43
      },
      "abilities": {"0": "Hyper Cutter", "1": "Iron Fist", "H": "Anger Point"},
      "weightkg": 180,
      "prevo": "Crabrawler",
      "evoType": "useItem",
      "evoItem": "Ice Stone",
      "eggGroups": ["Water 3"]
    },
    "oricorio": {
      "num": 741,
      "name": "Oricorio",
      "baseForme": "Baile",
      "types": ["Fire", "Flying"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 75,
        "atk": 70,
        "def": 70,
        "spa": 98,
        "spd": 70,
        "spe": 93
      },
      "abilities": {"0": "Dancer"},
      "weightkg": 3.4,
      "eggGroups": ["Flying"],
      "otherFormes": ["Oricorio-Pom-Pom", "Oricorio-Pa'u", "Oricorio-Sensu"],
      "formeOrder": [
        "Oricorio",
        "Oricorio-Pom-Pom",
        "Oricorio-Pa'u",
        "Oricorio-Sensu"
      ]
    },
    "oricoriopompom": {
      "num": 741,
      "name": "Oricorio-Pom-Pom",
      "baseSpecies": "Oricorio",
      "forme": "Pom-Pom",
      "types": ["Electric", "Flying"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 75,
        "atk": 70,
        "def": 70,
        "spa": 98,
        "spd": 70,
        "spe": 93
      },
      "abilities": {"0": "Dancer"},
      "weightkg": 3.4,
      "eggGroups": ["Flying"],
      "changesFrom": "Oricorio"
    },
    "oricoriopau": {
      "num": 741,
      "name": "Oricorio-Pa'u",
      "baseSpecies": "Oricorio",
      "forme": "Pa'u",
      "types": ["Psychic", "Flying"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 75,
        "atk": 70,
        "def": 70,
        "spa": 98,
        "spd": 70,
        "spe": 93
      },
      "abilities": {"0": "Dancer"},
      "weightkg": 3.4,
      "eggGroups": ["Flying"],
      "changesFrom": "Oricorio"
    },
    "oricoriosensu": {
      "num": 741,
      "name": "Oricorio-Sensu",
      "baseSpecies": "Oricorio",
      "forme": "Sensu",
      "types": ["Ghost", "Flying"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 75,
        "atk": 70,
        "def": 70,
        "spa": 98,
        "spd": 70,
        "spe": 93
      },
      "abilities": {"0": "Dancer"},
      "weightkg": 3.4,
      "eggGroups": ["Flying"],
      "changesFrom": "Oricorio"
    },
    "cutiefly": {
      "num": 742,
      "name": "Cutiefly",
      "types": ["Bug", "Fairy"],
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 40,
        "spa": 55,
        "spd": 40,
        "spe": 84
      },
      "abilities": {"0": "Honey Gather", "1": "Shield Dust", "H": "Sweet Veil"},
      "weightkg": 0.2,
      "evos": ["Ribombee"],
      "eggGroups": ["Bug", "Fairy"]
    },
    "ribombee": {
      "num": 743,
      "name": "Ribombee",
      "types": ["Bug", "Fairy"],
      "baseStats": {
        "hp": 60,
        "atk": 55,
        "def": 60,
        "spa": 95,
        "spd": 70,
        "spe": 124
      },
      "abilities": {"0": "Honey Gather", "1": "Shield Dust", "H": "Sweet Veil"},
      "weightkg": 0.5,
      "prevo": "Cutiefly",
      "evoLevel": 25,
      "eggGroups": ["Bug", "Fairy"],
      "otherFormes": ["Ribombee-Totem"],
      "formeOrder": ["Ribombee", "Ribombee-Totem"]
    },
    "ribombeetotem": {
      "num": 743,
      "name": "Ribombee-Totem",
      "baseSpecies": "Ribombee",
      "forme": "Totem",
      "types": ["Bug", "Fairy"],
      "baseStats": {
        "hp": 60,
        "atk": 55,
        "def": 60,
        "spa": 95,
        "spd": 70,
        "spe": 124
      },
      "abilities": {"0": "Sweet Veil"},
      "weightkg": 2,
      "eggGroups": ["Bug", "Fairy"]
    },
    "rockruff": {
      "num": 744,
      "name": "Rockruff",
      "baseForme": "Midday",
      "types": ["Rock"],
      "baseStats": {
        "hp": 45,
        "atk": 65,
        "def": 40,
        "spa": 30,
        "spd": 40,
        "spe": 60
      },
      "abilities": {
        "0": "Keen Eye",
        "1": "Vital Spirit",
        "H": "Steadfast",
        "S": "Own Tempo"
      },
      "weightkg": 9.2,
      "evos": ["Lycanroc", "Lycanroc-Midnight", "Lycanroc-Dusk"],
      "eggGroups": ["Field"],
      "formeOrder": ["Rockruff", "Rockruff"]
    },
    "lycanroc": {
      "num": 745,
      "name": "Lycanroc",
      "baseForme": "Midday",
      "types": ["Rock"],
      "baseStats": {
        "hp": 75,
        "atk": 115,
        "def": 65,
        "spa": 55,
        "spd": 65,
        "spe": 112
      },
      "abilities": {"0": "Keen Eye", "1": "Sand Rush", "H": "Steadfast"},
      "weightkg": 25,
      "prevo": "Rockruff",
      "evoLevel": 25,
      "evoCondition": "during the day",
      "eggGroups": ["Field"],
      "otherFormes": ["Lycanroc-Midnight", "Lycanroc-Dusk"],
      "formeOrder": ["Lycanroc", "Lycanroc-Midnight", "Lycanroc-Dusk"]
    },
    "lycanrocmidnight": {
      "num": 745,
      "name": "Lycanroc-Midnight",
      "baseSpecies": "Lycanroc",
      "forme": "Midnight",
      "types": ["Rock"],
      "baseStats": {
        "hp": 85,
        "atk": 115,
        "def": 75,
        "spa": 55,
        "spd": 75,
        "spe": 82
      },
      "abilities": {"0": "Keen Eye", "1": "Vital Spirit", "H": "No Guard"},
      "weightkg": 25,
      "prevo": "Rockruff",
      "evoLevel": 25,
      "evoCondition": "at night",
      "eggGroups": ["Field"]
    },
    "lycanrocdusk": {
      "num": 745,
      "name": "Lycanroc-Dusk",
      "baseSpecies": "Lycanroc",
      "forme": "Dusk",
      "types": ["Rock"],
      "baseStats": {
        "hp": 75,
        "atk": 117,
        "def": 65,
        "spa": 55,
        "spd": 65,
        "spe": 110
      },
      "abilities": {"0": "Tough Claws"},
      "weightkg": 25,
      "prevo": "Rockruff",
      "evoLevel": 25,
      "evoCondition": "from a special Rockruff",
      "eggGroups": ["Field"]
    },
    "wishiwashi": {
      "num": 746,
      "name": "Wishiwashi",
      "baseForme": "Solo",
      "types": ["Water"],
      "baseStats": {
        "hp": 45,
        "atk": 20,
        "def": 20,
        "spa": 25,
        "spd": 25,
        "spe": 40
      },
      "abilities": {"0": "Schooling"},
      "weightkg": 0.3,
      "eggGroups": ["Water 2"],
      "otherFormes": ["Wishiwashi-School"],
      "formeOrder": ["Wishiwashi", "Wishiwashi-School"]
    },
    "wishiwashischool": {
      "num": 746,
      "name": "Wishiwashi-School",
      "baseSpecies": "Wishiwashi",
      "forme": "School",
      "types": ["Water"],
      "baseStats": {
        "hp": 45,
        "atk": 140,
        "def": 130,
        "spa": 140,
        "spd": 135,
        "spe": 30
      },
      "abilities": {"0": "Schooling"},
      "weightkg": 78.6,
      "eggGroups": ["Water 2"],
      "requiredAbility": "Schooling",
      "battleOnly": "Wishiwashi"
    },
    "mareanie": {
      "num": 747,
      "name": "Mareanie",
      "types": ["Poison", "Water"],
      "baseStats": {
        "hp": 50,
        "atk": 53,
        "def": 62,
        "spa": 43,
        "spd": 52,
        "spe": 45
      },
      "abilities": {"0": "Merciless", "1": "Limber", "H": "Regenerator"},
      "weightkg": 8,
      "evos": ["Toxapex"],
      "eggGroups": ["Water 1"]
    },
    "toxapex": {
      "num": 748,
      "name": "Toxapex",
      "types": ["Poison", "Water"],
      "baseStats": {
        "hp": 50,
        "atk": 63,
        "def": 152,
        "spa": 53,
        "spd": 142,
        "spe": 35
      },
      "abilities": {"0": "Merciless", "1": "Limber", "H": "Regenerator"},
      "weightkg": 14.5,
      "prevo": "Mareanie",
      "evoLevel": 38,
      "eggGroups": ["Water 1"]
    },
    "mudbray": {
      "num": 749,
      "name": "Mudbray",
      "types": ["Ground"],
      "baseStats": {
        "hp": 70,
        "atk": 100,
        "def": 70,
        "spa": 45,
        "spd": 55,
        "spe": 45
      },
      "abilities": {"0": "Own Tempo", "1": "Stamina", "H": "Inner Focus"},
      "weightkg": 110,
      "evos": ["Mudsdale"],
      "eggGroups": ["Field"]
    },
    "mudsdale": {
      "num": 750,
      "name": "Mudsdale",
      "types": ["Ground"],
      "baseStats": {
        "hp": 100,
        "atk": 125,
        "def": 100,
        "spa": 55,
        "spd": 85,
        "spe": 35
      },
      "abilities": {"0": "Own Tempo", "1": "Stamina", "H": "Inner Focus"},
      "weightkg": 920,
      "prevo": "Mudbray",
      "evoLevel": 30,
      "eggGroups": ["Field"]
    },
    "dewpider": {
      "num": 751,
      "name": "Dewpider",
      "types": ["Water", "Bug"],
      "baseStats": {
        "hp": 38,
        "atk": 40,
        "def": 52,
        "spa": 40,
        "spd": 72,
        "spe": 27
      },
      "abilities": {"0": "Water Bubble", "H": "Water Absorb"},
      "weightkg": 4,
      "evos": ["Araquanid"],
      "eggGroups": ["Water 1", "Bug"]
    },
    "araquanid": {
      "num": 752,
      "name": "Araquanid",
      "types": ["Water", "Bug"],
      "baseStats": {
        "hp": 68,
        "atk": 70,
        "def": 92,
        "spa": 50,
        "spd": 132,
        "spe": 42
      },
      "abilities": {"0": "Water Bubble", "H": "Water Absorb"},
      "weightkg": 82,
      "prevo": "Dewpider",
      "evoLevel": 22,
      "eggGroups": ["Water 1", "Bug"],
      "otherFormes": ["Araquanid-Totem"],
      "formeOrder": ["Araquanid", "Araquanid-Totem"]
    },
    "araquanidtotem": {
      "num": 752,
      "name": "Araquanid-Totem",
      "baseSpecies": "Araquanid",
      "forme": "Totem",
      "types": ["Water", "Bug"],
      "baseStats": {
        "hp": 68,
        "atk": 70,
        "def": 92,
        "spa": 50,
        "spd": 132,
        "spe": 42
      },
      "abilities": {"0": "Water Bubble"},
      "weightkg": 217.5,
      "eggGroups": ["Water 1", "Bug"]
    },
    "fomantis": {
      "num": 753,
      "name": "Fomantis",
      "types": ["Grass"],
      "baseStats": {
        "hp": 40,
        "atk": 55,
        "def": 35,
        "spa": 50,
        "spd": 35,
        "spe": 35
      },
      "abilities": {"0": "Leaf Guard", "H": "Contrary"},
      "weightkg": 1.5,
      "evos": ["Lurantis"],
      "eggGroups": ["Grass"]
    },
    "lurantis": {
      "num": 754,
      "name": "Lurantis",
      "types": ["Grass"],
      "baseStats": {
        "hp": 70,
        "atk": 105,
        "def": 90,
        "spa": 80,
        "spd": 90,
        "spe": 45
      },
      "abilities": {"0": "Leaf Guard", "H": "Contrary"},
      "weightkg": 18.5,
      "prevo": "Fomantis",
      "evoLevel": 34,
      "evoCondition": "during the day",
      "eggGroups": ["Grass"],
      "otherFormes": ["Lurantis-Totem"],
      "formeOrder": ["Lurantis", "Lurantis-Totem"]
    },
    "lurantistotem": {
      "num": 754,
      "name": "Lurantis-Totem",
      "baseSpecies": "Lurantis",
      "forme": "Totem",
      "types": ["Grass"],
      "baseStats": {
        "hp": 70,
        "atk": 105,
        "def": 90,
        "spa": 80,
        "spd": 90,
        "spe": 45
      },
      "abilities": {"0": "Leaf Guard"},
      "weightkg": 58,
      "eggGroups": ["Grass"]
    },
    "morelull": {
      "num": 755,
      "name": "Morelull",
      "types": ["Grass", "Fairy"],
      "baseStats": {
        "hp": 40,
        "atk": 35,
        "def": 55,
        "spa": 65,
        "spd": 75,
        "spe": 15
      },
      "abilities": {"0": "Illuminate", "1": "Effect Spore", "H": "Rain Dish"},
      "weightkg": 1.5,
      "evos": ["Shiinotic"],
      "eggGroups": ["Grass"]
    },
    "shiinotic": {
      "num": 756,
      "name": "Shiinotic",
      "types": ["Grass", "Fairy"],
      "baseStats": {
        "hp": 60,
        "atk": 45,
        "def": 80,
        "spa": 90,
        "spd": 100,
        "spe": 30
      },
      "abilities": {"0": "Illuminate", "1": "Effect Spore", "H": "Rain Dish"},
      "weightkg": 11.5,
      "prevo": "Morelull",
      "evoLevel": 24,
      "eggGroups": ["Grass"]
    },
    "salandit": {
      "num": 757,
      "name": "Salandit",
      "types": ["Poison", "Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 48,
        "atk": 44,
        "def": 40,
        "spa": 71,
        "spd": 40,
        "spe": 77
      },
      "abilities": {"0": "Corrosion", "H": "Oblivious"},
      "weightkg": 4.8,
      "evos": ["Salazzle"],
      "eggGroups": ["Monster", "Dragon"]
    },
    "salazzle": {
      "num": 758,
      "name": "Salazzle",
      "types": ["Poison", "Fire"],
      "gender": "F",
      "baseStats": {
        "hp": 68,
        "atk": 64,
        "def": 60,
        "spa": 111,
        "spd": 60,
        "spe": 117
      },
      "abilities": {"0": "Corrosion", "H": "Oblivious"},
      "weightkg": 22.2,
      "prevo": "Salandit",
      "evoLevel": 33,
      "eggGroups": ["Monster", "Dragon"],
      "otherFormes": ["Salazzle-Totem"],
      "formeOrder": ["Salazzle", "Salazzle-Totem"]
    },
    "salazzletotem": {
      "num": 758,
      "name": "Salazzle-Totem",
      "baseSpecies": "Salazzle",
      "forme": "Totem",
      "types": ["Poison", "Fire"],
      "gender": "F",
      "baseStats": {
        "hp": 68,
        "atk": 64,
        "def": 60,
        "spa": 111,
        "spd": 60,
        "spe": 117
      },
      "abilities": {"0": "Corrosion"},
      "weightkg": 81,
      "eggGroups": ["Monster", "Dragon"]
    },
    "stufful": {
      "num": 759,
      "name": "Stufful",
      "types": ["Normal", "Fighting"],
      "baseStats": {
        "hp": 70,
        "atk": 75,
        "def": 50,
        "spa": 45,
        "spd": 50,
        "spe": 50
      },
      "abilities": {"0": "Fluffy", "1": "Klutz", "H": "Cute Charm"},
      "weightkg": 6.8,
      "evos": ["Bewear"],
      "eggGroups": ["Field"]
    },
    "bewear": {
      "num": 760,
      "name": "Bewear",
      "types": ["Normal", "Fighting"],
      "baseStats": {
        "hp": 120,
        "atk": 125,
        "def": 80,
        "spa": 55,
        "spd": 60,
        "spe": 60
      },
      "abilities": {"0": "Fluffy", "1": "Klutz", "H": "Unnerve"},
      "weightkg": 135,
      "prevo": "Stufful",
      "evoLevel": 27,
      "eggGroups": ["Field"]
    },
    "bounsweet": {
      "num": 761,
      "name": "Bounsweet",
      "types": ["Grass"],
      "gender": "F",
      "baseStats": {
        "hp": 42,
        "atk": 30,
        "def": 38,
        "spa": 30,
        "spd": 38,
        "spe": 32
      },
      "abilities": {"0": "Leaf Guard", "1": "Oblivious", "H": "Sweet Veil"},
      "weightkg": 3.2,
      "evos": ["Steenee"],
      "eggGroups": ["Grass"]
    },
    "steenee": {
      "num": 762,
      "name": "Steenee",
      "types": ["Grass"],
      "gender": "F",
      "baseStats": {
        "hp": 52,
        "atk": 40,
        "def": 48,
        "spa": 40,
        "spd": 48,
        "spe": 62
      },
      "abilities": {"0": "Leaf Guard", "1": "Oblivious", "H": "Sweet Veil"},
      "weightkg": 8.2,
      "prevo": "Bounsweet",
      "evoLevel": 18,
      "evos": ["Tsareena"],
      "eggGroups": ["Grass"]
    },
    "tsareena": {
      "num": 763,
      "name": "Tsareena",
      "types": ["Grass"],
      "gender": "F",
      "baseStats": {
        "hp": 72,
        "atk": 120,
        "def": 98,
        "spa": 50,
        "spd": 98,
        "spe": 72
      },
      "abilities": {
        "0": "Leaf Guard",
        "1": "Queenly Majesty",
        "H": "Sweet Veil"
      },
      "weightkg": 21.4,
      "prevo": "Steenee",
      "evoType": "levelMove",
      "evoMove": "Stomp",
      "eggGroups": ["Grass"]
    },
    "comfey": {
      "num": 764,
      "name": "Comfey",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 51,
        "atk": 52,
        "def": 90,
        "spa": 82,
        "spd": 110,
        "spe": 100
      },
      "abilities": {"0": "Flower Veil", "1": "Triage", "H": "Natural Cure"},
      "weightkg": 0.3,
      "eggGroups": ["Grass"]
    },
    "oranguru": {
      "num": 765,
      "name": "Oranguru",
      "types": ["Normal", "Psychic"],
      "baseStats": {
        "hp": 90,
        "atk": 60,
        "def": 80,
        "spa": 90,
        "spd": 110,
        "spe": 60
      },
      "abilities": {"0": "Inner Focus", "1": "Telepathy", "H": "Symbiosis"},
      "weightkg": 76,
      "eggGroups": ["Field"]
    },
    "passimian": {
      "num": 766,
      "name": "Passimian",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 100,
        "atk": 120,
        "def": 90,
        "spa": 40,
        "spd": 60,
        "spe": 80
      },
      "abilities": {"0": "Receiver", "H": "Defiant"},
      "weightkg": 82.8,
      "eggGroups": ["Field"]
    },
    "wimpod": {
      "num": 767,
      "name": "Wimpod",
      "types": ["Bug", "Water"],
      "baseStats": {
        "hp": 25,
        "atk": 35,
        "def": 40,
        "spa": 20,
        "spd": 30,
        "spe": 80
      },
      "abilities": {"0": "Wimp Out"},
      "weightkg": 12,
      "evos": ["Golisopod"],
      "eggGroups": ["Bug", "Water 3"]
    },
    "golisopod": {
      "num": 768,
      "name": "Golisopod",
      "types": ["Bug", "Water"],
      "baseStats": {
        "hp": 75,
        "atk": 125,
        "def": 140,
        "spa": 60,
        "spd": 90,
        "spe": 40
      },
      "abilities": {"0": "Emergency Exit"},
      "weightkg": 108,
      "prevo": "Wimpod",
      "evoLevel": 30,
      "eggGroups": ["Bug", "Water 3"]
    },
    "sandygast": {
      "num": 769,
      "name": "Sandygast",
      "types": ["Ghost", "Ground"],
      "baseStats": {
        "hp": 55,
        "atk": 55,
        "def": 80,
        "spa": 70,
        "spd": 45,
        "spe": 15
      },
      "abilities": {"0": "Water Compaction", "H": "Sand Veil"},
      "weightkg": 70,
      "evos": ["Palossand"],
      "eggGroups": ["Amorphous"]
    },
    "palossand": {
      "num": 770,
      "name": "Palossand",
      "types": ["Ghost", "Ground"],
      "baseStats": {
        "hp": 85,
        "atk": 75,
        "def": 110,
        "spa": 100,
        "spd": 75,
        "spe": 35
      },
      "abilities": {"0": "Water Compaction", "H": "Sand Veil"},
      "weightkg": 250,
      "prevo": "Sandygast",
      "evoLevel": 42,
      "eggGroups": ["Amorphous"]
    },
    "pyukumuku": {
      "num": 771,
      "name": "Pyukumuku",
      "types": ["Water"],
      "baseStats": {
        "hp": 55,
        "atk": 60,
        "def": 130,
        "spa": 30,
        "spd": 130,
        "spe": 5
      },
      "abilities": {"0": "Innards Out", "H": "Unaware"},
      "weightkg": 1.2,
      "eggGroups": ["Water 1"]
    },
    "typenull": {
      "num": 772,
      "name": "Type: Null",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 59
      },
      "abilities": {"0": "Battle Armor"},
      "weightkg": 120.5,
      "tags": ["Sub-Legendary"],
      "evos": ["Silvally"],
      "eggGroups": ["Undiscovered"]
    },
    "silvally": {
      "num": 773,
      "name": "Silvally",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "tags": ["Sub-Legendary"],
      "prevo": "Type: Null",
      "evoType": "levelFriendship",
      "eggGroups": ["Undiscovered"],
      "otherFormes": [
        "Silvally-Bug",
        "Silvally-Dark",
        "Silvally-Dragon",
        "Silvally-Electric",
        "Silvally-Fairy",
        "Silvally-Fighting",
        "Silvally-Fire",
        "Silvally-Flying",
        "Silvally-Ghost",
        "Silvally-Grass",
        "Silvally-Ground",
        "Silvally-Ice",
        "Silvally-Poison",
        "Silvally-Psychic",
        "Silvally-Rock",
        "Silvally-Steel",
        "Silvally-Water"
      ],
      "formeOrder": [
        "Silvally",
        "Silvally-Fighting",
        "Silvally-Flying",
        "Silvally-Poison",
        "Silvally-Ground",
        "Silvally-Rock",
        "Silvally-Bug",
        "Silvally-Ghost",
        "Silvally-Steel",
        "Silvally-Fire",
        "Silvally-Water",
        "Silvally-Grass",
        "Silvally-Electric",
        "Silvally-Psychic",
        "Silvally-Ice",
        "Silvally-Dragon",
        "Silvally-Dark",
        "Silvally-Fairy"
      ]
    },
    "silvallybug": {
      "num": 773,
      "name": "Silvally-Bug",
      "baseSpecies": "Silvally",
      "forme": "Bug",
      "types": ["Bug"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Bug Memory",
      "changesFrom": "Silvally"
    },
    "silvallydark": {
      "num": 773,
      "name": "Silvally-Dark",
      "baseSpecies": "Silvally",
      "forme": "Dark",
      "types": ["Dark"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Dark Memory",
      "changesFrom": "Silvally"
    },
    "silvallydragon": {
      "num": 773,
      "name": "Silvally-Dragon",
      "baseSpecies": "Silvally",
      "forme": "Dragon",
      "types": ["Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Dragon Memory",
      "changesFrom": "Silvally"
    },
    "silvallyelectric": {
      "num": 773,
      "name": "Silvally-Electric",
      "baseSpecies": "Silvally",
      "forme": "Electric",
      "types": ["Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Electric Memory",
      "changesFrom": "Silvally"
    },
    "silvallyfairy": {
      "num": 773,
      "name": "Silvally-Fairy",
      "baseSpecies": "Silvally",
      "forme": "Fairy",
      "types": ["Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Fairy Memory",
      "changesFrom": "Silvally"
    },
    "silvallyfighting": {
      "num": 773,
      "name": "Silvally-Fighting",
      "baseSpecies": "Silvally",
      "forme": "Fighting",
      "types": ["Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Fighting Memory",
      "changesFrom": "Silvally"
    },
    "silvallyfire": {
      "num": 773,
      "name": "Silvally-Fire",
      "baseSpecies": "Silvally",
      "forme": "Fire",
      "types": ["Fire"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Fire Memory",
      "changesFrom": "Silvally"
    },
    "silvallyflying": {
      "num": 773,
      "name": "Silvally-Flying",
      "baseSpecies": "Silvally",
      "forme": "Flying",
      "types": ["Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Flying Memory",
      "changesFrom": "Silvally"
    },
    "silvallyghost": {
      "num": 773,
      "name": "Silvally-Ghost",
      "baseSpecies": "Silvally",
      "forme": "Ghost",
      "types": ["Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Ghost Memory",
      "changesFrom": "Silvally"
    },
    "silvallygrass": {
      "num": 773,
      "name": "Silvally-Grass",
      "baseSpecies": "Silvally",
      "forme": "Grass",
      "types": ["Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Grass Memory",
      "changesFrom": "Silvally"
    },
    "silvallyground": {
      "num": 773,
      "name": "Silvally-Ground",
      "baseSpecies": "Silvally",
      "forme": "Ground",
      "types": ["Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Ground Memory",
      "changesFrom": "Silvally"
    },
    "silvallyice": {
      "num": 773,
      "name": "Silvally-Ice",
      "baseSpecies": "Silvally",
      "forme": "Ice",
      "types": ["Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Ice Memory",
      "changesFrom": "Silvally"
    },
    "silvallypoison": {
      "num": 773,
      "name": "Silvally-Poison",
      "baseSpecies": "Silvally",
      "forme": "Poison",
      "types": ["Poison"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Poison Memory",
      "changesFrom": "Silvally"
    },
    "silvallypsychic": {
      "num": 773,
      "name": "Silvally-Psychic",
      "baseSpecies": "Silvally",
      "forme": "Psychic",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Psychic Memory",
      "changesFrom": "Silvally"
    },
    "silvallyrock": {
      "num": 773,
      "name": "Silvally-Rock",
      "baseSpecies": "Silvally",
      "forme": "Rock",
      "types": ["Rock"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Rock Memory",
      "changesFrom": "Silvally"
    },
    "silvallysteel": {
      "num": 773,
      "name": "Silvally-Steel",
      "baseSpecies": "Silvally",
      "forme": "Steel",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Steel Memory",
      "changesFrom": "Silvally"
    },
    "silvallywater": {
      "num": 773,
      "name": "Silvally-Water",
      "baseSpecies": "Silvally",
      "forme": "Water",
      "types": ["Water"],
      "gender": "N",
      "baseStats": {
        "hp": 95,
        "atk": 95,
        "def": 95,
        "spa": 95,
        "spd": 95,
        "spe": 95
      },
      "abilities": {"0": "RKS System"},
      "weightkg": 100.5,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Water Memory",
      "changesFrom": "Silvally"
    },
    "minior": {
      "num": 774,
      "name": "Minior",
      "baseForme": "Red",
      "types": ["Rock", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 100,
        "def": 60,
        "spa": 100,
        "spd": 60,
        "spe": 120
      },
      "abilities": {"0": "Shields Down"},
      "weightkg": 0.3,
      "eggGroups": ["Mineral"],
      "otherFormes": ["Minior-Meteor"],
      "cosmeticFormes": [
        "Minior-Orange",
        "Minior-Yellow",
        "Minior-Green",
        "Minior-Blue",
        "Minior-Indigo",
        "Minior-Violet"
      ],
      "formeOrder": [
        "Minior-Meteor",
        "Minior-Meteor",
        "Minior-Meteor",
        "Minior-Meteor",
        "Minior-Meteor",
        "Minior-Meteor",
        "Minior-Meteor",
        "Minior",
        "Minior-Orange",
        "Minior-Yellow",
        "Minior-Green",
        "Minior-Blue",
        "Minior-Indigo",
        "Minior-Violet"
      ]
    },
    "miniormeteor": {
      "num": 774,
      "name": "Minior-Meteor",
      "baseSpecies": "Minior",
      "forme": "Meteor",
      "types": ["Rock", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 100,
        "spa": 60,
        "spd": 100,
        "spe": 60
      },
      "abilities": {"0": "Shields Down"},
      "weightkg": 40,
      "eggGroups": ["Mineral"],
      "requiredAbility": "Shields Down",
      "battleOnly": "Minior"
    },
    "komala": {
      "num": 775,
      "name": "Komala",
      "types": ["Normal"],
      "baseStats": {
        "hp": 65,
        "atk": 115,
        "def": 65,
        "spa": 75,
        "spd": 95,
        "spe": 65
      },
      "abilities": {"0": "Comatose"},
      "weightkg": 19.9,
      "eggGroups": ["Field"]
    },
    "turtonator": {
      "num": 776,
      "name": "Turtonator",
      "types": ["Fire", "Dragon"],
      "baseStats": {
        "hp": 60,
        "atk": 78,
        "def": 135,
        "spa": 91,
        "spd": 85,
        "spe": 36
      },
      "abilities": {"0": "Shell Armor"},
      "weightkg": 212,
      "eggGroups": ["Monster", "Dragon"]
    },
    "togedemaru": {
      "num": 777,
      "name": "Togedemaru",
      "types": ["Electric", "Steel"],
      "baseStats": {
        "hp": 65,
        "atk": 98,
        "def": 63,
        "spa": 40,
        "spd": 73,
        "spe": 96
      },
      "abilities": {"0": "Iron Barbs", "1": "Lightning Rod", "H": "Sturdy"},
      "weightkg": 3.3,
      "eggGroups": ["Field", "Fairy"],
      "otherFormes": ["Togedemaru-Totem"],
      "formeOrder": ["Togedemaru", "Togedemaru-Totem"]
    },
    "togedemarutotem": {
      "num": 777,
      "name": "Togedemaru-Totem",
      "baseSpecies": "Togedemaru",
      "forme": "Totem",
      "types": ["Electric", "Steel"],
      "baseStats": {
        "hp": 65,
        "atk": 98,
        "def": 63,
        "spa": 40,
        "spd": 73,
        "spe": 96
      },
      "abilities": {"0": "Sturdy"},
      "weightkg": 13,
      "eggGroups": ["Field", "Fairy"]
    },
    "mimikyu": {
      "num": 778,
      "name": "Mimikyu",
      "baseForme": "Disguised",
      "types": ["Ghost", "Fairy"],
      "baseStats": {
        "hp": 55,
        "atk": 90,
        "def": 80,
        "spa": 50,
        "spd": 105,
        "spe": 96
      },
      "abilities": {"0": "Disguise"},
      "weightkg": 0.7,
      "eggGroups": ["Amorphous"],
      "otherFormes": [
        "Mimikyu-Busted",
        "Mimikyu-Totem",
        "Mimikyu-Busted-Totem"
      ],
      "formeOrder": [
        "Mimikyu",
        "Mimikyu-Busted",
        "Mimikyu-Totem",
        "Mimikyu-Busted-Totem"
      ]
    },
    "mimikyubusted": {
      "num": 778,
      "name": "Mimikyu-Busted",
      "baseSpecies": "Mimikyu",
      "forme": "Busted",
      "types": ["Ghost", "Fairy"],
      "baseStats": {
        "hp": 55,
        "atk": 90,
        "def": 80,
        "spa": 50,
        "spd": 105,
        "spe": 96
      },
      "abilities": {"0": "Disguise"},
      "weightkg": 0.7,
      "eggGroups": ["Amorphous"],
      "requiredAbility": "Disguise",
      "battleOnly": "Mimikyu"
    },
    "mimikyutotem": {
      "num": 778,
      "name": "Mimikyu-Totem",
      "baseSpecies": "Mimikyu",
      "forme": "Totem",
      "types": ["Ghost", "Fairy"],
      "baseStats": {
        "hp": 55,
        "atk": 90,
        "def": 80,
        "spa": 50,
        "spd": 105,
        "spe": 96
      },
      "abilities": {"0": "Disguise"},
      "weightkg": 2.8,
      "eggGroups": ["Amorphous"]
    },
    "mimikyubustedtotem": {
      "num": 778,
      "name": "Mimikyu-Busted-Totem",
      "baseSpecies": "Mimikyu",
      "forme": "Busted-Totem",
      "types": ["Ghost", "Fairy"],
      "baseStats": {
        "hp": 55,
        "atk": 90,
        "def": 80,
        "spa": 50,
        "spd": 105,
        "spe": 96
      },
      "abilities": {"0": "Disguise"},
      "weightkg": 2.8,
      "eggGroups": ["Amorphous"],
      "requiredAbility": "Disguise",
      "battleOnly": "Mimikyu-Totem"
    },
    "bruxish": {
      "num": 779,
      "name": "Bruxish",
      "types": ["Water", "Psychic"],
      "baseStats": {
        "hp": 68,
        "atk": 105,
        "def": 70,
        "spa": 70,
        "spd": 70,
        "spe": 92
      },
      "abilities": {"0": "Dazzling", "1": "Strong Jaw", "H": "Wonder Skin"},
      "weightkg": 19,
      "eggGroups": ["Water 2"]
    },
    "drampa": {
      "num": 780,
      "name": "Drampa",
      "types": ["Normal", "Dragon"],
      "baseStats": {
        "hp": 78,
        "atk": 60,
        "def": 85,
        "spa": 135,
        "spd": 91,
        "spe": 36
      },
      "abilities": {"0": "Berserk", "1": "Sap Sipper", "H": "Cloud Nine"},
      "weightkg": 185,
      "eggGroups": ["Monster", "Dragon"]
    },
    "dhelmise": {
      "num": 781,
      "name": "Dhelmise",
      "types": ["Ghost", "Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 70,
        "atk": 131,
        "def": 100,
        "spa": 86,
        "spd": 90,
        "spe": 40
      },
      "abilities": {"0": "Steelworker"},
      "weightkg": 210,
      "eggGroups": ["Mineral"]
    },
    "jangmoo": {
      "num": 782,
      "name": "Jangmo-o",
      "types": ["Dragon"],
      "baseStats": {
        "hp": 45,
        "atk": 55,
        "def": 65,
        "spa": 45,
        "spd": 45,
        "spe": 45
      },
      "abilities": {"0": "Bulletproof", "1": "Soundproof", "H": "Overcoat"},
      "weightkg": 29.7,
      "evos": ["Hakamo-o"],
      "eggGroups": ["Dragon"]
    },
    "hakamoo": {
      "num": 783,
      "name": "Hakamo-o",
      "types": ["Dragon", "Fighting"],
      "baseStats": {
        "hp": 55,
        "atk": 75,
        "def": 90,
        "spa": 65,
        "spd": 70,
        "spe": 65
      },
      "abilities": {"0": "Bulletproof", "1": "Soundproof", "H": "Overcoat"},
      "weightkg": 47,
      "prevo": "Jangmo-o",
      "evoLevel": 35,
      "evos": ["Kommo-o"],
      "eggGroups": ["Dragon"]
    },
    "kommoo": {
      "num": 784,
      "name": "Kommo-o",
      "types": ["Dragon", "Fighting"],
      "baseStats": {
        "hp": 75,
        "atk": 110,
        "def": 125,
        "spa": 100,
        "spd": 105,
        "spe": 85
      },
      "abilities": {"0": "Bulletproof", "1": "Soundproof", "H": "Overcoat"},
      "weightkg": 78.2,
      "prevo": "Hakamo-o",
      "evoLevel": 45,
      "eggGroups": ["Dragon"],
      "otherFormes": ["Kommo-o-Totem"],
      "formeOrder": ["Kommo-o", "Kommo-o-Totem"]
    },
    "kommoototem": {
      "num": 784,
      "name": "Kommo-o-Totem",
      "baseSpecies": "Kommo-o",
      "forme": "Totem",
      "types": ["Dragon", "Fighting"],
      "baseStats": {
        "hp": 75,
        "atk": 110,
        "def": 125,
        "spa": 100,
        "spd": 105,
        "spe": 85
      },
      "abilities": {"0": "Overcoat"},
      "weightkg": 207.5,
      "eggGroups": ["Dragon"]
    },
    "tapukoko": {
      "num": 785,
      "name": "Tapu Koko",
      "types": ["Electric", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 70,
        "atk": 115,
        "def": 85,
        "spa": 95,
        "spd": 75,
        "spe": 130
      },
      "abilities": {"0": "Electric Surge", "H": "Telepathy"},
      "weightkg": 20.5,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "tapulele": {
      "num": 786,
      "name": "Tapu Lele",
      "types": ["Psychic", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 75,
        "spa": 130,
        "spd": 115,
        "spe": 95
      },
      "abilities": {"0": "Psychic Surge", "H": "Telepathy"},
      "weightkg": 18.6,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "tapubulu": {
      "num": 787,
      "name": "Tapu Bulu",
      "types": ["Grass", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 70,
        "atk": 130,
        "def": 115,
        "spa": 85,
        "spd": 95,
        "spe": 75
      },
      "abilities": {"0": "Grassy Surge", "H": "Telepathy"},
      "weightkg": 45.5,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "tapufini": {
      "num": 788,
      "name": "Tapu Fini",
      "types": ["Water", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 70,
        "atk": 75,
        "def": 115,
        "spa": 95,
        "spd": 130,
        "spe": 85
      },
      "abilities": {"0": "Misty Surge", "H": "Telepathy"},
      "weightkg": 21.2,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "cosmog": {
      "num": 789,
      "name": "Cosmog",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 43,
        "atk": 29,
        "def": 31,
        "spa": 29,
        "spd": 31,
        "spe": 37
      },
      "abilities": {"0": "Unaware"},
      "weightkg": 0.1,
      "evos": ["Cosmoem"],
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "cosmoem": {
      "num": 790,
      "name": "Cosmoem",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 43,
        "atk": 29,
        "def": 131,
        "spa": 29,
        "spd": 131,
        "spe": 37
      },
      "abilities": {"0": "Sturdy"},
      "weightkg": 999.9,
      "tags": ["Restricted Legendary"],
      "prevo": "Cosmog",
      "evoLevel": 43,
      "evos": ["Solgaleo", "Lunala"],
      "eggGroups": ["Undiscovered"]
    },
    "solgaleo": {
      "num": 791,
      "name": "Solgaleo",
      "types": ["Psychic", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 137,
        "atk": 137,
        "def": 107,
        "spa": 113,
        "spd": 89,
        "spe": 97
      },
      "abilities": {"0": "Full Metal Body"},
      "weightkg": 230,
      "tags": ["Restricted Legendary"],
      "prevo": "Cosmoem",
      "evoLevel": 53,
      "eggGroups": ["Undiscovered"]
    },
    "lunala": {
      "num": 792,
      "name": "Lunala",
      "types": ["Psychic", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 137,
        "atk": 113,
        "def": 89,
        "spa": 137,
        "spd": 107,
        "spe": 97
      },
      "abilities": {"0": "Shadow Shield"},
      "weightkg": 120,
      "tags": ["Restricted Legendary"],
      "prevo": "Cosmoem",
      "evoLevel": 53,
      "eggGroups": ["Undiscovered"]
    },
    "nihilego": {
      "num": 793,
      "name": "Nihilego",
      "types": ["Rock", "Poison"],
      "gender": "N",
      "baseStats": {
        "hp": 109,
        "atk": 53,
        "def": 47,
        "spa": 127,
        "spd": 131,
        "spe": 103
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 55.5,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "buzzwole": {
      "num": 794,
      "name": "Buzzwole",
      "types": ["Bug", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 107,
        "atk": 139,
        "def": 139,
        "spa": 53,
        "spd": 53,
        "spe": 79
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 333.6,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "pheromosa": {
      "num": 795,
      "name": "Pheromosa",
      "types": ["Bug", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 71,
        "atk": 137,
        "def": 37,
        "spa": 137,
        "spd": 37,
        "spe": 151
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 25,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "xurkitree": {
      "num": 796,
      "name": "Xurkitree",
      "types": ["Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 83,
        "atk": 89,
        "def": 71,
        "spa": 173,
        "spd": 71,
        "spe": 83
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 100,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "celesteela": {
      "num": 797,
      "name": "Celesteela",
      "types": ["Steel", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 97,
        "atk": 101,
        "def": 103,
        "spa": 107,
        "spd": 101,
        "spe": 61
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 999.9,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "kartana": {
      "num": 798,
      "name": "Kartana",
      "types": ["Grass", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 59,
        "atk": 181,
        "def": 131,
        "spa": 59,
        "spd": 31,
        "spe": 109
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 0.1,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "guzzlord": {
      "num": 799,
      "name": "Guzzlord",
      "types": ["Dark", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 223,
        "atk": 101,
        "def": 53,
        "spa": 97,
        "spd": 53,
        "spe": 43
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 888,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "necrozma": {
      "num": 800,
      "name": "Necrozma",
      "types": ["Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 97,
        "atk": 107,
        "def": 101,
        "spa": 127,
        "spd": 89,
        "spe": 79
      },
      "abilities": {"0": "Prism Armor"},
      "weightkg": 230,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": [
        "Necrozma-Dusk-Mane",
        "Necrozma-Dawn-Wings",
        "Necrozma-Ultra"
      ],
      "formeOrder": [
        "Necrozma",
        "Necrozma-Dusk-Mane",
        "Necrozma-Dawn-Wings",
        "Necrozma-Ultra"
      ]
    },
    "necrozmaduskmane": {
      "num": 800,
      "name": "Necrozma-Dusk-Mane",
      "baseSpecies": "Necrozma",
      "forme": "Dusk-Mane",
      "types": ["Psychic", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 97,
        "atk": 157,
        "def": 127,
        "spa": 113,
        "spd": 109,
        "spe": 77
      },
      "abilities": {"0": "Prism Armor"},
      "weightkg": 460,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Necrozma"
    },
    "necrozmadawnwings": {
      "num": 800,
      "name": "Necrozma-Dawn-Wings",
      "baseSpecies": "Necrozma",
      "forme": "Dawn-Wings",
      "types": ["Psychic", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 97,
        "atk": 113,
        "def": 109,
        "spa": 157,
        "spd": 127,
        "spe": 77
      },
      "abilities": {"0": "Prism Armor"},
      "weightkg": 350,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Necrozma"
    },
    "necrozmaultra": {
      "num": 800,
      "name": "Necrozma-Ultra",
      "baseSpecies": "Necrozma",
      "forme": "Ultra",
      "types": ["Psychic", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 97,
        "atk": 167,
        "def": 97,
        "spa": 167,
        "spd": 97,
        "spe": 129
      },
      "abilities": {"0": "Neuroforce"},
      "weightkg": 230,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Ultranecrozium Z",
      "battleOnly": ["Necrozma-Dawn-Wings", "Necrozma-Dusk-Mane"]
    },
    "magearna": {
      "num": 801,
      "name": "Magearna",
      "types": ["Steel", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 95,
        "def": 115,
        "spa": 130,
        "spd": 115,
        "spe": 65
      },
      "abilities": {"0": "Soul-Heart"},
      "weightkg": 80.5,
      "eggGroups": ["Undiscovered"],
      "tags": ["Mythical"],
      "otherFormes": ["Magearna-Original"],
      "formeOrder": ["Magearna", "Magearna-Original"]
    },
    "magearnaoriginal": {
      "num": 801,
      "name": "Magearna-Original",
      "baseSpecies": "Magearna",
      "forme": "Original",
      "types": ["Steel", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 95,
        "def": 115,
        "spa": 130,
        "spd": 115,
        "spe": 65
      },
      "abilities": {"0": "Soul-Heart"},
      "weightkg": 80.5,
      "eggGroups": ["Undiscovered"]
    },
    "marshadow": {
      "num": 802,
      "name": "Marshadow",
      "types": ["Fighting", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 125,
        "def": 80,
        "spa": 90,
        "spd": 90,
        "spe": 125
      },
      "abilities": {"0": "Technician"},
      "weightkg": 22.2,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"]
    },
    "poipole": {
      "num": 803,
      "name": "Poipole",
      "types": ["Poison"],
      "gender": "N",
      "baseStats": {
        "hp": 67,
        "atk": 73,
        "def": 67,
        "spa": 73,
        "spd": 67,
        "spe": 73
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 1.8,
      "tags": ["Sub-Legendary"],
      "evos": ["Naganadel"],
      "eggGroups": ["Undiscovered"]
    },
    "naganadel": {
      "num": 804,
      "name": "Naganadel",
      "types": ["Poison", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 73,
        "atk": 73,
        "def": 73,
        "spa": 127,
        "spd": 73,
        "spe": 121
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 150,
      "tags": ["Sub-Legendary"],
      "prevo": "Poipole",
      "evoType": "levelMove",
      "evoMove": "Dragon Pulse",
      "eggGroups": ["Undiscovered"]
    },
    "stakataka": {
      "num": 805,
      "name": "Stakataka",
      "types": ["Rock", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 61,
        "atk": 131,
        "def": 211,
        "spa": 53,
        "spd": 101,
        "spe": 13
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 820,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "blacephalon": {
      "num": 806,
      "name": "Blacephalon",
      "types": ["Fire", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 53,
        "atk": 127,
        "def": 53,
        "spa": 151,
        "spd": 79,
        "spe": 107
      },
      "abilities": {"0": "Beast Boost"},
      "weightkg": 13,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "zeraora": {
      "num": 807,
      "name": "Zeraora",
      "types": ["Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 88,
        "atk": 112,
        "def": 75,
        "spa": 102,
        "spd": 80,
        "spe": 143
      },
      "abilities": {"0": "Volt Absorb"},
      "weightkg": 44.5,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"]
    },
    "meltan": {
      "num": 808,
      "name": "Meltan",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 46,
        "atk": 65,
        "def": 65,
        "spa": 55,
        "spd": 35,
        "spe": 34
      },
      "abilities": {"0": "Magnet Pull"},
      "weightkg": 8,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"]
    },
    "melmetal": {
      "num": 809,
      "name": "Melmetal",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 135,
        "atk": 143,
        "def": 143,
        "spa": 80,
        "spd": 65,
        "spe": 34
      },
      "abilities": {"0": "Iron Fist"},
      "weightkg": 800,
      "tags": ["Mythical"],
      "eggGroups": ["Undiscovered"],
      "canGigantamax": "G-Max Meltdown"
    },
    "melmetalgmax": {
      "num": 809,
      "name": "Melmetal-Gmax",
      "baseSpecies": "Melmetal",
      "forme": "Gmax",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 135,
        "atk": 143,
        "def": 143,
        "spa": 80,
        "spd": 65,
        "spe": 34
      },
      "abilities": {"0": "Iron Fist"},
      "weightkg": 0,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Melmetal"
    },
    "grookey": {
      "num": 810,
      "name": "Grookey",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 50,
        "spa": 40,
        "spd": 40,
        "spe": 65
      },
      "abilities": {"0": "Overgrow", "H": "Grassy Surge"},
      "weightkg": 5,
      "evos": ["Thwackey"],
      "eggGroups": ["Field", "Grass"]
    },
    "thwackey": {
      "num": 811,
      "name": "Thwackey",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 70,
        "spa": 55,
        "spd": 60,
        "spe": 80
      },
      "abilities": {"0": "Overgrow", "H": "Grassy Surge"},
      "weightkg": 14,
      "prevo": "Grookey",
      "evoLevel": 16,
      "evos": ["Rillaboom"],
      "eggGroups": ["Field", "Grass"]
    },
    "rillaboom": {
      "num": 812,
      "name": "Rillaboom",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 100,
        "atk": 125,
        "def": 90,
        "spa": 60,
        "spd": 70,
        "spe": 85
      },
      "abilities": {"0": "Overgrow", "H": "Grassy Surge"},
      "weightkg": 90,
      "prevo": "Thwackey",
      "evoLevel": 35,
      "eggGroups": ["Field", "Grass"],
      "canGigantamax": "G-Max Drum Solo"
    },
    "rillaboomgmax": {
      "num": 812,
      "name": "Rillaboom-Gmax",
      "baseSpecies": "Rillaboom",
      "forme": "Gmax",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 100,
        "atk": 125,
        "def": 90,
        "spa": 60,
        "spd": 70,
        "spe": 85
      },
      "abilities": {"0": "Overgrow", "H": "Grassy Surge"},
      "weightkg": 0,
      "eggGroups": ["Field", "Grass"],
      "changesFrom": "Rillaboom"
    },
    "scorbunny": {
      "num": 813,
      "name": "Scorbunny",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 71,
        "def": 40,
        "spa": 40,
        "spd": 40,
        "spe": 69
      },
      "abilities": {"0": "Blaze", "H": "Libero"},
      "weightkg": 4.5,
      "evos": ["Raboot"],
      "eggGroups": ["Field", "Human-Like"]
    },
    "raboot": {
      "num": 814,
      "name": "Raboot",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 86,
        "def": 60,
        "spa": 55,
        "spd": 60,
        "spe": 94
      },
      "abilities": {"0": "Blaze", "H": "Libero"},
      "weightkg": 9,
      "prevo": "Scorbunny",
      "evoLevel": 16,
      "evos": ["Cinderace"],
      "eggGroups": ["Field", "Human-Like"]
    },
    "cinderace": {
      "num": 815,
      "name": "Cinderace",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 116,
        "def": 75,
        "spa": 65,
        "spd": 75,
        "spe": 119
      },
      "abilities": {"0": "Blaze", "H": "Libero"},
      "weightkg": 33,
      "prevo": "Raboot",
      "evoLevel": 35,
      "eggGroups": ["Field", "Human-Like"],
      "canGigantamax": "G-Max Fireball"
    },
    "cinderacegmax": {
      "num": 815,
      "name": "Cinderace-Gmax",
      "baseSpecies": "Cinderace",
      "forme": "Gmax",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 80,
        "atk": 116,
        "def": 75,
        "spa": 65,
        "spd": 75,
        "spe": 119
      },
      "abilities": {"0": "Blaze", "H": "Libero"},
      "weightkg": 0,
      "eggGroups": ["Field", "Human-Like"],
      "changesFrom": "Cinderace"
    },
    "sobble": {
      "num": 816,
      "name": "Sobble",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 50,
        "atk": 40,
        "def": 40,
        "spa": 70,
        "spd": 40,
        "spe": 70
      },
      "abilities": {"0": "Torrent", "H": "Sniper"},
      "weightkg": 4,
      "evos": ["Drizzile"],
      "eggGroups": ["Water 1", "Field"]
    },
    "drizzile": {
      "num": 817,
      "name": "Drizzile",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 65,
        "atk": 60,
        "def": 55,
        "spa": 95,
        "spd": 55,
        "spe": 90
      },
      "abilities": {"0": "Torrent", "H": "Sniper"},
      "weightkg": 11.5,
      "prevo": "Sobble",
      "evoLevel": 16,
      "evos": ["Inteleon"],
      "eggGroups": ["Water 1", "Field"]
    },
    "inteleon": {
      "num": 818,
      "name": "Inteleon",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 65,
        "spa": 125,
        "spd": 65,
        "spe": 120
      },
      "abilities": {"0": "Torrent", "H": "Sniper"},
      "weightkg": 45.2,
      "prevo": "Drizzile",
      "evoLevel": 35,
      "eggGroups": ["Water 1", "Field"],
      "canGigantamax": "G-Max Hydrosnipe"
    },
    "inteleongmax": {
      "num": 818,
      "name": "Inteleon-Gmax",
      "baseSpecies": "Inteleon",
      "forme": "Gmax",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 65,
        "spa": 125,
        "spd": 65,
        "spe": 120
      },
      "abilities": {"0": "Torrent", "H": "Sniper"},
      "weightkg": 0,
      "eggGroups": ["Water 1", "Field"],
      "changesFrom": "Inteleon"
    },
    "skwovet": {
      "num": 819,
      "name": "Skwovet",
      "types": ["Normal"],
      "baseStats": {
        "hp": 70,
        "atk": 55,
        "def": 55,
        "spa": 35,
        "spd": 35,
        "spe": 25
      },
      "abilities": {"0": "Cheek Pouch", "H": "Gluttony"},
      "weightkg": 2.5,
      "evos": ["Greedent"],
      "eggGroups": ["Field"]
    },
    "greedent": {
      "num": 820,
      "name": "Greedent",
      "types": ["Normal"],
      "baseStats": {
        "hp": 120,
        "atk": 95,
        "def": 95,
        "spa": 55,
        "spd": 75,
        "spe": 20
      },
      "abilities": {"0": "Cheek Pouch", "H": "Gluttony"},
      "weightkg": 6,
      "prevo": "Skwovet",
      "evoLevel": 24,
      "eggGroups": ["Field"]
    },
    "rookidee": {
      "num": 821,
      "name": "Rookidee",
      "types": ["Flying"],
      "baseStats": {
        "hp": 38,
        "atk": 47,
        "def": 35,
        "spa": 33,
        "spd": 35,
        "spe": 57
      },
      "abilities": {"0": "Keen Eye", "1": "Unnerve", "H": "Big Pecks"},
      "weightkg": 1.8,
      "evos": ["Corvisquire"],
      "eggGroups": ["Flying"]
    },
    "corvisquire": {
      "num": 822,
      "name": "Corvisquire",
      "types": ["Flying"],
      "baseStats": {
        "hp": 68,
        "atk": 67,
        "def": 55,
        "spa": 43,
        "spd": 55,
        "spe": 77
      },
      "abilities": {"0": "Keen Eye", "1": "Unnerve", "H": "Big Pecks"},
      "weightkg": 16,
      "prevo": "Rookidee",
      "evoLevel": 18,
      "evos": ["Corviknight"],
      "eggGroups": ["Flying"]
    },
    "corviknight": {
      "num": 823,
      "name": "Corviknight",
      "types": ["Flying", "Steel"],
      "baseStats": {
        "hp": 98,
        "atk": 87,
        "def": 105,
        "spa": 53,
        "spd": 85,
        "spe": 67
      },
      "abilities": {"0": "Pressure", "1": "Unnerve", "H": "Mirror Armor"},
      "weightkg": 75,
      "prevo": "Corvisquire",
      "evoLevel": 38,
      "eggGroups": ["Flying"],
      "canGigantamax": "G-Max Wind Rage"
    },
    "corviknightgmax": {
      "num": 823,
      "name": "Corviknight-Gmax",
      "baseSpecies": "Corviknight",
      "forme": "Gmax",
      "types": ["Flying", "Steel"],
      "baseStats": {
        "hp": 98,
        "atk": 87,
        "def": 105,
        "spa": 53,
        "spd": 85,
        "spe": 67
      },
      "abilities": {"0": "Pressure", "1": "Unnerve", "H": "Mirror Armor"},
      "weightkg": 0,
      "eggGroups": ["Flying"],
      "changesFrom": "Corviknight"
    },
    "blipbug": {
      "num": 824,
      "name": "Blipbug",
      "types": ["Bug"],
      "baseStats": {
        "hp": 25,
        "atk": 20,
        "def": 20,
        "spa": 25,
        "spd": 45,
        "spe": 45
      },
      "abilities": {"0": "Swarm", "1": "Compound Eyes", "H": "Telepathy"},
      "weightkg": 8,
      "evos": ["Dottler"],
      "eggGroups": ["Bug"]
    },
    "dottler": {
      "num": 825,
      "name": "Dottler",
      "types": ["Bug", "Psychic"],
      "baseStats": {
        "hp": 50,
        "atk": 35,
        "def": 80,
        "spa": 50,
        "spd": 90,
        "spe": 30
      },
      "abilities": {"0": "Swarm", "1": "Compound Eyes", "H": "Telepathy"},
      "weightkg": 19.5,
      "prevo": "Blipbug",
      "evoLevel": 10,
      "evos": ["Orbeetle"],
      "eggGroups": ["Bug"]
    },
    "orbeetle": {
      "num": 826,
      "name": "Orbeetle",
      "types": ["Bug", "Psychic"],
      "baseStats": {
        "hp": 60,
        "atk": 45,
        "def": 110,
        "spa": 80,
        "spd": 120,
        "spe": 90
      },
      "abilities": {"0": "Swarm", "1": "Frisk", "H": "Telepathy"},
      "weightkg": 40.8,
      "prevo": "Dottler",
      "evoLevel": 30,
      "eggGroups": ["Bug"],
      "canGigantamax": "G-Max Gravitas"
    },
    "orbeetlegmax": {
      "num": 826,
      "name": "Orbeetle-Gmax",
      "baseSpecies": "Orbeetle",
      "forme": "Gmax",
      "types": ["Bug", "Psychic"],
      "baseStats": {
        "hp": 60,
        "atk": 45,
        "def": 110,
        "spa": 80,
        "spd": 120,
        "spe": 90
      },
      "abilities": {"0": "Swarm", "1": "Frisk", "H": "Telepathy"},
      "weightkg": 0,
      "eggGroups": ["Bug"],
      "changesFrom": "Orbeetle"
    },
    "nickit": {
      "num": 827,
      "name": "Nickit",
      "types": ["Dark"],
      "baseStats": {
        "hp": 40,
        "atk": 28,
        "def": 28,
        "spa": 47,
        "spd": 52,
        "spe": 50
      },
      "abilities": {"0": "Run Away", "1": "Unburden", "H": "Stakeout"},
      "weightkg": 8.9,
      "evos": ["Thievul"],
      "eggGroups": ["Field"]
    },
    "thievul": {
      "num": 828,
      "name": "Thievul",
      "types": ["Dark"],
      "baseStats": {
        "hp": 70,
        "atk": 58,
        "def": 58,
        "spa": 87,
        "spd": 92,
        "spe": 90
      },
      "abilities": {"0": "Run Away", "1": "Unburden", "H": "Stakeout"},
      "weightkg": 19.9,
      "prevo": "Nickit",
      "evoLevel": 18,
      "eggGroups": ["Field"]
    },
    "gossifleur": {
      "num": 829,
      "name": "Gossifleur",
      "types": ["Grass"],
      "baseStats": {
        "hp": 40,
        "atk": 40,
        "def": 60,
        "spa": 40,
        "spd": 60,
        "spe": 10
      },
      "abilities": {
        "0": "Cotton Down",
        "1": "Regenerator",
        "H": "Effect Spore"
      },
      "weightkg": 2.2,
      "evos": ["Eldegoss"],
      "eggGroups": ["Grass"]
    },
    "eldegoss": {
      "num": 830,
      "name": "Eldegoss",
      "types": ["Grass"],
      "baseStats": {
        "hp": 60,
        "atk": 50,
        "def": 90,
        "spa": 80,
        "spd": 120,
        "spe": 60
      },
      "abilities": {
        "0": "Cotton Down",
        "1": "Regenerator",
        "H": "Effect Spore"
      },
      "weightkg": 2.5,
      "prevo": "Gossifleur",
      "evoLevel": 20,
      "eggGroups": ["Grass"]
    },
    "wooloo": {
      "num": 831,
      "name": "Wooloo",
      "types": ["Normal"],
      "baseStats": {
        "hp": 42,
        "atk": 40,
        "def": 55,
        "spa": 40,
        "spd": 45,
        "spe": 48
      },
      "abilities": {"0": "Fluffy", "1": "Run Away", "H": "Bulletproof"},
      "weightkg": 6,
      "evos": ["Dubwool"],
      "eggGroups": ["Field"]
    },
    "dubwool": {
      "num": 832,
      "name": "Dubwool",
      "types": ["Normal"],
      "baseStats": {
        "hp": 72,
        "atk": 80,
        "def": 100,
        "spa": 60,
        "spd": 90,
        "spe": 88
      },
      "abilities": {"0": "Fluffy", "1": "Steadfast", "H": "Bulletproof"},
      "weightkg": 43,
      "prevo": "Wooloo",
      "evoLevel": 24,
      "eggGroups": ["Field"]
    },
    "chewtle": {
      "num": 833,
      "name": "Chewtle",
      "types": ["Water"],
      "baseStats": {
        "hp": 50,
        "atk": 64,
        "def": 50,
        "spa": 38,
        "spd": 38,
        "spe": 44
      },
      "abilities": {"0": "Strong Jaw", "1": "Shell Armor", "H": "Swift Swim"},
      "weightkg": 8.5,
      "evos": ["Drednaw"],
      "eggGroups": ["Monster", "Water 1"]
    },
    "drednaw": {
      "num": 834,
      "name": "Drednaw",
      "types": ["Water", "Rock"],
      "baseStats": {
        "hp": 90,
        "atk": 115,
        "def": 90,
        "spa": 48,
        "spd": 68,
        "spe": 74
      },
      "abilities": {"0": "Strong Jaw", "1": "Shell Armor", "H": "Swift Swim"},
      "weightkg": 115.5,
      "prevo": "Chewtle",
      "evoLevel": 22,
      "eggGroups": ["Monster", "Water 1"],
      "canGigantamax": "G-Max Stonesurge"
    },
    "drednawgmax": {
      "num": 834,
      "name": "Drednaw-Gmax",
      "baseSpecies": "Drednaw",
      "forme": "Gmax",
      "types": ["Water", "Rock"],
      "baseStats": {
        "hp": 90,
        "atk": 115,
        "def": 90,
        "spa": 48,
        "spd": 68,
        "spe": 74
      },
      "abilities": {"0": "Strong Jaw", "1": "Shell Armor", "H": "Swift Swim"},
      "weightkg": 0,
      "eggGroups": ["Monster", "Water 1"],
      "changesFrom": "Drednaw"
    },
    "yamper": {
      "num": 835,
      "name": "Yamper",
      "types": ["Electric"],
      "baseStats": {
        "hp": 59,
        "atk": 45,
        "def": 50,
        "spa": 40,
        "spd": 50,
        "spe": 26
      },
      "abilities": {"0": "Ball Fetch", "H": "Rattled"},
      "weightkg": 13.5,
      "evos": ["Boltund"],
      "eggGroups": ["Field"]
    },
    "boltund": {
      "num": 836,
      "name": "Boltund",
      "types": ["Electric"],
      "baseStats": {
        "hp": 69,
        "atk": 90,
        "def": 60,
        "spa": 90,
        "spd": 60,
        "spe": 121
      },
      "abilities": {"0": "Strong Jaw", "H": "Competitive"},
      "weightkg": 34,
      "prevo": "Yamper",
      "evoLevel": 25,
      "eggGroups": ["Field"]
    },
    "rolycoly": {
      "num": 837,
      "name": "Rolycoly",
      "types": ["Rock"],
      "baseStats": {
        "hp": 30,
        "atk": 40,
        "def": 50,
        "spa": 40,
        "spd": 50,
        "spe": 30
      },
      "abilities": {"0": "Steam Engine", "1": "Heatproof", "H": "Flash Fire"},
      "weightkg": 12,
      "evos": ["Carkol"],
      "eggGroups": ["Mineral"]
    },
    "carkol": {
      "num": 838,
      "name": "Carkol",
      "types": ["Rock", "Fire"],
      "baseStats": {
        "hp": 80,
        "atk": 60,
        "def": 90,
        "spa": 60,
        "spd": 70,
        "spe": 50
      },
      "abilities": {"0": "Steam Engine", "1": "Flame Body", "H": "Flash Fire"},
      "weightkg": 78,
      "prevo": "Rolycoly",
      "evoLevel": 18,
      "evos": ["Coalossal"],
      "eggGroups": ["Mineral"]
    },
    "coalossal": {
      "num": 839,
      "name": "Coalossal",
      "types": ["Rock", "Fire"],
      "baseStats": {
        "hp": 110,
        "atk": 80,
        "def": 120,
        "spa": 80,
        "spd": 90,
        "spe": 30
      },
      "abilities": {"0": "Steam Engine", "1": "Flame Body", "H": "Flash Fire"},
      "weightkg": 310.5,
      "prevo": "Carkol",
      "evoLevel": 34,
      "eggGroups": ["Mineral"],
      "canGigantamax": "G-Max Volcalith"
    },
    "coalossalgmax": {
      "num": 839,
      "name": "Coalossal-Gmax",
      "baseSpecies": "Coalossal",
      "forme": "Gmax",
      "types": ["Rock", "Fire"],
      "baseStats": {
        "hp": 110,
        "atk": 80,
        "def": 120,
        "spa": 80,
        "spd": 90,
        "spe": 30
      },
      "abilities": {"0": "Steam Engine", "1": "Flame Body", "H": "Flash Fire"},
      "weightkg": 0,
      "eggGroups": ["Mineral"],
      "changesFrom": "Coalossal"
    },
    "applin": {
      "num": 840,
      "name": "Applin",
      "types": ["Grass", "Dragon"],
      "baseStats": {
        "hp": 40,
        "atk": 40,
        "def": 80,
        "spa": 40,
        "spd": 40,
        "spe": 20
      },
      "abilities": {"0": "Ripen", "1": "Gluttony", "H": "Bulletproof"},
      "weightkg": 0.5,
      "evos": ["Flapple", "Appletun", "Dipplin"],
      "eggGroups": ["Grass", "Dragon"]
    },
    "flapple": {
      "num": 841,
      "name": "Flapple",
      "types": ["Grass", "Dragon"],
      "baseStats": {
        "hp": 70,
        "atk": 110,
        "def": 80,
        "spa": 95,
        "spd": 60,
        "spe": 70
      },
      "abilities": {"0": "Ripen", "1": "Gluttony", "H": "Hustle"},
      "weightkg": 1,
      "prevo": "Applin",
      "evoType": "useItem",
      "evoItem": "Tart Apple",
      "eggGroups": ["Grass", "Dragon"],
      "canGigantamax": "G-Max Tartness"
    },
    "flapplegmax": {
      "num": 841,
      "name": "Flapple-Gmax",
      "baseSpecies": "Flapple",
      "forme": "Gmax",
      "types": ["Grass", "Dragon"],
      "baseStats": {
        "hp": 70,
        "atk": 110,
        "def": 80,
        "spa": 95,
        "spd": 60,
        "spe": 70
      },
      "abilities": {"0": "Ripen", "1": "Gluttony", "H": "Hustle"},
      "weightkg": 0,
      "eggGroups": ["Grass", "Dragon"],
      "changesFrom": "Flapple"
    },
    "appletun": {
      "num": 842,
      "name": "Appletun",
      "types": ["Grass", "Dragon"],
      "baseStats": {
        "hp": 110,
        "atk": 85,
        "def": 80,
        "spa": 100,
        "spd": 80,
        "spe": 30
      },
      "abilities": {"0": "Ripen", "1": "Gluttony", "H": "Thick Fat"},
      "weightkg": 13,
      "prevo": "Applin",
      "evoType": "useItem",
      "evoItem": "Sweet Apple",
      "eggGroups": ["Grass", "Dragon"],
      "canGigantamax": "G-Max Sweetness"
    },
    "appletungmax": {
      "num": 842,
      "name": "Appletun-Gmax",
      "baseSpecies": "Appletun",
      "forme": "Gmax",
      "types": ["Grass", "Dragon"],
      "baseStats": {
        "hp": 110,
        "atk": 85,
        "def": 80,
        "spa": 100,
        "spd": 80,
        "spe": 30
      },
      "abilities": {"0": "Ripen", "1": "Gluttony", "H": "Thick Fat"},
      "weightkg": 0,
      "eggGroups": ["Grass", "Dragon"],
      "changesFrom": "Appletun"
    },
    "silicobra": {
      "num": 843,
      "name": "Silicobra",
      "types": ["Ground"],
      "baseStats": {
        "hp": 52,
        "atk": 57,
        "def": 75,
        "spa": 35,
        "spd": 50,
        "spe": 46
      },
      "abilities": {"0": "Sand Spit", "1": "Shed Skin", "H": "Sand Veil"},
      "weightkg": 7.6,
      "evos": ["Sandaconda"],
      "eggGroups": ["Field", "Dragon"]
    },
    "sandaconda": {
      "num": 844,
      "name": "Sandaconda",
      "types": ["Ground"],
      "baseStats": {
        "hp": 72,
        "atk": 107,
        "def": 125,
        "spa": 65,
        "spd": 70,
        "spe": 71
      },
      "abilities": {"0": "Sand Spit", "1": "Shed Skin", "H": "Sand Veil"},
      "weightkg": 65.5,
      "prevo": "Silicobra",
      "evoLevel": 36,
      "eggGroups": ["Field", "Dragon"],
      "canGigantamax": "G-Max Sandblast"
    },
    "sandacondagmax": {
      "num": 844,
      "name": "Sandaconda-Gmax",
      "baseSpecies": "Sandaconda",
      "forme": "Gmax",
      "types": ["Ground"],
      "baseStats": {
        "hp": 72,
        "atk": 107,
        "def": 125,
        "spa": 65,
        "spd": 70,
        "spe": 71
      },
      "abilities": {"0": "Sand Spit", "1": "Shed Skin", "H": "Sand Veil"},
      "weightkg": 0,
      "eggGroups": ["Field", "Dragon"],
      "changesFrom": "Sandaconda"
    },
    "cramorant": {
      "num": 845,
      "name": "Cramorant",
      "types": ["Flying", "Water"],
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 55,
        "spa": 85,
        "spd": 95,
        "spe": 85
      },
      "abilities": {"0": "Gulp Missile"},
      "weightkg": 18,
      "eggGroups": ["Water 1", "Flying"],
      "otherFormes": ["Cramorant-Gulping", "Cramorant-Gorging"],
      "formeOrder": ["Cramorant", "Cramorant-Gulping", "Cramorant-Gorging"]
    },
    "cramorantgulping": {
      "num": 845,
      "name": "Cramorant-Gulping",
      "baseSpecies": "Cramorant",
      "forme": "Gulping",
      "types": ["Flying", "Water"],
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 55,
        "spa": 85,
        "spd": 95,
        "spe": 85
      },
      "abilities": {"0": "Gulp Missile"},
      "weightkg": 18,
      "eggGroups": ["Water 1", "Flying"],
      "requiredAbility": "Gulp Missile",
      "battleOnly": "Cramorant"
    },
    "cramorantgorging": {
      "num": 845,
      "name": "Cramorant-Gorging",
      "baseSpecies": "Cramorant",
      "forme": "Gorging",
      "types": ["Flying", "Water"],
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 55,
        "spa": 85,
        "spd": 95,
        "spe": 85
      },
      "abilities": {"0": "Gulp Missile"},
      "weightkg": 18,
      "eggGroups": ["Water 1", "Flying"],
      "requiredAbility": "Gulp Missile",
      "battleOnly": "Cramorant"
    },
    "arrokuda": {
      "num": 846,
      "name": "Arrokuda",
      "types": ["Water"],
      "baseStats": {
        "hp": 41,
        "atk": 63,
        "def": 40,
        "spa": 40,
        "spd": 30,
        "spe": 66
      },
      "abilities": {"0": "Swift Swim", "H": "Propeller Tail"},
      "weightkg": 1,
      "evos": ["Barraskewda"],
      "eggGroups": ["Water 2"]
    },
    "barraskewda": {
      "num": 847,
      "name": "Barraskewda",
      "types": ["Water"],
      "baseStats": {
        "hp": 61,
        "atk": 123,
        "def": 60,
        "spa": 60,
        "spd": 50,
        "spe": 136
      },
      "abilities": {"0": "Swift Swim", "H": "Propeller Tail"},
      "weightkg": 30,
      "prevo": "Arrokuda",
      "evoLevel": 26,
      "eggGroups": ["Water 2"]
    },
    "toxel": {
      "num": 848,
      "name": "Toxel",
      "types": ["Electric", "Poison"],
      "baseStats": {
        "hp": 40,
        "atk": 38,
        "def": 35,
        "spa": 54,
        "spd": 35,
        "spe": 40
      },
      "abilities": {"0": "Rattled", "1": "Static", "H": "Klutz"},
      "weightkg": 11,
      "evos": ["Toxtricity", "Toxtricity-Low-Key"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true
    },
    "toxtricity": {
      "num": 849,
      "name": "Toxtricity",
      "baseForme": "Amped",
      "types": ["Electric", "Poison"],
      "baseStats": {
        "hp": 75,
        "atk": 98,
        "def": 70,
        "spa": 114,
        "spd": 70,
        "spe": 75
      },
      "abilities": {"0": "Punk Rock", "1": "Plus", "H": "Technician"},
      "weightkg": 40,
      "prevo": "Toxel",
      "evoLevel": 30,
      "eggGroups": ["Human-Like"],
      "otherFormes": ["Toxtricity-Low-Key"],
      "formeOrder": ["Toxtricity", "Toxtricity-Low-Key"],
      "canGigantamax": "G-Max Stun Shock"
    },
    "toxtricitylowkey": {
      "num": 849,
      "name": "Toxtricity-Low-Key",
      "baseSpecies": "Toxtricity",
      "forme": "Low-Key",
      "types": ["Electric", "Poison"],
      "baseStats": {
        "hp": 75,
        "atk": 98,
        "def": 70,
        "spa": 114,
        "spd": 70,
        "spe": 75
      },
      "abilities": {"0": "Punk Rock", "1": "Minus", "H": "Technician"},
      "weightkg": 40,
      "prevo": "Toxel",
      "evoLevel": 30,
      "eggGroups": ["Human-Like"],
      "canGigantamax": "G-Max Stun Shock"
    },
    "toxtricitygmax": {
      "num": 849,
      "name": "Toxtricity-Gmax",
      "baseSpecies": "Toxtricity",
      "forme": "Gmax",
      "types": ["Electric", "Poison"],
      "baseStats": {
        "hp": 75,
        "atk": 98,
        "def": 70,
        "spa": 114,
        "spd": 70,
        "spe": 75
      },
      "abilities": {"0": "Punk Rock", "1": "Plus", "H": "Technician"},
      "weightkg": 0,
      "eggGroups": ["Human-Like"],
      "changesFrom": "Toxtricity"
    },
    "toxtricitylowkeygmax": {
      "num": 849,
      "name": "Toxtricity-Low-Key-Gmax",
      "baseSpecies": "Toxtricity",
      "forme": "Low-Key-Gmax",
      "types": ["Electric", "Poison"],
      "baseStats": {
        "hp": 75,
        "atk": 98,
        "def": 70,
        "spa": 114,
        "spd": 70,
        "spe": 75
      },
      "abilities": {"0": "Punk Rock", "1": "Minus", "H": "Technician"},
      "weightkg": 0,
      "eggGroups": ["Human-Like"],
      "battleOnly": "Toxtricity-Low-Key",
      "changesFrom": "Toxtricity-Low-Key"
    },
    "sizzlipede": {
      "num": 850,
      "name": "Sizzlipede",
      "types": ["Fire", "Bug"],
      "baseStats": {
        "hp": 50,
        "atk": 65,
        "def": 45,
        "spa": 50,
        "spd": 50,
        "spe": 45
      },
      "abilities": {"0": "Flash Fire", "1": "White Smoke", "H": "Flame Body"},
      "weightkg": 1,
      "evos": ["Centiskorch"],
      "eggGroups": ["Bug"]
    },
    "centiskorch": {
      "num": 851,
      "name": "Centiskorch",
      "types": ["Fire", "Bug"],
      "baseStats": {
        "hp": 100,
        "atk": 115,
        "def": 65,
        "spa": 90,
        "spd": 90,
        "spe": 65
      },
      "abilities": {"0": "Flash Fire", "1": "White Smoke", "H": "Flame Body"},
      "weightkg": 120,
      "prevo": "Sizzlipede",
      "evoLevel": 28,
      "eggGroups": ["Bug"],
      "canGigantamax": "G-Max Centiferno"
    },
    "centiskorchgmax": {
      "num": 851,
      "name": "Centiskorch-Gmax",
      "baseSpecies": "Centiskorch",
      "forme": "Gmax",
      "types": ["Fire", "Bug"],
      "baseStats": {
        "hp": 100,
        "atk": 115,
        "def": 65,
        "spa": 90,
        "spd": 90,
        "spe": 65
      },
      "abilities": {"0": "Flash Fire", "1": "White Smoke", "H": "Flame Body"},
      "weightkg": 0,
      "eggGroups": ["Bug"],
      "changesFrom": "Centiskorch"
    },
    "clobbopus": {
      "num": 852,
      "name": "Clobbopus",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 50,
        "atk": 68,
        "def": 60,
        "spa": 50,
        "spd": 50,
        "spe": 32
      },
      "abilities": {"0": "Limber", "H": "Technician"},
      "weightkg": 4,
      "evos": ["Grapploct"],
      "eggGroups": ["Water 1", "Human-Like"]
    },
    "grapploct": {
      "num": 853,
      "name": "Grapploct",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 80,
        "atk": 118,
        "def": 90,
        "spa": 70,
        "spd": 80,
        "spe": 42
      },
      "abilities": {"0": "Limber", "H": "Technician"},
      "weightkg": 39,
      "prevo": "Clobbopus",
      "evoType": "levelMove",
      "evoMove": "Taunt",
      "eggGroups": ["Water 1", "Human-Like"]
    },
    "sinistea": {
      "num": 854,
      "name": "Sinistea",
      "baseForme": "Phony",
      "types": ["Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 45,
        "spa": 74,
        "spd": 54,
        "spe": 50
      },
      "abilities": {"0": "Weak Armor", "H": "Cursed Body"},
      "weightkg": 0.2,
      "evos": ["Polteageist"],
      "eggGroups": ["Mineral", "Amorphous"],
      "otherFormes": ["Sinistea-Antique"],
      "formeOrder": ["Sinistea", "Sinistea-Antique"]
    },
    "sinisteaantique": {
      "num": 854,
      "name": "Sinistea-Antique",
      "baseSpecies": "Sinistea",
      "forme": "Antique",
      "types": ["Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 45,
        "spa": 74,
        "spd": 54,
        "spe": 50
      },
      "abilities": {"0": "Weak Armor", "H": "Cursed Body"},
      "weightkg": 0.2,
      "evos": ["Polteageist-Antique"],
      "eggGroups": ["Undiscovered"]
    },
    "polteageist": {
      "num": 855,
      "name": "Polteageist",
      "baseForme": "Phony",
      "types": ["Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 65,
        "def": 65,
        "spa": 134,
        "spd": 114,
        "spe": 70
      },
      "abilities": {"0": "Weak Armor", "H": "Cursed Body"},
      "weightkg": 0.4,
      "prevo": "Sinistea",
      "evoType": "useItem",
      "evoItem": "Cracked Pot",
      "eggGroups": ["Mineral", "Amorphous"],
      "otherFormes": ["Polteageist-Antique"],
      "formeOrder": ["Polteageist", "Polteageist-Antique"]
    },
    "polteageistantique": {
      "num": 855,
      "name": "Polteageist-Antique",
      "baseSpecies": "Polteageist",
      "forme": "Antique",
      "types": ["Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 60,
        "atk": 65,
        "def": 65,
        "spa": 134,
        "spd": 114,
        "spe": 70
      },
      "abilities": {"0": "Weak Armor", "H": "Cursed Body"},
      "weightkg": 0.4,
      "prevo": "Sinistea-Antique",
      "evoType": "useItem",
      "evoItem": "Chipped Pot",
      "eggGroups": ["Undiscovered"]
    },
    "hatenna": {
      "num": 856,
      "name": "Hatenna",
      "types": ["Psychic"],
      "gender": "F",
      "baseStats": {
        "hp": 42,
        "atk": 30,
        "def": 45,
        "spa": 56,
        "spd": 53,
        "spe": 39
      },
      "abilities": {"0": "Healer", "1": "Anticipation", "H": "Magic Bounce"},
      "weightkg": 3.4,
      "evos": ["Hattrem"],
      "eggGroups": ["Fairy"]
    },
    "hattrem": {
      "num": 857,
      "name": "Hattrem",
      "types": ["Psychic"],
      "gender": "F",
      "baseStats": {
        "hp": 57,
        "atk": 40,
        "def": 65,
        "spa": 86,
        "spd": 73,
        "spe": 49
      },
      "abilities": {"0": "Healer", "1": "Anticipation", "H": "Magic Bounce"},
      "weightkg": 4.8,
      "prevo": "Hatenna",
      "evoLevel": 32,
      "evos": ["Hatterene"],
      "eggGroups": ["Fairy"]
    },
    "hatterene": {
      "num": 858,
      "name": "Hatterene",
      "types": ["Psychic", "Fairy"],
      "gender": "F",
      "baseStats": {
        "hp": 57,
        "atk": 90,
        "def": 95,
        "spa": 136,
        "spd": 103,
        "spe": 29
      },
      "abilities": {"0": "Healer", "1": "Anticipation", "H": "Magic Bounce"},
      "weightkg": 5.1,
      "prevo": "Hattrem",
      "evoLevel": 42,
      "eggGroups": ["Fairy"],
      "canGigantamax": "G-Max Smite"
    },
    "hatterenegmax": {
      "num": 858,
      "name": "Hatterene-Gmax",
      "baseSpecies": "Hatterene",
      "forme": "Gmax",
      "types": ["Psychic", "Fairy"],
      "gender": "F",
      "baseStats": {
        "hp": 57,
        "atk": 90,
        "def": 95,
        "spa": 136,
        "spd": 103,
        "spe": 29
      },
      "abilities": {"0": "Healer", "1": "Anticipation", "H": "Magic Bounce"},
      "weightkg": 0,
      "eggGroups": ["Fairy"],
      "changesFrom": "Hatterene"
    },
    "impidimp": {
      "num": 859,
      "name": "Impidimp",
      "types": ["Dark", "Fairy"],
      "gender": "M",
      "baseStats": {
        "hp": 45,
        "atk": 45,
        "def": 30,
        "spa": 55,
        "spd": 40,
        "spe": 50
      },
      "abilities": {"0": "Prankster", "1": "Frisk", "H": "Pickpocket"},
      "weightkg": 5.5,
      "evos": ["Morgrem"],
      "eggGroups": ["Fairy", "Human-Like"]
    },
    "morgrem": {
      "num": 860,
      "name": "Morgrem",
      "types": ["Dark", "Fairy"],
      "gender": "M",
      "baseStats": {
        "hp": 65,
        "atk": 60,
        "def": 45,
        "spa": 75,
        "spd": 55,
        "spe": 70
      },
      "abilities": {"0": "Prankster", "1": "Frisk", "H": "Pickpocket"},
      "weightkg": 12.5,
      "prevo": "Impidimp",
      "evoLevel": 32,
      "evos": ["Grimmsnarl"],
      "eggGroups": ["Fairy", "Human-Like"]
    },
    "grimmsnarl": {
      "num": 861,
      "name": "Grimmsnarl",
      "types": ["Dark", "Fairy"],
      "gender": "M",
      "baseStats": {
        "hp": 95,
        "atk": 120,
        "def": 65,
        "spa": 95,
        "spd": 75,
        "spe": 60
      },
      "abilities": {"0": "Prankster", "1": "Frisk", "H": "Pickpocket"},
      "weightkg": 61,
      "prevo": "Morgrem",
      "evoLevel": 42,
      "eggGroups": ["Fairy", "Human-Like"],
      "canGigantamax": "G-Max Snooze"
    },
    "grimmsnarlgmax": {
      "num": 861,
      "name": "Grimmsnarl-Gmax",
      "baseSpecies": "Grimmsnarl",
      "forme": "Gmax",
      "types": ["Dark", "Fairy"],
      "gender": "M",
      "baseStats": {
        "hp": 95,
        "atk": 120,
        "def": 65,
        "spa": 95,
        "spd": 75,
        "spe": 60
      },
      "abilities": {"0": "Prankster", "1": "Frisk", "H": "Pickpocket"},
      "weightkg": 0,
      "eggGroups": ["Fairy", "Human-Like"],
      "changesFrom": "Grimmsnarl"
    },
    "obstagoon": {
      "num": 862,
      "name": "Obstagoon",
      "types": ["Dark", "Normal"],
      "baseStats": {
        "hp": 93,
        "atk": 90,
        "def": 101,
        "spa": 60,
        "spd": 81,
        "spe": 95
      },
      "abilities": {"0": "Reckless", "1": "Guts", "H": "Defiant"},
      "weightkg": 46,
      "prevo": "Linoone-Galar",
      "evoLevel": 35,
      "evoCondition": "at night",
      "eggGroups": ["Field"]
    },
    "perrserker": {
      "num": 863,
      "name": "Perrserker",
      "types": ["Steel"],
      "baseStats": {
        "hp": 70,
        "atk": 110,
        "def": 100,
        "spa": 50,
        "spd": 60,
        "spe": 50
      },
      "abilities": {
        "0": "Battle Armor",
        "1": "Tough Claws",
        "H": "Steely Spirit"
      },
      "weightkg": 28,
      "prevo": "Meowth-Galar",
      "evoLevel": 28,
      "eggGroups": ["Field"]
    },
    "cursola": {
      "num": 864,
      "name": "Cursola",
      "types": ["Ghost"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 60,
        "atk": 95,
        "def": 50,
        "spa": 145,
        "spd": 130,
        "spe": 30
      },
      "abilities": {"0": "Weak Armor", "H": "Perish Body"},
      "weightkg": 0.4,
      "prevo": "Corsola-Galar",
      "evoLevel": 38,
      "eggGroups": ["Water 1", "Water 3"]
    },
    "sirfetchd": {
      "num": 865,
      "name": "Sirfetch’d",
      "types": ["Fighting"],
      "baseStats": {
        "hp": 62,
        "atk": 135,
        "def": 95,
        "spa": 68,
        "spd": 82,
        "spe": 65
      },
      "abilities": {"0": "Steadfast", "H": "Scrappy"},
      "weightkg": 117,
      "prevo": "Farfetch’d-Galar",
      "evoType": "other",
      "evoCondition": "Land 3 critical hits in 1 battle",
      "eggGroups": ["Flying", "Field"]
    },
    "mrrime": {
      "num": 866,
      "name": "Mr. Rime",
      "types": ["Ice", "Psychic"],
      "baseStats": {
        "hp": 80,
        "atk": 85,
        "def": 75,
        "spa": 110,
        "spd": 100,
        "spe": 70
      },
      "abilities": {
        "0": "Tangled Feet",
        "1": "Screen Cleaner",
        "H": "Ice Body"
      },
      "weightkg": 58.2,
      "prevo": "Mr. Mime-Galar",
      "evoLevel": 42,
      "eggGroups": ["Human-Like"]
    },
    "runerigus": {
      "num": 867,
      "name": "Runerigus",
      "types": ["Ground", "Ghost"],
      "baseStats": {
        "hp": 58,
        "atk": 95,
        "def": 145,
        "spa": 50,
        "spd": 105,
        "spe": 30
      },
      "abilities": {"0": "Wandering Spirit"},
      "weightkg": 66.6,
      "prevo": "Yamask-Galar",
      "evoType": "other",
      "evoCondition": "Have 49+ HP lost and walk under stone sculpture in Dusty Bowl",
      "eggGroups": ["Mineral", "Amorphous"]
    },
    "milcery": {
      "num": 868,
      "name": "Milcery",
      "types": ["Fairy"],
      "gender": "F",
      "baseStats": {
        "hp": 45,
        "atk": 40,
        "def": 40,
        "spa": 50,
        "spd": 61,
        "spe": 34
      },
      "abilities": {"0": "Sweet Veil", "H": "Aroma Veil"},
      "weightkg": 0.3,
      "evos": ["Alcremie"],
      "eggGroups": ["Fairy", "Amorphous"]
    },
    "alcremie": {
      "num": 869,
      "name": "Alcremie",
      "baseForme": "Vanilla Cream",
      "types": ["Fairy"],
      "gender": "F",
      "baseStats": {
        "hp": 65,
        "atk": 60,
        "def": 75,
        "spa": 110,
        "spd": 121,
        "spe": 64
      },
      "abilities": {"0": "Sweet Veil", "H": "Aroma Veil"},
      "weightkg": 0.5,
      "prevo": "Milcery",
      "evoType": "other",
      "evoCondition": "spin while holding a Sweet",
      "eggGroups": ["Fairy", "Amorphous"],
      "cosmeticFormes": [
        "Alcremie-Ruby-Cream",
        "Alcremie-Matcha-Cream",
        "Alcremie-Mint-Cream",
        "Alcremie-Lemon-Cream",
        "Alcremie-Salted-Cream",
        "Alcremie-Ruby-Swirl",
        "Alcremie-Caramel-Swirl",
        "Alcremie-Rainbow-Swirl"
      ],
      "formeOrder": [
        "Alcremie",
        "Alcremie-Ruby-Cream",
        "Alcremie-Matcha-Cream",
        "Alcremie-Mint-Cream",
        "Alcremie-Lemon-Cream",
        "Alcremie-Salted-Cream",
        "Alcremie-Ruby-Swirl",
        "Alcremie-Caramel-Swirl",
        "Alcremie-Rainbow-Swirl"
      ],
      "canGigantamax": "G-Max Finale"
    },
    "alcremiegmax": {
      "num": 869,
      "name": "Alcremie-Gmax",
      "baseSpecies": "Alcremie",
      "forme": "Gmax",
      "types": ["Fairy"],
      "gender": "F",
      "baseStats": {
        "hp": 65,
        "atk": 60,
        "def": 75,
        "spa": 110,
        "spd": 121,
        "spe": 64
      },
      "abilities": {"0": "Sweet Veil", "H": "Aroma Veil"},
      "weightkg": 0,
      "eggGroups": ["Fairy", "Amorphous"],
      "changesFrom": "Alcremie"
    },
    "falinks": {
      "num": 870,
      "name": "Falinks",
      "types": ["Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 65,
        "atk": 100,
        "def": 100,
        "spa": 70,
        "spd": 60,
        "spe": 75
      },
      "abilities": {"0": "Battle Armor", "H": "Defiant"},
      "weightkg": 62,
      "eggGroups": ["Fairy", "Mineral"]
    },
    "pincurchin": {
      "num": 871,
      "name": "Pincurchin",
      "types": ["Electric"],
      "baseStats": {
        "hp": 48,
        "atk": 101,
        "def": 95,
        "spa": 91,
        "spd": 85,
        "spe": 15
      },
      "abilities": {"0": "Lightning Rod", "H": "Electric Surge"},
      "weightkg": 1,
      "eggGroups": ["Water 1", "Amorphous"]
    },
    "snom": {
      "num": 872,
      "name": "Snom",
      "types": ["Ice", "Bug"],
      "baseStats": {
        "hp": 30,
        "atk": 25,
        "def": 35,
        "spa": 45,
        "spd": 30,
        "spe": 20
      },
      "abilities": {"0": "Shield Dust", "H": "Ice Scales"},
      "weightkg": 3.8,
      "evos": ["Frosmoth"],
      "eggGroups": ["Bug"]
    },
    "frosmoth": {
      "num": 873,
      "name": "Frosmoth",
      "types": ["Ice", "Bug"],
      "baseStats": {
        "hp": 70,
        "atk": 65,
        "def": 60,
        "spa": 125,
        "spd": 90,
        "spe": 65
      },
      "abilities": {"0": "Shield Dust", "H": "Ice Scales"},
      "weightkg": 42,
      "prevo": "Snom",
      "evoType": "levelFriendship",
      "evoCondition": "at night",
      "eggGroups": ["Bug"]
    },
    "stonjourner": {
      "num": 874,
      "name": "Stonjourner",
      "types": ["Rock"],
      "baseStats": {
        "hp": 100,
        "atk": 125,
        "def": 135,
        "spa": 20,
        "spd": 20,
        "spe": 70
      },
      "abilities": {"0": "Power Spot"},
      "weightkg": 520,
      "eggGroups": ["Mineral"]
    },
    "eiscue": {
      "num": 875,
      "name": "Eiscue",
      "types": ["Ice"],
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 110,
        "spa": 65,
        "spd": 90,
        "spe": 50
      },
      "abilities": {"0": "Ice Face"},
      "weightkg": 89,
      "eggGroups": ["Water 1", "Field"],
      "otherFormes": ["Eiscue-Noice"],
      "formeOrder": ["Eiscue", "Eiscue-Noice"]
    },
    "eiscuenoice": {
      "num": 875,
      "name": "Eiscue-Noice",
      "baseSpecies": "Eiscue",
      "forme": "Noice",
      "types": ["Ice"],
      "baseStats": {
        "hp": 75,
        "atk": 80,
        "def": 70,
        "spa": 65,
        "spd": 50,
        "spe": 130
      },
      "abilities": {"0": "Ice Face"},
      "weightkg": 89,
      "eggGroups": ["Water 1", "Field"],
      "requiredAbility": "Ice Face",
      "battleOnly": "Eiscue"
    },
    "indeedee": {
      "num": 876,
      "name": "Indeedee",
      "baseForme": "M",
      "types": ["Psychic", "Normal"],
      "gender": "M",
      "baseStats": {
        "hp": 60,
        "atk": 65,
        "def": 55,
        "spa": 105,
        "spd": 95,
        "spe": 95
      },
      "abilities": {
        "0": "Inner Focus",
        "1": "Synchronize",
        "H": "Psychic Surge"
      },
      "weightkg": 28,
      "eggGroups": ["Fairy"],
      "otherFormes": ["Indeedee-F"],
      "formeOrder": ["Indeedee", "Indeedee-F"]
    },
    "indeedeef": {
      "num": 876,
      "name": "Indeedee-F",
      "baseSpecies": "Indeedee",
      "forme": "F",
      "types": ["Psychic", "Normal"],
      "gender": "F",
      "baseStats": {
        "hp": 70,
        "atk": 55,
        "def": 65,
        "spa": 95,
        "spd": 105,
        "spe": 85
      },
      "abilities": {"0": "Own Tempo", "1": "Synchronize", "H": "Psychic Surge"},
      "weightkg": 28,
      "eggGroups": ["Fairy"]
    },
    "morpeko": {
      "num": 877,
      "name": "Morpeko",
      "types": ["Electric", "Dark"],
      "baseStats": {
        "hp": 58,
        "atk": 95,
        "def": 58,
        "spa": 70,
        "spd": 58,
        "spe": 97
      },
      "abilities": {"0": "Hunger Switch"},
      "weightkg": 3,
      "eggGroups": ["Field", "Fairy"],
      "otherFormes": ["Morpeko-Hangry"],
      "formeOrder": ["Morpeko", "Morpeko-Hangry"]
    },
    "morpekohangry": {
      "num": 877,
      "name": "Morpeko-Hangry",
      "baseSpecies": "Morpeko",
      "forme": "Hangry",
      "types": ["Electric", "Dark"],
      "baseStats": {
        "hp": 58,
        "atk": 95,
        "def": 58,
        "spa": 70,
        "spd": 58,
        "spe": 97
      },
      "abilities": {"0": "Hunger Switch"},
      "weightkg": 3,
      "eggGroups": ["Field", "Fairy"],
      "requiredAbility": "Hunger Switch",
      "battleOnly": "Morpeko"
    },
    "cufant": {
      "num": 878,
      "name": "Cufant",
      "types": ["Steel"],
      "baseStats": {
        "hp": 72,
        "atk": 80,
        "def": 49,
        "spa": 40,
        "spd": 49,
        "spe": 40
      },
      "abilities": {"0": "Sheer Force", "H": "Heavy Metal"},
      "weightkg": 100,
      "evos": ["Copperajah"],
      "eggGroups": ["Field", "Mineral"]
    },
    "copperajah": {
      "num": 879,
      "name": "Copperajah",
      "types": ["Steel"],
      "baseStats": {
        "hp": 122,
        "atk": 130,
        "def": 69,
        "spa": 80,
        "spd": 69,
        "spe": 30
      },
      "abilities": {"0": "Sheer Force", "H": "Heavy Metal"},
      "weightkg": 650,
      "prevo": "Cufant",
      "evoLevel": 34,
      "eggGroups": ["Field", "Mineral"],
      "canGigantamax": "G-Max Steelsurge"
    },
    "copperajahgmax": {
      "num": 879,
      "name": "Copperajah-Gmax",
      "baseSpecies": "Copperajah",
      "forme": "Gmax",
      "types": ["Steel"],
      "baseStats": {
        "hp": 122,
        "atk": 130,
        "def": 69,
        "spa": 80,
        "spd": 69,
        "spe": 30
      },
      "abilities": {"0": "Sheer Force", "H": "Heavy Metal"},
      "weightkg": 0,
      "eggGroups": ["Field", "Mineral"],
      "changesFrom": "Copperajah"
    },
    "dracozolt": {
      "num": 880,
      "name": "Dracozolt",
      "types": ["Electric", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 100,
        "def": 90,
        "spa": 80,
        "spd": 70,
        "spe": 75
      },
      "abilities": {"0": "Volt Absorb", "1": "Hustle", "H": "Sand Rush"},
      "weightkg": 190,
      "eggGroups": ["Undiscovered"]
    },
    "arctozolt": {
      "num": 881,
      "name": "Arctozolt",
      "types": ["Electric", "Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 100,
        "def": 90,
        "spa": 90,
        "spd": 80,
        "spe": 55
      },
      "abilities": {"0": "Volt Absorb", "1": "Static", "H": "Slush Rush"},
      "weightkg": 150,
      "eggGroups": ["Undiscovered"]
    },
    "dracovish": {
      "num": 882,
      "name": "Dracovish",
      "types": ["Water", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 90,
        "def": 100,
        "spa": 70,
        "spd": 80,
        "spe": 75
      },
      "abilities": {"0": "Water Absorb", "1": "Strong Jaw", "H": "Sand Rush"},
      "weightkg": 215,
      "eggGroups": ["Undiscovered"]
    },
    "arctovish": {
      "num": 883,
      "name": "Arctovish",
      "types": ["Water", "Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 90,
        "def": 100,
        "spa": 80,
        "spd": 90,
        "spe": 55
      },
      "abilities": {"0": "Water Absorb", "1": "Ice Body", "H": "Slush Rush"},
      "weightkg": 175,
      "eggGroups": ["Undiscovered"]
    },
    "duraludon": {
      "num": 884,
      "name": "Duraludon",
      "types": ["Steel", "Dragon"],
      "baseStats": {
        "hp": 70,
        "atk": 95,
        "def": 115,
        "spa": 120,
        "spd": 50,
        "spe": 85
      },
      "abilities": {"0": "Light Metal", "1": "Heavy Metal", "H": "Stalwart"},
      "weightkg": 40,
      "eggGroups": ["Mineral", "Dragon"],
      "canGigantamax": "G-Max Depletion"
    },
    "duraludongmax": {
      "num": 884,
      "name": "Duraludon-Gmax",
      "baseSpecies": "Duraludon",
      "forme": "Gmax",
      "types": ["Steel", "Dragon"],
      "baseStats": {
        "hp": 70,
        "atk": 95,
        "def": 115,
        "spa": 120,
        "spd": 50,
        "spe": 85
      },
      "abilities": {"0": "Light Metal", "1": "Heavy Metal", "H": "Stalwart"},
      "weightkg": 0,
      "eggGroups": ["Mineral", "Dragon"],
      "changesFrom": "Duraludon"
    },
    "dreepy": {
      "num": 885,
      "name": "Dreepy",
      "types": ["Dragon", "Ghost"],
      "baseStats": {
        "hp": 28,
        "atk": 60,
        "def": 30,
        "spa": 40,
        "spd": 30,
        "spe": 82
      },
      "abilities": {"0": "Clear Body", "1": "Infiltrator", "H": "Cursed Body"},
      "weightkg": 2,
      "evos": ["Drakloak"],
      "eggGroups": ["Amorphous", "Dragon"]
    },
    "drakloak": {
      "num": 886,
      "name": "Drakloak",
      "types": ["Dragon", "Ghost"],
      "baseStats": {
        "hp": 68,
        "atk": 80,
        "def": 50,
        "spa": 60,
        "spd": 50,
        "spe": 102
      },
      "abilities": {"0": "Clear Body", "1": "Infiltrator", "H": "Cursed Body"},
      "weightkg": 11,
      "prevo": "Dreepy",
      "evoLevel": 50,
      "evos": ["Dragapult"],
      "eggGroups": ["Amorphous", "Dragon"]
    },
    "dragapult": {
      "num": 887,
      "name": "Dragapult",
      "types": ["Dragon", "Ghost"],
      "baseStats": {
        "hp": 88,
        "atk": 120,
        "def": 75,
        "spa": 100,
        "spd": 75,
        "spe": 142
      },
      "abilities": {"0": "Clear Body", "1": "Infiltrator", "H": "Cursed Body"},
      "weightkg": 50,
      "prevo": "Drakloak",
      "evoLevel": 60,
      "eggGroups": ["Amorphous", "Dragon"]
    },
    "zacian": {
      "num": 888,
      "name": "Zacian",
      "baseForme": "Hero",
      "types": ["Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 92,
        "atk": 120,
        "def": 115,
        "spa": 80,
        "spd": 115,
        "spe": 138
      },
      "abilities": {"0": "Intrepid Sword"},
      "weightkg": 110,
      "eggGroups": ["Undiscovered"],
      "tags": ["Restricted Legendary"],
      "otherFormes": ["Zacian-Crowned"],
      "formeOrder": ["Zacian", "Zacian-Crowned"],
      "cannotDynamax": true
    },
    "zaciancrowned": {
      "num": 888,
      "name": "Zacian-Crowned",
      "baseSpecies": "Zacian",
      "forme": "Crowned",
      "types": ["Fairy", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 92,
        "atk": 150,
        "def": 115,
        "spa": 80,
        "spd": 115,
        "spe": 148
      },
      "abilities": {"0": "Intrepid Sword"},
      "weightkg": 355,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Rusted Sword",
      "battleOnly": "Zacian",
      "cannotDynamax": true
    },
    "zamazenta": {
      "num": 889,
      "name": "Zamazenta",
      "baseForme": "Hero",
      "types": ["Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 92,
        "atk": 120,
        "def": 115,
        "spa": 80,
        "spd": 115,
        "spe": 138
      },
      "abilities": {"0": "Dauntless Shield"},
      "weightkg": 210,
      "eggGroups": ["Undiscovered"],
      "tags": ["Restricted Legendary"],
      "otherFormes": ["Zamazenta-Crowned"],
      "formeOrder": ["Zamazenta", "Zamazenta-Crowned"],
      "cannotDynamax": true
    },
    "zamazentacrowned": {
      "num": 889,
      "name": "Zamazenta-Crowned",
      "baseSpecies": "Zamazenta",
      "forme": "Crowned",
      "types": ["Fighting", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 92,
        "atk": 120,
        "def": 140,
        "spa": 80,
        "spd": 140,
        "spe": 128
      },
      "abilities": {"0": "Dauntless Shield"},
      "weightkg": 785,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Rusted Shield",
      "battleOnly": "Zamazenta",
      "cannotDynamax": true
    },
    "eternatus": {
      "num": 890,
      "name": "Eternatus",
      "types": ["Poison", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 140,
        "atk": 85,
        "def": 95,
        "spa": 145,
        "spd": 95,
        "spe": 130
      },
      "abilities": {"0": "Pressure"},
      "weightkg": 950,
      "eggGroups": ["Undiscovered"],
      "tags": ["Restricted Legendary"],
      "otherFormes": ["Eternatus-Eternamax"],
      "formeOrder": ["Eternatus", "Eternatus-Eternamax"],
      "cannotDynamax": true
    },
    "eternatuseternamax": {
      "num": 890,
      "name": "Eternatus-Eternamax",
      "baseSpecies": "Eternatus",
      "forme": "Eternamax",
      "types": ["Poison", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 255,
        "atk": 115,
        "def": 250,
        "spa": 125,
        "spd": 250,
        "spe": 130
      },
      "abilities": {"0": "Pressure"},
      "weightkg": 0,
      "eggGroups": ["Undiscovered"],
      "cannotDynamax": true
    },
    "kubfu": {
      "num": 891,
      "name": "Kubfu",
      "types": ["Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 60,
        "atk": 90,
        "def": 60,
        "spa": 53,
        "spd": 50,
        "spe": 72
      },
      "abilities": {"0": "Inner Focus"},
      "weightkg": 12,
      "tags": ["Sub-Legendary"],
      "evos": ["Urshifu", "Urshifu-Rapid-Strike"],
      "eggGroups": ["Undiscovered"]
    },
    "urshifu": {
      "num": 892,
      "name": "Urshifu",
      "baseForme": "Single-Strike",
      "types": ["Fighting", "Dark"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 100,
        "atk": 130,
        "def": 100,
        "spa": 63,
        "spd": 60,
        "spe": 97
      },
      "abilities": {"0": "Unseen Fist"},
      "weightkg": 105,
      "tags": ["Sub-Legendary"],
      "prevo": "Kubfu",
      "evoType": "other",
      "evoCondition": "Defeat the Single Strike Tower",
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Urshifu-Rapid-Strike"],
      "formeOrder": ["Urshifu", "Urshifu-Rapid-Strike"],
      "canGigantamax": "G-Max One Blow"
    },
    "urshifurapidstrike": {
      "num": 892,
      "name": "Urshifu-Rapid-Strike",
      "baseSpecies": "Urshifu",
      "forme": "Rapid-Strike",
      "types": ["Fighting", "Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 100,
        "atk": 130,
        "def": 100,
        "spa": 63,
        "spd": 60,
        "spe": 97
      },
      "abilities": {"0": "Unseen Fist"},
      "weightkg": 105,
      "prevo": "Kubfu",
      "evoType": "other",
      "evoCondition": "Defeat the Rapid Strike Tower",
      "eggGroups": ["Undiscovered"],
      "canGigantamax": "G-Max Rapid Flow"
    },
    "urshifugmax": {
      "num": 892,
      "name": "Urshifu-Gmax",
      "baseSpecies": "Urshifu",
      "forme": "Gmax",
      "types": ["Fighting", "Dark"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 100,
        "atk": 130,
        "def": 100,
        "spa": 63,
        "spd": 60,
        "spe": 97
      },
      "abilities": {"0": "Unseen Fist"},
      "weightkg": 0,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Urshifu"
    },
    "urshifurapidstrikegmax": {
      "num": 892,
      "name": "Urshifu-Rapid-Strike-Gmax",
      "baseSpecies": "Urshifu",
      "forme": "Rapid-Strike-Gmax",
      "types": ["Fighting", "Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 100,
        "atk": 130,
        "def": 100,
        "spa": 63,
        "spd": 60,
        "spe": 97
      },
      "abilities": {"0": "Unseen Fist"},
      "weightkg": 0,
      "eggGroups": ["Undiscovered"],
      "battleOnly": "Urshifu-Rapid-Strike",
      "changesFrom": "Urshifu-Rapid-Strike"
    },
    "zarude": {
      "num": 893,
      "name": "Zarude",
      "types": ["Dark", "Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 105,
        "atk": 120,
        "def": 105,
        "spa": 70,
        "spd": 95,
        "spe": 105
      },
      "abilities": {"0": "Leaf Guard"},
      "weightkg": 70,
      "eggGroups": ["Undiscovered"],
      "tags": ["Mythical"],
      "otherFormes": ["Zarude-Dada"],
      "formeOrder": ["Zarude", "Zarude-Dada"]
    },
    "zarudedada": {
      "num": 893,
      "name": "Zarude-Dada",
      "baseSpecies": "Zarude",
      "forme": "Dada",
      "types": ["Dark", "Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 105,
        "atk": 120,
        "def": 105,
        "spa": 70,
        "spd": 95,
        "spe": 105
      },
      "abilities": {"0": "Leaf Guard"},
      "weightkg": 70,
      "eggGroups": ["Undiscovered"]
    },
    "regieleki": {
      "num": 894,
      "name": "Regieleki",
      "types": ["Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 100,
        "def": 50,
        "spa": 100,
        "spd": 50,
        "spe": 200
      },
      "abilities": {"0": "Transistor"},
      "weightkg": 145,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "regidrago": {
      "num": 895,
      "name": "Regidrago",
      "types": ["Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 200,
        "atk": 100,
        "def": 50,
        "spa": 100,
        "spd": 50,
        "spe": 80
      },
      "abilities": {"0": "Dragon's Maw"},
      "weightkg": 200,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "glastrier": {
      "num": 896,
      "name": "Glastrier",
      "types": ["Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 145,
        "def": 130,
        "spa": 65,
        "spd": 110,
        "spe": 30
      },
      "abilities": {"0": "Chilling Neigh"},
      "weightkg": 800,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "spectrier": {
      "num": 897,
      "name": "Spectrier",
      "types": ["Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 65,
        "def": 60,
        "spa": 145,
        "spd": 80,
        "spe": 130
      },
      "abilities": {"0": "Grim Neigh"},
      "weightkg": 44.5,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "calyrex": {
      "num": 898,
      "name": "Calyrex",
      "types": ["Psychic", "Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 80,
        "def": 80,
        "spa": 80,
        "spd": 80,
        "spe": 80
      },
      "abilities": {"0": "Unnerve"},
      "weightkg": 7.7,
      "eggGroups": ["Undiscovered"],
      "tags": ["Restricted Legendary"],
      "otherFormes": ["Calyrex-Ice", "Calyrex-Shadow"],
      "formeOrder": ["Calyrex", "Calyrex-Ice", "Calyrex-Shadow"]
    },
    "calyrexice": {
      "num": 898,
      "name": "Calyrex-Ice",
      "baseSpecies": "Calyrex",
      "forme": "Ice",
      "types": ["Psychic", "Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 165,
        "def": 150,
        "spa": 85,
        "spd": 130,
        "spe": 50
      },
      "abilities": {"0": "As One (Glastrier)"},
      "weightkg": 809.1,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Calyrex"
    },
    "calyrexshadow": {
      "num": 898,
      "name": "Calyrex-Shadow",
      "baseSpecies": "Calyrex",
      "forme": "Shadow",
      "types": ["Psychic", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 85,
        "def": 80,
        "spa": 165,
        "spd": 100,
        "spe": 150
      },
      "abilities": {"0": "As One (Spectrier)"},
      "weightkg": 53.6,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Calyrex"
    },
    "wyrdeer": {
      "num": 899,
      "name": "Wyrdeer",
      "types": ["Normal", "Psychic"],
      "baseStats": {
        "hp": 103,
        "atk": 105,
        "def": 72,
        "spa": 105,
        "spd": 75,
        "spe": 65
      },
      "abilities": {"0": "Intimidate", "1": "Frisk", "H": "Sap Sipper"},
      "weightkg": 95.1,
      "prevo": "Stantler",
      "evoType": "other",
      "evoCondition": "Use Agile style Psyshield Bash 20 times",
      "eggGroups": ["Field"]
    },
    "kleavor": {
      "num": 900,
      "name": "Kleavor",
      "types": ["Bug", "Rock"],
      "baseStats": {
        "hp": 70,
        "atk": 135,
        "def": 95,
        "spa": 45,
        "spd": 70,
        "spe": 85
      },
      "abilities": {"0": "Swarm", "1": "Sheer Force", "H": "Sharpness"},
      "weightkg": 89,
      "prevo": "Scyther",
      "evoType": "other",
      "evoCondition": "Black Augurite",
      "eggGroups": ["Bug"]
    },
    "ursaluna": {
      "num": 901,
      "name": "Ursaluna",
      "types": ["Ground", "Normal"],
      "baseStats": {
        "hp": 130,
        "atk": 140,
        "def": 105,
        "spa": 45,
        "spd": 80,
        "spe": 50
      },
      "abilities": {"0": "Guts", "1": "Bulletproof", "H": "Unnerve"},
      "weightkg": 290,
      "prevo": "Ursaring",
      "evoType": "other",
      "evoCondition": "Peat Block when there's a full moon",
      "eggGroups": ["Field"],
      "otherFormes": ["Ursaluna-Bloodmoon"],
      "formeOrder": ["Ursaluna", "Ursaluna-Bloodmoon"]
    },
    "ursalunabloodmoon": {
      "num": 901,
      "name": "Ursaluna-Bloodmoon",
      "baseSpecies": "Ursaluna",
      "forme": "Bloodmoon",
      "types": ["Ground", "Normal"],
      "gender": "M",
      "baseStats": {
        "hp": 113,
        "atk": 70,
        "def": 120,
        "spa": 135,
        "spd": 65,
        "spe": 52
      },
      "abilities": {"0": "Mind's Eye"},
      "weightkg": 333,
      "eggGroups": ["Field"],
      "gen": 9
    },
    "basculegion": {
      "num": 902,
      "name": "Basculegion",
      "baseForme": "M",
      "types": ["Water", "Ghost"],
      "gender": "M",
      "baseStats": {
        "hp": 120,
        "atk": 112,
        "def": 65,
        "spa": 80,
        "spd": 75,
        "spe": 78
      },
      "abilities": {
        "0": "Swift Swim",
        "1": "Adaptability",
        "H": "Mold Breaker"
      },
      "weightkg": 110,
      "prevo": "Basculin-White-Striped",
      "evoType": "other",
      "evoCondition": "Receive 294+ recoil without fainting",
      "eggGroups": ["Water 2"],
      "otherFormes": ["Basculegion-F"],
      "formeOrder": ["Basculegion", "Basculegion-F"]
    },
    "basculegionf": {
      "num": 902,
      "name": "Basculegion-F",
      "baseSpecies": "Basculegion",
      "forme": "F",
      "types": ["Water", "Ghost"],
      "gender": "F",
      "baseStats": {
        "hp": 120,
        "atk": 92,
        "def": 65,
        "spa": 100,
        "spd": 75,
        "spe": 78
      },
      "abilities": {
        "0": "Swift Swim",
        "1": "Adaptability",
        "H": "Mold Breaker"
      },
      "weightkg": 110,
      "prevo": "Basculin-White-Striped",
      "evoType": "other",
      "evoCondition": "Receive 294+ recoil without fainting",
      "eggGroups": ["Water 2"]
    },
    "sneasler": {
      "num": 903,
      "name": "Sneasler",
      "types": ["Fighting", "Poison"],
      "baseStats": {
        "hp": 80,
        "atk": 130,
        "def": 60,
        "spa": 40,
        "spd": 80,
        "spe": 120
      },
      "abilities": {"0": "Pressure", "1": "Unburden", "H": "Poison Touch"},
      "weightkg": 43,
      "prevo": "Sneasel-Hisui",
      "evoType": "levelHold",
      "evoItem": "Razor Claw",
      "evoCondition": "during the day",
      "eggGroups": ["Field"]
    },
    "overqwil": {
      "num": 904,
      "name": "Overqwil",
      "types": ["Dark", "Poison"],
      "baseStats": {
        "hp": 85,
        "atk": 115,
        "def": 95,
        "spa": 65,
        "spd": 65,
        "spe": 85
      },
      "abilities": {"0": "Poison Point", "1": "Swift Swim", "H": "Intimidate"},
      "weightkg": 60.5,
      "prevo": "Qwilfish-Hisui",
      "evoType": "other",
      "evoCondition": "Use Strong style Barb Barrage 20 times",
      "eggGroups": ["Water 2"]
    },
    "enamorus": {
      "num": 905,
      "name": "Enamorus",
      "baseForme": "Incarnate",
      "types": ["Fairy", "Flying"],
      "gender": "F",
      "baseStats": {
        "hp": 74,
        "atk": 115,
        "def": 70,
        "spa": 135,
        "spd": 80,
        "spe": 106
      },
      "abilities": {"0": "Cute Charm", "H": "Contrary"},
      "weightkg": 48,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Enamorus-Therian"],
      "formeOrder": ["Enamorus", "Enamorus-Therian"]
    },
    "enamorustherian": {
      "num": 905,
      "name": "Enamorus-Therian",
      "baseSpecies": "Enamorus",
      "forme": "Therian",
      "types": ["Fairy", "Flying"],
      "gender": "F",
      "baseStats": {
        "hp": 74,
        "atk": 115,
        "def": 110,
        "spa": 135,
        "spd": 100,
        "spe": 46
      },
      "abilities": {"0": "Overcoat"},
      "weightkg": 48,
      "eggGroups": ["Undiscovered"],
      "changesFrom": "Enamorus"
    },
    "sprigatito": {
      "num": 906,
      "name": "Sprigatito",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 40,
        "atk": 61,
        "def": 54,
        "spa": 45,
        "spd": 45,
        "spe": 65
      },
      "abilities": {"0": "Overgrow", "H": "Protean"},
      "weightkg": 4.1,
      "evos": ["Floragato"],
      "eggGroups": ["Field", "Grass"]
    },
    "floragato": {
      "num": 907,
      "name": "Floragato",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 61,
        "atk": 80,
        "def": 63,
        "spa": 60,
        "spd": 63,
        "spe": 83
      },
      "abilities": {"0": "Overgrow", "H": "Protean"},
      "weightkg": 12.2,
      "prevo": "Sprigatito",
      "evoLevel": 16,
      "evos": ["Meowscarada"],
      "eggGroups": ["Field", "Grass"]
    },
    "meowscarada": {
      "num": 908,
      "name": "Meowscarada",
      "types": ["Grass", "Dark"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 76,
        "atk": 110,
        "def": 70,
        "spa": 81,
        "spd": 70,
        "spe": 123
      },
      "abilities": {"0": "Overgrow", "H": "Protean"},
      "weightkg": 31.2,
      "prevo": "Floragato",
      "evoLevel": 36,
      "eggGroups": ["Field", "Grass"]
    },
    "fuecoco": {
      "num": 909,
      "name": "Fuecoco",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 67,
        "atk": 45,
        "def": 59,
        "spa": 63,
        "spd": 40,
        "spe": 36
      },
      "abilities": {"0": "Blaze", "H": "Unaware"},
      "weightkg": 9.8,
      "evos": ["Crocalor"],
      "eggGroups": ["Field"]
    },
    "crocalor": {
      "num": 910,
      "name": "Crocalor",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 81,
        "atk": 55,
        "def": 78,
        "spa": 90,
        "spd": 58,
        "spe": 49
      },
      "abilities": {"0": "Blaze", "H": "Unaware"},
      "weightkg": 30.7,
      "prevo": "Fuecoco",
      "evoLevel": 16,
      "evos": ["Skeledirge"],
      "eggGroups": ["Field"]
    },
    "skeledirge": {
      "num": 911,
      "name": "Skeledirge",
      "types": ["Fire", "Ghost"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 104,
        "atk": 75,
        "def": 100,
        "spa": 110,
        "spd": 75,
        "spe": 66
      },
      "abilities": {"0": "Blaze", "H": "Unaware"},
      "weightkg": 326.5,
      "prevo": "Crocalor",
      "evoLevel": 36,
      "eggGroups": ["Field"]
    },
    "quaxly": {
      "num": 912,
      "name": "Quaxly",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 55,
        "atk": 65,
        "def": 45,
        "spa": 50,
        "spd": 45,
        "spe": 50
      },
      "abilities": {"0": "Torrent", "H": "Moxie"},
      "weightkg": 6.1,
      "evos": ["Quaxwell"],
      "eggGroups": ["Flying", "Water 1"]
    },
    "quaxwell": {
      "num": 913,
      "name": "Quaxwell",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 65,
        "spa": 65,
        "spd": 60,
        "spe": 65
      },
      "abilities": {"0": "Torrent", "H": "Moxie"},
      "weightkg": 21.5,
      "prevo": "Quaxly",
      "evoLevel": 16,
      "evos": ["Quaquaval"],
      "eggGroups": ["Flying", "Water 1"]
    },
    "quaquaval": {
      "num": 914,
      "name": "Quaquaval",
      "types": ["Water", "Fighting"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 85,
        "atk": 120,
        "def": 80,
        "spa": 85,
        "spd": 75,
        "spe": 85
      },
      "abilities": {"0": "Torrent", "H": "Moxie"},
      "weightkg": 61.9,
      "prevo": "Quaxwell",
      "evoLevel": 36,
      "eggGroups": ["Flying", "Water 1"]
    },
    "lechonk": {
      "num": 915,
      "name": "Lechonk",
      "types": ["Normal"],
      "baseStats": {
        "hp": 54,
        "atk": 45,
        "def": 40,
        "spa": 35,
        "spd": 45,
        "spe": 35
      },
      "abilities": {"0": "Aroma Veil", "1": "Gluttony", "H": "Thick Fat"},
      "weightkg": 10.2,
      "evos": ["Oinkologne", "Oinkologne-F"],
      "eggGroups": ["Field"]
    },
    "oinkologne": {
      "num": 916,
      "name": "Oinkologne",
      "baseForme": "M",
      "types": ["Normal"],
      "gender": "M",
      "baseStats": {
        "hp": 110,
        "atk": 100,
        "def": 75,
        "spa": 59,
        "spd": 80,
        "spe": 65
      },
      "abilities": {"0": "Lingering Aroma", "1": "Gluttony", "H": "Thick Fat"},
      "weightkg": 120,
      "prevo": "Lechonk",
      "evoLevel": 18,
      "otherFormes": ["Oinkologne-F"],
      "formeOrder": ["Oinkologne", "Oinkologne-F"],
      "eggGroups": ["Field"]
    },
    "oinkolognef": {
      "num": 916,
      "name": "Oinkologne-F",
      "baseSpecies": "Oinkologne",
      "forme": "F",
      "types": ["Normal"],
      "gender": "F",
      "baseStats": {
        "hp": 115,
        "atk": 90,
        "def": 70,
        "spa": 59,
        "spd": 90,
        "spe": 65
      },
      "abilities": {"0": "Aroma Veil", "1": "Gluttony", "H": "Thick Fat"},
      "weightkg": 120,
      "prevo": "Lechonk",
      "evoLevel": 18,
      "eggGroups": ["Field"]
    },
    "tarountula": {
      "num": 917,
      "name": "Tarountula",
      "types": ["Bug"],
      "baseStats": {
        "hp": 35,
        "atk": 41,
        "def": 45,
        "spa": 29,
        "spd": 40,
        "spe": 20
      },
      "abilities": {"0": "Insomnia", "H": "Stakeout"},
      "weightkg": 4,
      "evos": ["Spidops"],
      "eggGroups": ["Bug"]
    },
    "spidops": {
      "num": 918,
      "name": "Spidops",
      "types": ["Bug"],
      "baseStats": {
        "hp": 60,
        "atk": 79,
        "def": 92,
        "spa": 52,
        "spd": 86,
        "spe": 35
      },
      "abilities": {"0": "Insomnia", "H": "Stakeout"},
      "weightkg": 16.5,
      "prevo": "Tarountula",
      "evoLevel": 15,
      "eggGroups": ["Bug"]
    },
    "nymble": {
      "num": 919,
      "name": "Nymble",
      "types": ["Bug"],
      "baseStats": {
        "hp": 33,
        "atk": 46,
        "def": 40,
        "spa": 21,
        "spd": 25,
        "spe": 45
      },
      "abilities": {"0": "Swarm", "H": "Tinted Lens"},
      "weightkg": 1,
      "evos": ["Lokix"],
      "eggGroups": ["Bug"]
    },
    "lokix": {
      "num": 920,
      "name": "Lokix",
      "types": ["Bug", "Dark"],
      "baseStats": {
        "hp": 71,
        "atk": 102,
        "def": 78,
        "spa": 52,
        "spd": 55,
        "spe": 92
      },
      "abilities": {"0": "Swarm", "H": "Tinted Lens"},
      "weightkg": 17.5,
      "prevo": "Nymble",
      "evoLevel": 24,
      "eggGroups": ["Bug"]
    },
    "pawmi": {
      "num": 921,
      "name": "Pawmi",
      "types": ["Electric"],
      "baseStats": {
        "hp": 45,
        "atk": 50,
        "def": 20,
        "spa": 40,
        "spd": 25,
        "spe": 60
      },
      "abilities": {"0": "Static", "1": "Natural Cure", "H": "Iron Fist"},
      "weightkg": 2.5,
      "evos": ["Pawmo"],
      "eggGroups": ["Field"]
    },
    "pawmo": {
      "num": 922,
      "name": "Pawmo",
      "types": ["Electric", "Fighting"],
      "baseStats": {
        "hp": 60,
        "atk": 75,
        "def": 40,
        "spa": 50,
        "spd": 40,
        "spe": 85
      },
      "abilities": {"0": "Volt Absorb", "1": "Natural Cure", "H": "Iron Fist"},
      "weightkg": 6.5,
      "prevo": "Pawmi",
      "evoLevel": 18,
      "evos": ["Pawmot"],
      "eggGroups": ["Field"]
    },
    "pawmot": {
      "num": 923,
      "name": "Pawmot",
      "types": ["Electric", "Fighting"],
      "baseStats": {
        "hp": 70,
        "atk": 115,
        "def": 70,
        "spa": 70,
        "spd": 60,
        "spe": 105
      },
      "abilities": {"0": "Volt Absorb", "1": "Natural Cure", "H": "Iron Fist"},
      "weightkg": 41,
      "prevo": "Pawmo",
      "evoType": "other",
      "evoCondition": "walk 1000 steps in Let's Go",
      "eggGroups": ["Field"]
    },
    "tandemaus": {
      "num": 924,
      "name": "Tandemaus",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 50,
        "atk": 50,
        "def": 45,
        "spa": 40,
        "spd": 45,
        "spe": 75
      },
      "abilities": {"0": "Run Away", "1": "Pickup", "H": "Own Tempo"},
      "weightkg": 1.8,
      "evos": ["Maushold", "Maushold-Four"],
      "eggGroups": ["Field", "Fairy"]
    },
    "maushold": {
      "num": 925,
      "name": "Maushold",
      "baseForme": "Three",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 74,
        "atk": 75,
        "def": 70,
        "spa": 65,
        "spd": 75,
        "spe": 111
      },
      "abilities": {"0": "Friend Guard", "1": "Cheek Pouch", "H": "Technician"},
      "weightkg": 2.3,
      "prevo": "Tandemaus",
      "evoLevel": 25,
      "otherFormes": ["Maushold-Four"],
      "formeOrder": ["Maushold", "Maushold-Four"],
      "eggGroups": ["Field", "Fairy"]
    },
    "mausholdfour": {
      "num": 925,
      "name": "Maushold-Four",
      "baseSpecies": "Maushold",
      "forme": "Four",
      "types": ["Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 74,
        "atk": 75,
        "def": 70,
        "spa": 65,
        "spd": 75,
        "spe": 111
      },
      "abilities": {"0": "Friend Guard", "1": "Cheek Pouch", "H": "Technician"},
      "weightkg": 2.8,
      "prevo": "Tandemaus",
      "evoLevel": 25,
      "eggGroups": ["Field", "Fairy"]
    },
    "fidough": {
      "num": 926,
      "name": "Fidough",
      "types": ["Fairy"],
      "baseStats": {
        "hp": 37,
        "atk": 55,
        "def": 70,
        "spa": 30,
        "spd": 55,
        "spe": 65
      },
      "abilities": {"0": "Own Tempo", "H": "Klutz"},
      "weightkg": 10.9,
      "evos": ["Dachsbun"],
      "eggGroups": ["Field", "Mineral"]
    },
    "dachsbun": {
      "num": 927,
      "name": "Dachsbun",
      "types": ["Fairy"],
      "baseStats": {
        "hp": 57,
        "atk": 80,
        "def": 115,
        "spa": 50,
        "spd": 80,
        "spe": 95
      },
      "abilities": {"0": "Well-Baked Body", "H": "Aroma Veil"},
      "weightkg": 14.9,
      "prevo": "Fidough",
      "evoLevel": 26,
      "eggGroups": ["Field", "Mineral"]
    },
    "smoliv": {
      "num": 928,
      "name": "Smoliv",
      "types": ["Grass", "Normal"],
      "baseStats": {
        "hp": 41,
        "atk": 35,
        "def": 45,
        "spa": 58,
        "spd": 51,
        "spe": 30
      },
      "abilities": {"0": "Early Bird", "H": "Harvest"},
      "weightkg": 6.5,
      "evos": ["Dolliv"],
      "eggGroups": ["Grass"]
    },
    "dolliv": {
      "num": 929,
      "name": "Dolliv",
      "types": ["Grass", "Normal"],
      "baseStats": {
        "hp": 52,
        "atk": 53,
        "def": 60,
        "spa": 78,
        "spd": 78,
        "spe": 33
      },
      "abilities": {"0": "Early Bird", "H": "Harvest"},
      "weightkg": 11.9,
      "prevo": "Smoliv",
      "evoLevel": 25,
      "evos": ["Arboliva"],
      "eggGroups": ["Grass"]
    },
    "arboliva": {
      "num": 930,
      "name": "Arboliva",
      "types": ["Grass", "Normal"],
      "baseStats": {
        "hp": 78,
        "atk": 69,
        "def": 90,
        "spa": 125,
        "spd": 109,
        "spe": 39
      },
      "abilities": {"0": "Seed Sower", "H": "Harvest"},
      "weightkg": 48.2,
      "prevo": "Dolliv",
      "evoLevel": 35,
      "eggGroups": ["Grass"]
    },
    "squawkabilly": {
      "num": 931,
      "name": "Squawkabilly",
      "baseForme": "Green",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 82,
        "atk": 96,
        "def": 51,
        "spa": 45,
        "spd": 51,
        "spe": 92
      },
      "abilities": {"0": "Intimidate", "1": "Hustle", "H": "Guts"},
      "weightkg": 2.4,
      "otherFormes": [
        "Squawkabilly-Blue",
        "Squawkabilly-Yellow",
        "Squawkabilly-White"
      ],
      "formeOrder": [
        "Squawkabilly",
        "Squawkabilly-Blue",
        "Squawkabilly-Yellow",
        "Squawkabilly-White"
      ],
      "eggGroups": ["Flying"]
    },
    "squawkabillyblue": {
      "num": 931,
      "name": "Squawkabilly-Blue",
      "baseSpecies": "Squawkabilly",
      "forme": "Blue",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 82,
        "atk": 96,
        "def": 51,
        "spa": 45,
        "spd": 51,
        "spe": 92
      },
      "abilities": {"0": "Intimidate", "1": "Hustle", "H": "Guts"},
      "weightkg": 2.4,
      "eggGroups": ["Flying"]
    },
    "squawkabillyyellow": {
      "num": 931,
      "name": "Squawkabilly-Yellow",
      "baseSpecies": "Squawkabilly",
      "forme": "Yellow",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 82,
        "atk": 96,
        "def": 51,
        "spa": 45,
        "spd": 51,
        "spe": 92
      },
      "abilities": {"0": "Intimidate", "1": "Hustle", "H": "Sheer Force"},
      "weightkg": 2.4,
      "eggGroups": ["Flying"]
    },
    "squawkabillywhite": {
      "num": 931,
      "name": "Squawkabilly-White",
      "baseSpecies": "Squawkabilly",
      "forme": "White",
      "types": ["Normal", "Flying"],
      "baseStats": {
        "hp": 82,
        "atk": 96,
        "def": 51,
        "spa": 45,
        "spd": 51,
        "spe": 92
      },
      "abilities": {"0": "Intimidate", "1": "Hustle", "H": "Sheer Force"},
      "weightkg": 2.4,
      "eggGroups": ["Flying"]
    },
    "nacli": {
      "num": 932,
      "name": "Nacli",
      "types": ["Rock"],
      "baseStats": {
        "hp": 55,
        "atk": 55,
        "def": 75,
        "spa": 35,
        "spd": 35,
        "spe": 25
      },
      "abilities": {"0": "Purifying Salt", "1": "Sturdy", "H": "Clear Body"},
      "weightkg": 16,
      "evos": ["Naclstack"],
      "eggGroups": ["Mineral"]
    },
    "naclstack": {
      "num": 933,
      "name": "Naclstack",
      "types": ["Rock"],
      "baseStats": {
        "hp": 60,
        "atk": 60,
        "def": 100,
        "spa": 35,
        "spd": 65,
        "spe": 35
      },
      "abilities": {"0": "Purifying Salt", "1": "Sturdy", "H": "Clear Body"},
      "weightkg": 105,
      "prevo": "Nacli",
      "evoLevel": 24,
      "evos": ["Garganacl"],
      "eggGroups": ["Mineral"]
    },
    "garganacl": {
      "num": 934,
      "name": "Garganacl",
      "types": ["Rock"],
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 130,
        "spa": 45,
        "spd": 90,
        "spe": 35
      },
      "abilities": {"0": "Purifying Salt", "1": "Sturdy", "H": "Clear Body"},
      "weightkg": 240,
      "prevo": "Naclstack",
      "evoLevel": 38,
      "eggGroups": ["Mineral"]
    },
    "charcadet": {
      "num": 935,
      "name": "Charcadet",
      "types": ["Fire"],
      "baseStats": {
        "hp": 40,
        "atk": 50,
        "def": 40,
        "spa": 50,
        "spd": 40,
        "spe": 35
      },
      "abilities": {"0": "Flash Fire", "H": "Flame Body"},
      "weightkg": 10.5,
      "evos": ["Armarouge", "Ceruledge"],
      "eggGroups": ["Human-Like"]
    },
    "armarouge": {
      "num": 936,
      "name": "Armarouge",
      "types": ["Fire", "Psychic"],
      "baseStats": {
        "hp": 85,
        "atk": 60,
        "def": 100,
        "spa": 125,
        "spd": 80,
        "spe": 75
      },
      "abilities": {"0": "Flash Fire", "H": "Weak Armor"},
      "weightkg": 85,
      "prevo": "Charcadet",
      "evoType": "useItem",
      "evoItem": "Auspicious Armor",
      "eggGroups": ["Human-Like"]
    },
    "ceruledge": {
      "num": 937,
      "name": "Ceruledge",
      "types": ["Fire", "Ghost"],
      "baseStats": {
        "hp": 75,
        "atk": 125,
        "def": 80,
        "spa": 60,
        "spd": 100,
        "spe": 85
      },
      "abilities": {"0": "Flash Fire", "H": "Weak Armor"},
      "weightkg": 62,
      "prevo": "Charcadet",
      "evoType": "useItem",
      "evoItem": "Malicious Armor",
      "eggGroups": ["Human-Like"]
    },
    "tadbulb": {
      "num": 938,
      "name": "Tadbulb",
      "types": ["Electric"],
      "baseStats": {
        "hp": 61,
        "atk": 31,
        "def": 41,
        "spa": 59,
        "spd": 35,
        "spe": 45
      },
      "abilities": {"0": "Own Tempo", "1": "Static", "H": "Damp"},
      "weightkg": 0.4,
      "evos": ["Bellibolt"],
      "eggGroups": ["Water 1"]
    },
    "bellibolt": {
      "num": 939,
      "name": "Bellibolt",
      "types": ["Electric"],
      "baseStats": {
        "hp": 109,
        "atk": 64,
        "def": 91,
        "spa": 103,
        "spd": 83,
        "spe": 45
      },
      "abilities": {"0": "Electromorphosis", "1": "Static", "H": "Damp"},
      "weightkg": 113,
      "prevo": "Tadbulb",
      "evoType": "useItem",
      "evoItem": "Thunder Stone",
      "eggGroups": ["Water 1"]
    },
    "wattrel": {
      "num": 940,
      "name": "Wattrel",
      "types": ["Electric", "Flying"],
      "baseStats": {
        "hp": 40,
        "atk": 40,
        "def": 35,
        "spa": 55,
        "spd": 40,
        "spe": 70
      },
      "abilities": {"0": "Wind Power", "1": "Volt Absorb", "H": "Competitive"},
      "weightkg": 3.6,
      "evos": ["Kilowattrel"],
      "eggGroups": ["Water 1", "Flying"]
    },
    "kilowattrel": {
      "num": 941,
      "name": "Kilowattrel",
      "types": ["Electric", "Flying"],
      "baseStats": {
        "hp": 70,
        "atk": 70,
        "def": 60,
        "spa": 105,
        "spd": 60,
        "spe": 125
      },
      "abilities": {"0": "Wind Power", "1": "Volt Absorb", "H": "Competitive"},
      "weightkg": 38.6,
      "prevo": "Wattrel",
      "evoLevel": 25,
      "eggGroups": ["Water 1", "Flying"]
    },
    "maschiff": {
      "num": 942,
      "name": "Maschiff",
      "types": ["Dark"],
      "baseStats": {
        "hp": 60,
        "atk": 78,
        "def": 60,
        "spa": 40,
        "spd": 51,
        "spe": 51
      },
      "abilities": {"0": "Intimidate", "1": "Run Away", "H": "Stakeout"},
      "weightkg": 16,
      "evos": ["Mabosstiff"],
      "eggGroups": ["Field"]
    },
    "mabosstiff": {
      "num": 943,
      "name": "Mabosstiff",
      "types": ["Dark"],
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 90,
        "spa": 60,
        "spd": 70,
        "spe": 85
      },
      "abilities": {"0": "Intimidate", "1": "Guard Dog", "H": "Stakeout"},
      "weightkg": 61,
      "prevo": "Maschiff",
      "evoLevel": 30,
      "eggGroups": ["Field"]
    },
    "shroodle": {
      "num": 944,
      "name": "Shroodle",
      "types": ["Poison", "Normal"],
      "baseStats": {
        "hp": 40,
        "atk": 65,
        "def": 35,
        "spa": 40,
        "spd": 35,
        "spe": 75
      },
      "abilities": {"0": "Unburden", "1": "Pickpocket", "H": "Prankster"},
      "weightkg": 0.7,
      "evos": ["Grafaiai"],
      "eggGroups": ["Field"]
    },
    "grafaiai": {
      "num": 945,
      "name": "Grafaiai",
      "types": ["Poison", "Normal"],
      "baseStats": {
        "hp": 63,
        "atk": 95,
        "def": 65,
        "spa": 80,
        "spd": 72,
        "spe": 110
      },
      "abilities": {"0": "Unburden", "1": "Poison Touch", "H": "Prankster"},
      "weightkg": 27.2,
      "prevo": "Shroodle",
      "evoLevel": 28,
      "eggGroups": ["Field"]
    },
    "bramblin": {
      "num": 946,
      "name": "Bramblin",
      "types": ["Grass", "Ghost"],
      "baseStats": {
        "hp": 40,
        "atk": 65,
        "def": 30,
        "spa": 45,
        "spd": 35,
        "spe": 60
      },
      "abilities": {"0": "Wind Rider", "H": "Infiltrator"},
      "weightkg": 0.6,
      "evos": ["Brambleghast"],
      "eggGroups": ["Grass"]
    },
    "brambleghast": {
      "num": 947,
      "name": "Brambleghast",
      "types": ["Grass", "Ghost"],
      "baseStats": {
        "hp": 55,
        "atk": 115,
        "def": 70,
        "spa": 80,
        "spd": 70,
        "spe": 90
      },
      "abilities": {"0": "Wind Rider", "H": "Infiltrator"},
      "weightkg": 6,
      "prevo": "Bramblin",
      "evoType": "other",
      "evoCondition": "Walk 1000 steps in Let's Go",
      "eggGroups": ["Grass"]
    },
    "toedscool": {
      "num": 948,
      "name": "Toedscool",
      "types": ["Ground", "Grass"],
      "baseStats": {
        "hp": 40,
        "atk": 40,
        "def": 35,
        "spa": 50,
        "spd": 100,
        "spe": 70
      },
      "abilities": {"0": "Mycelium Might"},
      "weightkg": 33,
      "evos": ["Toedscruel"],
      "eggGroups": ["Field"]
    },
    "toedscruel": {
      "num": 949,
      "name": "Toedscruel",
      "types": ["Ground", "Grass"],
      "baseStats": {
        "hp": 80,
        "atk": 70,
        "def": 65,
        "spa": 80,
        "spd": 120,
        "spe": 100
      },
      "abilities": {"0": "Mycelium Might"},
      "weightkg": 58,
      "prevo": "Toedscool",
      "evoLevel": 30,
      "eggGroups": ["Field"]
    },
    "klawf": {
      "num": 950,
      "name": "Klawf",
      "types": ["Rock"],
      "baseStats": {
        "hp": 70,
        "atk": 100,
        "def": 115,
        "spa": 35,
        "spd": 55,
        "spe": 75
      },
      "abilities": {"0": "Anger Shell", "1": "Shell Armor", "H": "Regenerator"},
      "weightkg": 79,
      "eggGroups": ["Water 3"]
    },
    "capsakid": {
      "num": 951,
      "name": "Capsakid",
      "types": ["Grass"],
      "baseStats": {
        "hp": 50,
        "atk": 62,
        "def": 40,
        "spa": 62,
        "spd": 40,
        "spe": 50
      },
      "abilities": {"0": "Chlorophyll", "1": "Insomnia", "H": "Klutz"},
      "weightkg": 3,
      "evos": ["Scovillain"],
      "eggGroups": ["Grass"]
    },
    "scovillain": {
      "num": 952,
      "name": "Scovillain",
      "types": ["Grass", "Fire"],
      "baseStats": {
        "hp": 65,
        "atk": 108,
        "def": 65,
        "spa": 108,
        "spd": 65,
        "spe": 75
      },
      "abilities": {"0": "Chlorophyll", "1": "Insomnia", "H": "Moody"},
      "weightkg": 15,
      "prevo": "Capsakid",
      "evoType": "useItem",
      "evoItem": "Fire Stone",
      "eggGroups": ["Grass"]
    },
    "rellor": {
      "num": 953,
      "name": "Rellor",
      "types": ["Bug"],
      "baseStats": {
        "hp": 41,
        "atk": 50,
        "def": 60,
        "spa": 31,
        "spd": 58,
        "spe": 30
      },
      "abilities": {"0": "Compound Eyes", "H": "Shed Skin"},
      "weightkg": 1,
      "evos": ["Rabsca"],
      "eggGroups": ["Bug"]
    },
    "rabsca": {
      "num": 954,
      "name": "Rabsca",
      "types": ["Bug", "Psychic"],
      "baseStats": {
        "hp": 75,
        "atk": 50,
        "def": 85,
        "spa": 115,
        "spd": 100,
        "spe": 45
      },
      "abilities": {"0": "Synchronize", "H": "Telepathy"},
      "weightkg": 3.5,
      "prevo": "Rellor",
      "evoType": "other",
      "evoCondition": "walk 1000 steps in Let's Go",
      "eggGroups": ["Bug"]
    },
    "flittle": {
      "num": 955,
      "name": "Flittle",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 30,
        "atk": 35,
        "def": 30,
        "spa": 55,
        "spd": 30,
        "spe": 75
      },
      "abilities": {"0": "Anticipation", "1": "Frisk", "H": "Speed Boost"},
      "weightkg": 1.5,
      "evos": ["Espathra"],
      "eggGroups": ["Flying"]
    },
    "espathra": {
      "num": 956,
      "name": "Espathra",
      "types": ["Psychic"],
      "baseStats": {
        "hp": 95,
        "atk": 60,
        "def": 60,
        "spa": 101,
        "spd": 60,
        "spe": 105
      },
      "abilities": {"0": "Opportunist", "1": "Frisk", "H": "Speed Boost"},
      "weightkg": 90,
      "prevo": "Flittle",
      "evoLevel": 35,
      "eggGroups": ["Flying"]
    },
    "tinkatink": {
      "num": 957,
      "name": "Tinkatink",
      "types": ["Fairy", "Steel"],
      "gender": "F",
      "baseStats": {
        "hp": 50,
        "atk": 45,
        "def": 45,
        "spa": 35,
        "spd": 64,
        "spe": 58
      },
      "abilities": {"0": "Mold Breaker", "1": "Own Tempo", "H": "Pickpocket"},
      "weightkg": 8.9,
      "evos": ["Tinkatuff"],
      "eggGroups": ["Fairy"]
    },
    "tinkatuff": {
      "num": 958,
      "name": "Tinkatuff",
      "types": ["Fairy", "Steel"],
      "gender": "F",
      "baseStats": {
        "hp": 65,
        "atk": 55,
        "def": 55,
        "spa": 45,
        "spd": 82,
        "spe": 78
      },
      "abilities": {"0": "Mold Breaker", "1": "Own Tempo", "H": "Pickpocket"},
      "weightkg": 59.1,
      "prevo": "Tinkatink",
      "evoLevel": 24,
      "evos": ["Tinkaton"],
      "eggGroups": ["Fairy"]
    },
    "tinkaton": {
      "num": 959,
      "name": "Tinkaton",
      "types": ["Fairy", "Steel"],
      "gender": "F",
      "baseStats": {
        "hp": 85,
        "atk": 75,
        "def": 77,
        "spa": 70,
        "spd": 105,
        "spe": 94
      },
      "abilities": {"0": "Mold Breaker", "1": "Own Tempo", "H": "Pickpocket"},
      "weightkg": 112.8,
      "prevo": "Tinkatuff",
      "evoLevel": 38,
      "eggGroups": ["Fairy"]
    },
    "wiglett": {
      "num": 960,
      "name": "Wiglett",
      "types": ["Water"],
      "baseStats": {
        "hp": 10,
        "atk": 55,
        "def": 25,
        "spa": 35,
        "spd": 25,
        "spe": 95
      },
      "abilities": {"0": "Gooey", "1": "Rattled", "H": "Sand Veil"},
      "weightkg": 1.8,
      "evos": ["Wugtrio"],
      "eggGroups": ["Water 3"]
    },
    "wugtrio": {
      "num": 961,
      "name": "Wugtrio",
      "types": ["Water"],
      "baseStats": {
        "hp": 35,
        "atk": 100,
        "def": 50,
        "spa": 50,
        "spd": 70,
        "spe": 120
      },
      "abilities": {"0": "Gooey", "1": "Rattled", "H": "Sand Veil"},
      "weightkg": 5.4,
      "prevo": "Wiglett",
      "evoLevel": 26,
      "eggGroups": ["Water 3"]
    },
    "bombirdier": {
      "num": 962,
      "name": "Bombirdier",
      "types": ["Flying", "Dark"],
      "baseStats": {
        "hp": 70,
        "atk": 103,
        "def": 85,
        "spa": 60,
        "spd": 85,
        "spe": 82
      },
      "abilities": {"0": "Big Pecks", "1": "Keen Eye", "H": "Rocky Payload"},
      "weightkg": 42.9,
      "eggGroups": ["Flying"]
    },
    "finizen": {
      "num": 963,
      "name": "Finizen",
      "types": ["Water"],
      "baseStats": {
        "hp": 70,
        "atk": 45,
        "def": 40,
        "spa": 45,
        "spd": 40,
        "spe": 75
      },
      "abilities": {"0": "Water Veil"},
      "weightkg": 60.2,
      "evos": ["Palafin"],
      "eggGroups": ["Field", "Water 2"]
    },
    "palafin": {
      "num": 964,
      "name": "Palafin",
      "baseForme": "Zero",
      "types": ["Water"],
      "baseStats": {
        "hp": 100,
        "atk": 70,
        "def": 72,
        "spa": 53,
        "spd": 62,
        "spe": 100
      },
      "abilities": {"0": "Zero to Hero"},
      "weightkg": 60.2,
      "prevo": "Finizen",
      "evoLevel": 38,
      "otherFormes": ["Palafin-Hero"],
      "formeOrder": ["Palafin", "Palafin-Hero"],
      "eggGroups": ["Field", "Water 2"]
    },
    "palafinhero": {
      "num": 964,
      "name": "Palafin-Hero",
      "baseSpecies": "Palafin",
      "forme": "Hero",
      "types": ["Water"],
      "baseStats": {
        "hp": 100,
        "atk": 160,
        "def": 97,
        "spa": 106,
        "spd": 87,
        "spe": 100
      },
      "abilities": {"0": "Zero to Hero"},
      "weightkg": 97.4,
      "eggGroups": ["Field", "Water 2"],
      "requiredAbility": "Zero to Hero",
      "battleOnly": "Palafin"
    },
    "varoom": {
      "num": 965,
      "name": "Varoom",
      "types": ["Steel", "Poison"],
      "baseStats": {
        "hp": 45,
        "atk": 70,
        "def": 63,
        "spa": 30,
        "spd": 45,
        "spe": 47
      },
      "abilities": {"0": "Overcoat", "H": "Slow Start"},
      "weightkg": 35,
      "evos": ["Revavroom"],
      "eggGroups": ["Mineral"]
    },
    "revavroom": {
      "num": 966,
      "name": "Revavroom",
      "types": ["Steel", "Poison"],
      "baseStats": {
        "hp": 80,
        "atk": 119,
        "def": 90,
        "spa": 54,
        "spd": 67,
        "spe": 90
      },
      "abilities": {"0": "Overcoat", "H": "Filter"},
      "weightkg": 120,
      "prevo": "Varoom",
      "evoLevel": 40,
      "eggGroups": ["Mineral"]
    },
    "cyclizar": {
      "num": 967,
      "name": "Cyclizar",
      "types": ["Dragon", "Normal"],
      "baseStats": {
        "hp": 70,
        "atk": 95,
        "def": 65,
        "spa": 85,
        "spd": 65,
        "spe": 121
      },
      "abilities": {"0": "Shed Skin", "H": "Regenerator"},
      "weightkg": 63,
      "eggGroups": ["Field"]
    },
    "orthworm": {
      "num": 968,
      "name": "Orthworm",
      "types": ["Steel"],
      "baseStats": {
        "hp": 70,
        "atk": 85,
        "def": 145,
        "spa": 60,
        "spd": 55,
        "spe": 65
      },
      "abilities": {"0": "Earth Eater", "H": "Sand Veil"},
      "weightkg": 310,
      "eggGroups": ["Field"]
    },
    "glimmet": {
      "num": 969,
      "name": "Glimmet",
      "types": ["Rock", "Poison"],
      "baseStats": {
        "hp": 48,
        "atk": 35,
        "def": 42,
        "spa": 105,
        "spd": 60,
        "spe": 60
      },
      "abilities": {"0": "Toxic Debris", "H": "Corrosion"},
      "weightkg": 8,
      "evos": ["Glimmora"],
      "eggGroups": ["Mineral"]
    },
    "glimmora": {
      "num": 970,
      "name": "Glimmora",
      "types": ["Rock", "Poison"],
      "baseStats": {
        "hp": 83,
        "atk": 55,
        "def": 90,
        "spa": 130,
        "spd": 81,
        "spe": 86
      },
      "abilities": {"0": "Toxic Debris", "H": "Corrosion"},
      "weightkg": 45,
      "prevo": "Glimmet",
      "evoLevel": 35,
      "eggGroups": ["Mineral"]
    },
    "greavard": {
      "num": 971,
      "name": "Greavard",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 50,
        "atk": 61,
        "def": 60,
        "spa": 30,
        "spd": 55,
        "spe": 34
      },
      "abilities": {"0": "Pickup", "H": "Fluffy"},
      "weightkg": 35,
      "evos": ["Houndstone"],
      "eggGroups": ["Field"]
    },
    "houndstone": {
      "num": 972,
      "name": "Houndstone",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 72,
        "atk": 101,
        "def": 100,
        "spa": 50,
        "spd": 97,
        "spe": 68
      },
      "abilities": {"0": "Sand Rush", "H": "Fluffy"},
      "weightkg": 15,
      "prevo": "Greavard",
      "evoLevel": 30,
      "evoCondition": "at night",
      "eggGroups": ["Field"]
    },
    "flamigo": {
      "num": 973,
      "name": "Flamigo",
      "types": ["Flying", "Fighting"],
      "baseStats": {
        "hp": 82,
        "atk": 115,
        "def": 74,
        "spa": 75,
        "spd": 64,
        "spe": 90
      },
      "abilities": {"0": "Scrappy", "1": "Tangled Feet", "H": "Costar"},
      "weightkg": 37,
      "eggGroups": ["Flying"]
    },
    "cetoddle": {
      "num": 974,
      "name": "Cetoddle",
      "types": ["Ice"],
      "baseStats": {
        "hp": 108,
        "atk": 68,
        "def": 45,
        "spa": 30,
        "spd": 40,
        "spe": 43
      },
      "abilities": {"0": "Thick Fat", "1": "Snow Cloak", "H": "Sheer Force"},
      "weightkg": 45,
      "evos": ["Cetitan"],
      "eggGroups": ["Field"]
    },
    "cetitan": {
      "num": 975,
      "name": "Cetitan",
      "types": ["Ice"],
      "baseStats": {
        "hp": 170,
        "atk": 113,
        "def": 65,
        "spa": 45,
        "spd": 55,
        "spe": 73
      },
      "abilities": {"0": "Thick Fat", "1": "Slush Rush", "H": "Sheer Force"},
      "weightkg": 700,
      "prevo": "Cetoddle",
      "evoType": "useItem",
      "evoItem": "Ice Stone",
      "eggGroups": ["Field"]
    },
    "veluza": {
      "num": 976,
      "name": "Veluza",
      "types": ["Water", "Psychic"],
      "baseStats": {
        "hp": 90,
        "atk": 102,
        "def": 73,
        "spa": 78,
        "spd": 65,
        "spe": 70
      },
      "abilities": {"0": "Mold Breaker", "H": "Sharpness"},
      "weightkg": 90,
      "eggGroups": ["Water 2"]
    },
    "dondozo": {
      "num": 977,
      "name": "Dondozo",
      "types": ["Water"],
      "baseStats": {
        "hp": 150,
        "atk": 100,
        "def": 115,
        "spa": 65,
        "spd": 65,
        "spe": 35
      },
      "abilities": {"0": "Unaware", "1": "Oblivious", "H": "Water Veil"},
      "weightkg": 220,
      "eggGroups": ["Water 2"]
    },
    "tatsugiri": {
      "num": 978,
      "name": "Tatsugiri",
      "baseForme": "Curly",
      "types": ["Dragon", "Water"],
      "baseStats": {
        "hp": 68,
        "atk": 50,
        "def": 60,
        "spa": 120,
        "spd": 95,
        "spe": 82
      },
      "abilities": {"0": "Commander", "H": "Storm Drain"},
      "weightkg": 8,
      "cosmeticFormes": ["Tatsugiri-Droopy", "Tatsugiri-Stretchy"],
      "formeOrder": ["Tatsugiri", "Tatsugiri-Droopy", "Tatsugiri-Stretchy"],
      "eggGroups": ["Water 2"]
    },
    "annihilape": {
      "num": 979,
      "name": "Annihilape",
      "types": ["Fighting", "Ghost"],
      "baseStats": {
        "hp": 110,
        "atk": 115,
        "def": 80,
        "spa": 50,
        "spd": 90,
        "spe": 90
      },
      "abilities": {"0": "Vital Spirit", "1": "Inner Focus", "H": "Defiant"},
      "weightkg": 56,
      "prevo": "Primeape",
      "evoType": "other",
      "evoCondition": "Use Rage Fist 20 times and level-up",
      "eggGroups": ["Field"]
    },
    "clodsire": {
      "num": 980,
      "name": "Clodsire",
      "types": ["Poison", "Ground"],
      "baseStats": {
        "hp": 130,
        "atk": 75,
        "def": 60,
        "spa": 45,
        "spd": 100,
        "spe": 20
      },
      "abilities": {"0": "Poison Point", "1": "Water Absorb", "H": "Unaware"},
      "weightkg": 223,
      "prevo": "Wooper-Paldea",
      "evoLevel": 20,
      "eggGroups": ["Water 1", "Field"]
    },
    "farigiraf": {
      "num": 981,
      "name": "Farigiraf",
      "types": ["Normal", "Psychic"],
      "baseStats": {
        "hp": 120,
        "atk": 90,
        "def": 70,
        "spa": 110,
        "spd": 70,
        "spe": 60
      },
      "abilities": {"0": "Cud Chew", "1": "Armor Tail", "H": "Sap Sipper"},
      "weightkg": 160,
      "prevo": "Girafarig",
      "evoType": "levelMove",
      "evoMove": "Twin Beam",
      "eggGroups": ["Field"]
    },
    "dudunsparce": {
      "num": 982,
      "name": "Dudunsparce",
      "baseForme": "Two-Segment",
      "types": ["Normal"],
      "baseStats": {
        "hp": 125,
        "atk": 100,
        "def": 80,
        "spa": 85,
        "spd": 75,
        "spe": 55
      },
      "abilities": {"0": "Serene Grace", "1": "Run Away", "H": "Rattled"},
      "weightkg": 39.2,
      "prevo": "Dunsparce",
      "evoType": "levelMove",
      "evoMove": "Hyper Drill",
      "otherFormes": ["Dudunsparce-Three-Segment"],
      "formeOrder": ["Dudunsparce", "Dudunsparce-Three-Segment"],
      "eggGroups": ["Field"]
    },
    "dudunsparcethreesegment": {
      "num": 982,
      "name": "Dudunsparce-Three-Segment",
      "baseSpecies": "Dudunsparce",
      "forme": "Three-Segment",
      "types": ["Normal"],
      "baseStats": {
        "hp": 125,
        "atk": 100,
        "def": 80,
        "spa": 85,
        "spd": 75,
        "spe": 55
      },
      "abilities": {"0": "Serene Grace", "1": "Run Away", "H": "Rattled"},
      "weightkg": 47.4,
      "prevo": "Dunsparce",
      "evoType": "levelMove",
      "evoMove": "Hyper Drill",
      "eggGroups": ["Field"]
    },
    "kingambit": {
      "num": 983,
      "name": "Kingambit",
      "types": ["Dark", "Steel"],
      "baseStats": {
        "hp": 100,
        "atk": 135,
        "def": 120,
        "spa": 60,
        "spd": 85,
        "spe": 50
      },
      "abilities": {"0": "Defiant", "1": "Supreme Overlord", "H": "Pressure"},
      "weightkg": 120,
      "prevo": "Bisharp",
      "evoType": "other",
      "evoCondition": "Defeat 3 Bisharp leading Pawniard and level-up",
      "eggGroups": ["Human-Like"]
    },
    "greattusk": {
      "num": 984,
      "name": "Great Tusk",
      "types": ["Ground", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 115,
        "atk": 131,
        "def": 131,
        "spa": 53,
        "spd": 53,
        "spe": 87
      },
      "abilities": {"0": "Protosynthesis"},
      "weightkg": 320,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "screamtail": {
      "num": 985,
      "name": "Scream Tail",
      "types": ["Fairy", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 115,
        "atk": 65,
        "def": 99,
        "spa": 65,
        "spd": 115,
        "spe": 111
      },
      "abilities": {"0": "Protosynthesis"},
      "weightkg": 8,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "brutebonnet": {
      "num": 986,
      "name": "Brute Bonnet",
      "types": ["Grass", "Dark"],
      "gender": "N",
      "baseStats": {
        "hp": 111,
        "atk": 127,
        "def": 99,
        "spa": 79,
        "spd": 99,
        "spe": 55
      },
      "abilities": {"0": "Protosynthesis"},
      "weightkg": 21,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "fluttermane": {
      "num": 987,
      "name": "Flutter Mane",
      "types": ["Ghost", "Fairy"],
      "gender": "N",
      "baseStats": {
        "hp": 55,
        "atk": 55,
        "def": 55,
        "spa": 135,
        "spd": 135,
        "spe": 135
      },
      "abilities": {"0": "Protosynthesis"},
      "weightkg": 4,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "slitherwing": {
      "num": 988,
      "name": "Slither Wing",
      "types": ["Bug", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 85,
        "atk": 135,
        "def": 79,
        "spa": 85,
        "spd": 105,
        "spe": 81
      },
      "abilities": {"0": "Protosynthesis"},
      "weightkg": 92,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "sandyshocks": {
      "num": 989,
      "name": "Sandy Shocks",
      "types": ["Electric", "Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 85,
        "atk": 81,
        "def": 97,
        "spa": 121,
        "spd": 85,
        "spe": 101
      },
      "abilities": {"0": "Protosynthesis"},
      "weightkg": 60,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "irontreads": {
      "num": 990,
      "name": "Iron Treads",
      "types": ["Ground", "Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 112,
        "def": 120,
        "spa": 72,
        "spd": 70,
        "spe": 106
      },
      "abilities": {"0": "Quark Drive"},
      "weightkg": 240,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "ironbundle": {
      "num": 991,
      "name": "Iron Bundle",
      "types": ["Ice", "Water"],
      "gender": "N",
      "baseStats": {
        "hp": 56,
        "atk": 80,
        "def": 114,
        "spa": 124,
        "spd": 60,
        "spe": 136
      },
      "abilities": {"0": "Quark Drive"},
      "weightkg": 11,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "ironhands": {
      "num": 992,
      "name": "Iron Hands",
      "types": ["Fighting", "Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 154,
        "atk": 140,
        "def": 108,
        "spa": 50,
        "spd": 68,
        "spe": 50
      },
      "abilities": {"0": "Quark Drive"},
      "weightkg": 380.7,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "ironjugulis": {
      "num": 993,
      "name": "Iron Jugulis",
      "types": ["Dark", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 94,
        "atk": 80,
        "def": 86,
        "spa": 122,
        "spd": 80,
        "spe": 108
      },
      "abilities": {"0": "Quark Drive"},
      "weightkg": 111,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "ironmoth": {
      "num": 994,
      "name": "Iron Moth",
      "types": ["Fire", "Poison"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 70,
        "def": 60,
        "spa": 140,
        "spd": 110,
        "spe": 110
      },
      "abilities": {"0": "Quark Drive"},
      "weightkg": 36,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "ironthorns": {
      "num": 995,
      "name": "Iron Thorns",
      "types": ["Rock", "Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 134,
        "def": 110,
        "spa": 70,
        "spd": 84,
        "spe": 72
      },
      "abilities": {"0": "Quark Drive"},
      "weightkg": 303,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "frigibax": {
      "num": 996,
      "name": "Frigibax",
      "types": ["Dragon", "Ice"],
      "baseStats": {
        "hp": 65,
        "atk": 75,
        "def": 45,
        "spa": 35,
        "spd": 45,
        "spe": 55
      },
      "abilities": {"0": "Thermal Exchange", "H": "Ice Body"},
      "weightkg": 17,
      "evos": ["Arctibax"],
      "eggGroups": ["Dragon", "Mineral"]
    },
    "arctibax": {
      "num": 997,
      "name": "Arctibax",
      "types": ["Dragon", "Ice"],
      "baseStats": {
        "hp": 90,
        "atk": 95,
        "def": 66,
        "spa": 45,
        "spd": 65,
        "spe": 62
      },
      "abilities": {"0": "Thermal Exchange", "H": "Ice Body"},
      "weightkg": 30,
      "prevo": "Frigibax",
      "evoLevel": 35,
      "evos": ["Baxcalibur"],
      "eggGroups": ["Dragon", "Mineral"]
    },
    "baxcalibur": {
      "num": 998,
      "name": "Baxcalibur",
      "types": ["Dragon", "Ice"],
      "baseStats": {
        "hp": 115,
        "atk": 145,
        "def": 92,
        "spa": 75,
        "spd": 86,
        "spe": 87
      },
      "abilities": {"0": "Thermal Exchange", "H": "Ice Body"},
      "weightkg": 210,
      "prevo": "Arctibax",
      "evoLevel": 54,
      "eggGroups": ["Dragon", "Mineral"]
    },
    "gimmighoul": {
      "num": 999,
      "name": "Gimmighoul",
      "baseForme": "Chest",
      "types": ["Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 45,
        "atk": 30,
        "def": 70,
        "spa": 75,
        "spd": 70,
        "spe": 10
      },
      "abilities": {"0": "Rattled"},
      "weightkg": 5,
      "evos": ["Gholdengo"],
      "otherFormes": ["Gimmighoul-Roaming"],
      "formeOrder": ["Gimmighoul", "Gimmighoul-Roaming"],
      "eggGroups": ["Undiscovered"]
    },
    "gimmighoulroaming": {
      "num": 999,
      "name": "Gimmighoul-Roaming",
      "baseSpecies": "Gimmighoul",
      "forme": "Roaming",
      "types": ["Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 45,
        "atk": 30,
        "def": 25,
        "spa": 75,
        "spd": 45,
        "spe": 80
      },
      "abilities": {"0": "Run Away"},
      "weightkg": 0.1,
      "evos": ["Gholdengo"],
      "eggGroups": ["Undiscovered"]
    },
    "gholdengo": {
      "num": 1000,
      "name": "Gholdengo",
      "types": ["Steel", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 87,
        "atk": 60,
        "def": 95,
        "spa": 133,
        "spd": 91,
        "spe": 84
      },
      "abilities": {"0": "Good as Gold"},
      "weightkg": 30,
      "prevo": "Gimmighoul",
      "evoType": "other",
      "evoCondition": "Level up with 999 Coins in the bag",
      "eggGroups": ["Undiscovered"]
    },
    "wochien": {
      "num": 1001,
      "name": "Wo-Chien",
      "types": ["Dark", "Grass"],
      "gender": "N",
      "baseStats": {
        "hp": 85,
        "atk": 85,
        "def": 100,
        "spa": 95,
        "spd": 135,
        "spe": 70
      },
      "abilities": {"0": "Tablets of Ruin"},
      "weightkg": 74.2,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "chienpao": {
      "num": 1002,
      "name": "Chien-Pao",
      "types": ["Dark", "Ice"],
      "gender": "N",
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 80,
        "spa": 90,
        "spd": 65,
        "spe": 135
      },
      "abilities": {"0": "Sword of Ruin"},
      "weightkg": 152.2,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "tinglu": {
      "num": 1003,
      "name": "Ting-Lu",
      "types": ["Dark", "Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 155,
        "atk": 110,
        "def": 125,
        "spa": 55,
        "spd": 80,
        "spe": 45
      },
      "abilities": {"0": "Vessel of Ruin"},
      "weightkg": 699.7,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "chiyu": {
      "num": 1004,
      "name": "Chi-Yu",
      "types": ["Dark", "Fire"],
      "gender": "N",
      "baseStats": {
        "hp": 55,
        "atk": 80,
        "def": 80,
        "spa": 135,
        "spd": 120,
        "spe": 100
      },
      "abilities": {"0": "Beads of Ruin"},
      "weightkg": 4.9,
      "tags": ["Sub-Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "roaringmoon": {
      "num": 1005,
      "name": "Roaring Moon",
      "types": ["Dragon", "Dark"],
      "gender": "N",
      "baseStats": {
        "hp": 105,
        "atk": 139,
        "def": 71,
        "spa": 55,
        "spd": 101,
        "spe": 119
      },
      "abilities": {"0": "Protosynthesis"},
      "weightkg": 380,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "ironvaliant": {
      "num": 1006,
      "name": "Iron Valiant",
      "types": ["Fairy", "Fighting"],
      "gender": "N",
      "baseStats": {
        "hp": 74,
        "atk": 130,
        "def": 90,
        "spa": 120,
        "spd": 60,
        "spe": 116
      },
      "abilities": {"0": "Quark Drive"},
      "weightkg": 35,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "koraidon": {
      "num": 1007,
      "name": "Koraidon",
      "types": ["Fighting", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 135,
        "def": 115,
        "spa": 85,
        "spd": 100,
        "spe": 135
      },
      "abilities": {"0": "Orichalcum Pulse"},
      "weightkg": 303,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "miraidon": {
      "num": 1008,
      "name": "Miraidon",
      "types": ["Electric", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 85,
        "def": 100,
        "spa": 135,
        "spd": 115,
        "spe": 135
      },
      "abilities": {"0": "Hadron Engine"},
      "weightkg": 240,
      "tags": ["Restricted Legendary"],
      "eggGroups": ["Undiscovered"]
    },
    "walkingwake": {
      "num": 1009,
      "name": "Walking Wake",
      "types": ["Water", "Dragon"],
      "gender": "N",
      "baseStats": {
        "hp": 99,
        "atk": 83,
        "def": 91,
        "spa": 125,
        "spd": 83,
        "spe": 109
      },
      "abilities": {"0": "Protosynthesis"},
      "weightkg": 280,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "ironleaves": {
      "num": 1010,
      "name": "Iron Leaves",
      "types": ["Grass", "Psychic"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 130,
        "def": 88,
        "spa": 70,
        "spd": 108,
        "spe": 104
      },
      "abilities": {"0": "Quark Drive"},
      "weightkg": 125,
      "tags": ["Paradox"],
      "eggGroups": ["Undiscovered"]
    },
    "dipplin": {
      "num": 1011,
      "name": "Dipplin",
      "types": ["Grass", "Dragon"],
      "baseStats": {
        "hp": 80,
        "atk": 80,
        "def": 110,
        "spa": 95,
        "spd": 80,
        "spe": 40
      },
      "abilities": {
        "0": "Supersweet Syrup",
        "1": "Gluttony",
        "H": "Sticky Hold"
      },
      "weightkg": 4.4,
      "prevo": "Applin",
      "evoType": "useItem",
      "evoItem": "Syrupy Apple",
      "eggGroups": ["Grass", "Dragon"]
    },
    "poltchageist": {
      "num": 1012,
      "name": "Poltchageist",
      "baseForme": "Counterfeit",
      "types": ["Grass", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 45,
        "spa": 74,
        "spd": 54,
        "spe": 50
      },
      "abilities": {"0": "Hospitality", "H": "Heatproof"},
      "weightkg": 1.1,
      "evos": ["Sinistcha"],
      "eggGroups": ["Mineral", "Amorphous"],
      "otherFormes": ["Poltchageist-Artisan"],
      "formeOrder": ["Poltchageist", "Poltchageist-Artisan"]
    },
    "poltchageistartisan": {
      "num": 1012,
      "name": "Poltchageist-Artisan",
      "baseSpecies": "Poltchageist",
      "forme": "Artisan",
      "types": ["Grass", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 40,
        "atk": 45,
        "def": 45,
        "spa": 74,
        "spd": 54,
        "spe": 50
      },
      "abilities": {"0": "Hospitality", "H": "Heatproof"},
      "weightkg": 1.1,
      "evos": ["Sinistcha-Masterpiece"],
      "eggGroups": ["Undiscovered"]
    },
    "sinistcha": {
      "num": 1013,
      "name": "Sinistcha",
      "baseForme": "Unremarkable",
      "types": ["Grass", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 71,
        "atk": 60,
        "def": 106,
        "spa": 121,
        "spd": 80,
        "spe": 70
      },
      "abilities": {"0": "Hospitality", "H": "Heatproof"},
      "weightkg": 2.2,
      "prevo": "Poltchageist",
      "evoType": "useItem",
      "evoItem": "Unremarkable Teacup",
      "eggGroups": ["Mineral", "Amorphous"],
      "otherFormes": ["Sinistcha-Masterpiece"],
      "formeOrder": ["Sinistcha", "Sinistcha-Masterpiece"]
    },
    "sinistchamasterpiece": {
      "num": 1013,
      "name": "Sinistcha-Masterpiece",
      "baseSpecies": "Sinistcha",
      "forme": "Masterpiece",
      "types": ["Grass", "Ghost"],
      "gender": "N",
      "baseStats": {
        "hp": 71,
        "atk": 60,
        "def": 106,
        "spa": 121,
        "spd": 80,
        "spe": 70
      },
      "abilities": {"0": "Hospitality", "H": "Heatproof"},
      "weightkg": 2.2,
      "prevo": "Poltchageist-Artisan",
      "evoType": "useItem",
      "evoItem": "Masterpiece Teacup",
      "eggGroups": ["Undiscovered"]
    },
    "okidogi": {
      "num": 1014,
      "name": "Okidogi",
      "types": ["Poison", "Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 88,
        "atk": 128,
        "def": 115,
        "spa": 58,
        "spd": 86,
        "spe": 80
      },
      "abilities": {"0": "Toxic Chain", "H": "Guard Dog"},
      "weightkg": 92,
      "eggGroups": ["Undiscovered"]
    },
    "munkidori": {
      "num": 1015,
      "name": "Munkidori",
      "types": ["Poison", "Psychic"],
      "gender": "M",
      "baseStats": {
        "hp": 88,
        "atk": 75,
        "def": 66,
        "spa": 130,
        "spd": 90,
        "spe": 106
      },
      "abilities": {"0": "Toxic Chain", "H": "Frisk"},
      "weightkg": 12.2,
      "eggGroups": ["Undiscovered"]
    },
    "fezandipiti": {
      "num": 1016,
      "name": "Fezandipiti",
      "types": ["Poison", "Fairy"],
      "gender": "M",
      "baseStats": {
        "hp": 88,
        "atk": 91,
        "def": 82,
        "spa": 70,
        "spd": 125,
        "spe": 99
      },
      "abilities": {"0": "Toxic Chain", "H": "Technician"},
      "weightkg": 30.1,
      "eggGroups": ["Undiscovered"]
    },
    "ogerpon": {
      "num": 1017,
      "name": "Ogerpon",
      "baseForme": "Teal",
      "types": ["Grass"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 84,
        "spa": 60,
        "spd": 96,
        "spe": 110
      },
      "abilities": {"0": "Defiant"},
      "weightkg": 39.8,
      "eggGroups": ["Undiscovered"],
      "otherFormes": [
        "Ogerpon-Wellspring",
        "Ogerpon-Hearthflame",
        "Ogerpon-Cornerstone",
        "Ogerpon-Teal-Tera",
        "Ogerpon-Wellspring-Tera",
        "Ogerpon-Hearthflame-Tera",
        "Ogerpon-Cornerstone-Tera"
      ],
      "formeOrder": [
        "Ogerpon",
        "Ogerpon-Wellspring",
        "Ogerpon-Hearthflame",
        "Ogerpon-Cornerstone",
        "Ogerpon-Teal-Tera",
        "Ogerpon-Wellspring-Tera",
        "Ogerpon-Hearthflame-Tera",
        "Ogerpon-Cornerstone-Tera"
      ],
      "forceTeraType": "Grass"
    },
    "ogerponwellspring": {
      "num": 1017,
      "name": "Ogerpon-Wellspring",
      "baseSpecies": "Ogerpon",
      "forme": "Wellspring",
      "types": ["Grass", "Water"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 84,
        "spa": 60,
        "spd": 96,
        "spe": 110
      },
      "abilities": {"0": "Water Absorb"},
      "weightkg": 39.8,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Wellspring Mask",
      "changesFrom": "Ogerpon",
      "forceTeraType": "Water"
    },
    "ogerponhearthflame": {
      "num": 1017,
      "name": "Ogerpon-Hearthflame",
      "baseSpecies": "Ogerpon",
      "forme": "Hearthflame",
      "types": ["Grass", "Fire"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 84,
        "spa": 60,
        "spd": 96,
        "spe": 110
      },
      "abilities": {"0": "Mold Breaker"},
      "weightkg": 39.8,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Hearthflame Mask",
      "changesFrom": "Ogerpon",
      "forceTeraType": "Fire"
    },
    "ogerponcornerstone": {
      "num": 1017,
      "name": "Ogerpon-Cornerstone",
      "baseSpecies": "Ogerpon",
      "forme": "Cornerstone",
      "types": ["Grass", "Rock"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 84,
        "spa": 60,
        "spd": 96,
        "spe": 110
      },
      "abilities": {"0": "Sturdy"},
      "weightkg": 39.8,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Cornerstone Mask",
      "changesFrom": "Ogerpon",
      "forceTeraType": "Rock"
    },
    "ogerpontealtera": {
      "num": 1017,
      "name": "Ogerpon-Teal-Tera",
      "baseSpecies": "Ogerpon",
      "forme": "Teal-Tera",
      "types": ["Grass"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 84,
        "spa": 60,
        "spd": 96,
        "spe": 110
      },
      "abilities": {"0": "Embody Aspect (Teal)"},
      "weightkg": 39.8,
      "eggGroups": ["Undiscovered"],
      "battleOnly": "Ogerpon",
      "forceTeraType": "Grass"
    },
    "ogerponwellspringtera": {
      "num": 1017,
      "name": "Ogerpon-Wellspring-Tera",
      "baseSpecies": "Ogerpon",
      "forme": "Wellspring-Tera",
      "types": ["Grass", "Water"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 84,
        "spa": 60,
        "spd": 96,
        "spe": 110
      },
      "abilities": {"0": "Embody Aspect (Wellspring)"},
      "weightkg": 39.8,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Wellspring Mask",
      "battleOnly": "Ogerpon-Wellspring",
      "forceTeraType": "Water"
    },
    "ogerponhearthflametera": {
      "num": 1017,
      "name": "Ogerpon-Hearthflame-Tera",
      "baseSpecies": "Ogerpon",
      "forme": "Hearthflame-Tera",
      "types": ["Grass", "Fire"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 84,
        "spa": 60,
        "spd": 96,
        "spe": 110
      },
      "abilities": {"0": "Embody Aspect (Hearthflame)"},
      "weightkg": 39.8,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Hearthflame Mask",
      "battleOnly": "Ogerpon-Hearthflame",
      "forceTeraType": "Fire"
    },
    "ogerponcornerstonetera": {
      "num": 1017,
      "name": "Ogerpon-Cornerstone-Tera",
      "baseSpecies": "Ogerpon",
      "forme": "Cornerstone-Tera",
      "types": ["Grass", "Rock"],
      "gender": "F",
      "baseStats": {
        "hp": 80,
        "atk": 120,
        "def": 84,
        "spa": 60,
        "spd": 96,
        "spe": 110
      },
      "abilities": {"0": "Embody Aspect (Cornerstone)"},
      "weightkg": 39.8,
      "eggGroups": ["Undiscovered"],
      "requiredItem": "Cornerstone Mask",
      "battleOnly": "Ogerpon-Cornerstone",
      "forceTeraType": "Rock"
    },
    "missingno": {
      "num": 0,
      "name": "MissingNo.",
      "types": ["Bird", "Normal"],
      "baseStats": {
        "hp": 33,
        "atk": 136,
        "def": 0,
        "spa": 6,
        "spd": 6,
        "spe": 29
      },
      "abilities": {"0": ""},
      "weightkg": 1590.8,
      "eggGroups": ["Undiscovered"]
    },
    "syclar": {
      "num": -1,
      "name": "Syclar",
      "types": ["Ice", "Bug"],
      "baseStats": {
        "hp": 40,
        "atk": 76,
        "def": 45,
        "spa": 74,
        "spd": 39,
        "spe": 91
      },
      "abilities": {"0": "Compound Eyes", "1": "Snow Cloak", "H": "Ice Body"},
      "weightkg": 4,
      "evos": ["Syclant"],
      "eggGroups": ["Bug"],
      "gen": 4
    },
    "syclant": {
      "num": -2,
      "name": "Syclant",
      "types": ["Ice", "Bug"],
      "baseStats": {
        "hp": 70,
        "atk": 116,
        "def": 70,
        "spa": 114,
        "spd": 64,
        "spe": 121
      },
      "abilities": {"0": "Compound Eyes", "1": "Mountaineer", "H": "Ice Body"},
      "weightkg": 52,
      "prevo": "Syclar",
      "evoLevel": 30,
      "eggGroups": ["Bug"],
      "gen": 4
    },
    "revenankh": {
      "num": -3,
      "name": "Revenankh",
      "types": ["Ghost", "Fighting"],
      "baseStats": {
        "hp": 90,
        "atk": 105,
        "def": 90,
        "spa": 65,
        "spd": 110,
        "spe": 65
      },
      "abilities": {"0": "Air Lock", "1": "Triage", "H": "Shed Skin"},
      "weightkg": 44,
      "eggGroups": ["Amorphous", "Human-Like"],
      "gen": 4
    },
    "embirch": {
      "num": -4,
      "name": "Embirch",
      "types": ["Fire", "Grass"],
      "baseStats": {
        "hp": 60,
        "atk": 40,
        "def": 55,
        "spa": 65,
        "spd": 40,
        "spe": 60
      },
      "abilities": {"0": "Reckless", "1": "Leaf Guard", "H": "Chlorophyll"},
      "weightkg": 15,
      "evos": ["Flarelm"],
      "eggGroups": ["Monster", "Dragon"],
      "gen": 4
    },
    "flarelm": {
      "num": -5,
      "name": "Flarelm",
      "types": ["Fire", "Grass"],
      "baseStats": {
        "hp": 90,
        "atk": 50,
        "def": 95,
        "spa": 75,
        "spd": 70,
        "spe": 40
      },
      "abilities": {"0": "Rock Head", "1": "Battle Armor", "H": "White Smoke"},
      "weightkg": 73,
      "prevo": "Embirch",
      "evoLevel": 24,
      "evos": ["Pyroak"],
      "eggGroups": ["Monster", "Dragon"],
      "gen": 4
    },
    "pyroak": {
      "num": -6,
      "name": "Pyroak",
      "types": ["Fire", "Grass"],
      "baseStats": {
        "hp": 120,
        "atk": 70,
        "def": 105,
        "spa": 70,
        "spd": 65,
        "spe": 60
      },
      "abilities": {"0": "Rock Head", "1": "Battle Armor", "H": "Contrary"},
      "weightkg": 168,
      "prevo": "Flarelm",
      "evoLevel": 38,
      "eggGroups": ["Monster", "Dragon"],
      "gen": 4
    },
    "breezi": {
      "num": -7,
      "name": "Breezi",
      "types": ["Poison", "Flying"],
      "baseStats": {
        "hp": 50,
        "atk": 46,
        "def": 69,
        "spa": 60,
        "spd": 50,
        "spe": 75
      },
      "abilities": {"0": "Unburden", "1": "Own Tempo", "H": "Frisk"},
      "weightkg": 0.6,
      "evos": ["Fidgit"],
      "eggGroups": ["Field"],
      "gen": 4
    },
    "fidgit": {
      "num": -8,
      "name": "Fidgit",
      "types": ["Poison", "Ground"],
      "baseStats": {
        "hp": 95,
        "atk": 76,
        "def": 109,
        "spa": 90,
        "spd": 80,
        "spe": 105
      },
      "abilities": {"0": "Persistent", "1": "Vital Spirit", "H": "Frisk"},
      "weightkg": 53,
      "prevo": "Breezi",
      "evoLevel": 33,
      "eggGroups": ["Field"],
      "gen": 4
    },
    "rebble": {
      "num": -9,
      "name": "Rebble",
      "types": ["Rock"],
      "gender": "N",
      "baseStats": {
        "hp": 45,
        "atk": 25,
        "def": 65,
        "spa": 75,
        "spd": 55,
        "spe": 80
      },
      "abilities": {"0": "Levitate", "1": "Solid Rock", "H": "Sniper"},
      "weightkg": 7,
      "evos": ["Tactite"],
      "eggGroups": ["Mineral"],
      "gen": 4
    },
    "tactite": {
      "num": -10,
      "name": "Tactite",
      "types": ["Rock"],
      "gender": "N",
      "baseStats": {
        "hp": 70,
        "atk": 40,
        "def": 65,
        "spa": 100,
        "spd": 65,
        "spe": 95
      },
      "abilities": {"0": "Levitate", "1": "Technician", "H": "Sniper"},
      "weightkg": 16,
      "prevo": "Rebble",
      "evoLevel": 28,
      "evos": ["Stratagem"],
      "eggGroups": ["Mineral"],
      "gen": 4
    },
    "stratagem": {
      "num": -11,
      "name": "Stratagem",
      "types": ["Rock"],
      "gender": "N",
      "baseStats": {
        "hp": 90,
        "atk": 60,
        "def": 65,
        "spa": 120,
        "spd": 70,
        "spe": 130
      },
      "abilities": {"0": "Levitate", "1": "Technician", "H": "Sniper"},
      "weightkg": 45,
      "prevo": "Tactite",
      "evoType": "trade",
      "eggGroups": ["Mineral"],
      "gen": 4
    },
    "privatyke": {
      "num": -12,
      "name": "Privatyke",
      "types": ["Water", "Fighting"],
      "baseStats": {
        "hp": 65,
        "atk": 75,
        "def": 65,
        "spa": 40,
        "spd": 60,
        "spe": 45
      },
      "abilities": {"0": "Unaware", "H": "Technician"},
      "weightkg": 35,
      "evos": ["Arghonaut"],
      "eggGroups": ["Water 1", "Water 3"],
      "gen": 4
    },
    "arghonaut": {
      "num": -13,
      "name": "Arghonaut",
      "types": ["Water", "Fighting"],
      "baseStats": {
        "hp": 105,
        "atk": 110,
        "def": 95,
        "spa": 70,
        "spd": 100,
        "spe": 75
      },
      "abilities": {"0": "Unaware", "H": "Technician"},
      "weightkg": 151,
      "prevo": "Privatyke",
      "evoLevel": 37,
      "eggGroups": ["Water 1", "Water 3"],
      "gen": 4
    },
    "kitsunoh": {
      "num": -14,
      "name": "Kitsunoh",
      "types": ["Ghost", "Steel"],
      "baseStats": {
        "hp": 80,
        "atk": 103,
        "def": 85,
        "spa": 55,
        "spd": 80,
        "spe": 110
      },
      "abilities": {"0": "Frisk", "1": "Limber", "H": "Iron Fist"},
      "weightkg": 51,
      "prevo": "Nohface",
      "evoType": "trade",
      "evoItem": "Metal Coat",
      "eggGroups": ["Field"],
      "gen": 4
    },
    "cyclohm": {
      "num": -15,
      "name": "Cyclohm",
      "types": ["Electric", "Dragon"],
      "baseStats": {
        "hp": 108,
        "atk": 60,
        "def": 118,
        "spa": 112,
        "spd": 70,
        "spe": 80
      },
      "abilities": {"0": "Shield Dust", "1": "Static", "H": "Damp"},
      "weightkg": 59,
      "prevo": "Duohm",
      "evoLevel": 43,
      "eggGroups": ["Dragon", "Monster"],
      "gen": 4
    },
    "colossoil": {
      "num": -16,
      "name": "Colossoil",
      "types": ["Ground", "Dark"],
      "baseStats": {
        "hp": 133,
        "atk": 122,
        "def": 72,
        "spa": 71,
        "spd": 72,
        "spe": 95
      },
      "abilities": {"0": "Rebound", "1": "Guts", "H": "Unnerve"},
      "weightkg": 683.6,
      "prevo": "Dorsoil",
      "evoLevel": 39,
      "eggGroups": ["Water 2", "Field"],
      "gen": 4
    },
    "krilowatt": {
      "num": -17,
      "name": "Krilowatt",
      "types": ["Electric", "Water"],
      "baseStats": {
        "hp": 151,
        "atk": 84,
        "def": 73,
        "spa": 83,
        "spd": 74,
        "spe": 105
      },
      "abilities": {"0": "Trace", "1": "Magic Guard", "H": "Minus"},
      "weightkg": 10.6,
      "prevo": "Protowatt",
      "evoLevel": 15,
      "eggGroups": ["Water 1", "Fairy"],
      "gen": 4
    },
    "voodoll": {
      "num": -18,
      "name": "Voodoll",
      "types": ["Normal", "Dark"],
      "baseStats": {
        "hp": 55,
        "atk": 40,
        "def": 55,
        "spa": 75,
        "spd": 50,
        "spe": 70
      },
      "abilities": {
        "0": "Volt Absorb",
        "1": "Lightning Rod",
        "H": "Cursed Body"
      },
      "weightkg": 25,
      "evos": ["Voodoom"],
      "eggGroups": ["Human-Like", "Field"],
      "gen": 4
    },
    "voodoom": {
      "num": -19,
      "name": "Voodoom",
      "types": ["Fighting", "Dark"],
      "baseStats": {
        "hp": 90,
        "atk": 85,
        "def": 80,
        "spa": 130,
        "spd": 80,
        "spe": 110
      },
      "abilities": {
        "0": "Volt Absorb",
        "1": "Lightning Rod",
        "H": "Cursed Body"
      },
      "weightkg": 75.5,
      "prevo": "Voodoll",
      "evoLevel": 32,
      "eggGroups": ["Human-Like", "Field"],
      "gen": 4
    },
    "scratchet": {
      "num": -20,
      "name": "Scratchet",
      "types": ["Normal", "Fighting"],
      "baseStats": {
        "hp": 55,
        "atk": 85,
        "def": 80,
        "spa": 20,
        "spd": 70,
        "spe": 40
      },
      "abilities": {"0": "Scrappy", "1": "Prankster", "H": "Vital Spirit"},
      "weightkg": 20,
      "evos": ["Tomohawk"],
      "eggGroups": ["Field", "Flying"],
      "gen": 5
    },
    "tomohawk": {
      "num": -21,
      "name": "Tomohawk",
      "types": ["Flying", "Fighting"],
      "baseStats": {
        "hp": 105,
        "atk": 60,
        "def": 90,
        "spa": 115,
        "spd": 80,
        "spe": 85
      },
      "abilities": {"0": "Intimidate", "1": "Prankster", "H": "Justified"},
      "weightkg": 37.2,
      "prevo": "Scratchet",
      "evoLevel": 23,
      "eggGroups": ["Field", "Flying"],
      "gen": 5
    },
    "necturine": {
      "num": -22,
      "name": "Necturine",
      "types": ["Grass", "Ghost"],
      "gender": "F",
      "baseStats": {
        "hp": 49,
        "atk": 55,
        "def": 60,
        "spa": 50,
        "spd": 75,
        "spe": 51
      },
      "abilities": {"0": "Anticipation", "H": "Telepathy"},
      "weightkg": 1.8,
      "evos": ["Necturna"],
      "eggGroups": ["Grass", "Field"],
      "gen": 5
    },
    "necturna": {
      "num": -23,
      "name": "Necturna",
      "types": ["Grass", "Ghost"],
      "gender": "F",
      "baseStats": {
        "hp": 64,
        "atk": 120,
        "def": 100,
        "spa": 85,
        "spd": 120,
        "spe": 58
      },
      "abilities": {"0": "Forewarn", "H": "Telepathy"},
      "weightkg": 49.6,
      "prevo": "Necturine",
      "evoLevel": 31,
      "eggGroups": ["Grass", "Field"],
      "gen": 5
    },
    "mollux": {
      "num": -24,
      "name": "Mollux",
      "types": ["Fire", "Poison"],
      "baseStats": {
        "hp": 95,
        "atk": 45,
        "def": 83,
        "spa": 131,
        "spd": 105,
        "spe": 76
      },
      "abilities": {"0": "Dry Skin", "H": "Illuminate"},
      "weightkg": 41,
      "eggGroups": ["Fairy", "Field"],
      "gen": 5
    },
    "cupra": {
      "num": -25,
      "name": "Cupra",
      "types": ["Bug", "Psychic"],
      "baseStats": {
        "hp": 50,
        "atk": 60,
        "def": 49,
        "spa": 67,
        "spd": 30,
        "spe": 44
      },
      "abilities": {"0": "Shield Dust", "1": "Keen Eye", "H": "Magic Guard"},
      "weightkg": 4.8,
      "evos": ["Argalis"],
      "eggGroups": ["Bug"],
      "gen": 5
    },
    "argalis": {
      "num": -26,
      "name": "Argalis",
      "types": ["Bug", "Psychic"],
      "baseStats": {
        "hp": 60,
        "atk": 90,
        "def": 89,
        "spa": 87,
        "spd": 40,
        "spe": 54
      },
      "abilities": {"0": "Shed Skin", "1": "Compound Eyes", "H": "Overcoat"},
      "weightkg": 341.4,
      "prevo": "Cupra",
      "evoLevel": 30,
      "evos": ["Aurumoth"],
      "eggGroups": ["Bug"],
      "gen": 5
    },
    "aurumoth": {
      "num": -27,
      "name": "Aurumoth",
      "types": ["Bug", "Psychic"],
      "baseStats": {
        "hp": 110,
        "atk": 120,
        "def": 99,
        "spa": 117,
        "spd": 60,
        "spe": 94
      },
      "abilities": {"0": "Weak Armor", "1": "No Guard", "H": "Light Metal"},
      "weightkg": 193,
      "prevo": "Argalis",
      "evoLevel": 50,
      "eggGroups": ["Bug"],
      "gen": 5
    },
    "brattler": {
      "num": -28,
      "name": "Brattler",
      "types": ["Dark", "Grass"],
      "baseStats": {
        "hp": 80,
        "atk": 70,
        "def": 40,
        "spa": 20,
        "spd": 90,
        "spe": 30
      },
      "abilities": {"0": "Harvest", "1": "Infiltrator", "H": "Rattled"},
      "weightkg": 11.5,
      "evos": ["Malaconda"],
      "eggGroups": ["Grass", "Dragon"],
      "gen": 5
    },
    "malaconda": {
      "num": -29,
      "name": "Malaconda",
      "types": ["Dark", "Grass"],
      "baseStats": {
        "hp": 115,
        "atk": 100,
        "def": 60,
        "spa": 40,
        "spd": 130,
        "spe": 55
      },
      "abilities": {"0": "Harvest", "1": "Infiltrator", "H": "Drought"},
      "weightkg": 108.8,
      "prevo": "Brattler",
      "evoLevel": 33,
      "eggGroups": ["Grass", "Dragon"],
      "gen": 5
    },
    "cawdet": {
      "num": -30,
      "name": "Cawdet",
      "types": ["Steel", "Flying"],
      "baseStats": {
        "hp": 35,
        "atk": 72,
        "def": 85,
        "spa": 40,
        "spd": 55,
        "spe": 88
      },
      "abilities": {"0": "Keen Eye", "1": "Volt Absorb", "H": "Big Pecks"},
      "weightkg": 25,
      "evos": ["Cawmodore"],
      "eggGroups": ["Flying"],
      "gen": 5
    },
    "cawmodore": {
      "num": -31,
      "name": "Cawmodore",
      "types": ["Steel", "Flying"],
      "baseStats": {
        "hp": 50,
        "atk": 92,
        "def": 130,
        "spa": 65,
        "spd": 75,
        "spe": 118
      },
      "abilities": {"0": "Intimidate", "1": "Volt Absorb", "H": "Big Pecks"},
      "weightkg": 37,
      "prevo": "Cawdet",
      "evoLevel": 33,
      "eggGroups": ["Flying"],
      "gen": 5
    },
    "volkritter": {
      "num": -32,
      "name": "Volkritter",
      "types": ["Water", "Fire"],
      "baseStats": {
        "hp": 60,
        "atk": 30,
        "def": 50,
        "spa": 80,
        "spd": 60,
        "spe": 70
      },
      "abilities": {"0": "Anticipation", "1": "Infiltrator", "H": "Unnerve"},
      "weightkg": 15,
      "evos": ["Volkraken"],
      "eggGroups": ["Water 1", "Water 2"],
      "gen": 6
    },
    "volkraken": {
      "num": -33,
      "name": "Volkraken",
      "types": ["Water", "Fire"],
      "baseStats": {
        "hp": 100,
        "atk": 45,
        "def": 80,
        "spa": 135,
        "spd": 100,
        "spe": 95
      },
      "abilities": {"0": "Analytic", "1": "Infiltrator", "H": "Pressure"},
      "weightkg": 44.5,
      "prevo": "Volkritter",
      "evoLevel": 34,
      "eggGroups": ["Water 1", "Water 2"],
      "gen": 6
    },
    "snugglow": {
      "num": -34,
      "name": "Snugglow",
      "types": ["Electric", "Poison"],
      "baseStats": {
        "hp": 40,
        "atk": 37,
        "def": 79,
        "spa": 91,
        "spd": 68,
        "spe": 70
      },
      "abilities": {"0": "Storm Drain", "1": "Vital Spirit", "H": "Telepathy"},
      "weightkg": 6,
      "evos": ["Plasmanta"],
      "eggGroups": ["Water 1", "Water 2"],
      "gen": 6
    },
    "plasmanta": {
      "num": -35,
      "name": "Plasmanta",
      "types": ["Electric", "Poison"],
      "baseStats": {
        "hp": 60,
        "atk": 57,
        "def": 119,
        "spa": 131,
        "spd": 98,
        "spe": 100
      },
      "abilities": {"0": "Storm Drain", "1": "Vital Spirit", "H": "Telepathy"},
      "weightkg": 460,
      "prevo": "Snugglow",
      "evoLevel": 29,
      "eggGroups": ["Water 1", "Water 2"],
      "gen": 6
    },
    "floatoy": {
      "num": -36,
      "name": "Floatoy",
      "types": ["Water"],
      "baseStats": {
        "hp": 48,
        "atk": 70,
        "def": 40,
        "spa": 70,
        "spd": 30,
        "spe": 77
      },
      "abilities": {"0": "Water Veil", "1": "Heatproof", "H": "Swift Swim"},
      "weightkg": 1.9,
      "evos": ["Caimanoe"],
      "eggGroups": ["Water 1", "Field"],
      "gen": 6
    },
    "caimanoe": {
      "num": -37,
      "name": "Caimanoe",
      "types": ["Water", "Steel"],
      "baseStats": {
        "hp": 73,
        "atk": 85,
        "def": 65,
        "spa": 80,
        "spd": 40,
        "spe": 87
      },
      "abilities": {"0": "Water Veil", "1": "Heatproof", "H": "Light Metal"},
      "weightkg": 72.5,
      "prevo": "Floatoy",
      "evoLevel": 21,
      "evos": ["Naviathan"],
      "eggGroups": ["Water 1", "Field"],
      "gen": 6
    },
    "naviathan": {
      "num": -38,
      "name": "Naviathan",
      "types": ["Water", "Steel"],
      "baseStats": {
        "hp": 103,
        "atk": 110,
        "def": 90,
        "spa": 95,
        "spd": 65,
        "spe": 97
      },
      "abilities": {"0": "Guts", "1": "Heatproof", "H": "Light Metal"},
      "weightkg": 510,
      "prevo": "Caimanoe",
      "evoLevel": 40,
      "eggGroups": ["Water 1", "Field"],
      "gen": 6
    },
    "crucibelle": {
      "num": -39,
      "name": "Crucibelle",
      "types": ["Rock", "Poison"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 106,
        "atk": 105,
        "def": 65,
        "spa": 75,
        "spd": 85,
        "spe": 104
      },
      "abilities": {
        "0": "Regenerator",
        "1": "Mold Breaker",
        "H": "Liquid Ooze"
      },
      "weightkg": 23.6,
      "eggGroups": ["Amorphous", "Mineral"],
      "otherFormes": ["Crucibelle-Mega"],
      "formeOrder": ["Crucibelle", "Crucibelle-Mega"],
      "gen": 6
    },
    "crucibellemega": {
      "num": -39,
      "name": "Crucibelle-Mega",
      "baseSpecies": "Crucibelle",
      "forme": "Mega",
      "types": ["Rock", "Poison"],
      "genderRatio": {"M": 0.25, "F": 0.75},
      "baseStats": {
        "hp": 106,
        "atk": 135,
        "def": 75,
        "spa": 91,
        "spd": 125,
        "spe": 108
      },
      "abilities": {"0": "Magic Guard"},
      "weightkg": 22.5,
      "eggGroups": ["Amorphous", "Mineral"],
      "requiredItem": "Crucibellite",
      "gen": 6
    },
    "pluffle": {
      "num": -40,
      "name": "Pluffle",
      "types": ["Fairy"],
      "baseStats": {
        "hp": 74,
        "atk": 38,
        "def": 51,
        "spa": 65,
        "spd": 78,
        "spe": 49
      },
      "abilities": {
        "0": "Natural Cure",
        "1": "Aroma Veil",
        "H": "Friend Guard"
      },
      "weightkg": 1.8,
      "evos": ["Kerfluffle"],
      "eggGroups": ["Fairy", "Human-Like"],
      "gen": 6
    },
    "kerfluffle": {
      "num": -41,
      "name": "Kerfluffle",
      "types": ["Fairy", "Fighting"],
      "baseStats": {
        "hp": 84,
        "atk": 78,
        "def": 86,
        "spa": 115,
        "spd": 88,
        "spe": 119
      },
      "abilities": {
        "0": "Natural Cure",
        "1": "Aroma Veil",
        "H": "Friend Guard"
      },
      "weightkg": 24.2,
      "prevo": "Pluffle",
      "evoType": "levelMove",
      "evoMove": "Play Rough",
      "eggGroups": ["Fairy", "Human-Like"],
      "gen": 6
    },
    "pajantom": {
      "num": -42,
      "name": "Pajantom",
      "types": ["Dragon", "Ghost"],
      "baseStats": {
        "hp": 84,
        "atk": 133,
        "def": 71,
        "spa": 51,
        "spd": 111,
        "spe": 101
      },
      "abilities": {"0": "Comatose"},
      "weightkg": 3.1,
      "eggGroups": ["Dragon", "Monster"],
      "gen": 7
    },
    "mumbao": {
      "num": -43,
      "name": "Mumbao",
      "types": ["Grass", "Fairy"],
      "baseStats": {
        "hp": 55,
        "atk": 30,
        "def": 64,
        "spa": 87,
        "spd": 73,
        "spe": 66
      },
      "abilities": {"0": "Trace", "1": "Overcoat", "H": "Solar Power"},
      "weightkg": 83,
      "evos": ["Jumbao"],
      "eggGroups": ["Grass"],
      "gen": 7
    },
    "jumbao": {
      "num": -44,
      "name": "Jumbao",
      "types": ["Grass", "Fairy"],
      "baseStats": {
        "hp": 92,
        "atk": 63,
        "def": 97,
        "spa": 124,
        "spd": 104,
        "spe": 96
      },
      "abilities": {"0": "Trace", "1": "Overcoat", "H": "Drought"},
      "weightkg": 200,
      "prevo": "Mumbao",
      "evoType": "levelFriendship",
      "evoCondition": "during the day",
      "eggGroups": ["Grass"],
      "gen": 7
    },
    "fawnifer": {
      "num": -45,
      "name": "Fawnifer",
      "types": ["Grass"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 49,
        "atk": 61,
        "def": 42,
        "spa": 52,
        "spd": 40,
        "spe": 76
      },
      "abilities": {"0": "Overgrow", "H": "Lightning Rod"},
      "weightkg": 6.9,
      "evos": ["Electrelk"],
      "eggGroups": ["Field"],
      "gen": 7
    },
    "electrelk": {
      "num": -46,
      "name": "Electrelk",
      "types": ["Grass", "Electric"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 59,
        "atk": 81,
        "def": 67,
        "spa": 57,
        "spd": 55,
        "spe": 101
      },
      "abilities": {"0": "Overgrow", "H": "Galvanize"},
      "weightkg": 41.5,
      "prevo": "Fawnifer",
      "evoLevel": 17,
      "evos": ["Caribolt"],
      "eggGroups": ["Field"],
      "gen": 7
    },
    "caribolt": {
      "num": -47,
      "name": "Caribolt",
      "types": ["Grass", "Electric"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 84,
        "atk": 106,
        "def": 82,
        "spa": 77,
        "spd": 80,
        "spe": 106
      },
      "abilities": {"0": "Overgrow", "H": "Galvanize"},
      "weightkg": 140,
      "prevo": "Electrelk",
      "evoLevel": 34,
      "eggGroups": ["Field"],
      "gen": 7
    },
    "smogecko": {
      "num": -48,
      "name": "Smogecko",
      "types": ["Fire"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 48,
        "atk": 66,
        "def": 43,
        "spa": 58,
        "spd": 48,
        "spe": 56
      },
      "abilities": {"0": "Blaze", "H": "Technician"},
      "weightkg": 8.5,
      "evos": ["Smoguana"],
      "eggGroups": ["Field", "Monster"],
      "gen": 7
    },
    "smoguana": {
      "num": -49,
      "name": "Smoguana",
      "types": ["Fire", "Ground"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 68,
        "atk": 86,
        "def": 53,
        "spa": 68,
        "spd": 68,
        "spe": 76
      },
      "abilities": {"0": "Blaze", "H": "Technician"},
      "weightkg": 22.2,
      "prevo": "Smogecko",
      "evoLevel": 15,
      "evos": ["Smokomodo"],
      "eggGroups": ["Field", "Monster"],
      "gen": 7
    },
    "smokomodo": {
      "num": -50,
      "name": "Smokomodo",
      "types": ["Fire", "Ground"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 88,
        "atk": 116,
        "def": 67,
        "spa": 88,
        "spd": 78,
        "spe": 97
      },
      "abilities": {"0": "Blaze", "H": "Technician"},
      "weightkg": 205,
      "prevo": "Smoguana",
      "evoLevel": 36,
      "eggGroups": ["Field", "Monster"],
      "gen": 7
    },
    "swirlpool": {
      "num": -51,
      "name": "Swirlpool",
      "types": ["Water"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 61,
        "atk": 49,
        "def": 70,
        "spa": 50,
        "spd": 62,
        "spe": 28
      },
      "abilities": {"0": "Torrent", "H": "Poison Heal"},
      "weightkg": 7,
      "evos": ["Coribalis"],
      "eggGroups": ["Water 1", "Fairy"],
      "gen": 7
    },
    "coribalis": {
      "num": -52,
      "name": "Coribalis",
      "types": ["Water", "Bug"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 76,
        "atk": 69,
        "def": 90,
        "spa": 65,
        "spd": 77,
        "spe": 43
      },
      "abilities": {"0": "Torrent", "H": "Poison Heal"},
      "weightkg": 24.5,
      "prevo": "Swirlpool",
      "evoLevel": 17,
      "evos": ["Snaelstrom"],
      "eggGroups": ["Water 1", "Fairy"],
      "gen": 7
    },
    "snaelstrom": {
      "num": -53,
      "name": "Snaelstrom",
      "types": ["Water", "Bug"],
      "genderRatio": {"M": 0.875, "F": 0.125},
      "baseStats": {
        "hp": 91,
        "atk": 94,
        "def": 110,
        "spa": 80,
        "spd": 97,
        "spe": 63
      },
      "abilities": {"0": "Torrent", "H": "Poison Heal"},
      "weightkg": 120,
      "prevo": "Coribalis",
      "evoLevel": 34,
      "eggGroups": ["Water 1", "Fairy"],
      "gen": 7
    },
    "justyke": {
      "num": -54,
      "name": "Justyke",
      "types": ["Steel", "Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 72,
        "atk": 70,
        "def": 56,
        "spa": 83,
        "spd": 68,
        "spe": 30
      },
      "abilities": {"0": "Levitate", "1": "Bulletproof", "H": "Justified"},
      "weightkg": 36.5,
      "evos": ["Equilibra"],
      "eggGroups": ["Mineral"],
      "gen": 7
    },
    "equilibra": {
      "num": -55,
      "name": "Equilibra",
      "types": ["Steel", "Ground"],
      "gender": "N",
      "baseStats": {
        "hp": 102,
        "atk": 50,
        "def": 96,
        "spa": 133,
        "spd": 118,
        "spe": 60
      },
      "abilities": {"0": "Levitate", "1": "Bulletproof", "H": "Justified"},
      "weightkg": 51.3,
      "prevo": "Justyke",
      "evoLevel": 32,
      "eggGroups": ["Mineral"],
      "gen": 7
    },
    "solotl": {
      "num": -56,
      "name": "Solotl",
      "types": ["Fire", "Dragon"],
      "baseStats": {
        "hp": 68,
        "atk": 48,
        "def": 34,
        "spa": 72,
        "spd": 24,
        "spe": 84
      },
      "abilities": {"0": "Regenerator", "1": "Vital Spirit", "H": "Magician"},
      "weightkg": 11.8,
      "evos": ["Astrolotl"],
      "eggGroups": ["Dragon", "Fairy"],
      "gen": 8
    },
    "astrolotl": {
      "num": -57,
      "name": "Astrolotl",
      "types": ["Fire", "Dragon"],
      "baseStats": {
        "hp": 108,
        "atk": 108,
        "def": 74,
        "spa": 92,
        "spd": 64,
        "spe": 114
      },
      "abilities": {"0": "Regenerator", "1": "Vital Spirit", "H": "Magician"},
      "weightkg": 50,
      "prevo": "Solotl",
      "evoLevel": 35,
      "eggGroups": ["Dragon", "Fairy"],
      "gen": 8
    },
    "miasmite": {
      "num": -58,
      "name": "Miasmite",
      "types": ["Bug", "Dragon"],
      "baseStats": {
        "hp": 40,
        "atk": 85,
        "def": 60,
        "spa": 52,
        "spd": 52,
        "spe": 44
      },
      "abilities": {
        "0": "Neutralizing Gas",
        "1": "Hyper Cutter",
        "H": "Compound Eyes"
      },
      "weightkg": 10.1,
      "evos": ["Miasmaw"],
      "eggGroups": ["Bug", "Dragon"],
      "gen": 8
    },
    "miasmaw": {
      "num": -59,
      "name": "Miasmaw",
      "types": ["Bug", "Dragon"],
      "baseStats": {
        "hp": 85,
        "atk": 135,
        "def": 60,
        "spa": 88,
        "spd": 105,
        "spe": 99
      },
      "abilities": {
        "0": "Neutralizing Gas",
        "1": "Hyper Cutter",
        "H": "Compound Eyes"
      },
      "weightkg": 57,
      "prevo": "Miasmite",
      "evoLevel": 30,
      "eggGroups": ["Bug", "Dragon"],
      "gen": 8
    },
    "chromera": {
      "num": -60,
      "name": "Chromera",
      "types": ["Dark", "Normal"],
      "gender": "N",
      "baseStats": {
        "hp": 85,
        "atk": 85,
        "def": 115,
        "spa": 115,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Color Change"},
      "weightkg": 215,
      "eggGroups": ["Undiscovered"],
      "gen": 8
    },
    "nohface": {
      "num": -61,
      "name": "Nohface",
      "types": ["Ghost"],
      "baseStats": {
        "hp": 50,
        "atk": 73,
        "def": 50,
        "spa": 30,
        "spd": 50,
        "spe": 80
      },
      "abilities": {"0": "Frisk", "1": "Limber", "H": "Unnerve"},
      "weightkg": 5.9,
      "evos": ["Kitsunoh"],
      "eggGroups": ["Field"],
      "gen": 4
    },
    "monohm": {
      "num": -62,
      "name": "Monohm",
      "types": ["Electric"],
      "baseStats": {
        "hp": 53,
        "atk": 40,
        "def": 58,
        "spa": 67,
        "spd": 55,
        "spe": 55
      },
      "abilities": {"0": "Shield Dust", "1": "Static", "H": "Damp"},
      "weightkg": 4.1,
      "evos": ["Duohm"],
      "eggGroups": ["Dragon", "Monster"],
      "gen": 4
    },
    "duohm": {
      "num": -63,
      "name": "Duohm",
      "types": ["Electric", "Dragon"],
      "baseStats": {
        "hp": 88,
        "atk": 40,
        "def": 103,
        "spa": 77,
        "spd": 60,
        "spe": 60
      },
      "abilities": {"0": "Shield Dust", "1": "Static", "H": "Damp"},
      "weightkg": 19.2,
      "prevo": "Monohm",
      "evoLevel": 32,
      "evos": ["Cyclohm"],
      "eggGroups": ["Dragon", "Monster"],
      "gen": 4
    },
    "dorsoil": {
      "num": -64,
      "name": "Dorsoil",
      "types": ["Ground"],
      "baseStats": {
        "hp": 103,
        "atk": 72,
        "def": 52,
        "spa": 61,
        "spd": 52,
        "spe": 65
      },
      "abilities": {"0": "Oblivious", "1": "Guts", "H": "Unnerve"},
      "weightkg": 145,
      "evos": ["Colossoil"],
      "eggGroups": ["Water 2", "Field"],
      "gen": 4
    },
    "protowatt": {
      "num": -65,
      "name": "Protowatt",
      "types": ["Electric", "Water"],
      "baseStats": {
        "hp": 51,
        "atk": 44,
        "def": 33,
        "spa": 43,
        "spd": 34,
        "spe": 65
      },
      "abilities": {"0": "Trace", "1": "Magic Guard", "H": "Minus"},
      "weightkg": 0.1,
      "evos": ["Krilowatt"],
      "eggGroups": ["Water 1", "Fairy"],
      "gen": 4
    },
    "venomicon": {
      "num": -66,
      "name": "Venomicon",
      "baseForme": "Prologue",
      "types": ["Poison", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 85,
        "atk": 50,
        "def": 113,
        "spa": 118,
        "spd": 90,
        "spe": 64
      },
      "abilities": {"0": "Stamina", "H": "Power of Alchemy"},
      "weightkg": 11.5,
      "eggGroups": ["Amorphous"],
      "otherFormes": ["Venomicon-Epilogue"],
      "formeOrder": ["Venomicon", "Venomicon-Epilogue"],
      "gen": 8
    },
    "venomiconepilogue": {
      "num": -66,
      "name": "Venomicon-Epilogue",
      "baseSpecies": "Venomicon",
      "forme": "Epilogue",
      "types": ["Poison", "Flying"],
      "gender": "N",
      "baseStats": {
        "hp": 85,
        "atk": 102,
        "def": 85,
        "spa": 62,
        "spd": 85,
        "spe": 101
      },
      "abilities": {"0": "Tinted Lens"},
      "weightkg": 12.4,
      "eggGroups": ["Amorphous"],
      "requiredItem": "Vile Vial",
      "changesFrom": "Venomicon",
      "gen": 8
    },
    "saharascal": {
      "num": -67,
      "name": "Saharascal",
      "types": ["Ground"],
      "baseStats": {
        "hp": 50,
        "atk": 80,
        "def": 65,
        "spa": 45,
        "spd": 90,
        "spe": 70
      },
      "abilities": {"0": "Water Absorb", "1": "Pickpocket", "H": "Sand Spit"},
      "weightkg": 48,
      "evos": ["Saharaja"],
      "eggGroups": ["Field"],
      "gen": 8
    },
    "saharaja": {
      "num": -68,
      "name": "Saharaja",
      "types": ["Ground"],
      "baseStats": {
        "hp": 70,
        "atk": 112,
        "def": 105,
        "spa": 65,
        "spd": 123,
        "spe": 78
      },
      "abilities": {"0": "Water Absorb", "1": "Serene Grace", "H": "Sand Spit"},
      "weightkg": 303.9,
      "prevo": "Saharascal",
      "evoType": "useItem",
      "evoItem": "Shiny Stone",
      "eggGroups": ["Field"],
      "gen": 8
    },
    "ababo": {
      "num": -69,
      "name": "Ababo",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 42,
        "atk": 35,
        "def": 27,
        "spa": 35,
        "spd": 35,
        "spe": 38
      },
      "abilities": {"0": "Pixilate", "1": "Rattled", "H": "Own Tempo"},
      "weightkg": 3.5,
      "evos": ["Scattervein"],
      "eggGroups": ["Undiscovered"],
      "canHatch": true,
      "gen": 9
    },
    "scattervein": {
      "num": -70,
      "name": "Scattervein",
      "types": ["Fairy"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 75,
        "atk": 74,
        "def": 87,
        "spa": 62,
        "spd": 89,
        "spe": 63
      },
      "abilities": {"0": "Pixilate", "1": "Intimidate", "H": "Own Tempo"},
      "weightkg": 25,
      "prevo": "Ababo",
      "evoType": "levelFriendship",
      "evos": ["Hemogoblin"],
      "eggGroups": ["Monster", "Fairy"],
      "canHatch": true,
      "gen": 9
    },
    "hemogoblin": {
      "num": -71,
      "name": "Hemogoblin",
      "types": ["Fairy", "Fire"],
      "genderRatio": {"M": 0.75, "F": 0.25},
      "baseStats": {
        "hp": 90,
        "atk": 96,
        "def": 87,
        "spa": 96,
        "spd": 89,
        "spe": 55
      },
      "abilities": {"0": "Pixilate", "1": "Intimidate", "H": "Own Tempo"},
      "weightkg": 85,
      "prevo": "Scattervein",
      "evoType": "useItem",
      "evoItem": "Fire Stone",
      "eggGroups": ["Monster", "Fairy"],
      "gen": 9
    },
    "cresceidon": {
      "num": -72,
      "name": "Cresceidon",
      "types": ["Water", "Fairy"],
      "baseStats": {
        "hp": 80,
        "atk": 32,
        "def": 111,
        "spa": 88,
        "spd": 99,
        "spe": 125
      },
      "abilities": {"0": "Multiscale", "1": "Rough Skin"},
      "weightkg": 999.9,
      "eggGroups": ["Undiscovered"],
      "gen": 9
    },
    "pokestarsmeargle": {
      "num": -5000,
      "name": "Pokestar Smeargle",
      "types": ["Normal"],
      "baseStats": {
        "hp": 55,
        "atk": 20,
        "def": 35,
        "spa": 20,
        "spd": 45,
        "spe": 75
      },
      "abilities": {"0": "Own Tempo", "1": "Technician", "H": "Moody"},
      "weightkg": 61,
      "eggGroups": ["Field"],
      "gen": 5
    },
    "pokestarufo": {
      "num": -5001,
      "name": "Pokestar UFO",
      "types": ["Flying", "Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "otherFormes": ["Pokestar UFO-2", "Pokestar UFO-PropU2"],
      "cosmeticFormes": ["Pokestar UFO-PropU1"],
      "gen": 5
    },
    "pokestarufo2": {
      "num": -5001,
      "name": "Pokestar UFO-2",
      "baseSpecies": "Pokestar UFO",
      "forme": "2",
      "types": ["Psychic", "Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "gen": 5
    },
    "pokestarbrycenman": {
      "num": -5002,
      "name": "Pokestar Brycen-Man",
      "types": ["Dark", "Psychic"],
      "gender": "M",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar Brycen-Man-Prop"],
      "gen": 5
    },
    "pokestarmt": {
      "num": -5003,
      "name": "Pokestar MT",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Analytic"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar MT-Prop"],
      "gen": 5
    },
    "pokestarmt2": {
      "num": -5004,
      "name": "Pokestar MT2",
      "types": ["Steel", "Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Flash Fire"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar MT2-Prop"],
      "gen": 5
    },
    "pokestartransport": {
      "num": -5005,
      "name": "Pokestar Transport",
      "types": ["Steel"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Motor Drive"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar Transport-Prop"],
      "gen": 5
    },
    "pokestargiant": {
      "num": -5006,
      "name": "Pokestar Giant",
      "types": ["Normal"],
      "gender": "F",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Huge Power"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": [
        "Pokestar Giant-2",
        "Pokestar Giant-PropO1",
        "Pokestar Giant-PropO2"
      ],
      "gen": 5
    },
    "pokestarhumanoid": {
      "num": -5007,
      "name": "Pokestar Humanoid",
      "types": ["Normal"],
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Insomnia"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar Humanoid-Prop"],
      "gen": 5
    },
    "pokestarmonster": {
      "num": -5008,
      "name": "Pokestar Monster",
      "types": ["Dark"],
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Pressure"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar Monster-Prop"],
      "gen": 5
    },
    "pokestarf00": {
      "num": -5009,
      "name": "Pokestar F-00",
      "types": ["Steel", "Normal"],
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Volt Absorb"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar F-00-Prop"],
      "gen": 5
    },
    "pokestarf002": {
      "num": -5010,
      "name": "Pokestar F-002",
      "types": ["Steel", "Normal"],
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Reckless"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar F-002-Prop"],
      "gen": 5
    },
    "pokestarspirit": {
      "num": -5011,
      "name": "Pokestar Spirit",
      "types": ["Dark", "Ghost"],
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Wonder Guard"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar Spirit-Prop"],
      "gen": 5
    },
    "pokestarblackdoor": {
      "num": -5012,
      "name": "Pokestar Black Door",
      "types": ["Grass"],
      "gender": "F",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Early Bird"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar Black Door-Prop"],
      "gen": 5
    },
    "pokestarwhitedoor": {
      "num": -5013,
      "name": "Pokestar White Door",
      "types": ["Fire"],
      "gender": "M",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Blaze"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar White Door-Prop"],
      "gen": 5
    },
    "pokestarblackbelt": {
      "num": -5014,
      "name": "Pokestar Black Belt",
      "types": ["Fighting"],
      "gender": "M",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Huge Power"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "cosmeticFormes": ["Pokestar Black Belt-Prop"],
      "gen": 5
    },
    "pokestarufopropu2": {
      "num": -5001,
      "name": "Pokestar UFO-PropU2",
      "baseSpecies": "Pokestar UFO",
      "forme": "PropU2",
      "types": ["Psychic", "Electric"],
      "gender": "N",
      "baseStats": {
        "hp": 100,
        "atk": 100,
        "def": 100,
        "spa": 100,
        "spd": 100,
        "spe": 100
      },
      "abilities": {"0": "Levitate"},
      "weightkg": 61,
      "eggGroups": ["Undiscovered"],
      "gen": 5
    }
  }
}