{
  "randbats": "[Gen 9] Random Battle",
  "uber": "[Gen 9] Ubers",
  "anythinggoes": "[Gen 9] Anything Goes",
  "ubers": "[Gen 9] Ubers",
  "uubers": "[Gen 9] Ubers UU",
  "overused": "[Gen 9] OU",
  "underused": "[Gen 9] UU",
  "rarelyused": "[Gen 9] RU",
  "neverused": "[Gen 9] NU",
  "pu": "[Gen 9] PU",
  "zu": "[Gen 9] ZU",
  "zeroused": "[Gen 9] ZU",
  "mono": "[Gen 9] Monotype",
  "ag": "[Gen 9] Anything Goes",
  "bss": "[Gen 9] Battle Stadium Singles Series 2",
  "vgc": "[Gen 9] VGC 2023 Series 2",
  "bsd": "[Gen 9] VGC 2023 Series 2",
  "randdubs": "[Gen 9] Random Doubles Battle",
  "doubles": "[Gen 9] Doubles OU",
  "dou": "[Gen 9] Doubles OU",
  "dubs": "[Gen 9] Doubles OU",
  "dubers": "[Gen 9] Doubles Ubers",
  "duu": "[Gen 9] Doubles UU",
  "2v2": "[Gen 9] 2v2 Doubles",
  "natdex": "[Gen 9] National Dex",
  "nd": "[Gen 9] National Dex",
  "ndou": "[Gen 9] National Dex",
  "gen8natdex": "[Gen 8] National Dex",
  "gen8nd": "[Gen 8] National Dex",
  "gen8ndou": "[Gen 8] National Dex",
  "natdexubers": "[Gen 9] National Dex Ubers",
  "ndubers": "[Gen 9] National Dex Ubers",
  "natdexuu": "[Gen 9] National Dex UU",
  "nduu": "[Gen 9] National Dex UU",
  "natdexru": "[Gen 9] National Dex RU",
  "ndru": "[Gen 9] National Dex RU",
  "natdexmonotype": "[Gen 8] National Dex Monotype",
  "natdexmono": "[Gen 8] National Dex Monotype",
  "ndmono": "[Gen 8] National Dex Monotype",
  "natdexag": "[Gen 9] National Dex AG",
  "ndag": "[Gen 9] National Dex AG",
  "natdexbh": "[Gen 9] National Dex BH",
  "ndbh": "[Gen 9] National Dex BH",
  "bh": "[Gen 9] Balanced Hackmons",
  "mnm": "[Gen 9] Mix and Mega",
  "aaa": "[Gen 9] Almost Any Ability",
  "stab": "[Gen 9] STABmons",
  "gg": "[Gen 9] Godly Gift",
  "pic": "[Gen 9] Partners in Crime",
  "camo": "[Gen 9] Camomons",
  "revmons": "[Gen 9] Revelationmons",
  "tcg": "[Gen 9] The Card Game",
  "fotf": "[Gen 9] Force of the Fallen",
  "ffa": "[Gen 9] Free-For-All",
  "ph": "[Gen 9] Pure Hackmons",
  "hackmons": "[Gen 9] Pure Hackmons",
  "gen6ph": "[Gen 6] Pure Hackmons",
  "gen6hackmons": "[Gen 6] Pure Hackmons",
  "cc1v1": "[Gen 9] Challenge Cup 1v1",
  "cc2v2": "[Gen 9] Challenge Cup 2v2",
  "hc": "[Gen 9] Hackmons Cup",
  "monorandom": "[Gen 8] Monotype Random Battle",
  "bf": "[Gen 7] Battle Factory",
  "bssf": "[Gen 8] BSS Factory",
  "ssb": "[Gen 8] Super Staff Bros 4",
  "ssb4": "[Gen 8] Super Staff Bros 4",
  "lgrandom": "[Gen 7] Let's Go Random Battle",
  "gen6bf": "[Gen 6] Battle Factory",
  "gen7mono": "[Gen 7] Monotype",
  "gen7ag": "[Gen 7] Anything Goes",
  "gen7bss": "[Gen 7] Battle Spot Singles",
  "gen7bssf": "[Gen 7] BSS Factory",
  "lgpeou": "[Gen 7 Let's Go] OU",
  "lgou": "[Gen 7 Let's Go] Let's Go OU",
  "lgdou": "[Gen 7 Let's Go] Doubles OU",
  "lgpedou": "[Gen 7 Let's Go] OU",
  "vgc20": "[Gen 8] VGC 2020",
  "vgc19": "[Gen 7] VGC 2019",
  "vgc18": "[Gen 7] VGC 2018",
  "vgc17": "[Gen 7] VGC 2017",
  "gen7bsd": "[Gen 7] Battle Spot Doubles",
  "gen6mono": "[Gen 6] Monotype",
  "gen6ag": "[Gen 6] Anything Goes",
  "crossevo": "[Gen 9] Cross Evolution",
  "mayhem": "[Gen 9] Random Battle Mayhem",
  "omotm": "[Gen 9] Tier Shift",
  "lcotm": "[Gen 9] Sketchmons",
  "fabio": "Ampharos-Mega",
  "maero": "Aerodactyl-Mega",
  "megabunny": "Lopunny-Mega",
  "megabro": "Slowbro-Mega",
  "megacharizard": "Charizard-Mega-Y",
  "megacharizardx": "Charizard-Mega-X",
  "megacharizardy": "Charizard-Mega-Y",
  "megadoom": "Houndoom-Mega",
  "megadrill": "Beedrill-Mega",
  "megagard": "Gardevoir-Mega",
  "megacross": "Heracross-Mega",
  "megakhan": "Kangaskhan-Mega",
  "megalop": "Lopunny-Mega",
  "megaluc": "Lucario-Mega",
  "megamaw": "Mawile-Mega",
  "megamedi": "Medicham-Mega",
  "megamewtwo": "Mewtwo-Mega-Y",
  "megamewtwox": "Mewtwo-Mega-X",
  "megamewtwoy": "Mewtwo-Mega-Y",
  "megasnow": "Abomasnow-Mega",
  "megashark": "Sharpedo-Mega",
  "megasaur": "Venusaur-Mega",
  "mmx": "Mewtwo-Mega-X",
  "mmy": "Mewtwo-Mega-Y",
  "zardx": "Charizard-Mega-X",
  "zardy": "Charizard-Mega-Y",
  "blackdoor": "Pokestar Black-Door",
  "brycen": "Brycen-Man",
  "brycenman": "Pokestar Brycen-Man",
  "f00": "Pokestar F00",
  "f002": "Pokestar F002",
  "giant": "Pokestar Giant",
  "mt": "Pokestar MT",
  "mt2": "Pokestar MT2",
  "majin": "Spirit",
  "mechatyranitar": "MT",
  "mechatyranitar2": "MT2",
  "monica": "Giant",
  "spirit": "Pokestar Spirit",
  "transport": "Pokestar Transport",
  "ufo": "Pokestar UFO",
  "ufo2": "Pokestar UFO-2",
  "whitedoor": "Pokestar White-Door",
  "bugceus": "Arceus-Bug",
  "darkceus": "Arceus-Dark",
  "dragonceus": "Arceus-Dragon",
  "eleceus": "Arceus-Electric",
  "fairyceus": "Arceus-Fairy",
  "fightceus": "Arceus-Fighting",
  "fireceus": "Arceus-Fire",
  "flyceus": "Arceus-Flying",
  "ghostceus": "Arceus-Ghost",
  "grassceus": "Arceus-Grass",
  "groundceus": "Arceus-Ground",
  "iceceus": "Arceus-Ice",
  "poisonceus": "Arceus-Poison",
  "psyceus": "Arceus-Psychic",
  "rockceus": "Arceus-Rock",
  "steelceus": "Arceus-Steel",
  "waterceus": "Arceus-Water",
  "arcbug": "Arceus-Bug",
  "arcdark": "Arceus-Dark",
  "arcdragon": "Arceus-Dragon",
  "arcelectric": "Arceus-Electric",
  "arcfairy": "Arceus-Fairy",
  "arcfighting": "Arceus-Fighting",
  "arcfire": "Arceus-Fire",
  "arcflying": "Arceus-Flying",
  "arcghost": "Arceus-Ghost",
  "arcgrass": "Arceus-Grass",
  "arcground": "Arceus-Ground",
  "arcice": "Arceus-Ice",
  "arcpoison": "Arceus-Poison",
  "arcpsychic": "Arceus-Psychic",
  "arcrock": "Arceus-Rock",
  "arcsteel": "Arceus-Steel",
  "arcwater": "Arceus-Water",
  "basculinb": "Basculin-Blue-Striped",
  "basculinblue": "Basculin-Blue-Striped",
  "basculinbluestripe": "Basculin-Blue-Striped",
  "castformh": "Castform-Snowy",
  "castformice": "Castform-Snowy",
  "castformr": "Castform-Rainy",
  "castformwater": "Castform-Rainy",
  "castforms": "Castform-Sunny",
  "castformfire": "Castform-Sunny",
  "cherrims": "Cherrim-Sunshine",
  "cherrimsunny": "Cherrim-Sunshine",
  "darmanitanz": "Darmanitan-Zen",
  "darmanitanzenmode": "Darmanitan-Zen",
  "darmanitanzengalar": "Darmanitan-Galar-Zen",
  "darmgz": "Darmanitan-Galar-Zen",
  "deoxysnormal": "Deoxys",
  "deon": "Deoxys",
  "deoxysa": "Deoxys-Attack",
  "deoa": "Deoxys-Attack",
  "deoxysd": "Deoxys-Defense",
  "deoxysdefence": "Deoxys-Defense",
  "deod": "Deoxys-Defense",
  "deoxyss": "Deoxys-Speed",
  "deos": "Deoxys-Speed",
  "eiscuen": "Eiscue-Noice",
  "eternalfloette": "Floette-Eternal",
  "eternamax": "Eternatus-Eternamax",
  "girao": "Giratina-Origin",
  "giratinao": "Giratina-Origin",
  "gourgeists": "Gourgeist-Small",
  "gourgeistl": "Gourgeist-Large",
  "gourgeistxl": "Gourgeist-Super",
  "gourgeisth": "Gourgeist-Super",
  "gourgeisthuge": "Gourgeist-Super",
  "hoopau": "Hoopa-Unbound",
  "keldeor": "Keldeo-Resolute",
  "keldeoresolution": "Keldeo-Resolute",
  "kyuremb": "Kyurem-Black",
  "kyuremw": "Kyurem-White",
  "landorust": "Landorus-Therian",
  "meloettap": "Meloetta-Pirouette",
  "meloettas": "Meloetta-Pirouette",
  "meloettastep": "Meloetta-Pirouette",
  "meowsticfemale": "Meowstic-F",
  "morpekoh": "Morpeko-Hangry",
  "pumpkaboohuge": "Pumpkaboo-Super",
  "rotomc": "Rotom-Mow",
  "rotomcut": "Rotom-Mow",
  "rotomf": "Rotom-Frost",
  "rotomh": "Rotom-Heat",
  "rotoms": "Rotom-Fan",
  "rotomspin": "Rotom-Fan",
  "rotomw": "Rotom-Wash",
  "shaymins": "Shaymin-Sky",
  "skymin": "Shaymin-Sky",
  "thundurust": "Thundurus-Therian",
  "thundyt": "Thundurus-Therian",
  "tornadust": "Tornadus-Therian",
  "tornt": "Tornadus-Therian",
  "toxtricityl": "Toxtricity-Low-Key",
  "toxtricitylk": "Toxtricity-Low-Key",
  "wormadamg": "Wormadam-Sandy",
  "wormadamground": "Wormadam-Sandy",
  "wormadamsandycloak": "Wormadam-Sandy",
  "wormadams": "Wormadam-Trash",
  "wormadamsteel": "Wormadam-Trash",
  "wormadamtrashcloak": "Wormadam-Trash",
  "floettee": "Floette-Eternal",
  "floetteeternalflower": "Floette-Eternal",
  "ashgreninja": "Greninja-Ash",
  "zydog": "Zygarde-10%",
  "zydoge": "Zygarde-10%",
  "zygardedog": "Zygarde-10%",
  "zygarde50": "Zygarde",
  "zyc": "Zygarde-Complete",
  "zygarde100": "Zygarde-Complete",
  "zygardec": "Zygarde-Complete",
  "zygardefull": "Zygarde-Complete",
  "zygod": "Zygarde-Complete",
  "perfectzygarde": "Zygarde-Complete",
  "oricoriob": "Oricorio",
  "oricoriobaile": "Oricorio",
  "oricoriof": "Oricorio",
  "oricoriofire": "Oricorio",
  "oricorioe": "Oricorio-Pom-Pom",
  "oricorioelectric": "Oricorio-Pom-Pom",
  "oricoriog": "Oricorio-Sensu",
  "oricorioghost": "Oricorio-Sensu",
  "oricorios": "Oricorio-Sensu",
  "oricoriop": "Oricorio-Pa'u",
  "oricoriopsychic": "Oricorio-Pa'u",
  "lycanrocmidday": "Lycanroc",
  "lycanrocday": "Lycanroc",
  "lycanrocn": "Lycanroc-Midnight",
  "lycanrocnight": "Lycanroc-Midnight",
  "lycanrocd": "Lycanroc-Dusk",
  "ndm": "Necrozma-Dusk-Mane",
  "ndw": "Necrozma-Dawn-Wings",
  "necrozmadm": "Necrozma-Dusk-Mane",
  "necrozmadusk": "Necrozma-Dusk-Mane",
  "duskmane": "Necrozma-Dusk-Mane",
  "duskmanenecrozma": "Necrozma-Dusk-Mane",
  "necrozmadw": "Necrozma-Dawn-Wings",
  "necrozmadawn": "Necrozma-Dawn-Wings",
  "dawnwings": "Necrozma-Dawn-Wings",
  "dawnwingsnecrozma": "Necrozma-Dawn-Wings",
  "necrozmau": "Necrozma-Ultra",
  "ultranecrozma": "Necrozma-Ultra",
  "unecro": "Necrozma-Ultra",
  "ufop": "Pokestar UFO-2",
  "ufopsychic": "Pokestar UFO-2",
  "goon": "Obstagoon",
  "rime": "Mr. Rime",
  "zacianc": "Zacian-Crowned",
  "zamazentac": "Zamazenta-Crowned",
  "urshifuss": "Urshifu",
  "urshifusingle": "Urshifu",
  "urshifusinglestrike": "Urshifu",
  "urshifurs": "Urshifu-Rapid-Strike",
  "urshifurapid": "Urshifu-Rapid-Strike",
  "calyrexir": "Calyrex-Ice",
  "calyrexsr": "Calyrex-Shadow",
  "taurospaldea": "Tauros-Paldea-Combat",
  "taurospaldeafire": "Tauros-Paldea-Blaze",
  "taurospaldeawater": "Tauros-Paldea-Aqua",
  "nidoranfemale": "Nidoran-F",
  "nidoranmale": "Nidoran-M",
  "wormadamgrass": "Wormadam",
  "wormadamp": "Wormadam",
  "wormadamplant": "Wormadam",
  "wormadamplantcloak": "Wormadam",
  "cherrimo": "Cherrim",
  "cherrimovercast": "Cherrim",
  "furfrounatural": "Furfrou",
  "giratinaa": "Giratina",
  "giratinaaltered": "Giratina",
  "shayminl": "Shaymin",
  "shayminland": "Shaymin",
  "basculinr": "Basculin",
  "basculinred": "Basculin",
  "basculinredstripe": "Basculin",
  "basculinredstriped": "Basculin",
  "darmanitans": "Darmanitan",
  "darmanitanstandard": "Darmanitan",
  "darmanitanstandardmode": "Darmanitan",
  "tornadusi": "Tornadus",
  "tornadusincarnate": "Tornadus",
  "tornadusincarnation": "Tornadus",
  "thundurusi": "Thundurus",
  "thundurusincarnate": "Thundurus",
  "thundurusincarnation": "Thundurus",
  "landorusi": "Landorus",
  "landorusincarnate": "Landorus",
  "landorusincarnation": "Landorus",
  "keldeoo": "Keldeo",
  "keldeoordinary": "Keldeo",
  "meloettaa": "Meloetta",
  "meloettaaria": "Meloetta",
  "meloettavoice": "Meloetta",
  "meowsticm": "Meowstic",
  "meowsticmale": "Meowstic",
  "mimikyudisguised": "Mimikyu",
  "aegislashs": "Aegislash",
  "aegislashshield": "Aegislash",
  "pumpkabooaverage": "Pumpkaboo",
  "gourgeistaverage": "Gourgeist",
  "hoopac": "Hoopa",
  "hoopaconfined": "Hoopa",
  "wishiwashisolo": "Wishiwashi",
  "pokestarufof": "Pokestar UFO",
  "pokestarufoflying": "Pokestar UFO",
  "toxtricitya": "Toxtricity",
  "toxtricityamped": "Toxtricity",
  "ufof": "Pokestar UFO",
  "ufoflying": "Pokestar UFO",
  "vivillonmeadow": "Vivillon",
  "rockruffdusk": "Rockruff",
  "raticatet": "Raticate-Alola-Totem",
  "totemalolanraticate": "Raticate-Alola-Totem",
  "totemraticate": "Raticate-Alola-Totem",
  "totemraticatea": "Raticate-Alola-Totem",
  "totemraticatealola": "Raticate-Alola-Totem",
  "marowakt": "Marowak-Alola-Totem",
  "totemalolanmarowak": "Marowak-Alola-Totem",
  "totemmarowak": "Marowak-Alola-Totem",
  "totemmarowaka": "Marowak-Alola-Totem",
  "totemmarowakalola": "Marowak-Alola-Totem",
  "gumshoost": "Gumshoos-Totem",
  "totemgumshoos": "Gumshoos-Totem",
  "totemvikavolt": "Vikavolt-Totem",
  "vikavoltt": "Vikavolt-Totem",
  "ribombeet": "Ribombee-Totem",
  "totemribombee": "Ribombee-Totem",
  "araquanidt": "Araquanid-Totem",
  "totemaraquanid": "Araquanid-Totem",
  "lurantist": "Lurantis-Totem",
  "totemlurantis": "Lurantis-Totem",
  "salazzlet": "Salazzle-Totem",
  "totemsalazzle": "Salazzle-Totem",
  "mimikyut": "Mimikyu-Totem",
  "totemmimikyu": "Mimikyu-Totem",
  "kommoot": "Kommo-o-Totem",
  "totemkommoo": "Kommo-o-Totem",
  "alcremierubycream": "Alcremie",
  "alcremiematchacream": "Alcremie",
  "alcremiemintcream": "Alcremie",
  "alcremielemoncream": "Alcremie",
  "alcremiesaltedcream": "Alcremie",
  "alcremierubyswirl": "Alcremie",
  "alcremiecaramelswirl": "Alcremie",
  "alcremierainbowswirl": "Alcremie",
  "burmygrass": "Burmy",
  "burmyplant": "Burmy",
  "burmysandy": "Burmy",
  "burmytrash": "Burmy",
  "shelloseast": "Shellos",
  "shelloswest": "Shellos",
  "gastrodone": "Gastrodon",
  "gastrodoneast": "Gastrodon",
  "gastrodoneastsea": "Gastrodon",
  "gastrodonw": "Gastrodon",
  "gastrodonwest": "Gastrodon",
  "gastrodonwestsea": "Gastrodon",
  "deerlingspring": "Deerling",
  "deerlingsummer": "Deerling",
  "deerlingautumn": "Deerling",
  "deerlingwinter": "Deerling",
  "sawsbuckspring": "Sawsbuck",
  "sawsbucksummer": "Sawsbuck",
  "sawsbuckautumn": "Sawsbuck",
  "sawsbuckwinter": "Sawsbuck",
  "vivillonarchipelago": "Vivillon",
  "vivilloncontinental": "Vivillon",
  "vivillonelegant": "Vivillon",
  "vivillongarden": "Vivillon",
  "vivillonhighplains": "Vivillon",
  "vivillonicysnow": "Vivillon",
  "vivillonjungle": "Vivillon",
  "vivillonmarine": "Vivillon",
  "vivillonmodern": "Vivillon",
  "vivillonmonsoon": "Vivillon",
  "vivillonocean": "Vivillon",
  "vivillonpolar": "Vivillon",
  "vivillonriver": "Vivillon",
  "vivillonsandstorm": "Vivillon",
  "vivillonsavanna": "Vivillon",
  "vivillonsun": "Vivillon",
  "vivillontundra": "Vivillon",
  "flabb": "Flabebe",
  "flabebered": "Flabebe",
  "flabebeblue": "Flabebe",
  "flabebeorange": "Flabebe",
  "flabebewhite": "Flabebe",
  "flabebeyellow": "Flabebe",
  "flabbred": "Flabebe",
  "flabbblue": "Flabebe",
  "flabborange": "Flabebe",
  "flabbwhite": "Flabebe",
  "flabbyellow": "Flabebe",
  "floettered": "Floette",
  "floetteblue": "Floette",
  "floetteorange": "Floette",
  "floettewhite": "Floette",
  "floetteyellow": "Floette",
  "florgesred": "Florges",
  "florgesblue": "Florges",
  "florgesorange": "Florges",
  "florgeswhite": "Florges",
  "florgesyellow": "Florges",
  "furfroudandy": "Furfrou",
  "furfroudebutante": "Furfrou",
  "furfroudiamond": "Furfrou",
  "furfrouheart": "Furfrou",
  "furfroukabuki": "Furfrou",
  "furfroulareine": "Furfrou",
  "furfroumatron": "Furfrou",
  "furfroupharaoh": "Furfrou",
  "furfroustar": "Furfrou",
  "miniorred": "Minior",
  "miniororange": "Minior",
  "minioryellow": "Minior",
  "miniorgreen": "Minior",
  "miniorblue": "Minior",
  "miniorindigo": "Minior",
  "miniorviolet": "Minior",
  "unownb": "Unown",
  "unownc": "Unown",
  "unownd": "Unown",
  "unowne": "Unown",
  "unownf": "Unown",
  "unowng": "Unown",
  "unownh": "Unown",
  "unowni": "Unown",
  "unownj": "Unown",
  "unownk": "Unown",
  "unownl": "Unown",
  "unownm": "Unown",
  "unownn": "Unown",
  "unowno": "Unown",
  "unownp": "Unown",
  "unownq": "Unown",
  "unownr": "Unown",
  "unowns": "Unown",
  "unownt": "Unown",
  "unownu": "Unown",
  "unownv": "Unown",
  "unownw": "Unown",
  "unownx": "Unown",
  "unowny": "Unown",
  "unownz": "Unown",
  "unownexclamation": "Unown",
  "unownquestion": "Unown",
  "tatsugiridroopy": "Tatsugiri",
  "tatsugiristretchy": "Tatsugiri",
  "pokestargiant2": "Pokestar Giant",
  "pokestarmonica2": "Pokestar Giant",
  "pokestarufopropu1": "Pokestar UFO",
  "pokestarpropu1": "Pokestar UFO",
  "pokestarpropu2": "Pokestar UFO-2",
  "pokestarbrycenmanprop": "Pokestar Brycen-Man",
  "pokestarproph1": "Pokestar Brycen-Man",
  "pokestarmtprop": "Pokestar MT",
  "pokestarpropm1": "Pokestar MT",
  "pokestarmt2prop": "Pokestar MT2",
  "pokestarpropm2": "Pokestar MT2",
  "pokestartransportprop": "Pokestar Transport",
  "pokestarpropt1": "Pokestar Transport",
  "pokestargiantpropo1": "Pokestar Giant",
  "pokestarpropo1": "Pokestar Giant",
  "pokestargiantpropo2": "Pokestar Giant",
  "pokestarpropo2": "Pokestar Giant",
  "pokestarhumanoidprop": "Pokestar Humanoid",
  "pokestarpropc1": "Pokestar Humanoid",
  "pokestarmonsterprop": "Pokestar Monster",
  "pokestarpropc2": "Pokestar Monster",
  "pokestarspiritprop": "Pokestar Spirit",
  "pokestarpropg1": "Pokestar Spirit",
  "pokestarblackdoorprop": "Pokestar Black Door",
  "pokestarpropw1": "Pokestar Black Door",
  "pokestarwhitedoorprop": "Pokestar White Door",
  "pokestarpropw2": "Pokestar White Door",
  "pokestarf00prop": "Pokestar F00",
  "pokestarpropr1": "Pokestar F00",
  "pokestarf002prop": "Pokestar F002",
  "pokestarpropr2": "Pokestar F002",
  "pokestarblackbeltprop": "Pokestar Black Belt",
  "pokestarpropk1": "Pokestar Black Belt",
  "giant2": "Pokestar Giant",
  "monica2": "Pokestar Giant",
  "ufopropu1": "Pokestar UFO",
  "propu1": "Pokestar UFO",
  "ufopropu2": "Pokestar UFO-2",
  "propu2": "Pokestar UFO-2",
  "brycenmanprop": "Pokestar Brycen-Man",
  "proph1": "Pokestar Brycen-Man",
  "mtprop": "Pokestar MT",
  "propm1": "Pokestar MT",
  "mt2prop": "Pokestar MT2",
  "propm2": "Pokestar MT2",
  "transportprop": "Pokestar Transport",
  "propt1": "Pokestar Transport",
  "giantpropo1": "Pokestar Giant",
  "propo1": "Pokestar Giant",
  "giantpropo2": "Pokestar Giant",
  "propo2": "Pokestar Giant",
  "humanoidprop": "Pokestar Humanoid",
  "propc1": "Pokestar Humanoid",
  "monsterprop": "Pokestar Monster",
  "propc2": "Pokestar Monster",
  "spiritprop": "Pokestar Spirit",
  "propg1": "Pokestar Spirit",
  "blackdoorprop": "Pokestar Black Door",
  "propw1": "Pokestar Black Door",
  "whitedoorprop": "Pokestar White Door",
  "propw2": "Pokestar White Door",
  "f00prop": "Pokestar F00",
  "propr1": "Pokestar F00",
  "f002prop": "Pokestar F002",
  "propr2": "Pokestar F002",
  "blackbeltprop": "Pokestar Black Belt",
  "propk1": "Pokestar Black Belt",
  "ngas": "Neutralizing Gas",
  "pheal": "Poison Heal",
  "regen": "Regenerator",
  "stag": "Shadow Tag",
  "assvest": "Assault Vest",
  "av": "Assault Vest",
  "balloon": "Air Balloon",
  "band": "Choice Band",
  "boots": "Heavy-Duty Boots",
  "cb": "Choice Band",
  "ebelt": "Expert Belt",
  "fightgem": "Fighting Gem",
  "flightgem": "Flying Gem",
  "goggles": "Safety Goggles",
  "hdb": "Heavy-Duty Boots",
  "helmet": "Rocky Helmet",
  "lefties": "Leftovers",
  "lo": "Life Orb",
  "lorb": "Life Orb",
  "sash": "Focus Sash",
  "scarf": "Choice Scarf",
  "specs": "Choice Specs",
  "tr00swordsdance": "TR00",
  "tr01bodyslam": "TR01",
  "tr02flamethrower": "TR02",
  "tr03hydropump": "TR03",
  "tr04surf": "TR04",
  "tr05icebeam": "TR05",
  "tr06blizzard": "TR06",
  "tr07lowkick": "TR07",
  "tr08thunderbolt": "TR08",
  "tr09thunder": "TR09",
  "tr10earthquake": "TR10",
  "tr11psychic": "TR11",
  "tr12agility": "TR12",
  "tr13focusenergy": "TR13",
  "tr14metronome": "TR14",
  "tr15fireblast": "TR15",
  "tr16waterfall": "TR16",
  "tr17amnesia": "TR17",
  "tr18leechlife": "TR18",
  "tr19triattack": "TR19",
  "tr20substitute": "TR20",
  "tr21reversal": "TR21",
  "tr22sludgebomb": "TR22",
  "tr23spikes": "TR23",
  "tr24outrage": "TR24",
  "tr25psyshock": "TR25",
  "tr26endure": "TR26",
  "tr27sleeptalk": "TR27",
  "tr28megahorn": "TR28",
  "tr29batonpass": "TR29",
  "tr30encore": "TR30",
  "tr31irontail": "TR31",
  "tr32crunch": "TR32",
  "tr33shadowball": "TR33",
  "tr34futuresight": "TR34",
  "tr35uproar": "TR35",
  "tr36heatwave": "TR36",
  "tr37taunt": "TR37",
  "tr38trick": "TR38",
  "tr39superpower": "TR39",
  "tr40skillswap": "TR40",
  "tr41blazekick": "TR41",
  "tr42hypervoice": "TR42",
  "tr43overheat": "TR43",
  "tr44cosmicpower": "TR44",
  "tr45muddywater": "TR45",
  "tr46irondefense": "TR46",
  "tr47dragonclaw": "TR47",
  "tr48bulkup": "TR48",
  "tr49calmmind": "TR49",
  "tr50leafblade": "TR50",
  "tr51dragondance": "TR51",
  "tr52gyroball": "TR52",
  "tr53closecombat": "TR53",
  "tr54toxicspikes": "TR54",
  "tr55flareblitz": "TR55",
  "tr56aurasphere": "TR56",
  "tr57poisonjab": "TR57",
  "tr58darkpulse": "TR58",
  "tr59seedbomb": "TR59",
  "tr60xscissor": "TR60",
  "tr61bugbuzz": "TR61",
  "tr62dragonpulse": "TR62",
  "tr63powergem": "TR63",
  "tr64focusblast": "TR64",
  "tr65energyball": "TR65",
  "tr66bravebird": "TR66",
  "tr67earthpower": "TR67",
  "tr68nastyplot": "TR68",
  "tr69zenheadbutt": "TR69",
  "tr70flashcannon": "TR70",
  "tr71leafstorm": "TR71",
  "tr72powerwhip": "TR72",
  "tr73gunkshot": "TR73",
  "tr74ironhead": "TR74",
  "tr75stoneedge": "TR75",
  "tr76stealthrock": "TR76",
  "tr77grassknot": "TR77",
  "tr78sludgewave": "TR78",
  "tr79heavyslam": "TR79",
  "tr80electroball": "TR80",
  "tr81foulplay": "TR81",
  "tr82storedpower": "TR82",
  "tr83allyswitch": "TR83",
  "tr84scald": "TR84",
  "tr85workup": "TR85",
  "tr86wildcharge": "TR86",
  "tr87drillrun": "TR87",
  "tr88heatcrash": "TR88",
  "tr89hurricane": "TR89",
  "tr90playrough": "TR90",
  "tr91venomdrench": "TR91",
  "tr92dazzlinggleam": "TR92",
  "tr93darkestlariat": "TR93",
  "tr94highhorsepower": "TR94",
  "tr95throatchop": "TR95",
  "tr96pollenpuff": "TR96",
  "tr97psychicfangs": "TR97",
  "tr98liquidation": "TR98",
  "tr99bodypress": "TR99",
  "umbrella": "Utility Umbrella",
  "wp": "Weakness Policy",
  "aboma": "Abomasnow",
  "aegi": "Aegislash",
  "aegiblade": "Aegislash-Blade",
  "aegis": "Aegislash",
  "aero": "Aerodactyl",
  "amph": "Ampharos",
  "arc": "Arceus",
  "arceusnormal": "Arceus",
  "ashgren": "Greninja-Ash",
  "azu": "Azumarill",
  "bdrill": "Beedrill",
  "bee": "Beedrill",
  "bigsharp": "Kingambit",
  "birdjesus": "Pidgeot",
  "bish": "Bisharp",
  "blace": "Blacephalon",
  "bliss": "Blissey",
  "bull": "Tauros",
  "bulu": "Tapu Bulu",
  "bundlechan": "Iron Bundle",
  "camel": "Camerupt",
  "cathy": "Trevenant",
  "chandy": "Chandelure",
  "chomp": "Garchomp",
  "clanger": "Kommo-o",
  "clef": "Clefable",
  "coba": "Cobalion",
  "cofag": "Cofagrigus",
  "conk": "Conkeldurr",
  "cress": "Cresselia",
  "cruel": "Tentacruel",
  "cube": "Kyurem-Black",
  "cune": "Suicune",
  "darm": "Darmanitan",
  "dnite": "Dragonite",
  "dogars": "Koffing",
  "don": "Groudon",
  "drill": "Excadrill",
  "driller": "Excadrill",
  "dug": "Dugtrio",
  "duggy": "Dugtrio",
  "ekiller": "Arceus",
  "esca": "Escavalier",
  "ferro": "Ferrothorn",
  "fini": "Tapu Fini",
  "forry": "Forretress",
  "fug": "Rayquaza",
  "gar": "Gengar",
  "garde": "Gardevoir",
  "gatr": "Feraligatr",
  "gene": "Genesect",
  "gira": "Giratina",
  "gren": "Greninja",
  "gross": "Metagross",
  "gyara": "Gyarados",
  "hera": "Heracross",
  "hippo": "Hippowdon",
  "honch": "Honchkrow",
  "kanga": "Kangaskhan",
  "karp": "Magikarp",
  "kart": "Kartana",
  "keld": "Keldeo",
  "klef": "Klefki",
  "koko": "Tapu Koko",
  "kou": "Raikou",
  "krook": "Krookodile",
  "kyub": "Kyurem-Black",
  "kyuw": "Kyurem-White",
  "lando": "Landorus",
  "landoi": "Landorus",
  "landot": "Landorus-Therian",
  "lego": "Nihilego",
  "lele": "Tapu Lele",
  "linda": "Fletchinder",
  "luke": "Lucario",
  "lurk": "Golurk",
  "m2": "Mewtwo",
  "mage": "Magearna",
  "mamo": "Mamoswine",
  "mandi": "Mandibuzz",
  "mence": "Salamence",
  "milo": "Milotic",
  "morfentshusbando": "Gengar",
  "naga": "Naganadel",
  "nape": "Infernape",
  "nebby": "Cosmog",
  "neckboy": "Exeggutor-Alola",
  "nidok": "Nidoking",
  "nidoq": "Nidoqueen",
  "obama": "Abomasnow",
  "ogre": "Kyogre",
  "ohmagod": "Plasmanta",
  "p2": "Porygon2",
  "pato": "Psyduck",
  "pert": "Swampert",
  "pex": "Toxapex",
  "phero": "Pheromosa",
  "pika": "Pikachu",
  "pory2": "Porygon2",
  "poryz": "Porygon-Z",
  "pyuku": "Pyukumuku",
  "pz": "Porygon-Z",
  "queen": "Nidoqueen",
  "rachi": "Jirachi",
  "rank": "Reuniclus",
  "ray": "Rayquaza",
  "reuni": "Reuniclus",
  "sab": "Sableye",
  "sable": "Sableye",
  "scept": "Sceptile",
  "scoli": "Scolipede",
  "seejong": "Sealeo",
  "serp": "Serperior",
  "shao": "Mienshao",
  "skarm": "Skarmory",
  "smogon": "Koffing",
  "smogonbird": "Talonflame",
  "snips": "Drapion",
  "staka": "Stakataka",
  "steela": "Celesteela",
  "sui": "Suicune",
  "swole": "Buzzwole",
  "talon": "Talonflame",
  "tang": "Tangrowth",
  "terra": "Terrakion",
  "tflame": "Talonflame",
  "thundy": "Thundurus",
  "toed": "Politoed",
  "torn": "Tornadus",
  "tran": "Heatran",
  "ttar": "Tyranitar",
  "venu": "Venusaur",
  "viriz": "Virizion",
  "watershifu": "Urshifu-Rapid-Strike",
  "whimsi": "Whimsicott",
  "xern": "Xerneas",
  "xurk": "Xurkitree",
  "ygod": "Yveltal",
  "zam": "Alakazam",
  "zard": "Charizard",
  "zong": "Bronzong",
  "zor": "Scizor",
  "zyg": "Zygarde",
  "ub01": "Nihilego",
  "ub02a": "Buzzwole",
  "ub02b": "Pheromosa",
  "ub03": "Xurkitree",
  "ub04blade": "Kartana",
  "ub04blaster": "Celesteela",
  "ub05": "Guzzlord",
  "ubburst": "Blacephalon",
  "ubassembly": "Stakataka",
  "ubadhesive": "Poipole",
  "bb": "Brave Bird",
  "bd": "Belly Drum",
  "bde": "Baby-Doll Eyes",
  "bpass": "Baton Pass",
  "bp": "Baton Pass",
  "cc": "Close Combat",
  "cm": "Calm Mind",
  "dbond": "Destiny Bond",
  "dd": "Dragon Dance",
  "dv": "Dark Void",
  "eq": "Earthquake",
  "espeed": "ExtremeSpeed",
  "eterrain": "Electric Terrain",
  "faintattack": "Feint Attack",
  "glowpunch": "Power-up Punch",
  "gterrain": "Grassy Terrain",
  "hp": "Hidden Power",
  "hpbug": "Hidden Power Bug",
  "hpdark": "Hidden Power Dark",
  "hpdragon": "Hidden Power Dragon",
  "hpelectric": "Hidden Power electric",
  "hpfighting": "Hidden Power Fighting",
  "hpfire": "Hidden Power Fire",
  "hpflying": "Hidden Power Flying",
  "hpghost": "Hidden Power Ghost",
  "hpgrass": "Hidden Power Grass",
  "hpground": "Hidden Power Ground",
  "hpice": "Hidden Power Ice",
  "hppoison": "Hidden Power Poison",
  "hppsychic": "Hidden Power Psychic",
  "hprock": "Hidden Power Rock",
  "hpsteel": "Hidden Power Steel",
  "hpwater": "Hidden Power Water",
  "hjk": "High Jump Kick",
  "hijumpkick": "High Jump Kick",
  "mterrain": "Misty Terrain",
  "np": "Nasty Plot",
  "pfists": "Plasma Fists",
  "playaround": "Play Rough",
  "pterrain": "Psychic Terrain",
  "pup": "Power-up Punch",
  "qd": "Quiver Dance",
  "rocks": "Stealth Rock",
  "sd": "Swords Dance",
  "se": "Stone Edge",
  "sideshellarm": "Shell Side Arm",
  "spin": "Rapid Spin",
  "sr": "Stealth Rock",
  "ssa": "Shell Side Arm",
  "sub": "Substitute",
  "tr": "Trick Room",
  "troom": "Trick Room",
  "tbolt": "Thunderbolt",
  "tspikes": "Toxic Spikes",
  "twave": "Thunder Wave",
  "vicegrip": "Vise Grip",
  "web": "Sticky Web",
  "wow": "Will-O-Wisp",
  "10mv": "10,000,000 Volt Thunderbolt",
  "10mvt": "10,000,000 Volt Thunderbolt",
  "clangorous": "Clangorous Soulblaze",
  "cs": "Clangorous Soulblaze",
  "ee": "Extreme Evoboost",
  "extreme": "Extreme Evoboost",
  "genesis": "Genesis Supernova",
  "goa": "Guardian of Alola",
  "gs": "Genesis Supernova",
  "guardian": "Guardian of Alola",
  "lets": "Let's Snuggle Forever",
  "light": "Light That Burns the Sky",
  "lsf": "Let's Snuggle Forever",
  "ltbts": "Light That Burns the Sky",
  "malicious": "Malicious Moonsault",
  "menacing": "Menacing Moonraze Maelstrom",
  "mmm": "Menacing Moonraze Maelstrom",
  "moonsault": "Malicious Moonsault",
  "oceanic": "Oceanic Operetta",
  "oo": "Oceanic Operetta",
  "pp": "Pulverizing Pancake",
  "pulverizing": "Pulverizing Pancake",
  "sar": "Sinister Arrow Raid",
  "searing": "Searing Sunraze Smash",
  "sinister": "Sinister Arrow Raid",
  "ss": "Stoked Sparksurfer",
  "sss": "Searing Sunraze Smash",
  "sssss": "Soul-Stealing 7-Star Strike",
  "ss7ss": "Soul-Stealing 7-Star Strike",
  "soul": "Soul-Stealing 7-Star Strike",
  "soulstealingsevenstarstrike": "Soul-Stealing 7-Star Strike",
  "splintered": "Splintered Stormshards",
  "stoked": "Stoked Sparksurfer",
  "stormshards": "Splintered Stormshards",
  "zbug": "Savage Spin-Out",
  "zclangingscales": "Clangorous Soulblaze",
  "zdark": "Black Hole Eclipse",
  "zdarkestlariat": "Malicious Moonsault",
  "zdawnwingsnecrozma": "Menacing Moonraze Maelstrom",
  "zdecidueye": "Sinister Arrow Raid",
  "zdragon": "Devastating Drake",
  "zduskmanenecrozma": "Searing Sunraze Smash",
  "zelectric": "Gigavolt Havoc",
  "zeevee": "Extreme Evoboost",
  "zevo": "Extreme Evoboost",
  "zfairy": "Twinkle Tackle",
  "zflying": "Supersonic Skystrike",
  "zfighting": "All-Out Pummeling",
  "zfire": "Inferno Overdrive",
  "zghost": "Never-Ending Nightmare",
  "zgigaimpact": "Pulverizing Pancake",
  "zgrass": "Bloom Doom",
  "zground": "Tectonic Rage",
  "zice": "Subzero Slammer",
  "zincineroar": "Malicious Moonsault",
  "zkommoo": "Clangorous Soulblaze",
  "zlastresort": "Extreme Evoboost",
  "zlunala": "Menacing Moonraze Maelstrom",
  "zlycanroc": "Splintered Stormshards",
  "znaturesmadness": "Guardian of Alola",
  "zmarshadow": "Soul-Stealing 7-Star Strike",
  "zmew": "Genesis Supernova",
  "zmimikyu": "Let's Snuggle Forever",
  "zmoongeistbeam": "Menacing Moonraze Maelstrom",
  "znecrozma": "Light That Burns the Sky",
  "znormal": "Breakneck Blitz",
  "zrock": "Continental Crush",
  "zphotongeyser": "Light That Burns the Sky",
  "zpikachu": "Catastropika",
  "zpikachucap": "10,000,000 Volt Thunderbolt",
  "zplayrough": "Let's Snuggle Forever",
  "zpoison": "Acid Downpour",
  "zprimarina": "Oceanic Operetta",
  "zpsychic": "Shattered Psyche",
  "zraichu": "Stoked Sparksurfer",
  "zsnorlax": "Pulverizing Pancake",
  "zsolgaleo": "Searing Sunraze Smash",
  "zsparklingaria": "Oceanic Operetta",
  "zspectralthief": "Soul-Stealing 7-Star Strike",
  "zspiritshackle": "Sinister Arrow Raid",
  "zsunsteelstrike": "Searing Sunraze Smash",
  "zsteel": "Corkscrew Crash",
  "zstoneedge": "Splintered Stormshards",
  "ztapu": "Guardian of Alola",
  "zthunderbolt": "10,000,000 Volt Thunderbolt",
  "zultranecrozma": "Light That Burns the Sky",
  "zvolttackle": "Catastropika",
  "zwater": "Hydro Vortex",
  "maxbug": "Max Flutterby",
  "maxdark": "Max Darkness",
  "maxdragon": "Max Wyrmwind",
  "maxelectric": "Max Lightning",
  "maxfairy": "Max Starfall",
  "maxfighting": "Max Knuckle",
  "maxfire": "Max Flare",
  "maxflying": "Max Airstream",
  "maxghost": "Max Phantasm",
  "maxgrass": "Max Overgrowth",
  "maxground": "Max Quake",
  "maxice": "Max Hailstorm",
  "maxnormal": "Max Strike",
  "maxpoison": "Max Ooze",
  "maxpsychic": "Max Mindstorm",
  "maxrock": "Max Rockfall",
  "maxsteel": "Max Steelspike",
  "maxwater": "Max Geyser",
  "maxstatus": "Max Guard",
  "maxprotect": "Max Guard",
  "befuddle": "G-Max Befuddle",
  "cannonade": "G-Max Cannonade",
  "centiferno": "G-Max Centiferno",
  "chistrike": "G-Max Chi Strike",
  "cuddle": "G-Max Cuddle",
  "depletion": "G-Max Depletion",
  "drumsolo": "G-Max Drum Solo",
  "finale": "G-Max Finale",
  "fireball": "G-Max Fireball",
  "foamburst": "G-Max Foam Burst",
  "goldrush": "G-Max Gold Rush",
  "gravitas": "G-Max Gravitas",
  "hydrosnipe": "G-Max Hydrosnipe",
  "malodor": "G-Max Malodor",
  "meltdown": "G-Max Meltdown",
  "oneblow": "G-Max One Blow",
  "rapidflow": "G-Max Rapid Flow",
  "replenish": "G-Max Replenish",
  "resonance": "G-Max Resonance",
  "sandblast": "G-Max Sandblast",
  "smite": "G-Max Smite",
  "snooze": "G-Max Snooze",
  "steelsurge": "G-Max Steelsurge",
  "stonesurge": "G-Max Stonesurge",
  "stunshock": "G-Max Stun Shock",
  "sweetness": "G-Max Sweetness",
  "tartness": "G-Max Tartness",
  "terror": "G-Max Terror",
  "vinelash": "G-Max Vine Lash",
  "volcalith": "G-Max Volcalith",
  "voltcrash": "G-Max Volt Crash",
  "wildfire": "G-Max Wildfire",
  "windrage": "G-Max Wind Rage",
  "fushigidane": "Bulbasaur",
  "fushigisou": "Ivysaur",
  "fushigibana": "Venusaur",
  "hitokage": "Charmander",
  "rizaado": "Charmeleon",
  "rizaadon": "Charizard",
  "zenigame": "Squirtle",
  "kameeru": "Wartortle",
  "kamekkusu": "Blastoise",
  "kyatapii": "Caterpie",
  "toranseru": "Metapod",
  "batafurii": "Butterfree",
  "biidoru": "Weedle",
  "kokuun": "Kakuna",
  "supiaa": "Beedrill",
  "poppo": "Pidgey",
  "pijon": "Pidgeotto",
  "pijotto": "Pidgeot",
  "koratta": "Rattata",
  "ratta": "Raticate",
  "onisuzume": "Spearow",
  "onidoriru": "Fearow",
  "aabo": "Ekans",
  "aabokku": "Arbok",
  "pikachuu": "Pikachu",
  "raichuu": "Raichu",
  "sando": "Sandshrew",
  "sandopan": "Sandslash",
  "nidoranmesu": "Nidoran-F",
  "nidoriina": "Nidorina",
  "nidokuin": "Nidoqueen",
  "nidoranosu": "Nidoran-M",
  "nidoriino": "Nidorino",
  "nidokingu": "Nidoking",
  "pippi": "Clefairy",
  "pikushii": "Clefable",
  "rokon": "Vulpix",
  "kyuukon": "Ninetales",
  "purin": "Jigglypuff",
  "pukurin": "Wigglytuff",
  "zubatto": "Zubat",
  "gorubatto": "Golbat",
  "nazonokusa": "Oddish",
  "kusaihana": "Gloom",
  "rafureshia": "Vileplume",
  "parasu": "Paras",
  "parasekuto": "Parasect",
  "konpan": "Venonat",
  "morufon": "Venomoth",
  "diguda": "Diglett",
  "dagutorio": "Dugtrio",
  "nyaasu": "Meowth",
  "perushian": "Persian",
  "kodakku": "Psyduck",
  "gorudakku": "Golduck",
  "mankii": "Mankey",
  "okorizaru": "Primeape",
  "gaadi": "Growlithe",
  "uindi": "Arcanine",
  "nyoromo": "Poliwag",
  "nyorozo": "Poliwhirl",
  "nyorobon": "Poliwrath",
  "keeshy": "Abra",
  "yungeraa": "Kadabra",
  "fuudin": "Alakazam",
  "wanrikii": "Machop",
  "goorikii": "Machoke",
  "kairikii": "Machamp",
  "madatsubomi": "Bellsprout",
  "utsudon": "Weepinbell",
  "utsubotto": "Victreebel",
  "menokurage": "Tentacool",
  "dokukurage": "Tentacruel",
  "ishitsubute": "Geodude",
  "goroon": "Graveler",
  "goroonya": "Golem",
  "poniita": "Ponyta",
  "gyaroppu": "Rapidash",
  "yadon": "Slowpoke",
  "yadoran": "Slowbro",
  "koiru": "Magnemite",
  "reakoiru": "Magneton",
  "kamonegi": "Farfetch'd",
  "doodoo": "Doduo",
  "doodorio": "Dodrio",
  "pauwau": "Seel",
  "jugon": "Dewgong",
  "betobetaa": "Grimer",
  "betobeton": "Muk",
  "sherudaa": "Shellder",
  "parushen": "Cloyster",
  "goosu": "Gastly",
  "goosuto": "Haunter",
  "gengaa": "Gengar",
  "iwaaku": "Onix",
  "suriipu": "Drowzee",
  "suriipaa": "Hypno",
  "kurabu": "Krabby",
  "kinguraa": "Kingler",
  "biriridama": "Voltorb",
  "marumain": "Electrode",
  "tamatama": "Exeggcute",
  "nasshii": "Exeggutor",
  "karakara": "Cubone",
  "garagara": "Marowak",
  "sawamuraa": "Hitmonlee",
  "ebiwaraa": "Hitmonchan",
  "beroringa": "Lickitung",
  "dogaasu": "Koffing",
  "matadogasu": "Weezing",
  "saihoon": "Rhyhorn",
  "saidon": "Rhydon",
  "rakkii": "Chansey",
  "monjara": "Tangela",
  "garuura": "Kangaskhan",
  "tattsuu": "Horsea",
  "shiidora": "Seadra",
  "tosakinto": "Goldeen",
  "azumaou": "Seaking",
  "hitodeman": "Staryu",
  "sutaamii": "Starmie",
  "bariyaado": "Mr. Mime",
  "sutoraiku": "Scyther",
  "ruujura": "Jynx",
  "erebuu": "Electabuzz",
  "buubaa": "Magmar",
  "kairosu": "Pinsir",
  "kentarosu": "Tauros",
  "koikingu": "Magikarp",
  "gyaradosu": "Gyarados",
  "rapurasu": "Lapras",
  "metamon": "Ditto",
  "iibui": "Eevee",
  "shawaazu": "Vaporeon",
  "sandaasu": "Jolteon",
  "buusutaa": "Flareon",
  "porigon": "Porygon",
  "omunaito": "Omanyte",
  "omusutaa": "Omastar",
  "kabutopusu": "Kabutops",
  "putera": "Aerodactyl",
  "kabigon": "Snorlax",
  "furiizaa": "Articuno",
  "sandaa": "Zapdos",
  "faiyaa": "Moltres",
  "miniryuu": "Dratini",
  "hakuryuu": "Dragonair",
  "kairyuu": "Dragonite",
  "myuutsuu": "Mewtwo",
  "myuu": "Mew",
  "chikoriita": "Chikorita",
  "beiriifu": "Bayleef",
  "meganiumu": "Meganium",
  "hinoarashi": "Cyndaquil",
  "magumarashi": "Quilava",
  "bakufuun": "Typhlosion",
  "waninoko": "Totodile",
  "arigeitsu": "Croconaw",
  "oodairu": "Feraligatr",
  "otachi": "Sentret",
  "ootachi": "Furret",
  "hoohoo": "Hoothoot",
  "yorunozuku": "Noctowl",
  "rediba": "Ledyba",
  "redian": "Ledian",
  "itomaru": "Spinarak",
  "ariadosu": "Ariados",
  "kurobatto": "Crobat",
  "chonchii": "Chinchou",
  "rantaan": "Lanturn",
  "pichuu": "Pichu",
  "py": "Cleffa",
  "pupurin": "Igglybuff",
  "togepii": "Togepi",
  "togechikku": "Togetic",
  "neitei": "Natu",
  "neiteio": "Xatu",
  "meriipu": "Mareep",
  "mokoko": "Flaaffy",
  "denryuu": "Ampharos",
  "kireihana": "Bellossom",
  "mariru": "Marill",
  "mariruri": "Azumarill",
  "usokkii": "Sudowoodo",
  "nyorotono": "Politoed",
  "hanekko": "Hoppip",
  "popokko": "Skiploom",
  "watakko": "Jumpluff",
  "eipamu": "Aipom",
  "himanattsu": "Sunkern",
  "kimawari": "Sunflora",
  "yanyanma": "Yanma",
  "upaa": "Wooper",
  "nuoo": "Quagsire",
  "eefi": "Espeon",
  "burakkii": "Umbreon",
  "yamikarasu": "Murkrow",
  "yadokingu": "Slowking",
  "muuma": "Misdreavus",
  "annoon": "Unown",
  "soonansu": "Wobbuffet",
  "kirinriki": "Girafarig",
  "kunugidama": "Pineco",
  "foretosu": "Forretress",
  "nokotchi": "Dunsparce",
  "guraigaa": "Gligar",
  "haganeeru": "Steelix",
  "buruu": "Snubbull",
  "guranburu": "Granbull",
  "hariisen": "Qwilfish",
  "hassamu": "Scizor",
  "tsubotsubo": "Shuckle",
  "herakurosu": "Heracross",
  "nyuura": "Sneasel",
  "himeguma": "Teddiursa",
  "ringuma": "Ursaring",
  "magumaggu": "Slugma",
  "magukarugo": "Magcargo",
  "urimuu": "Swinub",
  "inomuu": "Piloswine",
  "saniigo": "Corsola",
  "teppouo": "Remoraid",
  "okutan": "Octillery",
  "deribaado": "Delibird",
  "mantain": "Mantine",
  "eaamudo": "Skarmory",
  "derubiru": "Houndour",
  "herugaa": "Houndoom",
  "kingudora": "Kingdra",
  "gomazou": "Phanpy",
  "donfan": "Donphan",
  "porigon2": "Porygon2",
  "odoshishi": "Stantler",
  "dooburu": "Smeargle",
  "barukii": "Tyrogue",
  "kapoeraa": "Hitmontop",
  "muchuuru": "Smoochum",
  "erekiddo": "Elekid",
  "buby": "Magby",
  "mirutanku": "Miltank",
  "hapinasu": "Blissey",
  "suikun": "Suicune",
  "yoogirasu": "Larvitar",
  "sanagirasu": "Pupitar",
  "bangirasu": "Tyranitar",
  "rugia": "Lugia",
  "houou": "Ho-Oh",
  "sereby": "Celebi",
  "kimori": "Treecko",
  "juputoru": "Grovyle",
  "jukain": "Sceptile",
  "achamo": "Torchic",
  "wakashamo": "Combusken",
  "bashaamo": "Blaziken",
  "mizugorou": "Mudkip",
  "numakuroo": "Marshtomp",
  "raguraaji": "Swampert",
  "pochiena": "Poochyena",
  "guraena": "Mightyena",
  "jiguzaguma": "Zigzagoon",
  "massuguma": "Linoone",
  "kemusso": "Wurmple",
  "karasarisu": "Silcoon",
  "agehanto": "Beautifly",
  "mayurudo": "Cascoon",
  "dokukeiru": "Dustox",
  "hasuboo": "Lotad",
  "hasuburero": "Lombre",
  "runpappa": "Ludicolo",
  "taneboo": "Seedot",
  "konohana": "Nuzleaf",
  "daatengu": "Shiftry",
  "subame": "Taillow",
  "oosubame": "Swellow",
  "kyamome": "Wingull",
  "perippaa": "Pelipper",
  "rarutosu": "Ralts",
  "kiruria": "Kirlia",
  "saanaito": "Gardevoir",
  "ametama": "Surskit",
  "amemoosu": "Masquerain",
  "kinokoko": "Shroomish",
  "kinogassa": "Breloom",
  "namakero": "Slakoth",
  "yarukimono": "Vigoroth",
  "kekkingu": "Slaking",
  "tsuchinin": "Nincada",
  "tekkanin": "Ninjask",
  "nukenin": "Shedinja",
  "gonyonyo": "Whismur",
  "dogoomu": "Loudred",
  "bakuongu": "Exploud",
  "makunoshita": "Makuhita",
  "hariteyama": "Hariyama",
  "ruriri": "Azurill",
  "nozupasu": "Nosepass",
  "eneko": "Skitty",
  "enekororo": "Delcatty",
  "yamirami": "Sableye",
  "kuchiito": "Mawile",
  "kokodora": "Aron",
  "kodora": "Lairon",
  "bosugodora": "Aggron",
  "asanan": "Meditite",
  "chaaremu": "Medicham",
  "rakurai": "Electrike",
  "raiboruto": "Manectric",
  "purasuru": "Plusle",
  "mainan": "Minun",
  "barubiito": "Volbeat",
  "irumiize": "Illumise",
  "rozeria": "Roselia",
  "gokurin": "Gulpin",
  "marunoomu": "Swalot",
  "kibania": "Carvanha",
  "samehadaa": "Sharpedo",
  "hoeruko": "Wailmer",
  "hoeruoo": "Wailord",
  "donmeru": "Numel",
  "bakuuda": "Camerupt",
  "kootasu": "Torkoal",
  "banebuu": "Spoink",
  "buupiggu": "Grumpig",
  "patchiiru": "Spinda",
  "nakkuraa": "Trapinch",
  "biburaaba": "Vibrava",
  "furaigon": "Flygon",
  "sabonea": "Cacnea",
  "nokutasu": "Cacturne",
  "chirutto": "Swablu",
  "chirutarisu": "Altaria",
  "zanguusu": "Zangoose",
  "habuneeku": "Seviper",
  "runatoon": "Lunatone",
  "sorurokku": "Solrock",
  "dojotchi": "Barboach",
  "namazun": "Whiscash",
  "heigani": "Corphish",
  "shizarigaa": "Crawdaunt",
  "yajiron": "Baltoy",
  "nendooru": "Claydol",
  "ririira": "Lileep",
  "yureidoru": "Cradily",
  "anopusu": "Anorith",
  "aamarudo": "Armaldo",
  "hinbasu": "Feebas",
  "mirokarosu": "Milotic",
  "powarun": "Castform",
  "kakureon": "Kecleon",
  "kagebouzu": "Shuppet",
  "jupetta": "Banette",
  "yomawaru": "Duskull",
  "samayooru": "Dusclops",
  "toropiusu": "Tropius",
  "chiriin": "Chimecho",
  "abusoru": "Absol",
  "soonano": "Wynaut",
  "yukiwarashi": "Snorunt",
  "onigoori": "Glalie",
  "tamazarashi": "Spheal",
  "todoguraa": "Sealeo",
  "todozeruga": "Walrein",
  "paaruru": "Clamperl",
  "hanteeru": "Huntail",
  "sakurabisu": "Gorebyss",
  "jiiransu": "Relicanth",
  "rabukasu": "Luvdisc",
  "tatsubei": "Bagon",
  "komoruu": "Shelgon",
  "boomanda": "Salamence",
  "danbaru": "Beldum",
  "metangu": "Metang",
  "metagurosu": "Metagross",
  "rejirokku": "Regirock",
  "rejiaisu": "Regice",
  "rejisuchiru": "Registeel",
  "rateiasu": "Latias",
  "rateiosu": "Latios",
  "kaiooga": "Kyogre",
  "guraadon": "Groudon",
  "rekkuuza": "Rayquaza",
  "jiraachi": "Jirachi",
  "deokishisu": "Deoxys",
  "naetoru": "Turtwig",
  "hayashigame": "Grotle",
  "dodaitosu": "Torterra",
  "hikozaru": "Chimchar",
  "moukazaru": "Monferno",
  "goukazaru": "Infernape",
  "potchama": "Piplup",
  "pottaishi": "Prinplup",
  "enperuto": "Empoleon",
  "mukkuru": "Starly",
  "mukubaado": "Staravia",
  "mukuhooku": "Staraptor",
  "bippa": "Bidoof",
  "biidaru": "Bibarel",
  "korobooshi": "Kricketot",
  "korotokku": "Kricketune",
  "korinku": "Shinx",
  "rukushio": "Luxio",
  "rentoraa": "Luxray",
  "subomii": "Budew",
  "rozureido": "Roserade",
  "zugaidosu": "Cranidos",
  "ramuparudo": "Rampardos",
  "tatetopusu": "Shieldon",
  "toridepusu": "Bastiodon",
  "minomutchi": "Burmy",
  "minomadamu": "Wormadam",
  "gaameiru": "Mothim",
  "mitsuhanii": "Combee",
  "biikuin": "Vespiquen",
  "buizeru": "Buizel",
  "furoozeru": "Floatzel",
  "cherinbo": "Cherubi",
  "cherimu": "Cherrim",
  "karanakushi": "Shellos",
  "toritodon": "Gastrodon",
  "eteboosu": "Ambipom",
  "fuwante": "Drifloon",
  "fuwaraido": "Drifblim",
  "mimiroru": "Buneary",
  "mimiroppu": "Lopunny",
  "muumaaji": "Mismagius",
  "donkarasu": "Honchkrow",
  "nyarumaa": "Glameow",
  "bunyatto": "Purugly",
  "riishan": "Chingling",
  "sukanpuu": "Stunky",
  "sukatanku": "Skuntank",
  "doomiraa": "Bronzor",
  "dootakun": "Bronzong",
  "usohachi": "Bonsly",
  "manene": "Mime Jr.",
  "pinpuku": "Happiny",
  "perappu": "Chatot",
  "mikaruge": "Spiritomb",
  "fukamaru": "Gible",
  "gabaito": "Gabite",
  "gaburiasu": "Garchomp",
  "gonbe": "Munchlax",
  "rioru": "Riolu",
  "rukario": "Lucario",
  "hipopotasu": "Hippopotas",
  "kabarudon": "Hippowdon",
  "sukorupi": "Skorupi",
  "dorapion": "Drapion",
  "guregguru": "Croagunk",
  "dokuroggu": "Toxicroak",
  "masukippa": "Carnivine",
  "keikouo": "Finneon",
  "neoranto": "Lumineon",
  "tamanta": "Mantyke",
  "yukikaburi": "Snover",
  "yukinooo": "Abomasnow",
  "manyuura": "Weavile",
  "jibakoiru": "Magnezone",
  "beroberuto": "Lickilicky",
  "dosaidon": "Rhyperior",
  "mojanbo": "Tangrowth",
  "erekiburu": "Electivire",
  "buubaan": "Magmortar",
  "togekissu": "Togekiss",
  "megayanma": "Yanmega",
  "riifia": "Leafeon",
  "gureishia": "Glaceon",
  "guraion": "Gliscor",
  "manmuu": "Mamoswine",
  "porigonz": "Porygon-Z",
  "erureido": "Gallade",
  "dainoozu": "Probopass",
  "yonowaaru": "Dusknoir",
  "yukimenoko": "Froslass",
  "rotomu": "Rotom",
  "yukushii": "Uxie",
  "emuritto": "Mesprit",
  "agunomu": "Azelf",
  "diaruga": "Dialga",
  "parukia": "Palkia",
  "hiidoran": "Heatran",
  "rejigigasu": "Regigigas",
  "girateina": "Giratina",
  "kureseria": "Cresselia",
  "fione": "Phione",
  "manafi": "Manaphy",
  "daakurai": "Darkrai",
  "sheimi": "Shaymin",
  "aruseusu": "Arceus",
  "bikuteini": "Victini",
  "tsutaaja": "Snivy",
  "janobii": "Servine",
  "jarooda": "Serperior",
  "pokabu": "Tepig",
  "chaobuu": "Pignite",
  "enbuoo": "Emboar",
  "mijumaru": "Oshawott",
  "futachimaru": "Dewott",
  "daikenki": "Samurott",
  "minezumi": "Patrat",
  "miruhoggu": "Watchog",
  "yooterii": "Lillipup",
  "haaderia": "Herdier",
  "muurando": "Stoutland",
  "choroneko": "Purrloin",
  "reparudasu": "Liepard",
  "yanappu": "Pansage",
  "yanakkii": "Simisage",
  "baoppu": "Pansear",
  "baokkii": "Simisear",
  "hiyappu": "Panpour",
  "hiyakkii": "Simipour",
  "mushaana": "Musharna",
  "mamepato": "Pidove",
  "hatooboo": "Tranquill",
  "kenhorou": "Unfezant",
  "shimama": "Blitzle",
  "zeburaika": "Zebstrika",
  "dangoro": "Roggenrola",
  "gantoru": "Boldore",
  "gigaiasu": "Gigalith",
  "koromori": "Woobat",
  "kokoromori": "Swoobat",
  "moguryuu": "Drilbur",
  "doryuuzu": "Excadrill",
  "tabunne": "Audino",
  "dokkoraa": "Timburr",
  "dotekkotsu": "Gurdurr",
  "roobushin": "Conkeldurr",
  "otamaro": "Tympole",
  "gamagaru": "Palpitoad",
  "gamageroge": "Seismitoad",
  "nageki": "Throh",
  "dageki": "Sawk",
  "kurumiru": "Sewaddle",
  "kurumayu": "Swadloon",
  "hahakomori": "Leavanny",
  "fushide": "Venipede",
  "hoiiga": "Whirlipede",
  "pendoraa": "Scolipede",
  "monmen": "Cottonee",
  "erufuun": "Whimsicott",
  "churine": "Petilil",
  "doredia": "Lilligant",
  "basurao": "Basculin",
  "meguroko": "Sandile",
  "warubiru": "Krokorok",
  "warubiaru": "Krookodile",
  "darumakka": "Darumaka",
  "hihidaruma": "Darmanitan",
  "marakatchi": "Maractus",
  "ishizumai": "Dwebble",
  "iwaparesu": "Crustle",
  "zuruggu": "Scraggy",
  "zuruzukin": "Scrafty",
  "shinboraa": "Sigilyph",
  "desumasu": "Yamask",
  "desukaan": "Cofagrigus",
  "purotooga": "Tirtouga",
  "abagoora": "Carracosta",
  "aaken": "Archen",
  "aakeosu": "Archeops",
  "yabukuron": "Trubbish",
  "dasutodasu": "Garbodor",
  "zoroa": "Zorua",
  "zoroaaku": "Zoroark",
  "chiraamy": "Minccino",
  "chirachiino": "Cinccino",
  "gochimu": "Gothita",
  "gochimiru": "Gothorita",
  "gochiruzeru": "Gothitelle",
  "yuniran": "Solosis",
  "daburan": "Duosion",
  "rankurusu": "Reuniclus",
  "koaruhii": "Ducklett",
  "suwanna": "Swanna",
  "baniputchi": "Vanillite",
  "baniritchi": "Vanillish",
  "baibanira": "Vanilluxe",
  "shikijika": "Deerling",
  "mebukijika": "Sawsbuck",
  "emonga": "Emolga",
  "kaburumo": "Karrablast",
  "shubarugo": "Escavalier",
  "tamagetake": "Foongus",
  "morobareru": "Amoonguss",
  "pururiru": "Frillish",
  "burungeru": "Jellicent",
  "mamanbou": "Alomomola",
  "bachuru": "Joltik",
  "denchura": "Galvantula",
  "tesshiido": "Ferroseed",
  "nattorei": "Ferrothorn",
  "giaru": "Klink",
  "gigiaru": "Klang",
  "gigigiaru": "Klinklang",
  "shibishirasu": "Tynamo",
  "shibibiiru": "Eelektrik",
  "shibirudon": "Eelektross",
  "riguree": "Elgyem",
  "oobemu": "Beheeyem",
  "hitomoshi": "Litwick",
  "ranpuraa": "Lampent",
  "shandera": "Chandelure",
  "kibago": "Axew",
  "onondo": "Fraxure",
  "ononokusu": "Haxorus",
  "kumashun": "Cubchoo",
  "tsunbeaa": "Beartic",
  "furiijio": "Cryogonal",
  "chobomaki": "Shelmet",
  "agirudaa": "Accelgor",
  "maggyo": "Stunfisk",
  "kojofuu": "Mienfoo",
  "kojondo": "Mienshao",
  "kurimugan": "Druddigon",
  "gobitto": "Golett",
  "goruugu": "Golurk",
  "komatana": "Pawniard",
  "kirikizan": "Bisharp",
  "baffuron": "Bouffalant",
  "washibon": "Rufflet",
  "uooguru": "Braviary",
  "baruchai": "Vullaby",
  "barujiina": "Mandibuzz",
  "kuitaran": "Heatmor",
  "aianto": "Durant",
  "monozu": "Deino",
  "jiheddo": "Zweilous",
  "sazandora": "Hydreigon",
  "meraruba": "Larvesta",
  "urugamosu": "Volcarona",
  "kobaruon": "Cobalion",
  "terakion": "Terrakion",
  "birijion": "Virizion",
  "torunerosu": "Tornadus",
  "borutorosu": "Thundurus",
  "reshiramu": "Reshiram",
  "zekuromu": "Zekrom",
  "randorosu": "Landorus",
  "kyuremu": "Kyurem",
  "kerudio": "Keldeo",
  "meroetta": "Meloetta",
  "genosekuto": "Genesect",
  "harimaron": "Chespin",
  "hariboogu": "Quilladin",
  "burigaron": "Chesnaught",
  "fokko": "Fennekin",
  "teerunaa": "Braixen",
  "mafokushii": "Delphox",
  "keromatsu": "Froakie",
  "gekogashira": "Frogadier",
  "gekkouga": "Greninja",
  "gekkougasatoshi": "Greninja-Ash",
  "satoshigekkouga": "Greninja-Ash",
  "horubii": "Bunnelby",
  "horuudo": "Diggersby",
  "yayakoma": "Fletchling",
  "hinoyakoma": "Fletchinder",
  "faiaroo": "Talonflame",
  "kofukimushi": "Scatterbug",
  "kofuurai": "Spewpa",
  "bibiyon": "Vivillon",
  "shishiko": "Litleo",
  "kaenjishi": "Pyroar",
  "furabebe": "Flabébé",
  "furaette": "Floette",
  "furaajesu": "Florges",
  "meeekuru": "Skiddo",
  "googooto": "Gogoat",
  "yanchamu": "Pancham",
  "goronda": "Pangoro",
  "torimian": "Furfrou",
  "nyasupaa": "Espurr",
  "nyaonikusu": "Meowstic",
  "hitotsuki": "Honedge",
  "nidangiru": "Doublade",
  "girugarudo": "Aegislash",
  "shushupu": "Spritzee",
  "furefuwan": "Aromatisse",
  "peroppafu": "Swirlix",
  "peroriimu": "Slurpuff",
  "maaiika": "Inkay",
  "karamanero": "Malamar",
  "kametete": "Binacle",
  "gamenodesu": "Barbaracle",
  "kuzumoo": "Skrelp",
  "doramidoro": "Dragalge",
  "udeppou": "Clauncher",
  "burosutaa": "Clawitzer",
  "erikiteru": "Helioptile",
  "erezaado": "Heliolisk",
  "chigorasu": "Tyrunt",
  "gachigorasu": "Tyrantrum",
  "amarusu": "Amaura",
  "amaruruga": "Aurorus",
  "ninfia": "Sylveon",
  "ruchaburu": "Hawlucha",
  "mereshii": "Carbink",
  "numera": "Goomy",
  "numeiru": "Sliggoo",
  "numerugon": "Goodra",
  "kureffi": "Klefki",
  "bokuree": "Phantump",
  "oorotto": "Trevenant",
  "baketcha": "Pumpkaboo",
  "panpujin": "Gourgeist",
  "kachikooru": "Bergmite",
  "kurebeesu": "Avalugg",
  "onbatto": "Noibat",
  "onbaan": "Noivern",
  "zeruneasu": "Xerneas",
  "iberutaru": "Yveltal",
  "jigarude": "Zygarde",
  "dianshii": "Diancie",
  "fuupa": "Hoopa",
  "borukenion": "Volcanion",
  "mokuroo": "Rowlet",
  "fukusuroo": "Dartrix",
  "junaipaa": "Decidueye",
  "nyabii": "Litten",
  "nyahiito": "Torracat",
  "gaogaen": "Incineroar",
  "ashimari": "Popplio",
  "oshamari": "Brionne",
  "ashireenu": "Primarina",
  "tsutsukera": "Pikipek",
  "kerarappa": "Trumbeak",
  "dodekabashi": "Toucannon",
  "yanguusu": "Yungoos",
  "dekaguusu": "Gumshoos",
  "agojimushi": "Grubbin",
  "denjimushi": "Charjabug",
  "kuwaganon": "Vikavolt",
  "makenkani": "Crabrawler",
  "kekenkani": "Crabominable",
  "odoridori": "Oricorio",
  "aburii": "Cutiefly",
  "aburibon": "Ribombee",
  "iwanko": "Rockruff",
  "rugarugan": "Lycanroc",
  "yowashi": "Wishiwashi",
  "hidoide": "Mareanie",
  "dohidoide": "Toxapex",
  "dorobanko": "Mudbray",
  "banbadoro": "Mudsdale",
  "shizukumo": "Dewpider",
  "onishizukumo": "Araquanid",
  "karikiri": "Fomantis",
  "rarantesu": "Lurantis",
  "nemashu": "Morelull",
  "masheedo": "Shiinotic",
  "yatoumori": "Salandit",
  "ennyuuto": "Salazzle",
  "nuikoguma": "Stufful",
  "kiteruguma": "Bewear",
  "amakaji": "Bounsweet",
  "amamaiko": "Steenee",
  "amaajo": "Tsareena",
  "kyuwawaa": "Comfey",
  "yareyuutan": "Oranguru",
  "nagetsukesaru": "Passimian",
  "kosokumushi": "Wimpod",
  "gusokumusha": "Golisopod",
  "sunabaa": "Sandygast",
  "shirodesuna": "Palossand",
  "namakobushi": "Pyukumuku",
  "taipunuru": "Type: Null",
  "shiruvuadi": "Silvally",
  "meteno": "Minior",
  "nekkoara": "Komala",
  "bakugamesu": "Turtonator",
  "mimikkyu": "Mimikyu",
  "hagigishiri": "Bruxish",
  "jijiiron": "Drampa",
  "dadarin": "Dhelmise",
  "jarako": "Jangmo-o",
  "jarango": "Hakamo-o",
  "jararanga": "Kommo-o",
  "kapukokeko": "Tapu Koko",
  "kaputetefu": "Tapu Lele",
  "kapubururu": "Tapu Bulu",
  "kapurehire": "Tapu Fini",
  "kosumoggu": "Cosmog",
  "kosumoumu": "Cosmoem",
  "sorugareo": "Solgaleo",
  "runaaara": "Lunala",
  "utsuroido": "Nihilego",
  "masshibuun": "Buzzwole",
  "fierooche": "Pheromosa",
  "denjumoku": "Xurkitree",
  "tekkaguya": "Celesteela",
  "kamitsurugi": "Kartana",
  "akujikingu": "Guzzlord",
  "nekurozuma": "Necrozma",
  "magiana": "Magearna",
  "maashadoo": "Marshadow",
  "bebenomu": "Poipole",
  "aagoyon": "Naganadel",
  "tsundetsunde": "Stakataka",
  "zugadoon": "Blacephalon",
  "merutan": "Meltan",
  "merumetaru": "Melmetal"
}