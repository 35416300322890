{
  "1": {
    "brn": {
      "name": "brn",
      "effectType": "Status",
      "onAfterMoveSelfPriority": 2
    },
    "par": {"name": "par", "effectType": "Status", "onBeforeMovePriority": 2},
    "slp": {
      "name": "slp",
      "effectType": "Status",
      "onBeforeMovePriority": 10,
      "onAfterMoveSelfPriority": 3
    },
    "frz": {"name": "frz", "effectType": "Status", "onBeforeMovePriority": 12},
    "psn": {
      "name": "psn",
      "effectType": "Status",
      "onAfterMoveSelfPriority": 2
    },
    "tox": {"inherit": true, "onAfterMoveSelfPriority": 2},
    "confusion": {"name": "confusion", "onBeforeMovePriority": 3},
    "flinch": {"name": "flinch", "duration": 1, "onBeforeMovePriority": 8},
    "trapped": {"name": "trapped", "noCopy": true},
    "partiallytrapped": {
      "name": "partiallytrapped",
      "duration": 2,
      "onBeforeMovePriority": 9
    },
    "partialtrappinglock": {"name": "partialtrappinglock"},
    "mustrecharge": {"inherit": true, "duration": 0, "onBeforeMovePriority": 7},
    "lockedmove": {"name": "lockedmove"},
    "twoturnmove": {"name": "twoturnmove"},
    "invulnerability": {"name": "invulnerability"}
  },
  "2": {
    "brn": {
      "name": "brn",
      "effectType": "Status",
      "onAfterMoveSelfPriority": 3
    },
    "par": {"name": "par", "inherit": true, "onBeforeMovePriority": 2},
    "slp": {"name": "slp", "effectType": "Status", "onBeforeMovePriority": 10},
    "frz": {"name": "frz", "inherit": true, "onResidualOrder": 7},
    "psn": {
      "name": "psn",
      "effectType": "Status",
      "onAfterMoveSelfPriority": 3
    },
    "tox": {
      "name": "tox",
      "effectType": "Status",
      "onAfterMoveSelfPriority": 3
    },
    "confusion": {"inherit": true},
    "partiallytrapped": {
      "inherit": true,
      "onResidualOrder": 3,
      "onResidualSubOrder": 1
    },
    "lockedmove": {"name": "lockedmove"},
    "futuremove": {"inherit": true, "onResidualOrder": 1},
    "raindance": {"inherit": true, "onFieldResidualOrder": 2},
    "sunnyday": {"inherit": true, "onFieldResidualOrder": 2},
    "sandstorm": {"inherit": true, "onFieldResidualOrder": 2},
    "stall": {"name": "stall", "duration": 2},
    "residualdmg": {"name": "residualdmg", "onAfterMoveSelfPriority": 100}
  },
  "3": {
    "slp": {"name": "slp", "effectType": "Status", "onBeforeMovePriority": 10},
    "frz": {"inherit": true},
    "sandstorm": {"inherit": true}
  },
  "4": {
    "brn": {"inherit": true, "onResidualOrder": 10, "onResidualSubOrder": 6},
    "par": {"inherit": true},
    "slp": {"name": "slp", "effectType": "Status", "onBeforeMovePriority": 10},
    "psn": {"inherit": true, "onResidualOrder": 10, "onResidualSubOrder": 6},
    "tox": {"inherit": true, "onResidualOrder": 10, "onResidualSubOrder": 6},
    "confusion": {"inherit": true},
    "frz": {"inherit": true},
    "substitutebroken": {"noCopy": true},
    "trapped": {"inherit": true, "noCopy": false},
    "trapper": {"inherit": true, "noCopy": false},
    "partiallytrapped": {
      "inherit": true,
      "onResidualOrder": 10,
      "onResidualSubOrder": 9
    },
    "choicelock": {"inherit": true},
    "futuremove": {"inherit": true, "onResidualOrder": 11},
    "stall": {"inherit": true, "counterMax": 8},
    "raindance": {"inherit": true, "onFieldResidualOrder": 8},
    "sunnyday": {"inherit": true, "onFieldResidualOrder": 8},
    "sandstorm": {"inherit": true, "onFieldResidualOrder": 8},
    "hail": {"inherit": true, "onFieldResidualOrder": 8},
    "arceus": {"name": "Arceus"}
  },
  "5": {
    "slp": {"inherit": true},
    "partiallytrapped": {"inherit": true},
    "stall": {"duration": 2, "counterMax": 256},
    "gem": {"duration": 1, "affectsFainted": true}
  },
  "6": {
    "brn": {"inherit": true},
    "par": {"inherit": true},
    "confusion": {"inherit": true},
    "choicelock": {"inherit": true}
  },
  "9": {
    "brn": {"name": "brn", "effectType": "Status", "onResidualOrder": 10},
    "par": {"name": "par", "effectType": "Status", "onBeforeMovePriority": 1},
    "slp": {"name": "slp", "effectType": "Status", "onBeforeMovePriority": 10},
    "frz": {"name": "frz", "effectType": "Status", "onBeforeMovePriority": 10},
    "psn": {"name": "psn", "effectType": "Status", "onResidualOrder": 9},
    "tox": {"name": "tox", "effectType": "Status", "onResidualOrder": 9},
    "confusion": {"name": "confusion", "onBeforeMovePriority": 3},
    "flinch": {"name": "flinch", "duration": 1, "onBeforeMovePriority": 8},
    "trapped": {"name": "trapped", "noCopy": true},
    "trapper": {"name": "trapper", "noCopy": true},
    "partiallytrapped": {
      "name": "partiallytrapped",
      "duration": 5,
      "onResidualOrder": 13
    },
    "lockedmove": {"name": "lockedmove", "duration": 2},
    "twoturnmove": {"name": "twoturnmove", "duration": 2},
    "choicelock": {"name": "choicelock", "noCopy": true},
    "mustrecharge": {
      "name": "mustrecharge",
      "duration": 2,
      "onBeforeMovePriority": 11,
      "onLockMove": "recharge"
    },
    "futuremove": {"name": "futuremove", "duration": 3, "onResidualOrder": 3},
    "healreplacement": {"name": "healreplacement", "onSwitchInPriority": 1},
    "stall": {"name": "stall", "duration": 2, "counterMax": 729},
    "gem": {
      "name": "gem",
      "duration": 1,
      "affectsFainted": true,
      "onBasePowerPriority": 14
    },
    "raindance": {
      "name": "RainDance",
      "effectType": "Weather",
      "duration": 5,
      "onFieldResidualOrder": 1
    },
    "primordialsea": {
      "name": "PrimordialSea",
      "effectType": "Weather",
      "duration": 0,
      "onTryMovePriority": 1,
      "onFieldResidualOrder": 1
    },
    "sunnyday": {
      "name": "SunnyDay",
      "effectType": "Weather",
      "duration": 5,
      "onFieldResidualOrder": 1
    },
    "desolateland": {
      "name": "DesolateLand",
      "effectType": "Weather",
      "duration": 0,
      "onTryMovePriority": 1,
      "onFieldResidualOrder": 1
    },
    "sandstorm": {
      "name": "Sandstorm",
      "effectType": "Weather",
      "duration": 5,
      "onModifySpDPriority": 10,
      "onFieldResidualOrder": 1
    },
    "hail": {
      "name": "Hail",
      "effectType": "Weather",
      "duration": 5,
      "onFieldResidualOrder": 1
    },
    "snow": {
      "name": "Snow",
      "effectType": "Weather",
      "duration": 5,
      "onModifyDefPriority": 10,
      "onFieldResidualOrder": 1
    },
    "deltastream": {
      "name": "DeltaStream",
      "effectType": "Weather",
      "duration": 0,
      "onEffectivenessPriority": -1,
      "onFieldResidualOrder": 1
    },
    "dynamax": {
      "name": "Dynamax",
      "noCopy": true,
      "onBeforeSwitchOutPriority": -1,
      "onDragOutPriority": 2,
      "onResidualPriority": -100
    },
    "commanded": {
      "name": "Commanded",
      "noCopy": true,
      "onDragOutPriority": 2,
      "onTrapPokemonPriority": -11
    },
    "commanding": {
      "name": "Commanding",
      "noCopy": true,
      "onDragOutPriority": 2,
      "onTrapPokemonPriority": -11,
      "onInvulnerabilityPriority": 2
    },
    "arceus": {"name": "Arceus", "onTypePriority": 1},
    "silvally": {"name": "Silvally", "onTypePriority": 1},
    "rolloutstorage": {"name": "rolloutstorage", "duration": 2}
  }
}